import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import RecipientsTable from './RecipientsTable';
import Submitted from './Submitted';
import Failed from './Failed'
import Delivered from './Delivered'
const Distribution= ({tabId})=>{
    const checkIsAuth = useSelector(state => state);
    const [distributionKpiData, setDistributionKpi] = useState();
    const { id } = useParams();
    const loadApiCall = useCallback(async () => {
        try {
            (checkIsAuth['APITOKEN']['token'] !== "") &&
                Client.get(`Insights/distribution/Email/${id}/KPI?tabId=${tabId}`, {
                    headers: {
                        'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token'],
                        'Content-Type': 'application/json'
                    }
                }).then(async (res) => {
                    try {
                        await setDistributionKpi(res['data']);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [checkIsAuth, id,tabId]);
    useEffect( () => {
         loadApiCall();
    }, [loadApiCall])
    return(
        <><div className="bx-boder-common">
            <div className="_insight_header white"><h3>Distribution</h3><p className="content">&nbsp;</p></div>
            <div className="livesend white summary_o_v">
                <figure className="highcharts-figure repeat3">
                    {distributionKpiData &&  
                       <> <Delivered Data={distributionKpiData}/>
                        <Submitted Data={distributionKpiData}/>
                        <Failed Data={distributionKpiData}/></>
                    }
                </figure>
            </div></div>
            <div className="overviewtable margin-top50 bx-boder-common white">
                <div className="_h_header"><h3>Sending Details</h3></div>
                <div className="smsDistribution_recipeients">
                    <RecipientsTable/>
                </div>
            </div>
            </>
    )
}
export default Distribution;