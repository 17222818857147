import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BlankData from "../../../../BlankData";
import Client from "../../../../GlobalAPICall";
import DeleteListTemplate from "../../../../ReuseTemplate";
import GridLoader from "../../../../ReuseTemplate/GridLoader";

const UTMTemplate = () => {
    const { APITOKEN } = useSelector(state => state);
    const [templateData, setTemplateData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [deleteTemplate, setDeleteConfirmTemplate] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const navigate = useNavigate();
    const load = useCallback(() => {
        setIsLoading(true);
        (APITOKEN['token'] !== "") &&
            Client.get(`UTM/GetUTMTemplates`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setIsLoading(false);
                    setTemplateData(data);
                } catch (err) {
                    console.error(err);
                }

            }).catch(err => setIsLoading(false));
    }, [APITOKEN]);

    const deleteFN = (item) => {
        console.log(item)
        setDeleteId(item.rowUID)
        setDeleteConfirmTemplate(true);
    }
    const deleteConfirm = () => {
        Client.delete(`UTM/DeleteUTMTemplate/${deleteId}`, {
            data: {},
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then((({data}) => {
                try {
                    if (data.success) {
                        load()
                        setDeleteConfirmTemplate(false);
                    }
                } catch (err) {
                    console.error(err);
                }
            })).catch(err => console.error(err));
    }
    useEffect(() => {
        load();
    }, [load])
    return (<>
        {deleteTemplate ? <div className="margin-top50 inline-block width100"><DeleteListTemplate title="Click on Confirm to delete this UTM Template." content="This will permanently delete and it can not be restored." colSpan="5" onClick={() => setDeleteConfirmTemplate(false)} deleteDownload={() => deleteConfirm()} /></div> :
            <div className="sms_Sec_1 bx-boder-common width100 nobg">
                <div className="_h_header white border-radius2">
                    <h3>UTM Templates</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 grid_btn_active _template_grid" onClick={() => navigate('/createContent/78')}>Create Template</button>
                    </span>
                </div>
                <div className="_list_fx">
                    {isLoading ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                        <table className="smstable white" cellPadding="0" cellSpacing="0">
                            {templateData.length > 0 &&
                                <thead>
                                    <tr>
                                        <th className="table_cell_header">Name</th>
                                        <th className="table_cell_header">Campaign</th>
                                        <th className="table_cell_header">Medium</th>
                                        <th className="table_cell_header">Source</th>
                                        <th className="table_cell_header">Content</th>
                                        <th className="table_cell_header">Term</th>
                                        <th className="table_cell_header">Actions</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {templateData.length === 0 ? <BlankData title="You have not created any UTM Template." content="Create UTM to easily measure and visualize the success of your campaigns and contacts build." colSpan="5" /> : templateData && templateData.map((item, i) => <Grid item={item} key={i} DeleteGrid={deleteFN} />)}

                            </tbody>

                        </table>
                    }
                </div>
            </div>
        }
    </>)
};
export default UTMTemplate;

const Grid = ({ item, DeleteGrid }) => {
    const navigate = useNavigate();
    return (
        <><tr>
            <td className="body_table_cell excludeClass">{item.name}</td>
            <td className="body_table_cell">{item.campaignName}</td>
            <td className="body_table_cell">{item.mediumName}</td>
            <td className="body_table_cell">{item.sourceName}</td>
            <td className="body_table_cell">{item.content}</td>
            <td className="body_table_cell">{item['term']}</td>
            <td className="body_table_cell">
                <button type="button" className="link inline-block utm_tem_edit nobg noborder" onClick={() => navigate(`/create/templates/editUTMTemplate?cam=${item.rowUID}&edit=true`)}>Edit</button>
                <button type="button" className="link inline-block utm_tem_delet nobg noborder" onClick={() => DeleteGrid(item)}>Delete</button>
            </td>
        </tr></>
    )
}