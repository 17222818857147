import React, { useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { PickerOverlay } from 'filestack-react';

import addImage from './../../../../assets/img/icon-add-image.svg';
import sixDot from './../../../../assets/img/icon-six-dot.svg';

import { fetchCategories } from "../../../../services/store";

const FILE_PICKER_API_KEY = process.env.REACT_APP_FILE_PICKER_API_KEY;

const CreateNewStore = ({ sendDataToParent, setMobilePreviewData, onCancel }) => {
  const [showFileUploadSection, setShowFileUploadSection] = useState(false);
  const [activeAccordionId, setActiveAccordionId] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [categories, setCategories] = useState([]);
  const [formState, setFormState] = useState({
    categoryId: 0,
    images: [{ id: 1, files: {}, isOpen: true }],
    storeInfo: '',
  });

  const [errors, setErrors] = useState({
    categoryId: '',
    storeInfo: '',
    images: '',
  });

  // On Page Refresh > Fetch Categories
  useEffect(() => {
    (async () => {
      try {
        const response = await fetchCategories();
        setCategories(_get(response, "data", []));
      } catch (err) {
        console.log('Error occurred when fetching categories', err);
      }
    })();
  }, []);

  const handleCategoryChange = (e) => {
    setFormState({ ...formState, categoryId: e.target.value });
  };

  const handleStoreInfoTextChange = (e) => {
    setFormState({ ...formState, storeInfo: e.target.value });
  };

  const addAccordion = () => {
    const newId = formState.images.length + 1;
    setFormState(prevState => ({
      ...prevState,
      images: [
        ...prevState.images,
        { id: newId, files: {}, isOpen: true }
      ]
    }));
  };

  const toggleAccordion = (id) => {
    setFormState(prevState => ({
      ...prevState,
      images: prevState.images.map(image =>
        image.id === id ? { ...image, isOpen: !image.isOpen } : image
      )
    }));
  };

  const deleteAccordion = (id) => {
    setFormState(prevState => ({
      ...prevState,
      images: prevState.images.filter(image => image.id !== id)
    }));
  };

  useEffect(() => {
    validateForm();
    setMobilePreviewData(formState, 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { categoryId: '', images: '', storeInfo: '' };

    if (!formState.categoryId) {
      newErrors.categoryId = 'Category is required.';
      isValid = false;
    }

    if (formState.storeInfo.length < 1) {
      newErrors.storeInfo = 'Store info is required.';
      isValid = false;
    }

    if (formState.storeInfo.length > 1000) {
      newErrors.storeInfo = 'Store info text cannot exceed 1000 characters.';
      isValid = false;
    }

    if (formState.images.length > 3) {
      newErrors.images = 'Only 3 images are allowed.';
      isValid = false;
    }

    setErrors(newErrors);
    setFormValid(isValid);
    return isValid;
  };

  const handleSubmit = () => {
    if (formValid) {
      sendDataToParent(formState, 2);
    }
  };

  const Accordion = ({ accordion }) => {
    return (
      <div className="formmodel-bx accordion-store">
        <div className="accordion-header" onClick={() => toggleAccordion(_get(accordion, "id", 0))}>
          <h4 className="flex align-items-center gap-2"><img src={sixDot} alt="" /> {`Image ${_get(accordion, "id", 0)}`}</h4>
          <div className="flex align-items-center gap-3">
            <span onClick={(e) => { e.stopPropagation(); deleteAccordion(_get(accordion, "id", 0)); }} style={{ cursor: 'pointer' }}>
              <i className="fas fa-trash"></i>
            </span>
            <span>
              <i className={`fas fa-chevron-${(_get(accordion, "isOpen", false) === true) ? 'up' : 'down'}`}></i>
            </span>
          </div>
        </div>

        {(_get(accordion, "isOpen", false) === true) && (
          <div className="accordion-content">
            <div className="position-relative">
              <div className="store-image">
                <div className="step-upload-file" onClick={() => {
                  setShowFileUploadSection(true);
                  setActiveAccordionId(_get(accordion, "id", 0));
                }}>
                  <i className="fal fa-cloud-upload" aria-hidden="true"></i>
                </div>
              </div>
              {!_isEmpty(_get(accordion, "files", {})) && (
                <div className="uploaded-images">
                  <img src={_get(accordion, "files.imageUrl", "")} alt="" />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const fileUploadSection = () => {
    return (
      <PickerOverlay
        apikey={FILE_PICKER_API_KEY}
        pickerOptions={{
          fromSources: ["local_file_system"],
          accept: ["image/*"],
          onClose: () => { setShowFileUploadSection(false); }
        }}
        onUploadDone={(res) => {
          setFormState(prevState => ({
            ...prevState,
            images: prevState.images.map(image =>
              image.id === activeAccordionId ? { ...image, files: { imageUrl: _get(res, "filesUploaded[0].url", ""), fileName: _get(res, "filesUploaded[0].filename", "") } } : image
            )
          }));
          setShowFileUploadSection(false);
        }}
      />
    );
  }

  return (
    <>
      <div className="bx-boder-common width100 nobg mt-8">
        <div className="_h_header white border-radius2">
          <h3>Create store name</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            <div className="col-12">
              <div className="formmodel-bx">
                <label className="_l_able_cls" id="categoryId">Category <span className="error-message">*</span></label>
                <select
                  className="inputformmodel width100"
                  value={formState.categoryId}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  {!_isEmpty(categories || []) && (categories || []).map((category, i) => (
                    <option key={i} value={_get(category, "id", 0)}>{_get(category, "name", "")}</option>
                  ))}
                </select>
              </div>

              <div className="formmodel-bx">
                <label className="_l_able_cls">Store image</label>
                <p className="mt-2">Click on the icon or Drag and Drop an image from your computer to upload it. Image size equals to 500px x 500px. Lower than this size will lead to distortion of the image in the mobile app. You can upload up to three images.</p>
              </div>

              {formState.images.map(accordion => (
                <Accordion key={accordion.id} accordion={accordion} />
              ))}

              {_get(errors, "images", "") && <p className="error-message">{errors.images}</p>}
              <div className="store-add-image mt-5 mb-10" onClick={addAccordion}>
                <img src={addImage} alt="" />
                <p>Add an image</p>
              </div>

              <div className="formmodel-bx">
                <label className="_l_able_cls">Store info text <span className="error-message">*</span></label>
                <textarea
                  className="inputformmodel width100 textareaModel excludeclassName"
                  value={formState.storeInfo}
                  onChange={handleStoreInfoTextChange}
                />
                <div className="text-right mt-3">
                  <p>Characters used: <b>{formState.storeInfo.length}</b></p>
                  <p>Characters in total: <b>1000</b></p>
                </div>
              </div>
            </div>
          </div>

          <div className="formmodel-bx text-right mt-12 mb-0">
            <button
              type="button"
              className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg"
              onClick={(e) => onCancel(formState, 2)}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`btn btn-primary btn-gape-15 ${formValid ? '' : 'btn-disabled'}`}
              onClick={handleSubmit}
              disabled={!formValid}
            >
              Continue
            </button>
          </div>
        </div>
      </div>

      {showFileUploadSection && fileUploadSection()}
    </>
  );
}

export default CreateNewStore;
