import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import Client from "../../GlobalAPICall";
import getLocaleDateString from "../../TimeFormat";

const SlugCreate = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [domainData, setDomainData] = useState({
        data: [],
        selectedDomain: ''
    });
    const [slugValue, setSlug] = useState({});
    const [onchangeFlag, setOnchangeFlag] = useState(false);

    const [slugName, setSlugName] = useState('');
    const [createSlugName, setCreateSlugName] = useState('');
    const navigate = useNavigate();
    const CampaignClient = slugValue.contentType === 2 ? 'Landing Pages' : slugValue.contentType === 5 ? 'Emails' : slugValue.contentType === 4 ? 'Surveys': slugValue.contentType === '97' ? 'Stores' : 'Workflows';
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Domain/2/Domains`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setDomainData({
                    data: res['data'],
                    selectedDomain: filterSelected(res['data'])
                });
            }).catch(err => {
                console.error(err);
            });
    }, [APITOKEN]);
    const domaindefaultvalues = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/domaindefaultvalues?id=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setSlug(res['data']);
                    setSlugName(res.data.slugName)
                    setCreateSlugName(res.data.campaignSubDomain)
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.error(err);
            });
    }, [APITOKEN, id, setSlugName]);

    function filterSelected(res) {
        let filter = [];
        if (res !== undefined) {
            filter = res.filter(x => x.IsDefault === true);

        }
        return filter[0].Id;
    };
    useEffect(() => {
        domaindefaultvalues();
        load();
    }, [load, domaindefaultvalues])

    const createSlug = () => {
        const makeDomain = onchangeFlag ? slugValue.campaignSubDomain :
        `https://${slugValue.campaignSubDomain}.${slugValue.campaignDomain}`
        Client.put(`Campaign/${id}/publish_slugurl/${slugName}?slugUrl=${makeDomain}/${slugName}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                const { Success, ContentType } = res.data;
                if (Success) {
                    if (ContentType === 2) {
                        navigate('/landingpage/2')
                    } else if (ContentType === 5) {
                        navigate('/email/5')
                    } else if (ContentType === 4) {
                        navigate('/survey/4')
                    } else {
                        navigate('/workflow/74')
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    const domainChange = ev => {
        const value = ev.target.value;
        setDomainData(prv => ({
            ...prv,
            selectedDomain: value
        }));
        const getFilter = domainData.data.filter(item => item.Id === value);
        setSlug(prv => ({
            ...prv,
            campaignSubDomain: getFilter[0].Domain
        }));
        setOnchangeFlag(true);
    }

    const creationSubDomain = () => {
        const obj = {
            id: getLocaleDateString('cam', window.location.href) || id,
            campaignSubDomain: createSlugName,
            campaignDomain: slugValue.campaignDomain
        };
        Client.post(`Domain/WhiteLabel/Configure/Domain/Campaign/customdomain_setup`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                const { success } = data;
                if (success) {
                    domaindefaultvalues();
                }
            } catch (err) {
                console.log(err);
            }
        }).catch(err => {
            console.error(err);
        });
    }

    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec  margin-top50">
                {slugValue && slugValue.isDomainCreated ?
                    <div className="sms_Sec_1 bx-boder-common width100 nomargin">
                        <div className="_h_header">
                            <h3>Create your {CampaignClient} URL</h3>
                        </div>
                        <div className="padding20">
                            <p>To give your {CampaignClient} URL a unique identifyer you create a slug. This will be a text behind your registered domain. If you are sending out the  URL as a unique URL, there will be generated a unique code behind the slug.</p>
                            <div className="padding20" style={{ paddingLeft: '0', paddingRight: '0', paddingBottom: '0' }}>
                                <div>
                                    <div className="formmodel-bx left_h_p">
                                        <label className="_l_able_cls">Domain</label>
                                        <select
                                            className="inputformmodel"
                                            onChange={e => domainChange(e)} value={domainData.selectedDomain}>
                                            <option value="">Select</option>
                                            {domainData.data && domainData.data.map((v, i) => <option key={i} value={v.Id}>{v.Domain}</option>)}
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <div className="formmodel-bx left_h_p">
                                        <label className="_l_able_cls">Slug</label>
                                        <input type="text"
                                            className="inputformmodel"
                                            defaultValue={slugValue.slugName}
                                            onChange={e => {
                                                setSlugName(e.target.value); setSlug(prv => ({
                                                    ...prv,
                                                    campaignCustomUrl: e.target.value
                                                }))
                                            }} />
                                    </div>
                                </div>
                                <div className="formmodel-bx left_h_p">
                                    <label className="_l_able_cls">Preview of URL</label>
                                    <p>
                                        {
                                            onchangeFlag ? <>{slugValue.campaignSubDomain}</> :
                                                <>https://{slugValue.campaignSubDomain}.{slugValue.campaignDomain}</>
                                        }/{slugName}
                                    </p>
                                </div>
                                <div className="formmodel-bx nomargin text-right">
                                    <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" onClick={createSlug}>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className="sms_Sec_1 bx-boder-common width100 nomargin">
                        <div className="_h_header">
                            <h3>Set a sub-domain for your creations URL</h3>
                        </div>
                        <div className="bx-pad">
                            <p>We recommend you to create your own sub-domain URL for creations. This will help you brand your creations and will make your customers trust that your content is coming from you. We use the domain mypages.no, so your sub-domain text will shown in front of this.</p>
                            <div className="bx-pad" style={{ paddingLeft: '0', paddingRight: '0' }}>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">&nbsp;</label>
                                    <div className="-Lhx inputformmodel noborder nopadding drop-zone">
                                        <input type="text" onChange={e => setCreateSlugName(e.target.value)} className="inputformmodel width100 d-input" value={createSlugName} />
                                        <span className="hyper-span">http://</span>
                                        <span className="domain-span">{slugValue.campaignDomain}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="formmodel-bx nomargin text-right">
                                <button type="button" className="grid_btn_1  nobg noborder _cls_return">Cancel</button>
                                <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" disabled={
                                    createSlugName !== "" ? false : true
                                } onClick={creationSubDomain}>Save</button>
                            </div>
                        </div>

                    </div>
                }
            </div>
        </>
    )

}
export default SlugCreate;
