import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import BlankData from "../../BlankData";
import Client from "../../GlobalAPICall";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import { encode as btoa } from 'base-64'
import Cookies from 'js-cookie'
import { filterFn } from ".";

export const SaveTemplate = ({ ...props }) => {
    const [selectedTemplateData, setSelectedTemplateData] = useState([]);
    const [deleteConfirmMap, setDeleteConfirmMap] = useState({});
    const wrapperRef = useRef(null);
    const { TemplateId, Index, APITOKEN } = props;

    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`Campaign/${getParameterByName('cam', window.location.href)}/${TemplateId}/savedtemplates`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            })
            .then(({ data }) => {
                try {
                    setSelectedTemplateData(data.length > 0 ? filterFn(data) : []);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.error(err);
                }
            });

        return () => {
            cancelToken.cancel();
            setSelectedTemplateData([]);
        };
    }, [APITOKEN, TemplateId]);

    const deleteTemplate = (templateId) => {
        setDeleteConfirmMap(prev => ({
            ...prev,
            [templateId]: true
        }));
    }

    const cancelDelete = (templateId) => {
        setDeleteConfirmMap(prev => ({
            ...prev,
            [templateId]: false
        }));
    }

    const deleteConfirmFN = (template) => {
        Client.delete(`Campaign/${template.templateId}/DeleteTemplates`, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        })
            .then(({ data }) => {
                try {
                    if (data.Success) {
                        load();
                        setDeleteConfirmMap(prev => ({
                            ...prev,
                            [template.templateId]: false
                        }));
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }

    const selectTemplate = (v) => {
        const secretKey = Cookies.get('secretKey');
        Client.get(`Campaign/${v.templateId}/template`, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        })
            .then(({ data }) => {
                try {
                    window.location.replace(`${APITOKEN.config.subscriptionInfo.EditorDomainUrl}/${data[0].nextPageUrl}?tem=${v.templateId}&cam=${getParameterByName('cam', window.location.href)}${(Index === 0 ? '&theme=1' : Index === 1 ? '&scratch=true' : '')}&type=${getParameterByName('type', window.location.href)}&backUrl=${btoa(window.location.href)}&sk=${secretKey}&lcxtkf=${APITOKEN.config.lcxtkf}&temptype='template'`);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }

    useEffect(() => {
        load();

        function handleClickOutside(event) {
            setDeleteConfirmMap({});
        }

        document.querySelectorAll('.drop-zone').forEach(el => { el.addEventListener("mouseleave", handleClickOutside) });

        return () => {
            document.querySelectorAll('.drop-zone').forEach(el => { el.removeEventListener("mouseleave", handleClickOutside) });
        };
    }, [load, wrapperRef]);

    return (
        selectedTemplateData.length > 0 ? <div className="lcTemb">
            {selectedTemplateData.map((v, i) =>
                <div className="temListL" key={i}>
                    <div className="drop-zone">
                        <div className="lss">
                            <div className="temheig">
                                <img src={v.previewImageUrl} alt="" className="limg" />
                            </div>
                        </div>

                        <div className={`image-overlay ${deleteConfirmMap[v.templateId] ? 'deleteSelected' : ''}`}>
                            {!deleteConfirmMap[v.templateId] ?
                                <div className="jss6">
                                    <div className="bx">
                                        <button type="button" className="nobg noborder pointer" onClick={() => selectTemplate(v)}><i className="fal fa-plus-circle"></i><p>Select</p></button>
                                    </div>
                                    <div className="bx">
                                        <button type="button" className="nobg noborder pointer" onClick={() => deleteTemplate(v.templateId)}><i className="fal fa-trash"></i><p>Delete</p></button>
                                    </div>
                                </div> :
                                <div className="jss6">
                                    <div className="bx">
                                        <button type="button" className="nobg noborder pointer" onClick={() => cancelDelete(v.templateId)}><i className="fal fa-times"></i><p>Cancel</p></button>
                                    </div>
                                    <div className="bx">
                                        <button type="button" className="nobg noborder pointer" onClick={() => deleteConfirmFN(v)}><i className="fal fa-trash"></i><p>Confirm</p></button>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <p className="_temti excludeClass">{v.templateName}</p>
                </div>
            )}
        </div> : <div className="padding20 dashbd_sec" style={{ display: 'flex', flexGrow: 1 }}><table cellPadding="0" cellSpacing="" className=" bx-boder-common " width="100%">
            <tbody><BlankData title="You have not saved any Templates yet." content="You can save a creation as a Template when you are creating it. By saving a creation as a Template it will be easier for you to create new creations with your design without starting from scratch every time." colSpan="5" />
            </tbody></table></div>
    );

}