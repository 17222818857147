import React, { Fragment, useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getMappingFields, importSubscriberList } from "../../../../services/crm";

const Summary = ({ sendDataToParent }) => {

	const navigate = useNavigate();
	const { importContact } = useSelector(state => _get(state, "importContacts", {}));
	const [summaryData, setSummaryData] = useState({});

	useEffect(() => {
		(async () => {
			try {
				const respFirstConset = await getMappingFields({ ImportId: _get(importContact, "data.ImportId", "") });
				setSummaryData(_get(respFirstConset, "data.data[0]", {}));

			} catch (error) {
				console.log("Error: ", error);
			}
		})();
	}, [importContact]);

	const onContinue = () => {
		(async () => {
			try {

				const response = await importSubscriberList(_get(importContact, "data.ImportId", ""), { StepId: 6 });

				if (_get(response, "flag", true) === true) {
					navigate("/crm/people/importManage?index=3", { state: 3 })
				}

			} catch (error) {
				console.log("Error: ", error);
			}
		})();
	}

	return (
		<>
			<div className="bx-boder-common nobg my-12">
				<div className="_h_header white border-radius2">
					<h3>Import Summary</h3>
				</div>
			</div>

			<div className="bx-boder-common nobg my-12">
				<div className="_h_header white border-radius2">
					<h3>Upload</h3>
				</div>

				<div className="white">
					<div className="step-summary">
						<div className="p-5">
							<div className="row">
								<div className="col-6">
									<label className="_l_able_cls mb-4">Name of the list you will import your data to</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">{_get(summaryData, "ListName", "")}</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">Upload file name</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">{_get(summaryData, "Filename", "")}</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="_h_header white border-radius2">
					<h3>Mapping</h3>
				</div>

				<div className="white">
					<div className="step-summary">
						<div className="p-5">
							<div className="row">
								{(!_isEmpty(_get(summaryData, "MappingFields", ""))) && JSON.parse(_get(summaryData, "MappingFields", "")).map((row, key) => {
									return (
										<Fragment>
											<div className="col-6">
												<label className="_l_able_cls mb-4">{_get(row, "headerName", "")}</label>
											</div>
											<div className="col-6">
												<label className="_l_able_cls mb-4">{_get(row, "mappedHeaderName", "")}</label>
											</div>
										</Fragment>
									)
								})}
							</div>
						</div>
					</div>
				</div>

				<div className="_h_header white border-radius2">
					<h3>Subscriber Mapping</h3>
				</div>

				<div className="white">
					<div className="step-summary">
						<div className="p-5">
							{(!_isEmpty(_get(summaryData, "SubscriberMapping", ""))) && JSON.parse(_get(summaryData, "SubscriberMapping", "")).map((row, key) => {
								return (
									<div className="row">
										<div className="col-6">
											<label className="_l_able_cls mb-4">{_get(row, "Headers", "")}</label>
										</div>
										<div className="col-6">
											<label className="_l_able_cls mb-4">{_get(row, "Text", "")}</label>
										</div>
									</div>
								)
							})}

						</div>
					</div>
				</div>

				<div className="_h_header white border-radius2">
					<h3>Import rules</h3>
				</div>

				<div className="white">
					<div className="step-summary">
						<div className="p-5">
							<div className="row">
								<div className="col-6">
									<label className="_l_able_cls mb-4">Master data</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">{_get(summaryData, "MasterData", "")}</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">Unique data field indentifier, primary key</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">{_get(summaryData, "PrimaryKey", "")}</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">Unique data field indentifier, secondary key</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">{_get(summaryData, "SecondaryKey", "")}</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="_h_header white border-radius2">
					<h3>Compliance</h3>
				</div>

				<div className="white">
					<div className="step-summary">
						<div className="p-5">
							<div className="row">
								<div className="col-6">
									<label className="_l_able_cls mb-4">I understand the GDPR consent for communication to people in EU/Europe</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">Yes</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">I understand the TCPR consent for communication to people in US</label>
								</div>
								<div className="col-6">
									<label className="_l_able_cls mb-4">Yes</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="white">
					<div className="step-summary pt-8">
						<div className="p-5 text-right">
							<button className="btn link-light" onClick={() => navigate("/crm/people/listings")}>Cancel</button>
							<button className="btn btn-success" onClick={() => onContinue()}>Continue</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Summary;