import { useState } from "react";
import { Link } from 'react-router-dom';

import AllLoyaltyProgram from './components/AllLoyaltyProgram';
import BreadCrum from "../../../ReuseTemplate/BreadCrum"
import Rules from './components/Rules';

const LoyaltyProgram = () => {

  const [activeTab, setActiveTab] = useState("loyalty");

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum To="/crm/people/listings" ToText="CRM List" CurrentPage="Loyalty Program" />
        </div>
        
        <div className="mycontent margin-top25">
          <ul className="tabing">
            <li><Link className={((activeTab || "") === "loyalty") ? "active" : ""} onClick={() => setActiveTab("loyalty")}>Loyalty Program</Link></li>
            <li><Link className={((activeTab || "") === "rules") ? "active" : ""} onClick={() => setActiveTab("rules")}>Rules</Link></li>
          </ul>
        </div>

        {((activeTab || "") === "loyalty") && (
          <AllLoyaltyProgram activeTab={activeTab} />
        )}

        {((activeTab || "") === "rules") && (
          <Rules activeTab={activeTab} />
        )}
      </div>
    </>
  )
}
export default LoyaltyProgram;