import { v4 as uuidv4 } from 'uuid';
import Client from '../../GlobalAPICall';
import { useSelector } from 'react-redux';

const SenderNameEmail = (props) => {
    const { APITOKEN } = useSelector(state => state);
    const { setSenderNameProperties, Data, EnterSenderName, senderNameContext, SetSenderEmail, Reload } = props;
    const selectDoamin = (ev, _ob) => {
        const newValue = Data.whiteLableDomains.map(x => x.id === _ob.id ? ({ ...x, whiteLableDomain: ev.target.value }) : { ...x })

        SetSenderEmail({ data: { ...Data, whiteLableDomains: newValue } });
        setSenderNameProperties(prv => ({
            ...prv,
            isSelectDomain: ev.target.value !== "" ?false:true
        }))

    }
    const newFieldAdd = () => {
        const res = [...Data.whiteLableDomains, ...[{
            id: uuidv4(),
            email: null,
            isDefault: false,
            emailId: "",
            whiteLableDomain: "",
            fieldDisabled: false,
            selectOptionDisabled: true
        }
        ]];
        SetSenderEmail({ data: { ...Data, whiteLableDomains: res } });
        setSenderNameProperties(prv => ({
            ...prv,
            buttonDisabled: true
        }));
    }

    const postObjectFn = () => {
        let obj = [];
        Data.whiteLableDomains.forEach(function (v, i) {
            if (!v['configured'] && !v['isDefault']) {
                obj.push({
                    "id": v.id,
                    "email": `${v.emailId}@${v.whiteLableDomain}`
                });
            }
        });
        return obj;
    }

    const deleteSenderName = _o => {
        const deleteArray = [_o.id];
        Client.delete(`Email/senderemails`, {
            data: deleteArray,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                if (data.Success) {
                    setSenderNameProperties(prv => ({ ...prv, display: false }));
                    Reload();
                };
            })
            .catch(err => console.error(err));
    }

    // Email Sender Name Save Method 
    const senderNameSave = () => {
        const makePostObject = postObjectFn();
        Client.post(`Email/senderemails`, makePostObject, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                if (data.Success) {
                    setSenderNameProperties(prv => ({ ...prv, display: false }));
                    Reload();
                };

            }).catch(err => console.error(err));
    }

    return (
        <div className="sendTestMask">
            <div className="_sendTestRemove extraclass" style={{ width: '498px' }}>
                <div className="topn-header noborder">
                    <span className="selected-title">Sender Email</span>
                    <span style={{
                        padding: '20px',
                        float: 'right'
                    }} onClick={() => setSenderNameProperties(prv => ({ ...prv, display: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div>
                    <p className="padding20" style={{ paddingBottom: '0' }}>
                        Create the email address you wish to use as the sender address for your email campaigns.
                        <br></br><br></br>
                        You can create multiple email addresses for your verified domain to address your business requirements, for example,
                        <span className="link"> noreply@yourdomain.com</span>, <span className="link">help@yourdomain.com</span> etc.
                    </p>

                    <div className="padding20">
                        {Data.whiteLableDomains.map((x, i) => <div key={i} className="formmodel-bx s-n-b"
                            style={{ display: !x.id ? 'none' : 'block' }}>
                            <div className="s-bx">
                                <div><input
                                    type="text"
                                    value={x['emailId']}
                                    disabled={x['fieldDisabled']}
                                    onChange={e => EnterSenderName(e, x)}
                                    className="inputformmodel senderInput" />
                                </div>
                                <div>@</div>
                                <div>
                                    <select
                                        disabled={x.selectOptionDisabled}
                                        className="inputformmodel sender-domain-selected"
                                        value={x['whiteLableDomain']}
                                        onChange={ev => selectDoamin(ev, x)}>
                                        <option value="">Select</option>
                                        {Data['configuredWhiteLableDomains'].map((y, index) =>
                                            <option value={y.name}
                                                key={index}
                                                disabled={y.isDefault}
                                            >{y.name} 
                                            </option>)}
                                    </select>
                                </div>
                                <div><button type="button" disabled={x['isDefault']} onClick={() => deleteSenderName(x)} className="link inline-block grid_btn_1 noborder nobg -delete-link">Delete</button></div>
                            </div>
                        </div>)}
                    </div>
                    <div className="formmodel-bx padding20 nomargin text-right">
                        <button type="button" disabled={(!senderNameContext.buttonDisabled && !senderNameContext.isSelectDomain?false:true)} onClick={newFieldAdd} className="grid_btn_1 btn-gape-15 margin-right15 addnewSenderEmail">Add</button>
                        <button type="button" disabled={(!senderNameContext.buttonDisabled && !senderNameContext.isSelectDomain?false:true)} onClick={senderNameSave} className="grid_btn_1 grid_btn_active btn-gape-15 _save_sender_email">Save</button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default SenderNameEmail