import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Client from '../../../../GlobalAPICall';
import { useParams } from 'react-router';

const TrafficeSource = () => {
    const { APITOKEN } = useSelector(state => state);
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 });
    const { id } = useParams();
    const [landingPageKPI, setLandingPageKPI] = useState([]);
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'area'
        },
        title: {
            text: 'Landing Page performance'
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            accessibility: {
                rangeDescription: ''
            },

            labels: {
                formatter: function () {
                    const value = this.value;
                    var s = value.split('-');
                    return s[2]
                },

            }
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {

                colorByPoint: true,
                name: "Landing Page Traffic",
                pointWidth: 21,
            }
        ]
    });
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/distribution/${id}/landing_page_performance`)
                .then(({ data }) => {
                    setLandingPageKPI(data || []);
                    dataFilter(data);
                }).catch(err => {
                    throw err;
                });
    }, [APITOKEN, id]);

    const dataFilter = (data) => {
        const ar = [];
        const landingGraphArray = [];
        data.forEach(function (v, i) {
            const $d = JSON.parse(v['PerformanceKPI']);
            for (let x of $d) {
                ar.push(x['Count']);
            }
            landingGraphArray.push({
                name: v['Name'],
                id: v['Id'],
                data: ar,
                lineWidth: 2,
                linkedTo: 'q',
                color: '#a2cef3',
                fillOpacity: 0.3,
                zIndex: 1,

                marker: {
                    enabled: false
                }
            })
        });
        setOptionValue({
            series: [{ data: landingGraphArray }], xAxis: {
                categories: ar,
            }
        });
    }


    return (
        <>
            {landingPageKPI.length > 0 ?
                <div className=" margin-top50 bx-boder-common white"><div className="chartharf  width100" >
                    <h3 className="_chart_title padding-top20 padding20">Landing Page Traffic Sources</h3>
                    <div className="b_top padding20">
                        <HighchartsReact highcharts={Highcharts} options={optionData} />
                    </div>
                </div>
                </div>
                : <></>}
        </>
    )
}
export default TrafficeSource;