import React, { useEffect, useState, useCallback } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Datepicker } from "@mobiscroll/react";
import moment from "moment";

import { getCRMActivityLogs, getActivityCRMContent, getActivityCRMName, getActivityCRMDistributionName, getActivityCRMEvent, getActivityCRMObject, getActivityCRMResponsible } from "../../../../../services/crm";

const Activity = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const peopleSubscriberId = queryParams.get('pId');
	const [CRMResponsible, setCRMResponsible] = useState([]);
	const [CRMContent, setCRMContent] = useState([]);
	const [CRMName, setCRMName] = useState([]);
	const [CRMDistributionName, setCRMDistributionName] = useState([]);
	const [CRMEvent, setCRMEvent] = useState([]);
	const [CRMObject, setCRMObject] = useState([]);
	const [CRMActivityLogs, setCRMActivityLogs] = useState([]);
	const [selectedTimeDuration, setSelectedTimeDuration] = useState(7);
	const { APITOKEN } = useSelector(state => state);

	//let defaultPayload = { locale: "", content: "All", contentName: "All", DistributionName: "All", event: "All", object: "All", responsible: "All", }
	let defaultPayload = { locale: "", content: "All", contentName: "All", DistributionName: "All", event: "All", object: "All", responsible: "All", };
	const [activityLogPayload, setActivityLogPayload] = useState(defaultPayload);
	const [dateFilterValue, setDateFilterValue] = useState([]);

	const fetchResponsible = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMResponsible = await getActivityCRMResponsible(peopleSubscriberId, commonPayload);
				setCRMResponsible(_get(respCRMResponsible, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	const fetchCRMContent = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMContent = await getActivityCRMContent(peopleSubscriberId, commonPayload);
				setCRMContent(_get(respCRMContent, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	const fetchCRMName = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMName = await getActivityCRMName(peopleSubscriberId, commonPayload);
				setCRMName(_get(respCRMName, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	const fetchCRMDistributionName = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMDistributionName = await getActivityCRMDistributionName(peopleSubscriberId, commonPayload);
				setCRMDistributionName(_get(respCRMDistributionName, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	const fetchCRMEvent = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMEvent = await getActivityCRMEvent(peopleSubscriberId, commonPayload);
				setCRMEvent(_get(respCRMEvent, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	const fetchCRMObject = useCallback((commonPayload) => {
		(async () => {
			try {

				const respCRMObject = await getActivityCRMObject(peopleSubscriberId, commonPayload);
				setCRMObject(_get(respCRMObject, "data", []));
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId]);

	useEffect(() => {
		(async () => {
			try {
				const currentData = new Date();
				let previousDate = new Date(currentData.getTime() - 4000 * 24 * 60 * 60 * 1000);
				if (selectedTimeDuration === 7) {
					previousDate = new Date(currentData.getTime() - 7 * 24 * 60 * 60 * 1000);
				} else if (selectedTimeDuration === 90) {
					previousDate = new Date(currentData.getTime() - 90 * 24 * 60 * 60 * 1000);
				}

				const commonPayload = {
					...activityLogPayload,
					fromDate: (!_isEmpty(dateFilterValue)) ? moment(dateFilterValue[0]).format('YYYY-MM-DD') : moment(previousDate).format('YYYY-MM-DD'),
					toDate: (!_isEmpty(dateFilterValue)) ? moment((dateFilterValue[1])).format('YYYY-MM-DD') : moment(currentData).format('YYYY-MM-DD'),
				}

				fetchResponsible(commonPayload);
				fetchCRMContent(commonPayload);
				fetchCRMName(commonPayload);
				fetchCRMDistributionName(commonPayload);
				fetchCRMEvent(commonPayload);
				fetchCRMObject(commonPayload);

				let payload = { offset: 0, limit: 20 };
				const respCRMActivityLogs = await getCRMActivityLogs(peopleSubscriberId, payload, commonPayload);
				//setActivityLogPayload({ locale: "", content: "All", contentName: "All", DistributionName: "All", event: "All", object: "All", responsible: "All", });
				let logMonths = [];
				_get(respCRMActivityLogs, "data", []).forEach(element => {
					if (_get(element, "distributionName", "") === "") {
						element = { ...element, distributionName: "N/A" };
					}

					logMonths[_get(element, "month", "")] = {
						month: _get(element, "month", ""),
						year: _get(element, "year", ""),
						monthName: _get(element, "monthName", "")
					};
				});

				let tmpActivityLogs = [];
				Object.keys(logMonths || []).reverse().forEach(element => {
					const data = _get(respCRMActivityLogs, "data", []).filter(log => _get(log, "month", "") === parseInt(element));

					tmpActivityLogs.push({ year: _get(logMonths, `[${element}]['year']`, ""), month: _get(logMonths, `[${element}]['monthName']`), data });
				});

				setCRMActivityLogs(tmpActivityLogs);

			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [peopleSubscriberId, selectedTimeDuration, activityLogPayload, dateFilterValue, fetchResponsible, fetchCRMContent, fetchCRMName, fetchCRMDistributionName, fetchCRMEvent, fetchCRMObject]);

	return (
		<div>
			<div className="bx-boder-common nobg my-13">
				<div className="_h_header white border-radius2">
					<h3>Filters</h3>
				</div>

				<div className="white">
					<div className="row">
						<div className="col-12">
							<div className="flex p-5  mbsc-align-items-center gap-6">
								<button className={`btn ${((selectedTimeDuration || 7) === 7) ? "btn-sky" : "btn-light"}`} type="button" onClick={() => { setDateFilterValue([]); setSelectedTimeDuration(7); }}>Last 7 days</button>
								<button className={`btn ${((selectedTimeDuration || 7) === 90) ? "btn-sky" : "btn-light"}`} type="button" onClick={() => { setDateFilterValue([]); setSelectedTimeDuration(90); }}>Last 90 days</button>
								<button className={`btn ${((selectedTimeDuration || 7) === 365) ? "btn-sky" : "btn-light"}`} type="button" onClick={() => { setDateFilterValue([]); setSelectedTimeDuration(365); }}>All Time</button>
								<Datepicker className="inputformmodel "
									value={(dateFilterValue || "")}
									select="range"
									//onChange={e => setDateFilterValue(_get(e, "value", ""))}
									onClose={e => { setSelectedTimeDuration(1); setDateFilterValue(_get(e, "value", "")); }}
									dataTimezone='utc'
									dateFormat="YYYY-MM-DD"
									locale={APITOKEN.config.countryCode}
									controls={['calendar']}
									placeholder="Choose a date"
									touchUi={true}
									theme="ios"
									themeVariant="dark"

								/>
							</div>
						</div>
					</div>
					<div className="p-5">
						<div className="row">
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Responsible</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "responsible", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, responsible: _get(e, "target.value", "") })}
									>
										{((CRMResponsible || []).length > 0) && (CRMResponsible || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Content</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "content", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, content: _get(e, "target.value", "") })}
									>
										{((CRMContent || []).length > 0) && (CRMContent || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Name</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "contentName", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, contentName: _get(e, "target.value", "") })}
									>
										{((CRMName || []).length > 0) && (CRMName || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Distribution List</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "DistributionName", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, DistributionName: _get(e, "target.value", "") })}
									>
										{((CRMDistributionName || []).length > 0) && (CRMDistributionName || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Event</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "event", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, event: _get(e, "target.value", "") })}
									>
										{((CRMEvent || []).length > 0) && (CRMEvent || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
							<div className="col-2">
								<div className="mb-6">
									<label className="_l_able_cls">Object</label>
									<select
										className="inputformmodel width100"
										value={_get(activityLogPayload, "object", "All")}
										onChange={(e) => setActivityLogPayload({ ...activityLogPayload, object: _get(e, "target.value", "") })}
									>
										{((CRMObject || []).length > 0) && (CRMObject || []).map((row, key) => {
											return (
												<option key={key} value={_get(row, "key", "")}>{_get(row, "value", "")}</option>
											)
										})}
									</select>
								</div>
							</div>
						</div>
					</div>

					{((CRMActivityLogs || []).length > 0) && (CRMActivityLogs || []).map((activitiLogs, key) => {
						return (
							<div className="row">
								<div className="col-12">
									<h2 className="h2_year p-5">{_get(activitiLogs, "year", "")}</h2>
									<h3 className="h3_month b_top b_bottom p-5">{_get(activitiLogs, "month", "")}</h3>

									<div className="dataTable-primary">
										<table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
											<thead>
												<tr>
													<th className="table_cell_header pt-3">Date Time</th>
													<th className="table_cell_header pt-3">Content</th>
													<th className="table_cell_header pt-3">Name</th>
													{(_get(activityLogPayload, "content", "") !== "CRM") && (
														<th className="table_cell_header pt-3">Distribution List</th>
													)}
													<th className="table_cell_header pt-3">Event</th>
													<th className="table_cell_header pt-3">Object</th>
													<th className="table_cell_header pt-3">Value</th>
													<th className="table_cell_header pt-3">Point</th>
													<th className="table_cell_header pt-3">Details</th>
												</tr>
											</thead>
											<tbody>
												{(_get(activitiLogs, "data", []).length > 0) && _get(activitiLogs, "data", []).map((row, key) => {
													return (
														<tr key={key}>
															<td className="body_table_cell">{_get(row, "datetime", "")}</td>
															<td className="body_table_cell">{_get(row, "content", "")}</td>
															<td className="body_table_cell">{_get(row, "name", "")}</td>
															{(_get(activityLogPayload, "content", "") !== "CRM") && (
																<td className="body_table_cell">{_get(row, "distributionName", "")}</td>
															)}
															<td className="body_table_cell">{_get(row, "event", "")}</td>
															<td className="body_table_cell">{_get(row, "object", "")}</td>
															<td className="body_table_cell">{_get(row, "value", "")}</td>
															<td className="body_table_cell">{_get(row, "point", "")}</td>
															{(_get(activityLogPayload, "event", "") !== "Delivered") && (_get(activityLogPayload, "event", "") !== "Survey") && (
																<td className="body_table_cell">
																	{_get(row, "details", "")}
																</td>
															)}
														</tr>
													)
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</div>
	)
}

export default Activity;