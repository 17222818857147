import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import axios from 'axios';

export const TemplateCampaign = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const { Value, ChangeEvent, setTemplate, smsTemplateData, setFilterCampaign, CampaignId, isRequired = false } = props;
    const campaignObjectFilter = (data, CampaignId) => {
        return data.filter(x => x.Id === CampaignId)[0];
    }
    // Get All list from API Call
    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        (APITOKEN.token !== "") &&
            Client.get(`Lookup/contentDetails/${Value.smsContent}`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setTemplate(data);
                    if (CampaignId !== null && !!CampaignId) {
                        const getFilterObject = campaignObjectFilter(data, CampaignId);
                        // This condition for redirect case for all Campaign Type //
                        setFilterCampaign(!getFilterObject ? prv => ({ ...prv }) : getFilterObject);
                    }

                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    console.error(err);
                }
            });
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN, Value.smsContent, setTemplate, CampaignId, setFilterCampaign])
    useEffect(() => {
        load()
    }, [load])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">{Value.smsContent === '4' ? 'Survey' : 'Landing Page'} {(isRequired === true) ? <span className="error-message">*</span> : ""}</label>
            <select className="inputformmodel"
                value={Value.campaign.Id}
                onChange={e =>
                    ChangeEvent(e.target.value !== "" ? smsTemplateData.filter(item => item.Id === e.target.value) : [])}
                style={{
                    border: Value.campaign.HasWaterMark ? '1px solid red' : '1px solid rgba(36,28,21,0.3)'
                }}>
                <option value="">Select</option>
                {smsTemplateData.map((v, i) => !v.IsDraft && <option key={i} value={v.Id}>{v.Name}</option>)}
            </select>
        </div>
    )

}

