import { useNavigate } from "react-router";
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import { useState } from "react";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";

const LandingPageDomain = ({ ...props }) => {
  const { setSelectedDoaminLabel, domainLabel } = props;

  const { APITOKEN } = useSelector(state => state);
  const [formData, setFormData] = useState({});
  const [apiResponse, setAPIResponse] = useState({ data: {} });
  const navigate = useNavigate();
  const [isloader, setLoader] = useState(false);
  const saveDomain = () => {
    const obj = {
      "domainName": formData.domainName,
      "domainType": domainLabel.id,
      "subDomainName": formData.subDomainName,
      "description": formData.description === undefined ? "" : formData.description
    };
    setLoader(true);

    Client.post(`Domain/WhiteLabel/Configure/Domain/setup`, obj, {
      headers: {
        Authorization: `Bearer ` + APITOKEN['token']
      }
    })
      .then(res => {
        try {
          setLoader(false);
          if (res.data.statusCode === 200) {
            navigate('/configuration?_id=39')
          } else {
            setAPIResponse(prv => ({
              ...prv,
              data: res.data
            }));
          }
        } catch (err) {
          console.error(err);
        }
      }).catch(err => {
        console.error(err);
      })
  }
  return (
    <div className="domain__body padding20">
      <p>After you have white labeled your domain your new created landing pages can use this domain as landing page URL. We recomend adding a sub domain in front of your main domain for Landing Page Hosting.</p>
      <div className="margin-top50">
        <div className="domain__half">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Sub Domain
            </label>
            <input type="text" className="inputformmodel width100" placeholder="Campaign" onChange={e => setFormData(prv => ({ ...prv, subDomainName: e.target.value }))}  />
          </div>
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Your Domain
            </label>
            <input type="text" className="inputformmodel width100" placeholder="yourdomain.com" onChange={e => setFormData(prv => ({ ...prv, domainName: e.target.value }))} />
            <p className='validation'>{apiResponse.data.result}</p>
          </div>
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Description
            </label>
            <textarea type="text" className="inputformmodel textareaModel width100" placeholder="Give an optional description of the domain purpose." onChange={e => setFormData(prv => ({ ...prv, description: e.target.value }))} ></textarea>
          </div>
        </div>
        <div className="domain__half fright">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Preview
            </label>
            <div className="previewImg configDomain" style={{ height: `268px` }}>
              <img src="https://app-dev.promoti.ai/pages/admin/configuration/DomainSetUp/landingpageimg.png" style={{ maxWidth: '100%' }} alt="" />
              
              <div className="lslug">
                <span className="lpu">
                  <b className="__camp">{(formData.subDomainName !== undefined && formData.subDomainName !== "") ? formData.subDomainName : 'campaign'}</b>.
                  <b className="__yodomain">{(formData.domainName !== undefined && formData.domainName !== "") ? formData.domainName : 'yourdomain.com'}</b>/slug</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-block width100  text-right">
        <button className="grid_btn_1 nobg noborder vertical-middle" onClick={() => setSelectedDoaminLabel({ id: "" })} type="button">Cancel</button>
        <button type="button" className="grid_btn_1 grid_btn_active" onClick={saveDomain} disabled={
          (formData.domainName !== undefined && formData.domainName !== ""  && formData.subDomainName !== undefined && formData.subDomainName !== "" ) ? false : true
        }>
        <ButtonLoader IsLoader={isloader}>Continue</ButtonLoader>  
        </button>
      </div>
    </div>
  )

}
export default LandingPageDomain;