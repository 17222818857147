import React, { useEffect, useState } from 'react'
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';

import BreadCrum from "../../../ReuseTemplate/BreadCrum";
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';

import { createList, getLookupCategoryList } from "../../../services/crm";

const CreateList = () => {

  const [isLoader, setIsLoader] = useState(false);
  const [formData, setFormData] = useState({ CategoryTypeId: "", Name: "", Description: "" });
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {

    fetchLookupCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchLookupCategoryList = () => {
    (async () => {
      try {
        const response = await getLookupCategoryList();

        if ((_get(response, "flag", false) === true) && !_isEmpty(_get(response, "data.data", []))) {
          setFormData({ ...formData, CategoryTypeId: _get(response, `data.data[0].Id`, "") });
        }
      } catch (err) {
        console.log("Error: ", err)
      }
    })();
  }


  const onSubmitForm = () => {
    (async () => {
      try {
        setIsLoader(true);
        const response = await createList(formData);
        setIsLoader(false);

        if (_get(response, "flag", false) === true) {

          navigate(`/crm/people/subscribers/${_get(response, "data.data.RowUID.ROWUID", false)}/1/create`);
        } else {

          setErrorMessage(_get(response, "data.message", false));
        }
      } catch (err) {
      }
    })();
  }

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum ToText="CRM List" ToSecondPage="" ToSecondPageText="" CurrentPage="Create List" />
        </div>
      </div>
      <div className="dashbd_sec margin-top0">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Create list</h3>
          </div>
          <div className="bx-pad">
            <div className="formmodel-bx">
              <label className="_l_able_cls">Name</label>
              <input
                type="text"
                className="inputformmodel width100"
                value={_get(formData, "Name", "")}
                onChange={(e) => setFormData({ ...formData, Name: _get(e, "target.value", "") })}
              />
            </div>

            <div className="formmodel-bx">
              <label className="_l_able_cls">Description</label>
              <textarea
                id="_createDes"
                className="inputformmodel width100 textareaModel excludeclassName"
                value={_get(formData, "Description", "")}
                onChange={(e) => setFormData({ ...formData, Description: _get(e, "target.value", "") })}
              />
            </div>

            <div className="formmodel-bx text-right nomargin">
              <Link to="/crm/people/listings" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { }} >Cancel</Link>
              <button
                type="button"
                className="btn btn-primary btn-gape-15"
                disabled={(_isEmpty(_get(formData, "Name", ""))) ? "disabled" : ""}
                onClick={() => onSubmitForm()}>
                <ButtonLoader IsLoader={isLoader}>
                  Continue
                </ButtonLoader>
              </button>

            </div>

            <div><p className="error-message">{errorMessage}</p></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateList;