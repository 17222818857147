import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Client from "../../GlobalAPICall";

const APIDoc = () => {
  const { APITOKEN } = useSelector((state) => state);
  const linkRef = useRef(null);
  const copyRef = useRef("");
  const copy = (copy) => {
    /* Select the text field */
    copy.current.select();
    copy.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };
  useEffect(() => {
    APITOKEN["token"] &&
      Client.get(
        `Lookup/ApiDocumentation?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
        {
          headers: {
            Authorization: `Bearer ${APITOKEN["token"]}`,
          },
        }
      ).then(async (res) => {
        try {
          //setAPIData(res['data']);
          linkRef.current.href = res["data"].DocUrl;
          copyRef.current.value = res["data"].UserToken;
        } catch (err) {
          console.log(err);
        }
      });
  }, [APITOKEN]);
  return (
    <>
      <div className=" bx-boder-common width100  margin-bottom white">
        <div className="_h_header">
          <h3>API Documentation</h3>
        </div>
        <div className="padding20">
          <p>
            Click on button to see our API documentation. The documentation will
            open in a new session in your browser.
          </p>
          <p className="margin-top50">
            <Link
              target="_blank"
              ref={linkRef}
              className="grid_btn_1 grid_btn_activeDrak apilinkDoc"
            >
              API Documentation
            </Link>
          </p>
        </div>
      </div>
      <div className="sms_Sec_1 bx-boder-common width100  nomargin">
        <div className="_h_header">
          <h3>Private Token</h3>
        </div>
        <div className="padding20">
          <div className="margin-">
            <div style={{ width: "60%", position: "relative" }}>
              <input
                type="text"
                className="private_token inputformmodel margin-right15"
                ref={copyRef}
                readOnly
              />
              <button
                type="button"
                className="grid_btn_1 nobg noborder -copy"
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  height: "35px",
                  color: "#666",
                }}
                onClick={() => copy(copyRef)}
              >
                <i className="fas fa-copy"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default APIDoc;
