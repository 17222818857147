
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import moment from 'moment';
import getLocaleDateString from '../../TimeFormat';
import BlankData from "../../BlankData";

const ShareContent = ({ Id }) => {
    const {APITOKEN} = useSelector(state => state);
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const [data, setData] = useState([]);
    const [toggleValue, setToggle] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/sharecontentrequests/${Id}/campaign`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setData(res['data']);
                } catch (err) {
                    console.error(err);
                }

            }).catch(err=> console.error(err));
    }, [APITOKEN, Id])

   
    const sharcopy = (v) => {
        setToggle(true)
        setDeleteId(v.id);
    }
    const deleteConfirm = () => {
        Client.get(`Campaign/sharecontentrequest/${deleteId}/takeaction/CancelSharing`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.Success) {
                        setToggle(false);
                        load();
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err=> console.error(err))
    }
    useEffect(() => {
        load()
    }, [load]);
    return (
        <>
            {toggleValue ? <div className="bx-boder-common white">
                <div className="_h_header">
                    <h3>Share</h3>
                </div>
                <table cellPadding="0" cellSpacing="0" width="100%">
                    <tbody>
                        <BlankData colSpan="5" title="Do you want to Cancel sharing the template?" content="" />
                    </tbody>
                </table>
                <div className="text-right padding20">
                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={() => setToggle(false)}>Cancel</button>
                <button type="button" className="grid_btn_1  deleteBtn  _confirm" onClick={deleteConfirm}>Confirm</button>
                </div>
            </div> :
                <div className=" bx-boder-common width100 white">
                    <div className="_h_header">
                        <h3>Shared Content</h3>
                    </div>
                    <table cellPadding="0" cellSpacing="" width="100%" className="smstable white">
                        {data.length > 0 &&
                            <thead>
                                <tr>
                                    <th className="table_cell_header">Name</th>
                                    <th className="table_cell_header">Content</th>
                                    <th className="table_cell_header">Share Date/Time</th>
                                    <th className="table_cell_header">To</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {data.length > 0 ?
                                data.map(($v, i) =>
                                    <tr key={i}>
                                        <td className="body_table_cell">{$v['name']}</td>
                                        <td className="body_table_cell">{$v['content']}</td>
                                        <td className="body_table_cell">{moment($v['createdDateTime']).format(f)}</td>
                                        <td className="body_table_cell">{$v['email']}</td>
                                        <td className="body_table_cell">{($v['actionType'] !== 0) ? $v['actionType'] : <button type="button" className="inline-block link nobg noborder nopadding" onClick={() => sharcopy($v)}>Cancel Sharing</button>}</td>
                                    </tr>
                                ) : <BlankData title="You have not created any Landing Page yet" content="With a template you are able to configure and change your Landing Page without involving your technical department." colSpan="6" />}
                        </tbody>
                    </table>
                </div>
            }
        </>


    )

}

export default ShareContent
