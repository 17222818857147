import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import WedgetGrid from "./WedgetGrid";

const Engagment = ({tabId}) => {
    const { id } = useParams();
    const checkIsAuth = useSelector(state => state);
    const [wedgetData, setWedgetGridData] = useState([])

    const loadApiCall = useCallback(async () => {
        try {
            (checkIsAuth['APITOKEN']['token'] !== "") &&
                Client.get(`Insights/distribution/Email/${id}/KPI?tabId=${tabId}&widgetstats=true`, {
                    headers: {
                        'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                    }
                }).then((res) => {
                    try {
                        setWedgetGridData(res['data']['KPI'])
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [checkIsAuth, id,tabId]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            <div className="overviewtable bx-boder-common white"><div className="_h_header" style={{ justifyContent: "space-between" }}><h3>Widget Clicks</h3><span className="fright font-16 goal_duration" style={{ display: "none" }}>Goal Duration:&nbsp;<span className="goalMeasureEndDate"></span></span></div><div className="widgetContainer"><WedgetGrid Data={wedgetData} tabId={tabId} /></div></div>
        </>
    )
}

export default Engagment
