import { RecipientsPanel } from "../RecipientsPanel";
import './index.css';

const DistributionMobile = () => {
    return (
        <RecipientsPanel></RecipientsPanel>
    )
}
export default DistributionMobile;

