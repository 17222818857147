import React from 'react';

const SliderFeedback = ({ value , onClose }) => {
    return (
        <div className="chargify-js-wrapper">
            <div className="topn-header">
                <span className="selected-title">
                    Details
                </span>
                <span style={{ padding: '20px', float: 'right' }} onClick={()=> 
                    onClose(prv => ({
                        ...prv,
                        display: false
                    }))}>
                    <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                </span>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <thead>
                    <tr>
                        <th className="table_cell_header">First Name</th>
                        <th className="table_cell_header">Last Name</th>
                        <th className="table_cell_header">Email</th>
                        <th className="table_cell_header">Mobile Number</th>
                        <th className="table_cell_header">Country</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className="body_table_cell _feedback__firstname">{value.firstname}</td>
                        <td className="body_table_cell _feedback__lastname">{value.lastName}</td>
                        <td className="body_table_cell _email_feedback">{value.email}</td>
                        <td className="body_table_cell feedback__mobilenumber">{value.mobileNumber}</td>
                        <td className="body_table_cell _feedback__country">{value.country}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default SliderFeedback;