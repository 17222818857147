// import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import { Link } from "react-router-dom";
import { ChromePicker } from "react-color";
import { client } from "filestack-react";

import _ from "lodash";
import { setLogoFn } from "../Redux/Action";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";
const DesignSetting = ({ ...props }) => {
  const { data } = props;
  const filterVal = data[0];
  const { APITOKEN, Logo } = useSelector((state) => state);
  const dispach = useDispatch();
  const dataParse = data.length > 0 && JSON.parse(filterVal.DefaultJsonInfo);
  const [validationCheck, setValidation] = useState(true);
  const [isloader, setLoader] = useState(false);
  const url = process.env;
  const [count, setCount] = useState(0);
  let index = null;
  if (data.length > 0) {
    index = _.findIndex(props.tabData, function (o) {
      return o.MenuId === filterVal.MenuId;
    });
  }
  const [defaultData, setDefault] = useState({
    fontColor: [],
    fontSizeJson: [],
    fontTextJson: [],
    defaultFontText: "",
    defaultFontSize: "",
    CompanyLogo: "",
  });
  const setLoaded = useCallback(() => {
    APITOKEN["token"] &&
      Client.get(`Lookup/DefaultSettings/GetDefaultSettingsDetails`, {
        headers: {
          Authorization: `Bearer ${APITOKEN["token"]}`,
        },
      }).then(async (res) => {
        try {
          const { FontColor, FontSizeJson, FontTextJson, CompanyLogo } =
            res["data"][0];
          setDefault({
            fontColor: JSON.parse(FontColor),
            fontSizeJson: JSON.parse(FontSizeJson),
            fontTextJson: JSON.parse(FontTextJson),
            defaultFontText: (function () {
              return JSON.parse(FontTextJson)[0].DefaultText;
            })(),
            defaultFontSize: (function () {
              return JSON.parse(FontSizeJson)[0].DefaultSize;
            })(),
            CompanyLogo,
          });
        } catch (err) {
          console.log(err);
        }
      });
  }, [APITOKEN]);

  const handleClick = (v) => {
    for (var x of defaultData.fontColor) {
      x["show"] = false;
    }
    const filter = defaultData.fontColor.filter((x) => x.Id === v["Id"]);
    filter[0].show = !!filter[0].show ? false : true;
    filter[0].DefaultColor = !!filter[0].DefaultColor
      ? v["DefaultColor"]
      : v["DefaultColor"];
    setDefault((prv) => ({
      ...prv,
      fontColor: defaultData.fontColor,
      ...filter,
    }));
  };
  const handleChangeComplete = (v, color) => {
    const index = _.findIndex(defaultData.fontColor, function (x) {
      return x.Id === v["Id"];
    });
    defaultData.fontColor[index].DefaultColor = color.hex;
    setDefault((prv) => ({ ...prv, fontColor: defaultData.fontColor }));
  };
  const pickerClose = () => {
    for (var x of defaultData.fontColor) {
      x["show"] = false;
    }
    setDefault((prv) => ({ ...prv, fontColor: defaultData.fontColor }));
  };

  //=====  Save default Setting  =====//

  const onboardingSav = ({ isCompleted, stepId, isSkipped }, flag) => {
    setLoader(isSkipped ? false : true);
    let obj = {
      language: "",
      locale: "",
      stepId: stepId,
      subDomains: "",
      timezone: "",
      color: "",
      companyLogo: defaultData.CompanyLogo,
      isNewsLetterAccepted: false,
      isCompleted: isCompleted,
      isSkipped: isSkipped,
    };
    const filterFontText = defaultData.fontTextJson.filter(
      (x) => x.FontText === defaultData.defaultFontText
    );
    if (flag === 0) {
      obj = {
        ...obj,
        fontId: 0,
      };
    } else {
      obj = {
        ...obj,
        fontId: filterFontText[0].fontId.toString(),
        fontSize: defaultData.defaultFontSize.toString(),
        color: JSON.stringify(defaultData.fontColor),
      };
    }
    Client.post(
      `Lookup/OnBoardingSteps/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/SaveSteps`,
      obj,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    ).then((res) => {
      setLoader(false);
      try {
        props.Click(
          props.tabData[index + 1] === undefined
            ? props.tabData[0].MenuId
            : props.tabData[index + 1].MenuId
        );
      } catch (err) {
        console.log(err);
      }
    });
  };
  const fileUPloadByFilePicker = useCallback(() => {
    setTimeout(() => {
      const clients = client.init(url.REACT_APP_FILE_PICKER_API_KEY);
      const options1 = {
        container: ".company_logo",
        displayMode: "dropPane",
        maxFiles: 1,
        dropPane: {
          overlay: true, // if this is true then overlays will conflict between instances
          onSuccess: (res) => {
            setDefault((prv) => ({
              ...prv,
              CompanyLogo: res[0].url,
            }));
            dispach(setLogoFn(res[0].url));
          },
        },
      };
      clients.picker(options1).open();
    }, 800);
    setCount(1);
  }, [url.REACT_APP_FILE_PICKER_API_KEY, dispach]);

  useEffect(() => {
    setLoaded();
    count === 0 && fileUPloadByFilePicker();
  }, [setLoaded, count, fileUPloadByFilePicker]);
  return (
    <>
      {dataParse ? (
        <>
          <div>
            <h2>
              {filterVal.Name}
              <label
                style={{ paddingTop: "5px" }}
                className="fright vertical-middle sms_content"
              >
                Duration: {dataParse.Duration}
              </label>
            </h2>
            <p>{dataParse && dataParse.HeaderDescription}</p>
          </div>
          <div className="config__bx ">
            <div className="formmodel-bx sx-mobile">
              <label htmlFor="Company_Logo" className="_l_able_cls">
                Company Logo
              </label>
              {defaultData.CompanyLogo !== "" && (
                <div className="logouploaded">
                  <img src={Logo} alt="" width="150" />
                </div>
              )}
              <div
                id="fs-panel"
                className="company_logo"
                style={{
                  display: defaultData.CompanyLogo === "" ? "block" : "none",
                }}
              ></div>
            </div>

            <div
              className="fontsection"
              style={{ gridTemplateColumns: "45% 45% auto" }}
            >
              <div className="formmodel-bx">
                <label className="_l_able_cls">
                  <strong>Select Text Font</strong>
                </label>
                <select
                  className="inputformmodel"
                  value={defaultData.defaultFontText}
                  onChange={(e) => {
                    setDefault((prv) => ({
                      ...prv,
                      defaultFontText: e.target.value,
                    }));
                    setValidation(false);
                  }}
                >
                  <option value="">Select</option>
                  {defaultData.fontTextJson &&
                    defaultData.fontTextJson.map((v, i) => (
                      <option key={i} value={v.FontText}>
                        {v["FontText"]}
                      </option>
                    ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">
                  <strong>Select Text Size</strong>
                </label>
                <select
                  className="inputformmodel"
                  value={defaultData.defaultFontSize}
                  onChange={(e) => {
                    setDefault((prv) => ({
                      ...prv,
                      defaultFontSize: e.target.value,
                    }));
                    setValidation(false);
                  }}
                >
                  <option value="">Select</option>
                  {defaultData.fontSizeJson &&
                    defaultData.fontSizeJson.map((v, i) => (
                      <option key={i} value={v.FontSize}>
                        {v["FontSize"]}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <p className="">
            If you can't find your font you can add it later in the{" "}
            <Link to="/configuration" className="link inline-block">
              Configuration
            </Link>{" "}
            Menu.
          </p>
          <div className="config__bx fontsection">
            <div className="formmodel-bx">
              <label className="_l_able_cls">
                <strong>Colors</strong>
              </label>
              <div className="__default__text__fontColor onboarding_bx ">
                {defaultData.fontColor &&
                  defaultData.fontColor.map((v, i) => (
                    <div key={i} className="__color_bx">
                      <p>{v["Name"]}</p>
                      <span style={{ background: v["DefaultColor"] }}></span>
                      <div
                        onClick={() => handleClick(v)}
                        className="inputformmodel picker"
                      >
                        {v["DefaultColor"]}{" "}
                      </div>
                      {v.show ? (
                        <>
                          <div
                            className="colorPickerContainer"
                            onClick={pickerClose}
                          ></div>
                          <div className="colorPicker">
                            <ChromePicker
                              color={v.DefaultColor}
                              onChangeComplete={(e) => {
                                handleChangeComplete(v, e);
                                setValidation(false);
                              }}
                            />
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="formmodel-bx text-right margin-top50">
            <button
              type="button"
              className="nobg noborder grid_btn_1"
              onClick={() =>
                onboardingSav(
                  {
                    isCompleted: false,
                    isSkipped: true,
                    stepId: filterVal.MenuId,
                  },
                  0
                )
              }
            >
              Skip
            </button>
            <button
              type="button"
              className="grid_btn_1 grid_btn_active __Save_link"
              disabled={validationCheck}
              onClick={() => {
                onboardingSav(
                  {
                    isCompleted: true,
                    isSkipped: false,
                    stepId: filterVal.MenuId,
                  },
                  1
                );
              }}
            >
              <ButtonLoader IsLoader={isloader}>Done</ButtonLoader>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default DesignSetting;
