
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import Client from '../GlobalAPICall';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { forEach } from 'lodash';

const Trend = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataTrend, setdataTrend] = useState({});
    const [showChart, setChartShow] = useState(false);
    const { ListObject } = props;
    const calculationGrowthCount = useCallback((response) => {
        const _o = JSON.parse(response);
        let dynamicPopoleLists = [];
        for (var x = 0; x < _o.length; x++) {
            var nextobj = _o[x - 1];
            if (nextobj !== undefined) {
                _o[x].Count += parseInt(nextobj['Count']);
                dynamicPopoleLists.push(_o[x]);
            } else {
                dynamicPopoleLists.push(_o[x]);
                _o[x].Count = parseInt(_o[x].Count);
            }
        };
        if (dynamicPopoleLists.length === 0) {
            return false;
        }
        const obj = {}
        dynamicPopoleLists.forEach(async function (v, i) {
            for (var pro in v) {
                if (typeof (obj[pro]) == "undefined") obj[pro] = [];
                obj[pro].push(v.Count);
            }
        });
        obj['Count'].forEach((v, i) => {
            obj.Count[i] = Math.abs(v);
        });

        const heigtChartObject = {
            chart: {
                type: 'area',
                backgroundColor: '#fff'
            },
            title: {
                text: ' '
            },
            subtitle: {
                text: ' '
            },
            yAxis: {
                title: {
                    text: ' '
                }
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{
                    dashStyle: 'dash',
                    width: 1,
                    color: '#d33'
                }],
                lineColor: "#52626f",
                categories: dynamicPopoleLists,
                labels: {
                    formatter: function () {
                        let data = this.value
                        data = data.SubscriberCreatedAt;
                        var slit = data.split('-');
                        return slit[2];
                    }
                }
            },
            tooltip: {
                split: true,
                backgroundColor: '#fff',
                borderColor: '#eee',
                borderRadius: 3,
                borderWidth: 1,
                shadow: false,
                useHTML: true,
                xDateFormat: '%Y-%m-%d',
                formatter: function () {
                    var s = [];
                    const self = this;
                    s.push('<table width="100%" cellspacing="0" cellpadding="0"><tr><td style="font-family:arial;background:#fff;color:#000;font-size:15px;padding:5px 0;font-weight:bold;text-align:center">' + this.x.SubscriberCreatedAt + '</td></tr></table>');
                    forEach(this.x, function (point, i) {
                        s.push('<table width="100%"><tr><td style="font-size:12px;padding-bottom:5px;padding-top:5px;font-family:roboto;color:#1b283e;">'+ self.points[0].series.name +'</td><td style="padding-left:10px;text-align:right;"><b style="text-align:center; line-height:20px;min-width:20px;height:20px;color:#000;display:inline-block;font-family:roboto;padding:0 10px;font-size:12px";">' + self.y + '</td></tr></table>');
                    })
                    return s;
                },
                shared: true,
                followTouchMove: true,
                followPointer: true,
                style: {
                    color: '#666',
                    fontWeight: 'normal',

                },

            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                    },
                    cursor: 'pointer',
                }
            },
            series: [{
                name: 'List Trend',
                data: obj['Count'],
                color: '#0177ff'
            }]
        }

        return { ...heigtChartObject }
    }, [])

    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/dashboard/SubscriberListGrowth/${ListObject.id}/Details`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        if (res.data.length > 0) {
                            setChartShow(true);
                            setdataTrend(calculationGrowthCount(res.data[0]['SubscriberGrowthDayWise']));
                        }else{
                            setChartShow(false);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false);
        }
    }, [APITOKEN, ListObject, calculationGrowthCount]); // <-- add any missing dependencies react warns about
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
       <>{showChart ? <HighchartsReact highcharts={Highcharts} options={dataTrend} /> : <p className="font-16" style={{ padding: '18% 0', textAlign: 'center' }}>No Data</p>}</> 
        
        
    )

}

export default Trend;
