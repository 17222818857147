import React, { useState } from 'react'

export default function Tags({ Set, Get }) {
    const [targetInput, setTargetInput] = useState("");
    const handleKeyDown = event => {
        const { key, target } = event;
        if (key === 'Enter') {
            if (target.value !== "") {
                Set(prv => ({...prv,searchTags:[...prv.searchTags, { id: Math.floor(Math.random() * 10000), value: target.value }]}))
                setTargetInput("");
            }
        }

    }
    const deleteTag = item => {
        const deleteTag = Get.searchTags.filter((value) => value.id !== item.id);
        Set(prv => ({...prv,searchTags:deleteTag}))
    }

    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">Tags</label>
            <div className="tags_contianer">
                {Get.searchTags.map((item, index) =>
                    <div className="multiValue" key={index}>
                        <div className="TagItem">{item.value}</div>
                        <button type="button" className="tagItemRemove" onClick={() => deleteTag(item)}><i className="far fa-times"></i></button>
                    </div>
                )}
                <div className="tagInput">
                    <input type="text" className="tagInputField" value={targetInput} placeholder='Tags' onKeyDown={handleKeyDown}
                        onChange={e => setTargetInput(e.target.value)} />
                </div>
            </div>
        </div>
    )
}
