import Tabing from "./Tabing";

const Download = ()=>{
    const data = [{id:0,name:' All Downloads'}]
    return(
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                    <Tabing data={data} />
            </div>
        </>
    )
}
export default Download;