import { Link } from 'react-router-dom';
const Tabing = ({ ...props }) => {
    const { data, Click, Id } = props;
    return (
        <>

            <ul className="onboard__list__ul">
                {data && data.map((item, index) => {
                    return <li key={index} onClick={() => Click(item.MenuId)} ><Link className={item.MenuId === Id ? 'active ' : ''}>
                        <span className="icon">
                            <i className="fas fa-check-circle" style={{ color: item.IsCompleted ? '#3bac69 ' : '#c8ccd0' }}></i>
                        </span>
                        {item.Name}
                        <span className="liright" style={{ display: 'none' }}>
                            <i className="fas fa-caret-right"></i>
                        </span>
                    </Link></li>
                })}
            </ul>
        </>
    )
}

export default Tabing;