import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ShareContent from './ShareContent';
import ShareWithYou from './ShareWithYou';


const Tabing = ({ data }) => {
    const defaultId = 1;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <ShareWithYou Id={tabId} /> : <ShareContent Id={tabId} />
                }
                </div>
            }
        </>
    )
}

export default Tabing;