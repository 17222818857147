import React, { Fragment } from 'react';
import { Link } from "react-router-dom";

const BreadCrum = ({ ...props }) => {
    const { To, CurrentPage, ToText, Callback, ToSecondPage = "", ToSecondPageText = "", CallbackSecondPage, ToThirdPage = "",
        ToThirdPageText = "", CallbackThirdPage, FirstBreadCrumbState = {}, SecondBreadCrumbState = {}, ThirdBreadCrumbState = {}
    } = props;

    return (
        <>
            {((ToSecondPage || "") !== "") && (
                <Fragment>
                    <Link
                        className="link inline-block"
                        onClick={Callback}
                        to={To}
                        state={FirstBreadCrumbState}
                    >
                        {ToText}
                    </Link> &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                    <Link
                        className="link inline-block"
                        onClick={CallbackSecondPage}
                        to={ToSecondPage}
                        state={SecondBreadCrumbState}
                    >
                        {ToSecondPageText}
                    </Link>
                    {((ToThirdPage || "") !== "") && (
                        <Fragment>
                            &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                            <Link
                                className="link inline-block"
                                onClick={CallbackThirdPage}
                                to={ToThirdPage}
                                state={ThirdBreadCrumbState}
                            >
                                {ToThirdPageText}
                            </Link>
                        </Fragment>
                    )}
                    &nbsp; &nbsp; <i className="fas fa-caret-right" /> &nbsp; {` ${CurrentPage}`}
                </Fragment>
            )}
            {((ToSecondPage || "") === "") && (
                <Fragment>
                    <Link
                        className="link inline-block"
                        onClick={Callback}
                        to={To}
                        state={FirstBreadCrumbState}
                    >
                        {ToText}
                    </Link>  {`> ${CurrentPage}`}
                </Fragment>
            )}
        </>
    )
}
export default BreadCrum;