import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import Client from "../GlobalAPICall";
import getParameterByName from "../ReuseTemplate/GetRoute";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";
import BreadCrum from "../ReuseTemplate/BreadCrum";
import { encode as btoa, decode as atob } from 'base-64'
import { TemplateId } from '../ReuseTemplate/TemplateIds';
import Cookies from 'js-cookie'
const CreateContent = () => {
	const { APITOKEN } = useSelector(state => state);
	const [contentPageDetail, setContentPageDetails] = useState({});
	const [contentName, setContentName] = useState({
		name: "",
		disable: true
	});
	const [contentDescriptionValue, setContentDescription] = useState("");
	const [IsLoader, setIsLoader] = useState(false);
	const [folderData, setFolderData] = useState([]);
	const [folderAccessData, setFolderAccessData] = useState({ data: [], selectedAccess: 0 });
	const [optionVal, setOptionVal] = useState('');
	const [apiResponse, setAPIResponse] = useState({});
	const [isFlaseCase, setFalseCase] = useState({});

	const { id } = useParams();
	const navigate = useNavigate();
	// Get All list from API Call
	const getFolder = useCallback(() => {
		const cancelToken = axios.CancelToken.source();
		(APITOKEN['token'] !== "") &&
			Client.get(`Campaign/${id}/folders`).then((res) => {
				try {
					setFolderData(res['data']);
					if (apiResponse.response?.IsFolderCreated) {
						setOptionVal(res.data[1].id)
					}
				} catch (err) {
					console.error(err);
				}
			}).catch(err => {
				if (axios.isCancel(err)) {
					console.log('cancel')
				}
			});
		return () => {
			cancelToken.cancel();
		}
	}, [APITOKEN, id, apiResponse.response])
	const setLoaded = useCallback(() => {
		const cancelToken = axios.CancelToken.source();
		APITOKEN['token'] &&
			Client.get(`Lookup/AccessPermissions`).then((res) => {
				try {
					setFolderAccessData(prevs => ({
						...prevs,
						data: res.data,
						selectedAccess: 1
					}));
				} catch (err) {
					console.log(err);
				}
			}).catch(err => {
				if (axios.isCancel(err)) {
					console.log('cancel')
				}
			});
		return () => {
			cancelToken.cancel();
		}

	}, [APITOKEN])



	const loadPreview = useCallback(() => {
		const cancelToken = axios.CancelToken.source();
		const queryId = getParameterByName('id', window.location.href);
		(APITOKEN['token'] !== "") &&
			Client.get(`Campaign/Preview/${id}/Name${queryId !== null ? `?id=${queryId}` : ''}`).then(({ data }) => {
				try {
					setContentPageDetails(data);
					setFalseCase({});
					if (data.hasOwnProperty('hasFolder')) {
						if (!data.hasFolder) {
							setLoaded();
						} else {
							getFolder();
						}
					} else {
					}
					const fl = TemplateId.some(x => x === id);
					const templateObject = !localStorage.getItem('tem') ? "" : atob(localStorage.getItem('tem')).split('~');
					setContentName({
						name: fl ? data.formattedPreviewName : !localStorage.getItem('tem') ? data.formattedPreviewName : templateObject[0],
						disable: false,
						reactCancelPageUrl: data.reactCancelPageUrl
					});
					setContentDescription(fl ? !data.description ? "" : data.description : !localStorage.getItem('tem') ? !data.description ? "" : data.description : templateObject[1]);
					!fl && localStorage.removeItem('tem');
					setOptionVal(data.folderId === undefined ? "" : data.folderId)
				} catch (err) {
					console.error(err);
				}
			}).catch(err => {
				if (axios.isCancel(err)) {
					console.log('cancel')
				}
			});
		return () => {
			cancelToken.cancel();
		}
	}, [APITOKEN, id, getFolder, setLoaded])


	const [editCampaignCase, setEditCampaignCase] = useState({});

	const createFolder = (val, typeid) => {
		if (val === "00000000-0000-0000-0000-000000000000") {
			setContentDescription("");
			const queryId = getParameterByName('id', window.location.href);
			const foldertype = getParameterByName('folder', window.location.href);

			setEditCampaignCase({
				id: queryId !== null ? queryId : '',
				folderId: foldertype !== null ? foldertype : ''
			})
			localStorage.setItem('tem', btoa(`${contentName.name}~${contentDescriptionValue}`), "");
			navigate(`/createContent/${typeid}`);
		} else {
			setOptionVal(val)
		}
	}

	const contentNameFn = ev => {
		const $v = ev.target.value;
		if ($v === "") {
			setContentName(prv => ({
				...prv,
				name: $v.replace(/[^\w\s\-|:^]/gi, ""),
				disable: true
			}))
		} else {
			setContentName(prv => ({
				...prv,
				name: $v.replace(/[^\w\s\-|:^]/gi, ""),
				disable: false
			}));
		}
	}
	const folderAcess = ev => {
		setFolderAccessData(prv => ({
			...prv,
			selectedAccess: ev.target.value
		}));

	}
	const closeFn = () => {
		setFolderAccessData(prv => ({
			...prv,
			selectedAccess: 0
		}));
	}

	const createNewTemplateAPI = () => {
		const obj = {
			campaignName: contentName.name,
			campaignDescription: contentDescriptionValue,
			folderId: optionVal,
			accessPermissionType: "",
			contentTypeId: id,
			isDuplicate: contentPageDetail.isDuplicate
		};
		setIsLoader(true);
		if (folderAccessData.selectedAccess === 1) {
			obj['accessPermissionType'] = folderAccessData.selectedAccess;
		}
		else {
			delete obj['accessPermissionType'];
		}
		const headers = {
			'Authorization': `Bearer ` + APITOKEN['token'],
			'Content-Type': 'application/json'
		};
		const queryId = getParameterByName('id', window.location.href);
		const folderPageEdit = getParameterByName('editFolder', window.location.href);
		const foldertype = getParameterByName('folder', window.location.href);
		const secreatKey = Cookies.get('secretKey');
		if (queryId !== null) {
			Client.put(`Campaign/${queryId}`, obj, { headers })
				.then(res => {
					try {
						const response = res.data;
						setAPIResponse(prv => ({ ...prv, response }));
						setIsLoader(false);
						if (response['Success']) {
							if (folderPageEdit !== null) {
								navigate(res.data['ReactEditPageUrl']);
								return false;
							};
							// this codition for check isDuplicate campaign and
							// folder id not null in url path
							// then redirect Editor page
							if (contentPageDetail.isDuplicate && !foldertype) {
								window.location.replace(`${response['ReactNextPageUrl']}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}&edit=1`);
							} else {

								if (foldertype === "42") {
									if (id === "2") {
										navigate('/landingPage/2');
									} else if (id === "5") {
										navigate(`/email/5`);

									} else {
										navigate(`/survey/4`);
									}
									return false;

								}
								navigate(`${response['ReactNextPageUrl']}?cam=${queryId}&edit=true&type=${id}&ed=1`);
							}
						} else {
							setFalseCase({ Success: response['Success'], message: response.Message });
						}
					} catch (err) {
						console.error(err);
					}
				})
		} else {
			const clone = getParameterByName('clone', window.location.href), ctype = getParameterByName('ctype', window.location.href) , campaignId = getParameterByName('redirect', window.location.href);
			Client.post(`Campaign/create/${id}`, obj, { headers })
				.then(({data}) => {
					try {
						// const response = res.data;
						setAPIResponse(prv => ({ ...prv, data }));
						setIsLoader(false);
						if (data['Success'] === false) {
							setFalseCase({ Success: data['Success'], message: data.Message });
						} else {
							if (id === 12 || id === 14) {
							} else {
								if (data['IsFolderCreated']) {
									navigate(`${data['ReactNextPageUrl']}${editCampaignCase.id !== "" ? `?id=${editCampaignCase.id}` : ''}${editCampaignCase.folderId !== "" ? `&folder=${editCampaignCase.folderId}` : ''}`);
									setFolderAccessData(prv => ({
										...prv,
										selectedAccess: 3
									}));
								} else {
									navigate(`${data['ReactNextPageUrl']}?cam=${data['Id']}&type=${id}${clone !== null ? '&clone=' + clone : ''}${ctype !==null?`&ctype=${ctype}${campaignId !== null?`&redirect=${campaignId}`:''}`:''}`);
								}
							}
						}
					} catch (err) {
						console.error(err);
					}
				});
		}
	}
	useEffect(() => {
		loadPreview();
		// return (() => {
		// 	// This line code for create template name stored removed //
		// 	localStorage.removeItem('tem');
		// })
	}, [loadPreview])
	return (
		<>
			<div className="smsHeader  nomargin">
				<div className="dashbd_sec nopadding">
					<div className="left_h_p">
						<h1 className="smstitle _t_i">{contentPageDetail.headerTitle}</h1>
						<p className="sms_content" >{contentPageDetail.headerDescription}</p>
					</div>
				</div>
			</div>
			<div className="dashbd_sec nopadding margin-top50">
				<BreadCrum To={contentName.reactCancelPageUrl && contentName.reactCancelPageUrl} Callback={closeFn} ToText={`${contentPageDetail.headerTitle && contentPageDetail.headerTitle}`} CurrentPage={`Add Content`} />
			</div>
			<div className="wrSms margin-top50">
				<div className="bx-boder-common common_Sec_1">
					<div className="_h_header">
						<h3 className="_t_i">{contentPageDetail.headerTitle}</h3>
					</div>
					<div className="bx-pad">
						<div className="formmodel-bx">
							<label className="_l_able_cls">Name</label>
							<input type="text" className="inputformmodel width100" value={contentName.name} onChange={e => contentNameFn(e)} maxLength="70" />
							{!isFlaseCase.Success && <p className="validation">{isFlaseCase.message }</p>}
						</div>
						<div className="formmodel-bx" style={{ "display": contentPageDetail.hasFolder === null || contentPageDetail.hasFolder === undefined ? 'none' : contentPageDetail.hasFolder ? 'none' : 'block' }}>
							<label className="_l_able_cls">Folder Access</label>
							<div id="premission_id">
								{folderAccessData.data && folderAccessData.data.map((x, i) => {
									return <span key={i}> <input type="radio" key={i} checked={i === 0 ? 'checked' : ''} onChange={folderAcess} className="inline-block folderAcces" name={x.name} value={x.Id} /> <span className="inline-block">{x.name}</span>&nbsp;&nbsp;</span>
								})}</div>
						</div>

						<div className="formmodel-bx">
							<label className="_l_able_cls">Description</label>
							<textarea id="_createDes" className="inputformmodel width100 textareaModel excludeclassName" value={contentDescriptionValue} onChange={(e) => setContentDescription(e.target.value)} maxLength="218"></textarea>
						</div>
						<div className="formmodel-bx" style={{ "display": contentPageDetail.hasFolder === null ? 'none' : contentPageDetail.hasFolder ? 'block' : 'none' }}>
							<label className="_l_able_cls" id="_createlabel">Add Content to Folder</label>
							<select type="text"
								className="inputformmodel"
								value={optionVal}
								onChange={(e => createFolder(e.target.value, e.target.childNodes[e.target.selectedIndex].getAttribute('data-id')))}>
								<option value="">Select</option>
								{folderData &&
									folderData.map((x, i) => <option key={i}
										data-id={x.typeId}
										className={x['id'] === '00000000-0000-0000-0000-000000000000' ? 'propertyallow' : 'excludeClass'}
										value={x.id}>{x.name}</option>)}
							</select>
						</div>
						<div className="formmodel-bx text-right nomargin">
							<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { navigate(contentName.reactCancelPageUrl); closeFn() }} >Cancel</button>
							<button
								type="button"
								className="grid_btn_1 grid_btn_active btn-gape-15"
								disabled={contentName.disable}
								onClick={createNewTemplateAPI}>
								<ButtonLoader IsLoader={IsLoader}>
									Continue
								</ButtonLoader>
							</button>

						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CreateContent;
