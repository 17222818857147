import React, { useState } from 'react'

export default function SelectMultipal({ Set, Get, Name }) {
    const [targetInput, setTargetInput] = useState("");
    const handleKeyDown = event => {
        const { key, target } = event;
        if (key === 'Enter') {
            if (target.value !== "") {
                Set(prv => ({ ...prv, data: [...prv.data, { id: Math.floor(Math.random() * 10000), email: target.value, displayName: "" }] }))
                setTargetInput("");
            }
        }

    }
    const deleteTag = item => {
        const deleteTag = Get.data.filter((value) => value.id !== item.id);
        Set(prv => ({ ...prv, data: deleteTag }))
    }

    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">{Name}</label>
            <div className="tags_contianer">
                {Get.data.map((item, index) =>
                    <div className="multiValue" key={index}>
                        <div className="TagItem">{item.email}</div>
                        <button type="button" className="tagItemRemove" onClick={() => deleteTag(item)}><i className="far fa-times"></i></button>
                    </div>
                )}
                <div className="tagInput">
                    <input type="text" className="tagInputField" value={targetInput} placeholder={Name} onKeyDown={handleKeyDown}
                        onChange={e => setTargetInput(e.target.value)} />
                </div>
            </div>
        </div>
    )
}
