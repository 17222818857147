import { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { get as _get } from "lodash";
import "react-modern-drawer/dist/index.css";

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Filters = (props) => {

  const [items, setItems] = useState(_get(props, "selectedDragFilterItems", []));

  useEffect(() => {
    console.log("selectedDragFilterItems: ", _get(props, "selectedDragFilterItems", []));
    setItems(_get(props, "selectedDragFilterItems", []));
  }, [props]);
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let itemsData = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    itemsData = itemsData.map((row, key) => {
      return { ...row, sequence: key };
    });
    setItems(itemsData);

    const allFields = [...itemsData, ..._get(props, "remainingDragFilterItems", [])];

    if (typeof props.setParentState === "function") {
      props.setParentState(allFields);
    }
  }

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable className="droppable-box" droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {(items || []).map((item, index) => (
              <Draggable key={_get(item, "CustomFieldUID", "")} draggableId={_get(item, "CustomFieldUID", "")} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                    className="draggable-list"
                  >
                    <div>
                      {_get(item, "CustomFieldName", "")}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext >
  );
}
export default Filters;