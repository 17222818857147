import { useCallback, useEffect, useState } from "react";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import  { forEach } from "lodash";
const CountResponseChart = ({ ...props }) => {
    const { Data, tabId } = props;

    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            accessibility: {
                rangeDescription: ''
            },
            enabled:false   
        },
        accessibility: {
         enabled:false   
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        tooltip: {
            // split: true,
            backgroundColor: '#fff',
            borderColor: '#eee',
            borderRadius: 3,
            borderWidth: 1,
            shadow: false,
            useHTML: true,
            xDateFormat: '%Y-%m-%d',
            formatter: function () {
                var s = [];
                const self = this;
                s.push('<table width="100%" cellspacing="0" cellpadding="0"><tr><td style="font-family:arial;background:#fff;color:#000;font-size:15px;padding:5px 0;font-weight:bold;text-align:center">' +  this.x + '</td></tr></table>');
                forEach(self.points, function (point, i) {
                    s.push('<table width="100%"><tr><td style="font-size:12px;padding-bottom:5px;padding-top:5px;font-family:roboto;color:#1b283e;">' + point.series.name + ' </td><td style="padding-left:10px;text-align:right;"><b style="text-align:center; line-height:20px;min-width:20px;height:20px;color:#000;display:inline-block;font-family:roboto;padding:0 10px;font-size:12px";"> &nbsp;' + point.y + '</td></tr></table>');
                })
                return s;
            },
            shared: true,
            followTouchMove: true,
            followPointer: true,
            style: {
                color: '#666',
                fontWeight: 'normal',
            },
        },

       
        responsive: {
            rules: [{
                condition: {
                    maxWidth: 500
                },
                chartOptions: {
                    legend: {
                        layout: 'horizontal',
                        align: 'center',
                        verticalAlign: 'bottom'
                    }
                }
            }]
        }
    });
    const filter = useCallback(() => {
        const ar = [];
        const landingGraphArray = [];
        // const _d = [];
        Data.forEach(async function (v, i) {
            const $d = v['performanceKPI'];
            v['dataChart'] = [];
            for (var x of $d) {
                ar.push(x['captuerdAt']);
                const param = (tabId === 1) ? "count" : "rate";
                v['dataChart'].push(x[param]);
            };
            landingGraphArray.push({
                name: v['name'],
                data: v['dataChart'],
                lineWidth: 2,
                linkedTo: 'q',
                color: '#a2cef3',
                fillOpacity: 0.3,
                zIndex: 1,
                marker: {
                    enabled: true
                }
            });
        });
        setOptionValue(prv=> ({...prv,
            series: landingGraphArray,
            xAxis: {
                categories: ar,
            }
        }))

    }, [Data, tabId]);
    useEffect(() => {
        filter();
    }, [filter])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={optionData} />

        </div>
    )

}

export default CountResponseChart
