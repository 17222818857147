import { useEffect, useRef } from "react";

const AutoCompleteInput = ({ value, Data, ...props }) => {
    let inputRef = useRef()
    useEffect(() => {
        inputRef.current.value = value;
    }, [value])
    return (
        <>
            <input className="inputformmodel" ref={inputRef} onChange={e => props.onChange(e.target.value)} />
            <div style={{ display: props.toggle ? 'block' : 'none' }}>
                {Data.length > 0 ?
                    <div className="filter_autocomplete">
                        <ul>
                            {Data && Data.map((x, i) => <li key={i}><button type="button" onClick={() => props.onClick(x)} >{x['CountryName']}  {x.DateFormat}</button ></li>)}
                        </ul>
                    </div> : ''
                }
            </div>
        </>
    )
}
export default AutoCompleteInput;