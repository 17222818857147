import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SMSKPI from './SMSKPI/SMSKPI';
import SurveyKPI from './SurveyKPI';
import Distribution from './Distribution';
import Export from './Export';
import Device from './Device';
import Engagment from './Engagment';
import Recipients from './Recipients';
import LiveSending from './LiveSending';
import LandingKPI from './LandingKPI';
import getParameterByName from '../../../ReuseTemplate/GetRoute';

const Tabing = ({ data }) => {
    const defaultId = 8;
    const [liveSendingId,setLiveSendingId] = useState();
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    useEffect(() => {
        if (getParameterByName('f', window.location.href) !== null) {
            clickmethod({ id: 13 });
        }else{
            setLiveSendingId(13);
        }
    }, [])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return item.id !== liveSendingId  && <li
                            key={index}
                            onClick={() => clickmethod(item)}
                        ><Link
                            className={item.id === tabId ? 'active ' : ' '}>
                                {item.name}
                            </Link>
                        </li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <SMSKPI tabId={tabId} /> :
                    tabId === 74 ? <SurveyKPI tabId={tabId} /> :
                        tabId === 10 ? <Distribution tabId={tabId} /> :
                            tabId === 73 ? <Export tabId={tabId} /> :
                                tabId === 30 ? <LandingKPI tabId={tabId} /> :
                                    tabId === 12 ? <Device /> :
                                        tabId === 11 ? <Engagment tabId={tabId} /> :
                                            tabId === 9 ? <Recipients tabId={tabId} /> : <LiveSending />}
                </div>
            }
        </>
    )
}

export default Tabing;