import { useEffect, useState } from "react";
import { get as _get, cloneDeep as _cloneDeep } from "lodash";
import DataTable from 'react-data-table-component';

import TableRowsLoading from "../../../ReusableComponents/TableRowsLoading";

import { getLoyaltyProgramRules, getSubscribersList, getLoyaltyProgramControls, saveLoyaltyProgramRule, updateRule, deleteRule } from "../../../../services/crm";

const Rules = () => {

  const rulesTableColumns = [
    { name: "Content", selector: (item) => _get(item, "content", "") },
    { name: "List", selector: (item) => _get(item, "contentName", "") },
    { name: "Event", selector: (item) => _get(item, "activityEvent", "") },
    { name: "Object", selector: (item) => _get(item, "activityObject", "") },
    { name: "Point", selector: (item) => _get(item, "points", 0) },
    {
      name: "Actions",
      selector: (item) => (
        <>
          <span className="text-07f margin-left4 cursor-pointer"
            onClick={() => {
              setShowEditRuleSection(true);
              setShowCreateRuleSection(false);
              setContent(_get(item, "content", ""));
              fetchSubscribeList();
              setEditRuleId(_get(item, "contentRowId", null));
              setEditFormData({
                activityEvent: _get(item, "activityEvent", ""),
                activityObject: _get(item, "activityObject", ""),
                contentName: _get(item, "contentName", ""),
                contentRowId: _get(item, "contentRowId", ""),
                points: _get(item, "points", 0)
              });
            }}
          >
            Edit
          </span>
          <span className="text-07f margin-left4 cursor-pointer"
            onClick={() => {

              setShowDeleteRuleSection(true);
              setDeleteRuleId(_get(item, "rowId", null));
            }}
          >
            Delete
          </span>
        </>
      )
    },
  ];

  const [showCreateSection, setShowCreateRuleSection] = useState(false);
  const [showEditSection, setShowEditRuleSection] = useState(false);
  const [showDeleteSection, setShowDeleteRuleSection] = useState(false);
  const [subscribersData, setSubscribersData] = useState([]);
  const [loyaltyControlsData, setloyaltyControlsData] = useState([]);
  const [rulesTableData, setRulesTableData] = useState([]);
  const [editRuleId, setEditRuleId] = useState("00000000-0000-0000-0000-000000000000");
  const [isEditValueChanged, setEditValueChanged] = useState(false);
  const [deleteRuleId, setDeleteRuleId] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(true);
  const [content, setContent] = useState("");

  const defaultPayload = { activityEvent: "", activityObject: "", contentName: "", points: 0 };
  const [formData, setFormData] = useState(defaultPayload);

  const defaultEditPayload = { activityEvent: "", activityObject: "", contentName: "", contentRowId: "", points: 0 };
  const [editForrmData, setEditFormData] = useState(defaultEditPayload);

  useEffect(() => {

    if (refreshTableData === true) {
      fetchLoyalotyPrograms();
    }
  }, [refreshTableData]);

  const fetchLoyalotyPrograms = () => {
    (async () => {
      try {
        const response = await getLoyaltyProgramRules();

        setRulesTableData(_get(response, "data", []));
        setRefreshTableData(false);
      } catch (err) {
        console.log("Error: ", err)
      }
    })();
  }

  const fetchSubscribeList = () => {
    (async () => {
      try {
        const responseSubscribers = await getSubscribersList();

        setSubscribersData(_get(responseSubscribers, "data", []));
      } catch (err) {
        console.log("Error: ", err)
      }
    })();
  }

  const onSubmitForm = () => {
    (async () => {
      try {
        const response = await saveLoyaltyProgramRule(formData);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setShowCreateRuleSection(false);
          setFormData(_cloneDeep(defaultPayload));
        }
      } catch (err) {
      }
    })();
  }

  const onEditForm = () => {
    (async () => {
      try {

        const response = await updateRule(editRuleId, editForrmData);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setEditRuleId(null);
          setContent("");
          setShowEditRuleSection(false);
          setEditValueChanged(false);
          setEditFormData(_cloneDeep(defaultEditPayload));
        }
      } catch (err) {
      }
    })();
  }

  const onDelete = () => {
    (async () => {
      try {

        const response = await deleteRule(deleteRuleId);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setDeleteRuleId(null);
          setContent("");
          setShowDeleteRuleSection(false);
        }
      } catch (err) {
      }
    })();
  }

  /*const onCancel = () => {
    setShowCreateRuleSection(false);
    setShowEditRuleSection(false);
    setFormData(_cloneDeep(defaultPayload));
    setEditFormData(_cloneDeep(defaultEditPayload));
    setEditValueChanged(false);
    setEditRuleId(null);
  }*/

  const onCancelDelete = () => {
    setShowDeleteRuleSection(false);
    setDeleteRuleId(null);
  }

  const onChangeCRMList = (e, isEdit) => {
    (async () => {
      try {
        if (isEdit === false) {
          setFormData({ ...formData, contentName: _get(e, "target.value", "") });
        } else {
          setEditFormData({ ...editForrmData, contentName: _get(e, "target.value", "") });
        }

        const programControls = await getLoyaltyProgramControls();
        setloyaltyControlsData(_get(programControls, "data", []));
      } catch (err) {
      }
    })();
  }

  const deleteRuleSection = () => {

    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Delete</h3>
          </div>
          <div className="bx-pad">
            <div className="alert alert-warning margin-bottom24">
              Do you want to delete Rule?
            </div>

            <div className="formmodel-bx text-right nomargin">
              <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancelDelete()} >Cancel</button>

              <button
                type="button"
                className="btn btn-danger btn-gape-15"
                disabled=""
                onClick={() => onDelete()}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const createRule = () => {

    return (
      <div className="width100 white">
        <div className="common_Sec_1">
          <div className="bx-pad">
            <div className="container margin-bottom50">
              <div className="row">
                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">CRM list</label>
                    <select
                      className="inputformmodel"
                      value={_get(formData, "contentName", "")}
                      onChange={(e) => onChangeCRMList(e, false)}
                    >
                      <option value="">Select</option>
                      {(subscribersData || []).map((row, key) => {
                        return (
                          <option key={key} value={_get(row, "name", "")}>{_get(row, "name", "")}</option>
                        )
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Event</label>
                    <select
                      className="inputformmodel"
                      value={_get(formData, "activityEvent", "")}
                      onChange={(e) => setFormData({ ...formData, activityEvent: _get(e, "target.value", "") })}
                    >
                      <option value="">Select</option>
                      <option value="Created">Created</option>
                      <option values="Updated">Updated</option>
                    </select>
                  </div>
                </div>

                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Object</label>
                    <select
                      className="inputformmodel"
                      value={_get(formData, "activityObject", "")}
                      onChange={(e) => setFormData({ ...formData, activityObject: _get(e, "target.value", "") })}
                    >
                      <option value="">Select</option>
                      <optgroup label="Default">
                        {(loyaltyControlsData || []).map((row, key) => {
                          return (
                            (_get(row, "type", 0) === 1) && (
                              <option value={_get(row, "controlName", "")}>{_get(row, "controlName", "")}</option>
                            )
                          )
                        })}
                      </optgroup>
                      <optgroup label="Custom">
                        {(loyaltyControlsData || []).map((row, key) => {
                          return (
                            (_get(row, "type", 0) === 2) && (
                              <option value={_get(row, "controlName", "")}>{_get(row, "controlName", "")}</option>
                            )
                          )
                        })}
                      </optgroup>
                    </select>
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputIncrese-wrap">
                    <label className="_l_able_cls w-100">Assign points for rule</label>
                    <div className="inputIncrese-btn">
                      <button
                        className="inputBtn"
                        type="button"
                        onClick={(e) => setFormData({ ...formData, points: (parseInt(_get(formData, "points", 0)) - 1) })}
                      >-</button>
                      <input
                        type="number"
                        className="inputformmodel"
                        value={_get(formData, "points", "")}
                        onChange={(e) => setFormData({ ...formData, points: _get(e, "target.value", "") })}
                      />
                      <button
                        className="inputBtn"
                        type="button"
                        onClick={(e) => setFormData({ ...formData, points: (parseInt(_get(formData, "points", 0)) + 1) })}
                      >+</button>
                    </div>
                  </div>
                </div>

                <div className="col-12 text-right">
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={((_get(formData, "activityEvent", "") === "") || (_get(formData, "activityObject", "") === "") || (_get(formData, "contentName", "") === "")) ? true : false}
                    onClick={() => onSubmitForm()}
                  >
                    Save Rule
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  const editRule = () => {

    return (
      <div className="width100 white">
        <div className="common_Sec_1">
          <div className="bx-pad">
            <div className="container margin-bottom50">
              <div className="row">
                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">CRM list</label>
                    <input type="text" className="inputformmodel" value={_get(editForrmData, "contentName", "")} disabled={true} />
                  </div>
                </div>

                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Content</label>
                    <input type="text" className="inputformmodel" value={content} disabled={true} />
                  </div>
                </div>

                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Event</label>
                    <select
                      className="inputformmodel"
                      value={_get(editForrmData, "activityEvent", "")}
                      disabled={true}
                    >
                      <option value="">Select</option>
                      <option value="Created">Created</option>
                      <option values="Updated">Updated</option>
                    </select>
                  </div>
                </div>

                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Object</label>
                    <input type="text" className="inputformmodel" value={_get(editForrmData, "activityObject", "")} disabled={true} />
                  </div>
                </div>

                <div className="col-12">
                  <div className="margin-bottom24">
                    <div className="inputIncrese-wrap">
                      <label className="_l_able_cls">Assign points for rule</label>
                      <div className="inputIncrese-btn">
                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => {
                            setEditValueChanged(true);
                            setEditFormData({ ...editForrmData, points: (parseInt(_get(editForrmData, "points", 0)) - 1) });
                          }}
                        >-</button>

                        <input
                          type="number"
                          className="inputformmodel"
                          value={_get(editForrmData, "points", "")}
                          onChange={(e) => {
                            setEditValueChanged(true);
                            setEditFormData({ ...editForrmData, points: _get(e, "target.value", "") })
                          }}
                        />

                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => {
                            setEditValueChanged(true);
                            setEditFormData({ ...editForrmData, points: (parseInt(_get(editForrmData, "points", 0)) + 1) });
                          }}
                        >+</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <div className="formmodel-bx text-right nomargin">
                    <button
                      type="button"
                      className="btn btn-primary btn-gape-15"
                      disabled={(!isEditValueChanged || false)}
                      onClick={() => onEditForm()}>
                      Update Rule
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  return (
    <>

      {((showDeleteSection || false) === true) && (
        deleteRuleSection()
      )}

      {((showDeleteSection || false) === false) && (
        <div className="sms_Sec_1 bx-boder-common width100 nobg">
          <div className="_h_header white border-radius2">
            <h3>Create Rule</h3>
            <span className="flex-right">
              <button
                type="button"
                className="btn btn-light"
                onClick={() => {
                  setShowCreateRuleSection(true);
                  setShowEditRuleSection(false);
                  fetchSubscribeList();
                }}
              >
                Create Rule
              </button>
            </span>
          </div>
          <div className="">
            {((showCreateSection || false) === true) && (
              createRule()
            )}

            {((showEditSection || false) === true) && (
              editRule()
            )}
            <div className="dataTable-primary">
              {((refreshTableData || false) === true) && (
                <TableRowsLoading />
              )}
              {((refreshTableData || false) === false) && (
                <DataTable
                  columns={rulesTableColumns}
                  data={rulesTableData}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Rules;