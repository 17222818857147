import React from 'react'
import Client from '../../../GlobalAPICall';

export default function TrackOpen(props) {
    const { dispatch, setObject, state, speakerAPI, trackAPI, lang, reSet, onSpeechSpeakder, selectedObject ,playListLoad } = props;
    const savePlayList = () => {
        Client.post(`/Mobile/AppMediaPlayList?playListId=${selectedObject.playListId}&mediaId=${selectedObject.trackIdArray.length > 0 ? 0 : selectedObject.trackId}&peopleSubscriberId=0&listType=${selectedObject.mediaId}&mediaIds=${selectedObject.trackIdArray}`, {})
            .then(async ({ data }) => {
                playListLoad();
                dispatch({
                    type: 'OPEN',
                    payload: {
                        open: false
                    }
                });
                reSet();

            }).catch(err => console.error(err));
    }
    const selecTrackOption = (ev, fl) => {
        const { value } = ev.target;
        if (value !== "00-000-000") {
            setObject(prv => ({
                ...prv,
                trackId: value,
                trackIdArray: [],
            }))
        } else {
            const getId = state.trackData.map(x => fl === 0 ? x.trackId : x.videoId);
            setObject(prv => ({
                ...prv,
                trackIdArray: getId,
            }))
        }
        dispatch({ type: 'MEDIA', payload: { ...state ,selected:value } });
    }
    return (
        <div className='trackContainer'>
            <div className='topn-header'>
                <span className='selected-title'>{state.flagTitle} Playlist</span>
                <span style={{ padding: '20px', float: 'right' }} onClick={() => {
                    dispatch({
                        type: 'OPEN',
                        payload: {
                            open: false
                        }
                    }); reSet()
                }}>
                    <i className='fa fal fa-times fright pointer'></i>
                </span>
            </div>

            <div className="padding20 ">
                <div className="formmodel-bx">
                    <label className="_l_able_cls">Select language</label>
                    <select className="inputformmodel" onChange={e => speakerAPI(e.target.value)}>
                        <option value="">Select</option>
                        {lang.data.map((v, i) => <option value={v.LanguageCode} key={i}>{v.Value}</option>)}
                    </select>
                </div>
                <div className="formmodel-bx">
                    <label className="_l_able_cls">Select Speaker</label>
                    <select className="inputformmodel" onChange={e => {
                        setObject(prv => ({
                            ...prv,
                            speakerId: e.target.value
                        }));
                        selectedObject.mediaId === 1 ? onSpeechSpeakder(e.target.value) : trackAPI(e.target.value);

                    }}>
                        <option value="">Select</option>
                        {lang.Speaker.map((v, i) => <option value={v.speakerId} key={i}>{v.speakerName}</option>)}
                    </select>
                </div>

                {state.showMedia ? <>
                    {/* <div className="formmodel-bx">
                        <label className="_l_able_cls">Select PlayList</label>
                        <select className="inputformmodel" onChange={e => {
                            setObject(prv => ({
                                ...prv,
                                playListId: e.target.value
                            }));

                        }}>
                            <option value="">Select</option>
                            {state.playList && state.playList.map((v, i) => <option value={v.playListId} key={i}>{v.title}</option>)}
                        </select>
                    </div> */}
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Select Speech</label>
                        <select className="inputformmodel" onChange={e => {
                            setObject(prv => ({
                                ...prv,
                                speechId: e.target.value
                            }));
                            trackAPI(e.target.value);
                        }}>
                            <option value="">Select</option>
                            {state.speakderData && state.speakderData.map((v, i) => <option value={v.speechId} key={i}>{v.title}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Select Track</label>
                        <select className="inputformmodel" onChange={e => selecTrackOption(e, 0)}>
                            <option value="">Select</option>
                            <option value="00-000-000" style={{ display: state.trackData && state.trackData.length > 0 ? 'block' : 'none' }}>All</option>
                            {state.trackData && state.trackData.map((v, i) => <option value={v.trackId} key={i}>{v.trackNumber}.{v.speechTitle}</option>)}
                        </select>
                    </div>
                </> : <div className="formmodel-bx">
                    <label className="_l_able_cls">Select Video</label>
                    <select className="inputformmodel" value={state.selected} onChange={e => selecTrackOption(e, 1)}>
                        <option value="">Select</option>
                        <option value="00-000-000" style={{ display: state.trackData && state.trackData.length > 0 ? 'block' : 'none' }}>All</option>
                        {state.trackData && state.trackData.map((v, i) => <option value={v.videoId} key={i}>{v.videoTitle}</option>)}
                    </select>
                </div>}

            </div>
            <div className="padding20 text-right">
                <button type="button" className="grid_btn_1 nobg noborder" onClick={() => {
                    dispatch({
                        type: 'OPEN',
                        payload: {
                            open: false
                        }
                    }); reSet()
                }}>Cancel</button>
                <button type="button" className="grid_btn_1 grid_btn_active" onClick={savePlayList} disabled={
                    (selectedObject.mediaId === 1 ? (selectedObject.languageCode !== "" && selectedObject.speechId !== "" && (!!selectedObject.trackId || !!selectedObject.trackIdArray)) ? false : true :
                        (selectedObject.languageCode !== "" && state.selected !=="") ? false : true)
                } >
                    Save
                </button>
            </div>
        </div>
    )
}
