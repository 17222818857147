import { UPDATE_APP_URI, CREATE_TOKEN, INSIGHT_DETAILSOBJECT, NAVIGATIONTOGGLE, ONBORDINGSTEPS, LOGO, EMAILUPLOADFILE, DELETE_TOKEN } from './Type';
export const updateAppURL = (t) => {
    return {
        type: UPDATE_APP_URI,
        payload: t
    };
};
export const setToken = (t) => {
    return {
        type: CREATE_TOKEN,
        payload: t
    };
};
export const setSMSInsightDetails = (t) => {
    return {
        type: INSIGHT_DETAILSOBJECT,
        payload: t
    };
};
export const setOnboardingSteps = (t) => {
    return {
        type: ONBORDINGSTEPS,
        payload: t
    };
};
export const setNavigationToggleFn = (t) => {
    return {
        type: NAVIGATIONTOGGLE,
        payload: t
    };
};
export const setLogoFn = (t) => {
    return {
        type: LOGO,
        payload: t
    };
};
export const setEmailFileImport = state => {
    return {
        type: EMAILUPLOADFILE,
        payload: state
    };
};
export const deleteToken = state => {
    return {
        type: DELETE_TOKEN,
        payload: state
    };
};