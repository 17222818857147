import React, { useEffect, useState, useCallback } from 'react';
import { get as _get, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getLookupImportConsents, importSubscriberList } from "../../../../services/crm";

const ImportRules = ({ sendDataToParent }) => {
	//const pathArray = window.location.pathname.split("/");
	//const listID = _get(pathArray, "[4]", null); // get PKID from UR
	//const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

	const navigate = useNavigate();
	const { importContact } = useSelector(state => _get(state, "importContacts", {}));
	//const [mergeRuleConsent, setMergeRuleConsent] = useState(null);
	const [identifierMasterConsent, setIdentifierMasterConsent] = useState([]);
	const [identifierPrimaryConsent, setIdentifierPrimaryConsent] = useState([]);
	const [identifierSecondaryConsent, setIdentifierSecondaryConsent] = useState([]);

	const defaultIncommingDataMappingJSON = { "IncommingDataConsentMapping": { "MasterDataConsentId": "", "ChildDataConsentId": [] }, "UniqueIndentifierConsentMapping": { "PrimaryConsentId": [], "SecondaryConsentId": [] } };
	const [incomingDataMappingJSON, setIncomingDataMappingJSON] = useState(defaultIncommingDataMappingJSON);
	const [selectedParentConsetId, setSelectedParentConsetId] = useState("");
	const [selectedSecondaryConsetId, setSelectedSecondaryConsetId] = useState("");
	const [isDisableSubmitButton, setIsDisableSubmitButton] = useState(true);

	const onChangeMasterConsent = useCallback((isChecked, selectedConsent, rowKey) => {

		let tmpMasterConsent = _cloneDeep(_get(identifierMasterConsent, "ConsentDetails", []));
		let tmpIncomingDataMappingJSON = _cloneDeep(incomingDataMappingJSON);

		if (isChecked) {
			// Change isDisabled and isChecked value for selected Consent

			tmpMasterConsent = tmpMasterConsent.map((originalConsent, i) => {

				// Change isDisabled and isChecked value for childten of selected Consent
				if (_get(originalConsent, "ParentId", "") === _get(selectedConsent, "TypeId", "")) {
					//Change Payload chilldren value to send after continue button click
					let currentSelectedChildren = _get(tmpIncomingDataMappingJSON, "IncommingDataConsentMapping.ChildDataConsentId", []);
					currentSelectedChildren.push(_get(originalConsent, "ConsentDetailsId", ""));

					tmpIncomingDataMappingJSON = {
						...tmpIncomingDataMappingJSON,
						IncommingDataConsentMapping: {
							...tmpIncomingDataMappingJSON["IncommingDataConsentMapping"],
							ChildDataConsentId: currentSelectedChildren
						}
					}

					return originalConsent = { ...originalConsent, isDisabled: false, isChecked: true }
				} else if (_get(originalConsent, "TypeId", "") !== _get(selectedConsent, "TypeId", "")) {

					// isDisabled = true and isChecked = false for all other consents except selected and its children
					return originalConsent = { ...originalConsent, isDisabled: true, isChecked: false }
				} else {
					tmpIncomingDataMappingJSON = {
						...tmpIncomingDataMappingJSON,
						IncommingDataConsentMapping: {
							...tmpIncomingDataMappingJSON["IncommingDataConsentMapping"],
							MasterDataConsentId: _get(selectedConsent, "ConsentDetailsId", "")
						}
					}
					// Change values for selected consent
					return originalConsent = { ...originalConsent, isChecked: true, isDisabled: false, };
				}
			});
		} else {

			if (_get(selectedConsent, "IsParent", 0) === 1) {

				// If unselected conset is children then disable it and uncheck it  (isDisabled: false & isChecked: false)
				tmpMasterConsent[rowKey] = { ...tmpMasterConsent[rowKey], isDisabled: true, isChecked: false, };

				// Remove children from final payload
				let currentSelectedChildren = _get(tmpIncomingDataMappingJSON, "IncommingDataConsentMapping.ChildDataConsentId", []);
				const index = currentSelectedChildren.indexOf(_get(selectedConsent, "ConsentDetailsId", ""));
				currentSelectedChildren.splice(index, 1);

				tmpIncomingDataMappingJSON = {
					...tmpIncomingDataMappingJSON,
					IncommingDataConsentMapping: {
						...tmpIncomingDataMappingJSON["IncommingDataConsentMapping"],
						ChildDataConsentId: currentSelectedChildren
					}
				}
			} else {

				// If unselected conset is not children, just change isChecked: false
				tmpMasterConsent[rowKey] = { ...tmpMasterConsent[rowKey], isDisabled: false, isChecked: true, };

				// Unselect all other consents
				tmpMasterConsent = tmpMasterConsent.map((originalConsent, i) => {
					if (_get(originalConsent, "IsParent", 0) === 1) {
						return originalConsent = { ...originalConsent, isDisabled: true, isChecked: false }
					} else {
						return originalConsent = { ...originalConsent, isDisabled: false, isChecked: false }
					}
				});

				//SET Parent & Child null in Final payload after clicking continue
				tmpIncomingDataMappingJSON = {
					...tmpIncomingDataMappingJSON,
					IncommingDataConsentMapping: {
						MasterDataConsentId: "",
						ChildDataConsentId: []
					}
				}
			}
		}
		setIdentifierMasterConsent({ ...identifierMasterConsent, ConsentDetails: tmpMasterConsent });
		setIncomingDataMappingJSON(tmpIncomingDataMappingJSON);

	}, [identifierMasterConsent, incomingDataMappingJSON]);

	const onDisablePrimarySecondary = useCallback((parentId, dataType) => {
		let tmpData = (dataType === "secondary") ? _cloneDeep(identifierSecondaryConsent) : _cloneDeep(identifierPrimaryConsent);

		tmpData = _get(tmpData, "ConsentDetails", []).map((data, i) => {
			if (_get(data, "ParentId", "") === parentId) {
				return { ...data, isDisabled: true };
			} else {
				let childData = [];
				if (!_isEmpty(_get(data, "ChildElements", []))) {

					childData = _get(data, "ChildElements", []).map((child, i) => {
						let disabled = false;
						if (_get(child, "Id", "") === parentId) {
							disabled = true;
						}
						return { ...child, disabled: disabled }
					});
				}

				let disableParent = false;
				if (_get(data, "IsExternalIdMapped", "") === 0) {
					disableParent = true;
				} else if (_get(data, "IsSystemContactIdMapped", "") === 0) {
					disableParent = true;
				}

				data = { ...data, isDisabled: disableParent, ChildElements: childData };
				return data;
			}
		});
		return tmpData;

	}, [identifierSecondaryConsent, identifierPrimaryConsent]);

	const onChangePrimaryConsent = useCallback((selectedConsentId, consentType, isChild = 0) => {
		(async () => {
			try {
				let tmpIncomingDataMappingJSON = _cloneDeep(incomingDataMappingJSON);
				let tmpPrimaryConsent = _cloneDeep(_get(identifierPrimaryConsent, "ConsentDetails", []));
				let tmpSecondaryConsent = _cloneDeep(_get(identifierSecondaryConsent, "ConsentDetails", []));

				if (consentType === 1) {
					const index = tmpPrimaryConsent.findIndex(obj => { return obj.ConsentDetailsId === selectedConsentId });
					let selectedConsent = tmpPrimaryConsent[index];
					const parentId = _get(selectedConsent, "ParentId", _get(selectedConsent, "Id", ""),);

					tmpIncomingDataMappingJSON = {
						...tmpIncomingDataMappingJSON,
						UniqueIndentifierConsentMapping: {
							...tmpIncomingDataMappingJSON["UniqueIndentifierConsentMapping"],
							PrimaryConsentId: [{
								FieldType: (_get(selectedConsent, "ParentId", "") === 40) ? "ExternalId" : "Default",
								ParentId: (isChild === 1) ? _get(selectedConsent, "extanalIdConsentDetailsId", "") : _get(selectedConsent, "ConsentDetailsId", ""),
								ChildId: (isChild === 1) ? _get(selectedConsent, "ConsentDetailsId", "") : null
							}],
							SecondaryConsentId: [],
						}
					}

					tmpPrimaryConsent = tmpPrimaryConsent.map((originalConsent, i) => {

						if ((_get(originalConsent, "IsSystemContactIdMapped", "") === 0) || (_get(originalConsent, "ConsentDetailsId", "") === selectedConsentId)) {
							originalConsent = { ...originalConsent, isDisabled: true, }
						} else {
							originalConsent = { ...originalConsent, isDisabled: false, }
						}
						return originalConsent;
					});
					//Make secondary item data in final payload empty
					if (parentId === 35) {

						// Disable All Secondary options
						tmpSecondaryConsent = tmpSecondaryConsent.map((originalConsent, i) => {

							return originalConsent = { ...originalConsent, isDisabled: false, }
						});

						setIdentifierSecondaryConsent({ ...identifierSecondaryConsent, ConsentDetails: tmpSecondaryConsent });
					} else {
						const secondaryData = onDisablePrimarySecondary(parentId, "secondary");
						setIdentifierSecondaryConsent({ ...identifierSecondaryConsent, ConsentDetails: secondaryData });
					}

					setIdentifierPrimaryConsent({ ...identifierPrimaryConsent, ConsentDetails: tmpPrimaryConsent });
					setSelectedParentConsetId(selectedConsentId);
					setIncomingDataMappingJSON(tmpIncomingDataMappingJSON);
					setSelectedSecondaryConsetId("");
				} else {
					const index = tmpSecondaryConsent.findIndex(obj => { return obj.ConsentDetailsId === selectedConsentId });
					let selectedConsent = tmpSecondaryConsent[index];
					const parentId = _get(selectedConsent, "ParentId", _get(selectedConsent, "Id", ""),);

					tmpIncomingDataMappingJSON = {
						...tmpIncomingDataMappingJSON,
						UniqueIndentifierConsentMapping: {
							...tmpIncomingDataMappingJSON["UniqueIndentifierConsentMapping"],
							SecondaryConsentId: [{
								FieldType: (_get(selectedConsent, "ParentId", "") === 40) ? "ExternalId" : "Default",
								ParentId: (isChild === 1) ? _get(selectedConsent, "extanalIdConsentDetailsId", "") : _get(selectedConsent, "ConsentDetailsId", ""),
								ChildId: (isChild === 1) ? _get(selectedConsent, "ConsentDetailsId", "") : null
							}],
						}
					}

					const primaryData = onDisablePrimarySecondary(parentId, "primary");
					setIdentifierPrimaryConsent({ ...identifierPrimaryConsent, ConsentDetails: primaryData });
					setIncomingDataMappingJSON(tmpIncomingDataMappingJSON);
					setSelectedSecondaryConsetId(selectedConsentId);
				}
			} catch (error) {
			}
		})();
	}, [onDisablePrimarySecondary, incomingDataMappingJSON, identifierPrimaryConsent, identifierSecondaryConsent]);

	useEffect(() => {
		(async () => {
			try {
				const respMasterConsent = await getLookupImportConsents({ moduleId: 10, importId: _get(importContact, "data.ImportId", "") });

				let masterConsent = [];
				_get(respMasterConsent, "data.data[0].ConsentDetails", []).forEach((element, i) => {
					masterConsent.push(element);

					// Disable Child Consents
					masterConsent[i]["isDisabled"] = (_get(element, "IsParent", 0) === 1) ? true : false;

					// If default select check condition for on change consents
					if (_get(element, "IsSelected", 0) === 1) {
					}
				});

				setIdentifierMasterConsent(_get(respMasterConsent, "data.data[0]", []));

				const respPrimaryConsent = await getLookupImportConsents({ moduleId: 11, importId: _get(importContact, "data.ImportId", "") });
				setIdentifierPrimaryConsent(_get(respPrimaryConsent, "data.data[0]", []));

				/*let tmpPrimaryConsent = _cloneDeep(_get(respPrimaryConsent, "data.data[0]", []));
				tmpPrimaryConsent = _get(tmpPrimaryConsent, "ConsentDetails", []).map((tmpConsent, i) => {
					if (_get(tmpConsent, "IsExternalIdMapped", "") === 0) {
		
						// if consent has children element
						let childrenElements = [];
						if ((_get(tmpConsent, "ChildElements", "") !== "")) {
							childrenElements = (JSON.parse(_get(tmpConsent, "ChildElements", ""))).map((c, i) => {
								return { ...c, extanalIdConsentDetailsId: tmpConsent["ConsentDetailsId"] }
							});
						}
						tmpConsent = {
							...tmpConsent,
							isDisabled: true,
							ChildElements: childrenElements
						}
					}
					return tmpConsent;
				});*/
				//setIdentifierPrimaryConsent({ ...tmpPrimaryConsent, ConsentDetails: tmpPrimary });

				const respSecondary = await getLookupImportConsents({ moduleId: 12, importId: _get(importContact, "data.ImportId", "") });
				setIdentifierSecondaryConsent(_get(respSecondary, "data.data[0]", []));
			} catch (error) {
				console.log("Error3: ", error);
			}
		})();
	}, [importContact]);

	/*useEffect(() => {
		//onChangePrimaryConsent();
		let tmpPrimaryConsent = _cloneDeep(identifierPrimaryConsent);
	
		tmpPrimaryConsent = _get(tmpPrimaryConsent, "ConsentDetails", []).map((tmpConsent, i) => {
			if (_get(tmpConsent, "IsExternalIdMapped", "") === 0) {
	
				// if consent has children element
				let childrenElements = [];
				if ((_get(tmpConsent, "ChildElements", "") !== "")) {
					childrenElements = (JSON.parse(_get(tmpConsent, "ChildElements", ""))).map((c, i) => {
						return { ...c, extanalIdConsentDetailsId: tmpConsent["ConsentDetailsId"] }
					});
				}
				tmpConsent = {
					...tmpConsent,
					isDisabled: true,
					ChildElements: childrenElements
				}
			}
			return tmpConsent;
		});
		//setIdentifierPrimaryConsent(tmpPrimaryConsent);
	}, [identifierPrimaryConsent]);*/

	useEffect(() => {
		let error = false;
		if (
			_get(incomingDataMappingJSON, "IncommingDataConsentMapping.MasterDataConsentId", "") === "" ||
			(
				_isEmpty(_get(incomingDataMappingJSON, "UniqueIndentifierConsentMapping.PrimaryConsentId", [])) &&
				_isEmpty(_get(incomingDataMappingJSON, "UniqueIndentifierConsentMapping.SecondaryConsentId", []))
			)
		) {
			error = true;
		}
		setIsDisableSubmitButton(error);
	}, [incomingDataMappingJSON]);

	const onContinue = () => {
		(async () => {
			try {
				const payload = {
					IncomingDataMappingJson: incomingDataMappingJSON,
					StepId: 4,
					columnHeaderMappingsJson: null,
					isFirstRowAsHeader: null,
					listMergeMappingJson: null,
					otherInfo: null
				}
				const response = await importSubscriberList(_get(importContact, "data.ImportId", ""), payload);

				if (_get(response, "flag", true) === true) {
					sendDataToParent("compliance");
				}
			} catch (error) {
				console.log("Error: ", error);
			}
		})();
	}

	return (
		<>
			<div className="bx-boder-common nobg my-12">
				<div className="_h_header white border-radius2">
					<h3>Import Rules</h3>
				</div>

				<div className="white">
					<div className="step-import-rules">
						<div className="p-5">
							<p className="mb-12">Tell us about how to handle duplicates. In the case of a duplicate, should we overwrite existing data with the incomming data from file or do you want to keep the existing data?</p>

							{_get(identifierMasterConsent, "ConsentDetails", []).map((row, key) => {

								return (
									<p key={key} className={(_get(row, "IsParent", 0) === 1) ? "mt-4 ps-5" : "mt-10"}>
										<input
											id={`consent-${key}`}
											className="inline-block vertical-middle"
											type="checkbox"
											disabled={_get(row, "isDisabled", false)}
											checked={_get(row, "isChecked", false)}
											onChange={(e) => onChangeMasterConsent(_get(e, "target.checked", false), row, key)}
										/>
										<label className="ps-2" htmlFor={`consent-${key}`}>{_get(row, "Text", "")}<br />
											<span className="ps-5">{_get(row, "Description", "")}</span></label>
									</p>
								)
							})}

							<div className="mb-4 mt-15">
								<label className="_l_able_cls">
									What kind of unique data field identifier (key, ID, etc) would you like to use to identify duplicates <strong>(Primary key)</strong>?
								</label>
								<select className="inputformmodel width100 mxw-100" value={(selectedParentConsetId || "")} onChange={(e) => onChangePrimaryConsent(_get(e, "target.value", ""), 1)}>
									<option value="">Select</option>
									<optgroup label={_get(identifierPrimaryConsent, "Consent", "")}>
										{_get(identifierPrimaryConsent, "ConsentDetails", []).map((row, key) => {
											return (
												<option
													key={key}
													value={_get(row, "ConsentDetailsId", "")}
													disabled={(_get(row, "isDisabled", false) === true) ? true : false}
												>
													{_get(row, "Description", "")}
												</option>
											)
										})}
									</optgroup>
								</select>
							</div>

							<div className="mb-4">
								<label className="_l_able_cls">
									If we don't get match for the first key, we can try to find duplicates with a second key <b>(Secondary key).</b>  </label>
								<select className="inputformmodel width100 mxw-100" value={(selectedSecondaryConsetId || "")} onChange={(e) => onChangePrimaryConsent(_get(e, "target.value", ""), 2)}>
									<option value="">Select</option>
									<optgroup label={_get(identifierSecondaryConsent, "Consent", "")}>
										{_get(identifierSecondaryConsent, "ConsentDetails", []).map((row, key) => {
											return (
												<option
													key={key}
													value={_get(row, "ConsentDetailsId", "")}
													disabled={(_get(row, "isDisabled", false) === true) ? true : false}
												>
													{_get(row, "Description", "")}
												</option>
											)
										})}
									</optgroup>
								</select>
							</div>
						</div>

						<div className="p-5 text-right">
							<button className="btn link-light" onClick={() => navigate("/crm/people/listings")}>Cancel</button>
							<button className="btn btn-success" disabled={isDisableSubmitButton} onClick={() => onContinue()}>Continue</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ImportRules;