import { useCallback, useEffect, useRef, useState } from "react";
import BlankData from "../BlankData";
import Client from "../GlobalAPICall";
import { DeleteTemplateForCampaign } from "../ReuseTemplate";
import Pagination from "../ReuseTemplate/Pagination";
import ButtonPanel from "./ButtonPanel";
import CampaignDetails from "./CampaignDetails";
import ImgCampaign from "./ImgCampaign";
import LandingPageLoader from "../ReuseTemplate/LandingPageLoader";
import CampaignPreview from "../ReuseTemplate/CampaignPreview";
import { useParams } from "react-router";
import { ToastContainer } from "react-toastify";
import _ from "lodash";
const MyContent = ({ tabId, contentId, APITOKEN, ...props }) => {
  const [camData, setCampaignData] = useState([]);
  const { isDisplay, setDisplay } = props;
  const [pagiShow, setPaginationShow] = useState(false);
  const {
    count,
    onCountChange,
    totalCount,
    buttonEvent,
    buttonProps,
    folderId,
    setSearchText,
    searchText,
  } = props;
  const wrapperRef = useRef(null);
  const [previewDisplay, setPreviewDisplay] = useState(false);
  const [previewLink, setPreviewLink] = useState({});
  const { id } = useParams();
  let [deleteCamId, setDeleteCamId] = useState("");

  const load = useCallback(() => {
    const folderIdValue = folderId?.id || ""; // Ensure folderId is valid
    const searchTextValue = searchText || ""; // Ensure searchText is valid
    const url = `Campaign/${contentId}/${tabId}?folderId=${folderIdValue}&searchText=${searchTextValue}&pageno=${count}&pageSize=10`;

    console.log("Making API request with the following details:");
    console.log("URL:", url);
    console.log("Authorization:", `Bearer ${APITOKEN.token}`);

    Client.get(url, {
      headers: {
        Authorization: `Bearer ${APITOKEN.token}`,
      },
    })
      .then(({ data }) => {
        try {
          setCampaignData(data);
          setDisplay(false);
          if (count === 1) {
            buttonEvent({ nextPage: false, previous: true });
            setPaginationShow(data.length < 10);
          }
        } catch (err) {
          console.error("Error processing response data:", err);
        }
      })
      .catch((err) => {
        console.error("API request error:", err);
        console.error("Request URL:", url);
        console.error("Request Headers:", {
          Authorization: `Bearer ${APITOKEN.token}`,
        });
      });
  }, [
    APITOKEN,
    tabId,
    contentId,
    count,
    buttonEvent,
    folderId,
    setDisplay,
    searchText,
  ]);

  const campaignSearch = (ev) => {
    ev.stopPropagation();
    setSearchText(ev.target.value);
  };
  var debounced = _.debounce(campaignSearch, 1000);
  const pagination = (f) => {
    if (f === 0) {
      onCountChange((count) => count - 1);
      props.setKkipUse(10 * (count - 1));
      if (count - 1 === 1) {
        buttonEvent({ previous: true });
      } else {
        buttonEvent({ previous: false });
      }
    } else {
      onCountChange((count) => count + 1);
      props.setKkipUse(10 * (count + 1));
      if (10 * (count + 1) >= totalCount.totalNumber) {
        buttonEvent({ ...buttonProps, ...{ nextPage: true, previous: false } });
      } else {
        buttonEvent({ previous: false });
      }
    }
  };

  const deletePermanently = (v) => {
    setDeleteCamId(v.id);
    props.setPermantaley(true);
  };
  //  Delete Event Confirmation //
  const deleteConfirm = (flag) => {
    Client.delete(
      `Campaign/${deleteCamId}/permanentDelete?isInsightsDelete=${flag}`,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(({ data }) => {
        try {
          if (data.Success) {
            load();
            props.setPermantaley(false);
            props.TabLoad();
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => console.error(err));
  };
  const previewCampaign = (v) => {
    setPreviewDisplay(!previewDisplay);
    setPreviewLink({ PreviewUrl: v.previewUrl, id: v.id });
  };
  useEffect(() => {
    load();
  }, [load]);
  return (
    <>
      <div ref={wrapperRef}></div>
      {props.permantaley ? (
        <DeleteTemplateForCampaign
          title="Do you want to delete the Landing Page permanently?"
          content="If you confirm delete with Insights, the Landing Page will be permanently deleted with insights from the database, whereas if you confirm Delete without Insights, then the Landing Page will be deleted and Insights data will be there in the database."
          deleteDownload={deleteConfirm}
          onClick={() => props.setPermantaley(false)}
          span="6"
        />
      ) : (
        <>
          <div
            className="defaultborder width100 nobg content__my"
            style={{ borderBottom: "0" }}
          >
            <div className="_h_header white" style={{ border: "0" }}>
              <h3>
                {contentId === "2"
                  ? "Landing Pages"
                  : contentId === "5"
                  ? "Emails"
                  : contentId === "4"
                  ? "Surveys"
                  : contentId === "97"
                  ? "Stores"
                  : "Workflows"}
              </h3>
              <span className="flex-right">
                <input
                  type="text"
                  className="inputformmodel _seachTextbox"
                  placeholder={
                    contentId === "2"
                      ? "Search Landing Page"
                      : contentId === "5"
                      ? "Search Email"
                      : contentId === "4"
                      ? "Search Survey"
                      : contentId === "97"
                      ? "Search Store"
                      : "Search Workflow"
                  }
                  onChange={debounced}
                />
              </span>
            </div>
          </div>
          <div className="_tab_content">
            {isDisplay ? (
              <LandingPageLoader />
            ) : camData.length === 0 ? (
              <>
                <table
                  cellPadding="0"
                  cellSpacing=""
                  width="100%"
                  className="defaultborder"
                >
                  <tbody>
                    <BlankData
                      title={
                        tabId === 5
                          ? "We have organized your Email designs to give you a better overview and to make it easier for you to find historical Emails."
                          : tabId === 2
                          ? "We have organized your Landing Page to give you a better overview and to make it easier for you to find historical Landing Pages."
                          : "We have organized your Survey designs to give you a better overview and to make it easier for you to find historical Survey."
                      }
                      content={`Here you will find the 3 latest ${
                        tabId === 5
                          ? "Emails"
                          : tabId === 2
                          ? "Landing Page"
                          : "Survey"
                      } you have created.`}
                      colSpan="8"
                    />
                  </tbody>
                </table>
              </>
            ) : (
              camData.map((v, i) => {
                return (
                  <div
                    className="-l-body-l white bx-boder-common border-radius2"
                    key={i}
                  >
                    <div className="topbarListing">
                      <h3 className=" titleHover">
                        <label style={{ whiteSpace: "pre" }}>{v["name"]}</label>
                        <input
                          type="text"
                          onChange={(e) => e.stopPropagation()}
                          className="cam_title inputformmodel"
                          style={{ width: "250px", display: "none" }}
                          value={v["name"]}
                        />
                      </h3>
                      <ButtonPanel
                        v={v}
                        tabId={tabId}
                        contentId={contentId}
                        Data={camData}
                        onChangeEvent={setCampaignData}
                        wrapperRef={wrapperRef}
                        load={load}
                        onClick={deletePermanently}
                        APITOKEN={APITOKEN}
                        {...props}
                      />
                    </div>
                    <div className="_listing_bx_content">
                      <ImgCampaign
                        v={v}
                        tabId={tabId}
                        contentId={contentId}
                        previewCampaign={previewCampaign}
                      />
                      <CampaignDetails
                        v={v}
                        tabId={tabId}
                        contentId={contentId}
                        load={load}
                      />
                    </div>
                  </div>
                );
              })
            )}
            {tabId === 1 || pagiShow ? (
              <></>
            ) : (
              <Pagination
                pageCount={count - 1}
                skip={props.skip}
                totalCount={totalCount}
                buttonAction={buttonProps}
                onClick={pagination}
              />
            )}
          </div>
          <ToastContainer className="ToastCustom" />
        </>
      )}
      {previewDisplay ? (
        <CampaignPreview Close={setPreviewDisplay} Link={previewLink} Id={id} />
      ) : (
        <></>
      )}
    </>
  );
};
export default MyContent;
