import React, { useEffect, useState } from 'react'
import Client from '../GlobalAPICall'
import { useCallback } from 'react';
import ChargifyPayment from './ChargifyPayment';
import { AddFundRePayment } from './Payment';
import ButtonLoader from './ButtonLoader';
import { Link } from 'react-router-dom';

export default function BalanceCheckFn(props) {
    const [getBlanceSetting, setGetBlanceSetting] = useState({});
    const [reloadBalance, setPreloadBalance] = useState({
        isloader: false,
        response: false,
        isloaderaddFund: false
    });
    const { APITOKEN, lowBlance, setLowBalancePopup, removeWaterMark } = props;
    const [chargifyDisplay, setChargifyDisplay] = useState(false);
    const [addFundValue, setAddFund] = useState("");
    const lowBlanceSetting = useCallback(() => {
        Client.get('User/LowBalanceAlert_Settings', {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setPreloadBalance({
                    isloader: false,
                    response: false
                });
                setGetBlanceSetting(data);
            }).catch(err => console.error(err));
    }, [APITOKEN]);
    const payNow = () => {
        const obj = {
            "amount": lowBlance['balance'].isMinimumAmount ? lowBlance['balance'].minimumAmountForPayment : Math.abs(lowBlance['balance'].currentAccountedSubscriptionBalance),
            "details": "Add Prepaid Fund",
            "memo": "Add Prepaid Fund"
        };
        setPreloadBalance({
            isloader: true,
            response: false,
            isloaderaddFund: false
        });
        addFundCommonMethod(obj);
    }
    const addFund = () => {
        const obj = {
            "amount": addFundValue,
            "details": "Add Prepaid Fund",
            "memo": "Add Prepaid Fund"
        };
        setPreloadBalance({
            isloader: false,
            response: false,
            isloaderaddFund: true
        });
        addFundCommonMethod(obj);
        setAddFund("");
    }
    const addFundCommonMethod = obj => {

        AddFundRePayment({ obj, APITOKEN }, function (data) {
            !!setPreloadBalance &&
                setPreloadBalance(prv => ({
                    ...prv,
                    isloader: false,
                    isloaderaddFund: false,
                    response: true,
                    ...data
                }));
            removeWaterMark();
        });
    }
    const [lowBalaceManagement, setLowBalaceManagement] = useState({
        resource: false,
        settings: false,
        threshold: "",
        refill: ""
    });
    const lowBalanceManagement = () => {
        const data = {
            "lowBalance": Number(lowBalaceManagement.threshold),
            "refilAmount": Number(lowBalaceManagement.refill),
            "paymentMode": 1
        };
        Client.post('User/LowBalanceAlert_Settings', data, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                if (data['Success']) {
                    setLowBalancePopup(prv => ({ ...prv, display: false }));
                    removeWaterMark();
                }
            }).catch(err => console.error(err));
    }

    useEffect(() => {
        lowBlanceSetting()
    }, [lowBlanceSetting])
    return (
        <>

            <div className="balanceMasking _balancePopRemove"></div>
            <div className="__balanceCheckContainer">
                <div className="topn-header">
                    <span className="selected-title">{APITOKEN.config.paymentSource === "" || APITOKEN.config.paymentSource === null || APITOKEN.config.paymentSource === "onlinecardpayment" ? 'You need to pay for this product to continue' : 'Support Contact'}</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => setLowBalancePopup(prv => ({ ...prv, display: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer _balancePopRemove"></i></span>
                </div>
                {APITOKEN.config.paymentSource === "" || APITOKEN.config.paymentSource === null || APITOKEN.config.paymentSource === "onlinecardpayment" ?
                    lowBlance['balance'] ?
                        <div className="">
                            <div className="padding20">
                                <p className="p-m sms_content">You have reached your included subscription limit. To see what your
                                    subscription includes, current usage and additional resource costs you will
                                    find it under the billing menu or you can <Link to="/billing" target="_blank" rel="noopener noreferrer" className="link inline-block sms_content nopadding">click here</Link>.</p>
                            </div>
                            <div className="padding20 b_top" style={{ background: '#f8f6ec' }}>
                                <h3 className="sms_content">
                                    <strong>Product</strong></h3>
                                <div className="">
                                    <table width="100%" cellPadding="0" cellSpacing="0" className="producttable">
                                        <thead>
                                            <tr>
                                                <th className="ptable_cell_header">Resource</th>
                                                <th className="ptable_cell_header">Total Items</th>
                                                <th className="ptable_cell_header">Item Price</th>
                                                <th className="ptable_cell_header">Total Cost</th>
                                            </tr>
                                        </thead>
                                        <thead className="costinfo">
                                            {lowBlance['balance'].resourceLineItems.map((v, i) => <tr key={i}><td className="pbody_table_cell">{v['resourceName']}</td><td className="pbody_table_cell">{v['quantity']}</td>
                                                <td className="pbody_table_cell">{(v['resourceType'] === 29) ? <a href="/billing" target="_blank" className="link inline-block">Price list</a> : v['currency'] + ' ' + v['pricePerQuantity']}</td><td className="pbody_table_cell">{v['currency']} {v['amount']}</td></tr>)}
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div className="padding20 b_top" style={{ background: '#f8f6ec' }}>
                                <h3 className="sms_content">
                                    <strong>Transaction</strong></h3>
                                <div className="">
                                    <table width="100%" cellPadding="0" cellSpacing="0" className="producttable">
                                        <thead id="tranaction">
                                            <tr>
                                                <td className="pbody_table_cell ">Your balance</td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td className="pbody_table_cell">
                                                    <span className="y__balance">
                                                        {lowBlance['balance'].currency} {lowBlance['balance'].currentPrepaymentBalance}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="pbody_table_cell nopadding">Amount to pay <br></br><span className="tax-info">
                                                    {lowBlance['balance'].totalInCents > 0 ? '(Inclusive Tax)' : ''}
                                                </span></td>
                                                <td>&nbsp;</td>
                                                <td>&nbsp;</td>
                                                <td className="pbody_table_cell nopadding">
                                                    <span className="y__minimumAmountForPayment">
                                                        {lowBlance['balance'].currency} {Math.abs(lowBlance['balance'].currentAccountedSubscriptionBalance)}
                                                    </span>
                                                </td>
                                            </tr>
                                            {lowBlance['balance'].isMinimumAmount &&
                                                <tr className="total__show__with__conditioin">
                                                    <td className="pbody_table_cell">Total charge</td>
                                                    <td>&nbsp;</td>
                                                    <td>&nbsp;</td>
                                                    <td className="pbody_table_cell"><span className="t_charge">
                                                        <strong>{lowBlance['balance'].currency} {lowBlance['balance'].minimumAmountForPayment} *</strong>
                                                    </span></td>
                                                </tr>
                                            }
                                        </thead>
                                    </table>
                                    {lowBlance['balance'].isMinimumAmount &&
                                        <p className="total__show__with__conditioin" style={{ fontSize: '12px', paddingTop: '10px' }}>* Our payment gateway Stipe has a minimum charge amount. If you proceed with this payment, you will be charged the minimum amount, and the remaining amount will be added to your balance. </p>
                                    }
                                </div>
                            </div>
                            <div className="_h_header">
                                <h3>Payment Method</h3>
                            </div>
                            <div className="padding20">
                                {!!lowBlance['balance']['paymentProfile'] ?
                                    <div className="formmodel-bx paymentCardbox__panel">
                                        <label className="_l_able_cls">Charge my existing credit card</label>
                                        <div className="card_info member__Card">
                                            <p className="card__logo">
                                                <img alt='card' width={30} src={lowBlance['balance'].paymentProfile.cardType === 'visa' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/visa.png' : lowBlance['balance'].paymentProfile.cardType === 'master' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/mastercard.png' : 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/amax.png'} />
                                            </p>
                                            <p className="cardMember_info">{lowBlance['balance']['paymentProfile'].firstName} {lowBlance['balance']['paymentProfile'].lastName}</p>
                                            <p className="card__no">{lowBlance['balance']['paymentProfile'].maskedCardNumber}</p>
                                            <p className="card__date">{lowBlance['balance']['paymentProfile'].expirationMonth}/{lowBlance['balance']['paymentProfile'].expirationYear}</p>
                                        </div>
                                    </div>
                                    : <></>}
                                <div className="formmodel-bx"><label className="_l_able_cls">Add new card</label>
                                    <div className="card_info"><p>Click on the button Add card . A new session will open with your billing portal and you need to click “Update Payment Method to add your card. When you have added your card, go back to this session and click on refresh to continue with Payment Option 1 or Payment Option 2.</p><br></br><p className="text-right"><button className="grid_btn_1  grid_btn_darkBlue_common billingPortal" onClick={() => setChargifyDisplay(true)}>Add Card</button></p>
                                    </div>
                                </div>
                            </div>
                            <div className="padding20 b_top">
                                <div className="">
                                    <h3 className="sms_content">
                                        <strong>Payment Option 1</strong>
                                        <p style={{ marginTop: '30px' }}>You are able to pay for individual resources when needed (not Monthly reccuring resources). This option requires you to make a payment everytime you have a need for additional resources. If you would like to pay for this individual resource, click on the button Pay Now.</p>
                                    </h3>
                                    <div style={{ marginTop: '40px' }} className="text-right">
                                        <button type="button" className="grid_btn_1 grid_btn_darkBlue_common chargePayNow" disabled={addFundValue !== "" ? true : false} onClick={() => payNow()}>
                                            <ButtonLoader IsLoader={reloadBalance.isloader}>Pay Now</ButtonLoader>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            {reloadBalance.response &&
                                <div className="lowBalanceBody">
                                    {!!reloadBalance.errorMessage ? <>
                                        <div class="paymentErrorMessage">
                                            <h2>Payment failed!</h2>
                                            <p>We where not able to charge your credit card. Please try again or update your
                                                credit card details. To update your credit card
                                                <a href={reloadBalance['billingPortalLink']} target="_blanks" class="inline-block link">click here</a> and a new session will
                                                open with your billing portal. After you have updated your credit card
                                                information go back to this session and try the Pay Now button again.</p>
                                        </div>
                                    </> : <>
                                        {!reloadBalance.resp1 ? !reloadBalance.billingPortalLink ?
                                            <div className="paymentSucessfull">
                                                <h2>Payment success!</h2>
                                                <p>Thank you for your payment. You will find your invoice under the menu
                                                    Billing/Subscription or you can <a href="/billing" target="_blank" className="inline-block link">click here</a> and a new session will open with
                                                    your billing portal.</p>
                                            </div>
                                            : <></> : <>
                                            {!reloadBalance.resp1.action_link && !!reloadBalance.resp1.errors && <div className="paymentErrorMessage 3dsecureMesage"><h2>Payment failed!</h2><p>{reloadBalance['resp1'].errors[0]}</p></div>}
                                        </>}
                                    </>}
                                </div>
                            }
                            <div className="padding20 b_top">
                                <h3 className="sms_content">
                                    <strong>Payment Option 2</strong>
                                    <p style={{ marginTop: '30px' }} className="">If you create a balance by adding more funds than needed to pay for this additional charge, we will use the balanced amount for additional resource charges. You are also able to create a low
                                        balance threshold with automatic recharge of your refill amount. You will find your balance under the billing menu or you can <a href="/billing" rel="noreferrer" className="link inline-block">click here</a>.</p>
                                    <br></br>
                                </h3>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Add funds to balance</label>
                                    <div className="f-box">
                                        <input type="text" className="inputformmodel p-60-right" disabled={lowBalaceManagement.resource && lowBalaceManagement.settings && lowBalaceManagement.refill && lowBalaceManagement.threshold ? true : false} value={addFundValue} onChange={e => setAddFund(e.target.value.replace(/[^0-9]/g, ''))} />
                                        <span className="currencyGloabel  text-center">
                                            {lowBlance['balance'].currency}
                                        </span>
                                    </div>
                                    <button type="button" className="grid_btn_1  mr25 grid_btn_darkBlue_common __add__balancePayment2 fright" disabled={addFundValue !== "" ? false : true}
                                        onClick={addFund}
                                    >
                                        <ButtonLoader IsLoader={reloadBalance.isloaderaddFund} color={true}>Add Funds</ButtonLoader>
                                    </button>
                                </div>
                            </div>
                            <div className="padding20 b_top">
                                <h3 className="sms_content addfn">
                                    <strong>Low Balance Management</strong>
                                </h3>
                                <p className="low__balance">
                                    <input type="checkbox" className="lowBalance_checkbox" onChange={() => setGetBlanceSetting(prv => ({ ...prv, isEnableAutoRecharge: !getBlanceSetting.isEnableAutoRecharge }))} />
                                    If you want to apply an automatic recharge will we automatically recharge your account registered credit card. The charge will be bashed on your spesified refill amount and will be performed when the balance falls below your balance threshold.  </p>
                                <div className="padding20 drop-zone padding-left28">
                                    {!getBlanceSetting.isEnableAutoRecharge ? <></> : <div className="checkableMasking"></div>}

                                    <div className="formmodel-bx">
                                        <div className="b__threshold">
                                            <div>
                                                <label className="_l_able_cls">Balance Threshold</label>
                                                <div className="f-box">
                                                    <input type="text" value={lowBalaceManagement.threshold} className="inputformmodel p-60-right"
                                                        onChange={e => setLowBalaceManagement(prv => ({
                                                            ...prv,
                                                            threshold: e.target.value.replace(/[^0-9]/g, '')
                                                        }))}
                                                    />
                                                    <span className="currencyGloabel  text-center">
                                                        {lowBlance['balance'].currency}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <label className="_l_able_cls">Refill Amount</label>
                                                <div className="f-box">
                                                    <input type="text" value={lowBalaceManagement.refill} className="inputformmodel p-60-right"
                                                        onChange={e => setLowBalaceManagement(prv => ({
                                                            ...prv,
                                                            refill: e.target.value.replace(/[^0-9]/g, '')
                                                        }))} />
                                                    <span className="currencyGloabel  text-center">
                                                        {lowBlance['balance'].currency}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="formmodel-bx">
                                        <input type="checkbox" className="inline-block  vertical-top margin-right15 autoCharge" onChange={e => setLowBalaceManagement(prv => ({
                                            ...prv,
                                            resource: e.target.checked
                                        }))} />
                                        <span className="inline-block w90per">I accept that my balance is automatically charged for my accounts additional resource usage.</span>
                                    </div>
                                    <div className="formmodel-bx">
                                        <input type="checkbox" className="inline-block vertical-top margin-right15 lowBalance" onChange={e => setLowBalaceManagement(prv => ({
                                            ...prv,
                                            settings: e.target.checked
                                        }))} />
                                        <span className="inline-block w90per">I accept to be autmatically rechaged according to my low balance management settings.</span>
                                    </div>
                                    <div className="formmodel-bx text-right">
                                        <button type="button" className="grid_btn_1  mr25 grid_btn_darkBlue_common __add__balance" disabled={
                                            lowBalaceManagement.resource && lowBalaceManagement.settings && lowBalaceManagement.refill && lowBalaceManagement.threshold ? false : true
                                        } onClick={lowBalanceManagement}>Save</button>
                                    </div></div>
                            </div>
                        </div> :
                        <div className='padding20'>{lowBlance.message} <a href={`mailto:${APITOKEN.config.subscriptionInfo.SupportUrl}`} className='link inline-block'>{APITOKEN.config.subscriptionInfo.SupportUrl}</a></div> :
                        <div className='padding20'>You don't have sufficient balance in your account. Kindly contact <a href={`mailto:${APITOKEN.config.subscriptionInfo.SupportUrl}`} className='link inline-block'>{APITOKEN.config.subscriptionInfo.SupportUrl}</a></div>
                }
            </div>
            {chargifyDisplay && <ChargifyPayment setChargifyDisplay={setChargifyDisplay} reload={() => { }} />}
        </>
    )
}