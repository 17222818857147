import { useCallback, useEffect, useState } from "react";
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
const PieChart = ({ ...props }) => {
    const { Data } = props;
    var colors = ['#e8f086', '#6fde6e', '#ff4241', '#a691ae', '#235fa4'];
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'pie',
        },
        title: {
            useHTML: true,
            align: 'center',
            verticalAlign: 'middle',
            y: 20,
            style: { "font-size": "18px", "color": "#000", 'margin-bottom': '20px', 'font-family': 'roboto' }

        },
        accessibility: {
            enabled:false  
          },
        subtitle: {
            align: "center",
            useHTML: true,
            style: { "font-size": "18px", "color": "#000", 'marginTop': '20px', 'font-family': 'roboto' }

        },

        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high'
            },

        },

        xAxis: {
            title: {
                text: null
            }
        },
        tooltip: {
            useHTML: true,
            borderColor: '#fff',
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: '#fff',
            formatter: function () {
                return `<div style="width:150px;font-size:14px;"><p  style="color:#666;margin-bottom:10px;font-size:15px;font-weight:bold;">${this.point.title}</p><p style="margin-bottom:10px;color:#666">Total Count : ${this.point.text}</p><p  style="color:#666">Percentage : ${this.point.y}%</p></div>`;
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                showInLegend: true,

                dataLabels: {
                    enabled: false,
                    distance: -10,
                    style: {
                        fontWeight: 'bold',
                        color: 'white'
                    }
                },
                startAngle: -180,
                endAngle: 180,
                center: ['50%', '50%'],
                size: '100%'
            }
        },
        series: [{
            type: 'pie',
            innerSize: '50%',
            colors: colors,
        }]
    });
    const Chart = useCallback(() => {
        if (Data['MatrixResponse'] !== undefined) {
            const newarray = _.unionBy(Data['MatrixResponse'], 'optionRawId');
            const filterData = [];
            newarray.forEach(($v, $i) => {

                filterData.push({ title: "", data: [], catgoriesName: [] });
                filterData[$i].maintitle = Data.WidgetName;
                for (var x of Data['MatrixResponse']) {
                    if ($v['optionRawId'] === x['optionRawId']) {
                        filterData[0].data.push({
                            name: x['optionResponse'][0].optionText,
                            y: x['optionResponse'][0].countPer,
                            text: x['optionResponse'][0].totalCount,
                            title: x.optionRowText === undefined ? '' : x.optionRowText
                        });
                        filterData[0].catgoriesName.push(x['optionResponse'][0].optionText);
                        filterData[0].title = x.optionRowText;
                    };
                };
            });
            setOptionValue(prv => ({
                ...prv,
                series: [{ data: filterData[0].data }],
                xAxis: {
                    categories: filterData[0].catgoriesName
                },
                title: {
                    text: `${filterData[0]['maintitle']}`,
                },
                subtitle: {
                    text: `${(filterData[0]['title'] !== undefined) ? 'Answer: ' + filterData[0].title : ''}`,
                }
            }));
        };
    }, [Data]);
    useEffect(() => {
        Chart();
    }, [Chart])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={optionData} />
        </div>
    )

}

export default PieChart
