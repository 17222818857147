import HighchartsReact from 'highcharts-react-official'
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import Highcharts from 'highcharts';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import InsightBlank from '../../../InsightBlank';
import LoaderBox from '../../../../ReuseTemplate/LoaderBox';
import GridLoader from '../../../../ReuseTemplate/GridLoader';

const Source = ({postObject}) => {
  const { id } = useParams();
  const { APITOKEN } = useSelector(state => state);
  const [sourceArray, setSourceArray] = useState({
    data: [],
    isDisplay: true
  });
  let [isPinned, setIsPinned] = useState([]);
  // const [postObject] = useState({ "locale": "en-IN", "fromDate": "", "toDate": "" });

  const [sourceChartObject, setSourceChartObject] = useState([]);
  Highcharts.setOptions({ colors: ['#aff2bf', '#FFCC44', '#673AB7', '#61C8F7', '#ED254E', "#019788", "#F7A35C", "#3AAC69", "#91E8E1", "#FF7B9C", "#E4D354"], borderWidth: 0 });

  const [DesktopData, setDesktop] = useState({
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    subtitle: {
      text: ''
    },
    accessibility: {
      announceNewData: {
        enabled: true
      },
      enabled:false   
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: ''
      }

    },
    legend: {
      enabled: false
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: false,
          format: '{point.y:.1f}%'
        }
      }
    },

    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
    },

    series: [
      {
        name: "Traffic Soure",
        colorByPoint: true,
        pointWidth: 21,
      }
    ]
  });

  const loadApiCall = useCallback(async () => {
    try {
      (APITOKEN['token'] !== "") &&
        Client.post(`Insights/Survey/${id}/Kpi/Source`, postObject, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        }).then((res) => {
          try {
            setSourceArray(prv => ({
              ...prv,
              data: res['data'],
              isDisplay: false
            }));
            setIsPinned(filterObjectIsPinned(res['data']))
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [APITOKEN, id, postObject]);
  const filterObject = (res) => {
    const filter = [];
    for (let v of res) {
      filter.push({ name: v['sources'], y: v['traffic'] });
    };
    setDesktop(prv => ({ ...prv, series: [{ data: filter }] }))
    return filter;

  }
  const filterObjectIsPinned = (res) => {
    const filter = [];
    for (let v of res) {
      if (v.isPinned) {
        filter.push(v);
      }
    }
    return filter;
  }
  const loadTrafficCall = useCallback(async () => {
    try {
      (APITOKEN['token'] !== "") &&
        Client.post(`Insights/targetSourceCount/${id}`, postObject, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        }).then(async (res) => {
          try {
            await setSourceChartObject(filterObject(res.data));
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [APITOKEN, id, postObject]);
  const pinMethod = (e) => {
    if (APITOKEN['token'] !== "") {
      let response = null;
      if (e['isPinned']) {
        response = Client.delete(`Insights/survey/${id}/KPI/Pin/${e['kpipkid']}`, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        });
      } else {
        response = Client.put(`Insights/survey/${id}/KPI/Pin/${e['kpipkid']}`, {}, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        });
      }
      response.then((res) => {
        res['data']['success'] &&
          loadApiCall();
        loadTrafficCall();
      });
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    setSourceChartObject((array) => {
      return [...sourceChartObject, arrayMoveImmutable(array, oldIndex, newIndex)]
    })
  }
  useEffect(() => {
    loadApiCall();
    loadTrafficCall()
  }, [loadApiCall, loadTrafficCall])

  return (
   

    <div>
      <div className="bx-boder-common margin-bottom">
        <div className="_insight_header white">
          <h3>
            Traffic Source
          </h3>
        </div>
        <div className="padding20 white">
          {DesktopData && <HighchartsReact highcharts={Highcharts} options={DesktopData} />}
        </div>
        <div className={`white ${sourceArray.isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''}`}>
          {sourceArray.isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> :
            <SortableList
              onSortEnd={onSortEnd}
              className="_overviewPin summary_o_v white"
              draggedItemClassName="dragged">{
                sourceArray.data.map((v, i) => {
                  return v.isPinned ? <SortableItem key={i}>
                    <div className="bx-boder-common bx detailsbx white" style={{ display: (v['isPinned']) ? 'inline-block' : 'none' }} ><div className="drop-zone"><button className="pin" style={{ fontSize: '19px' }} onClick={() => pinMethod(v)} type="button"><i className={(v['isPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'}></i></button><h3 className="title">{v['kpiName']}</h3><p className="content paddingbottom20">{v['pinnedDescription']}</p></div><div class="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tr style={{ visibility: (v['kpiCountLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['kpiCountLabel']}</td><td className=" -tvl"><strong>{v['conversions']}</strong></td></tr><tr style={{ visibility: (v['kpiRateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['kpiRateLabel']}</td><td className="-tvl"><strong style={{ display: v['conversionRate'] === -1 ? 'hidden' : 'visible' }}>{v['conversionRate']}%</strong></td></tr></table></div></div>
                  </SortableItem> : <></>
                })}</SortableList>
          }

        </div>
      </div>
      <div className="bx-boder-common white">
        <div className="_h_header ">
          <h3>
            Traffic Performance Per Source
          </h3>
        </div>
        <div className="white">
          {sourceArray.isDisplay ?  <GridLoader TDArray={[1, 2, 3, 4]} />  :
            <table width="100%" cellPadding="0" className="smstable" cellSpacing="0"><thead><tr><th className="table_cell_header">Source</th><th className="table_cell_header">Visitors</th><th className="table_cell_header">Bounce Rate</th><th className="table_cell_header">Action</th></tr></thead>
              <tbody>
                {sourceArray.data.length > 0 && sourceArray.data.map((v, i) =>
                  <tr key={i}>
                    <td className="body_table_cell">{v['sources']}</td>
                    <td className="body_table_cell">{v['visitors']}</td>
                    <td className="body_table_cell" style={{ paddingLeft: '10px' }}><strong>{v['bounceRate']}%</strong></td>
                    <td className="body_table_cell"><button type="button" className="link pinunpin" onClick={() => pinMethod(v)} >{(!v['isPinned']) ? ' Pin' : 'Remove Pin'}</button></td>
                  </tr>
                )}
              </tbody>
            </table>
          }

        </div>
      </div>
    </div >
  )

}

export default Source
