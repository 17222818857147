import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BreadCrum from '../../ReuseTemplate/BreadCrum';
import Client from '../../GlobalAPICall';

const GoogleTagDetails = () => {
    const { APITOKEN } = useSelector(state => state);
    const [smsDetails, setSMSDetails] = useState([]);
    const [showDeletePanel, setDeletePanel] = useState(true);
    const [guidId, setListGuidId] = useState("");
    // Get All list from API Call

    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Connector/GetCampaignsDetailsForGTM?skip=0&limit=10000', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setSMSDetails(res['data']);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN])


    // List Item Delete API Call //
    const deleteList = () => {
        Client.delete(`Connector/DeleteGTMGA/${guidId.campaignId}?type=GTM`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({Data} )=> {
            setDeletePanel(!showDeletePanel);
            load();
        });
    }

    const showDeletePanelFN = (f, item) => {
        setDeletePanel(!showDeletePanel);
        setListGuidId(item);
    }
    const navigate = useNavigate();


    useEffect(() => {
        load();
    }, [load])
    return (<>
        <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle">Google Tag Manager</h1>
                    <p className="sms_content">
                        Google Tag Manager helps you deploy and update measurement tags or tracking code on your websites and mobile apps without having to modify the code manually. Integrate your Google Tag Manager Account with our platform and ensure that you capture and track all click activity from your landing pages.
                    </p>
                </div>
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/configuration" ToText="Configration" CurrentPage="Google Tag Manager" />
        </div>
        <div className="dashbd_sec">
            {showDeletePanel ?
                <>
                    <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                        <div className="_h_header">
                            <h3>My Templates</h3>
                            <span className="flex-right"><button className="grid_btn_1 grid_btn_active" onClick={() => navigate(`/configDomain/tag/create/15`)} type="button">Create New Template</button></span>
                        </div>

                        <table cellPadding="0" cellSpacing="0" className="smstable">
                            <thead>
                                <tr>
                                    <td className="table_cell_header">Campaign Name</td>
                                    <td className="table_cell_header">Tracking ID</td>
                                    <td className="table_cell_header">Actions</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    smsDetails && smsDetails.map((item, index) => {
                                        return <tr key={index}>
                                            <td className="body_table_cell noproperties">{item.campaignName}</td>
                                            <td className="body_table_cell ">{item.googleTagManagerID}</td>
                                            <td className="body_table_cell">
                                            <button className="inline-block link _edit_template noborder nobg pointer"
                                                    onClick={() => navigate(`/configDomain/tag/create/15?edit=true&id=${item.campaignId}`)}>Edit</button>
                                                <button className="inline-block link nobg noborder pointer" onClick={() => showDeletePanelFN(false, item)}>Delete</button></td>

                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </> : <DeletePanel click={() => deleteList()} closeFn={() => setDeletePanel(!showDeletePanel)} />
            }
        </div>
    </>)

}

export default GoogleTagDetails

const DeletePanel = (props) => {
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            <div className="_h_header">
                <h3>Delete</h3>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <tbody>
                    <tr>
                        <td className="body_table_cell white">
                            <div className="_nodata margin-bottom">
                                <div id="nodatamessage">
                                    <strong>
                                        Do you want to delete the template ?.
                                    </strong>
                                    <br></br>
                                    <br></br>
                                    This will permanently delete this template and it can not be restored.
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={props.closeFn}>Cancel</button>
                                <button type="button" className="grid_btn_1 deleteBtn" onClick={props.click}>Delete</button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div></>)
}