import { useEffect, useState } from "react"
import Client from "../../../GlobalAPICall"
import * as filestack from 'filestack-js';
import { CaptionAPI } from "./ImportVideo";
const client = filestack.init('APBcz9XF7TEmEQpcF5wmxz');

export const VideoCaption = ({ APITOKEN ,Object ,Close }) => {
    const [captionObject, setCaption] = useState({
        data: [],
        selected: "",
    });
    const onCaptionClick = async () => {
        const options = {
            maxFiles: 1,
            fromSources: ["local_file_system"],
            uploadInBackground: false,
            onOpen: () => console.log('opened'),
            onUploadDone: async (res) => {
                const { data } = await CaptionAPI(Object.videoId, res['filesUploaded'][0].url, APITOKEN,captionObject.selected);
                console.log(data)
                Close(prv => ({
                    ...prv,
                    captionSliderDisplay: false
                }))
            },
        };
        await client.picker(options).open();
    }

    useEffect(() => {
        try {
            Client.get(`Mobile/CaptionLanguage`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setCaption(prv => ({
                            ...prv,
                            data
                        }))
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => console.error(err));
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN])
    return (
        <>
            <div className="formmodel-bx">
                <label className="_l_able_cls">Language</label>
                <select className="inputformmodel" onChange={e => setCaption(prv => ({
                    ...prv,
                    selected: e.target.value
                }))}>
                    <option value="">Select</option>
                    {captionObject.data.map((v, i) => <option value={v.code} key={i}>{v.name}</option>)}
                </select>
            </div>
            <div className="formmodel-bx">
                <button typeof="button" className="grid_btn_1 width100" onClick={onCaptionClick}>
                    Choose file
                </button>
            </div>
        </>
    )

}

export default VideoCaption