import { useCallback, useEffect, useState } from 'react';
import './preview.css'
import Client from '../GlobalAPICall';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

const CampaignPreview = ({ ...props }) => {
    const { Close, Link, Id } = props;

    const { APITOKEN } = useSelector(state => state);
    const [tabId, setTab] = useState(0);


    const load = useCallback(() => {
        debugger
        APITOKEN['token'] &&
            Client.get(`User/EditorMenu/${Id}/${Link.id}`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(async (res) => {
                try {
                    setPreviewPages(prv => ({
                        ...prv,
                        data:res.data
                    }))
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => console.error(err.response.request.response));
    },[APITOKEN,Link,Id])


    const [pages, setPreviewPages] = useState({
        data: [],
        selected: Link.PreviewUrl
    })
    const tabClick = (id) => {
        setTab(id)
    };
    useEffect(() => {
        load();
    },[load])

    return (
        <div className="campaign-wrap" style={{ visibility: 'visible' }}>
            <div className="close_preview" style={{ display: 'block' }}>
                <ul style={{ display: 'flex' }}>
                    <li className={tabId === 0 ? "active" : ""} onClick={() => tabClick(0)}>Desktop View</li>
                    <li className={tabId === 1 ? "active" : ""} onClick={() => tabClick(1)}>Tablet View</li>
                </ul>
                <button className="btn-class btn-green removePreview" onClick={() => Close(false)}><i className="fas fa-arrow-left"></i>Return</button>
                {(Id === '2' || Id === '4') ? <select className="input_Class previewmode-pagedrpdwn" onChange={e => setPreviewPages(prv => ({
                    ...prv,
                    selected: e.target.value
                }))} >
                    {pages.data.map((v, i) => <option key={i} value={v.pageUrl}>{v.pageName}</option>)}
                </select> : <></>}
            </div>
            <div className={`${tabId === 1 ? "ipadView-active" : ""} tempMain_wrapper dragable_box transition_class PrevH_active slideAnimation_active`} style={{ marginTop: '55px' }}>
                <div id="previewLandingpage" className="RowsortConnect RowconnectedSortable ui-sortable">
                    <iframe className="noborder" title="campaignPreview" src={pages.selected} width="100%" style={{ height: '97vh' }} webkitallowfullscreen="" mozallfullscreen="" allowFullScreen=""></iframe>
                </div>
            </div>
            <div className="EMtemplate_mobilePreview_outer slideAnimation_active emulator_active" style={{ height: '100%' }}>
                <div className="EMtemplate_mobilePreview transition_class">
                    <div className="iframe-preview">
                        <iframe className="noborder" title="campaignMobilePreview" src={pages.selected} width="100%" height="100%" webkitallowfullscreen="" mozallfullscreen="" allowFullScreen=""></iframe>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default CampaignPreview
