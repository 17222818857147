import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
const AgePie = (props)=>{
    Highcharts.setOptions({
        colors: ['#7bb5ec', '#434348', '#90ed7d', "#f7a35c", "#8085e9", "#f15c7f", "#e5d453", "#2b908e", "#f45a5b", "#91e8e1"]
    });
    const [genderData] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',


        },
        title: {
            text: 'Age',
            align: 'center'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            },
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                pie: {
                    size: '100%',
                    dataLabels: {
                        enabled: false
                    },

                }
            }
        },
        series: [{
            type: 'pie',
            colorByPoint: true,
            innerSize: '50%',
            name: 'Age',
            data: props.ChartData
        }]
    })
    return(
        <HighchartsReact highcharts={Highcharts} options={genderData} />

    )
}
export default AgePie;