import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router';
import axios from 'axios';

const Visitor = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();

    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: '',
            align: 'right',
            verticalAlign: 'bottom'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'datetime',
            labels: {
                formatter: function () {
                    const date = this.value;
                    var splitedate = date.split('-');
                    return splitedate[2]
                }
            }

        },
        yAxis: {
            title: {
                text: ''
            }

        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: '',

        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,

                },
                cursor: 'pointer',
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {

                name: "Visitors",
            }
        ]
    });
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/landingpage/${id}/visitors`)
                .then(({ data }) => {
                    const { PerformanceKPI } = data[0] || "[]";
                    dataFilter(JSON.parse(PerformanceKPI));

                }).catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                });
    }, [APITOKEN, id]);

    const dataFilter = response => {
        const data = [...response.map(item => item.Count)];
        const CaptuerdAt = [...response.map(item => item.CaptuerdAt)];
        setOptionValue(prv => ({
            ...prv,
            series: [{ data }],
            xAxis: { categories: CaptuerdAt }
        }));
    }


    return (
        <>
            <h3 className="_chart_title padding-top20 padding20">Visitors in period</h3><div className="b_top padding20"><HighchartsReact highcharts={Highcharts} options={optionData} /></div>
        </>
    )
}
export default Visitor;