import React, { useState } from 'react'
import ButtonLoader from '../../ReuseTemplate/ButtonLoader'
import Client from '../../GlobalAPICall';
import { useDispatch, useSelector } from 'react-redux';

function TwoWayAuth({ APITOKEN }) {
    const [isAuth, setAuth] = useState({ isChanged: true, value: APITOKEN.config.subscriptionInfo.IsMFAEnabled });
    const [isloader, setLoader] = useState(false);
    const [showValidation, setValidation] = useState({ display: false, message: "" });
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    const authentication = () => {
        setLoader(true);
        Client.put(`User/mfa/${isAuth.value ? 'enable' : 'disable'}`, {})
            .then(({ data }) => {
                try {
                    setLoader(false);
                    setValidation({ display: false, message: "" });
                    if (data.success) {
                        setAuth(prv => ({ ...prv, isChanged: true }));
                        setValidation({ display: true, message: data.message });
                        dispatch({
                            type: "CREATE_TOKEN", payload: {
                                ...state.APITOKEN,
                                config: {
                                    ...state.APITOKEN.config,
                                    subscriptionInfo: {
                                        ...state.APITOKEN.config.subscriptionInfo,
                                        IsMFAEnabled: isAuth.value
                                    }
                                }
                            }
                        });
                    } else {
                        setValidation({ display: true, message: data.message });
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                setValidation({ display: true, message: err.response.data.message });
                setLoader(false);
            })

    }

    return (
        <div className=" bx-boder-common width100  margin-bottom white">
            <div className="_h_header">
                <h3>Two Factor Authentication (2FA)</h3></div>

            <div className="padding20">
                <div className="formmodel-bx">
                    <label className="_l_able_cls ">
                        <strong className='inline-block vertical-middle pointer'>Enable Two Factor Authentication &nbsp;</strong>
                        <input type='checkbox' className='inline-block vertical-middle' checked={isAuth.value} onChange={event => { setAuth(prv => ({ ...prv, value: event.target.checked, isChanged: false })); setValidation({ display: false, message: "" });}} />
                    </label>
                </div>
                {showValidation.display && <p className='validation'>{showValidation.message}</p>}
                <div className=" text-right margin-top50 ">
                    <button type="button" className="grid_btn_1 grid_btn_active __Save_link" disabled={isAuth.isChanged} onClick={authentication}>
                        <ButtonLoader IsLoader={isloader}>Save</ButtonLoader>
                    </button>
                </div>
            </div>
        </div>

    )
}

export default TwoWayAuth
