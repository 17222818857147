import getLocaleDateString from "../../../../TimeFormat";
import moment from "moment";
import BlankData from "../../../../BlankData";
import GridLoader from "../../../../ReuseTemplate/GridLoader";
import Client from "../../../../GlobalAPICall";
import { useState } from "react";
import { LoaderGrid } from ".";

const OngoingResponse = (props) => {
    const { Data, Loader ,APITOKEN } = props;
    const [objectResponse, setObject] = useState({
        display: false,
        data: [],
        loader: false
    })
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const keysLength = Object.keys(Data);
    const surveyReponse = item => {
        setObject(prv => ({
            ...prv,
            display: true,
            loader: true
        }))
        Client.get(`insights/getsurveyanswer/${Data.surveyId}?responseSessionId=${item.response_Session_id}`, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    setObject(prv => ({
                        ...prv,
                        data,
                        loader: false
                    }))
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }
    return (
        <>
            <div className="overviewtable margin-top50 bx-boder-common white">
                <div className="_h_header ">
                    <h3> Ongoing Responses</h3>
                    {keysLength.length > 0 && Data.onGoingResponses.length === 0 ? <></> : <span className="flex-right responehide_cls">
                        <span className="inline-block font-16"><strong>Total Survey Score:</strong></span>
                        <span className="_score font-16" style={{ marginRight: '60px' }}>{Data.surveyScore}</span>
                        <span className="inline-block font-16">
                            <strong>Goal:</strong>
                        </span>
                        <span className="_goal font-16" style={{ marginRight: '60px' }}>{Data.goalScore}</span>
                    </span>
                    }
                </div>
                <div className="respinseGrid">
                    {Loader ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                        <table cellPadding="0" cellSpacing="0" width="100%" className="smstable">
                            {keysLength.length > 0 && Data.onGoingResponses.length === 0 ? <></> :
                                <thead id="_thtrigger_onGoingResponses">
                                    <tr>
                                        <th className="table_cell_header">Survey Id</th>
                                        <th className="table_cell_header">First Name</th>
                                        <th className="table_cell_header">Last Name</th>
                                        <th className="table_cell_header">Survey Started</th>
                                        <th className="table_cell_header">Score</th>
                                        <th className="table_cell_header">Score Rate</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {

                                    keysLength.length > 0 && Data.onGoingResponses.length === 0 ? <BlankData title="There are no new responses yet." content="" colSpan="7" /> : keysLength.length > 0 && Data.onGoingResponses.map((v, i) => <tr key={i}>
                                        <td className="body_table_cell"><button type="button" onClick={() => surveyReponse(v)} className="link">{v['responseId']}</button></td>
                                        <td className="body_table_cell">{v['firstName']}</td>
                                        <td className="body_table_cell">{v['lastName']}</td>
                                        <td className="body_table_cell">{moment(v['surveyCompletetionTime']).format(f)}</td>
                                        <td className="body_table_cell">{v['score']}</td>
                                        <td className="body_table_cell">{v['scoreRate']}%</td>
                                    </tr>)
                                }

                            </tbody>
                        </table>
                    }
                </div>
            </div>
            {objectResponse.display && <SurveyPopup setObject={setObject} object={objectResponse} />}

        </>
    )

}

export default OngoingResponse;


const SurveyPopup = (props) => {
    const { setObject, object } = props;
    return (
        <div className="videoCaptionHtml">
            <div className="videoCaptionContainer">
                <div className="topn-header">
                    <span className="selected-title">Survey Ongoing Responses</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => setObject(prv => ({
                        ...prv,
                        display: false
                    }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="padding20">
                    {object.loader ? <LoaderGrid /> : object.data.length > 0 ? object.data.map((x, i) =>
                        <div className="bx-boder-common margin-bottom" key={i}>
                            <div className="_h_header">
                                <h3>{x['questionText']}</h3>
                            </div>
                            <div className="padding20">
                                {x['singleAnswer'] && x['singleAnswer'].map((y, index) =>
                                    <span key={index}> {index > 0 && <span> , </span>} {y['optionText']}</span>)}
                                {x['matrixAnswer'] && x['matrixAnswer'].map((y, index) =>
                                    <div className={index > 0 ? 'b_top' : ''} key={index}>
                                        <p style={{ margin: '10px 0' }}><strong>{y.rowText}</strong>:- <span>{y.colText}</span></p>
                                    </div>
                                )}

                            </div>
                        </div >
                    ) : <> <div id="nodatamessage">
                        <strong>No Data Found</strong><br></br><br></br>
                    </div></>}

                </div>
            </div >
        </div >
    )
}

