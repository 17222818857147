import { CREATE_TOKEN, DELETE_TOKEN, EMAILUPLOADFILE, INSIGHT_DETAILSOBJECT, LOGO, NAVIGATIONTOGGLE, ONBORDINGSTEPS } from './Type';

export default function token(state = {
    token: "",
    TokenObject: {},
    config: {}
}, { type, payload }) {
    switch (type) {

        case CREATE_TOKEN:
            return {
                ...state,
                token: payload.token,
                TokenObject: payload.TokenObject,
                config: { ...state.config, ...payload.config }
            };

        case DELETE_TOKEN:
            return {
                token: "",
                TokenObject: {},
                config: {}
            };

        default:
            return state;
    }
}

export function InsightSMSDetails(state = {}, { type, payload }) {
    switch (type) {
        case INSIGHT_DETAILSOBJECT:
            return { ...state, ...payload };

        default:
            return state;
    }
}
export function OnboardingStep(state = {}, { type, payload }) {
    switch (type) {
        case ONBORDINGSTEPS:
            return { ...state, ...payload };

        default:
            return state;
    }
}

export function NavigationToggleReduce(state = false, { type, payload }) {
    switch (type) {
        case NAVIGATIONTOGGLE:
            return state = payload;

        default:
            return state;
    }
}
export function LogoReducer(state = "", { type, payload }) {
    switch (type) {
        case LOGO:
            return state = payload;

        default:
            return state;
    }
}
export function FileImport(state = [], { type, payload }) {
    switch (type) {
        case EMAILUPLOADFILE:

            return state = payload;

        default:
            return state;
    }
}