import React from 'react'
import { useEffect, useState } from 'react'
import Client from '../../GlobalAPICall';
function AppStore({ ...props }) {
    const [smsContentData, setContent] = useState([]);
    const { ChangeEvent, Value, APITOKEN, isRequired = false } = props;
    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get('Mobile/Apps', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setContent(res['data']);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
        return (() => {
            setContent([]);
        })
    }, [APITOKEN])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">Apps {(isRequired === true) ? <span className="error-message">*</span> : ""}</label>
            <select className="inputformmodel" value={Value.appStore} onChange={e => ChangeEvent(prv => ({
                ...prv,
                appStore: e.target.value
            }))}>
                <option value="">Select</option>
                {smsContentData.map((v, i) => <option key={i} value={v.id}>{v.title}</option>)}
            </select>
        </div>
    )
}

export default AppStore
