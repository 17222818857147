import moment from "moment";
import { useSelector } from "react-redux";
import getLocaleDateString from '../../TimeFormat';
import { useNavigate } from "react-router";
import Cookies from 'js-cookie'
import { encode as btoa } from 'base-64'
export const ALLTab = ({ ...props }) => {
    const { Data, ArchieveFN, isLoading } = props;
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const editCampagin = ev => {
        if (ev.redirectToDomain !== "") {
            const secreatKey = Cookies.get('secretKey');
            window.location.replace(`${ev.redirectToDomain}${ev.editUrl}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}`);
        } else {
            navigate(ev.editUrl);
        }
    }
    return (

        <> {isLoading ? <div></div> : <div className=" bx-boder-common width100 nobg">
            <div className="_h_header white noborder border-radius2"><h3 className="_t_i">ALL</h3></div>
            <div className="_list_fx">
                {Data && Data.map((v, index) => <div key={index}>
                    <div className="list_title_folder">
                        <div className="topbarListing white b_top"><h3 className="gray">{v['dateString']} ({v['totalCount']})</h3></div>
                    </div>
                    <div className="list_table_listing">
                        <table cellPadding="0" cellSpacing="0" className="smstable white" width="100%">
                            <tbody>
                                <tr>
                                    <th className="table_cell_header">Name</th>
                                    <th className="table_cell_header">Description</th>
                                    <th className="table_cell_header">Content</th>
                                    <th className="table_cell_header">Created</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                            </tbody>
                            <tbody className="table_listing white">
                                {v['contentDetails'] && v['contentDetails'].map((v, i) => <tr key={i}>
                                    <td className="body_table_cell">{v['name']}</td>
                                    <td className="body_table_cell">{v['description']}</td>
                                    <td className="body_table_cell">{v['contentType']}</td>
                                    <td className="body_table_cell">{moment(v['created']).format(f)}</td>
                                    <td className="body_table_cell"><button type="button" onClick={()=> editCampagin(v)}
                                        className="inline-block link">Edit</button>&nbsp; | &nbsp;
                                        <button type="button" className="inline-block link -archived-link" onClick={() => ArchieveFN(v.id)}>Archive</button></td></tr>)}

                            </tbody>
                        </table>
                    </div>
                </div>)}

            </div>

        </div>}</>

    )

}


