import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import { ConversionGoal } from "../../Goal/ConversionGoal";
import { PerformanceGoal } from "../../Goal/PerformanceGoal";
import { SendingGoal } from "../../Goal/SendingGoal";
import StepsDistribution from "../StepsTopBar";

const GoalSMS = () => {
    const [showGoalPanel, setShowGoal] = useState(true);
    const [goalArrayObject, setGoalDetails] = useState([]);
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);
    const [ buttonDisplay,setGoalButtonDisplay] = useState(true);
    const { id } = useParams();
    const load = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Insights/distribution/goals/${id}/setting_details`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setGoalDetails(data);
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.error(err);
                })
    }, [APITOKEN, id])

    useEffect(() => {
        load()
    }, [load])
    return (
        <>
        <StepsDistribution Name="SMS" Active={3}/>
            {showGoalPanel ?
                <div className="dashbd_sec">
                    <div className="sms_Sec_1 bx-boder-common width100">
                        <>
                            <div className="_h_header _insight_header">
                                <h3>Set your Goals</h3>
                            </div>
                            <div className="padding20 text-right">
                                <div id="nodatamessage" className="margin-bottom">
                                    <strong>We highly recommend you to set goals for your sending.</strong>
                                    <br></br>
                                    <br></br>
                                    Setting goals gives a long-term vision and short-term motivation. It focuses your acquisition of knowledge and help to organize time and resources to reach the goals and improve your ROI.

                                </div>
                                <button className="grid_btn_1 noborder nobg btn-gape-15 skip" type="button" onClick={() => navigate(`/SMS/SMSsummary/${id}`)}>Skip Goal Settings</button>
                                <button className="grid_btn_1 grid_btn_active btn-gape-15" type="button" onClick={() => setShowGoal(false)}>Set Your Goals</button>
                            </div>

                        </>
                    </div>
                </div>
                : <>
                    {goalArrayObject.length > 0 && goalArrayObject.map((v, i) =>
                        <div key={i}>{v.Id === 25 ? <SendingGoal Data={v} ButtonDisplay={setGoalButtonDisplay}  /> : v.Id === 26 ? <PerformanceGoal Data={v} ButtonDisplay={setGoalButtonDisplay}/> : <ConversionGoal Data={v} ButtonDisplay={setGoalButtonDisplay} />}</div>
                    )}
                    <div className="padding20 text-right dashbd_sec">
                        <button type="button" className="grid_btn_1 noborder nobg btn-gape-15" onClick={() => navigate(`/SMS/SMSsummary/${id}`)}>Cancel</button>
                        <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" disabled={
                            buttonDisplay
                        } onClick={()=> navigate(`/SMS/SMSsummary/${id}`)}>Continue</button>
                    </div>
                </>}

        </>
    )
}
export default GoalSMS;