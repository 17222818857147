import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";

export const MergeCodes = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [mergeCode, setMergeCode] = useState([]);
    const [pageCount, setPagCount] = useState(1);

    const { onClick, Value, CloseShortCode } = props;
    const load = useCallback(() => {
        (APITOKEN.token !== "") &&
            Client.options(`Lookup/hashtags?Pageno=${pageCount}&PageSize=9&hasTagsFor=SMS`, {
                data: [],
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setMergeCode(res.data);
                    } catch (err) {
                        console.err(err);
                    }
                })
    }, [APITOKEN,pageCount])

    const nextPage = () => {
        setPagCount(pageCount + 1)
    }
    const previousPage = ()=>{
        setPagCount(pageCount - 1)
    }


    useEffect(() => {
        load();
    }, [load])
    return (
        <div className="mergecode" style={{ top: '0px', position: 'absolute', marginTop: '-219px', right: '-430px', width: '400px', zIndex: '11' }}>
            <div className="txt_preiv extraclass">
                <div className="_h_header noborder">
                    <h3>Preview SMS Trigger</h3>
                </div>
                <div className="_p_b_x noborder">
                    <div className="senderTextPreivew">
                        {Value.smsText}
                    </div>
                </div>
            </div>
            <div className="bx_1__bx mergecode extraclass">
                <div className="_h_header noborder">
                    <h3>Merge Codes</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1" onClick={CloseShortCode}>Close</button>
                    </span>
                </div>
                <div className="_p_b_x noborder">
                    <div className="formmodel-bx">
                        <p>Click or drag and drop your preferred Merge Codes into your SMS text.This will dynamically embed your contacts CRM data into your sending.</p>
                    </div>
                    <div className="formmodel-bx">
                        {mergeCode.map((v, i) => <button key={i} type="button" className="hashlink pointer" onClick={() => onClick(v)}>{v.displayName}</button>)}
                    </div>
                    <div className="formmodel-bx text-right">
                        <span className="pagination_count">
                            1 - {mergeCode.length} of {pageCount}
                        </span>
                        <button type="button" className="bigtext" disabled={pageCount === 1?true:false} onClick={previousPage}>
                            <i className="fas fa-caret-left"></i>
                        </button>
                        <button type="button" className="bigtext" disabled={mergeCode.length < 9?true:false} onClick={nextPage}>
                            <i className="fas fa-caret-right"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

