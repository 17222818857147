import BrowserChart from "./BrowserChart"
import DesktopChart from "./DesktopChart"
import MobileChart from "./MobileChart"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import BlankData from '../../../../BlankData'
import { DeviceLoader } from "../../../../ReuseTemplate";
const Device = ({ postObject }) => {
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [deviceData, setDeviceData] = useState([]);
    const [loader, setLoader] = useState(false);

    const loadApiCall = useCallback(async () => {
        setLoader(true);
        try {
            (APITOKEN['token'] !== "") &&
                Client.post(`Insights/Survey/${id}/device`, postObject, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    setLoader(false);
                    try {
                        if (Object.keys(data).length === 0) {
                            setDeviceData([]);
                        } else {
                            setDeviceData(data);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
            setLoader(false);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id, postObject]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {loader ? <DeviceLoader /> : Object.keys(deviceData).length === 0 ?
                <div className=" bx-boder-common white">
                    <table cellPadding="0" cellSpacing="0" width="100%">
                        <tbody>
                            <BlankData title="There is no data available yet." content="" colSpan="5" />
                        </tbody>
                    </table>
                </div> :
                <>
                    {!deviceData.mobileDevice ? <></> : <MobileChart Data={deviceData.mobileDevice} />}
                    {!deviceData.browser ? <></> : <BrowserChart Data={deviceData.browser} />}
                    {!deviceData.desktop ? <></> : <DesktopChart Data={deviceData.desktop} />}
                </>
            }
        </>
    )
}
export default Device