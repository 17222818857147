import { Link } from "react-router-dom";
import './index.css';
import Promoters from "./Promoters";
import NPSTrend from "./NPSTrend";
import ScoringTrend from "./ScoringTrend";
import ResponseNps from "./ResponseNps";
import Feedback from "./Feedback";
import NPSScore from "./NPSScore";
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import getLocaleDateString from "../../TimeFormat";
import moment from "moment";
import NPSFilterContainer from "./NPSFilterContainer";

const NPS = () => {
    const { APITOKEN } = useSelector(state => state);
    const [npsData, setNpsData] = useState([]);
    const [isdisplayNps, setNPSFilterDisplay] = useState(false);
    const [postObject, setPostObject] = useState(
        {
            campaignList: [],
            dateSpan: "",
            locale: "en-IN",
            fromDate: "",
            toDate: "",
            peoplesuscriberList: [],
            elasticSegmentJson: "",
            subscriberList: []
        }
    )
    const [DesktopData, setDesktop] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            width: 290,
            height:400

        },
        title: {
            text: '',
            align: 'center',


        },
        accessibility: {
            enabled: false
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        pane: {
            center: ['50%', '20%'],
            size: '50%',
            startAngle: -90,
            endAngle:72,
            align: 'center',

            background: {
                borderWidth: 0,
                backgroundColor: 'none',
                innerRadius: '50%',
                outerRadius: '70%',
                shape: 'arc'
            }
        },

        yAxis: [{
            lineWidth: 0,
            min: 0,
            max: 90,
            minorTickLength: 0,
            tickLength: 0,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            title: {
                text: '',
                useHTML: false,
                y: 0
            },
            pane: 0,
        }],
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: 0,
                },
                align: 'center',
                size: 180,
                innerSize: 50,
                colors: ['#ed254e', '#ffcc44', '#3bac69'],
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '18%'],
                borderWidth: 0 // < set this option
            },
            gauge: {
                dataLabels: {
                    enabled: false
                },

            }
        },
    });

    const filterObject = res => {
        setDesktop(prv => ({
            ...prv,
            series: [{
                type: 'pie',
                name: 'Net Score',
                innerSize: '60%',

                data: [
                    ["", 40],
                    ["", 10],
                    ["", 50],

                ]
            }, {
                type: 'gauge',
                dial: {
                    rearLength: 0
                },
                data: [res[0].graphScore]
            }]

        }));
        setNpsData(res);
        return res;
    }
    const load = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.post(`Insights/NPSdashboard/ScorePerformance`, postObject, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            filterObject(res.data);
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        console.error(err);
                    })
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, postObject]);
    const filterFN = () => {
        setNPSFilterDisplay(true);
    }
    useEffect(() => {
        load();
    }, [load])
    const f = getLocaleDateString(APITOKEN.config.cultureCode)
    return (
        <>
            <div className="dashbd_sec">
                &nbsp;
            </div>
            <div className="dashbd_sec margin-top50">
                <div className="mycontent">
                    <ul className="tabing">
                        <li><Link className="active">NPS Report</Link></li>
                    </ul>
                    <button type="button" className="grid_btn_1 fright npn_filter" onClick={filterFN}>Filters</button>
                </div>
                {
                    npsData[0] &&
                    <div className="tab_body">
                        <div className="bx-boder-common white">
                            <div className="_h_header noborder" style={{ justifyContent: 'space-between' }}>
                                <h3>Net Performance Score Report</h3>
                            </div>
                            <p>
                                <span className="font-16" style={{ fontSize: '16px', paddingLeft: '17px' }}>{moment(npsData[0].fromDate).format(f).substring(0, 10)} - {moment(npsData[0].toDate).format(f).substring(0, 10)}</span>
                            </p>
                            <div className="padding20 nps_reportbx ">
                                <div className=" bx-boder-common white  report_first">
                                    <NPSScore PostObject={postObject} ChartOption={DesktopData} Data={npsData[0]} />
                                </div>
                                <div className="report_second bx-boder-common white">
                                    <Promoters PostObject={postObject} />
                                </div>

                            </div>

                            <NPSTrend PostObject={postObject}  SetNPSFilterDisplay={setNPSFilterDisplay} />
                            <ScoringTrend PostObject={postObject}  SetNPSFilterDisplay={setNPSFilterDisplay} />
                            <ResponseNps PostObject={postObject}  SetNPSFilterDisplay={setNPSFilterDisplay} />
                            <Feedback PostObject={postObject}  SetNPSFilterDisplay={setNPSFilterDisplay} />
                        </div>
                    </div>
                }
            </div>
            {isdisplayNps ? <NPSFilterContainer SetPostObject={setPostObject} SetNPSFilterDisplay={setNPSFilterDisplay} /> : <></>}
        </>
    )
}
export default NPS;