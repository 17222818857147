import React, { useEffect, useState } from 'react';

const OpeningHours = ({ sendDataToParent, setMobilePreviewData, onCancel }) => {

  const defaultHoursData = {
    isOpeningHoursVisible: false,
    mondayOpeningHours: "",
    tuesdayOpeningHours: "",
    wednesdayOpeningHours: "",
    thursdayOpeningHours: "",
    fridayOpeningHours: "",
    saturdayOpeningHours: "",
    sundayOpeningHours: "",
  };
  const [storeOpeningHours, setStoreOpeningHours] = useState(defaultHoursData);

  const handleRadioChange = (e) => {
    const isVisible = e.target.value === 'yes';
    setStoreOpeningHours((prevState) => ({
      ...prevState,
      isOpeningHoursVisible: isVisible,
    }));
  };

  const handleInputChange = (e, day, type) => {
    const value = e.target.value;
    const currentHours = storeOpeningHours[`${day}OpeningHours`];
    let newHours;

    if (type === 'from') {
      const toTime = currentHours.split(' - ')[1] || '';
      newHours = `${value} - ${toTime}`;
    } else {
      const fromTime = currentHours.split(' - ')[0] || '';
      newHours = `${fromTime} - ${value}`;
    }

    setStoreOpeningHours((prevState) => ({
      ...prevState,
      [`${day}OpeningHours`]: newHours,
    }));
  };

  useEffect(() => {
    setMobilePreviewData(storeOpeningHours, 4);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeOpeningHours]);

  const handleSubmit = () => {

    sendDataToParent(storeOpeningHours, 4);
  };

  return (
    <div className="bx-boder-common width100 nobg mt-8">
      <div className="_h_header white border-radius2">
        <h3>Opening hours</h3>
      </div>

      <div className="p-5 white">
        <div className="row">
          <div className="col-7">
            <div className="formmodel-bx">
              <label className="_l_able_cls">Display opening hours in app</label>
              <div className="flex align-items-center gap-5">
                <label htmlFor="yes">
                  <input
                    type="radio"
                    className="inline-block me-2"
                    id="yes"
                    name="displayopeninghours"
                    value="yes"
                    checked={storeOpeningHours.isOpeningHoursVisible}
                    onChange={handleRadioChange}
                  />
                  Yes
                </label>

                <label htmlFor="no">
                  <input
                    type="radio"
                    className="inline-block me-2"
                    id="no"
                    name="displayopeninghours"
                    value="no"
                    checked={!storeOpeningHours.isOpeningHoursVisible}
                    onChange={handleRadioChange}
                  />
                  No
                </label>
              </div>
            </div>

            {storeOpeningHours.isOpeningHoursVisible && (
              ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
                <div className="formmodel-bx" key={day}>
                  <label className="_l_able_cls">{day.charAt(0).toUpperCase() + day.slice(1)}</label>
                  <div className="flex gap-3">
                    <div>
                      <label className="block mb-1">From Time</label>
                      <input
                        type="time"
                        className="inputformmodel width186"
                        value={storeOpeningHours[`${day}OpeningHours`].split(' - ')[0] || ''}
                        onChange={(e) => handleInputChange(e, day, 'from')}
                      />
                    </div>
                    <div>
                      <label className="block mb-1">To Time</label>
                      <input
                        type="time"
                        className="inputformmodel width186"
                        value={storeOpeningHours[`${day}OpeningHours`].split(' - ')[1] || ''}
                        onChange={(e) => handleInputChange(e, day, 'to')}
                      />
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>

        <div className="formmodel-bx text-right mt-12 mb-0">
          <button
            type="button"
            className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg"
            onClick={() => onCancel(storeOpeningHours, 4)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary btn-gape-15"
            onClick={handleSubmit}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default OpeningHours;
