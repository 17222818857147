import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import LineChart from "../../../Charts/LineChart";
import BarChart from "../../../Charts/BarChart";
import StackBar from "../../../Charts/StackBar";
import StackLine from "../../../Charts/StackLine";
import PieChart from "../../../Charts/PieChart";
import Client from "../../../../GlobalAPICall";
import { useParams } from "react-router";
import CompleteDetails from "../../../SMS/Details/CompleteDetails";
import ColumnVertical from "../../../Charts/ColumnVertical";
import ColumnHorizental from "../../../Charts/ColumnHorizental";

const WedgetGrid = ({ ...props }) => {
  // const { InsightSMSDetails } = useSelector(state => state);
  const wrapperRef = useRef(null);
  const { setEngagmentData, Data } = props;
  // const [engagmentData, setEngagmentData] = useState([...wedgetData]);
  const [isDisplay, setDetailsDisplay] = useState({ flag: false, object: {} });
  const { APITOKEN } = useSelector(state => state);
  const { id } = useParams();

  // const showCompleteDetails = (childObject, parentObject) => {
  //     setDetailsDisplay(prv => ({ ...prv, flag: true, object: childObject, WidgetId: parentObject.WidgetId }));
  // }
  const widgetPanel = useCallback(() => {
    Data &&
      Data.forEach(async function (v) {
        if (v["KPICoupon"] !== undefined) {
          v["CouponFlag"] = true;
          v["KPICoupon"].forEach(function (k, _$i) {
            setEngagmentData(prv => [
              ...prv,
              ...[
                {
                  WidgetName: `${v["WidgetName"]}`,
                  WidgetPreviewImage: k["CouponImagePath"],
                  KPI: k["KPI"],
                  IsWidgetDeleted: v["IsWidgetDeleted"],
                  WidgetId: v["WidgetId"],
                  CouponId: k["CouponId"],
                  WidgetElementValue: k["CouponTagName"],
                },
              ],
            ]);
          });
        }
      });
  }, [Data, setEngagmentData]);
  const chartShowFn = (e, widgetObject) => {
    e.stopPropagation();
    Data.length > 0 &&
      Data.forEach(async function (v) {
        if (v["WidgetId"] === widgetObject["WidgetId"]) {
          v["chartShow"] = true;
        } else {
          v["chartShow"] = false;
        }
      });
    setEngagmentData([...Data]);
  };
  const chartChange = (parentObject, chartObject) => {
    Data.forEach(async function (v) {
      if (v["WidgetId"] === parentObject["WidgetId"]) {
        v["SelectedChart"] = chartObject;
        v["chartShow"] = false;
      }
    });

    const obj = {
      chartType: chartObject,
      questionId: parentObject["WidgetId"],
    };
    try {
      Client.post(`Insights/Survey/${id}/engagementChart`, obj, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then(res => {
        try {
          if (res.data.success) {
            setEngagmentData([...Data]);
          }
        } catch (err) {
          console.log(err);
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  };
  useEffect(() => {
    //  setEngagmentData(Data);
    widgetPanel();
    function handleClickOutside() {
      Data.length > 0 &&
        Data.forEach(async function (v) {
          v["chartShow"] = false;
        });
      setEngagmentData([...Data]);
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [widgetPanel, Data, setEngagmentData]);

  return (
    <>
      <div ref={wrapperRef}></div>
      {Data.length > 0 &&
        Data.map((v, i) => {
          return (
            <div key={i}>
              <div
                className="inline-block verticalalign"
                style={{ width: "100%" }}
              >
                <table
                  width="100%"
                  className="widgetcls"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>
                        <span className="sms_title_engagment">
                          {/* {InsightSMSDetails.typeId === 4 ? 'Survey Widget' : 'Landing Page Widget'} */}
                          Survey Widget
                        </span>
                      </th>
                      <th>Content</th>
                      <th>
                        {v["questionFlag"] !== undefined
                          ? "Responses"
                          : "Click Through Rate"}
                      </th>
                      <th>
                        {v["questionFlag"] !== undefined
                          ? "Unique Responses"
                          : "Unique Click Through  Rate"}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div className="inline-block" style={{ width: "100%" }}>
                <table
                  width="100%"
                  className="widgetcls"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr className="_bt" id="_clickinfo">
                      <td className="white">
                        {v["MatrixResponse"] === undefined
                          ? v["WidgetName"]
                          : "Question"}
                      </td>

                      <td className="white">{v["WidgetElementValue"]}</td>
                      {v.KPI.map(kpi => (
                        <td key={kpi.Id}>
                          {kpi["FormattedRate"]}% &nbsp;{" "}
                          <strong
                            className={kpi["Count"] === 0 ? "" : ""}
                            onClick={e => e.stopPropagation()}
                          >
                            {kpi["Count"]}
                          </strong>
                        </td>
                      ))}
                      {/* showCompleteDetails(kpi, v) remove this method */}
                      <td
                        className="white"
                        style={{
                          display:
                            v["questionFlag"] === undefined
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {v["TotalResponses"]}
                      </td>
                      <td
                        className="white"
                        style={{
                          display:
                            v["questionFlag"] === undefined
                              ? "none"
                              : "table-cell",
                        }}
                      >
                        {v["UniqueResponses"]}
                      </td>
                    </tr>
                    <tr
                      style={{
                        display:
                          v["questionFlag"] !== undefined
                            ? "none"
                            : "table-row",
                      }}
                    >
                      <td>
                        <img
                          alt=""
                          src={v["WidgetPreviewImage"]}
                          style={{ maxWidth: "350px" }}
                        />
                      </td>
                      <td className="text-right verstiongoal" colSpan="3"></td>
                    </tr>
                    <tr
                      style={{
                        display:
                          v["questionFlag"] !== undefined
                            ? "table-row"
                            : "none",
                      }}
                    >
                      <td colSpan="4">
                        <div className="grpah_type drop-zone">
                          {v.MatrixResponse === undefined ? (
                            <></>
                          ) : (
                            <button
                              type="button"
                              onClick={e => chartShowFn(e, v)}
                              className="grid_btn_1 nobg vertical-middle link fright -menu-c-l"
                            >
                              <i className="fal fa-ellipsis-h"></i>
                            </button>
                          )}
                          {v.chartShow && (
                            <ul className="graph-type-drop">
                              {v.Charts &&
                                Object.values(v.Charts).map((x, i) => (
                                  <li key={i}>
                                    <button
                                      type="button"
                                      onClick={() => chartChange(v, x)}
                                      className="g-type"
                                    >
                                      {x}
                                    </button>
                                  </li>
                                ))}
                            </ul>
                          )}
                        </div>
                        <div
                          className="graph-grid"
                          style={{
                            display:
                              v["OpentextResponse"] === undefined
                                ? "block"
                                : "none",
                          }}
                        >
                          <div className="_question_graph">
                            {v.MatrixResponse === undefined ? (
                              <></>
                            ) : v.SelectedChart === "Line" ? (
                              <LineChart Data={v} />
                            ) : v.SelectedChart === "Bar" ? (
                              <BarChart Data={v} />
                            ) : v.SelectedChart === "Stacked Bar" ? (
                              <StackBar Data={v} />
                            ) : v.SelectedChart === "Stacked_Line" ? (
                              <StackLine Data={v} />
                            ) : v.SelectedChart === "Pie" ? (
                              <PieChart Data={v} />
                            ) : v.SelectedChart === "Column-Horizental" ? (
                              <ColumnVertical Data={v} />
                            ) : v.SelectedChart === "Column-Vertical" ? (
                              <ColumnHorizental Data={v} />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div
                          className="graph-grid"
                          style={{
                            display:
                              v["OpentextResponse"] === undefined
                                ? "none"
                                : "block",
                          }}
                        >
                          <h2 style={{ fontSize: "18px" }}>
                            {v["WidgetName"]}
                          </h2>
                          <div className="opentextdiv opentextdiv">
                            {v.OpentextResponse &&
                              v.OpentextResponse.map(v => (
                                <span className="link" key={v}>
                                  {v}
                                </span>
                              ))}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      {isDisplay.flag ? (
        <CompleteDetails
          ClosePopup={setDetailsDisplay}
          Value={isDisplay}
          TabId={props.tabId}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default WedgetGrid;
