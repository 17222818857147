import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
const SegmentGroup = () => {
    const checkIsAuth = useSelector(state => state);
    const { id } = useParams();
    //const [segementGroupData, setSegementGroup] = useState([]);
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 });

    const [segementGroupData, setSegementGroup] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            colors: ['red', 'blue', 'yelow', 'green']

        },
        title: {
            text: 'Segment/Groups',
            align: 'center'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },

        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            itemMarginBottom: 10,



        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                pie: {
                    size: '100%',
                    dataLabels: {
                        enabled: false
                    },

                }
            }
        },
        series: [{
            type: 'pie',
            colorByPoint: true,
            innerSize: '50%',
            name: 'Recipients',

        }]
    });
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            // SMS Insight  ---- Tabing Information 
            Client.get(`Insights/distribution/Email/${id}/Recipients/KPI?section=1`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                const newArry = [];
                const Data = JSON.parse(res['data'][0].KPI);
                Data.forEach(function (v, i) {
                    newArry.push({ name: v['GroupName'], y: v['Count'] });
                })
                setSegementGroup({ series: [{ data: newArry }] });

            });
    }, [checkIsAuth, id]);
    return (
        <HighchartsReact highcharts={Highcharts} options={segementGroupData} />
    )
}
export default SegmentGroup;