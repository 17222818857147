const BlankData = ({ title, content ,colSpan }) => {
    return (
        <><tr>
            <td className="body_table_cell white" colSpan={colSpan}>
                <div className="_nodata">
                    <div id="nodatamessage">
                        <strong>{title}</strong><br></br><br></br>
                        {content}
                    </div>
                </div>
            </td></tr></>
    )
}
export default BlankData;