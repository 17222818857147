import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { get as _get } from "lodash";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import ButtonLoader from '../../../../../ReuseTemplate/ButtonLoader';

import { createSegment, getSegmentDetails, updateSegment } from "../../../../../services/crm";

const SegmentName = () => {

	const navigate = useNavigate();
	const pathArray = window.location.pathname.split("/");
	const listID = _get(pathArray, "[4]", null);
	const [searchParams] = useSearchParams();
	const segmentId = searchParams.get('edit');
	const location = useLocation();
	const [isLoader, setIsLoader] = useState(false);
	const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
	//const [isFormSubmitOnce, setIsFormSubmitOnce] = useState(false);
	const [segmentFormData, setSegmentFormData] = useState({ name: "", description: "", type: "" });
	const [segmentFormErrors, setSegmentFormErrors] = useState();

	useEffect(() => {
		(async () => {
			try {
				if ((segmentId || null) !== null) {

					const response = await getSegmentDetails({ segmentId: segmentId });
					setSegmentFormData({ name: _get(response, "data.data[0].Name", ""), description: _get(response, "data.data[0].Description", ""), type: _get(response, "data.data[0].segmentType", "") });
				}
			} catch (err) {
			}
		})();
	}, [segmentId]);

	useEffect(() => {
		if (_get(segmentFormData, "name", "") === "" || _get(segmentFormData, "type", "") === "") {

			setIsSubmitButtonDisabled(true);
		} else {

			setIsSubmitButtonDisabled(false);
		}
	}, [segmentFormData]);

	const saveFN = async () => {
		setIsLoader(true);
		const payload = {
			name: _get(segmentFormData, "name", ""),
			description: _get(segmentFormData, "description", ""),
			segmentType: _get(segmentFormData, "type", ""),
			subscriberListId: (listID || "")
		}
		const response = await createSegment(payload);

		setIsLoader(false);
		if (typeof _get(response, "data.data", []) !== "number") {
			setSegmentFormErrors({ ...segmentFormErrors, name: "Record already exist!" });
		} else {
			setSegmentFormErrors({ ...segmentFormErrors, name: "" });
			navigate(`/crm/people/subscribers/${(listID || "")}/1/segment/buildSegment/${_get(response, "data.data", "")}`, { state: { selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") } });
		}
	}

	const updateFN = async () => {
		setIsLoader(true);
		const payload = {
			segmentId: (segmentId || ""),
			name: _get(segmentFormData, "name", ""),
			description: _get(segmentFormData, "description", ""),
			subscriberListId: (listID || ""),
		}

		const response = await updateSegment(payload);

		setIsLoader(false);
		if (_get(response, "data.message", "") === "Success") {

			setSegmentFormErrors({ ...segmentFormErrors, name: "" });
			navigate(`/crm/people/subscribers/${(listID || "")}/1/segment/list`, { state: { selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") } });
		} else {
			setSegmentFormErrors({ ...segmentFormErrors, name: "Record already exist!" });
		}
	}

	return (
		<>
			<div className="dashbd_sec margin-top100">
				<div className="bx-boder-common width100 white margin-bottom50">
					<div className="_h_header">
						<h3 className="_t_i">Give your Segment a describing name</h3>
					</div>
					<div className="bx-pad">
						<div className="formmodel-bx">
							<label className="_l_able_cls">Name</label>
							<input type="text" className="inputformmodel width100" value={_get(segmentFormData, "name", "")} onChange={(e) => setSegmentFormData({ ...segmentFormData, name: _get(e, "target.value", "") })} />
							{(_get(segmentFormErrors, "name", "") !== "") && (<p className="error-message">{_get(segmentFormErrors, "name", "")}</p>)}
						</div>

						<div className="formmodel-bx">
							<label className="_l_able_cls">Description</label>
							<textarea id="_createDes" className="inputformmodel width100 textareaModel excludeclassName" value={_get(segmentFormData, "description", "")} onChange={(e) => setSegmentFormData({ ...segmentFormData, description: _get(e, "target.value", "") })}></textarea>
						</div>

						<div className="formmodel-bx">
							<label className="_l_able_cls">What type of data would you like to store in this field?</label>
							<select
								className="inputformmodel width100"
								value={_get(segmentFormData, "type", "")}
								disabled={(segmentId !== null) ? true : false}
								onChange={(e) => setSegmentFormData({ ...segmentFormData, type: _get(e, "target.value", "") })}
							>
								<option value="">Select</option>
								<option value="1">Static</option>
								<option value="2">Dynamic</option>
							</select>
						</div>

						<div className="formmodel-bx text-right nomargin">
							<button
								type="button"
								className="btn link-light"
								onClick={() =>
									navigate(`/crm/people/subscribers/${(listID || "")}/1/segment/list`, { state: { selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") } })
								}
							>
								Cancel
							</button>
							<button
								type="button"
								className="btn btn-primary"
								disabled={isSubmitButtonDisabled}
								onClick={() => (segmentId !== null) ? updateFN() : saveFN()}>
								<ButtonLoader IsLoader={isLoader}>
									Continue
								</ButtonLoader>
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default SegmentName;