import { get as _get } from "lodash";

import api from "../helpers/apiAll";
//import { objectToParams, objectToParamsWithEmptyValue } from "../helpers/util";

// Fetch Stores Lists
export const getStoreList = () => {

	return api().get("Mobile/Stores").then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", [])
		};
	}).catch((err) => {
		return {
			flag: false,
			message: _get(err, "response.message", "Something went wrong while fetching stores list."),
			data: {}
		};
	});
}

// Create Store > Stamp Cards > Create new store
export const fetchCategories = () => {

	return api().get("Mobile/Categories?categoryType=Store").then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", [])
		};
	}).catch((err) => {
		return {
			flag: false,
			message: _get(err, "response.data.message", "Something went wrong while fetching categories."),
			data: {}
		};
	});
}

// Create Store > Stamp Cards > Create new store
export const fetchCountries = () => {

	return api().get("Lookup/countries").then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", [])
		};
	}).catch((err) => {
		return {
			flag: false,
			message: _get(err, "response.data.message", "Something went wrong while fetching countries."),
			data: {}
		};
	});
}

// Create Store > Stamp Cards > Fetch Landing Pages
export const fetchLandingPages = () => {

	return api().get("Lookup/contentDetails/2").then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", [])
		};
	}).catch((err) => {
		return {
			flag: false,
			message: _get(err, "response.data.message", "Something went wrong while fetching landing pages."),
			data: {}
		};
	});
}

// Create Store
export const createStore = (variables) => {

	return api().post("Mobile/Stores", variables).then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		console.log("error: ", err);
		return {
			flag: false,
			message: _get(err, "response.data", "Something went wrong while creating store."),
			data: {}
		};
	});
}

// Get Store Details
export const getStoreDetails = (storeID) => {

	return api().get(`Mobile/Store/${(storeID || "")}`).then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			message: _get(err, "response.data.message", "Something went wrong while fetching store details."),
			data: {}
		};
	});
}
