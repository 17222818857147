import { useNavigate } from "react-router"
import BreadCrum from "../../../../ReuseTemplate/BreadCrum"
import { useCallback, useEffect, useState } from "react";
import './index.css'
import getParameterByName from "../../../../ReuseTemplate/GetRoute";
import Client from "../../../../GlobalAPICall";
import { useSelector } from "react-redux";
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";
function CreateUnsubscribe() {
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);
    const [isloader, setIsloader] = useState(false);
    const [UnSubscribeButton, setUnSubscribeButton] = useState({
        text: true,
        undo: false,
        reasons: false
    });
    const [unsubscribeObject, setUnsubscribeObject] = useState({
        Header: "Successful unsubscribe.",
        UnsubscribeText: "You have been removed from this subscriber list.",
        Undo: {
            Text: "Undo Unsubscribe",
            Success: "Your preferences are restored now.",

        },
        Reasons: {
            Header: "If you have a moment, please let us know why you unsubscribed:",
            ReasonUnsubscribe: [
                { text: 'I no longer want to receive these emails', isSelected: true, id: 1 },
                { text: 'I never signed up for this mailing list', isSelected: true, id: 2 },
                { text: 'The emails are inappropriate', isSelected: true, id: 3 },
                { text: 'The emails are spam and should be reported', isSelected: true, id: 4 },
                { text: 'Others(fill in reason below)', isSelected: true, id: 5 }
            ],
            Button: "Submit",
            Thank: "Thank you for your feedback!"
        }

    })
    
    const saveFn = () => {
        setIsloader(true);
        const templateId = getParameterByName('cam', window.location.href);
        const obj = {
            "opt_top_label": unsubscribeObject.Header,
            "opt_top_header": unsubscribeObject.UnsubscribeText,
            "opt_top_search": unsubscribeObject.Reasons.Button,
            "opt_submit_text": unsubscribeObject.Undo.Text,
            "opt_template_id": templateId,
            "opt_thanks_text": unsubscribeObject.Undo.Success,
            "opt_reasonoption": {
                "reasonoption": 1,
                "header": unsubscribeObject.Reasons.Header,
                "extinfofield": null,
                "options": unsubscribeObject.Reasons.ReasonUnsubscribe,
                "message": unsubscribeObject.Reasons.Thank
            },
            "opt_templatetype": "12",
            "unsubscribeReasonStatus": UnSubscribeButton.undo ? 1 : 0,
            "unsubscribeUndoStatus": UnSubscribeButton.reasons ? 1 : 0,


        };
        Client.put(`Unsubscribe/UnsubscribeTemplate?templateId=${templateId}`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            setIsloader(false);
            try {
                if (data.success) {
                    navigate(`/create/templates/unsubscribe?type=12`);
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => setIsloader(true));
    }

    const edit_temFn = useCallback(() => {
        const templateId = getParameterByName('cam', window.location.href);
        APITOKEN['token'] &&
        Client.get(`Unsubscribe/UnSubscribeTemplate?typeid=SMSEmailUnSubscribe&templateId=${templateId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                const data = res.data;
                let ReasonUnsubscribeValue = [];
                data.opt_reasonoptionGet.options.map(item => ReasonUnsubscribeValue.push({
                    text: item.text, isSelected: item.isSelected, id: item.id
                }));
                setUnsubscribeObject({
                    Header: data.opt_top_label,
                    UnsubscribeText: data.opt_top_header,
                    Undo: {
                        Text: data.opt_submit_text,
                        Success: data.opt_thanks_text,
                    },
                    Reasons: {
                        Header: data.opt_reasonoptionGet.header,
                        ReasonUnsubscribe: ReasonUnsubscribeValue,
                        Button: data.opt_top_search,
                        Thank: data.opt_reasonoptionGet.message
                    }
                });
                
                setUnSubscribeButton({
                    text: true,
                    undo: true,
                    reasons: true
                })
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));

    }, [APITOKEN])

    useEffect(() => {
        if (getParameterByName('edit', window.location.href) !== null) {
            edit_temFn();
        }
    }, [edit_temFn])
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Create Sms And Email Unsubscribe</h1>
                        <p className="sms_content">Here you can create SMS and Email Triggers, Opt-out link and Birthday Automation. Get started on those triggers!</p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/create/templates/unsubscribe?type=12" ToText="Unsubscribe" CurrentPage="SMS and Email Unsubscribe" />
            </div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 bx-boder-common inline-block margin-bottom">
                    <div className="_h_header relative">
                        <h3>Unsubscribe Text</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1" onClick={() => setUnSubscribeButton(prv => ({
                                ...prv,
                                text: !prv.text
                            }))}>{UnSubscribeButton.text ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.text ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Header</label>
                                <textarea className="inputformmodel textareaModel"
                                    value={unsubscribeObject.Header}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Header: e.target.value
                                    }))}
                                ></textarea>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <textarea className="inputformmodel textareaModel"
                                    value={unsubscribeObject.UnsubscribeText}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        UnsubscribeText: e.target.value
                                    }))}
                                ></textarea>
                            </div>

                        </div>
                        : <></>}
                    <div className="_h_header relative b_top">
                        <h3>Undo Unsubscribe</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1"
                                onClick={() => setUnSubscribeButton(prv => ({
                                    ...prv,
                                    undo: !prv.undo
                                }))}>{UnSubscribeButton.undo ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.undo ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Undo.Text}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Undo: {
                                            ...prv.Undo,
                                            Text: e.target.value
                                        }
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Success message</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Undo.Success}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Undo: {
                                            ...prv.Undo,
                                            Success: e.target.value
                                        }
                                    }))}
                                />
                            </div>
                        </div>
                        : <></>}
                    <div className="_h_header relative">
                        <h3>Reasons for Unsubscribe</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1"
                                onClick={() => setUnSubscribeButton(prv => ({
                                    ...prv,
                                    reasons: !prv.reasons
                                }))}>{UnSubscribeButton.reasons ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.reasons ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Unsubscribe Text Header</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Reasons.Header}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Reasons: {
                                            ...prv.Reasons,
                                            Header: e.target.value,
                                            ReasonUnsubscribe:[...prv.Reasons.ReasonUnsubscribe]
                                        }
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Reasons for Unsubscribe</label>
                                <table cellPadding="0" cellSpacing="10" width="100%">
                                    <tbody>
                                        {unsubscribeObject.Reasons.ReasonUnsubscribe.map((v, i) =>
                                            <tr key={i}>
                                                <td align="right">
                                                    <input type="checkbox" value={v.isSelected} checked={v.isSelected}
                                                    onChange={e => setUnsubscribeObject(prv => ({
                                                        ...prv,
                                                        Reasons: {
                                                            ...prv.Reasons,
                                                            ReasonUnsubscribe:
                                                                prv.Reasons.ReasonUnsubscribe.map(item => (item.id === v.id ? { ...item, isSelected: e.target.checked } : item))
                                                        }
                                                    }))}
                                                    />
                                                </td>
                                                <td>
                                                    <input style={{ width: 450 }} type="text"
                                                        onChange={e => setUnsubscribeObject(prv => ({
                                                            ...prv,
                                                            Reasons: {
                                                                ...prv.Reasons,
                                                                ReasonUnsubscribe:
                                                                    prv.Reasons.ReasonUnsubscribe.map(item => (item.id === v.id ? { ...item, text: e.target.value } : item))
                                                            }
                                                        }))}
                                                        value={v.text} maxLength="200" className="inputformmodel" />
                                                </td>
                                            </tr>
                                        )}

                                    </tbody>
                                </table>

                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Submit Button Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Reasons.Button}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Reasons: {
                                            ...prv.Reasons,
                                            Button: e.target.value,
                                            ReasonUnsubscribe:[...prv.Reasons.ReasonUnsubscribe]
                                        }
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Thank You Message</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Reasons.Thank}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Reasons: {
                                            ...prv.Reasons,
                                            Thank: e.target.value,
                                            ReasonUnsubscribe:[...prv.Reasons.ReasonUnsubscribe]
                                        }
                                    }))}
                                />
                            </div>
                        </div>
                        : <></>}
                    <div className="formmodel-bx nomargin text-right padding20">
                        <button type="button"
                            className="grid_btn_1 nobg noborder"
                            onClick={() => navigate(`/create/templates/unsubscribe?type=12`)}>Cancel</button>
                        <button type="button"
                            className="grid_btn_1 grid_btn_active"
                            onClick={saveFn}
                        >
                            <ButtonLoader IsLoader={isloader}>
                                {(getParameterByName('edit', window.location.href) !== null) ? 'Update' : 'Save'} 
                                </ButtonLoader>
                        </button>

                    </div>
                </div>
                <div className="previewPanel">
                    <div className="iphonePriview">
                        <div className="ovflow_preview">
                            {UnSubscribeButton.text ?
                                <div className=" perivewHeader lightblue">
                                    <div className="heading">{unsubscribeObject.Header}</div>
                                    <p>{unsubscribeObject.UnsubscribeText}</p>
                                </div>
                                : <></>}
                            {UnSubscribeButton.undo ?
                                <div className="restorebtn">
                                    <button type="button" className="previwButton" disabled>
                                        {unsubscribeObject.Undo.Text}
                                    </button>
                                </div>
                                : <></>}
                            {UnSubscribeButton.reasons ?
                                <>
                                    
                                    <div className="perivewHeader">
                                        <h2 className="font-16">{unsubscribeObject.Reasons.Header}</h2>
                                        {unsubscribeObject.Reasons.ReasonUnsubscribe.map((v, i) => 
                                            v.isSelected ? <p key={i} className="drop-zone fromPreview">
                                                <input type="radio" className="inline-block vertical-middle margin-right10"
                                                    onChange={e => e.stopPropagation()} name="radioUnsubscribe" />
                                                <span className="prradio">{v.text}</span>
                                            </p> : <></>)}
                                    </div>
                                    <div className="restorebtn">
                                        <button type="button" className="btn_green">{unsubscribeObject.Reasons.Button}</button>
                                    </div>
                                </>
                                : <></>}
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default CreateUnsubscribe
