import { useCallback, useContext, useEffect, useState } from "react";
import { ConfigContext } from ".";
import Client from "../../../../GlobalAPICall";
import axios from "axios";
import BlankData from "../../../../BlankData";
import { useNavigate } from "react-router";


const SenderNameGrid = ({...props}) => {
    const TokenObject = useContext(ConfigContext);
    const [sederGrid, setSenderGrid] = useState([]);
    const { SetisDisplay} = props;
    const navigate = useNavigate();

    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        TokenObject.token &&
            Client.get(`Trigger/Templates/SMSIndianContentTemplates`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + TokenObject['token']
                }
            })
                .then(res => {
                    try {
                        setSenderGrid(res.data);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [TokenObject]);
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className="sms_Sec_1 white bx-boder-common width100">
            <div className="_h_header">
                <h3>SMS Templates India - Content (SMS Text)</h3>
                <span className="flex-right">
                    <button type="button" className="grid_btn_1 grid_btn_active" onClick={()=> navigate('/create/template/createTemplateSMS')}>Create New Template</button>
                </span>
            </div>
            <table className="smstable _senderTemplate_table" cellPadding="0" cellSpacing="0">
                {sederGrid.length !== 0 ?
                    <thead>
                        <tr>
                            <th className="table_cell_header">Template Name</th>
                            <th className="table_cell_header">Template Text</th>
                            <th className="table_cell_header">Content Template ID</th>
                            <th className="table_cell_header">Message Type</th>
                            <th className="table_cell_header">Creation Date</th>
                            <th className="table_cell_header">Actions</th>
                        </tr>
                    </thead>
                    : <></>}
                <tbody>
                    {sederGrid.length === 0 ? <BlankData title="You have not created any SMS text template." content="" colSpan="6" /> :
                        sederGrid.map((v, i) => <tr key={i}>
                            <td className="body_table_cell noproperties">
                                {v['templateName']}
                            </td>
                            <td className="body_table_cell noproperties">
                                {v['templateUserMessage']}
                            </td>
                            <td className="body_table_cell noproperties">
                                {v['gatewayTemplateId']}
                            </td>
                            <td className="body_table_cell noproperties">
                                {v['messageTypeText']}
                            </td>
                            <td className="body_table_cell">
                                {v['creationDate']}
                            </td>

                            <td className="body_table_cell">
                                <button type="button" className="inline-block link _edit_template nobg noborder pointer" onClick={()=>navigate(`/create/template/createTemplateSMS?id=${v.rowUID}`)} >Edit</button>  &nbsp; | &nbsp;
                                <button type="button" className="inline-block link _delete_template nobg noborder pointer" onClick={() => SetisDisplay(prv => ({ ...prv, flag: false,rowUID:v.rowUID }))}>Delete</button>
                            </td>
                        </tr>)
                    }
                </tbody>
            </table>
        </div>
    )

}

export default SenderNameGrid
