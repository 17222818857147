import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import "./index.css";
import styled from "styled-components";
import { SlideBarData } from "./SlidebarData";
import SubMenu from "./SideBarLink";
import { useSelector } from "react-redux";
import NotificationWrapper from "./NotificationWrapper";
import Client from "../GlobalAPICall";
import { useDispatch } from "react-redux";
import { setNavigationToggleFn, setOnboardingSteps } from "../Redux/Action";
import Cookies from "js-cookie";
import { deleteToken } from "../Redux/Action";
import Logo from "../assets/img/logo.png";
import NavbarConnect from "../layout/Navbar";
import { useAppContext } from "../contexts/AppContext";
const LeftNav = styled.div`
  width: 251px;
  background: #1a283d;
  bottom: 0;
  left: 0;
  position: fixed;
  top: 56px;
  z-index: 1000;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding-bottom: 50px;
  height: 100%;
`;

const NavigationSide = styled.div`
  width: 100%;
  padding: 40px 16px 16px 16px;
  box-sizing: border-box;
  position: relative;
`;

const Navbar = () => {
  let [showMenu, setToggle] = useState(false);
  const { APITOKEN, OnboardingStep, NavigationToggleReduce } = useSelector(
    (state) => state
  );
  const FilterData = (data) => {
    data.map(
      (x) =>
        (x.id === 2 || x.id === 4 || x.id === 5) &&
        x.subNav.map((y) =>
          y.subId === 17 || y.subId === 233 || y.subId === 36
            ? (y.activeMobile =
                APITOKEN?.config?.subscriptionInfo?.IsMobileAppAssigned === 1
                  ? true
                  : false)
            : false
        )
    );
    return data;
  };
  const [letMenuObject, setLeftObject] = useState(FilterData(SlideBarData));

  const MenuToggle = (e) => {
    e.stopPropagation();
    setToggle(true);
  };
  const location = useLocation();
  const hideNav = location.pathname.indexOf("slugPublish");
  const wrapperRef = useRef(null);
  const [tabData, setTabData] = useState({});
  const dispatch = useDispatch();
  // const cookies = new Cookies();
  const [navtoggle, setNavToggle] = useState(false);
  const [onboardClass, setOnboardClass] = useState(
    location.pathname === "/onboarding" ? true : false
  );
  const { setUpdateWallet, setTokenUpdateNew, updateWallet } = useAppContext();
  const userWalletAddress = APITOKEN?.config?.userWalletAddress;
  const userToken = APITOKEN.token;

  useEffect(() => {
    if (!userWalletAddress || userWalletAddress.trim() === "") {
      // If userWalletAddress does not exist or is an empty string
      setUpdateWallet(true);
      setTokenUpdateNew(userToken);

      // Store in localStorage
      localStorage.setItem("updateWallet", true);
      localStorage.setItem("tokenUpdateNew", userToken);
    } else {
      // If userWalletAddress exists and has a value
      setUpdateWallet(false);

      localStorage.removeItem("updateWallet");
      localStorage.removeItem("tokenUpdateNew");
    }
  }, [userWalletAddress]);
  const filterClearObject = () => {
    const filter = [];
    for (let x of letMenuObject) {
      x["slider"] = false;
      x["classActive"] = false;

      filter.push(x);
    }
    return filter;
  };
  const load = useCallback(() => {
    APITOKEN["token"] !== "" &&
      Client.get(
        `Lookup/OnBoardingMenus/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      ).then((res) => {
        try {
          let count = 0;
          for (let x of res.data) {
            if (x["IsCompleted"]) {
              count++;
            }
          }
          setTabData(dispatch(setOnboardingSteps({ Data: res.data, count })));
        } catch (err) {
          console.log(err);
        }
      });
  }, [APITOKEN, dispatch]);
  // useEffect(() => {
  //   const needRefresh = localStorage.getItem("needRefresh");

  //   if (needRefresh) {
  //     // Remove the flag to prevent additional refreshes
  //     localStorage.removeItem("needRefresh");

  //     // Perform the refresh
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    // Refresh the page when component is mounted
    // window.location.reload();
    load();
    function handleClickOutside() {
      setToggle(false);
      setShowNotification(false);
    }

    // function mouseOver(){
    //     setNavToggle(dispatch(setNavigationToggleFn(false)));
    // }
    // function mouseOut(){
    //     setNavToggle(dispatch(setNavigationToggleFn(true)));
    // }
    const hkbfe_urlHosts = window.location.href.indexOf("promoti");
    var Heroicrelease_script = document.createElement("script");
    var Heroicrelease_link = document.createElement("link");
    Heroicrelease_link.type = "text/css";
    Heroicrelease_link.rel = "stylesheet";

    Heroicrelease_script.id = "hkbfe-script";
    Heroicrelease_link.id = "hkbfe-style";
    if (hkbfe_urlHosts > -1) {
      Heroicrelease_script.src =
        "https://www.jirafix.com/hc/?kbembed=content&output=embed";
      Heroicrelease_link.href = "https://release.promoti.ai/?kbembed=style";
      document.head.appendChild(Heroicrelease_link);
      document.head.appendChild(Heroicrelease_script);
    } else {
      Heroicrelease_script.src =
        "https://help.promoti.ai/?kbembed=content&output=embed";
      Heroicrelease_link.href = "https://release.promoti.ai/?kbembed=style";
      document.head.appendChild(Heroicrelease_link);
      document.head.appendChild(Heroicrelease_script);
    }

    window.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [load, dispatch]);
  //remove from useEffect dependices //
  //wrapperRef,
  //showMenu
  let [showNotification, setShowNotification] = useState(false);
  const notification = (e) => {
    e.stopPropagation();
    setShowNotification((showNotification = !showNotification));
    setToggle(false);
  };
  const closeNotification = (e) => {
    e.stopPropagation();
    setShowNotification(false);
    setToggle(false);
  };
  const logoutMethod = async () => {
    // Cookies.remove('secretKey');
    const secretkeyId = Cookies.get("secretKey");
    try {
      Client.post(
        `Authenticate/logout?secretKey=${secretkeyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then(({ data }) => {
          document.cookie = `secretKey=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
          Cookies.remove("secretKey");
          localStorage.removeItem("authStatus");
          localStorage.removeItem("messageConnect");
          localStorage.removeItem("signatureConnect");
          localStorage.removeItem("addressConnect");
          dispatch(deleteToken());
          localStorage.removeItem("tokenApi");
          setTimeout(() => {
            window.location.replace("/login");
          }, 500);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
      Cookies.remove("secretKey");
      dispatch(deleteToken());
      setTimeout(() => {
        window.location.replace("/login");
      }, 500);
    }
  };
  const filterObject = (arry, item) => {
    const filter = [];
    for (let x of arry) {
      if (x.id === item.id) {
        if (x.subNav === undefined) {
          x["slider"] = false;
          x["classActive"] = true;
        } else {
          if (item.slider) {
            x["slider"] = false;
            x["classActive"] = false;
          } else {
            x["slider"] = true;
            x["classActive"] = true;
          }
        }
      } else {
        x["slider"] = false;
        x["classActive"] = false;
      }

      filter.push(x);
    }
    return filter;
  };
  const nagigationToggle = async (item, e) => {
    e.stopPropagation();
    await setLeftObject(filterObject(letMenuObject, item));
    setOnboardClass(false);
  };
  const navigationToggle = () => {
    setNavToggle(dispatch(setNavigationToggleFn(!NavigationToggleReduce)));
  };
  const [gethelp, setGetHelp] = useState(false);
  const getHelp = () => {
    setGetHelp(true);
  };
  return (
    <>
      <div ref={wrapperRef}></div>
      <div className="verfiymsgdiv">
        <ul className="topmenulist">
          {APITOKEN.config.themeID === 10 ||
          APITOKEN.config.themeID === 6 ||
          APITOKEN.config.themeID === 12 ? (
            <></>
          ) : (
            <li>
              <Link
                to={`${location.pathname}${location.search}`}
                onClick={getHelp}
              >
                Get help
              </Link>
            </li>
          )}
          <li>
            <Link
              to={`${location.pathname}${location.search}`}
              onClick={notification}
            >
              Alerts
            </Link>
          </li>
          <li>
            <Link
              to={`${location.pathname}${location.search}`}
              onClick={MenuToggle}
            >
              {APITOKEN.TokenObject.userName}
              <em className="droparrow"></em>
            </Link>
            {showMenu ? (
              <ul className="drop_menu_toppanel profileDrop">
                <li>
                  <Link to="/myaccount" className="paddingleft20">
                    My Account
                  </Link>
                </li>
                <li>
                  <Link to="/billing" className="paddingleft20">
                    Billing
                  </Link>
                </li>
                <li>
                  <Link to="/configuration" className="paddingleft20">
                    Configuration
                  </Link>
                </li>
                <li>
                  <Link to="/download" className="paddingleft20">
                    Downloads
                  </Link>
                </li>
                <li className="divider"></li>
                <li>
                  <Link
                    to={`${location.pathname}${location.search}`}
                    onClick={logoutMethod}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            ) : (
              ""
            )}
          </li>
          <li>
            {updateWallet ? (
              <Link
                to="/myaccount"
                id="connect-wallet"
                className="custom-secondary btn px-5 undefined btn btn-outline-secondary"
              >
                Use Connect Wallet{" "}
              </Link>
            ) : (
              <NavbarConnect updateWallet={updateWallet ? true : false} />
            )}
          </li>
        </ul>

        <Link
          to="/"
          className="logopanel"
          style={{
            background: `url(${Logo}) center center / contain no-repeat rgb(255, 255, 255)`,
          }}
        ></Link>
        <Link
          to={`${location.pathname}${location.search}`}
          onClick={navigationToggle}
          className="menuvalue"
        >
          <i className="fal fa-bars" style={{ fontSize: "19px" }}></i>
        </Link>
        {showNotification ? (
          <NotificationWrapper
            onClick={closeNotification}
            wrapperRef={wrapperRef}
          />
        ) : (
          <></>
        )}
      </div>
      {hideNav === -1 ? (
        <LeftNav
          className={`navigationleft ${
            NavigationToggleReduce && "slideminiMenu"
          }`}
          NavFlag={navtoggle}
        >
          <NavigationSide className="navigationSide">
            <ul>
              <li
                style={{ marginBottom: "30px" }}
                className={`${onboardClass ? "toggleSelected" : ""}`}
              >
                <Link
                  to="/onboarding"
                  onClick={() => {
                    load();
                    setLeftObject(filterClearObject());
                    setOnboardClass(true);
                  }}
                  className="menuactive_analyticss menuactive_onboarding"
                >
                  <i className="fal fa-list-alt"></i>
                  <label>Getting Started </label>
                  {tabData.payload && (
                    <div className="width100 inline-block p-p">
                      <em>
                        {OnboardingStep.count}/{OnboardingStep.Data.length}
                      </em>
                      <p className="progresss">
                        <span
                          className="progessBars"
                          style={{
                            width: `${
                              (100 / OnboardingStep.Data.length) *
                              OnboardingStep.count
                            }%`,
                            background: "#3bac69",
                            position: "absolute",
                            left: 0,
                            top: 0,
                            height: "5px",
                            borderRadius: "10px",
                          }}
                        >
                          {" "}
                        </span>
                      </p>
                    </div>
                  )}
                </Link>
              </li>
              {letMenuObject.map((item, index) => (
                <SubMenu
                  item={item}
                  key={index}
                  Data={SlideBarData}
                  NagigationToggle={nagigationToggle}
                  setNavToggle={setNavToggle}
                />
              ))}
            </ul>
          </NavigationSide>
        </LeftNav>
      ) : (
        <></>
      )}
      {gethelp && (
        <>
          <div
            id="ht-kb-fe-embed-container"
            className="ht-kb-fe-embed-container lc-releaseNote"
          >
            <div id="ht-kb-fe-embed-contents" style={{ zIndex: "10000000" }}>
              <span
                className="hkbembed-button-open"
                onClick={() => setGetHelp(false)}
                style={{
                  position: "absolute",
                  right: "10px",
                  borderRadius: "20px",
                  margin: "11px",
                  cursor: "pointer",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  height="24"
                  width="24"
                >
                  <g
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    strokeLinejoin="round"
                  >
                    <path d="M19 5L5 19M19 19L5 5" />
                  </g>
                </svg>
              </span>
              <iframe
                id="ht-kb-fe-embed-iframe"
                title="get"
                src={
                  window.location.href.indexOf("promoti") > -1
                    ? "https://www.jirafix.com/hc/?kbembed=content&output=embed"
                    : "https://help.promoti.ai/?kbembed=content&output=embed"
                }
                loading="lazy"
                style={{ display: "block" }}
              ></iframe>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default Navbar;
