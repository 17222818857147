import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Export_Client } from "../../GlobalAPICall";
import BlankData from '../../BlankData';
import DeleteListTemplate from "../../ReuseTemplate";
import Pagination from "../../ReuseTemplate/Pagination";
import moment from "moment";
import getLocaleDateString from '../../TimeFormat';
const DownloadTab = (props) => {
    const { APITOKEN } = useSelector(state => state);
    const [downloadData, setDownLoad] = useState({ data: [], totalNumber: 0 });
    const [downloadPaginationData, setDownLoadPagination] = useState(0);
    const [pageCount, setDownLoadPageCount] = useState(0);
    const [buttonShow, setButtonShow] = useState({ nextPage: false, previous: true });
    const [deleteTemplate, setDeleteTemplate] = useState(false);
    const [deleteId, setDeleteId] = useState("");
    // const { SetDispatch } = props;
    const setLoaded = useCallback(() => {

        APITOKEN['token'] &&
            Export_Client.get(`export?skip=${downloadPaginationData}&limit=10`, {
                headers: {
                    'Authorization': 'Bearer ' + APITOKEN['token']
                }
            }).then(({data}) => {
                try {
                    setDownLoad({
                        data: data,
                        totalNumber: data[0].totalcount
                    });
                    if (data.length < 10) {
                        setButtonShow({ nextPage: true })
                        if (downloadPaginationData === 0) {
                            setButtonShow({ nextPage: true, previous: true });
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        return (() => {
            setDownLoad({
                data: [],
                totalNumber: 0
            });
        })

    }, [APITOKEN, downloadPaginationData])

    const refresh = () => {
        setLoaded();
    }


    const pagination = (f) => {
        if (f === 0) {
            setDownLoadPagination(count => count - 10);
            setDownLoadPageCount(prv => prv - 1);
            if ((downloadPaginationData - 10) === 0) {
                setButtonShow({ previous: true })
            } else {
                setButtonShow({ previous: false });
            }
        } else {
            setDownLoadPageCount(prv => prv + 1);
            setDownLoadPagination(count => count + 10);
            setButtonShow({ previous: false });
        }
    }
    const deleteDownloadLit = (v) => {
        setDeleteTemplate(true)
        setDeleteId(v['id']);
    }
    const closeDeleteTemplate = () => {
        setDeleteTemplate(false)
    }
    const deleteTemplateFN = () => {
        Export_Client.delete(`/export?Id=${deleteId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                if (res['data']['success']) {
                    setDeleteTemplate(false)
                    setLoaded();
                }
            } catch (err) {
                console.log(err);
            }

        })
    }
    useEffect(() => {
        setLoaded();
    }, [setLoaded])
    return (
        <>
            {
                deleteTemplate ? <DeleteListTemplate onClick={closeDeleteTemplate} deleteDownload={deleteTemplateFN} title="Please confirm if want to delete else cancel to go back." content="" colSpan="6" /> :

                    <div className=" white bx-boder-common width100 marginBottom download_container_bx">
                        <div className="_h_header">
                            <h3>Downloads</h3>
                            <span className="flex-right"><button type="button" className="grid_btn_1" onClick={refresh}>Refresh</button></span>
                        </div>
                        <table cellPadding="0" cellSpacing="0" className="smstable">
                            {downloadData.data.length === 0 ? '' :
                                <thead><tr>
                                    <th className="table_cell_header">Name</th>
                                    <th className="table_cell_header">Content</th>
                                    <th className="table_cell_header">Export Time</th>
                                    <th className="table_cell_header">Status</th>
                                    <th className="table_cell_header">Downloads</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                                </thead>
                            }
                            <tbody>
                                {downloadData.data.length !== 0 ? <Grid data={downloadData} onClick={deleteDownloadLit} /> : <BlankData title="You have not exported any files yet." content="When you have done an export, your file will be displayed here and you can download it to your computer." colSpan="6" />}
                            </tbody>
                        </table>
                        {downloadData.data.length !== 0 ?
                            <div className="padding20">
                                <Pagination pageCount={pageCount} totalCount={downloadData} skip={pageCount === 0 ? 10 : ((pageCount + 1) * 10)} buttonAction={buttonShow} onClick={pagination} />
                            </div>
                            : <></>}
                    </div>
            }
        </>
    )
}
export default DownloadTab;

const Grid = ({ data, onClick }) => {
    const { APITOKEN } = useSelector(state => state);

    const url = process.env;
    const f = getLocaleDateString(APITOKEN.config.cultureCode);

    const downloadFile = o => {
        var urlPath = `${url.REACT_APP_API_URL_EXPORT}/export/DownloadExport?Id=${o.id}&isRespFile=true`
        var req = new XMLHttpRequest();
        req.open("POST", urlPath, true);
        req.setRequestHeader("Authorization", `Bearer ${APITOKEN['token']}`);
        req.responseType = "blob";
        req.onload = function () {
            var blob = new Blob([req.response], { type: req.response.type });
            var isIE = false || !!document.documentMode;
            if (isIE) {
                window.navigator.msSaveBlob(blob, o.fileName);
            } else {
                var urlPath = window.urlPath || window.webkitURL;
                var link = urlPath.createObjectURL(blob);
                var a = document.createElement("a");
                a.setAttribute("download", o.fileName);
                a.setAttribute("href", link);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
        };
        req.send(JSON.stringify({}));
    };

    return (<>
        {data['data'] &&
            data['data'].map((v, i) =>
                <tr key={i}>
                    <td className="body_table_cell">
                        <button type="button" className="link detail_sms nobg noborder pointer text-left word-wrap">{v['name']}</button>
                    </td>
                    <td className="body_table_cell">{v['contentType']}</td>
                    <td className="body_table_cell">{moment(v['exportTime']).format(f)} </td>
                    <td className="body_table_cell" style={{ color: v['syncStatusflag'] ? '#56b67c' : '' }}>
                        {v['status']}</td>
                    <td className="body_table_cell">{v['downloadCount']}</td>
                    <td className="body_table_cell">
                        <button type="button" className="link download_link nobg noborder pointer downlink" onClick={() => downloadFile(v)} disabled={!v['isDownlinkAvailable']}>Download</button> &nbsp;&nbsp;
                        <button type="button" className="link _download_list_delete nobg downlink noborder pointer" onClick={() => onClick(v)}>Delete</button>
                    </td>
                </tr>
            )
        }</>
    )
}

