
import { useParams } from 'react-router-dom';
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useReducer, useState } from "react";
import BlankData from '../../../BlankData';
import GridLoader from '../../../ReuseTemplate/GridLoader';



const initializaObject = {
    skip: 10,
    count: 1,
    data: [],
    incrementCount: 0,
    nextDisabled: false,
    previousDisabled: true,
    loader: true
}

const filterRecipientsData = (response, state) => {
    const arrayFields = response.slice(state.incrementCount, state.skip);
    return arrayFields;
}
function Reducer(state, action) {
    switch (action.type) {
        case 'NEXT':
            return {
                ...state, ...{
                    ...action.payload,
                    data: filterRecipientsData(state.BluckData, action.payload),
                    nextDisabled: action.payload.BluckData.length <= action.payload.skip ? true : false,
                    previousDisabled: action.payload.count === 0 ? true : false,
                    loader: false
                }
            }
        case 'PREVIOUS':
            return {
                ...state, ...{
                    ...action.payload,
                    data: filterRecipientsData(state.BluckData, action.payload),
                    previousDisabled: action.payload.count === 1 ? true : false,
                    nextDisabled: action.payload.BluckData.length === action.payload.skip ? true : false,
                    loader: false
                }
            }
        case 'DATA':
            return {
                ...state, ...{
                    ...action.payload,
                    data: filterRecipientsData(action.payload.data, state),
                    loader: false
                }
            }
        default:
            return state
    }
}


export const CompleteDetails = ({ ...props }) => {
    const { ClosePopup, Value, TabId } = props;
    const { object, WidgetId } = Value;
    const [videoData, setVideoData] = useState({ data: [], flag: false });
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [state, dispatch] = useReducer(Reducer, initializaObject)

    const url = WidgetId !== undefined ? `Insights/distribution/Email/${id}/${TabId}/${object.Id}/${WidgetId}/Recipients?skip=0&limit=10` : `Insights/distribution/Email/${id}/${TabId}/${object.Id}/Recipients?skip=0&limit=10`;


    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(url, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        dispatch({
                            type: 'DATA',
                            payload: {
                                data: res.data,
                                BluckData: res.data,
                                TotalRecords: res.data.length,
                            }
                        });
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, url]);

    const videoDetails = (value) => {
        try {
            Client.get(`Insights/distribution/${value.MobileNumber}/${id}/${WidgetId}/VideoStatus`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setVideoData({ data: res.data, flag: true });
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])


    return (
        <div className="maskingComplete">
            <div className="detail-popp">
                <div className="topn-header">
                    <span className="selected-title">{object.Name}</span>
                    <span style={{ padding: '0 20px', float: 'right' }} onClick={() => ClosePopup(prv => ({ ...prv, flag: false }))}><i className="fa fal fa-times  pointer p-clsbtn "></i></span>
                </div>
                <div className={`_h_header ${videoData.flag ? 'noborder' : ''}`}>&nbsp;</div>
                {videoData.flag ?
                    <div className="table_dealis_wiget scrollable">
                        <button type="button" class="cls_secondbx link" onClick={() => setVideoData(prv => ({ ...prv, flag: false }))}><i class="fas fa-arrow-left"></i></button>
                        <table
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            class="smstable table-layout-default table_dealis_wigettable">
                            <thead>
                                {videoData.pageViews !== undefined ? <tr>
                                    <th class="table_cell_header">Name</th>
                                    <th class="table_cell_header">Clicks</th>
                                </tr> :
                                    <tr>
                                        <th class="table_cell_header">Event Action</th>
                                        <th class="table_cell_header">Event Time Duration</th>
                                        <th class="table_cell_header">Full Duration</th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {videoData.data.map((v, i) =>
                                    videoData.pageViews !== undefined ?
                                        <tr key={i}>
                                            <td class="body_table_cell">{v.pageName}</td>
                                            <td class="body_table_cell">{v.clicks}</td>
                                        </tr>
                                        :
                                        <tr key={i}>
                                            <td class="body_table_cell">{v.EventAction}</td>
                                            <td class="body_table_cell">{v.EventTimeDuration}</td>
                                            <td class="body_table_cell">{v.FullDuration}</td>
                                        </tr>
                                )}
                            </tbody>
                        </table>
                    </div> :
                    <>
                        <div className="table_dealis_wiget scrollable">

                            {state.loader ? <GridLoader TDArray={[1, 2, 4, 5, 6]} /> :
                                <table cellPadding="0" cellSpacing="0" className="smstable table-layout-default table_dealis_wigettable">
                                    {state.data.length > 0 &&
                                        <thead>
                                            <tr>
                                                <th className="table_cell_header">First Name</th>
                                                <th className="table_cell_header">Last Name</th>
                                                <th className="table_cell_header" style={{ display: 'none' }}>Company</th>
                                                <th className="table_cell_header">Email</th>
                                                <th className="table_cell_header">Zip Code</th>
                                                <th className="table_cell_header">Widget Clicks</th>
                                                <th className="table_cell_header" >{object.Id === 227 ? 'KPI Status' : 'Status'}</th>
                                                <th className="table_cell_header" style={{ display: object.Name === "Plays" || object.Name === 'Unique Plays' ? 'table-cell' : 'none' }}>Video Status</th>
                                            </tr>
                                        </thead>
                                    }
                                    <tbody>
                                        {state.data.length > 0 ? state.data.map((v, i) =>
                                            <tr key={i}>
                                                <td className="body_table_cell">{v['FirstName']}</td>
                                                <td className="body_table_cell">{v['LastName']}</td>
                                                <td className="body_table_cell" style={{ display: 'none' }}>{v['Company']}</td>
                                                <td className="body_table_cell">{v['Email']}</td>
                                                <td className="body_table_cell">{v['ZipCode']}</td>
                                                <td className="body_table_cell">{(object.Name === 'Plays' || object.Name === 'Unique Plays') ? v['Plays'] : v['Clicks']}</td>
                                                <td className="body_table_cell">{v['Status']}</td>
                                                <td className="body_table_cell"
                                                    style={{
                                                        display: (
                                                            object.Name === 'Plays' ||
                                                            object.Name === 'Unique Plays') ? 'table-cell' : 'none'
                                                    }}>
                                                    <button type="button"
                                                        onClick={() => videoDetails(v)}
                                                        className="videoLink link"
                                                        style={{ display: (v['VideoStatus'] !== 'Not Completed') ? 'none' : 'table-cell' }}>More Details</button>
                                                    <span style={{ display: (v['VideoStatus'] === 'Not Completed') ? 'none' : 'table-cell' }}>{v['VideoStatus']}</span>
                                                </td>
                                            </tr>
                                        ) : <BlankData title="No data registered." content="There is no data registered for this KPI." colSpan="8" />}
                                    </tbody>
                                </table>
                            }
                        </div>

                        {state.loader ? <></> : state.BluckData && state.BluckData.length < 10 ? <></> :
                            <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                                <span className="pagination_count vertical-middle">
                                    <span>{state.count}</span>-
                                    <span>{state.skip}</span> of&nbsp;
                                    <span>{state.TotalRecords}</span>
                                </span>

                                <button type="button"
                                    className="bigtext"
                                    disabled={state.previousDisabled}
                                    onClick={() => dispatch({
                                        type: 'PREVIOUS',
                                        payload: {
                                            ...state,
                                            count: state.count - 10,
                                            skip: state.skip - 10,
                                            incrementCount: state.incrementCount - 10
                                        }
                                    })}><i className="fas fa-caret-left"></i></button>
                                <button type="button"
                                    className="bigtext"
                                    disabled={state.nextDisabled}
                                    onClick={() => dispatch({
                                        type: 'NEXT',
                                        payload: {
                                            ...state,
                                            count: state.skip + 1,
                                            skip: state.skip + 10,
                                            incrementCount: state.incrementCount + 10
                                        }
                                    })}><i className="fas fa-caret-right"></i></button>
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )

}

export default CompleteDetails
