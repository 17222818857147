import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import _ from 'lodash';
import History from './History'
import Client from "../GlobalAPICall";
import { encode as btoa } from 'base-64'
import Cookies from 'js-cookie'
import ButtonLoader from "../ReuseTemplate/ButtonLoader";

const ButtonPanel = ({ v, tabId, contentId, ...props }) => {
    const loaction = useLocation();
    const { id } = useParams()
    // const { APITOKEN } = useSelector(state => state);
    const { APITOKEN } = props;
    const [IsDuplicateLoader, setIsDuplicateloader] = useState(false);
    const navigate = useNavigate();
    const campaignToggle = (e, o) => {
        e.stopPropagation();
        props.Data.map(item => ({ ...item, show: false }));
        const index = _.findIndex(props.Data, function (x) { return x.id === o['id']; });
        props.Data[index].show = !o['show'];
        props.onChangeEvent([...props.Data]);
    }
    useEffect(() => {
        // Window Click campaignToggle hide //
        function handleClickOutside(event) {
            if (props.wrapperRef.current && !props.wrapperRef.current.contains(event.target)) {
                props.setHistoryToggle(false);
                const refresh = props.Data.map(item => ({ ...item, show: false }));
                props.onChangeEvent([...refresh]);
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [props]);

    const historySlider = event => {
        event.stopPropagation();
        props.setHistoryToggle(props.historyToggle = !props.historyToggle);
    }

    const archiveFn = ({ id }) => {
        Client.delete(`Campaign/${id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    props.load();
                    props.TabLoad();
                } catch (err) {
                    console.error(err);
                }
            });
    }
    // Duplicate Campaign API Call //
    const duplicateFn = ({ id }) => {
        setIsDuplicateloader(true);
        Client.post(`Campaign/duplicate?id=${id}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    setIsDuplicateloader(false);
                    props.load();
                    props.setTabid(pre => ({
                        ...pre,
                        id: 1,
                    }));
                    props.TabLoad();
                    window.scrollTo(0, 0);

                } catch (err) {
                    console.error(err);
                }
            }).catch(err => setIsDuplicateloader(false));
    }
    const recoverCampaign = ({ id }) => {
        Client.get(`Campaign/${id}/recover`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    if (data.Success) {
                        props.load();
                        props.TabLoad();
                    }
                } catch (err) {
                    console.error(err);
                }
            });
    }
    const insightDistribution = ev => {
        if (id === '2') {
            navigate(`/insights/smsDetails/${ev.queueId}`);
        } else if (id === '5') {
            navigate(`/insights/EmailDetails/${ev.queueId}`);
        }
        else if (id === '4') {
            navigate(`/insights/surveyDetails/${ev.id}`);
        }
    }
    const editCampagin = ev => {
        if (ev.redirectToDomain !== "") {
            const secreatKey = Cookies.get('secretKey');
            // window.location.replace(`${ev.redirectToDomain}${ev.editUrl}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}`);
            window.location.replace(`${APITOKEN.config.subscriptionInfo.EditorDomainUrl}${ev.editUrl}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&type=${id}&sk=${secreatKey}`);
        } else {
            navigate(ev.editUrl);
        }
    }
    return (
        <>
            <span className="flex-right drop-zone sx-content">
                <span className="sx-child" style={{ display: (tabId === 7) ? 'none' : 'inline-block' }}>
                    <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -edit-cam-" disabled={(contentId === '74') ? (v['isWorkFlowPublished']) ? true : false : (v['isExpired']) ? true : false} onClick={() => editCampagin(v)} >Edit</button>
                    <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle" onClick={() => duplicateFn(v)}>
                        <ButtonLoader IsLoader={IsDuplicateLoader} color={'0077FF'}>
                            Duplicate
                        </ButtonLoader>
                    </button>

                    {/* <button type="button" disabled={(tabId === 7) ? true : false} className={`grid_btn_1 nobg margin-right15 vertical-middle ${(v['status'] === 'Active') ? 'live' : (v['inProgress'] === true) ? '-normal-text' : 'defaultActive'}`} style={{ paddingLeft: '10px', color: '#000', display: (v['status'] === 'Active') ? 'none' : (v['inProgress'] === true) ? 'none' : 'inline-block' }}>{v['status']}</button> */}

                    <button type="button" style={{ display: (contentId === '74') ? 'inline-block' : 'none' }} className="grid_btn_1 vertical-middle margin-right15 activateLink">{(v['isWorkFlowPublished']) ? 'Deactivate' : 'Set Active'}</button>
                    <button
                        type="button"
                        className="grid_btn_1 nobg margin-right15 vertical-middle distribute"
                        onClick={() => navigate(`${(id === '2' || id === '4') ? `/createContent/47?redirect=${v.id}&ctype=${id}` : `/createContent/48?redirect=${v.id}&ctype=${id}`}`)}
                        disabled={(v['isExpired'] || v['inProgress']) ? true : false}>Send</button>
                    <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle goinsight" onClick={() => insightDistribution(v)} style={{ display: (v['isSent']) ? 'inline-block' : 'none' }}>Insights</button>
                </span>
                <span style={{ display: (tabId === 7) ? 'inline-block' : 'none' }}>
                    <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -recover-camp" onClick={() => recoverCampaign(v)}>{(contentId === '2') ? 'Recover Landing Page' : (contentId === '5') ? 'Recover Email' : (contentId === '4') ? 'Recover Survey' : 'Recover Workflow'}</button>
                    <button type="button"
                        className="grid_btn_1 nobg margin-right15 vertical-middle -permnent-delete-camp"
                        onClick={() => { props.onClick(v) }}>Delete Permanently</button>
                </span>
                <button type="button" className="grid_btn_1 nobg vertical-middle link" onClick={(e) => campaignToggle(e, v)}><i className="fal fa-ellipsis-h" style={{ fontSize: '28px' }}></i></button>
                {v.show ?
                    <> <div className="cam-more-drop">
                        <ul className="cm-ul" ref={props.wrapperRef}>
                            <li
                                style={{
                                    display: (contentId === 74) ?
                                        'none' : (v['inProgress']) ?
                                            'none' : 'inline-block'
                                }}>
                                <Link to={`/campaignURL/${v['id']}`}>URL</Link>
                            </li>
                            <li
                                style={{
                                    display: (contentId === 74) ?
                                        'none' : (v['inProgress']) ?
                                            'none' : (tabId === 7) ?
                                                'none' : 'inline-block'
                                }}>
                                <Link to={`/shareCampaign/${v['id']}/${contentId}`} >Share</Link>
                            </li>
                            <li>
                                <Link to={loaction.pathname} className="-historylink" onClick={e => historySlider(e)}>History</Link></li>
                            <li style={{
                                display: (tabId === 7) ?
                                    'none' : 'inline-block'
                            }}>
                                <Link to={loaction.pathname} onClick={() => archiveFn(v)} className="_archivelink" >Archive</Link>
                            </li>
                            <li style={{
                                display: (contentId === 74) ?
                                    'none' : (v.inProgress) ?
                                        'none' : (tabId === 7) ?
                                            'none' : 'inline-block'
                            }}>
                                <Link to={`/embeddedCode/${v.id}`} >Embedded Code</Link>
                            </li>
                            <li
                                style={{
                                    display: (tabId === 7) ?
                                        'none' : 'inline-block'
                                }}>
                                <Link to={`/createContent/${id}?id=${v.id}&folder=42`} className="edit_name_folder">Edit Name and Folder</Link>
                            </li>
                            <li
                                style={{
                                    display: (!v.isSlugGenerated) ?
                                        'block' : 'none'
                                }}><Link to={`/slugPublish/${v.id}`} className="editName_folder" title="Create your Slug">Create your Slug</Link>
                            </li></ul>

                    </div>
                        {props.historyToggle ? <History Object={v} {...props} Event={campaignToggle} /> : <></>}
                    </>
                    : ''}
            </span >
        </>
    )

}
export default ButtonPanel