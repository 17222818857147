import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';

export const IndianTemplate = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [smsTemplateData, setTemplate] = useState([]);
    const { Value, ChangeEvent } = props;

    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get(`SMS/SenderBasedPredefineSMS?sendername=${Value.senderName}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setTemplate(res['data']);
            });
    }, [APITOKEN, Value.senderName])
    return (
        <>
            {smsTemplateData.length !== 0 &&
                <div className="formmodel-bx">
                    <label className="_l_able_cls">Indian Template</label>
                    <select
                        className="inputformmodel"
                        value={Value.indianTemplateVal}
                        onChange={(e) => ChangeEvent(smsTemplateData.filter((item) => item.templateId === e.target.value))}>
                        <option value="">Select</option>
                        {smsTemplateData.map((v, i) => <option key={i} value={v.templateId}>{v.templateName}</option>)}
                    </select>
                </div>
            }
            </>
    )

}

