

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Queued from "./Queued";
import Submitted from "./Submitted";
import DeliveredLive from "./Delivered";
import FailedLive from "./Failed";
import BalanceCheckFn from "../../../../ReuseTemplate/BalanceCheckFn";


const LiveSending = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [queueObject, setQueueObject] = useState({});
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const queuedAPIFN = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/distribution/SMS/live/sending/Aggregrated?queueId=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setQueueObject(prv => ({ ...prv, ...data }));
                    setLowBalancePopup(prv => ({
                        ...prv,
                        balance: data.DistributionStatus.Balance,
                    }))
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.log(err);
            });

    }, [APITOKEN, id]);

    const addfund = () => {
        setLowBalancePopup(prv => ({
            ...prv,
            display: true
        }))
    }

    useEffect(() => {
        var date = new Date();

        setTimeout(function () {
            let refreshIntervalId = setInterval(function () {
                queuedAPIFN();
                clearInterval(refreshIntervalId);

            }, 60000);
            queuedAPIFN();
        }, (60 - date.getSeconds()) * 1000);
        queuedAPIFN();
    }, [queuedAPIFN])

    return (
        <>
            <div className="bx-boder-common">
                <div className="_insight_header white">
                    <h3>Live Sending</h3>
                    <p className="content">&nbsp;</p>
                </div>
                <div className="livesend white summary_o_v">

                    {!!queueObject.DistributionStatus &&
                        queueObject.DistributionStatus['SyncStatus'] <= 100 &&
                        queueObject.DistributionStatus['SyncStatus'] > 0 ?
                        <><p className="-sub">Your SMS sending is on its way!</p>
                            <figure className="highcharts-figure">
                                <div className="-l"><div className="chart-container"><Queued Data={queueObject} />
                                </div><div id="-sms-live" className="g-txt">{queueObject.Queued} SMS
                                    </div></div>
                                <div className="-l"><div id="container-rpm" className="chart-container"><Submitted Data={queueObject} /></div><div id="-sub-live" className="g-txt">{queueObject.Submitted} SMS
                                </div></div>
                                <div className="-l"><div id="container-delivered" className="chart-container"><DeliveredLive Data={queueObject} /></div><div id="-delive-live" className="g-txt">{queueObject.Delivered} SMS
                                </div></div>
                                <div className="-l"><div id="container-failed" className="chart-container"><FailedLive Data={queueObject} /></div><div id="-fail-live" className="g-txt">{queueObject.Failed} SMS
                                </div></div>

                            </figure></>
                        : !!queueObject.DistributionStatus && queueObject.DistributionStatus['SyncStatus'] === 999 ? <><p className="-sub" style={{ fontSize: '16px' }}>You don't have enought balance <button style={{ fontSize: '16px' }} type="button" className="link inline-block addFundsInsight" onClick={addfund}>Add Funds</button></p></> : <><p style={{ fontSize: '16px' }} onLoad={queuedAPIFN}>Inprogress.. </p></>}
                </div>
            </div>
            {lowBlance.display ? <BalanceCheckFn APITOKEN={APITOKEN} lowBlance={lowBlance} setLowBalancePopup={setLowBalancePopup} removeWaterMark={() => queuedAPIFN()} /> : <></>}

        </>
    )

}

export default LiveSending
