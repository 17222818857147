import { useLocation, useNavigate } from "react-router";
import "./multiUi.css";
import Cookies from "js-cookie";
const MultipalUser = () => {
  const location = useLocation();
  const { data, user } = location.state;
  const url = process.env;
  const navigate = useNavigate();
  const login = (v) => {
    console.log(location);
    const options = {
      accountName: v.accountName,
      userName: user.name,
      password: user.password,
      validity: "LongLive",
      domain: window.location.origin,
    };
    try {
      fetch(`${url.REACT_APP_API_URL}/Authenticate/token/ui`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        body: JSON.stringify(options),
      })
        .then(async (response) => await response.json())
        .then((data) => {
          if (data.hasOwnProperty("client_secret")) {
            try {
              Cookies.set("secretKey", data.client_secret, { path: "/" });
              window.location.replace("/onboarding");
            } catch (err) {
              throw new Error(err);
            }
          } else {
            // Check multipal account info //
            if (Array.isArray(data)) {
              navigate("/multiaccount", {
                state: {
                  data,
                  user: { name: user.userName, password: user.password },
                },
              });
            } else {
              Cookies.remove("secretKey");
              // setError({
              //     errorMessage: data.message
              // })
            }
          }
        })
        .catch((err) => {
          console.err(err);
        });
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      <div className="verfiymsgdiv"></div>
      <div className="-select-account">
        <div className="smsHeader">
          <div className="dashbd_sec">
            <h1 className="smstitle">Select Account</h1>
          </div>
        </div>
        <div className="switch_account_wrapper">
          <div className="login-middlwe-section">
            <ul className="differbx">
              {data.map((v, i) => (
                <li key={i} className="excludeClass">
                  <span className="img_logo">
                    <img src={v.accountImageUrl} width="168" alt="" />
                  </span>
                  <div className="-account">
                    <p className="-content">
                      <button
                        type="button"
                        onClick={() => login(v)}
                        title="Click here to login wih this account."
                        className="csslnkaccount"
                      >
                        {v.accountName}
                        <p className="sub_content">{v.companyName}</p>
                      </button>
                    </p>
                    <p></p>
                  </div>
                  <div className="b-panel">
                    <button
                      type="button"
                      title="Click here to login wih this account."
                      onClick={() => login(v)}
                      className="btn-cls link"
                    >
                      Select
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MultipalUser;
