import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Client from '../../../../GlobalAPICall';
import EmailOPTINGrid from './EmailOPTINGrid';
import BlankData from '../../../../BlankData';
import GridLoader from '../../../../ReuseTemplate/GridLoader';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';

const EmailOPTIN = () => {
    const { APITOKEN } = useSelector(state => state);
    const [emailDetails, setEmailDetails] = useState({
        data: [],
        isDisplay: true
    });
    const [showDeletePanel, setDeletePanel] = useState(true);
    const [guidId, setListGuidId] = useState("");
    const navigate = useNavigate();
    // Get All list from API Call

    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Trigger/Templates/EmailoptIn', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setEmailDetails(prv => ({
                        ...prv,
                        data: res['data'],
                        isDisplay: false
                    }));
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));;
    }, [APITOKEN])

    // List Item Delete API Call //
    const deleteList = () => {
        Client.delete(`Trigger/Templates/${guidId.id}/EmailoptIn`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                setDeletePanel(!showDeletePanel);
                load();
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
    }

    const showDeletePanelFN = (f, item) => {
        setDeletePanel(!showDeletePanel);
        setListGuidId(item);
    }
    useEffect(() => {
        load()
    }, [load])

    return (<>
        <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle">Email Double Opt-in</h1>
                    <p className="sms_content">Create an Email Double Opt-in template that you could trigger from our API, Send page or include as a trigger inside a Landing Page, Email or Signup Form to send out a specific campaign</p>
                </div>
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="Email Double Opt-in" />
        </div>
        <div className="dashbd_sec">
            {showDeletePanel ?
                <>
                    <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                        <div className="_h_header">
                            <h3>My Templates</h3>
                            <span className="flex-right"><button className="grid_btn_1 grid_btn_active" onClick={() => navigate(`/createContent/28`)} type="button">Create Template</button></span>
                        </div>
                        {emailDetails.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4]} /> :

                            <table cellPadding="0" cellSpacing="0" className="smstable">
                                {
                                    emailDetails.data.length > 0 &&
                                    <thead>
                                        <tr>
                                            <td className="table_cell_header">Template Name</td>
                                            <td className="table_cell_header">Template ID</td>
                                            <td className="table_cell_header">Actions</td>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {
                                        emailDetails.data.length > 0 ? emailDetails.data.map((item, index) => {
                                            return <EmailOPTINGrid item={item} key={index} showDeletePanelFN={showDeletePanelFN} />
                                        }) : <BlankData title="You have not configured any Email Double Opt-in." content="With a template you are able to configure and change your Email Double OptIn without involving your technical department." colSpan="3" />
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </> : <DeletePanel click={() => deleteList()} closeFn={() => setDeletePanel(!showDeletePanel)} />
            }
        </div>
    </>)
}
const DeletePanel = (props) => {
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            <div className="_h_header">
                <h3>Delete Template</h3>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <tbody>
                    <tr>
                        <td className="body_table_cell white">
                            <div className="_nodata margin-bottom">
                                <div id="nodatamessage">
                                    <strong>
                                        Do you want Delete Template?
                                    </strong>
                                    <br></br>
                                    <br></br>
                                    Connect it to give your landing pages and website a more professional look.
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={props.closeFn}>Cancel</button>
                                <button type="button" className="grid_btn_1 deleteBtn" onClick={props.click}>Delete</button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div></>)
}

export default EmailOPTIN;