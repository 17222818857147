import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OverView from './Overview';
import APP from './APP';
import Speaker from './Speaker';
import Speech from './Speech';
import Playlist from './Playlist';
import Video from './Video';
import { Datepicker } from '@mobiscroll/react';
import { useSelector } from 'react-redux';


const Tabing = ({ data }) => {
    const defaultId = 1;
    const { APITOKEN } = useSelector(state => state);
    const [tabId, setTabid] = useState({ id: defaultId, totalNumber: 0 });
    const [dateFormat, setDateFormat] = useState({
        startDate: "",
        endDate: "",
        maxData:""
    })
    const clickmethod = item => {
        setTabid({
            id: item.id,
            totalNumber: item.id
        });
    }
    const filterDateSet = el => {
        const [startDate,endDate] = el['valueText'].split(' - ');
        setDateFormat(prv => ({
            ...prv,
            startDate,
            endDate
        }));
    }
    useEffect(() => {
         // ===  Minimum Time Zone set for Calendar === //
        const date = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        setDateFormat(prv => ({
            ...prv,
            maxData: date
        }));
    },[APITOKEN.config])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index}
                            onClick={() => clickmethod(item)}>
                            <Link className={item.id === tabId.id ? 'active ' : ' '} id={item.id}>{item.name}</Link>
                        </li>
                    })}
                </ul>
                <div className="_DateRanges fright">
                    <Datepicker className="inputformmodel"
                        // value={[postObject.fromDate,postObject.toDate]}
                        max={dateFormat.maxData}
                        select="range"
                        touchUi={true}
                        onChange={e => filterDateSet(e)}
                        dataTimezone='utc'
                        dateFormat="YYYY.MM.DD"
                        placeholder="Date Range"
                        locale={APITOKEN.config.countryCode}
                        display="center"
                        theme="ios"
                        themeVariant="dark" />
                </div>
            </div>
            {
                <div className="tab_body">{tabId.id === defaultId ?
                    <OverView /> : tabId.id === 2 ?
                        <APP Date={dateFormat} APITOKEN={APITOKEN} /> : tabId.id === 3 ?
                            <Speaker Date={dateFormat}  APITOKEN={APITOKEN}/> : tabId.id === 4 ?
                                <Speech Date={dateFormat}  APITOKEN={APITOKEN}/> : tabId.id === 5 ?
                                    <Playlist Date={dateFormat}  APITOKEN={APITOKEN}/> : <Video  APITOKEN={APITOKEN} Date={dateFormat}  />}
                </div>
            }
        </>
    )
}

export default Tabing;