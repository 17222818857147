import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { get as _get } from "lodash";
import { useSelector } from "react-redux";
import { CKEditor } from 'ckeditor4-react';
import parse from 'html-react-parser';
import moment from "moment";

import { getCRMNotes, createCRMNotes, deleteCRMNotes, updateCRMNotes } from "../../../../../services/crm";
import getLocaleDateString from '../../../../../TimeFormat';

const Notes = () => {
	const queryParams = new URLSearchParams(window.location.search);
	const peopleSubscriberId = queryParams.get('pId');
	const [notes, setNotes] = useState([]);
	const [showHideDeleteAction, setshowHideDeleteAction] = useState([]);
	const [showDeleteNoteSection, setShowDeleteNoteSection] = useState(false);
	const [deleteNoteId, setDeleteNoteId] = useState(false);
	const [showCKEditor, setShowCKEditor] = useState(false);
	const [note, setNote] = useState("");
	const defaultEditNoteData = { id: 0, noteId: "" };
	const [updateNoteDetail, setUpdateNoteDetail] = useState(defaultEditNoteData);
	const { APITOKEN } = useSelector(state => state);
	const f = getLocaleDateString(APITOKEN.config.cultureCode);
	const refs = useRef([]);

	const getNotes = useCallback(() => {
		(async () => {
			try {

				let payload = {
					peopleSubscriberId: (peopleSubscriberId || 0),
				};

				const respNotes = await getCRMNotes(payload);
				let allNotes = [];
				_get(respNotes, "data", []).forEach((element, k) => {
					const monthNameSplit = _get(element, "monthName", "").split(' ');
					// allNotes[k]['Month'] = monthNameSplit[0];
					// allNotes[k]['Years'] = monthNameSplit[1];

					let notesDetail = JSON.parse(_get(element, "notesDetails", ""));
					notesDetail = (notesDetail || []).map((ele, j) => {

						return { ...ele, Notes: decodeURI(_get(ele, "Notes", "")), LastUpdatedDateTime: moment(_get(ele, "LastUpdatedDateTime", "")).format(f) }
						// allNotes[k]["notesDetails"][j]['Notes'] = decodeURI(ele['Notes']);
						// allNotes[k]["notesDetails"][j]['LastUpdatedDateTime'] = moment(ele['LastUpdatedDateTime']).format("DD.MM.YYYY HH:mm:ss")
					});

					let finalNote = {
						Month: monthNameSplit[0],
						Years: monthNameSplit[1],
						notesDetails: notesDetail
					}
					allNotes.push(finalNote);
				});
				setNotes(allNotes);
				(allNotes || []).map((notes) => refs.current = _get(notes, "notesDetails", []).map(() => React.createRef()));
				console.log("REFS START: ", refs, allNotes);
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [f, peopleSubscriberId])

	useEffect(() => {
		(async () => {
			try {

				getNotes();

			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [getNotes]);

	const onCancelDelete = () => {
		setShowDeleteNoteSection(false);
		setDeleteNoteId(null);
	}

	const createNote = () => {
		(async () => {
			try {

				await createCRMNotes({ peopleSubscriberId: peopleSubscriberId, notes: encodeURI(note) });

				getNotes();
				setDeleteNoteId(null);
				setUpdateNoteDetail(defaultEditNoteData);
				setShowDeleteNoteSection(false);
				setShowCKEditor(false);
				setNote("");
			} catch (err) {
			}
		})();
	}

	const updateNote = () => {
		(async () => {
			try {

				await updateCRMNotes({
					id: _get(updateNoteDetail, "id", 0),
					noteId: _get(updateNoteDetail, "noteId", ""),
					peopleSubscriberId: peopleSubscriberId,
					notes: encodeURI(note)
				});

				getNotes();
				setDeleteNoteId(null)
				setShowDeleteNoteSection(false);
				setUpdateNoteDetail(defaultEditNoteData);
				setShowCKEditor(false);
			} catch (err) {
			}
		})();
	}

	const onDelete = () => {
		(async () => {
			try {

				await deleteCRMNotes({ peopleSubscriberId: peopleSubscriberId, noteId: deleteNoteId });

				getNotes();
				setDeleteNoteId(null)
				setShowDeleteNoteSection(false);
			} catch (err) {
			}
		})();
	}

	const closeCKEditor = () => {
		setNote("");
		setShowCKEditor(false);
		setUpdateNoteDetail(defaultEditNoteData);
	}

	useEffect(() => {
		const handleClickOutsideAll = (event) => {
			refs.current.forEach((ref, index) => {
				if (ref.current && !ref.current.contains(event.target)) {
					//setshowHideDeleteAction([]);
					setshowHideDeleteAction((prev) => {
						const newVisibleDropdowns = [...prev];
						console.log("newVisibleDropdowns: ", newVisibleDropdowns, index, refs);
						newVisibleDropdowns[index] = false;
						console.log("newVisibleDropdowns 2: ", newVisibleDropdowns);
						return newVisibleDropdowns;
					});
				}
			});
		};

		document.addEventListener('mousedown', handleClickOutsideAll);
		return () => {
			document.removeEventListener('mousedown', handleClickOutsideAll);
		};
	}, []);

	const deleteNoteSection = () => {

		return (
			<div className="sms_Sec_1 width100 white margin-bottom50">
				<div className="bx-boder-common common_Sec_1">
					<div className="_h_header">
						<h3 className="_t_i">Delete</h3>
					</div>
					<div className="bx-pad">
						<div className="alert alert-warning margin-bottom24">
							Do you want to delete this note?
						</div>

						<div className="formmodel-bx text-right nomargin">
							<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancelDelete()} >Cancel</button>

							<button
								type="button"
								className="btn btn-danger btn-gape-15"
								disabled=""
								onClick={() => onDelete()}>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}

	const renderCKEditor = () => {

		return (

			<div className="bx-boder-common nobg my-13">
				<div className="_h_header white border-radius2">
					<h3>Editor</h3>
				</div>

				<div className="p-0 white">
					<div className="row">
						<div className="col-12">
							<CKEditor
								type="classic"
								config={{
									toolbar: [
										{
											name: "styles",
											groups: ["styles"],
											items: ["Format"]
										},
										{
											name: "basicstyles",
											groups: ["basicstyles", "cleanup"],
											items: ["Bold", "Italic"]
										},
										{
											name: "clipboard",
											groups: ["clipboard"],
											items: ["Undo", "Redo"]
										},
									],
									resize_enabled: false,
									allowedContent: true
								}}
								initData={(note || "")}
								debug={false}
								onChange={(e, editor) => setNote(e.editor.getData())}
							/>
						</div>
					</div>

					<div className="formmodel-bx text-right nomargin px-5 py-2">
						<button
							type="button"
							className="btn link-light"
							onClick={() => closeCKEditor()}
						>
							Cancel
						</button>
						<button
							type="button"
							disabled={((note || "") === "") ? true : false}
							className="btn btn-primary"
							onClick={() => (_get(updateNoteDetail, "noteId", "") === "") ? createNote() : updateNote()}>
							Save
						</button>
					</div>
				</div>
			</div>
		)
	}
	console.log("NOTES: ", refs);
	return (
		<div>
			{((notes || []).length < 1) && (
				<Fragment>
					{((showCKEditor || false) === true) && (
						renderCKEditor()
					)}

					{((showCKEditor || false) === false) && (
						<div className="bx-boder-common nobg my-13">
							<div className="p-5 white">
								<div className="row">
									<div className="col-12">
										<div className="alert alert-warning">
											You haven't created any Notes yet!

											<p className="mt-4">Click on the green button to create your first Note.</p>
										</div>

										<div className="mt-12 text-right">
											<button
												className="btn btn-primary"
												disabled={((showCKEditor || false) === true) ? true : false}
												onClick={() => { setUpdateNoteDetail(defaultEditNoteData); setShowCKEditor(true); }}
											>
												Create Note
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</Fragment>
			)}
			{(!showDeleteNoteSection) && ((notes || []).length > 0) && (
				<Fragment>
					<div className="mt-12 text-right">
						<button
							className="btn btn-primary"
							disabled={((showCKEditor || false) === true) ? true : false}
							onClick={() => { setUpdateNoteDetail(defaultEditNoteData); setShowCKEditor(true); }}
						>
							Create Note
						</button>
					</div>

					{((showCKEditor || false) === true) && (
						renderCKEditor()
					)}
				</Fragment>
			)}

			{(!showDeleteNoteSection) && ((notes || []).length > 0) && (notes || []).map((note, key) => {

				return (
					<Fragment>

						<div key={key} className="bx-boder-common nobg my-13">
							<div className="white">
								<div className="row">
									<div className="col-12">
										<h4 className="h2_year p-5">{_get(note, "Month", "")}</h4>

										<div className="h3_month b_top p-5">{_get(note, "Years", "")}</div>

										{_get(note, "notesDetails", []).map((detail, k) => {

											return (
												<div key={k} className="note-list">
													<div className="note-list-item">
														<div className="note-list-item-body">
															<p><strong>Note</strong> by {_get(APITOKEN, "TokenObject.userName", "")}</p>

															<div className="note-list-edit">
																<div className="show_note">
																	{parse(_get(detail, "Notes", ""))}
																</div>
															</div>
														</div>

														<div className="note-list-item-action" ref={refs.current[k]}>
															<span
																className="note-list-dropdow-link text-07f cursor-pointer"
																onClick={() =>
																	setshowHideDeleteAction(
																		(showHideDeleteAction) => {
																			const newVisibleDropdowns = [...showHideDeleteAction];
																			newVisibleDropdowns[k] = !newVisibleDropdowns[k];
																			return newVisibleDropdowns;
																		}
																	)}
															>
																Actions
																<i className="fas fa-caret-down fa-fw" aria-hidden="true"></i>
															</span>

															<ul className={`drop_menu_toppanel ${(showHideDeleteAction[k] === true) ? "show" : "hide"}`}>
																<li>
																	<span
																		className="paddingleft20"
																		onClick={() => {
																			setNote(_get(detail, "Notes", ""));
																			setShowCKEditor(true);
																			setUpdateNoteDetail({ id: _get(detail, "Id", 0), noteId: _get(detail, "NoteId", "") });
																			setshowHideDeleteAction([]);
																		}}>Edit</span>
																</li>
																<li>
																	<span
																		className="paddingleft20"
																		onClick={() => {
																			setDeleteNoteId(_get(detail, "NoteId", ""));
																			setShowDeleteNoteSection(true);
																			setshowHideDeleteAction([]);
																			closeCKEditor();
																		}}>Delete</span>
																</li>
															</ul>
														</div>

														<div className="note-list-item-date">
															<p>{_get(detail, "CreationDate", "")} {_get(detail, "CreationTime", "")}</p>
														</div>
													</div>
												</div>
											)
										})}
									</div>
								</div>
							</div>
						</div>
					</Fragment>
				)
			})}

			{(showDeleteNoteSection) && deleteNoteSection()}
		</div >
	)
}

export default Notes;