import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import ReplyTo from "../../Create/Template/ReplyTo";
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import StepsDistribution from "../StepsTopBar";
import FileUpload from "./FileUpload";
import SendTestContainer from "../Email/SendTestContainer";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import Personalize from "../../Create/Template/Trigger/Email/Personalize";
import SenderNameEmail from "./SenderNameEmail";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import SelectMultipal from "./SelectMultipal";

function Reducer(state, action) {
  switch (action.type) {
    case "STARTDATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

const EmailSend = () => {
  const { APITOKEN } = useSelector(state => state);
  const navigate = useNavigate();
  const { id } = useParams();
  const [sendeEmail, setSenderEmail] = useState({ data: {} });
  const [replytoValue, setReplyTo] = useState({ data: [] });
  const [emailCampaignValue, setemailCampaign] = useState({ data: [] });
  const [getTimeZone, setTimeZone] = useState({ data: [], selected: "" });
  const [bcc, setBCC] = useState({ data: [], selected: "" });
  const [cc, setCC] = useState({ data: [], selected: "" });
  const wrapperRef = useRef(null);
  const CampaignId = getParameterByName("cam", window.location.href);
  const CampaignType = getParameterByName("ctype", window.location.href);
  const [state, dispatch] = useReducer(Reducer, { StartDate: "", MinDate: "" });
  const [count, setCount] = useState(0);
  const [IsLoader, setIsLoader] = useState(false);
  const [scheduleValue, setScheduleValue] = useState("now");
  const [senderNameContext, setSenderNameContext] = useState({
    display: false,
    buttonDisabled: true,
    isSelectDomain: true,
  });
  const [sendTestVal, setSendTestObject] = useState({
    flag: false,
    recipinentsTest: [],
    addEmail: "",
    addButton: true,
  });

  const [fileObject, setFileObject] = useState({
    fileImportValidation: true,
    FileAttachMent: [],
  });
  const [objectParameters, setObjectParameters] = useState({
    senderName: "",
    senderEmail: "",
    subject: "",
    preHeader: "",
    typeDetailId: CampaignType !== null ? CampaignId : "",
    replyTo: [],
    url: "",
    bcc: [],
    cc: [],
  });
  const [buttonName, setButtonName] = useState("Continue");
  // Presonlize Tag API Call

  const [presonlizFirst, setpresonlizFirst] = useState(false);
  const [presonlizeSecond, setpresonlizeSecond] = useState(false);

  const filterSendEmail = response => {
    const newObject = response.whiteLableDomains.map(x => ({
      ...x,
      fieldDisabled: true,
      selectOptionDisabled: true,
    }));
    const isdefault = response.whiteLableDomains.filter(x => x.isDefault);
    const whiteLabelDomainList = response.configuredWhiteLableDomains.map(x =>
      x === isdefault[0].whiteLableDomain
        ? { name: x, isDefault: true }
        : { name: x, isDefault: false }
    );

    response["configuredWhiteLableDomains"] = whiteLabelDomainList;
    return { ...response, whiteLableDomains: newObject };
  };
  const senderListLoad = useCallback(() => {
    APITOKEN.token &&
      Client.get("Email/senderemails")
        .then(({ data }) => {
          try {
            senderEmailFilter(filterSendEmail(data));
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => console.error(err));
  }, [APITOKEN]);

  const catFilter = res => {
    const array = [];
    array.push(
      ...res.map(x => ({ ...x, value: x.email.email, label: x.email.email }))
    );
    return array;
  };
  const replyToLoad = useCallback(() => {
    APITOKEN.token &&
      Client.get("Email/replyto")
        .then(({ data }) => {
          try {
            setReplyTo({ data: catFilter(data), selected: "" }); // API call data list show using this state //

            // Default Field selected filter by 'isDefaultSelection' this params //
            if (getParameterByName("edit", window.location.href) === null) {
              setObjectParameters(prv => ({
                ...prv,
                replyTo: [
                  ...data
                    .filter(x => x.isDefaultSelection)
                    .map(item => ({
                      ...item,
                      value: item.email.email,
                      label: item.email.email,
                    })),
                ],
              }));
            }
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => console.error(err));
  }, [APITOKEN]);

  const emailCampaign = useCallback(() => {
    APITOKEN.token &&
      Client.get("Lookup/contentDetails/5")
        .then(({ data }) => {
          try {
            const filteObject = data.filter(item => item.Id === CampaignId);
            setObjectParameters(prv => ({
              ...prv,
              url:
                filteObject.length !== 0
                  ? data && filteObject[0].PreviewUrl
                  : "",
            }));
            setemailCampaign({ data, selected: "", url: "" });
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {});
  }, [APITOKEN, CampaignId]);

  const checkSchduleSending = e => {
    const { value } = e.target;
    setScheduleValue(value);
    if (value !== "now") {
      availableTimeZones();
    } else {
      setTimeZone(prv => ({ ...prv, data: [] }));
    }
  };
  const availableTimeZones = useCallback(async () => {
    APITOKEN.token &&
      (await Client.get("Lookup/AvailableTimeZones")
        .then(result => {
          try {
            setTimeZone(prv => ({
              ...prv,
              data: result.data,
              selected: APITOKEN.config.timeZoneOffset,
            }));
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {}));
  }, [APITOKEN]);

  function senderEmailFilter(response) {
    if (response.hasConfiguredWhiteLableDomain) {
      response.whiteLableDomains.unshift({
        emailAddress: { email: "Create Sender Email" },
        configured: response.hasConfiguredWhiteLableDomain,
      });
    }
    const res = [
      ...response.whiteLableDomains,
      ...[
        {
          id: uuidv4(),
          email: null,
          isDefault: false,
          emailId: "",
          whiteLableDomain: "",
          fieldDisabled: false,
          selectOptionDisabled: true,
        },
      ],
    ];
    setSenderEmail({ data: { ...response, whiteLableDomains: res } });
    if (getParameterByName("edit", window.location.href) === null) {
      setObjectParameters(prv => ({
        ...prv,
        senderEmail: res.filter(item => item.isDefaultSelection === true)[0]
          ?.emailAddress.email,
      }));
    }
  }
  const editReplyTo = data => {
    const newArray = [];
    for (const x of data) {
      newArray.push({ value: x.email, label: x.email });
    }
    return newArray;
  };

  const selectedReplyTo = () => {
    const getArray = [];
    for (const x of objectParameters.replyTo) {
      getArray.push({ email: x.value, displayName: "" });
    }
    return getArray;
  };

  const createpostobject = () => {
    const replyTolist = selectedReplyTo();
    let obj = {
      isDraft: true,
      isAddContactsToCRM: false,
      senderFrom: {
        email: objectParameters.senderEmail,
        displayName: objectParameters.senderName,
      },
      content: {
        typeId: 5,
        subject: objectParameters.subject,
        preHeaderText: objectParameters.preHeader,
        typeDetailId: objectParameters.typeDetailId,
      },
      replyTo_List: replyTolist,
      sendingBatchId: id,
      attachments: [...(fileObject["FileAttachMent"] || [])],
      ccEmail_List: cc["data"],
      bCcEmail_list: bcc["data"],
      TestAgainstQueueId: id,
    };

    if (getTimeZone.data.length === 0) {
      delete obj["scheduleDateTime"];
    } else {
      obj["scheduleDateTime"] = {
        dateTime: state.StartDate,
        offset: getTimeZone.selected,
      };
    }
    return obj;
  };

  const saveTemplateFn = () => {
    const obj = createpostobject();
    setIsLoader(true);
    postAPI(obj);
  };
  const postAPI = obj => {
    Client.put(`Email/Send/Batch_step2/${id}`, obj)
      .then(({ data }) => {
        try {
          if (data.Success) {
            setIsLoader(false);
            navigate(`/Email/Emailsummary/${id}`);
          } else {
            console.error(data);
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  let [replyToShow, setReplyToggle] = useState(false);

  const onChange = newValue => {
    if (newValue.length !== 0) {
      const filter = newValue.filter(
        x => x.id === "00000000-0000-0000-0000-000000000000"
      );
      if (filter.length !== 0) {
        setReplyToggle(true);
      } else {
        setReplyToggle(false);
        if (newValue.length > 0) {
          setObjectParameters(prv => ({
            ...prv,
            replyTo: [...newValue],
          }));
        } else {
          setObjectParameters(prv => ({
            ...prv,
            replyTo: [],
          }));
        }
      }
    } else {
      setObjectParameters(prv => ({
        ...prv,
        replyTo: [],
      }));
    }
  };
  const closeMethod = () => {
    setReplyToggle((replyToShow = !replyToShow));
  };
  // Send Test Method //
  const sendTestSMS = () => {
    setSendTestObject(prv => ({ ...prv, flag: true }));
  };

  const presonlizeFn = (ev, f) => {
    ev.stopPropagation();
    if (f === 0) {
      setpresonlizFirst(true);
      setpresonlizeSecond(false);
    } else {
      setpresonlizFirst(false);
      setpresonlizeSecond(true);
    }
  };

  const selectedPresonlizeFn = (event, ev, f) => {
    event.stopPropagation();
    if (f === "0") {
      setObjectParameters(prv => ({
        ...prv,
        subject: `${prv.subject} ${ev.tag}`,
      }));
    } else {
      setObjectParameters(prv => ({
        ...prv,
        preHeader: `${prv.preHeader} ${ev.tag}`,
      }));
    }
  };
  const currentTimeAPIFn = ev => {
    Client.get(`utility/currenttime?timeZone=${ev.target.value}`)
      .then(res => {
        try {
          dispatch({
            type: "STARTDATE",
            payload: {
              StartDate: `${res.data.date} ${res.data.time} `,
              MinDate: `${res.data.date} ${res.data.time}`,
            },
          });
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => console.error(err));
  };
  // SendeName Popup Display //
  const senderNameFn = ev => {
    const ishow = ev.target.value === "Create Sender Email" ? true : false;
    setSenderNameContext(prv => ({ ...prv, display: ishow }));
  };

  const enterSenderName = (ev, _vl) => {
    const { data } = sendeEmail;
    const filterItem = data.whiteLableDomains.map(x =>
      x.id === _vl.id
        ? {
            ...x,
            emailId: ev.target.value.trimStart(),
            selectOptionDisabled: false,
          }
        : { ...x }
    );
    setSenderNameContext(prv => ({
      ...prv,
      buttonDisabled: ev.target.value.trimStart() !== "" ? false : true,
    }));
    setSenderEmail({ data: { ...data, whiteLableDomains: filterItem } });
  };

  const campaignSelection = event => {
    setObjectParameters(prv => ({
      ...prv,
      typeDetailId: event.target.value,
      url: event.target.options[event.target.selectedIndex].getAttribute(
        "data-url"
      ),
    }));
  };

  const replyToMemo = useMemo(() => {
    const filterArray = replytoValue.data.filter(
      x => x.isVerified || x.id === "00000000-0000-0000-0000-000000000000"
    );
    filterArray.map(x => ({
      ...x,
      value: x.email.email,
      label: x.email.email,
    }));
    return filterArray;
  }, [replytoValue.data]);

  const editEmailTriggeremail = useCallback(
    clone => {
      APITOKEN.token &&
        Client.get(`Email/Distribution/${clone !== null ? clone : id}`)
          .then(async ({ data }) => {
            try {
              setObjectParameters(prv => ({
                ...prv,
                senderName: data.SenderFrom.displayName,
                senderEmail: data.SenderFrom.email,
                subject: data.Content.subject,
                preHeader: data.Content.preHeaderText,
                typeDetailId: data.Content.detail.Id,
                replyTo: editReplyTo(data.ReplyTo_list),
                url: data.Content.detail.PreviewUrl,
              }));
              setBCC({
                data: !data.BCcEmails
                  ? []
                  : data.BCcEmails.map(item => ({
                      ...item,
                      id: Math.floor(Math.random() * 10000),
                    })),
                selected: "",
              });
              setCC({
                data: !data.CcEmails
                  ? []
                  : data.CcEmails.map(item => ({
                      ...item,
                      id: Math.floor(Math.random() * 10000),
                    })),
                selected: "",
              });

              setFileObject(prv => ({
                ...prv,
                FileAttachMent: data.attachments,
              }));
              setCount(0);
              if (!!data.ScheduleDateTime) {
                await availableTimeZones();
                dispatch({
                  type: "STARTDATE",
                  payload: {
                    StartDate: data.ScheduleDateTime.dateTimeFormattedAsString,
                  },
                });
                await setTimeZone(prv => ({
                  ...prv,
                  selected: data.ScheduleDateTime.offset,
                }));
                setScheduleValue("scheduleFor");
              } else {
                setScheduleValue("now");
              }
            } catch (err) {
              console.error(err);
            }
          })
          .catch(err => console.error(err));
    },
    [APITOKEN, id, availableTimeZones]
  );

  // BCC or CC API

  const bccORcc = useCallback(() => {
    APITOKEN.token &&
      Client.get(`Email/defaultccbcc`)
        .then(({ data }) => {
          const [filed] = data;
          const { bcc, cc, sendername, emailSubject } = filed;
          setObjectParameters(prv => ({
            ...prv,
            senderName: sendername,
            subject: emailSubject,
          }));

          setBCC({
            data: [
              ...JSON.parse(bcc).map(item => ({
                id: Math.floor(Math.random() * 10000),
                email: item,
              })),
            ],
            selected: "",
          });
          setCC({
            data: [
              ...JSON.parse(cc).map(item => ({
                id: Math.floor(Math.random() * 10000),
                email: item,
              })),
            ],
            selected: "",
          });

          try {
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => console.error(err));
  }, [APITOKEN]);

  // Call All on Onload Method //
  useEffect(() => {
    senderListLoad();
    replyToLoad();
    emailCampaign();
    const clone = getParameterByName("clone", window.location.href);
    const dateTime =
      APITOKEN.config.hasOwnProperty("cultureCode") &&
      APITOKEN.config.currentTime.split(".")[0];
    dispatch({
      type: "STARTDATE",
      payload: { StartDate: dateTime, MinDate: dateTime },
    });

    if (
      getParameterByName("edit", window.location.href) !== null ||
      clone !== null
    ) {
      editEmailTriggeremail(clone);
      setButtonName(clone ? "Continue" : "Update");
    } else {
      bccORcc();
    }
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setpresonlizFirst(false);
        setpresonlizeSecond(false);
      }
    }

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [
    editEmailTriggeremail,
    senderListLoad,
    replyToLoad,
    emailCampaign,
    bccORcc,
    APITOKEN,
  ]);

  return (
    <>
      <div ref={wrapperRef}></div>

      <StepsDistribution Name="Email" Active={2} Hide={true} UTM={true} />
      <div className="nomargin">
        <div className="dashbd_sec">&nbsp;</div>
      </div>
      <div className="dashbd_sec margin-bottom">
        <form>
          <div className="sms_Sec_1 bx-boder-common inline-block">
            <div className="_h_header">
              <h3>Email Campaign</h3>
            </div>
            <div className="bx-pad">
              <div className="formmodel-bx">
                <label className="_l_able_cls">Sender Name *</label>
                <input
                  type="text"
                  className="inputformmodel"
                  placeholder="Sender Name"
                  value={objectParameters.senderName}
                  onChange={e =>
                    setObjectParameters({
                      ...objectParameters,
                      ...{
                        senderName: e.target.value.replace(
                          /[^0-9a-zA-Z ._-]/g,
                          ""
                        ),
                      },
                    })
                  }
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Sender Email *</label>
                <select
                  className="inputformmodel"
                  value={objectParameters.senderEmail}
                  name="senderEmail"
                  onChange={e => {
                    setObjectParameters({
                      ...objectParameters,
                      ...{
                        senderEmail:
                          e.target.value === "Create Sender Email"
                            ? ""
                            : e.target.value,
                      },
                    });
                    senderNameFn(e);
                  }}
                >
                  <option value="">Select</option>
                  {sendeEmail.data.whiteLableDomains &&
                    sendeEmail.data.whiteLableDomains.map(
                      (v, i) =>
                        v.emailAddress && (
                          <option key={i} value={v.emailAddress.email}>
                            {v.emailAddress.email}
                          </option>
                        )
                    )}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Reply To *</label>
                <Select
                  value={objectParameters.replyTo}
                  isMulti
                  name="language"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onChange}
                  options={replyToMemo}
                />
              </div>

              {APITOKEN["config"].subscriptionInfo
                ?.IsPersonalizationTagVisibleOnSending ? (
                <SelectMultipal Set={setCC} Name="Cc" Get={cc} />
              ) : (
                <></>
              )}

              <SelectMultipal Set={setBCC} Name="Bcc" Get={bcc} />
              <div className="formmodel-bx drop-zone">
                <label className="_l_able_cls">
                  Subject *
                  <span
                    className="fright pointer __prefence__link link"
                    onClick={e => presonlizeFn(e, 0)}
                  >
                    {APITOKEN["config"]?.subscriptionInfo
                      ?.IsPersonalizationTagVisibleOnSending
                      ? "Personalize"
                      : ""}
                  </span>
                  {presonlizFirst ? (
                    <Personalize Flag="0" Onclick={selectedPresonlizeFn} />
                  ) : (
                    <></>
                  )}
                </label>
                <input
                  type="text"
                  className="inputformmodel"
                  value={objectParameters.subject}
                  onChange={e =>
                    setObjectParameters({
                      ...objectParameters,
                      ...{ subject: e.target.value.trimStart() },
                    })
                  }
                  placeholder="Create a subject text for your email."
                />
              </div>
              {APITOKEN["config"].subscriptionInfo
                ?.IsPersonalizationTagVisibleOnSending ? (
                <div className="formmodel-bx drop-zone">
                  <label className="_l_able_cls">
                    Pre Header
                    <span
                      className="fright pointer __prefence__link link"
                      onClick={e => presonlizeFn(e, 1)}
                    >
                      {APITOKEN["config"].subscriptionInfo
                        ?.IsPersonalizationTagVisibleOnSending
                        ? "Personalize"
                        : ""}
                    </span>
                    {presonlizeSecond ? (
                      <Personalize Flag="1" Onclick={selectedPresonlizeFn} />
                    ) : (
                      <></>
                    )}
                  </label>
                  <input
                    type="text"
                    className="inputformmodel"
                    value={objectParameters.preHeader}
                    onChange={e =>
                      setObjectParameters({
                        ...objectParameters,
                        ...{ preHeader: e.target.value },
                      })
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              <div className="formmodel-bx">
                <label className="_l_able_cls">Email Campaign *</label>
                <select
                  className="inputformmodel"
                  value={objectParameters.typeDetailId || ""}
                  onChange={e => campaignSelection(e)}
                >
                  <option value="">Select</option>
                  {emailCampaignValue.data.map((v, i) => (
                    <option key={i} data-url={v.PreviewUrl} value={v.Id}>
                      {v.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Attachment</label>
                <FileUpload
                  setCount={setCount}
                  count={count}
                  setFileObject={setFileObject}
                  fileObject={fileObject}
                />
              </div>
            </div>
          </div>
          <div className="previewPanel">
            <div className="iphonePriview">
              {objectParameters.url !== null ? (
                <div className="padding20">
                  <iframe
                    title="EmailPreivew"
                    className="noborder"
                    width="100%"
                    src={objectParameters.url}
                    height="500"
                  ></iframe>
                </div>
              ) : (
                <div className="previewDefaultMsg">
                  A preview of your selected Email Campaign will be show here.
                  <br></br>
                  <br></br>
                  Select Email Campaign to see the preview.
                </div>
              )}
            </div>
          </div>
          <div className="sms_Sec_1 bx-boder-common inline-block drop-zone">
            <div className="_h_header">
              <h3>Send Options</h3>
              {/* {JSON.stringify(objectParameters.typeDetailId)} */}
              <span className="flex-right">
                <button
                  type="button"
                  className="grid_btn_1 nobg"
                  onClick={() => sendTestSMS()}
                  disabled={
                    objectParameters.senderName !== "" &&
                    objectParameters.senderEmail !== "" &&
                    objectParameters.replyTo !== "" &&
                    objectParameters.typeDetailId !== "" &&
                    objectParameters.subject !== ""
                      ? false
                      : true
                  }
                >
                  Send Test
                </button>
              </span>
            </div>
            <div className="bx-pad">
              <div className="formmodel-bx">
                <label className="_l_able_cls">Schedule</label>
                <select
                  className="inputformmodel"
                  value={scheduleValue}
                  onChange={checkSchduleSending}
                >
                  <option value="now">Send Now</option>
                  <option value="scheduleFor">Schedule For</option>
                </select>
              </div>
              {getTimeZone.data.length > 0 && (
                <div className="formmodel-bx">
                  <label className="_l_able_cls">Schedule For</label>
                  <div
                    className="relative width50"
                    style={{ paddingRight: "20px" }}
                  >
                    <Datepicker
                      className="inputformmodel"
                      placeholder="Schedule date"
                      min={state.MinDate}
                      value={state.StartDate}
                      // dataTimezone="utc"
                      onChange={ev => {
                        dispatch({
                          type: "STARTDATE",
                          payload: { StartDate: ev.inst._valueText },
                        });
                      }}
                      dateFormat="YYYY.MM.DD"
                      touchUi={true}
                      timeFormat="HH:mm"
                      locale={APITOKEN.config.countryCode}
                      theme="ios"
                      themeVariant="dark"
                      controls={["calendar", "time"]}
                    />
                  </div>
                  <div className="relative width50">
                    <select
                      className="inputformmodel"
                      value={getTimeZone.selected}
                      onChange={e => {
                        setTimeZone(prv => ({
                          ...prv,
                          selected: e.target.value,
                        }));
                        currentTimeAPIFn(e);
                      }}
                    >
                      {getTimeZone.data.length > 0 &&
                        getTimeZone.data.map((v, i) => (
                          <option key={i} value={v.Id}>
                            {v.Name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="formmodel-bx nomargin text-right">
                <button
                  className="grid_btn_1 grid_btn_active"
                  disabled={
                    objectParameters.senderName !== "" &&
                    objectParameters.senderEmail !== "" &&
                    objectParameters.replyTo.length !== 0 &&
                    objectParameters.typeDetailId !== "" &&
                    objectParameters.typeDetailId !== null &&
                    // fileObject.fileImportValidation &&
                    objectParameters.subject !== ""
                      ? false
                      : true
                  }
                  type="button"
                  onClick={saveTemplateFn}
                >
                  <ButtonLoader IsLoader={IsLoader}>{buttonName}</ButtonLoader>
                </button>
              </div>
            </div>
          </div>
        </form>
        {replyToShow ? (
          <ReplyTo
            ReplyToData={replytoValue}
            setValue={setReplyTo}
            Close={closeMethod}
            ReplytoAPI={replyToLoad}
          />
        ) : (
          <></>
        )}
        {sendTestVal.flag ? (
          <SendTestContainer
            SetSendTestObject={setSendTestObject}
            SendTestVal={sendTestVal}
            SendObject={createpostobject}
          />
        ) : (
          <></>
        )}
        {senderNameContext.display && (
          <SenderNameEmail
            setSenderNameProperties={setSenderNameContext}
            Data={sendeEmail.data}
            senderNameContext={senderNameContext}
            EnterSenderName={enterSenderName}
            SetSenderEmail={setSenderEmail}
            Reload={senderListLoad}
          />
        )}
      </div>
    </>
  );
};
export default EmailSend;
