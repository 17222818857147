import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import EmailKPI from './EmailKPI/EmailKPI';
import SurveyKPI from './SurveyKPI';
import Distribution from './Distribution';
import Export from './Export';
import Device from './Device';
import Engagment from './Engagment';
import Recipients from './Recipients';
import LiveSending from '../../Email/Details/LiveSending';
import getParameterByName from '../../../ReuseTemplate/GetRoute';
const Tabing = ({ data }) => {
    const defaultId = 8;
    const [tabId, setTabid] = useState(defaultId);
    const [liveSendingId,setLiveSendingId] = useState();

    const clickmethod = (item) => {
        setTabid(item.id);
    }
    useEffect(() => {
        if (getParameterByName('f', window.location.href) !== null) {
            clickmethod({ id: 13 });
        }else{
            setLiveSendingId(13);
        }
    }, [])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return item.id !== liveSendingId  && <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <EmailKPI tabId={tabId} /> :
                    tabId === 74 ? <SurveyKPI tabId={tabId} /> :
                        tabId === 10 ? <Distribution tabId={tabId} /> :
                            tabId === 73 ? <Export tabId={tabId} /> :
                                tabId === 12 ? <Device tabId={tabId} /> :
                                    tabId === 11 ? <Engagment tabId={tabId}/> :
                                     tabId === 9 ?<Recipients tabId={tabId}/>:  <LiveSending/>}
                </div>
            }
        </>
    )
}

export default Tabing;