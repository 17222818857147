import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';

export const SMSContent = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [smsContentData, setContent] = useState([]);
    const { ChangeEvent, Value } = props;
    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get('SMS/contenttype', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setContent(res['data']);
            });
    }, [APITOKEN])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">SMS Content</label>
            <select className="inputformmodel" value={Value.smsContent} onChange={ChangeEvent}>
                <option value="">Select</option>
                {smsContentData.map((v, i) => v.id !== 6 && <option key={i} disabled={!v['hasRecords']} value={v.id}>{v.name}</option>)}
            </select>
        </div>
    )

}

