import { get as _get } from "lodash";

import api from "../helpers/api";
import { objectToParams, objectToParamsWithEmptyValue } from "../helpers/util";

// Fetch CRM Lists
export const getCRMList = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriberlist?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM list."),
			data: {}
		};
	});
}

// Delete CRM List
export const deleteCRMList = (variables) => {

	return api("APIV3").post("CRM/List/Delete", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting CRM List."),
			data: {}
		};
	});
}

// Fetch All Contacts
export const getCRMListContacts = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/list?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching contacts."),
			data: {}
		};
	});
}

// Search All Contacts
export const searchCRMListContacts = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/Search?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching contacts."),
			data: {}
		};
	});
}

// Fetch All Countries
export const getCountryList = () => {

	return api("crm").get("/lookUp/listcountry").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching countries."),
			data: {}
		};
	});
}

// Create Contact Manually
export const createContactManually = (variables) => {

	return api("crm").post("subscriber/multiple", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while creating contact(s)."),
			data: {}
		};
	});
}

// Delete Contact
export const deleteContact = (variables) => {

	return api("crm").post("subscriber/Delete", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting contact(s)."),
			data: {}
		};
	});
}

// Fetch All Contacts > Archived Tab
export const getArchivedContacts = (variables) => {
	const params = objectToParams(variables);

	return api("crm").get(`/subscriber/DeletedSubscriber?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching archived contacts."),
			data: {}
		};
	});
}

// Fetch All Contacts > Get Filters (customfields/displaynames)
export const getFilters = (variables) => {
	const params = objectToParams(variables);

	return api("crm").get(`/customfields/displaynames?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching filters."),
			data: {}
		};
	});
}

// Fetch All Contacts > Update Filter Sorting (customfields/displaynames)
export const updateFilters = (variables) => {

	return api("crm").put("/customfields/displaynames", variables).then((res) => {

		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching filters."),
			data: {}
		};
	});
}

// Fetch Custom Fields
export const getCustomFields = (variables) => {
	const params = objectToParams(variables);

	return api("crm").get(`/customfields/list?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data.data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching custom field list."),
			data: {}
		};
	});
}

// Update Flags for custom fields isVisible, isRequred, isIncludeAllList
export const getCustomFieldsTypes = () => {

	return api("crm").get("/customfields").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching custom field types."),
			data: {}
		};
	});
}

// Create New Custom Field
export const createCustomFields = (variables) => {
	return api("crm").post("customfields", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while creating preference."),
			data: {}
		};
	});
}

// Update Custom Field
export const updateCustomFields = (variables) => {
	return api("crm").put("customfields", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating preference."),
			data: {}
		};
	});
}

// Update Flags for custom fields isVisible, isRequred, isIncludeAllList
export const updateCustomFieldsPreference = (variables) => {
	return api("crm").put("customfields", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating preference."),
			data: {}
		};
	});
}

// Delete Custom Fields
export const deleteCustomField = (customFieldId, isDeleteFromAll) => {

	return api("crm").delete(`customfields/${customFieldId}/${isDeleteFromAll}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating preference."),
			data: {}
		};
	});
}

// Fetch Segmentation List
export const getSegmentationList = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/Segmentation/List?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segmentation List."),
			data: {}
		};
	});
}

// Export Segment
export const exportSegment = (queryString, variables) => {
	const params = objectToParamsWithEmptyValue(queryString);

	return api("EXPORTV3", "export", "Bearer ").post(`/export/create/1?${params}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while exporting segment."),
			data: {}
		};
	});
}

export const deleteSegment = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").delete(`/Segmentation/ListById?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting segmentation List."),
			data: {}
		};
	});
}

// Fetch Import Status List (Completed, Draft, Failed, Import)
export const getImportStatusProgress = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/progress/Imports/Subscribers?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching import statuses."),
			data: {}
		};
	});
}

// Delete Import Statuses
export const deleteImportStatus = (importId) => {

	return api("crm").delete(`subscriberlist/Import/${importId}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting import status."),
			data: {}
		};
	});
}

// Fetch Import Summary
export const fetchImportSummary = (importId) => {

	return api("crm").get(`subscriberlist/Imports/${importId}/Summary`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching import summary."),
			data: {}
		};
	});
}

// Fetch Import Details
export const fetchImportDetails = (importId, columnName) => {

	return api("crm").get(`subscriberlist/Imports/${importId}/Summary/${(columnName || "")}/Details?skip=0&limit=10`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching import details."),
			data: {}
		};
	});
}

// Fetch Progress status (View Logs)
export const fetchImportProgressLogsDetails = (importId) => {

	return api("crm").get(`subscriberlist/Import/${importId}/ProgressStatus`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching progress status."),
			data: {}
		};
	});
}

// Fetch loyalty programs
export const getLoyaltyProgramsList = () => {

	return api("APIV3").get("LoyaltyProgram/GetUserLoyaltyProgram").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching loyalty programs."),
			data: {}
		};
	});
}

// Save loyalty programs
export const saveLoyaltyProgram = (variables) => {

	return api("APIV3").post("LoyaltyProgram/SaveLoyaltyProgram", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while saving loyalty program."),
			data: {}
		};
	});
}

// Update loyalty programs
export const updateLoyaltyProgram = (programId, variables) => {

	return api("APIV3").put(`LoyaltyProgram/UpdateLoyaltyProgram/${programId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating loyalty program."),
			data: {}
		};
	});
}

// Delete loyalty program
export const deleteLoyaltyProgram = (programId) => {

	return api("APIV3").delete(`LoyaltyProgram/DeleteLoyaltyProgram/${programId}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting loyalty program."),
			data: {}
		};
	});
}

// Fetch loyalty programs rules
export const getLoyaltyProgramRules = () => {

	return api("APIV3").get("LoyaltyProgram/GetUserLoyaltyProgramRules").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || [])
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching loyalty program rules."),
			data: {}
		};
	});
}

// Fetch Subscribers List
export const getSubscribersList = () => {

	return api("APIV3").get("CRM/Contacts/SubscriberList").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || [])
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching subscribers list."),
			data: []
		};
	});
}

// Fetch loyalty programs Controls
export const getLoyaltyProgramControls = () => {

	return api("APIV3").get("CRM/Contacts/f388afaa-45c4-ee11-a534-809302526beb/LoyaltyProgramControls").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || [])
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching subscribers list."),
			data: {}
		};
	});
}

// Save loyalty programs
export const saveLoyaltyProgramRule = (variables) => {

	return api("APIV3").post("LoyaltyProgram/SaveLoyaltyProgramRules", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while saving loyalty program rule."),
			data: {}
		};
	});
}

// Update Rule
export const updateRule = (programId, variables) => {

	return api("APIV3").put(`LoyaltyProgram/UpdateLoyaltyProgramRules/${programId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating loyalty program."),
			data: {}
		};
	});
}

// Delete Rule
export const deleteRule = (programId) => {

	return api("APIV3").delete(`LoyaltyProgram/DeleteLoyaltyProgramRules/${programId}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting loyalty program rule."),
			data: {}
		};
	});
}

// Get Lokup Category
export const getLookupCategoryList = () => {

	return api("crm").get("lookUp/listcategory").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching categories."),
			data: {}
		};
	});
}

// Create List API
export const createList = (variables) => {

	return api("crm").post("/subscriberlist", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while create list."),
			data: {}
		};
	});
}

// Create List API
export const exportList = (creationSource, variables) => {
	const params = objectToParams(variables);

	return api("EXPORTV3", "export", "Bearer ").post(`/export/create/${creationSource}?${params}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while exporting list."),
			data: {}
		};
	});
}

// Step 1: Upload Contacts
export const importContacts = (variables, setParentState = null) => {
	const config = {
		onUploadProgress: (data) => {
			if (typeof setParentState === "function") {
				console.log("DAta: ", data, Math.round((100 * (data.loaded || 0)) / (data.total || 0)));
				setParentState({
					progress: Math.round((100 * (data.loaded || 0)) / (data.total || 0))
				});
			}
		}
	};
	return api("EXPORTV3", "import", "Bearer ").post("contacts/upload", variables, config).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while uploading contacts."),
			data: {}
		};
	});
}

//  Step 1: Create Import List after Upload Contacts response
export const subscribersCreateImport = (listId, variables) => {

	return api("crm", "authProd").post(`subscriberlist/${listId}/Import`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while creating contacts."),
			data: {}
		};
	});
}

// Step 3: Mapping > Import Subscriber List
export const importSubscriberList = (importId, variables) => {

	return api("crm").put(`/subscriberlist/Import/${importId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while importing subscribers."),
			data: {}
		};
	});
}

// Step 3: Mapping > Lookup Consents
export const getLookupConsents = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/lookUp/Consents?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching consents."),
			data: {}
		};
	});
}

// Step 4: Import Rules > Lookup Consents
export const getLookupImportConsents = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/lookUp/GetImportRules?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching import rules consents."),
			data: {}
		};
	});
}

// Step 3: Mapping > Lookup Consents
export const fetchMappingFields = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/mappingfields?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching mapping fields."),
			data: {}
		};
	});
}

// Step 6: Get Summary
export const getMappingFields = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriberlist/Imports/GetSummaryBeforeImport?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching summary."),
			data: {}
		};
	});
}

// Profile > Profile > Get Profile Details
export const getProfileDetails = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/PropertiesFilter/GetProfileProperties?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching profile details."),
			data: {}
		};
	});
}

// Profile > Profile > Update Fields
export const addFieldsAndValue = (variables) => {

	return api("crm").post("customfields/addFieldsAndValue", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating fields."),
			data: {}
		};
	});
}

// Profile > Profile > GetLoyalty Score Details
export const getPeopleCurrentLoyaltyLevelScoreDetail = (pId) => {

	return api("APIV3").get(`/LoyaltyProgram/GetPeopleCurrentLevelScoreDetail/${(pId || 0)}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching loyalty score details."),
			data: {}
		};
	});
}

// Profile > Profile > All Addresses
export const getAllAddresses = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/GetAllAddress?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching addresses."),
			data: {}
		};
	});
}

// Profile > Profile > GET CRM Uploaded Files
export const getCRMUplodedFiles = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("APIV3").get(`/CRM/UplodedFiles?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching uploaded files."),
			data: {}
		};
	});
}

// Profile > Profile > GET User Segments
export const getUserSegments = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("APIV3").get(`/CRM/Contacts/GetUserSegments?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segments."),
			data: {}
		};
	});
}

// Profile > Address
export const addEditAddress = (variables) => {

	return api("crm").post("subscriber/InsertAddress", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while inserting/editing address."),
			data: {}
		};
	});
}

// Profile > Address > Get all countries
export const getAllCountries = (variables) => {

	return api("crm").get("subscriber/GetAllCountries", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching countries."),
			data: {}
		};
	});
}

// Profile > Address > Address types
export const getAddressTypes = (variables) => {

	return api("crm").get("subscriber/GetAddressTypes", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching address types."),
			data: {}
		};
	});
}

// Profile > Communnication > GET Email Details
export const getEmailDetails = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/GetEmailDetails?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching email details."),
			data: {}
		};
	});
}

// Profile > Communnication > GET Mobile Details
export const getMobileDetails = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/subscriber/GetMobileDetails?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching mobile detials."),
			data: {}
		};
	});
}

// Profile > Communnication > GET Mobile Details
export const getMobileAppFollowers = (peopleSubscriberId) => {

	return api("", "main", "Bearer ").get(`/Mobile/AppFollowers/${peopleSubscriberId}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching mobile followers."),
			data: {}
		};
	});
}

// Profile > Communnication > GET priority types
export const getPriorityTypes = () => {

	return api("crm").get("/subscriber/GetPriorityType").then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching priority types."),
			data: {}
		};
	});
}

// Profile > Communication
export const addEditMobileNumber = (variables) => {

	return api("crm").post("subscriber/InsertMobileNumber", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while inserting/editing mobile number."),
			data: {}
		};
	});
}

// Profile > Communication
export const addEditEmail = (variables) => {

	return api("crm").post("subscriber/InsertEmail", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: (res.data || {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while inserting/editing email."),
			data: {}
		};
	});
}

// Profile > Notes > GET Notes
export const getCRMNotes = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("APIV3").get(`/CRM/Notes?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching notes."),
			data: {}
		};
	});
}
// Profile > Notes > Create Notes
export const createCRMNotes = (variables) => {

	return api("APIV3").post("/CRM/Notes", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while creating notes."),
			data: {}
		};
	});
}
// Profile > Notes > Update Notes
export const updateCRMNotes = (variables) => {

	return api("APIV3").put("/CRM/Notes", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating notes."),
			data: {}
		};
	});
}

// Profile > Notes > Delete Notes
export const deleteCRMNotes = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("APIV3").delete(`/CRM/Notes?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while deleting notes."),
			data: {}
		};
	});
}

// Profile > Activity > Save Activity 
export const saveActivityCRMLog = (variables) => {

	return api("APIV3").post("ActivityCRMLog", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while saving CRM activity."),
			data: {}
		};
	});
}

// Profile > Activity > GET Responsible
export const getActivityCRMResponsible = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/Responsible/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM responsible."),
			data: {}
		};
	});
}

// Profile > Activity > GET Content
export const getActivityCRMContent = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/Content/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM content."),
			data: {}
		};
	});
}

// Profile > Activity > GET Name
export const getActivityCRMName = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/Name/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM name."),
			data: {}
		};
	});
}

// Profile > Activity > GET Distribution Name
export const getActivityCRMDistributionName = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/DistributionName/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM distribution name."),
			data: {}
		};
	});
}

// Profile > Activity > GET Event
export const getActivityCRMEvent = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/Event/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM event."),
			data: {}
		};
	});
}

// Profile > Activity > GET Object
export const getActivityCRMObject = (peopleSubscriberId, variables) => {

	return api("APIV3").post(`ActivityCRMLog/ListValues/Object/${peopleSubscriberId}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM object."),
			data: {}
		};
	});
}

// Profile > Activity > GET Activity
export const getCRMActivityLogs = (peopleSubscriberId, queryVariables, variables) => {
	const params = objectToParamsWithEmptyValue(queryVariables);

	return api("APIV3").post(`ActivityCRMLog/GetActivityLogs/${peopleSubscriberId}?${params}`, variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching CRM activity logs."),
			data: {}
		};
	});
}

// Segmentation > Create Segment
export const createSegment = (variables) => {

	return api("crm").post("/Segmentation", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while creating segment."),
			data: {}
		};
	});
}

// Segmentation > Get Segment Details
export const getSegmentDetails = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/Segmentation/GetSegmentDetails?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segment details."),
			data: {}
		};
	});
}

// Segmentation > Update Segment
export const updateSegment = (variables) => {

	return api("crm").put("/Segmentation/UpdateSegmentDetails", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating segment."),
			data: {}
		};
	});
}

// Segmentation > Define Segment > Get Segmentation Fields
export const getSegmentationFields = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/Segmentation/Fields?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segmentation fields."),
			data: {}
		};
	});
}

// Segmentation > Define Segment > Get Segment Query Json
export const getSegmentQueryJson = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/Segmentation/SegmentQueryJson?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segmentation json data."),
			data: {}
		};
	});
}

// Segmentation > Define Segment > Get Segment Operators
export const getSegmentOperators = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("crm").get(`/Segmentation/Operators?${params}`).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segmentation operatorss."),
			data: {}
		};
	});
}

// Segmentation > Define Segment > Get Segment Operators
export const submitSegmentation = (variables) => {

	return api("crm").put("/Segmentation", variables).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while fetching segmentation results."),
			data: {}
		};
	});
}

// Segmentation > Update Loyalty Points
export const updateLoyaltyPoints = (variables) => {
	const params = objectToParamsWithEmptyValue(variables);

	return api("APIV3").post(`/CRM/UpdateLoyaltyPointsinCRM?${params}`, {}).then((res) => {
		return {
			flag: true,
			message: (res.message || ""),
			data: _get(res, "data", {})
		};
	}).catch((err) => {
		return {
			flag: false,
			title: _get(err, "response.data.title", "Alert"),
			message: _get(err, "response.data.message", "Something went wrong while updating loyalty points."),
			data: {}
		};
	});
}