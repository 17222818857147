import React, { useState } from 'react'
import ChangePlan from './ChangePlan'
import ChargifyPayment from '../../ReuseTemplate/ChargifyPayment';
import moment from 'moment/moment';
import getLocaleDateString from '../../TimeFormat';
import ConfirmUpgradePlan from './ConfirmUpgradePlan';

const Subscription = ({ Data ,APITOKEN,TabReload}) => {
    const [changePlaDisplay, setChangePlanDisplay] = useState(false);
    const [chargifyDisplay, setChargifyDisplay] = useState(false);
    const format = getLocaleDateString(APITOKEN.config.cultureCode)
    const changePlan = () => {
        setChangePlanDisplay(true);
    }
    const [priceDetailsPlanChange, setpriceDetailsPlanChangeFn] = useState({
        display:false
    });

    return (
        <><div className="white bx-boder-common width100">
            <div className="_h_header">
                <h3>Subscription</h3>
            </div>
            <div className="padding20">
                <div className="__sub__bx">
                    <div className="__b_w">
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Current Plan</label>
                            <label className="plan_info_subscription inline-block">
                                <strong className="inline-block fo_subscription margin-right15">{Data.productName}</strong>
                                

                            </label>
                            {(APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 12) ?<></> :<button className="grid_btn_1 grid_btn_darkBlue change_plan_subscriber" type='button' onClick={changePlan}>Change Plan</button>}
                            
                        </div>
                    </div>
                    <div className="__b_w" style={{display:(APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 12)?(APITOKEN.config.paymentSource === 'onlinecardpayment')?'block':'none':'block'}}>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Balance</label>
                            <label className="plan_info_subscription">
                                <em className="curreny_icon">{Data.currency}</em>
                                <strong className="inline-block __balnceInfo">{Data.balance}</strong>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="b_top">
                <div className="__sub__bx padding20">
                    <div className="__b_w">
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Subscription Period</label>
                        </div>
                        <p className="font-16">
                            { moment(Data.subscriptionStartedAt).format(format) } - {moment(Data.subscriptionEndedAt).format(format)}
                        </p>
                    </div>
                </div>
            </div>
        </div>
            {APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 12 ? <></> :
                <> <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Payment Method</h3>
                    </div>
                    <div className="padding20">
                        {!!Data.paymentProfile ? <>
                            <div className="formmodel-bx paymentCardbox__panel" style={{ width: '30%', textAlign: 'left' }}>
                                <div className="card_info member__Card">
                                    <p className='card__logo'>
                                        <img alt='card' width={30} src={Data.paymentProfile.cardType === 'visa' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/visa.png' : Data.paymentProfile.cardType === 'master' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/mastercard.png' : 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/amax.png'} />
                                    </p>
                                    <p className='cardMember_info'>
                                        {Data.paymentProfile.firstName} {Data.paymentProfile.lastName}
                                    </p>
                                    <p className='card__no'>{Data.paymentProfile.maskedCardNumber}</p>
                                    <p className='card__date'>{Data.paymentProfile.expirationMonth}/{Data.paymentProfile.expirationYear}</p>
                                </div>
                            </div>
                        </> : <p>You need to add your credit card to be able to buy extra resourses, create a balance or to upgrade your account.</p>}

                    </div>
                    <div className="padding20 text-right">
                        {!!Data.paymentProfile ? <button type="button" className="grid_btn_1 grid_btn_darkBlue addCard_btn" onClick={() => setChargifyDisplay(true)}>Change Card</button> : <button type="button" className="grid_btn_1 grid_btn_darkBlue addCard_btn" onClick={() => setChargifyDisplay(true)}>Add card</button>}

                    </div>
                </div>
                    <div className="sms_Sec_1 bx-boder-common width100">
                        <div className="_h_header">
                            <h3>Invoices</h3>
                        </div>
                        <div className="padding20">
                            <p>Here you will find your payment receipts for all your transactions.</p>
                        </div>
                        <div className="padding20 text-right">
                            <a href={Data.billingPortalUrl} target='_blank' rel="noreferrer" className="grid_btn_1 change_plan text-black">Invoices</a>
                        </div>
                    </div>
                    <div className="sms_Sec_1 bx-boder-common width100">
                        <div className="_h_header">
                            <h3>Cancel Subscription</h3>
                        </div>
                        <div className="padding20">
                            <p>Your account will be terminated at the end of your subscription period. You can keep operating your account until the expiry of <br />
                                your subscribed plan.</p>
                        </div>
                        <div className="padding20 text-right">
                            <a href={Data.billingPortalUrl} target='_blank' rel="noreferrer" className="grid_btn_1 change_plan text-black">Cancel Subscription</a>
                        </div>
                    </div>
                </>}
            {changePlaDisplay && <ChangePlan closePopup={setChangePlanDisplay} Data={Data} setChangePlanDisplay={setChangePlanDisplay}  priceDetailsPlanChange={priceDetailsPlanChange} setpriceDetailsPlanChangeFn={setpriceDetailsPlanChangeFn} />}
            {priceDetailsPlanChange.display && <ConfirmUpgradePlan Data={priceDetailsPlanChange} APITOKEN={APITOKEN} setData={setpriceDetailsPlanChangeFn} Reload={TabReload} />}
            {chargifyDisplay && <ChargifyPayment setChargifyDisplay={setChargifyDisplay} reload={()=>{}}  />}
        </>
    )

}

export default Subscription
