import BlankData from "../../BlankData"
import GridLoader from "../../ReuseTemplate/GridLoader";

const GridAggregated = ({ dataPin, onClick, IsDisplay }) => {
    return (
        <>
            {IsDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5]} /> :
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                    {dataPin.length > 0 &&
                        <thead>
                            <tr>
                                <th className="table_cell_header">KPI</th>
                                <th className="table_cell_header">Description</th>
                                <th className="table_cell_header">Status</th>
                                <th className="table_cell_header">Status Rate</th>
                                <th className="table_cell_header">Action</th>
                            </tr>
                        </thead>}<tbody>{dataPin.length === 0 ? <BlankData title="You have not sent any Survey" content="After you have sent an Survey you will find the aggredated Survey Insight for the Last 30 Days here." colSpan="6" /> : <Grid dataPin={dataPin} onClick={onClick} />}</tbody></table>
            }
        </>
    )
}
export default GridAggregated;

const Grid = ({ dataPin, onClick }) => {
    return (
        <>
            {dataPin && dataPin.map((v, i) =>
                <tr key={i}><td className="body_table_cell">{v['name']}</td>
                    <td className="body_table_cell">{v['description']}</td>
                    <td className="body_table_cell" style={{ color: v['countColor'] }}>{v['count'] === -1 ? v['timeFormat'] : v['count']}</td><td className="body_table_cell " style={{ color: v['rateColor'] }}>{(v['formattedRate'] === undefined) ? '-' : v['formattedRate'] + '%'}</td>
                    <td className="body_table_cell" style={{ display: 'none' }}>{v['kpiBasedGoalValue']}</td>
                    <td className="FormattedRate" style={{ display: 'none' }}><i className="fas fa-trophy" style={{ fontSize: '19px' }}></i></td>
                    <td className="body_table_cell">
                        <button type="button" onClick={() => onClick(v)} className="link pinunpin">{(!v['isPinned']) ? 'Pin' : 'Remove Pin'}</button>
                    </td>
                </tr>
            )}
        </>
    )
}
