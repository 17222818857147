
import Tabing from './Tabing';
import './index.css'
import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../GlobalAPICall';
import getParameterByName from '../../ReuseTemplate/GetRoute';
import axios from 'axios';

export const filterFn = data => {
    const result = data.map(item => item.tags[0]).map(item => item.templateList)[0];
    return result;
}
const SelectTemplate = () => {
    const { APITOKEN } = useSelector(state => state);
    const [selectedTemplateData, setSelectedTemplateData] = useState([]);

    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`Lookup/templates/categories/${getParameterByName('cam', window.location.href)}`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setSelectedTemplateData(data);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
            setSelectedTemplateData([]);
        }
    }, [APITOKEN]);

    useEffect(() => {
        load();
    }, [load])

    return (
        <div className="_teml_w">
            <div className="">
                <div className="head_p">
                    <h1>Templates</h1>
                    <p>Select one of your saved Templates or our ready made Layouts or Themes to get started on your creation.</p>
                </div>
                <Tabing Data={selectedTemplateData} APITOKEN={APITOKEN} />
            </div>
        </div>
    )

}

export default SelectTemplate
