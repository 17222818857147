import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Default from './Default';
import Integration from './Integration';
import APIDoc from './APIDOC';
import SMTP from './SMTP';
import Domain from './Domain';
import getParameterByName from '../../ReuseTemplate/GetRoute';
import TwoWayAuth from './TwoWayAuth';

const Tabing = ({ data, isVisible ,APITOKEN }) => {

    const defaultId = 38;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.Id);
    }
    useEffect(() => {
        const returnId = getParameterByName('_id', window.location.href);
        clickmethod({ Id: returnId !== null ? parseInt(returnId) : defaultId });
    }, [])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return  item.show && <li key={index} onClick={() => clickmethod(item)} ><Link className={item.Id === tabId ? 'active ' : ' '} id={item.Id}>{item.Name}</Link></li>
                    })}
                </ul>
            </div>
            {

                <div className="tab_body">{tabId === defaultId ?
                    <Default tabId={tabId} isVisible={isVisible} /> : tabId === 39 ? <Domain /> : tabId === 75 ? <SMTP /> : tabId === 57 ? <Integration /> : tabId === 42 ? <APIDoc /> : <TwoWayAuth APITOKEN={APITOKEN} />}
                </div>
            }
        </>
    )
}

export default Tabing;