// import _ from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import Client from '../../GlobalAPICall';
import getParameterByName from '../../ReuseTemplate/GetRoute';
import { RecipientsPanel } from '../RecipientsPanel'
import StepsDistribution from '../StepsTopBar';
import { encode as btoa } from 'base-64'
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';

export const RecipientMobileScreen = () => {
    const { APITOKEN } = useSelector(state => state);
    const [isRecipients, setIsrecipients] = useState([]);
    const navigate = useNavigate();
    let [recipientsObject, setRecipientsObject] = useState({
        ParentObject: [],
        NestedObject: [],
        ExcludedObject:[]
    });
    const [IsLoader, setIsLoader] = useState(false);
    const wrapperRef = useRef(null);
    const [recipientsList, setRecipients] = useState([]);
   
    // Step 1 Complete API Call //
    const step1Complete = () => {
        const CampaignId = getParameterByName('cam', window.location.href);
        setIsLoader(true);
        const obj = {
            "groups": [...recipientsObject.ParentObject, ...recipientsObject.NestedObject],
            "contentId": CampaignId,
            "contentTypeId": 95
        };
        if (getParameterByName('edit', window.location.href) !== null) {
            PutAPI(obj, CampaignId);
        } else {
            PostAPI(obj, CampaignId)
        }
    };
    const PutAPI = (obj, CampaignId) => {
        Client.put(`Mobile/Distribution/${CampaignId}`, obj, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.Success) {
                        navigate(`/Mobile/Send/${CampaignId}?edit=true`);
                        setIsLoader(false);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })

    }
    const PostAPI = (obj, CampaignId) => {
        Client.post(`Mobile/Distribution`, obj, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.Success) {
                        navigate(`/Mobile/Send/${res.data.Id}?cam=${CampaignId}`);
                        setIsLoader(false);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            }).finally(res => {
                setIsLoader(false);
            })

    }
    const editCaseCheckboxSelected = (prvs, res) => {
        const response = [];
        for (let x of prvs) {
            for (let e of res.ExcludedGroups) {
                if (!!x.segmentations) {
                    for (let s of x.segmentations) {
                        if (e.id === s.id) {
                            s.exclude = true;
                            s.isSelected = true;
                        }
                    }
                }

            }
            for (let y of res.Groups) {
                if ((y.type === 0)) {
                    x.disabled = true;
                    prvs[0].disabled = false;
                    if (!!x.segmentations) {
                        for (let s of x.segmentations) {
                            s.disabledSeg = true;
                        }
                    }
                }
                if ((x.id === y.id) && (y.type === 0)) {
                    x.isSelected = y.isSelected;
                    y.checked = y.isAnySegmentSelected;
                    if (!!y.segmentations) {
                        for (let g of x.segmentations) {
                            for (let s of y.segmentations) {
                                g.isSelected = s.isSelected;
                            }
                            g['ParentCheckboxSelected'] = y.isAnySegmentSelected;
                            for (let b of res.ExcludedGroups) {
                                if (g.id === b.id) {
                                    g['exclude'] = true;
                                }
                            }
                        }
                    }

                } else if (x.id === y.id) {
                    x.display = true;
                    prvs[0].allContactSelected = true
                    x.isSelected = y.isSelected;
                    prvs[0].disabled = x.type === 0 ? false : true;
                    if (!!prvs[0].segmentations) {
                        for (let g of prvs[0].segmentations) {
                            g.disabledSeg = true;
                        }
                    }
                    x.checked = y.isAnySegmentSelected;
                    if (!!y.segmentations) {
                        for (let g of x.segmentations) {
                            for (let s of y.segmentations) {
                                if (g.id === s.id) {
                                    g.isSelected = s.isSelected;
                                    setIsrecipients(prv=>([...prv,s]));
                                }
                            }
                            g['ParentCheckboxSelected'] = y.isAnySegmentSelected;
                            for (let b of res.ExcludedGroups) {
                                if (g.id === b.id) {
                                    g['exclude'] = true;
                                    x.display = true;
                                    g.isSelected = true;
                                    setIsrecipients(prv => (prv.filter(x => x.id !== b.id)));
                                    //check All contact checkbox true / false //
                                }
                            }
                        }
                    }
                }

            };
            response.push(x)
        };
        return response
    };

    const recipientObjectFilter = (prevs, response) => {
        const obj = { ParentObject: [], NestedObject: [], ExcludedObject: [] };
        for (let group of response.Groups) {
            if (group.isSelected) {
                obj.ParentObject.push(group);
            };
            if (group.segmentations !== undefined) {
                for (let segment of group.segmentations) {
                    if(segment.isSelected) obj.NestedObject.push(segment);
                }
            };

        };
        for (let exclude of response.ExcludedGroups) {
            obj['ExcludedObject'].push(exclude);
        }
        return { ...prevs, ...{ ParentObject: obj.ParentObject, NestedObject: obj.NestedObject, ExcludedObject: obj.ExcludedObject } }
    }

    const recipientEdit = useCallback(() => {
        APITOKEN.token &&
            Client.get(`SMS/Distribution/${getParameterByName('cam', window.location.href)}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        let response = result.data;
                        setRecipients(prvs => editCaseCheckboxSelected(prvs, response));
                        setRecipientsObject(prevs => recipientObjectFilter(prevs, response))
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {

                });
    }, [APITOKEN])
    const crmList = () => {
        window.location.replace(`${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&redurl=crm/people/joinlist`)
    }
    const countFetch = recipientElement => {
        setRecipients(recipientsList.map(x=> x.id === recipientElement.id ? ({...x,isLoader:true}):{...x}));
        Client.get(`Mobile/Contacts/Count/${recipientElement.id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                const { recordCount, segmentations } = data;
                setRecipients(recipientsList.map(x => x.id === recipientElement.id ? ({ ...x, recordCount, segmentations: !!segmentations && x.segmentations.map((y,i)=> ({...y,recordCount:segmentations[i].recordCount}))}) : { ...x }));
            } catch (err) {
                console.error(err);
            }
        }).catch(err=> console.error(err));
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                // setSearchData([]);
                // setnewarrySearchData(prev => ({ ...prev, data: [], selected: prev.selected }));
            }
        }
        // countryLoad();
        if (getParameterByName('edit', window.location.href) !== null) {
            recipientEdit();
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, [recipientEdit]);
    return (
        <>
            <StepsDistribution Name="Mobile" Active={1} Hide={true} />
            <div className="dashbd_sec margin-bottom">
                <div ref={wrapperRef}></div>
                <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Mobile Recipients</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1 grid_btn_active" onClick={crmList}>Create List</button>
                        </span>
                    </div>
                    <RecipientsPanel
                        setRecipientsObject={setRecipientsObject}
                        recipientsObject={recipientsObject}
                        SetRecipients={setRecipients}
                        RecipientsList={recipientsList}
                        isRecipients={isRecipients}
                        setIsrecipients={setIsrecipients}
                        countFetch={countFetch}
                    />
                    <div className="b_top">
                        {/* <div className="_h_header noborder">
                            <h3>&nbsp;</h3>
                            <span className="flex-right">
                                <button
                                    type="button"
                                    className="grid_btn_1  margin-right15"
                                    onClick={() => setRecipientTab(prev => ({ ...prev, manullay: !prev.manullay, import: false, copy: false }))} >{recipientTab.manullay ? 'Close' : 'Add Recipients Manually'}</button>
                                <button
                                    type="button"
                                    className="grid_btn_1  margin-right15"
                                    onClick={() => setRecipientTab(prev => ({ ...prev, manullay: false, import: !prev.import, copy: false }))} >{recipientTab.import ? 'Close' : 'Quick Import'}</button>
                                <button
                                    type="button"
                                    className="grid_btn_1"
                                    onClick={() => setRecipientTab(prev => ({ ...prev, manullay: false, import: false, copy: !prev.copy }))}>{recipientTab.copy ? 'Close' : 'Copy Paste Recipients'}</button>
                            </span>
                        </div> */}
                        {/* Recipient Tab Componets */}
                        {/* {recipientTab.manullay &&
                            < div className="bx-pad b_top">
                                <div className="formmodel-bx drop-zone">
                                    <label className="_l_able_cls">Search CRM</label>
                                    <input type="text" className="inputformmodel" placeholder="Search CRM" onChange={e => debounced(e)} />
                                    {searchVal.length > 0 && <div className="data_reponse_bx">
                                        <ul>
                                            {searchVal.map((v, i) => <li key={i}> <button key={i} type="button" disabled={v.selected ? true : false} className="CRM_Count" onClick={e => selectedRecipients(e, v)}>{v.FirstName} {v.formattedMobileNumber} {v.SubscriberListName}</button></li>)}
                                        </ul>

                                    </div>}
                                </div>
                                <div className="formmodel-bx">
                                    <span>
                                        <strong>To be able to manually add contacts you have to agree that you have SMS Opt-in consent. </strong>
                                        <strong className="link inline-block agreeLink pointer" onClick={() => setcountryDropDownActive(!countryActive)}>I Agree</strong> &nbsp; &nbsp;
                                    </span>
                                    <input type="checkbox" className="inline-block vertical-middle" checked={!countryActive} onChange={() => setcountryDropDownActive(!countryActive)} />
                                </div>
                                <div className="formmodel-bx drop-zone">
                                    <label className="_l_able_cls">Select Country</label>
                                    <input type="text"
                                        className="inputformmodel inputselectBg"
                                        disabled={countryActive}
                                        onChange={e => countrySearch(e)} placeholder="Select Country" />
                                    {newarrySearchData.data.length > 0 && <ul className="country_filter">
                                        {newarrySearchData.data.map((v, i) =>
                                            <li key={i}>
                                                <button type="button" className="nobg noborder pointer width100 inline-block text-left" onClick={() => selectCountryCode(v)}>{v.countryname}</button>
                                            </li>)}</ul>}
                                </div>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Mobile Number</label>
                                    <input type="text"
                                        className="inputformmodel f-m-one margin-right15"
                                        disabled
                                        defaultValue={newarrySearchData.selected} />
                                    <input type="text"
                                        className="inputformmodel margin-right15"
                                        placeholder="Mobile Number"
                                        value={manuallRecipient.value || ""}
                                        style={{ width: 'calc(100% - 549px)' }}
                                        disabled={countryActive}
                                        onChange={e => manuallyRecipient(e.target.value)} />
                                    <button type="button"
                                        className="grid_btn_1 grid_btn_active btnadd"
                                        disabled={(manuallRecipient.value !== "" && manuallRecipient.value !== undefined) ? false : true}
                                        onClick={() => manuallyRecipientAdd()}>Add</button>
                                </div>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Recipients</label>
                                    <div className="inputformmodel textareaModel overflowAuto">
                                        {selectedRecipient.map((v, i) => <div className="l_b-bx" key={i}>{v.Recipient}<span></span><button type="button" onClick={e => removeRecipients(e, v)} className="nobg noborder pointer"><i className="fas fa-trash-alt"></i></button></div>)}

                                    </div>
                                </div>
                            </div>
                        } */}
                        {/* Import Tab Componets */}
                        {/* {recipientTab.import && <>{importObject.sample_data &&
                            importObject.sample_data.length > 0 ?
                            <table cellPadding="0"
                                cellSpacing="0"
                                className="smstable"><thead>
                                    <tr><th className="table_cell_header">Mobile Number</th></tr>
                                </thead><tbody>{importObject.sample_data.map((x, i) => <tr key={i}><td className="body_table_cell">{x.MobileNumber || x['PhoneNumber']}</td></tr>)}</tbody></table> : <FileImport SetFileImportObject={setFileImportObject} APIName="sms" Name="SMS" />}</>} */}
                        {/* Copy - Paste Componets */}
                        {/* {recipientTab.copy && <CopyPaste copyPasteFN={copyPasteFN} TotalPaste={totalPaste} Placeholder="For multiple numbers, use 'Enter' to separate numbers. Don't use comma, semicolon etc. Enter Mobile number with country code, Don't use 0 and +." />} */}
                        <div className="text-right padding20 b_top">
                            <button className="grid_btn_1 grid_btn_active btn-gape-15 recipients_panel_fill" disabled={
                                (recipientsObject.ParentObject.length > 0 || recipientsObject.NestedObject.length) ? false : true
                            } type="button" onClick={step1Complete}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    Continue
                                </ButtonLoader>

                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </>)

}

