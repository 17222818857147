import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { get as _get, isEmpty as _isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import Client from '../../../GlobalAPICall';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Select from 'react-select';
import VideoGrid from './VideoGrid';
import '../index.css'
import { ImportVideoVimeo, VideoThumnailAPI } from './ImportVideo';
import * as  tus from 'tus-js-client';
import * as filestack from 'filestack-js';
import VideoCaption from './VideoCaption';
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';
//import Tags from '../Tags';
import Languages from '../Track/Languages';
import CatgoryList from '../Categories/CatgoryList';
const client = filestack.init('APBcz9XF7TEmEQpcF5wmxz');
function Video() {
	const { APITOKEN } = useSelector(state => state);
	const [categoriesList, SetCategoriesList] = useState([]);
	const [subCatgory, SetSubCatogry] = useState([]);
	const [percentage, setPercentage] = useState("0");
	const [isShowPercentage, setIsShowPercentage] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const [deleteObj, setDelete] = useState({});
	const [thumnailResponse, setThumnailResponse] = useState({});
	const [editThumbnailImageData, setEditThumbnailImageData] = useState(null);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const videoInputRef = React.useRef();
	const [pushNotification, setPushNoty] = useState({
		value: "",
		notificationText: "",
		shareType: ""
	})
	const [videoObject, setVideoObject] = useState({
		data: [],
		langSelected: false,
		speakerSelected: false,
		captionSliderDisplay: false,
		videoId: 0
	})
	const [isLoader, setIsLoader] = useState(false);

	const [lang, SetLang] = useState({
		data: [],
		Speaker: []
	});
	const [objectArray, setObject] = useState({
		title: "",
		description: "",
		categoryId: "",
		speakerId: 0,
		languageCode: "",
		videoURL: "",
		videoId: "",
		thumbnailURL: "",
		searchTags: [],
		subCategoryId: []
	});
	const handleChange = async eventObject => {
		// Get the selected file from the input element
		const file = eventObject.target.files[0];
		// const fileName = file.name;
		const fileSize = file.size.toString();
		const { data } = await ImportVideoVimeo(fileSize);
		setObject(prv => ({
			...prv,
			videoURL: data.link,
		}));
		setEditThumbnailImageData(null);
		const upload = new tus.Upload(file, {
			endPoint: `${process.env.REACT_APP_VIMEO_URL}`,
			uploadUrl: data.upload.upload_link,
			retryDelays: [0, 3000, 5000, 10000, 20000],
			metadata: {
				filename: file.name,
				filetype: file.type
			},
			headers: {},
			onError: function (error) {
				console.log('Failed because: ' + error);
			},
			onProgress: function (bytesUploaded, bytesTotal) {
				let percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
				setPercentage(percentage + '%');
				setIsShowPercentage(true);
			},
			/*onSuccess: function () {
				setPercentage("0%");
			}*/
		});
		upload.start();
	};
	const catFilter = res => {
		const array = [];
		//array.push(...res.map(x => ({ ...x, value: x.value, label: x.value })))
		array.push(...res.map(x => ({ id: x.id, value: x.value })))
		return array;
	}
	const onChangeFn = newValue => {
		setObject(prv => ({
			...prv,
			languageCode: newValue,
			categories: [],
			subCategoryId: []
		}));

		setSelectedCategory(null);
		setVideoObject(prv => ({
			...prv,
			langSelected: true
		}))
		const cancelToken = axios.CancelToken.source();
		Client.get(`Mobile/Speakers?languageCode=${newValue}`)
			.then(({ data }) => {
				try {
					SetLang(prv => ({
						...prv,
						Speaker: data
					}));
					catgoriesList(newValue);
				} catch (err) {
					console.error(err);
				}
			}).catch(err => {
				if (axios.isCancel(err)) {
					cancelToken.cancel();
				}
			});

	}


	const catgoriesList = ev => {
		const cancelToken = axios.CancelToken.source();
		Client.get(`Mobile/AppCategories?languageCode=${ev}`).then(({ data }) => {
			try {
				SetCategoriesList(catFilter(data) || []);
			} catch (err) {
				console.error(err);
			}
		}).catch(err => {
			if (axios.isCancel(err)) {
				cancelToken.cancel();
			}
		});
	}

	const onChange = (newValue) => {

		let tmpCategoryPayload = _get(objectArray, "categories", []);

		// Set sub categories in final payload array
		//const indexOfParentCategory = (tmpCategoryPayload || []).map((cat, i) => (cat.id === _get(selectedCategory, "id", null)) ? i : 0);

		tmpCategoryPayload = (tmpCategoryPayload || []).map((cat, i) => {
			if (cat.id === _get(selectedCategory, "id", null)) {
				cat = { ...cat, subCategories: newValue };
			}
			return cat;
		});

		if (newValue.length > 0) {
			setObject(prv => ({
				...prv,
				subCategoryId: newValue,
				categories: tmpCategoryPayload
			}));
		} else {
			setObject(prv => ({
				...prv,
				subCategoryId: [],
				categories: tmpCategoryPayload
			}));
		}
	}

	// Thumnail Upload Method // 
	const thumnailFn = async ({
		videoURL,
		videoTitle,
		videoDescription,
		categoryName,
		videoId,
		pushNotificationType,
		shareWith,
		pushNotificationText,
		tags,
		videoThumbNailURL,
		categories
	}, flagIndex) => {

		//const splitURL = videoURL.split('.com/');
		if (flagIndex === 3) {
			const options = {
				maxFiles: 1,
				fromSources: ["local_file_system"],
				uploadInBackground: false,
				onOpen: () => console.log('opened'),
				onUploadDone: async (res) => {
					setObject(prv => ({
						...prv,
						thumbnailURL: res['filesUploaded'][0].url
					}));
					try {
						const { data } = await VideoThumnailAPI(videoId, res['filesUploaded'][0].url, APITOKEN);
						if (data[0].Success) {
							setThumnailResponse(prv => ({ ...prv, ...data[0] }))
						};
					} catch (err) {
						console.error(err);
					}
				},
			};
			await client.picker(options).open();
		} else if (flagIndex === 1) {
			setObject(prv => ({
				...prv,
				description: videoDescription,
				title: videoTitle,
				categoryId: [{ value: categoryName, label: categoryName }],
				categories, //Set default selected categories when edit clicked
				videoId,
				videoURL,
				searchTags: !tags ? [] : tags
			}));

			setEditThumbnailImageData({ videoThumbNailURL, videoTitle });
			setPushNoty(prv => ({ ...prv, value: pushNotificationType, notificationText: pushNotificationText, shareType: shareWith }));
			setPercentage("100.00%");
			setIsEditMode(true);
		} else if (flagIndex === 4) {
			setDelete(prv => ({
				...prv,
				flag: true,
				videoId
			}))
		} else {
			setVideoObject(prv => ({
				...prv,
				captionSliderDisplay: true,
				//videoId: splitURL[1]
				videoId: videoId
			}))
		}
	}
	const videoDeleteFn = () => {
		Client.delete(`Mobile/Videos?id=${deleteObj.videoId}`)
			.then(({ data }) => {
				setDelete(prv => ({
					...prv,
					flag: false
				}))
			})
			.catch(err => {
				throw err;
			});
	}
	const load = useCallback(async () => {
		if (APITOKEN['token'] !== "") {
			const { data } = await Languages(APITOKEN);
			try {
				if (data.length > 0) {
					SetLang(prv => ({
						...prv,
						data
					}));
				}
			} catch (err) {
				console.error(err);
			}
		}
		return (() => {
			SetLang({
				data: [],
				Speaker: []
			});
		});
	}, [APITOKEN]);
	const saveVideoFn = () => {
		setIsLoader(true);
		let catId = "";
		objectArray.subCategoryId.map(x => catId += `${x.id},`);
		const obj = {
			"speakerId": objectArray.speakerId,
			"categoryId": objectArray.categoryId,
			"languageCode": objectArray.languageCode,
			"videoId": objectArray.videoId,
			"videoTitle": objectArray.title,
			"videoDescription": objectArray.description,
			"videoURL": objectArray.videoURL,
			"videoThumbNailURL": objectArray.thumbnailURL,
			"pushNotificationType": parseInt(pushNotification.value),
			"pushNotificationText": pushNotification.notificationText,
			"shareType": pushNotification.shareType,
			"subCategoryIds": catId,
			"categories": _get(objectArray, "categories", [])
		};
		PostAPI(obj);
	}
	const PostAPI = obj => {

		Client.post('/Mobile/Videos', obj)
			.then(({ data }) => {
				setIsLoader(false);
				if (data.Success) {
					videoInputRef.current.value = "";
					toast.success("Viedo Upload successfully.", {
						position: "bottom-center",
						autoClose: 10000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
						theme: "colored",
					});
					restFn();
				}

			})
			.catch(err => {
				setIsLoader(false);
				throw err;
			})
	}

	const restFn = () => {
		setObject({
			title: "",
			description: "",
			categoryId: [],
			speakerId: 0,
			languageCode: "",
			videoURL: "",
			videoId: "",
			thumbnailURL: "",
			searchTags: [],
			subCategoryId: [],
			categories: [],
			videoThumbNailURL: ""
		});
		SetLang(prv => ({ ...prv, Speaker: [] }));
		SetCategoriesList([]);
		SetSubCatogry([]);
		setPercentage("0%");
		setIsShowPercentage(false);
		setPushNoty({ value: "", notificationText: "", shareType: "" });
		setEditThumbnailImageData(null);
		setIsEditMode(false);
	}
	/*const getCatgoryFn = async (event) => {
		const { value } = event.target;
		const { data } = await CatgoryList(APITOKEN, objectArray.languageCode, value);
		setObject(prv => ({
			...prv,
			categoryId: parseInt(value),
			subCategoryId: []
		}));

		SetSubCatogry(catFilter(data));
	}*/
	const getSubCatgoryFn = async (selected) => {

		if (_get(selected, "id", null) === null) {
			return false;
		}
		const value = _get(selected, "id", null);
		let subCategoryIds = [];
		const tmpCategoryPayload = _get(objectArray, "categories", []); // store temp Variable for existing selected subcategories
		setSelectedCategory(selected); // Set selected category for showing dropdown value

		// Fetch sub categories
		const { data } = await CatgoryList(APITOKEN, objectArray.languageCode, value);
		SetSubCatogry(catFilter(data));

		// Set category in final array
		const isAlreadyExist = (tmpCategoryPayload || []).some(cat => cat.id === value);

		if (!isAlreadyExist) {

			tmpCategoryPayload.push({ id: _get(selected, "id", null), value: _get(selected, "value", null), subCategories: [] });
		} else {

			(tmpCategoryPayload || []).forEach(cat => {

				if (_get(cat, "id", "") === value) { // If category already selected then set subcategories if selected any
					subCategoryIds = _get(cat, "subCategories", []);
				}
			});
		}

		setObject(prv => ({
			...prv,
			categoryId: parseInt(value),
			subCategoryId: subCategoryIds,
			categories: tmpCategoryPayload
		}));
		//setCategoryPayload();
	}
	useEffect(() => {
		load();
	}, [load])

	function Tag({ text, onRemove }) {
		return (
			<div className="tag mb-5">
				<span>{text}</span>
				<span className="remove" onClick={onRemove}>x</span>
			</div>
		);
	}

	return (
		<>

			{!!deleteObj.flag ? <div className="bx-boder-common white width100 inline-block">
				<div className="_h_header">
					<h3>Delete</h3>
				</div>
				<table cellPadding="0" cellSpacing="0" width="100%">
					<tbody>
						<tr>
							<td className="body_table_cell white">
								<div className="_nodata">
									<div id="nodatamessage">
										<strong>Are you sure you want to delete this video?</strong>
										<br></br>
									</div>
								</div>
								<div className="text-right margin-top50">
									<button type="button" onClick={() => setDelete(prv => ({
										...prv,
										flag: false
									}))} className="grid_btn_1 noborder nobg">Cancel</button>
									<button type="button" className="grid_btn_1 deleteBtn" onClick={videoDeleteFn}>Confirm</button>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div> :
				<>
					<div className="bx-boder-common white width100 inline-block">
						<div className="_h_header">
							<h3>Video</h3>
						</div>
						<div className="">
							<div style={{ maxWidth: '492px' }}>
								<div className="padding20">
									<div className="formmodel-bx">
										<label className="_l_able_cls">Select language</label>
										<select className="inputformmodel" value={objectArray.languageCode} onChange={e => onChangeFn(e.target.value)} disabled={(isEditMode === true) ? "disabled" : ""}>
											<option value="">Select</option>
											{lang.data.map((v, i) => <option value={v.LanguageCode} key={i}>{v.Value}</option>)}
										</select>
									</div>
									<div className="formmodel-bx">
										<label className="_l_able_cls">Select Speaker</label>
										<select className="inputformmodel" value={objectArray.speakerId} onChange={e => {
											setObject(prv => ({
												...prv,
												speakerId: parseInt(e.target.value)
											}));
											setVideoObject(prv => ({
												...prv,
												speakerSelected: e.target.value !== "" ? true : false
											}))
										}}
											disabled={(isEditMode === true) ? "disabled" : ""}
										>
											<option value="">Select</option>
											{lang.Speaker.map((v, i) => <option value={v.speakerId} key={i}>{v.speakerName}</option>)}
										</select>
									</div>
									<hr className="category-section" />
									<div className="formmodel-bx">
										<label className="_l_able_cls">Category</label>
										{/*<select className="inputformmodel" onChange={getCatgoryFn} value={objectArray.categoryId}>
                                            <option value="">Select</option>
                                            {categoriesList.map((item, index) => <option key={index} value={item.id}>{item.value}</option>)}
                                        </select>*/}
										<Select
											className="basic-multi-select"
											classNamePrefix="select"
											placeholder="Select"
											options={(categoriesList || [])}
											value={(selectedCategory || null)}
											getOptionLabel={(option) => (option.value)}
											getOptionValue={(option) => (option.id)}
											onChange={(selected) => getSubCatgoryFn(selected)}
										/>
									</div>
									<div className="formmodel-bx">
										<label className="_l_able_cls">Sub Category</label>
										<Select
											value={objectArray.subCategoryId}
											isMulti
											name="language"
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={onChange}
											getOptionLabel={(option) => (option.value)}
											getOptionValue={(option) => (option.id)}
											options={subCatgory}
										/>
									</div>

									{(!_isEmpty(_get(objectArray, "categories", []))) && (

										_get(objectArray, "categories", []).map((cat, i) => {

											const subCat = (!_isEmpty(_get(cat, "subCategories", []))) ? `: ${_get(cat, "subCategories", []).map(subCat => subCat.value).join(', ')}` : "";
											return (
												<Fragment key={i}>
													{(i === 0) && (
														<div className="mb-5">
															<span className="link cursor-pointer" onClick={() => {
																setObject(prv => ({
																	...prv,
																	categoryId: [],
																	subCategoryId: []
																}));
																setSelectedCategory(null);
															}}
															>
																+ Add Category
															</span>
														</div>
													)}
													<Tag key={i} text={`${_get(cat, "value", "")}${subCat}`} onRemove={() => {
														let tmpSelectedCategories = _get(objectArray, "categories", []);

														tmpSelectedCategories.splice(i, 1); // If Click on remove, then remove whole category from array

														/*tmpSelectedCategories.forEach((item, index) => {
															item.id = index + 1; 
														});*/

														setObject(prv => ({
															...prv,
															categories: tmpSelectedCategories,
															subCategoryId: []
														}));
														setSelectedCategory(null);
													}} />
												</Fragment>
											)
										})
									)}
									<hr className="category-section" />

									<div className="formmodel-bx">
										<label className="_l_able_cls">Video Title</label>
										<input type="text" className="inputformmodel" value={objectArray.title} placeholder="Title"
											onChange={e => setObject(prv => ({
												...prv,
												title: e.target.value
											}))}
										/>
									</div>
									<div className="formmodel-bx">
										<label className="_l_able_cls">Video Description</label>
										<textarea type="text" className="inputformmodel textareaModel" value={objectArray.description}
											onChange={e => setObject(prv => ({
												...prv,
												description: e.target.value
											}))}
											placeholder="Description"></textarea>

									</div>
									{/* Tag Component */}
									{/*<Tags Set={setObject} Get={objectArray} />*/}
									{/* End Tag Component */}
									<div className="formmodel-bx">
										<label className="_l_able_cls">Push Notification</label>
										<select className="inputformmodel" value={pushNotification.value} onChange={event => setPushNoty(prv => ({ ...prv, value: event.target.value, notificationText: "" }))}>
											<option value="">Select</option>
											<option value="1">With Push Notification</option>
											<option value="2">Without Push Notification</option>
										</select>
									</div>
									{pushNotification.value === "1" ?
										<div className="formmodel-bx">
											<label className="_l_able_cls">Push Notification Text</label>
											<textarea className="inputformmodel textareaModel" value={pushNotification.notificationText} placeholder="Push Notification Text" onChange={event => setPushNoty(prv => ({ ...prv, notificationText: event.target.value }))}></textarea>
										</div>
										: <></>}
									<div className="formmodel-bx">
										<label className="_l_able_cls">Share</label>
										<select className="inputformmodel" value={pushNotification.shareType} onChange={event => setPushNoty(prv => ({ ...prv, shareType: event.target.value }))}>
											<option value="">Select</option>
											<option value="Internal">Internal</option>
											<option value="External">External</option>
											<option value="Both">Both</option>
										</select>
									</div>
									<div className="formmodel-bx">
										<label className="_l_able_cls">Video Upload</label>
										<input type="file" onChange={handleChange} ref={videoInputRef} className="custom-file-input" />
										{/* <label className='upload' for="upload">Choose file</label> */}
									</div>
									{(((isShowPercentage || false) === true)) && (
										<Fragment>

											<div className="Video_progresss">
												<span style={{ width: percentage, height: '5px', position: 'absolute', borderRadius: '20px', background: '#0077FF' }}></span>
												<span onClick={() => setObject(prv => ({ ...prv, videoURL: "" }))}></span>
											</div>
											<div style={{ marginLeft: "40%", marginBottom: "10px" }}>
												<span>{percentage}</span>
											</div>
										</Fragment>
									)}
									{(!_isEmpty(editThumbnailImageData || null)) && (
										<Fragment>
											<img src={_get(editThumbnailImageData, "videoThumbNailURL", "")} alt={_get(editThumbnailImageData, "videoTitle", "")} title={_get(editThumbnailImageData, "videoTitle", "")} />
											<span
												className="link cursor-pointer"
												onClick={() => {
													setObject(prv => ({ ...prv, videoURL: "" }));
													setEditThumbnailImageData(null);
												}}

											>Remove</span>
										</Fragment>
									)}
									{/*percentage === "0%" && <p style={{ color: 'green' }}>Viedo Upload successfully.</p>*/}
									{thumnailResponse.Success && <div className="success">Thumbnail Uploaded</div>}

								</div>

							</div>
							<div className="text-right padding20 ">
								<button type="button" className="grid_btn_1 nobg noborder" onClick={restFn}>Cancel</button>
								<button typeof="button" className="grid_btn_1 grid_btn_active" disabled={
									objectArray.title !== ""
										&& objectArray.speakerId !== 0
										//&& objectArray.categoryId !== ""
										&& !_isEmpty(objectArray.categories)
										&& objectArray.videoURL !== "" &&
										percentage === "100.00%" &&
										pushNotification.shareType !== "" &&
										pushNotification.value !== "" ? pushNotification.value === "1" ? pushNotification.notificationText !== "" ? false : true : false : false
											&& objectArray.languageCode !== "" ? false : true
								} type="button" onClick={saveVideoFn}>
									<ButtonLoader IsLoader={isLoader}>Save</ButtonLoader>
								</button>
							</div>

							{videoObject.langSelected && videoObject.speakerSelected ?
								<div className="b_top">
									<VideoGrid {...objectArray}
										APITOKEN={APITOKEN}
										setVideoObject={setVideoObject}
										videoObject={videoObject}
										onThumnailFn={thumnailFn} />
								</div>
								: <></>}
						</div>
					</div>
				</>
			}

			{
				videoObject.captionSliderDisplay &&
				<div className="videoCaptionHtml">
					<div className="videoCaptionContainer">
						<div className="topn-header">
							<span className="selected-title">Caption</span>
							<span style={{ padding: '20px', float: 'right' }} onClick={() => setVideoObject(prv => ({
								...prv,
								captionSliderDisplay: false
							}))}>
								<i className="fa fal fa-times clsbtnNoti fright pointer"></i>
							</span>
						</div>
						<div className="padding20">
							<VideoCaption APITOKEN={APITOKEN} Object={videoObject} Close={setVideoObject} />
						</div>
					</div>
				</div>
			}
		</>
	)
}

export default Video