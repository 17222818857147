import Highcharts from 'highcharts'
import HighchartsReact from "highcharts-react-official";
import { useState } from "react";
import { GENDER_COLOR } from '../../../constant/HightChartColor';

const GenderPie = (props) => {
    Highcharts.setOptions({
        colors: GENDER_COLOR
    });
    const [genderData] = useState({
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
        },
        title: {
            text: 'Gender',
            align: 'center'
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            },
            enabled: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false
                },
                showInLegend: true,
                pie: {
                    size: '100%',
                    dataLabels: {
                        enabled: false
                    },

                }
            }
        },
        series: [{
            type: 'pie',
            colorByPoint: true,
            innerSize: '50%',
            name: 'Gender',
            data: props.ChartData
        }]
    });
    return (
        <HighchartsReact highcharts={Highcharts} options={genderData} />

    )
}
export default GenderPie;