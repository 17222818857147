
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useParams } from 'react-router-dom';
import Client from '../../../../GlobalAPICall';
import Demographic from './Demographic';
import RecipientGrid from './RecipientGrid';
import SegmentGroup from './SegmentGroup';
import CompleteDetails from '../../../Email/Details/CompleteDetails';


const Recipients = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [recipientData, setRecipients] = useState([]);
    const [isDisplay, setDetailsDisplay] = useState({ flag: false, object: {} });
    const [totalRecipients, setTotalRecipiets] = useState(0);
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            // Email Insight  ---- Tabing Information 
            Client.get(`Insights/distribution/Email/${id}/Recipients/KPI?section=0`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    const Data = JSON.parse(data[0].KPI);
                    setTotalRecipiets(data[0].TotalRecipients);
                    setRecipients(Data);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }, [APITOKEN, id]);
    const showCompleteDetails = (v) => {
        setDetailsDisplay(prv => ({ ...prv, flag: true, object: v }));
    }
    return (
        <>
            <div className="bx-boder-common">
                <div className="_insight_header white">
                    <h3>Recipients</h3>
                </div>
                <div className="white summary_o_v">
                    <div className="recipient_count_wrapper inline-block">
                        {recipientData && recipientData.map((v, i) => <div className="-r-b" key={i}><h3>{v['Name']}</h3><h4> {v['CountLabel']}</h4><div className="_rec_total pointer completeDetails" onClick={e => (v['Count'] === 0 ? e.stopPropagation() : showCompleteDetails(v))}>{v['Count']}</div></div>)}
                    </div>
                    <div className="recipient_graph_wrapper inline-block">
                        <SegmentGroup />
                    </div>
                </div>
            </div>
            <div className="margin-top50 bx-boder-common white">
                <div className="_h_header">
                    <h3>Demographic Insight</h3>
                </div>
                <div className="demogprah repeat3 padding20">
                    <Demographic />
                </div>
            </div>
            <div className="overviewtable margin-top50 bx-boder-common white">
                <div className="_h_header">
                    <h3>Recipients</h3>
                </div>
                <div className="recipientGrid">
                    <RecipientGrid TotalRecipients={totalRecipients} APITOKEN={APITOKEN} />
                </div>
            </div>
            {isDisplay.flag ? <CompleteDetails ClosePopup={setDetailsDisplay} Value={isDisplay} TabId={tabId} /> : <></>}
        </>
    )
}
export default Recipients;