import Tabing from './Tabing';
import './index.css'
const Goal = () => {
    return (
        <> <div className=" ">
            <div className="dashbd_sec">
                &nbsp;
            </div>
        </div>
            <div className="dashbd_sec margin-top50">
                <Tabing data={[{ id: 0, name: 'Goals' }]} />
            </div>
        </>
    )
}

export default Goal;