import React, { useEffect, useState, useRef } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { ChromePicker } from 'react-color';

import { fetchCountries } from "../../../../services/store";

const ButtonFunctions = ({ sendDataToParent, setMobilePreviewData, onCancel }) => {
  const [formData, setFormData] = useState({
    isRatingVisible: false,
    ratingColor: '',
    isPhoneNumberVisible: false,
    phoneNumber: {
      countryCode: '',
      number: ''
    },
    isSinpeMovilPaymentPhoneNumberVisible: false,
    sinpeMovilPaymentPhoneNumber: {
      countryCode: '',
      number: ''
    },
    isEmailAddressVisible: false,
    emailAddress: '',
    isDirectionDisplayVisible: false,
    address: '',
    city: '',
    zip: '',
    state: '',
    municipal: '',
    isoCountryCode: "",
    country: '',
    isDisplayWebSiteVisible: false,
    webSite: ''
  });

  const [validationErrors, setValidationErrors] = useState({
    phoneNumber: false,
    sinpeMovilPaymentPhoneNumber: false,
    emailAddress: false,
    webSite: false,
    address: false,
    city: false,
    zip: false,
    state: false,
    country: false,
  });
  const [formValid, setFormValid] = useState(false);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [countries, setCountries] = useState([]);
  const colorPickerRef = useRef(null);

  // On Page Refresh > Fetch Countries
  useEffect(() => {
    (async () => {
      try {
        const response = await fetchCountries();
        setCountries(_get(response, "data", []));
      } catch (err) {
        console.log('Error occurred when fetching categories', err);
      }
    })();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handlePhoneInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: {
        ...prevData.phoneNumber,
        [name]: value
      }
    }));
  };

  const handleSnipeMovilPhoneInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      sinpeMovilPaymentPhoneNumber: {
        ...prevData.sinpeMovilPaymentPhoneNumber,
        [name]: value
      }
    }));
  };

  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      [name]: value === 'yes'
    };
    if (name === 'isPhoneNumberVisible' && value === 'no') {
      updatedFormData.phoneNumber = { countryCode: '', number: '' };
    }
    if (name === 'isSinpeMovilPaymentPhoneNumberVisible' && value === 'no') {
      updatedFormData.sinpeMovilPaymentPhoneNumber = { countryCode: '', number: '' };
    }
    if (name === 'isDirectionDisplayVisible' && value === 'no') {
      // Reset direction-related fields when direction display is set to No
      updatedFormData.address = '';
      updatedFormData.city = '';
      updatedFormData.zip = '';
      updatedFormData.state = '';
      updatedFormData.municipal = '';
      updatedFormData.country = '';
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        address: false,
        city: false,
        zip: false,
        state: false,
        country: false,
      }));
    }
    setFormData(updatedFormData);
  };

  const handleRatingColorChange = (color) => {
    setFormData((prevData) => ({
      ...prevData,
      ratingColor: color.hex
    }));
  };

  useEffect(() => {
    validateForm();
    setMobilePreviewData(formData, 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const validateForm = () => {

    let isValid = true;
    const errors = {};

    // Validate rating color if visible
    if (formData.isRatingVisible === true && !formData.ratingColor) {
      errors.ratingColor = 'Rating color is required.';
      isValid = false;
    }

    // Validate phone number fields if visible
    if (formData.isPhoneNumberVisible && (!formData.phoneNumber.countryCode || !formData.phoneNumber.number)) {
      errors.phoneNumber = true;
      isValid = false;
    }

    // Validate snipe movil phone number fields if visible
    if (formData.isSinpeMovilPaymentPhoneNumberVisible && (!formData.sinpeMovilPaymentPhoneNumber.countryCode || !formData.sinpeMovilPaymentPhoneNumber.number)) {
      errors.sinpeMovilPaymentPhoneNumber = true;
      isValid = false;
    }

    // Validate email address if visible
    if (formData.isEmailAddressVisible && !isValidEmail(formData.emailAddress)) {
      errors.emailAddress = true;
      isValid = false;
    }

    // Validate website URL if visible
    if (formData.isDisplayWebSiteVisible && !isValidUrl(formData.webSite)) {
      errors.webSite = true;
      isValid = false;
    }

    // Validate direction-related fields if display directions is Yes
    if (formData.isDirectionDisplayVisible && formData.address === '') {
      errors.address = true;
      isValid = false;
    }
    if (formData.isDirectionDisplayVisible && formData.city === '') {
      errors.city = true;
      isValid = false;
    }
    if (formData.isDirectionDisplayVisible && formData.zip === '') {
      errors.zip = true;
      isValid = false;
    }
    if (formData.isDirectionDisplayVisible && formData.state === '') {
      errors.state = true;
      isValid = false;
    }
    if (formData.isDirectionDisplayVisible && formData.country === '') {
      errors.country = true;
      isValid = false;
    }

    setValidationErrors(errors);
    setFormValid(isValid);
    return isValid;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {

      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setShowColorPicker(false);
      }
    };

    if (showColorPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showColorPicker]);

  const handleContinue = () => {
    if (formValid) {
      // Combine phone number parts into a single string
      const combinedPhoneNumber = (formData.phoneNumber.countryCode && formData.phoneNumber.number) ? `+${formData.phoneNumber.countryCode} ${formData.phoneNumber.number}` : "";
      const combinedSinpeMovilPaymentPhoneNumber = (formData.sinpeMovilPaymentPhoneNumber.countryCode && formData.sinpeMovilPaymentPhoneNumber.number) ? `+${formData.sinpeMovilPaymentPhoneNumber.countryCode} ${formData.sinpeMovilPaymentPhoneNumber.number}` : "";

      // Add additional processing or validation as needed before sending data
      const formValues = {
        ...formData,
        phoneNumber: combinedPhoneNumber,
        sinpeMovilPaymentPhoneNumber: combinedSinpeMovilPaymentPhoneNumber,
      };
      sendDataToParent(formValues, 3);
    }
  };

  const onCancelForm = () => {
    if (formValid) {
      // Combine phone number parts into a single string
      const combinedPhoneNumber = (formData.phoneNumber.countryCode && formData.phoneNumber.number) ? `+${formData.phoneNumber.countryCode} ${formData.phoneNumber.number}` : "";
      const combinedSinpeMovilPaymentPhoneNumber = (formData.sinpeMovilPaymentPhoneNumber.countryCode && formData.sinpeMovilPaymentPhoneNumber.number) ? `+${formData.sinpeMovilPaymentPhoneNumber.countryCode} ${formData.sinpeMovilPaymentPhoneNumber.number}` : "";

      // Add additional processing or validation as needed before sending data
      const formValues = {
        ...formData,
        phoneNumber: combinedPhoneNumber,
        sinpeMovilPaymentPhoneNumber: combinedSinpeMovilPaymentPhoneNumber,
      };
      onCancel(formValues, 3);
    }
  };

  const isValidEmail = (email) => {
    // Simple email validation using regex
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <>
      <div className="bx-boder-common width100 nobg mt-8">
        <div className="_h_header white border-radius2">
          <h3>Button functions</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            <div className="col-12">

              <div className="formmodel-bx">
                <label className="_l_able_cls">Display rating</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="yes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="yes"
                      name="isRatingVisible"
                      value="yes"
                      checked={formData.isRatingVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="no">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="no"
                      name="isRatingVisible"
                      value="no"
                      checked={!formData.isRatingVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isRatingVisible === true && (
                <div className="formmodel-bx ps-5">
                  <label className="_l_able_cls">Rating color</label>
                  <div className="position-relative">
                    <input
                      type="text"
                      className={`inputformmodel inline-block width100 ${validationErrors.ratingColor ? 'border-red' : ''}`}
                      value={formData.ratingColor}
                      readOnly
                      onClick={() => setShowColorPicker(!showColorPicker)}
                    />
                    {showColorPicker && (
                      <div className="color-picker-store-popup" ref={colorPickerRef}>
                        <ChromePicker
                          color={formData.ratingColor}
                          onChangeComplete={handleRatingColorChange}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="formmodel-bx">
                <label className="_l_able_cls mb-3">Display phone number button</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="phoneYes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="phoneYes"
                      name="isPhoneNumberVisible"
                      value="yes"
                      checked={formData.isPhoneNumberVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="phoneNo">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="phoneNo"
                      name="isPhoneNumberVisible"
                      value="no"
                      checked={!formData.isPhoneNumberVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isPhoneNumberVisible && (
                <div className="formmodel-bx ps-5">
                  <label className="_l_able_cls">Phone number <span className="error-message">*</span></label>
                  <div className="flex align-items-center gap-2">
                    <input
                      type="number"
                      className={`inputformmodel inline-block wauto min-width-inherit ${validationErrors.phoneNumber ? 'border-red' : ''}`}
                      name="countryCode"
                      value={formData.phoneNumber.countryCode}
                      onChange={(e) => handlePhoneInputChange(e)}
                      style={{ width: '52px' }}
                    />

                    <input
                      type="number"
                      className={`inputformmodel inline-block width100 ${validationErrors.phoneNumber ? 'border-red' : ''}`}
                      name="number"
                      value={formData.phoneNumber.number}
                      onChange={(e) => handlePhoneInputChange(e)}
                    />
                  </div>
                </div>
              )}

              <div className="formmodel-bx">
                <label className="_l_able_cls mb-3">Display snipe movil payment phone number button</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="phoneYes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="phoneYes"
                      name="isSinpeMovilPaymentPhoneNumberVisible"
                      value="yes"
                      checked={formData.isSinpeMovilPaymentPhoneNumberVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="phoneNo">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="phoneNo"
                      name="isSinpeMovilPaymentPhoneNumberVisible"
                      value="no"
                      checked={!formData.isSinpeMovilPaymentPhoneNumberVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isSinpeMovilPaymentPhoneNumberVisible && (
                <div className="formmodel-bx ps-5">
                  <label className="_l_able_cls">Phone number <span className="error-message">*</span></label>
                  <div className="flex align-items-center gap-2">
                    <input
                      type="number"
                      className={`inputformmodel inline-block wauto min-width-inherit ${validationErrors.sinpeMovilPaymentPhoneNumber ? 'border-red' : ''}`}
                      name="countryCode"
                      value={formData.sinpeMovilPaymentPhoneNumber.countryCode}
                      onChange={(e) => handleSnipeMovilPhoneInputChange(e)}
                      style={{ width: '52px' }}
                    />

                    <input
                      type="number"
                      className={`inputformmodel inline-block width100 ${validationErrors.sinpeMovilPaymentPhoneNumber ? 'border-red' : ''}`}
                      name="number"
                      value={formData.sinpeMovilPaymentPhoneNumber.number}
                      onChange={(e) => handleSnipeMovilPhoneInputChange(e)}
                    />
                  </div>
                </div>
              )}

              <div className="formmodel-bx">
                <label className="_l_able_cls mb-3">Display email button</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="emailYes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="emailYes"
                      name="isEmailAddressVisible"
                      value="yes"
                      checked={formData.isEmailAddressVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="emailNo">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="emailNo"
                      name="isEmailAddressVisible"
                      value="no"
                      checked={!formData.isEmailAddressVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isEmailAddressVisible && (
                <div className="formmodel-bx ps-5">
                  <label className="_l_able_cls">Email <span className="error-message">*</span></label>
                  <input
                    type="email"
                    className={`inputformmodel inline-block width100 ${validationErrors.emailAddress ? 'border-red' : ''}`}
                    name="emailAddress"
                    value={formData.emailAddress}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              )}

              <div className="formmodel-bx">
                <label className="_l_able_cls mb-3">Display directions button</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="directionsYes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="directionsYes"
                      name="isDirectionDisplayVisible"
                      value="yes"
                      checked={formData.isDirectionDisplayVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="directionsNo">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="directionsNo"
                      name="isDirectionDisplayVisible"
                      value="no"
                      checked={!formData.isDirectionDisplayVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isDirectionDisplayVisible && (
                <>
                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">Address <span className="error-message">*</span></label>
                    <input
                      type="text"
                      className={`inputformmodel inline-block width100 ${validationErrors.address && !formData.address ? 'border-red' : ''}`}
                      name="address"
                      value={formData.address}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">City <span className="error-message">*</span></label>
                    <input
                      type="text"
                      className={`inputformmodel inline-block width100 ${validationErrors.city && !formData.city ? 'border-red' : ''}`}
                      name="city"
                      value={formData.city}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">Zip <span className="error-message">*</span></label>
                    <input
                      type="text"
                      className={`inputformmodel inline-block width100 ${validationErrors.zip && !formData.zip ? 'border-red' : ''}`}
                      name="zip"
                      value={formData.zip}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">State <span className="error-message">*</span></label>
                    <input
                      type="text"
                      className={`inputformmodel inline-block width100 ${validationErrors.state && !formData.state ? 'border-red' : ''}`}
                      name="state"
                      value={formData.state}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">Municipal</label>
                    <input
                      type="text"
                      className="inputformmodel inline-block width100"
                      name="municipal"
                      value={formData.municipal}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </div>

                  <div className="formmodel-bx ps-5">
                    <label className="_l_able_cls">Country <span className="error-message">*</span></label>
                    <select
                      className={`inputformmodel width100 ${validationErrors.country && !formData.country ? 'border-red' : ''}`}
                      value={`${formData.isoCountryCode},${formData.country}`}
                      onChange={(e) => setFormData((prevData) => ({
                        ...prevData,
                        isoCountryCode: (_get(e, "target.value", "") !== "") ? (_get(e, "target.value", "")).split(",")[0] : "",
                        country: (_get(e, "target.value", "") !== "") ? (_get(e, "target.value", "")).split(",")[1] : ""
                      }))}
                    >
                      <option value="">Select Country</option>
                      {!_isEmpty(countries || []) && (countries || []).map((category, i) => (
                        <option key={i} value={`${_get(category, "isOcountrycode", "")},${_get(category, "countryname", "")}`}>{_get(category, "countryname", "")}</option>
                      ))}
                    </select>
                  </div>
                </>
              )}

              <div className="formmodel-bx">
                <label className="_l_able_cls mb-3">Display website button</label>
                <div className="flex align-items-center gap-5">
                  <label htmlFor="websiteYes">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="websiteYes"
                      name="isDisplayWebSiteVisible"
                      value="yes"
                      checked={formData.isDisplayWebSiteVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    Yes
                  </label>

                  <label htmlFor="websiteNo">
                    <input
                      type="radio"
                      className="inline-block me-2"
                      id="websiteNo"
                      name="isDisplayWebSiteVisible"
                      value="no"
                      checked={!formData.isDisplayWebSiteVisible}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    No
                  </label>
                </div>
              </div>

              {formData.isDisplayWebSiteVisible && (
                <div className="formmodel-bx ps-5">
                  <label className="_l_able_cls">Website <span className="error-message">*</span></label>
                  <input
                    type="text"
                    className={`inputformmodel inline-block width100 ${validationErrors.webSite ? 'border-red' : ''}`}
                    name="webSite"
                    value={formData.webSite}
                    onChange={(e) => handleInputChange(e)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="formmodel-bx text-right mt-12 mb-0">
            <button
              type="button"
              className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg"
              onClick={() => onCancelForm()}
            >
              Cancel
            </button>
            <button
              type="button"
              className={`btn btn-primary btn-gape-15 ${formValid ? '' : 'btn-disabled'}`}
              onClick={() => handleContinue({})}
            >
              Continue
            </button>
          </div>
        </div>
      </div >
    </>
  );
};

export default ButtonFunctions;
