import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Client from "../../../GlobalAPICall";
import Tabing from "./Tabing";
// import './index.css';
import { useParams } from "react-router-dom";
import { setSMSInsightDetails } from "../../../Redux/Action";
import BreadCrum from "../../../ReuseTemplate/BreadCrum";
const SurveyDetails = () => {
  const { APITOKEN } = useSelector((state) => state);
  const { id } = useParams();
  const [tabData, setTab] = useState([]);
  const [campaignValue, setCampaignInfor] = useState({});

  const dispach = useDispatch();

  useEffect(() => {
    APITOKEN["token"] !== "" &&
      // SMS Insight  ---- Tabing Information
      Client.get(`Insights/Categories/Survey?queueId=${id}`, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then((res) => {
        setTab(res["data"]);
      });
    // SMS Insight  ---- SMS sending  ---- Campaign Information
    APITOKEN["token"] !== "" &&
      Client.get(`Campaign/${id}?excludehtml=true`, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then((res) => {
        // Add data in redux //

        if (res["data"].Name) {
          setCampaignInfor(res["data"]);
          dispach(setSMSInsightDetails(res["data"]));
        }
      });
  }, [APITOKEN, id, dispach]);

  return (
    <>
      {" "}
      <div className="smsHeader nomargin">
        <div className="dashbd_sec drop-zone">
          <div className="left_h_p">
            <h1 className="smstitle _t_i word-wrap">{campaignValue.Name}</h1>
          </div>
          <div className="right_h_p">
            <div className="imgspan">
              <img
                alt={campaignValue.Name}
                src={campaignValue.PreviewImagePath}
                className="insight-details-camimg"
                border="0"
                style={{ position: "absolute", maxWidth: "270px" }}
              />
              <div className="text_platform" style={{ display: "none" }}>
                <label>
                  Hi [firstname], Here are this week's secret coupons. Based on
                  your membership with us you also get a -20% discount. Best,
                  Promoti
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="dashbd_sec margin-top50">&nbsp;</div> */}
      <div className="dashbd_sec margin-top50">
        <BreadCrum
          To="/insights/survey"
          ToText="Survey Insights"
          CurrentPage="Details"
        />
      </div>
      <div className="dashbd_sec margin-top50">
        <Tabing data={tabData} />
      </div>
    </>
  );
};
export default SurveyDetails;
