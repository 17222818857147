import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Client from "../GlobalAPICall";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";

const VF = styled.div`
   {
    padding: 40px 0 0 0;
  }
`;
const OTPVerification = styled.span`
  position: absolute;
  min-width: calc(100% - 0px);
  text-align: left;
  margin-left: 16px;
  line-height: 31px;
  color: green;
  ${({ valClass }) =>
    valClass &&
    `
        color: red;
  `}
`;

const VerifyAccount = ({ ...props }) => {
  const { data } = props;
  const filterVal = data[0];
  const dataParse = data.length > 0 && JSON.parse(filterVal.DefaultJsonInfo);
  const { APITOKEN } = useSelector((state) => state);
  const [country, setCountryData] = useState({ data: [], selectedCountry: [] });
  const [mobileNumber, setMobileNumber] = useState({
    value: "",
    disable: true,
  });
  const [verificationCodeValue, setVerificationNumber] = useState({
    disable: true,
    value: "",
  });
  const [apiMessage, setAPIMessage] = useState({ message: "", show: false });
  const [optMessage, setOTPAPIMessage] = useState({ message: "", show: true });
  const [isloader, setLoader] = useState(false);
  const [isloaderVerfication, setVerificationLoader] = useState(false);
  const [isVerifyloader, setVerifyLoader] = useState(false);
  let index = null;
  if (data.length > 0) {
    index = _.findIndex(props.tabData, function (o) {
      return o.MenuId === filterVal.MenuId;
    });
  }
  const countryLoad = useCallback(() => {
    APITOKEN["token"] !== "" &&
      Client.get(`Lookup/countries?viewAll=false`, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then((res) => {
        try {
          setCountryData({
            data: res.data,
            selectedCountry: res.data.filter(
              (x) => x["isOcountrycode"] === APITOKEN.config.countryCode
            ),
          });
        } catch (err) {
          console.log(err);
        }
      });
  }, [APITOKEN]);
  useEffect(() => {
    countryLoad();
  }, [countryLoad]);

  const onboardingSav = ({ isCompleted, stepId, isSkipped }) => {
    setLoader(true);
    const obj = {
      color: "",
      companyLogo: "",
      fontId: 0,
      isCompleted: isCompleted,
      isNewsLetterAccepted: false,
      isSkipped: isSkipped,
      language: "",
      locale: "",
      stepId: stepId,
      subDomains: "",
      timezone: "",
    };
    Client.post(
      `Lookup/OnBoardingSteps/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/SaveSteps`,
      obj,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    ).then((res) => {
      setLoader(false);
      try {
        props.Click(
          props.tabData[index + 1] === undefined
            ? props.tabData[0].MenuId
            : props.tabData[index + 1].MenuId
        );
      } catch (err) {
        console.log(err);
      }
    });
  };

  const verifyBtn = () => {
    setVerificationLoader(true);
    Client.post(
      `Lookup/OnBoardingSteps/${mobileNumber.value}/SendVerificationCode?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
      {},
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then((res) => {
        try {
          setVerificationLoader(false);
          if (res.data.success) {
            setAPIMessage({ ...apiMessage, ...{ message: "", show: true } });
            setMobileNumber({ ...mobileNumber, ...{ disable: true } });
          } else {
            setAPIMessage({
              ...apiMessage,
              ...{ message: res.data.message, show: false },
            });
          }
        } catch (err) {
          setVerificationLoader(false);
          console.log(err);
        }
      })
      .catch((err) => setVerificationLoader(false));
  };

  const mobileNumberFN = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length >= 7) {
      setMobileNumber({
        ...mobileNumber,
        ...{ disable: false, value: onlyNums },
      });
    } else {
      setMobileNumber({
        ...mobileNumber,
        ...{ value: onlyNums, disable: true },
      });
    }
  };

  const verificationCode = (e) => {
    const onlyNums = e.target.value.replace(/[^0-9]/g, "");
    if (onlyNums.length >= 4) {
      setVerificationNumber({
        ...verificationCodeValue,
        ...{ disable: false, value: onlyNums },
      });
    } else {
      setVerificationNumber({
        ...verificationCodeValue,
        ...{ value: onlyNums, disable: true },
      });
    }
  };

  const otpFn = () => {
    setVerifyLoader(true);
    Client.post(
      `Lookup/OnBoardingSteps/${verificationCodeValue.value}/${country.selectedCountry[0].countryCode}${mobileNumber.value}/${country.selectedCountry[0].countryCode}/${mobileNumber.value}/VerifyPhoneNumber`,
      {},
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(({ data }) => {
        setVerifyLoader(false);
        try {
          if (data[0].Success) {
            setVerificationNumber((prv) => ({ ...prv, disable: true }));
            setOTPAPIMessage({ message: data[0].Message, show: false });
          } else {
            setOTPAPIMessage({ message: data[0].Message, show: true });
            setVerificationNumber((prv) => ({ ...prv, disable: false }));
          }
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => setVerifyLoader(false));
  };
  return (
    <>
      {data.length > 0 && (
        <>
          <div>
            <h2>
              {filterVal.Name}
              <label
                style={{ paddingTop: "5px" }}
                className="fright vertical-middle sms_content"
              >
                Duration: {dataParse.Duration}
              </label>
            </h2>
            <p>{dataParse.HeaderDescription}</p>
          </div>
          {APITOKEN.config.isPhoneNumberVerified ? (
            <>
              <div id="nodatamessage" className="margin-top50">
                <strong>Your account is successfully verified.</strong>
                <br />
                If you need to verify a new mobile number please contact{" "}
                <span className="support-mail">
                  {APITOKEN.config &&
                    APITOKEN.config.subscriptionInfo.SupportUrl}
                </span>
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="margin-top50 mobileVerificationBox">
                <div className="formmodel-bx width120 sx-mobile inline-block margin-right15">
                  <label className="_l_able_cls">Country</label>
                  <select
                    className="inputformmodel width100"
                    onChange={(e) => e.stopPropagation()}
                    value={APITOKEN.config.countryCode}
                    style={{ backgroundPosition: "92%" }}
                  >
                    <option value="">Select</option>
                    {country.data.length > 0 &&
                      country.data.map((v, i) => (
                        <option key={i} disabled value={v.isOcountrycode}>
                          {v.countryname}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="formmodel-bx width186 inline-block sx-mobile">
                  <label className="_l_able_cls">Mobile Number</label>
                  <input
                    type="text"
                    className="inputformmodel width100"
                    value={mobileNumber.value}
                    onChange={mobileNumberFN}
                  />
                </div>
                <div className="__send_verify__bx text-right sx-mobile">
                  <button
                    className="grid_btn_1 grid_btn_blue"
                    onClick={verifyBtn}
                    disabled={mobileNumber.disable}
                    type="button"
                  >
                    <ButtonLoader IsLoader={isloaderVerfication}>
                      Send verification code
                    </ButtonLoader>
                  </button>
                  {!apiMessage.show ? (
                    <p
                      style={{
                        color: "red",
                        textAlign: "left",
                        lineHeight: "16px",
                        paddingTop: "11px",
                        width: "500px",
                      }}
                    >
                      {apiMessage.message}
                    </p>
                  ) : (
                    ""
                  )}
                </div>

                {apiMessage.show && apiMessage.show ? (
                  <div>
                    <VF>
                      <p>
                        <strong>
                          Did you not receive the verification code?
                        </strong>
                      </p>
                      <p>
                        We sent an SMS with the code to the following number{" "}
                        {APITOKEN.config.countryCod}{" "}
                        {mobileNumber.value !== ""
                          ? mobileNumber.value
                          : 48263827}{" "}
                        .
                      </p>
                      <p>
                        If this was the wrong number, please enter the correct
                        number and then press send verification code button
                        again.
                      </p>
                      <p>
                        Still not receiving the verification code? Contact us at{" "}
                        <a href="s" className="link inline-block supportlink">
                          {APITOKEN.config &&
                            APITOKEN.config.subscriptionInfo.SupportUrl}
                        </a>
                      </p>
                    </VF>
                    <VF className="formmodel-bx __send_verify__bx inline-block">
                      <label className="_l_able_cls">
                        Enter Verification Code
                      </label>
                      <input
                        type="text"
                        className="inputformmodel width100"
                        value={verificationCodeValue.value}
                        onChange={verificationCode}
                      />
                    </VF>
                    <div className="__send_verify__bx sx-mobile text-right drop-zone">
                      <button
                        className="grid_btn_1 grid_btn_blue"
                        disabled={verificationCodeValue.disable}
                        onClick={otpFn}
                        type="button"
                      >
                        <ButtonLoader IsLoader={isVerifyloader}>
                          {" "}
                          Verify code
                        </ButtonLoader>
                      </button>
                      {optMessage.message !== "" ? (
                        <OTPVerification valClass={optMessage.show}>
                          {optMessage.message}
                        </OTPVerification>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="formmodel-bx text-right margin-top50">
                <button
                  type="button"
                  className="nobg noborder grid_btn_1"
                  onClick={() =>
                    onboardingSav({
                      isCompleted: false,
                      isSkipped: true,
                      stepId: 13,
                    })
                  }
                >
                  Skip
                </button>
                <button
                  type="button"
                  className="grid_btn_1 grid_btn_active __Save_link"
                  id={filterVal.MenuId}
                  onClick={() =>
                    onboardingSav({
                      isCompleted: true,
                      isSkipped: false,
                      stepId: 13,
                    })
                  }
                  disabled={optMessage.show}
                >
                  <ButtonLoader IsLoader={isloader}>Done</ButtonLoader>
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default VerifyAccount;
