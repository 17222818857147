import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tabing from "../Tabing";
import Client from "../../GlobalAPICall";
import { useParams } from 'react-router-dom';
import '../index.css'

const Store = () => {
    const {APITOKEN} = useSelector(state => state);
    const [tabData, setTab] = useState([]);
    const { id  } = useParams();
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/${id}/datacategories`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });

    }, [APITOKEN, id]);
    useEffect(() => {
        load();
    }, [load])

    return (
        <> 
            <div className="dashbd_sec margin-top50">
                &nbsp;
            </div>
        
            <div className="dashbd_sec margin-top50">
                <Tabing data={tabData} Id={id} TabLoad={load}/>
            </div>
        </>
    )
}

export default Store;