import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import { useEffect, useState } from "react";
import _ from "lodash";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const SenderNameContainer = ({ ...props }) => {
    const { SetSenderNameProperties, SenderNameProp, SmsSenderData, Reload } = props;
    const { APITOKEN } = useSelector(state => state);
    const [senderData, setSenderData] = useState(SmsSenderData.map(item=> ({...item,disabled:false})))
    const [sInput, setSenderInput] = useState({
        id: null, senderText: ""
    });
    const [IsLoader, setIsLoader] = useState(false);

    const addSenderName = () => {
        SetSenderNameProperties(prv => ({ ...prv, NewSenderInput: true, apiMessage: "" }));
    }
    const editSender = (v) => {
        for (let s of senderData) {
            s.disabled = false;
        }
        const index = _.findIndex(senderData, function (x) { return x.id === v.id });
        senderData[index].disabled = true;
        setSenderData([...senderData]);
        SetSenderNameProperties(prv => ({ ...prv, editActive: true }));
    }
    const enterSendername = (ev, e) => {
        const index = _.findIndex(senderData, function (x) { return x.id === ev.id });
        senderData[index].senderText = e.target.value;
        setSenderData([...senderData]);
    }
    const saveSenderNameFn = () => {
        const obj = [sInput];
        setIsLoader(true);
        if (sInput.id === -1) {
            postAPICall(obj);
        } else {
            putAPICall(obj);
        }


    }
    const putAPICall = (obj) => {
        try {
            Client.put(`SMS/sendernames`, obj)
                .then(({ data }) => {
                    if (data.success) {
                        SetSenderNameProperties(prv => ({ ...prv, senderInputValue: "", flag: false }));
                    } else {
                        SetSenderNameProperties(prv => ({ ...prv, apiMessage: data.message }));
                    }
                    setIsLoader(false);
                }).catch(err => {
                    setIsLoader(false);
                    console.error(err);
                })
        } catch (err) {
            setIsLoader(false);
            console.log(err);
        }
    }
    const postAPICall = (obj) => {
        try {
            Client.post(`SMS/sendernames`, obj)
                .then(({ data }) => {
                    
                    if (data.success) {
                        SetSenderNameProperties(prv => ({
                            ...prv,
                            senderInputValue: "",
                            flag: false
                        }));
                        Reload(() => Math.random());
                    } else {
                        SetSenderNameProperties(prv => ({ ...prv, apiMessage: data.message }));
                    }
                    setIsLoader(false);
                }).catch(err => {
                    setIsLoader(false);
                    console.error(err);
                })
        } catch (err) {
            setIsLoader(false);
            console.log(err);
        }
    }

    const senderNameDelete = ev => {
        const { id, senderText } = ev;
        const _DeleteObject = [{ id, senderText }];
        Client.delete(`SMS/sendernames`, {
            data: _DeleteObject,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                SetSenderNameProperties(prv => ({
                    ...prv,
                    senderInputValue: "",
                    flag: false
                }));
                Reload(() => id);
            })
            .catch(err => console.error(err));
    }
    useEffect(() => {
        SetSenderNameProperties(prv => ({ ...prv, apiMessage: "" }));
    },[SetSenderNameProperties])

    return (
        <div className="sendTestMask">
            <div className="_sendTestRemove extraclass">
                <div className="topn-header noborder">
                    <span className="selected-title">Sender Name</span>
                    <span style={{
                        padding: '20px',
                        float: 'right'
                    }} onClick={() => SetSenderNameProperties(prv => ({ ...prv, flag: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="_p_b_x noborder" style={{ height: '78vh', overflow: 'auto' }}>
                    <ul className="_senderList">
                        {senderData.map((v, i) =>
                            v.id !== -1 &&
                            <li key={i}>
                                <input
                                    type="text"
                                    disabled={!v.disabled}
                                    className="inputformmodel disabled _senderread"
                                    value={v.senderText}
                                    onChange={e => {
                                        enterSendername(v, e)
                                        setSenderInput(prv => ({ ...prv, id: v.id, senderText: e.target.value }))
                                    }}
                                />
                                <button type="button" className="inline-block link noborder nobg pointer"
                                    style={{ padding: '0 5px', display: (v['id'] === 0) ? 'none' : 'inline-block' }}
                                    onClick={() => editSender(v)}>Edit</button>
                                <button className="inline-block link _deleteSender"
                                    style={{ padding: '0 5px', display: (v['id'] === 0) ? 'none' : 'inline-block' }}
                                    onClick={() => senderNameDelete(v)} >Delete</button>
                            </li>
                        )}
                        {SenderNameProp.NewSenderInput ?
                            <li>
                                <input type="text"
                                    placeholder="Create Sender New"
                                    className="inputformmodel _senderread"
                                    value={SenderNameProp.senderInputValue}
                                    onChange={e => {
                                        SetSenderNameProperties(prv => ({ ...prv, senderInputValue: e.target.value }));
                                        setSenderInput(prv => ({ ...prv, id: -1, senderText: e.target.value }))
                                    }}
                                />
                            </li>
                            : <></>}
                    </ul>
                    {!!SenderNameProp.apiMessage && SenderNameProp.apiMessage === "" ? <p className="validation">{SenderNameProp.apiMessage}</p> : <p className="validation">{SenderNameProp.apiMessage}</p>}

                </div>
                <div className="text-right padding20">
                    <button
                        type="button"
                        className="grid_btn_1 nobg vertical-middle margin-right15"
                        onClick={addSenderName}
                        disabled={SenderNameProp.NewSenderInput || SenderNameProp.editActive}
                    >
                        Add
                    </button>
                    <button type="button" disabled={
                        (SenderNameProp.senderInputValue !== "" || sInput.senderText !== "") ? false : true
                    } className="grid_btn_1 grid_btn_active vertical-middle" onClick={saveSenderNameFn}>
                        <ButtonLoader IsLoader={IsLoader}>
                            Save
                        </ButtonLoader>
                    </button>
                </div>
            </div>
        </div>

    )

}

export default SenderNameContainer
