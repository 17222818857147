import React, { useEffect, useReducer, useState } from 'react'
import { useCallback } from 'react'
import Client from '../../GlobalAPICall';
import SourceCountChart from './SourceCountChart';
import BlankData from '../../BlankData';
import AppInstalled from './AppInstalled';
import MobieDevice from './MobileDevice';
import MapChart from './MapChart'
function Reducer(state, action) {
  switch (action.type) {
    case 'APICOUNT':
      return { ...state, ...action.payload }
    case 'BESTDAY':
      return { ...state, ...action.payload }
    case 'BESTIME':
      return { ...state, ...action.payload }
    case 'INSTALLED':
      return { ...state, ...action.payload }
    default:
      return state
  }
}


export default function APP({ Date, APITOKEN }) {
  // const [dataChart, setDataChart] = useState([]);
  const [state, dispatch] = useReducer(Reducer, {
    ApiCount: [],
    BestDay: [],
    Besttime: [],
    Installed: [],
    Device: []
  });
  const load = useCallback(() => {
    APITOKEN.token &&
      Client.get(`Insights/Mobile/DateWiseSourceCount?sourceType=AppOpened&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
        headers: {
          'Authorization': `Bearer ` + APITOKEN['token']
        }
      })
        .then(({ data }) => {
          try {
            dispatch({
              type: 'APICOUNT',
              payload: { ApiCount: data }
            });
          } catch (err) {
            console.error(err);
          }
        }).catch(err => console.error(err))
  }, [APITOKEN, Date]);
  const [mapData,setMapdata]= useState([])


  const loadAppInstalled = useCallback(() => {
    APITOKEN.token &&
      Client.get(`Insights/Mobile/DateWiseSourceCount?sourceType=AppInstalleds&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
        headers: {
          'Authorization': `Bearer ` + APITOKEN['token']
        }
      })
        .then(({ data }) => {
          try {
            dispatch({
              type: 'INSTALLED',
              payload: { Installed: data }
            });
          } catch (err) {
            console.error(err);
          }
        }).catch(err => console.error(err))
  }, [APITOKEN, Date]);

  const MapApiCall = useCallback(async () => {
    try {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/Mobile/MobileCountryWiseCount?sourceType=AppInstalleds`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                setMapdata(data);
            });
    } catch (err) {
        console.log(err);
    } finally {
        //console.log(false); // <-- set loading false when done no matter what
    }
}, [APITOKEN]); // <-- add any missing dependencies react warns about


  useEffect(() => {
    load();
    // loadBestDay();
    // loadTime();
    loadAppInstalled();
    MapApiCall()
  }, [load,  loadAppInstalled,MapApiCall])
  return (
    <div>
      <AppInstalled Data={state.Installed} Title="App Installed" />
      {state.ApiCount.length > 0 && <SourceCountChart Data={state.ApiCount} Title="App Open" />}

      <div className="overviewDaychart margin-top50 bx-boder-common white">
        <div className="chartharf  padding-right50" style={{ width: "51%" }}>
          <MobieDevice Date={Date} Title="AppInstalleds" MainHeading="Installed" />
        </div>
        <div className="chartharf  padding-right50" style={{ width: "49%" }}>
          <MobieDevice Date={Date} Title="AppOpened"  MainHeading="Opens"/>
        </div>
      </div>
      <div className="overviewtable margin-top50 bx-boder-common white">
        <div className="_h_header"><h3>App installed in country</h3></div><MapChart APITOKEN={APITOKEN} Mapdata={mapData} />
      </div>
      <Grid APITOKEN={APITOKEN} Date={Date} Mapdata={mapData}/>
      
    </div>
  )
}

const Grid = ({Mapdata }) => {
  // const [gridData, SetGridData] = useState([]);

  // const Griload = useCallback(() => {
  //   APITOKEN.token &&
  //     Client.get(`Insights/Mobile/MobileSourceDetailData?sourceType=AppInstalleds&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
  //       headers: {
  //         'Authorization': `Bearer ` + APITOKEN['token']
  //       }
  //     })
  //       .then(({ data }) => {
  //         try {
  //           debugger
  //           SetGridData(data);
  //         } catch (err) {
  //           console.error(err);
  //         }
  //       }).catch(err => console.error(err))
  // }, [APITOKEN, Date]);

  // useEffect(() => {
  //   Griload();
  // }, [Griload])
  return (
    <>
    <div className="-cam-sen-grid white drop-zone margin-top50 bx-boder-common">
      <div className="_h_header">
        <h3>App Installed</h3>
      </div>
      <div className="">
        <table
          cellPadding="0"
          cellSpacing=""
          className="smstable table-layout-default white">
          {Mapdata.length === 0 ? ''
            : <thead>
              <tr>
                <th className="table_cell_header">Country</th>
                <th className="table_cell_header">Count</th>
              </tr>
            </thead>
          }

          <tbody>
            <>
              {Mapdata.length === 0 ?
                <BlankData title="The app has not been opened or installed."
                  colSpan="8"
                  content="After opening or installed the app, you will find the insights here." /> :
                  Mapdata.map((v, i) => <tr key={i}>
                  <td className="body_table_cell">{v['country']}</td>
                  <td className="body_table_cell">{v['totalCount']}</td>
                </tr>)}
            </>
          </tbody>
        </table>
      </div>
      </div>
     
    </>
  )
}