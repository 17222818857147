import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import WedgetGrid from "./WedgetGrid";

const Engagment = ({ tabId }) => {
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [wedgetData, setWedgetGridData] = useState([]);
    const { engamentPostObject } = { fromDate: "", locale: "en-IN", toDate: "" };




    const filterWefet = res => {
        for (const v of res) {
            if (!!v['KPICoupon']) {
                v['CouponFlag'] = true;
                v['KPICoupon'].forEach(function (k, _$i) {
                    res.push({
                        WidgetName: `${v['WidgetName']}`,
                        WidgetPreviewImage: k['CouponImagePath'],
                        KPI: k['KPI'],
                        IsWidgetDeleted: v['IsWidgetDeleted'],
                        WidgetId: v['WidgetId'],
                        CouponId: k['CouponId'],
                        WidgetElementValue: k['CouponTagName'],
                    });
                });
            }
        };
        const filterResultArrayObject = res.filter(x => !x.CouponFlag);
        setWedgetGridData(filterResultArrayObject);
    }
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                await Client.get(`Insights/distribution/SMS/${id}/KPI?tabId=${tabId}&widgetstats=true`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    try {
                        filterWefet(data.KPI);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id, tabId]);


    const loadApiEngagementCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                await Client.post(`Insights/Survey/00000000-0000-0000-0000-000000000000/Engagement?queueId=${id}`, { engamentPostObject }, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    try {
                        filterObject(data);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id, engamentPostObject]);
    const filterObject = (res) => {
        const filter = [];
        for (const x of res) {
            const getChart = Object.keys(x.charts);
            filter.push({
                WidgetName: x['questionText'],
                WidgetId: x['questionId'],
                WidgetElementValue: x['questionType'],
                questionFlag: true,  // ** this flag for print question section ** //
                TotalResponses: x['totalResponses'],
                UniqueResponses: x['uniqueResponses'],
                MatrixResponse: x['matrixResponse'],
                Charts: x['charts'],
                SelectedChart: x['chartType'] === "" ? getChart[0] : x['chartType'],
                OpentextResponse: x['opentextResponse']
            });
        }

        setWedgetGridData(prv => ([...prv, ...filter]))
    }

    useEffect(() => {
        loadApiCall();
        loadApiEngagementCall();
    }, [loadApiCall, loadApiEngagementCall])
    return (
        <>
            <div className="overviewtable bx-boder-common white"><div className="_h_header" style={{ justifyContent: "space-between" }}><h3>Widget Clicks</h3><span className="fright font-16 goal_duration" style={{ display: "none" }}>Goal Duration:&nbsp;<span className="goalMeasureEndDate"></span></span></div>
                <div className="widgetContainer">
                    {/* {JSON.stringify(wedgetData)} */}
                    {
                        wedgetData.length > 0 && <WedgetGrid
                            Data={wedgetData}
                            tabId={tabId}
                            setWedgetGridData={setWedgetGridData}
                        />
                    }</div></div>
        </>
    )
}

export default Engagment
