import { useSelector } from "react-redux";
import { Export_Client } from "../../../../GlobalAPICall";
import { useNavigate, useParams } from "react-router";
import { useState } from "react";
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";

const ExportTab = ({ postObject }) => {
    const { APITOKEN, InsightSMSDetails } = useSelector(state => state);
    const { id } = useParams();
    const [exportMenuObject, setExportMennu] = useState({});
    const [loader, setLoader] = useState(false);
    const [loaderResponse, setLoaderResponse] = useState(false);
    

    const navigate = useNavigate();
    const exportFN = (f) => {
        if (f) {
            setLoaderResponse(true);
        } else {
            setLoader(true);
        }
        const obj = {
            name: InsightSMSDetails.Name,
            jsonData: JSON.stringify({ SurveyId: id, IsUniqueResponseRequired: f, filter: postObject, SurveyStatus: exportMenuObject.value })
        }
        try {
            Export_Client.post(`export/Survey`, obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                setLoader(false);
                setLoaderResponse(false);
                try {
                    if (data.success) {
                        navigate('/download');
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            setLoader(false);
            setLoaderResponse(false);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }
    const exportMenu = value => {
        setExportMennu(prv => ({
            ...prv,
            value
        }))
    }


    return (
        <>
            <div className="bx-boder-common white">
                <div className="_h_header">
                    <h3>Export</h3>
                    <div style={{ flex: '1', position: 'relative' }} className="text-right">
                        <select className="inputformmodel exportListing" onChange={e => exportMenu(e.target.value)}>
                            {/* <option value="">Select</option> */}
                            <option value="All">All</option>
                            <option value="Ongoing">Ongoing</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>
                </div>
                <div className="padding20">
                    <div className="_nodata margin-bottom nopadding">
                        <div id="nodatamessage"><strong>Prepare your insights for download.</strong><br /><br />If you want to get all your insights regarding this sending downloadable in excel format click on the Export button. Your exports will be stored inside your top right corner menu named Downloads.</div>
                    </div>
                    <div className="text-right">
                        <button type="button" className="grid_btn_1 grid_btn_active  _exportbtn margin-right15" onClick={() => exportFN(true)}>
                        <ButtonLoader IsLoader={loaderResponse}>Export Unique Responses</ButtonLoader>
                            
                        </button>
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => exportFN(false)}>
                            <ButtonLoader IsLoader={loader}>Export</ButtonLoader>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ExportTab
