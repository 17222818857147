
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import ColleagueSlider from "./ColleagueSlider";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import BalanceCheckFn from "../../ReuseTemplate/BalanceCheckFn";
import jsPDF from 'jspdf';

function DomainAuthenticate() {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [dataAPI, setAPIData] = useState({
        data: []
    });
    const reportTemplateRef = useRef(null);

    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const navigate = useNavigate();
    const [colleagueIsDisplay, setColleagueDisplay] = useState(false);
    const [responseVerfiy, setAPIVerfiy] = useState({});
    const [makeDefault, setMaketDefault] = useState({
        validation: false
    });
    const [IsLoader, setIsLoader] = useState(false);

    const setLoaded = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Domain/${id}/Details`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(async (res) => {
                try {
                    setAPIData({
                        data: res.data
                    });
                } catch (err) {
                    console.error(err);
                }

            }).catch(err => console.error(err));

    }, [APITOKEN, id]);
    const verfiyDomain = (ev) => {
        Client.put(`Domain/WhiteLabel/verify/Domain/DNSAndCertificate?DomainID=${id}&validateFor=${ev.validateFor}`, {}, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        }).then(async ({ data }) => {
            try {
                if (!data.success) {
                    if (data.statusCode === 402 && !data.balance) {
                        setAPIVerfiy(prv => ({
                            ...prv,
                            message: data.message
                        }));
                    } else if (data['statusCode'] === 402 && !!data['balance']) {
                        setAPIVerfiy(prv => ({
                            ...prv,
                            message: data.message
                        }));
                        setLowBalancePopup(prv => ({
                            ...prv,
                            ...data.balance
                        }))
                    } else {
                        setAPIVerfiy(prv => ({
                            ...prv,
                            message: 'Verification failed'
                        }));
                        setLoaded();


                    }
                } else {
                    setLoaded();
                    setAPIVerfiy(prv => ({
                        ...prv,
                        message: ''
                    }))
                }
            } catch (err) {
                console.error(err);
            }

        }).catch(err => console.error(err));
    }
    const copy = (id, index) => {
        const _id = `${id}${index}`
        var copyText = document.getElementById(_id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
    const makeDefaultAPI = () => {
        setIsLoader(true);
        Client.put(`Domain/${id}/MarkedAsDefault`, {}, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        }).then(async ({data}) => {
            try {
                setIsLoader(false);
                if (data[0].Success) {
                    navigate('/configuration');
                }
            } catch (err) {
                console.error(err);
            }

        }).catch(err => setIsLoader(false));
    }
    const colleagueFn = () => {
        setColleagueDisplay(true);
    }
    const handleGeneratePdf = () => {
        const doc = new jsPDF({
            format: [640,800],
            unit: 'pt',
            compressPdf:true
        });

        // Adding the fonts.
        doc.setFont('Arial', 'normal');
        doc.setCharSpace(2);

        doc.html(reportTemplateRef.current, {
            async callback(doc) {
                await doc.save('Domains_Configration');
            },
        });
    };
    useEffect(() => {
        setLoaded();
    }, [setLoaded]);
    return (
        <>
            <div className="dashbd_sec d_wrapper">
                <div className="sms_Sec_1 bx-boder-common width100  margin-bottom">
                    <div className="_h_header">
                        <h3>Domains</h3>
                    </div>
                    <table cellPadding="0" cellSpacing="smstable" className="smstable" ref={reportTemplateRef}>
                        <thead>
                            <tr>
                                <th className="table_cell_header">Type</th>
                                <th className="table_cell_header">Host</th>
                                {/* <th className="table_cell_header">&nbsp;</th> */}
                                <th className="table_cell_header">Value</th>
                                {/* <th className="table_cell_header">&nbsp;</th> */}
                                <th className="table_cell_header">Status</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        <tbody >
                            {dataAPI.data.map((v, i) => <tr key={i}>
                                <td className="body_table_cell">{v['Type']}</td>
                                <td className="body_table_cell">
                                    <textarea type="text" readOnly className="nobg noborder font-roboto" value={v['Host']} id={`_rawUrl${i}`} style={{
                                        outline: 'none',
                                        resize: 'none',
                                        color: 'rgba(26, 40, 61, 0.8)',
                                    }}>
                                        {v['Host']}
                                    </textarea>
                                    
                                </td>
                                {/* <td  className="body_table_cell">
                                    <button type="button" className="grid_btn_1 inline-block " style={{verticalAlign: 'top'}} onClick={() => copy('_rawUrl', i)}><strong>Copy</strong></button>
                                </td> */}
                                {/* <td className="body_table_cell">
                                    <textarea id={`_rawUrlVal${i}`}type="text" readOnly className="nobg noborder font-roboto" style={{ outline: 'none',
                                        resize: 'none',
                                        color: 'rgba(26, 40, 61, 0.8)',}} value={v['Value']}>{v['Value']}</textarea>
                                </td> */}
                                <td className="body_table_cell">{v['Value']}</td>
                                {/* <td  className="body_table_cell">
                                    <button type="button" className="grid_btn_1 inline-block " style={{verticalAlign: 'top'}} onClick={() => copy('_rawUrlVal', i)}><strong>Copy</strong></button>
                                </td> */}
                                <td className="body_table_cell"
                                    style={{
                                        color: (v['Status'] === 'Pending') ?
                                            '#afafaf' : '#3aac69'
                                    }}><strong>{(v['Status'])}</strong></td>
                                <td>
                                    <button type="button" className="link inline-block nopadding" onClick={() => verfiyDomain(v)}><strong>Verify</strong></button> &nbsp;
                                    <button type="button" className="link inline-block nopadding" onClick={() => copy('_rawUrl', i)}><strong>Copy</strong></button>
                                    <span
                                        style={{ paddingLeft: '10px', color: (v['Status'] === 'Verified') ? '#3bac69' : '#ed254e' }}>
                                        {(v['Status'] === 'Verified') ? '' : <span className="verifiy_error_message" style={{ paddingLeft: '10px', color: '#ed254e' }}>
                                            {responseVerfiy.message}</span>}</span>

                                </td>
                            </tr>)}
                        </tbody>
                    </table>

                    <div className="padding20 text-right b_top  inline-block width100">
                        <button className="grid_btn_1 vertical-middle fleft margin-right15" onClick={colleagueFn}>
                            Send to a Colleague
                        </button>
                        <button type="button" className="grid_btn_1 vertical-middle fleft " style={{display:"none"}} onClick={handleGeneratePdf}>Download</button>
                        <input type="checkbox" className="inline-block vertical-middle" onChange={e => setMaketDefault(prv => ({
                            ...prv,
                            validation: e.target.checked
                        }))} />
                        <span className="inline-block vertical-middle">&nbsp;Use as default domain</span>
                    </div>
                    <div className="inline-block width100  text-right padding20 __box__panel">
                        <button type="button" className="grid_btn_1 nobg noborder vertical-middle _cls_btn"
                            onClick={() => navigate('/configuration?_id=39')}>Cancel</button>
                        <button type="button" onClick={makeDefaultAPI} disabled={
                            makeDefault.validation ? false : true
                        } className="grid_btn_1 grid_btn_active">
                            <ButtonLoader IsLoader={IsLoader}>Save</ButtonLoader></button>
                    </div>
                    {responseVerfiy['message'] && <div className="apiErrorMessage paymentErrorMessage">
                        <>
                            <p>{responseVerfiy['message']}</p>
                            {/* <p className="text-right"><a className="grid_btn_1" style={{ color: '#000' }} href="/billing">UpGrade</a></p> */}
                        </>

                    </div>
                    }
                </div>
            </div>
            {colleagueIsDisplay ? <ColleagueSlider APITOKEN={APITOKEN} Close={setColleagueDisplay} /> : <></>}
            {lowBlance.display ? <BalanceCheckFn APITOKEN={APITOKEN} lowBlance={lowBlance} setLowBalancePopup={setLowBalancePopup} removeWaterMark={() => { }} /> : <></>}
        </>
    )

}

export default DomainAuthenticate;
