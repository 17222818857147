import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import EmailConversionGoal from './EmailConversionGoal-Template';
import EmailGoal from './EmailGoal-Template';
import LandingPageConversion from './LandingPageConversion-Template';
import LandingPagePerformance from './LandingPagePerformance-Template';
import SMSGoalTemplate from './SMSGoal-Template';
import SurveyConversion from './SurveyConversion-Template';
import SurveyPerformance from './SurveyPerformance-Template';

const Tabing = ({ data }) => {
    const defaultId = 25;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div>{tabId === defaultId ?
                    <SMSGoalTemplate tabId={tabId}/> : tabId === 26
                        ? <LandingPagePerformance tabId={tabId}/> : tabId === 27
                            ? <LandingPageConversion tabId={tabId}/>: tabId === 29
                                ? <EmailGoal  tabId={tabId}/> : tabId === 56
                                    ? <EmailConversionGoal tabId={tabId}/>: tabId === 67
                                        ? <SurveyPerformance/> : <SurveyConversion/>}
                </div>
            }
        </>
    )
}

export default Tabing;