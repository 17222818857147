import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import { useSelector } from 'react-redux';

import axios from 'axios';

const RecipientsTable = () => {
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [messageCount, setMessageCount] = useState({ listWiseRecipientsDetails: [] });
    const [isloader, setLoader] = useState(true);
    const [getAllEmailSendingDetails, setAllEmailSendingDetails] = useState({});
    const [spliceCount, setSpliceCount] = useState(10);
    let timerId = useRef(null)

    const messageCountLoad = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        clearTimeout(timerId.current);
        APITOKEN.token &&
            Client.get(`Email/Distribution/${id}/Preview/AggregatedMessageCount`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        if (data['isPreviewReadyToSend']) {
                            setMessageCount(data);
                        } else {
                            timerId.current = setTimeout(function () {
                                messageCountLoad();
                            }, 4000);
                        }
                    } catch (err) {
                        console.error(err);
                        messageCountLoad();
                    }

                }).catch((err) => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                    messageCountLoad();
                });
        return () => {
            clearTimeout(timerId.current);
            cancelToken.cancel();
        }
    }, [APITOKEN, id]);

    const moreRecipients = () => {
        setSpliceCount(spliceCount === 14000 ? 10 : 14000)
    }
    const loadApiCall = useCallback(() => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Email/Distribution/${id}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token'],
                        'Content-Type': 'application/json'
                    }
                }).then(async ({ data }) => {
                    try {
                        try {
                            setLoader(false);
                            setAllEmailSendingDetails(data);
                            if (!!data.recipientImportUrl) {
                                filterImportFile(data);
                            }
                            messageCountLoad();

                        } catch (err) {
                            console.error(err);
                        }
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
        }
    }, [APITOKEN, id, messageCountLoad]);
    const filterImportFile = data => {
        let split = data.recipientImportUrl.split("/");
        const fileName = split[split.length - 1].split('.');
        setAllEmailSendingDetails(prv => ({
            ...prv,
            recipientImportUrl: fileName[0]
        }))

    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            <div className="bx-pad">
                {isloader ? <>

                    {[1, 2, 3, 4].map((i) => <div key={i}><div className="skeleton-loader" style={{ height: '50px', marginBottom: '20px' }}></div><div className="skeleton-loader" style={{ height: '20px', marginBottom: '20px' }}></div></div>)}

                </> :
                    <table className="summary_table" cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td><strong>Recipients</strong></td>
                                <td>


                                    {messageCount.listWiseRecipientsDetails.length > 0 &&
                                        messageCount.listWiseRecipientsDetails.map((v, i) => {
                                            return <div key={i} className='formmodel-bx'>
                                                <div className="mainlist" ><p><span>List:&nbsp;</span>
                                                    <button type="button"
                                                        className="inline-block default-pointer noborder nobg">{v['listDisplayName']}</button></p>
                                                    <p>Segment included: {v['includedMessage']}</p><p className="segmentlist"></p>
                                                </div>

                                                {!v['excludedListNames'] ? v['incluedSegmentTextType'] === 0 &&
                                                    v['segmentsCountDetails'] !== undefined &&
                                                    v['segmentsCountDetails'].map((y, i) =>
                                                        <p key={i}>

                                                            <p style={{ display: y['isExcluded'] ? 'block' : 'none' }}>Excluded segment</p>
                                                        </p>
                                                    ) : v.excludedListNames.map((y, i) => <p key={i}><span>Excluded segment:&nbsp;</span> {y}</p>)}

                                            </div>
                                        })}
                                    {messageCount.manualRecipients && messageCount.manualRecipients.length > 0 &&
                                        <p className="manual_counts">
                                            Manually added:&nbsp;
                                            {messageCount.manualRecipients.filter((x, i) => i < spliceCount).map((v, i) =>
                                                <button key={i}
                                                    type="button"
                                                    className="inline-block nopadding">{(v['Recipient'] !== null) ? v['Recipient'] : ''}
                                                    {(i === messageCount.manualRecipients.length - 1 ? '' : `,`)}&nbsp;</button>)
                                            }
                                            {messageCount.manualRecipients && messageCount.manualRecipients.length > 10 && <button type="button" onClick={moreRecipients} className="toggle link">{spliceCount > 10 ? 'less' : 'more..'}</button>}
                                        </p>
                                    }
                                    {!!getAllEmailSendingDetails.recipientImportLink && <p>Import: <button type='button'>{getAllEmailSendingDetails.recipientImportUrl}</button></p>}

                                </td>
                            </tr>
                            {getAllEmailSendingDetails.CcEmails && <tr>
                                <td><strong>CC</strong></td>
                                <td>{getAllEmailSendingDetails.CcEmails.map((item, index) => <button key={index} type="button" className="nobg noborder  default-pointer pointer" >{item.email},</button>)}</td>
                            </tr>}

                            {getAllEmailSendingDetails.BCcEmails && <tr>
                                <td><strong>Bcc</strong></td>
                                <td>{getAllEmailSendingDetails.BCcEmails.map((item, index) => <button key={index} type="button" className="nobg noborder  default-pointer pointer" >{item.email},</button>)}</td>
                            </tr>}
                            <tr>
                                <td><strong>Total</strong></td>
                                <td><button type="button" className="nobg noborder  default-pointer pointer">{messageCount.totalRecipientCount}</button></td>
                            </tr>
                            <tr>
                                <td><strong>Sender Name	</strong></td>
                                <td><button type="button" className="nobg noborder default-pointer  pointer" >{getAllEmailSendingDetails.SenderFrom.displayName}</button></td>
                            </tr>
                            <tr>
                                <td><strong>Sender Email</strong></td>
                                <td><button type="button" className="nobg noborder default-pointer  pointer" >{getAllEmailSendingDetails.SenderFrom.email}</button></td>
                            </tr>
                            <tr>
                                <td><strong>Subject</strong></td>
                                <td><button type="button" className="nobg noborder  default-pointer pointer" >{getAllEmailSendingDetails.Content.subject}</button></td>
                            </tr>
                            <tr>
                                <td><strong>Reply To</strong></td>
                                <td><button type="button" className="nobg noborder default-pointer  pointer" style={{ 'display': 'flex', 'flexWrap': 'wrap' }} >{getAllEmailSendingDetails.ReplyTo_list.length === 0 ? getAllEmailSendingDetails.ReplyTo.email : getAllEmailSendingDetails.ReplyTo_list.map((x, i) => <span key={i}>{x.email},</span>)}</button></td>
                            </tr>
                            <tr>
                                <td><strong>Campaign Url</strong></td>
                                <td><a href={getAllEmailSendingDetails.Content.detail.PreviewUrl} rel="noreferrer" target='_blank' className="nobg noborder link  pointer" >{getAllEmailSendingDetails.Content.detail.Name}</a></td>
                            </tr>
                            <tr>
                                <td><strong>Pre Header</strong></td>
                                <td><button type="button" className="nobg noborder  default-pointer" >{getAllEmailSendingDetails.Content.preHeaderText}</button></td>
                            </tr>
                            {APITOKEN['config'].subscriptionInfo?.IsUTMVisibleOnSending ? <tr>
                                <td><strong>UTM</strong></td>
                                <td><button type="button" className="nobg noborder  default-pointer text-left" >
                                    <>
                                        {!!getAllEmailSendingDetails.utmParameters ?
                                            <>  {getAllEmailSendingDetails.utmParameters.utm_campaign} <br></br>
                                                {getAllEmailSendingDetails.utmParameters.utm_medium}<br ></br>
                                                {getAllEmailSendingDetails.utmParameters.utm_source}  <br></br >
                                                {getAllEmailSendingDetails.utmParameters.utm_content}    <br></br>
                                                {getAllEmailSendingDetails.utmParameters.utm_term}
                                            </> : 'N/A'
                                        }
                                    </>
                                </button></td>
                            </tr> : <></>}

                            <tr>
                                <td><strong>Attachment</strong></td>
                                <td>
                                    {getAllEmailSendingDetails.attachments && getAllEmailSendingDetails.attachments.map((v, i) => <span key={i}><span className="link" >{v.filename}</span><br></br></span>)}
                                </td>
                            </tr>
                            {/* <tr>
                                <td><strong>Email Option</strong></td>
                                <td><button type="button" className="nobg noborder link pointer">{getAllEmailSendingDetails.ScheduleDateTime === undefined ? 'Send Now' : `${getAllEmailSendingDetails.ScheduleDateTime.dateTimeFormattedAsString}  ${getAllEmailSendingDetails.ScheduleDateTime.offset}`}</button></td>
                            </tr> */}
                        </tbody>
                    </table>
                }

            </div>
        </>
    )

}
export default RecipientsTable;

