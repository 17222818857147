import { useCallback, useEffect, useState } from "react";
import Client from "../GlobalAPICall";
import MyContent from "./MyContent";
import { useNavigate } from "react-router";
import { encode as btoa } from 'base-64'
import GridLoader from "../ReuseTemplate/GridLoader";

const FolderListing = ({ ...props }) => {
    const [folderData, setFolderData] = useState([]);
    const { contentId, totalCount ,APITOKEN } = props;
    const navigate = useNavigate()
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/${contentId}/folders?detailViewReq=true`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                try {
                    setFolderData(data);
                } catch (err) {
                    console.log(err);
                }
            }).catch(err=> console.error(err));
    }, [APITOKEN, contentId])

    useEffect(() => {
        load()
    }, [load])
    return (
        <>

            {props.folderId.flag ? <MyContent {...props} totalCount={props.totalCount} /> :
                <>
                    <div className="defaultborder width100 nobg content__my">
                        <div className="_h_header white">
                            <h3>{contentId === '2' ? 'Landing Pages' : contentId === '5' ? 'Emails' : contentId === '4' ? 'Surveys': contentId === '97' ? 'Stores' : 'Workflows'}</h3>
                            <span className="flex-right">
                                <input type="text" className="inputformmodel" style={{ visibility: 'hidden' }} />
                            </span>
                        </div><div className=" width100 border-top-left-radius  nobg ">
                            <div className="_template_list">
                                {folderData.length === 0 ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                                <table cellPadding="0" cellSpacing="0" width="100%" className="smstable white">
                                    {folderData.length > 0 ?
                                        <thead>
                                            <tr><th className="table_cell_header">Name</th>
                                                <th className="table_cell_header">Description</th>
                                                <th className="table_cell_header">Type</th>
                                                <th className="table_cell_header">Creation</th>
                                                <th className="table_cell_header">Actions</th>
                                            </tr>
                                        </thead>
                                        : <></>}
                                    <tbody>
                                        {folderData.length > 0 && folderData.map((v, i) =>
                                            <tr key={i}>
                                                <td className="body_table_cell">{v['name']}</td>
                                                <td className="body_table_cell ">{v['description']}</td>
                                                <td className="body_table_cell">{v['access']}</td>
                                                <td className="body_table_cell">{v['creations']}</td>
                                                <td className="body_table_cell">
                                                    <button type="button" className="link inline-block -open noborder nobg pointer" onClick={() => { props.setFolderId({ id: v.id, flag: true }); props.setTabid({ ...totalCount, ...{ totalNumber: v.creations } }) }}>Open</button>&nbsp; | &nbsp;
                                                    <button type="button" className="link inline-block noborder nobg pointer" onClick={() => navigate(`/shareFolder/${v.id}?n=${btoa(v['name'])}`)} >Share</button>
                                                </td></tr>
                                        )}
                                    </tbody>
                                </table>
                            }
                            </div>
                        </div>
                    </div>
                </>
            }


        </>
    )

}

export default FolderListing
