import Tabing from "./Tabing";

const Folder = () => {

    return (<>
        <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle _t_i">Folder</h1>
                    <p className="sms_content" id="_sub_ti">Here you are able to create a Folder to collect all your content creations. This will make it easier for you to manage different projects. Folder Access is default for all accounts users, but you could also choose to create a private folder.</p>
                </div>
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <Tabing data={[{ id: 0, name: 'Folders' }]} />
        </div>
    </>)
}

export default Folder;