import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import UTMTemplate from './UTMTemplate';
import UTMCotent from './UTMContent';
import UTMCampaign from './UTMCampign'
import UTMMedium from './UTMMedium'
import UTMSource from './UTMSource';
import UTMTerm from './UTMTerm';
const Tabing = ({ data }) => {
    const defaultId = 50;
    const location = useLocation();
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link to={`${location.pathname}${location.search}`}  className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div>{tabId === defaultId ?
                    <UTMTemplate /> : tabId === 51
                        ? <UTMCampaign tabId={tabId}/> : tabId === 52
                            ? <UTMMedium tabId={tabId}/>: tabId === 53
                                ? <UTMSource  tabId={tabId}/> : tabId === 54
                                    ? <UTMCotent tabId={tabId}/>: <UTMTerm/> }
                </div>
            }
        </>
    )
}

export default Tabing;