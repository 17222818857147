import { useState } from "react";
import GridLoader from "../../../../ReuseTemplate/GridLoader";
import CompleteDetails from "../CompleteDetails";

const GridAggregatedContainer = ({ dataAggrated, onClick, tabId }) => {
    const [isDisplay, setDetailsDisplay] = useState({ flag: false, object: {} });
    const showCompleteDetails = childObject => {
        childObject['Id'] = childObject.id;
        childObject['Name'] = childObject.name;
        setDetailsDisplay(prv => ({ ...prv, flag: true, object: childObject }));
    }
    return (
        <>
            {dataAggrated.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5]} /> :
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                    <thead>
                        <tr>
                            <th className="table_cell_header">KPI</th>
                            <th className="table_cell_header">Description</th>
                            <th className="table_cell_header">Status</th>
                            <th className="table_cell_header">Status %</th>
                            <th className="table_cell_header">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <Grid dataAggrated={dataAggrated} onClick={onClick} tabId={tabId}
                            showCompleteDetails={showCompleteDetails}
                        />
                    </tbody>
                </table>
            }
            {isDisplay.flag ? <CompleteDetails
                ClosePopup={setDetailsDisplay}
                Value={isDisplay}
                TabId={tabId} /> : <></>}
        </>
    )
}

export default GridAggregatedContainer;

const Grid = ({ dataAggrated, onClick, tabId  ,showCompleteDetails }) => {
    return (
        <>
            {dataAggrated['KPI'] && dataAggrated['KPI'].map((v, i) =>
                <tr key={i}>
                    <td className="body_table_cell">{v['name']}</td>
                    <td className="body_table_cell">{v['description']}</td>
                    <td className="body_table_cell"
                        style={{ color: v['countColor'], paddingLeft: '10px' }}><strong style={{ display: (v['count'] === -1) ? 'none' : 'table-cell' }} className={v['count'] > 0 ? 'pointer completeDetails link' : ''} onClick={e => (v['count'] === 0)? e.stopPropagation() : showCompleteDetails(v)}>{(v['isConversionRevenue']) ? (v['formattedConversionRevenueCount'] === undefined) ? '' : v['formattedConversionRevenueCount'] : (v['count'] === undefined) ? '' : v['count']}</strong> <strong className="pointer completeDetails link" >{v['timeFormat']}</strong>
                    </td>
                    <td className="body_table_cell" style={{ color: v['rateColor'] }}><span style={{ display: (v['isConversionRevenue']) ? 'table-cell' : (v['rate'] === -1) ? 'none' : 'table-cell' }}>{(v['isConversionRevenue']) ? (v['rate'] === undefined) ? '' : v['rate'] + '%' : (v['rate'] === undefined) ? '' : v['rate'] + '%'}</span></td>
                    <td className="body_table_cell" style={{ display: 'none' }}>{(v['isConversionRevenue']) ? v['kpiBasedGoalQuantity'] : (v['kpiBasedGoalSetup']) ? v['kpiBasedGoalQuantity'] : 'N/A'}</td>
                    <td style={{ display: (tabId === 30) ? 'table-cell' : 'none' }} className="body_table_cell">{(v['isConversionRevenue']) ? v['formattedConversionRevenueGoal'] : v['kpiBasedGoalQuantity']}</td>
                    <td className="body_table_cell" style={{ display: 'none' }} >{(v['isConversionRevenue']) ? v['formattedConversionRevenueGoal'] : (v['kpiBasedGoalSetup']) ? (v['kpiBasedGoalRate'] === undefined) ? '' : v['kpiBasedGoalRate'] + '%' : 'N/A'}</td>
                    <td className="body_table_cell"><button type="button" className="link pinunpin" onClick={() => onClick(v)} >{(!v['IsPinned']) ? ' Pin' : 'Remove Pin'}</button></td>
                </tr>
            )}
        </>
    )
}
