
import _ from "lodash";
import { useCallback, useEffect } from "react";

const ConversionGoalGrid = ({ ...props }) => {
    const { goalObject, valueDisabled, setValidationChcked, checkValidation, setGoalObject } = props;
    const vailedFields = useCallback(() => {
        const goalrateNotBlank = goalObject.filter(x => (x['Goal Rate %'] !== "" ||
            x['Goal Quantity'] !== "" ||
            x['Goal Value'] !== ""
        )).map(v => v.Id);
        setValidationChcked(goalrateNotBlank);
    }, [goalObject, setValidationChcked]);

    const goalGetVal = (v, ev, flag) => {
        let e = ev.target.value;
        const index = _.findIndex(goalObject, function (o) { return o.WidgetId === v.WidgetId });
        const ind = _.findIndex(checkValidation, function (o) { return o === v.WidgetId });
        goalObject[index].quantiryDisabled = flag === 0 ? e.replace(/[^0-9]/g, '') !== '' ? true : false : false;
        goalObject[index].rateDisabled = flag === 1 ? e.replace(/[^0-9]/g, '') !== '' ? true : false : false;
        const filtercheckValidation = _.sortedUniq(checkValidation);

        if (flag === 0) {
            if (e.replace(/[^0-9]/g, '') !== '') {
                goalObject[index].quantiryDisabled = true;
            } else {
                goalObject[index].quantiryDisabled = false;
            }
            goalObject[index]['Goal Rate %'] = e.replace(/[^0-9]/g, '');

        }
        if (flag === 1) {
            if (e.replace(/[^0-9]/g, '') !== '') {
                goalObject[index].rateDisabled = true;
            } else {
                goalObject[index].rateDisabled = false;
            }
            goalObject[index]['Goal Quantity'] = e.replace(/[^0-9]/g, '');
        }
        if (flag === 2) {
            if (goalObject[index]['Goal Rate %'] !== "") {
                if (goalObject[index]['Goal Quantity'] !== "") {
                    goalObject[index].quantiryDisabled = true;
                    goalObject[index].rateDisabled = false;
                } else {
                    goalObject[index].rateDisabled = false;
                    goalObject[index].quantiryDisabled = true;
                }
            } else {
                if (goalObject[index]['Goal Quantity'] !== "") {
                    goalObject[index].quantiryDisabled = false;
                    goalObject[index].rateDisabled = true;
                }
            }
            goalObject[index]['Goal Value'] = e.replace(/[^0-9]/g, '');
        };
        setGoalObject(() => ([...goalObject]));

        if (ind === -1) {
            filtercheckValidation.push(goalObject[index].WidgetId);
            setValidationChcked(filtercheckValidation);
        } else {
            if (goalObject[index]['Goal Rate %'] === "" &&
                goalObject[index]['Goal Quantity'] === "" &&
                goalObject[index]['Goal Value'] === ""
            ) {
                setValidationChcked(filtercheckValidation.filter(x => x !== v.WidgetId));
            }
        }
    }
    useEffect(() => {
        vailedFields();
    }, [vailedFields])
    return (
        <>
            <div className="padding20">
                <p className="sms_content">Set Your Landing Page Call To Conversion Goals and optional value</p>
            </div>
            {goalObject.length > 0 ? goalObject.map((v, i) =>
                <table cellPadding="0" cellSpacing="0" className="smstable table-layout-default versiontab" key={i}>
                    <thead>
                        <tr>
                            <th className="" colSpan="2">{v.Name}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td ><img alt="" src={v['ImageUrl']} style={{ maxWidth: '250px' }} /></td>
                            <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
                                <div className="fontsize16 padb10">
                                    <span className="tx nopadding inline-block" style={{ float: 'none' }}>Conversion Goal in %</span>
                                    <div style={{ width: '200px' }} className="inline-block">
                                        <span className="pbx">&nbsp;</span>
                                        <span>{v['OperatorSymbol']}</span>
                                        <span className="pbx drop-zone">
                                            <input type="text"
                                                name="rate"
                                                value={v['Goal Rate %']}
                                                disabled={v.rateDisabled}
                                                onChange={e => goalGetVal(v, e, 0)}
                                                className="verinput inputformmodel percentage-padding-right" /><b className="percentage">%</b></span>
                                    </div>
                                </div>
                                <div className="fontsize16 padb10">
                                    <span className="tx nopadding inline-block"
                                        style={{ float: 'none' }}>{v['ConversionGoalQuantityLabel']} </span>
                                    <div style={{ width: '200px' }}
                                        className="inline-block">
                                        <span className="pbx">&nbsp;</span>
                                        <span>{v['OperatorSymbol']}</span>
                                        <span className="pbx">
                                            <input type="text"
                                                onChange={e => goalGetVal(v, e, 1)}
                                                name="Quantity"
                                                disabled={v.quantiryDisabled}
                                                value={v['Goal Quantity']}
                                                className="verinput inputformmodel" />
                                        </span>
                                    </div>
                                </div>
                                <div className="fontsize16 padb10">
                                    <span
                                        className="tx nopadding inline-block"
                                        style={{ float: 'none' }}>Conversion Value(Optional) </span>
                                    <div style={{ width: '200px' }}
                                        className="inline-block"><span>
                                            <input
                                                className="verinput inputformmodel"
                                                readOnly
                                                disabled
                                                value={v['ConversionGoalValueCurrencyText']}
                                                onChange={e => e.stopPropagation()}
                                            />
                                        </span><span className="pbx">
                                            <input type="text"
                                                className="verinput inputformmodel"
                                                name="value"
                                                disabled={valueDisabled}
                                                value={v['Goal Value']}
                                                onChange={e => goalGetVal(v, e, 2)}
                                            /></span>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>) : <div className="padding20"><div id="nodatamessage">
                    <strong>You have not created any goals yet.</strong><br /><br />
                    Create goals to easy meassure your campaigns and new contacts.
                </div></div>

            }</>
    )

}

export default ConversionGoalGrid
