import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import BlankData from "../../BlankData";
import { useNavigate } from "react-router";
import getLocaleDateString from "../../TimeFormat";
import moment from "moment";
import { Datepicker } from "@mobiscroll/react";
import GridLoader from "../../ReuseTemplate/GridLoader";
import _ from "lodash";
const SMSSending = () => {
    const { APITOKEN } = useSelector(state => state);
    const [dataSMSSending, setdataSMSSending] = useState([]);
    const [isDisplay, setDisplay] = useState(true);
    const [minDate, setMinDate] = useState('');
    const [totalRecode, setTotalRecord] = useState(0);
    const [skip, setSkip] = useState(0);
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const [obj, setAPIObject] = useState(prv => ({
        graterThanVal: !prv ? 0 : prv.graterThanVal,
        searchValue: !prv ? "" : prv.searchValue,
        startDate: !prv ? "" : prv.startDate,
        endDate: !prv ? "" : prv.endDate
    }));

    const crmSearch = e => {
        e.stopPropagation();
        setAPIObject(prv => ({ ...prv, searchValue: e.target.value }))
    }
    var debounced = _.debounce(crmSearch, 1000);

    const DateSelected = (e) => {
        const [startDate, endDate] = e.valueText.split(' - ');
        setAPIObject(prv => ({ ...prv, startDate, endDate }));
    }
    const loadApiCall = useCallback(async () => {
        setDisplay(true);
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/SMS/details?recipientsCountgreaterThan=${obj.graterThanVal}&search=${obj.searchValue}&startDate=${obj.startDate}&endDate=${obj.endDate}&skip=${skip}&limit=10`)
                    .then(({ data }) => {
                        try {
                            setdataSMSSending(data || []);
                            setDisplay(false);
                            const { TotalCount } = data.length === 0 ? 0 : data[0];
                            setTotalRecord(TotalCount);
                            if (((skip + 1) * 10) > TotalCount) {
                                setpainationButton(prv => ({ ...prv, next: true }));
                            } else {
                                if (data.length < 10) {
                                    if (skip === 0) {
                                        setpainationButton(prv => ({ ...prv, previous: true, next: true }));
                                    };

                                };
                                setpainationButton(prv => ({ ...prv, next: false }))
                            }
                        } catch (err) {
                            console.error(err);
                        }
                    });
        } catch (err) {
            console.log(err);
        }
        return (() => {
            setdataSMSSending([]);
        })

    }, [APITOKEN, obj, skip]); // <-- add any missing dependencies react warns about

    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }

    useEffect(() => {
        loadApiCall();
        setMinDate(() => (APITOKEN.config.hasOwnProperty('cultureCode')) && APITOKEN.config.currentTime.split('.')[0]);
    }, [loadApiCall, APITOKEN])

    return (
        <><div className="bx-boder-common">
            <div className="_insight_header white">
                <h3>SMS Sendings</h3>
            </div>
            <div className="bx-pad white">
                <div className="total_res inline-block">
                    <label className="inline-block">Total Recepients Greater Than</label>
                    <input
                        type="text"
                        className="inline-block wi_70px border-radius4 inputformmodel text-center"
                        value={obj.graterThanVal}
                        onChange={e => setAPIObject(prv => ({ ...prv, graterThanVal: e.target.value }))} />
                </div>
                <div className="date-range inline-block dateRange">
                    <label className="inline-block">Date Range</label>
                    <Datepicker className="inline-block border-radius4  inputformmodel"
                        select="range"
                        max={minDate}
                        onChange={e => DateSelected(e)}
                        dataTimezone='utc'
                        dateFormat="YYYY.MM.DD"
                        display="center"
                        theme="ios"
                        locale={APITOKEN.config.countryCode}
                        controls={['calendar']} />
                    <input
                        type="text"
                        className="inline-block border-radius4 inputformmodel fright"
                        placeholder="Name/Mobile Search"
                        onChange={e => debounced(e)} />

                </div>
            </div>
            <div className="-cam-sen-grid white drop-zone">
                <div className="b_top">
                    <SendingTable data={dataSMSSending} IsDisplay={isDisplay} />
                </div>
            </div>
            {dataSMSSending.length > 0 ?
                <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                    <span className="pagination_count vertical-middle">
                        <span>{skip + 1}</span>-
                        <span>{(skip + 1) * 10}</span> of&nbsp;
                        <span>{totalRecode}</span>
                    </span>
                    <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                    <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                </div>
                : <></>}
        </div></>
    )
}

export default SMSSending;

const SendingTable = ({ data, IsDisplay }) => {

    return (
        <>{
            IsDisplay ?
                <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
                <table cellPadding="0" cellSpacing="" className="smstable table-layout-default white">
                    {data && data.length === 0 ? ''
                        : <thead><tr><th className="table_cell_header">Name</th><th className="table_cell_header">Sent</th><th className="table_cell_header">Goals</th><th className="table_cell_header">Total Recepients</th><th className="table_cell_header">Delivered</th><th className="table_cell_header">Submitted</th><th className="table_cell_header">Failed</th><th className="table_cell_header">Clicks</th></tr></thead>
                    }
                    <tbody>
                        {data && data.length === 0 ? <BlankData title="You have not sent any SMS." colSpan="8" content="After you have sent an SMS you will find the insights here." /> : <Grid data={data} />}
                    </tbody>
                </table>}</>

    )
}
const Grid = ({ data }) => {
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    return (
        <>
            {data && data.map((v, i) => <tr key={i}><td className="body_table_cell"><button type="button" onClick={e => navigate(`/insights/smsDetails/${v['QueueId']}`)} className="link text-left detail_sms word-wrap">{v['CampaignName']}</button></td><td className="body_table_cell">{moment(v['SentDate']).utc().format(f)}</td><td className="body_table_cell">{v['HasGoal']}</td><td className="body_table_cell">
                {v['TotalReciepients']}</td><td className="body_table_cell"><strong>{v['DeliveredRate']}%</strong> ({v['Delivered']})</td><td className="body_table_cell"><strong>{v['SubmittedRate']}%</strong>({v['Submitted']})</td><td className="body_table_cell"><strong>{v['FailedRate']}%</strong> ({v['Failed']})</td><td className="body_table_cell"><strong>{v['OpenedRate']}%</strong>({v['Opened']})</td></tr>)}
        </>
    )
}