import { useState } from "react";
import CryptoJS from 'crypto-js'
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const ChangePassword = () => {
    const checkIsAuth = useSelector(state => state);
    const [responseData, setSuccessResponse] = useState({});
    const [loader, setLoader] = useState(false);
    const [passwordObject, setPasswordObject] = useState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    });
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const handleChange = (e) => {
        const newData = { ...passwordObject };
        newData[e.target.name] = e.target.value;
        setPasswordObject(newData);
        if (newData['currentPassword'] !== "" && newData['newPassword'] && newData['confirmPassword']) {
            setButtonDisabled(false);
        }
    }
    const encryptData = (msg) => {
        var keySize = 256;
        var salt = CryptoJS.lib.WordArray.random(16);
        var key = CryptoJS.PBKDF2('C4D8AA08-031D-EC11-84B8-85A8C07B8A39', salt, {
            keySize: keySize / 32,
            iterations: 100
        });

        var iv = CryptoJS.lib.WordArray.random(128 / 8);

        var encrypted = CryptoJS.AES.encrypt(msg, key, {
            iv: iv,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });

        var result = CryptoJS.enc.Base64.stringify(salt.concat(iv).concat(encrypted.ciphertext));

        return result;
    }
    const saveProfile = (e) => {
        const obj = {
            "currentPassword": encryptData(passwordObject.currentPassword),
            "newPassword": encryptData(passwordObject.newPassword),
            "confirmPassword": encryptData(passwordObject.confirmPassword),
        };
        setLoader(true);
        const headers = {
            'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token'],
            'Content-Type': 'application/json'
        };
        Client.put(`User/ChangePassword`, obj, { headers }).then((res) => {
            setLoader(false);
            try {
                setSuccessResponse(res['data']);
            } catch (err) {
                console.log(err);
            }
        });

    }

    return (
        <><div className="bx-boder-common white width100 inline-block">
            <div className="_h_header">
                <h3>Change Password </h3>
            </div>
            <div className="padding20">
                <form>
                    <div style={{ width: "492px" }}>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Current Password</label>
                            <input type="password" onChange={handleChange} className="inputformmodel" name="currentPassword" />
                        </div>

                        <div className="formmodel-bx">
                            <label className="_l_able_cls">New Password</label>
                            <input type="password" onChange={handleChange} className="inputformmodel" name="newPassword" />
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Confirm Password </label>
                            <input type="password" onChange={handleChange} className="inputformmodel" name="confirmPassword" />
                        </div>

                    </div>
                </form>
                {responseData.success ? <><p className="success">{responseData.message}</p></> : <></>}
            </div>
            <div className="padding20 nomargin text-right">
                <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" disabled={buttonDisabled} onClick={saveProfile}>
                    <ButtonLoader IsLoader={loader}>Save</ButtonLoader>
                </button>
            </div>
        </div></>
    )
}
export default ChangePassword;