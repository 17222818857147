import React, { createContext, useState } from 'react'
import SenderNameGrid from './SenderNameGrid';
import { useSelector } from 'react-redux';
import SMSTemplate from './SMSTemplate';
import BlankData from '../../../../BlankData';
import Client from '../../../../GlobalAPICall';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';
export const ConfigContext = createContext();
const LocalSender = () => {
    const { APITOKEN } = useSelector(state => state);
    const [isDisplay, setisDisplay] = useState({
        flag: true
    });
    const deleteTemplate = () => {
        if (isDisplay.rowUID === undefined) {
            Client.delete(`Trigger/Templates/SMSHeaderValues/${isDisplay.templateId}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.success) {
                            setisDisplay(prv => ({ ...prv, flag: true }))
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.log(err);
                })
        } else {
            Client.delete(`Trigger/Templates/SMSContentTemplate/${isDisplay.rowUID}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.success) {
                            setisDisplay(prv => ({ ...prv, flag: true }))
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.log(err);
                })
        }
    }
    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="Sender Name" />
            </div>
            {APITOKEN.token &&
                <ConfigContext.Provider value={APITOKEN}>
                    <div className="dashbd_sec">
                        {isDisplay.flag ?
                            <>
                                <SenderNameGrid SetisDisplay={setisDisplay} />
                                <SMSTemplate SetisDisplay={setisDisplay} />
                            </>
                            : <div className="sms_Sec_1 white bx-boder-common width100">
                                <div className="_h_header">
                                    <h3>Delete</h3>
                                </div><table cellPadding="0" cellSpacing="0" width="100%">
                                    <tbody>
                                        <BlankData title="Do you really want to delete the template?" colSpan="10" />
                                    </tbody>
                                </table>
                                <div className="text-right padding20">
                                    <button type="button" onClick={() => setisDisplay(prv => ({ ...prv, flag: true }))} className="grid_btn_1 nobg noborder _no_cls_delete_sms margin-right10">Cancel</button>
                                    <button type="button" className="grid_btn_1 deleteBtn" onClick={() => deleteTemplate()}>Delete</button>
                                </div></div>}
                    </div>

                </ConfigContext.Provider>
            }
        </>
    )

}

export default LocalSender
