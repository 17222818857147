import { useState } from "react";
import CompleteDetails from "../CompleteDetails";

const GridSurveyContainer = (props) => {
    const [isDisplay, setDetailsDisplay] = useState({ flag: false, object: {} });
    const showCompleteDetails = childObject => {
        childObject['Id'] = childObject.id;
        childObject['Name'] = childObject.name;
        setDetailsDisplay(prv => ({ ...prv, flag: true, object: childObject }));
    }
    return (
        <><table width="100%" cellPadding="0" className="smstable" cellSpacing="0"><thead><tr><th className="table_cell_header">KPI</th><th className="table_cell_header">Description</th><th className="table_cell_header">Status</th><th className="table_cell_header">Status %</th><th className="table_cell_header">Action</th></tr></thead><tbody><Grid {...props} showCompleteDetails={showCompleteDetails}  /></tbody></table>
            {isDisplay.flag ? <CompleteDetails
                ClosePopup={setDetailsDisplay}
                Value={isDisplay}
                TabId={props.tabId} /> : <></>}
        </>
    )
}
export default GridSurveyContainer;


const Grid = ({...props}) => {
   
    return (
        <>
            {props['Data']['kpi'] &&
                props['Data']['kpi'].map((v, i) =>
                    <tr key={i}>
                        <td className="body_table_cell">{v['name']}</td>
                        <td className="body_table_cell">{v['description']}</td>
                        <td className="body_table_cell"
                            style={{ color: v['countColor'], paddingLeft: "10px" }}>
                            <strong
                                style={{ display: (v['count'] === -1) ? 'none' : 'table-cell' }}
                                className={(v['count'] === 0) ? '' : 'pointer completeDetails link'}
                                onClick={e => (v['count'] === 0)? e.stopPropagation() : props.showCompleteDetails(v)}>
                                {(v['isConversionRevenue']) ? (v['formattedConversionRevenueCount'] === undefined) ? '' : v['formattedConversionRevenueCount'] : (v['count'] === undefined) ? '' : v['count']}</strong><strong className="pointer completeDetails link" onClick={() => props.showCompleteDetails(v)} >{v['timeFormat']}
                            </strong>
                        </td>
                        <td
                            className="body_table_cell"
                            style={{ color: v['rateColor'] }}>
                            <span
                                style={{ display: (v['isConversionRevenue']) ? 'table-cell' : (v['rate'] === -1) ? 'none' : 'table-cell' }}>
                                {(v['isConversionRevenue']) ? (v['rate'] === undefined) ? '' : v['rate'] + '%' : (v['rate'] === undefined) ? '' : v['rate'] + '%'}
                            </span>
                        </td>
                        <td className="body_table_cell" style={{ display: "none" }}>
                            {(v['isConversionRevenue']) ? v['kpiBasedGoalQuantity'] : (v['kpiBasedGoalSetup']) ? v['kpiBasedGoalQuantity'] : 'N/A'}
                        </td>
                        <td style={{ display: (props.tabId === 30) ? 'table-cell' : 'none' }} className="body_table_cell">
                            {(v['isConversionRevenue']) ? v['formattedConversionRevenueGoal'] : v['kpiBasedGoalQuantity']}
                        </td>
                        <td className="body_table_cell" style={{ display: "none" }}>
                            {(v['isConversionRevenue']) ? v['formattedConversionRevenueGoal'] : (v['kpiBasedGoalSetup']) ? (v['kpiBasedGoalRate'] === undefined) ? '' : v['kpiBasedGoalRate'] + '%' : 'N/A'}
                        </td>
                        <td className="body_table_cell" style={{ display: "none" }}><i style={{ color: v['kpiBasedGoalRateOrQuantityColor'], fontSize: "19px", display: (v['isGoalSetup']) ? (v['kpiBasedGoalSetup']) ? 'table-cell' : 'none' : 'none' }} className="fas fa-trophy"></i></td>
                        <td className="body_table_cell">
                            <button type="button" className="link pinunpin" onClick={e => props.onClick(v)} >{(!v['isPinned']) ? ' Pin' : 'Remove Pin'}</button>
                            </td>
                    </tr>
                )}
            

        </>
    )
}
