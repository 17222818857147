import { useCallback, useEffect, useState } from "react"
import Client from "../../../../GlobalAPICall";
import { useSelector } from "react-redux";

const Personalize = ({ ...props }) => {
    const { Onclick, Flag } = props;
    const { APITOKEN } = useSelector(state => state);
    const [data, setData] = useState([]);

    const load = useCallback(() => {
        Client.options(`Lookup/hashtags?Pageno=1&PageSize=200&hasTagsFor=email`, {
            data: [],
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    setData(res.data)
                } catch (err) {
                    console.error(err);
                }

            }).catch(err => console.error(err));
    }, [APITOKEN])
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className="ap__pref">
            <ul className="_prul">
                {data.map((v, i) => <li key={i}><button type="button" onClick={e => Onclick(e,v, Flag)} className="_hs_tag">{v.displayName}</button></li>)}
            </ul>
        </div>
    )

}

export default Personalize
