
const getLocaleDateString = (date) => {

    var formats = {
        "ar-SA": "DD/MM/YY HH:mm:ss",
        "bg-BG": "DD.M.YYYY HH:mm:ss",
        "ca-ES": "DD/MM/YYYY HH:mm:ss",
        "zh-TW": "YYYY/M/D HH:mm:ss",
        "cs-CZ": "D.M.YYYY HH:mm:ss",
        "da-DK": "DD-MM-YYYY HH:mm:ss",
        "de-DE": "DD.MM.YYYY HH:mm:ss",
        "el-GR": "D/M/YYYY HH:mm:ss",
        "en-US": "MM/DD/YYYY hh:mm:ss A",
        "fi-FI": "D.M.YYYY HH:mm:ss",
        "fr-FR": "DD/MM/YYYY HH:mm:ss",
        "he-IL": "DD/MM/YYYY HH:mm:ss",
        "hu-HU": "YYYY. MM. DD. HH:mm:ss",
        "is-IS": "D.M.YYYY HH:mm:ss",
        "it-IT": "DD/MM/YYYY HH:mm:ss",
        "ja-JP": "YYYY/MM/DD HH:mm:ss",
        "ko-KR": "YYYY-MM-DD HH:mm:ss",
        "nl-NL": "D-M-YYYY HH:mm:ss",
        "pl-PL": "YYYY-MM-DD HH:mm:ss",
        "pt-BR": "D/M/YYYY HH:mm:ss",
        "ro-RO": "DD.MM.YYYY HH:mm:ss",
        "ru-RU": "DD.MM.YYYY HH:mm:ss",
        "hr-HR": "D.M.YYYY HH:mm:ss",
        "sk-SK": "D. M. YYYY HH:mm:ss",
        "sq-AL": "YYYY-MM-DD hh:mm:ss A",
        "sv-SE": "YYYY-MM-DD HH:mm:ss",
        "th-TH": "D/M/YYYY HH:mm:ss",
        "tr-TR": "DD.MM.YYYY HH:mm:ss",
        "ur-PK": "DD/MM/YYYY hh:mm:ss A",
        "id-ID": "DD/MM/YYYY HH:mm:ss",
        "uk-UA": "DD.MM.YYYY HH:mm:ss",
        "be-BY": "DD.MM.YYYY HH:mm:ss",
        "sl-SI": "D.M.YYYY HH:mm:ss",
        "et-EE": "D.MM.YYYY HH:mm:ss",
        "lv-LV": "YYYY.MM.DD. HH:mm:ss",
        "lt-LT": "YYYY.MM.DD HH:mm:ss",
        "fa-IR": "MM/DD/YYYY HH:mm:ss",
        "vi-VN": "DD/MM/YYYY HH:mm:ss",
        "hy-AM": "DD.MM.YYYY HH:mm:ss",
        "az-Latn-AZ": "DD.MM.YYYY HH:mm:ss",
        "eu-ES": "YYYY/MM/DD HH:mm:ss",
        "mk-MK": "DD.MM.YYYY HH:mm:ss",
        "af-ZA": "YYYY/MM/DD HH:mm:ss",
        "ka-GE": "DD.MM.YYYY HH:mm:ss",
        "fo-FO": "DD-MM-YYYY HH:mm:ss",
        "ms-MY": "DD/MM/YYYY hh:mm:ss A",
        "kk-KZ": "DD.MM.YYYY HH:mm:ss",
        "ky-KG": "DD.MM.YY HH:mm:ss",
        "sw-KE": "M/D/YYYY HH:mm:ss",
        "uz-Latn-UZ": "DD/MM YYYY HH:mm:ss",
        "tt-RU": "DD.MM.YYYY HH:mm:ss",
        "pa-IN": "DD-MM-YY HH:mm:ss",
        "gu-IN": "DD-MM-YY HH:mm:ss",
        "ta-IN": "DD-MM-YYYY HH:mm:ss",
        "te-IN": "DD-MM-YY HH:mm:ss",
        "kn-IN": "DD-MM-YY HH:mm:ss",
        "mr-IN": "DD-MM-YYYY HH:mm:ss",
        "sa-IN": "DD-MM-YYYY HH:mm:ss",
        "mn-MN": "YY.MM.DD HH:mm:ss",
        "gl-ES": "DD/MM/YY HH:mm:ss",
        "kok-IN": "DD-MM-YYYY HH:mm:ss",
        "syr-SY": "DD/MM/YYYY HH:mm:ss",
        "dv-MV": "DD/MM/YY HH:mm:ss",
        "ar-IQ": "DD/MM/YYYY HH:mm:ss",
        "zh-CN": "YYYY/M/D HH:mm:ss",
        "de-CH": "DD.MM.YYYY HH:mm:ss",
        "en-GB": "DD/MM/YYYY HH:mm:ss",
        "es-MX": "DD/MM/YYYY hh:mm:ss A",
        "fr-BE": "D/MM/YYYY HH:mm:ss",
        "it-CH": "DD.MM.YYYY HH:mm:ss",
        "nl-BE": "D/MM/YYYY HH:mm:ss",
        "nn-NO": "DD.MM.YYYY HH:mm:ss",
        "nb-NO": "DD.MM.YYYY HH:mm:ss",
        "pt-PT": "DD-MM-YYYY HH:mm:ss",
        "sr-Latn-CS": "D.M.YYYY HH:mm:ss",
        "sv-FI": "D.M.YYYY HH:mm:ss",
        "az-Cyrl-AZ": "DD.MM.YYYY HH:mm:ss",
        "ms-BN": "DD/MM/YYYY HH:mm:ss",
        "uz-Cyrl-UZ": "DD.MM.YYYY HH:mm:ss",
        "ar-EG": "DD/MM/YYYY hh:mm:ss A",
        "zh-HK": "D/M/YYYY HH:mm:ss",
        "de-AT": "DD.MM.YYYY HH:mm:ss",
        "en-AU": "D/MM/YYYY hh:mm:ss A",
        "es-ES": "DD/MM/YYYY HH:mm:ss",
        "fr-CA": "YYYY-MM-DD HH:mm:ss",
        "sr-Cyrl-CS": "D.M.YYYY HH:mm:ss",
        "ar-LY": "DD/MM/YYYY HH:mm:ss",
        "zh-SG": "D/M/YYYY HH:mm:ss",
        "de-LU": "DD.MM.YYYY HH:mm:ss",
        "en-CA": "DD/MM/YYYY hh:mm:ss A",
        "es-GT": "DD/MM/YYYY HH:mm:ss",
        "fr-CH": "DD.MM.YYYY HH:mm:ss",
        "ar-DZ": "DD-MM-YYYY HH:mm:ss",
        "zh-MO": "D/M/YYYY HH:mm:ss",
        "de-LI": "DD.MM.YYYY HH:mm:ss",
        "en-NZ": "D/MM/YYYY hh:mm:ss A",
        "es-CR": "DD/MM/YYYY HH:mm:ss",
        "fr-LU": "DD/MM/YYYY HH:mm:ss",
        "ar-MA": "DD-MM-YYYY HH:mm:ss",
        "en-IE": "DD/MM/YYYY hh:mm:ss A",
        "es-PA": "MM/DD/YYYY HH:mm:ss",
        "fr-MC": "DD/MM/YYYY HH:mm:ss",
        "ar-TN": "DD-MM-YYYY HH:mm:ss",
        "en-ZA": "YYYY/MM/DD HH:mm:ss",
        "es-DO": "DD/MM/YYYY HH:mm:ss",
        "ar-OM": "DD/MM/YYYY HH:mm:ss",
        "en-JM": "DD/MM/YYYY HH:mm:ss",
        "es-VE": "DD/MM/YYYY HH:mm:ss",
        "ar-YE": "DD/MM/YYYY HH:mm:ss",
        "en-029": "MM/DD/YYYY HH:mm:ss",
        "es-CO": "DD/MM/YYYY hh:mm:ss A",
        "ar-SY": "DD/MM/YYYY HH:mm:ss",
        "en-BZ": "DD/MM/YYYY HH:mm:ss",
        "es-PE": "DD/MM/YYYY HH:mm:ss",
        "ar-JO": "DD/MM/YYYY hh:mm:ss A",
        "en-TT": "DD/MM/YYYY HH:mm:ss",
        "es-AR": "DD/MM/YYYY HH:mm:ss",
        "ar-LB": "DD/MM/YYYY HH:mm:ss",
        "en-ZW": "M/D/YYYY HH:mm:ss",
        "es-EC": "DD/MM/YYYY HH:mm:ss",
        "ar-KW": "DD/MM/YYYY HH:mm:ss",
        "en-PH": "M/D/YYYY hh:mm:ss A",
        "es-CL": "DD-MM-YYYY HH:mm:ss",
        "ar-AE": "DD/MM/YYYY HH:mm:ss",
        "es-UY": "DD/MM/YYYY HH:mm:ss",
        "ar-BH": "DD/MM/YYYY HH:mm:ss",
        "es-PY": "DD/MM/YYYY HH:mm:ss",
        "ar-QA": "DD/MM/YYYY HH:mm:ss",
        "es-BO": "DD/MM/YYYY HH:mm:ss",
        "es-SV": "DD/MM/YYYY hh:mm:ss A",
        "es-HN": "DD/MM/YYYY hh:mm:ss A",
        "es-NI": "DD/MM/YYYY hh:mm:ss A",
        "es-PR": "DD/MM/YYYY HH:mm:ss",
        "am-ET": "D/M/YYYY HH:mm:ss",
        "tzm-Latn-DZ": "DD-MM-YYYY HH:mm:ss",
        "iu-Latn-CA": "D/MM/YYYY HH:mm:ss",
        "sma-NO": "DD.MM.YYYY HH:mm:ss",
        "mn-Mong-CN": "YYYY/M/D HH:mm:ss",
        "gd-GB": "DD/MM/YYYY HH:mm:ss",
        "en-MY": "D/M/YYYY HH:mm:ss",
        "prs-AF": "DD/MM/YY HH:mm:ss",
        "bn-BD": "DD-MM-YY hh:mm:ss A",
        "wo-SN": "DD/MM/YYYY HH:mm:ss",
        "rw-RW": "M/D/YYYY HH:mm:ss",
        "qut-GT": "DD/MM/YYYY HH:mm:ss",
        "sah-RU": "MM.DD.YYYY HH:mm:ss",
        "gsw-FR": "DD/MM/YYYY HH:mm:ss",
        "co-FR": "DD/MM/YYYY HH:mm:ss",
        "oc-FR": "DD/MM/YYYY HH:mm:ss",
        "mi-NZ": "DD/MM/YYYY HH:mm:ss",
        "ga-IE": "DD/MM/YYYY HH:mm:ss",
        "se-SE": "YYYY-MM-DD HH:mm:ss",
        "br-FR": "DD/MM/YYYY HH:mm:ss",
        "smn-FI": "D.M.YYYY HH:mm:ss",
        "moh-CA": "M/D/YYYY HH:mm:ss",
        "arn-CL": "DD-MM-YYYY HH:mm:ss",
        "ii-CN": "YYYY/M/D HH:mm:ss",
        "dsb-DE": "D. M. YYYY HH:mm:ss",
        "ig-NG": "D/M/YYYY HH:mm:ss",
        "kl-GL": "DD-MM-YYYY HH:mm:ss",
        "lb-LU": "DD/MM/YYYY HH:mm:ss",
        "ba-RU": "DD.MM.YY HH:mm:ss",
        "nso-ZA": "YYYY/MM/DD HH:mm:ss",
        "quz-BO": "DD/MM/YYYY HH:mm:ss",
        "yo-NG": "D/M/YYYY HH:mm:ss",
        "ha-Latn-NG": "D/M/YYYY HH:mm:ss",
        "fil-PH": "M/D/YYYY HH:mm:ss",
        "ps-AF": "DD/MM/YY HH:mm:ss",
        "fy-NL": "D-M-YYYY HH:mm:ss",
        "ne-NP": "M/D/YYYY HH:mm:ss",
        "se-NO": "DD.MM.YYYY HH:mm:ss",
        "iu-Cans-CA": "D/M/YYYY HH:mm:ss",
        "sr-Latn-RS": "D.M.YYYY HH:mm:ss",
        "si-LK": "YYYY-MM-DD HH:mm:ss",
        "sr-Cyrl-RS": "D.M.YYYY HH:mm:ss",
        "lo-LA": "DD/MM/YYYY HH:mm:ss",
        "km-KH": "YYYY-MM-DD HH:mm:ss",
        "cy-GB": "DD/MM/YYYY HH:mm:ss",
        "bo-CN": "YYYY/M/D HH:mm:ss",
        "sms-FI": "D.M.YYYY HH:mm:ss",
        "as-IN": "DD-MM-YYYY HH:mm:ss",
        "ml-IN": "DD-MM-YY HH:mm:ss",
        "en-IN": "DD-MM-YYYY HH:mm:ss",
        "or-IN": "DD-MM-YY HH:mm:ss",
        "bn-IN": "DD-MM-YY HH:mm:ss",
        "tk-TM": "DD.MM.YY HH:mm:ss",
        "bs-Latn-BA": "D.M.YYYY HH:mm:ss",
        "mt-MT": "DD/MM/YYYY HH:mm:ss",
        "sr-Cyrl-ME": "D.M.YYYY HH:mm:ss",
        "se-FI": "D.M.YYYY HH:mm:ss",
        "zu-ZA": "YYYY/MM/DD HH:mm:ss",
        "xh-ZA": "YYYY/MM/DD HH:mm:ss",
        "tn-ZA": "YYYY/MM/DD HH:mm:ss",
        "hsb-DE": "D. M. YYYY HH:mm:ss",
        "bs-Cyrl-BA": "D.M.YYYY HH:mm:ss",
        "tg-Cyrl-TJ": "DD.MM.YY HH:mm:ss",
        "sr-Latn-BA": "D.M.YYYY HH:mm:ss",
        "smj-NO": "DD.MM.YYYY HH:mm:ss",
        "rm-CH": "DD/MM/YYYY HH:mm:ss",
        "smj-SE": "YYYY-MM-DD HH:mm:ss",
        "quz-EC": "DD/MM/YYYY HH:mm:ss",
        "quz-PE": "DD/MM/YYYY HH:mm:ss",
        "hr-BA": "D.M.YYYY. HH:mm:ss",
        "sr-Latn-ME": "D.M.YYYY HH:mm:ss",
        "sma-SE": "YYYY-MM-DD HH:mm:ss",
        "en-SG": "DD/MM/YYYY HH:mm:ss",
        "ug-CN": "YYYY-M-D HH:mm:ss",
        "sr-Cyrl-BA": "D.M.YYYY HH:mm:ss",
        "es-US": "MM/DD/YYYY"
    };

    return formats[date];

}
export default getLocaleDateString;