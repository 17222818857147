
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import BlankData from "../../BlankData";
import moment from "moment";
import getLocaleDateString from '../../TimeFormat';
import axios from "axios";
import SliderFeedback from "./FeedbackDetailsSlider";
const Feedback = ({ ...props }) => {
    const { PostObject } = props;
    const { APITOKEN } = useSelector(state => state);
    const [trendData, settrendData] = useState([]);
    const [skip, setSkip] = useState(1);
    const [detailResponse, setDetail] = useState({
        response: {},
        display: false
    })
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        try {
            APITOKEN['token'] &&
                Client.post(`Insights/NPSdashboard/ScoreWithFeedbackComments?pageSize=10&pageNo=${skip}`, PostObject, {
                    cancelToken: cancelToken.token,
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(({data}) => {
                        try {
                            if (data.length > 0) {
                                const totalCount = data[0].TotalRecipients;
                                if (((skip) * 10) > totalCount) {
                                    setpainationButton(prv => ({ ...prv, next: true }));
                                } else {
                                    if (data.length < 10) {
                                        if (skip === 1) {
                                            setpainationButton(prv => ({ ...prv, previous: true, next: true }));
                                        };
                                    }
                                };
                            }
                            settrendData(data);
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        if (axios.isCancel(err)) {
                            console.error(err);
                        }
                    });
            return () => {
                cancelToken.cancel();
            }
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, PostObject, skip]);
    const pagination = f => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 1) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }

    const feedbackDetailsAPI = ({ rowUid }) => {
        Client.get(`Insights/GetPeopleDetailonScoreComment?queueId=${rowUid}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    setDetail(prv => ({
                        ...prv,
                        response: data
                    }))
                } catch (err) {
                    console.error(err);
                }
            })
            .catch(err => console.error(err));
    }

    const onClickDetails = ev => {
        setDetail(prv => ({
            ...prv,
            display: true
        }))
        feedbackDetailsAPI(ev);
    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <> <div className="padding20 nps_chart">
            <div className="bx-boder-common">
                <div className="_h_header drop-zone">
                    <h3>Feedback</h3>
                </div>
                <>

                    <table cellPadding="0" className="smstable table-layout-default" cellSpacing="">
                        <thead>
                            {trendData.length === 0 ? <BlankData title="You have not received any feedback yet." content="If you had received any feedback, it would be visible here." colSpan="8" /> : <tr><th className="table_cell_header">Comment</th><th className="table_cell_header">Score</th><th className="table_cell_header">Date Time</th><th className="table_cell_header">Source</th><th className="table_cell_header">Action</th></tr>
                            }

                        </thead>
                        <tbody>
                            <Grid Data={trendData} onClick={onClickDetails} />
                        </tbody>
                    </table>
                    {trendData.length === 0 ? <></> :
                        <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                            <span className="pagination_count vertical-middle">
                                <span>{skip}</span>-
                                <span>{(skip) * 10}</span> of&nbsp;
                                <span>10</span>
                            </span>
                            <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                            <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                        </div>
                    }

                </>
            </div>
        </div>
            {detailResponse.display ?
                <>
                    <SliderFeedback value={detailResponse.response} onClose={setDetail} />
                    <div className="balanceMasking"></div>
                </>
                :
                <></>}

        </>
    )

}
export default Feedback;

const Grid = ({ Data, onClick }) => {
    const { APITOKEN } = useSelector(state => state);

    const f = getLocaleDateString(APITOKEN.config.cultureCode)
    return (
        <>
            {Data && Data.map((v, i) => <tr key={i}>
                <td className="body_table_cell">{v['npsComment']}</td>
                <td className="body_table_cell">{v['npsScore']}</td>
                <td className="body_table_cell">{moment(v['npsScoreDate']).format(f)}</td>
                <td className="body_table_cell">{v['source']}</td>
                <td className="body_table_cell">
                    <button type="button" style={{ display: v['isRecipient'] ? 'inline-block' : 'none' }} onClick={() => onClick(v)} className="link _feedBack_details">Details</button></td>
            </tr>)}
        </>
    )
}