import BlankData from '../../../../BlankData';
import { Export_Client } from '../../../../GlobalAPICall';
import { useParams, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';
const Export = () => {
    const { id } = useParams();
    const { InsightSMSDetails, APITOKEN } = useSelector(state => state);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const exportFn = () => {
        setLoader(true);
        (APITOKEN['token'] !== "") &&
            Export_Client.post(`export/create/EmailDistributionExport?Name=${InsightSMSDetails.name}&QueueId=${id}&emailRecipient=${APITOKEN.config.userEmail}`, {}, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token'],
                    'Content-Type': 'application/json'
                }
            }).then(({data}) => {
                try {
                    setLoader(false);
                    if (data['success']) {
                        navigate('../../../../Download');
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(()=> setLoader(false));
    }

    return (
        <><div className="bx-boder-common white">
            <div className="_h_header">
                <h3>Export</h3>
            </div>
            <div className="_nodata margin-bottom nopadding">
                <table cellPadding="0" cellSpacing="0" width="100%">
                    <tbody>
                        <BlankData title="Prepare your insights for download." content="If you want to get all your insights regarding this sending downloadable in excel format click on the Export button. Your exports will be stored inside your top right corner menu named Downloads." colSpan="1" />
                    </tbody>
                </table>
            </div>
            <div className="text-right addfn percentage-padding-right">
                <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => exportFn(false)}>
                    <ButtonLoader IsLoader={loader}>Export</ButtonLoader>
                    
                </button>
            </div>
        </div></>
    )
}
export default Export;