import React, { useEffect } from "react";
import Cookies from "js-cookie";
import Client from "../GlobalAPICall";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { deleteToken } from "../Redux/Action";

function Logout() {
  const { APITOKEN } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const secretkeyId = Cookies.get("secretKey");
    try {
      Client.post(
        `Authenticate/logout?secretKey=${secretkeyId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then(({ data }) => {
          Cookies.remove("secretKey");
          localStorage.removeItem("_t");
          localStorage.removeItem("_n");
          //   localStorage.removeItem("persist:promoti");
          localStorage.removeItem("authStatus");
          localStorage.removeItem("messageConnect");
          localStorage.removeItem("signatureConnect");
          localStorage.removeItem("addressConnect");
          dispatch(deleteToken());
          setTimeout(() => {
            navigate("/login");
          }, 1000);
        })
        .catch((err) => console.log(err));
    } catch (err) {
      console.log("err", err);
      Cookies.remove("secretKey");
      //   localStorage.removeItem("persist:promoti");
      localStorage.removeItem("authStatus");
      localStorage.removeItem("messageConnect");
      localStorage.removeItem("signatureConnect");
      localStorage.removeItem("addressConnect");
      dispatch(deleteToken());
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <div></div>;
}

export default Logout;
