import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tabing from "./Tabing";
import Client from "../../GlobalAPICall";
import '../../index.css';
const  Billing = ()=>{
    const checkIsAuth = useSelector(state => state);
    
    const [tabData, setTab] = useState([]);
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('Lookup/billing_tabs', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    return(
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                    <Tabing data={tabData}/>
            </div>
        </>
    )
}

export default Billing