import { useSelector } from "react-redux";
import Client from "../../../GlobalAPICall";
import { useState } from "react";
import { useNavigate } from "react-router";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";

const EmailDomain = ({ ...props }) => {
  const { setSelectedDoaminLabel, domainLabel } = props;
  const { APITOKEN } = useSelector(state => state);
  const [formData, setFormData] = useState({});
  const [apiResponse, setAPIResponse] = useState({data:{}});
  const navigate = useNavigate();
  const [isloader, setLoader] = useState(false);
  const saveDomain = () => {
    const obj = {
      "domainName": formData.domainName,
      "domainType": domainLabel.id,
      "description": formData.description === undefined ? "" : formData.description
    };
    setLoader(true);
    Client.post(`Domain/WhiteLabel/Configure/Domain/setup`, obj, {
      headers: {
        Authorization: `Bearer ` + APITOKEN['token']
      }
    })
      .then(res => {
        try {
          setLoader(false);
          if (res.data.statusCode === 200) {
            navigate('/configuration?_id=39')
          } else {
            setAPIResponse(prv => ({
              ...prv,
              data:res.data
            }));
          }
        } catch (err) {
          console.error(err);
        }
      }).catch(err => {
        console.error(err);
      })
  }
  return (
    <div className="domain__body padding20">
      <p>After you have white labeled your domain you are able to create all possible email sender names and reply-to addressess for the domain.</p>
      <div className="margin-top50">
        <div className="domain__half">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Enter your domain
            </label>
            <input type="text" className="inputformmodel width100" onChange={e => setFormData(prv => ({ ...prv, domainName: e.target.value }))} />
            <p className='validation'>{apiResponse.data.result}</p>
          </div>
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Description
            </label>
            <textarea type="text" className="inputformmodel textareaModel width100" onChange={e => setFormData(prv => ({ ...prv, description: e.target.value }))}>
            </textarea>
          </div>
        </div>
        <div className="domain__half fright">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Preview
            </label>
            <div className="previewImg configDomain" style={{ height: `215px` }}>
              <div className="domainconfig">
                <span className="pinfo"><i className="fas fa-user" style={{ fontSize: '16px', color: '#cac9c9' }}></i></span>
                <span>Company X &lt;</span>
                <span>noreply@<b className="domaininfo">{(formData.domainName !== undefined && formData.domainName !== "") ? formData.domainName : 'yourdomain.com'}</b>&gt;</span>
                <p className="ctopme">To me <i className="fas fa-caret-down"></i></p>
              </div>
              <img src="https://deoqpqrmgsvan.cloudfront.net/web/base/icons/EmailDomainWhieLabeling1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="inline-block width100  text-right">
        <button className="grid_btn_1 nobg noborder vertical-middle" onClick={() => setSelectedDoaminLabel({ id: "" })} type="button">Cancel</button>
        <button type="button" className="grid_btn_1 grid_btn_active" onClick={saveDomain} disabled={
          (formData.domainName !== undefined && formData.domainName !== "") ? false : true
        }>
        <ButtonLoader IsLoader={isloader}>Continue</ButtonLoader>
        </button>
      </div>
    </div>
  )

}
export default EmailDomain;