import moment from 'moment';
import { get as _get } from "lodash";
import { useState } from 'react';
import WaterMarkRemove from '../ReuseTemplate/WaterMarkRemove';
import getLocaleDateString from '../TimeFormat';
import Client from '../GlobalAPICall';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const defaultConfig = {
    position: "bottom-center",
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const CampaignDetails = ({ v, tabId, contentId, ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const [showFlag, setPopupShowFlag] = useState(false);
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const closeWaterMarkPopup = (f) => {
        setPopupShowFlag(f);
    }
    const CampaignName = contentId === '2' ? 'Landing Page' : contentId === '5' ? 'Email' : contentId === '4' ? 'Survey' : contentId === '97' ? 'Store' : 'Workflow';
    const QrGenrator = () => {
        let url = (contentId === '97') ? `Mobile/store/${v.id}/generateqrcode` : `Campaign/${v.id}/generateqrcode`;
        Client.patch(url, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                console.log("data: ", data);
                try {
                    props.load();
                } catch (err) {
                    console.log("error");
                    console.error(err);
                }
            }).catch(err => {
                console.log("ERROR");
                console.log(err);
                if (contentId === '97') {
                    console.log("IN error toast");
                    toast.info(_get(err, "response.data.message", ""), {
                        position: "bottom-center",
                        ...defaultConfig
                    });
                }
            })
    }
    return (
        <><div className="-cam-right">
            <div className="-cam-detail-wrap">
                <table width="100%" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>Details</th>
                            <th>{(contentId === '74') ? 'Start Trigger' : 'State'}</th>
                            <th>Status</th>
                            <th>Ends</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{v['description']}</td>
                            <td>{v['state']}</td>
                            <td className="drop-zone">
                                <button type="button"
                                    disabled={(tabId === 7) ? true : false} className={(v['status'] === 'Active') ?
                                        'live' : (v['inProgress'] === true) ? '-normal-text' : 'defaultActive'}
                                    onClick={(e) => {
                                        (v['status'] === 'Active' || v['inProgress'] === true) ?
                                            e.stopPropagation() : setPopupShowFlag(true)
                                    }} style={{ paddingLeft: '0', display: (v['isExpired']) ? 'none' : 'inline-block' }} >{v['status']}</button>
                                <span className="red" style={{ display: (!v['isExpired']) ? 'none' : 'inline-block' }}>Expired</span></td>
                            <td className=" drop-zone">
                                <strong style={{ display: (v['isExpired']) ? 'none' : 'inline-block' }}
                                    onClick={() => (contentId === '5') ? '' : (v['isExpired']) ? '' : setPopupShowFlag(true)}
                                    className={(contentId === '5') ? '' : (v['isExpired']) ? '' : 'defaultActive'}>
                                    {(v['status'] === 'Active') ?
                                        (v['isNeverEnd']) ? 'Forever Active' : moment(v['endDate']).format(f) : (v['inProgress'] === true) ? '' : 'Set'}
                                </strong>
                                <strong style={{ display: (!v['isExpired']) ? 'none' : 'inline-block' }}>{v['endDate']}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="list-btm">
                <div className="cam-info">
                    <table cellPadding="0" cellSpacing="0">
                        <tbody>
                            <tr>
                                <td>Folder</td>
                                <td>{(v['folderName'] === undefined) ? "" : v['folderName']}</td>
                            </tr>
                            <tr>
                                <td>Created By</td>
                                <td>{v['createdBy']}</td>
                            </tr>
                            <tr><td>Creation Date</td>
                                <td>{moment(v['creationDate']).format(f)}</td>
                            </tr>
                            <tr>
                                <td>Last Modified</td>
                                <td>{(v['lastModifiedDate'] === undefined) ? "" : moment(v['lastModifiedDate']).format(f)} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ display: (contentId === '74') ? 'none' : 'inline-block' }} className="cam-qr inline-block">
                    {/* <div style={{ marginTop: '10px', display: !!v['qrCodeUrl'] ? 'block' : 'none' }}>
                        <button type="button" className="inline-block vertical-middle pointer  link font-16 nobg downloadbutton downloadMenu drop-zone"><i className="fal fa-ellipsis-h" style={{ fontSize: '24px' }}></i></button>
                    </div> */}
                    <img style={{ display: (v['inProgress']) ? 'none' : 'inline-block' }} alr="" src={(v['qrCodeUrl'] !== undefined && v['qrCodeUrl'] !== "") ? v['qrCodeUrl'] : 'https://d27kqpxnei4laf.cloudfront.net/web/base/icons/QRCodeDefault.png'} className="qr-genratore" onClick={QrGenrator} width="120" alt="" />
                </div>
            </div>
        </div>
            {showFlag ?
                <WaterMarkRemove
                    closeEvent={closeWaterMarkPopup}
                    CampaignData={v.watermarkName}
                    Id={v.id}
                    Reload={() => props.load()}
                    Data={v}
                    IsNeverEnd={v.isNeverEnd}
                    StartDate={v.startDate}
                    EndDate={v.endDate}
                    contentId={contentId}
                    setLowBalancePopup={setLowBalancePopup}
                    lowBlance={lowBlance}
                >
                    <div>
                        <h3>Activate</h3>
                        <p>To Activate your {CampaignName} you need to set a Activation Period. Before you set the Activation Period a Watermark layer is disabling the {contentId === '2' ? 'Landing Page' : contentId === '5' ? 'Email' : contentId === '4' ? 'Survey' : contentId === '97' ? 'Store' : 'Workflow'} for interaction. You can still view the {CampaignName}.</p>
                    </div>
                    <div>
                        <h3>Activation Period</h3>
                        <p>In the Activation Period you visitors can interact with you {CampaignName} and you will find all registered activity under Insights.</p>
                    </div>
                    <div style={{ paddingBottom: '27px' }}>
                        <h3>Closing Time</h3>
                        <p>When the Closing Time is reached we will add a Watermark Text as a layer on top of your {CampaignName}, disabling it for interaction.</p>
                    </div>

                </WaterMarkRemove>
                : <></>}
        </>
    )

}

export default CampaignDetails;