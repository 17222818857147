
import { encode as btoa } from 'base-64'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Cookies from 'js-cookie'

export const TemplateFolder = ({ ...props }) => {
  const { Data, TabInfo, ArchieveFN, isLoading } = props;

  const { APITOKEN } = useSelector(state => state);
  const navigate = useNavigate();
  const editCampagin = (ev) => {
      if (ev.RedirectToDomain !== "") {
        const secreatKey = Cookies.get('secretKey');
          window.location.replace(`${ev.RedirectToDomain}${ev.EditUrl}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}`);
      } else {
          navigate(ev.EditUrl);
      }
  }

  return (
    <>
      {isLoading ? <div></div> :
        <div className=" width100 bx-boder-common nobg borderraduisAll">
          <div className="_h_header white  border-radius-bottom-none">
            <h3 className="_t_i">{TabInfo.name}</h3>
            <span className="flex-right">

            </span>
          </div><div className="_template_list"><table cellPadding="0" cellSpacing="0" width="100%" className="smstable white">
            <thead><tr><th className="table_cell_header">Name</th><th className="table_cell_header">Description</th><th className="table_cell_header">Template Type</th><th className="table_cell_header">Created</th><th className="table_cell_header">Actions</th></tr></thead><tbody className="_template_list_table">
              {Data.map((v, i) =>
                <tr key={i}>
                  <td className="body_table_cell">{v['Name']}</td>
                  <td className="body_table_cell">{v['Description']}</td>
                  <td className="body_table_cell">{v['TemplateType']}</td>
                  <td className="body_table_cell">{v['CreationDate']}</td>
                  <td className="body_table_cell">
                  <button type="button" onClick={()=> editCampagin(v)}
                                        className="inline-block link">Edit</button>&nbsp; | &nbsp;
                    <button type="button" className="link inline-block" onClick={() => ArchieveFN(v.Id)}>Delete</button></td></tr>
              )}
            </tbody></table><div id="_delete_pop" style={{ display: 'none' }}><div className="margin-bottom padding20"  >
              <div id="nodatamessage">
                <strong>Do you want Delete Template.</strong><br /><br />
                Connect it to give your landing pages and website a more professional look.
              </div>
            </div><div className="text-right padding20" style={{ paddingTop: 0 }}>
                <button type="button" className="grid_btn_1 nobg noborder _nodelete">Cancel</button>
                <button type="button" className="grid_btn_1  deleteBtn  _aggre_link">Confirm</button>
              </div></div></div></div>
      }
    </>
  )

}


