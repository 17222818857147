import { useCallback, useEffect, useState } from "react";
import {  useDispatch, useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import { ChromePicker } from 'react-color';
import _ from "lodash";
import { client } from 'filestack-react';
import { setLogoFn } from "../../Redux/Action";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
// import { setToken } from "../../Redux";

const Default = ({ isVisible }) => {
    const { APITOKEN } = useSelector(state => state);
    const [apiResponse, setApiResponse] = useState("");
    const url = process.env;
    const dispach = useDispatch();
    const [isloader, setLoader] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const [defaultData, setDefault] = useState({
        fontColor: [],
        fontSizeJson: [],
        fontTextJson: [],
        socialMediaJson: [],
        defaultFontText: "",
        defaultFontSize: "",
        CompanyLogo: ""
    });
    const [count, setCount] = useState(0);
    const setLoaded = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Lookup/DefaultSettings/GetDefaultSettingsDetails`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(async (res) => {
                try {
                    const { FontColor, FontSizeJson, FontTextJson, SocialMediaJson } = res['data'][0];
                    setDefault({
                        fontColor: JSON.parse(FontColor),
                        fontSizeJson: JSON.parse(FontSizeJson),
                        fontTextJson: JSON.parse(FontTextJson),
                        socialMediaJson: JSON.parse(SocialMediaJson),
                        defaultFontText: (function () { return JSON.parse(FontTextJson)[0].DefaultText }()),
                        defaultFontSize: (function () { return JSON.parse(FontSizeJson)[0].DefaultSize }())
                    });
                } catch (err) {
                    console.log(err);
                }
            });

    }, [APITOKEN])

    const handleClick = (v) => {
        for (var x of defaultData.fontColor) {
            x['show'] = false;
        };
        const filter = defaultData.fontColor.filter(x => x.Id === v['Id']);
        filter[0].show = (!!filter[0].show) ? false : true;
        filter[0].DefaultColor = (!!filter[0].DefaultColor) ? v['DefaultColor'] : v['DefaultColor'];
        setDefault(prv => ({ ...prv, fontColor: defaultData.fontColor, ...filter }))
    };
    const handleChangeComplete = (v, color) => {
        const index = _.findIndex(defaultData.fontColor, function (x) { return x.Id === v['Id'] });
        defaultData.fontColor[index].DefaultColor = color.hex;
        setDefault(prv => ({ ...prv, fontColor: defaultData.fontColor }));
    };
    const pickerClose = () => {
        for (var x of defaultData.fontColor) {
            x['show'] = !isVisible;
        };
        setDefault(prv => ({ ...prv, fontColor: defaultData.fontColor }));
    }

    const socialMedia = (object, value) => {
        const index = _.findIndex(defaultData.socialMediaJson, function (x) { return x.MediaType === object.MediaType });
        defaultData.socialMediaJson[index].links = value;
        setDefault(prv => ({ ...prv, socialMediaJson: defaultData.socialMediaJson }));
    }

    //=====  Save default Setting  =====//
    const saveDefaultSetting = () => {
        const filterFontText = defaultData.fontTextJson.filter(x => x.FontText === defaultData.defaultFontText);
        const obj = {
            fontSize: defaultData.defaultFontSize.toString(),
            fontId: filterFontText[0].fontId.toString(),
            color: JSON.stringify(defaultData.fontColor),
            socialMedia: JSON.stringify(defaultData.socialMediaJson),
            companyLogo: defaultData.CompanyLogo
        };
        setLoader(true);
        Client.post(`Lookup/DefaultSettings/SaveDefaultSettings`, obj, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        })
            .then(({data}) => {
                setLoader(false);
                if (data.Success) {
                    setApiResponse(data.Message);
                }
            }).catch(err => {
                console.error(err);
            })
    }
    const fileUPloadByFilePicker = useCallback(() => {
        setTimeout(() => {
            const clients = client.init(url.REACT_APP_API_KEY);
            const options1 = {
                container: '.company_logo',
                displayMode: 'dropPane',
                maxFiles: 1,
                dropPane: {
                    overlay: true, // if this is true then overlays will conflict between instances
                    onSuccess: (res) => {
                        setDefault(prv => ({
                            ...prv,
                            CompanyLogo: res[0].url
                        }));
                        setDisabled(false);
                        dispach(setLogoFn(
                            res[0].url
                          ))
                    }
                }
            };
            clients.picker(options1).open();
        }, 500);
        setCount(1);
    }, [url.REACT_APP_API_KEY,dispach]);
    useEffect(() => {
        setLoaded();
        count === 0 &&
        fileUPloadByFilePicker();
    }, [setLoaded,count,fileUPloadByFilePicker])


    return (
        <><div className="bx-boder-common width100  margin-bottom white">
            <div className="_h_header">
                <h3>Default Settings</h3>
            </div>
            <div className="padding20">
                <p>Set your defaults here and your editors will be configured with your Default Settings.</p>
                <div className="config__bx fontsection" style={{ gridTemplateColumns: "38% 38% auto" }}>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">
                            <strong>Select Text Font</strong>

                        </label>
                        <select className="inputformmodel" value={defaultData.defaultFontText}
                            onChange={e => { setDefault(prv => ({ ...prv, defaultFontText: e.target.value })); setDisabled(false)}} >
                            <option value="">Select</option>
                            {defaultData.fontTextJson && defaultData.fontTextJson.map((v, i) => <option key={i} value={v.FontText}>{v['FontText']}</option>)}
                        </select>

                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls"><strong>Select Text Size</strong></label>
                        <select className="inputformmodel" value={defaultData.defaultFontSize}
                            onChange={e => { setDefault(prv => ({ ...prv, defaultFontSize: e.target.value }));  setDisabled(false)}} >
                            <option value="">Select</option>
                            {defaultData.fontSizeJson && defaultData.fontSizeJson.map((v, i) => <option key={i} value={v.FontSize}>{v['FontSize']}</option>)}
                        </select>
                    </div>
                </div>
                <div className="config__bx fontsection" style={{ gridTemplateColumns: "78.5% auto" }}>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">
                            <strong>Company Logo </strong>
                        </label>
                        {/* <div id="fs-panel">
                            <i className="far fa-cloud-upload-alt"></i>
                            <p>Click on the icon or Drag and drop the file from your computer to upload it.</p></div> */}
                        {/* <div className="logouploaded"></div> */}
                        <div id="fs-panel" className="company_logo"></div>
                    </div>
                </div>
                <div className="config__bx fontsection">
                    <div className="formmodel-bx">
                        <label className="_l_able_cls"><strong>Colors</strong></label>
                        <div className="__default__text__fontColor">
                            {defaultData.fontColor && defaultData.fontColor.map((v, i) =>
                                <div key={i} className="__color_bx"><p>{v['Name']}</p><span style={{ background: v['DefaultColor'] }}></span>
                                    <div onClick={() => handleClick(v)} className="inputformmodel picker">{v['DefaultColor']} </div>
                                    {v.show ? <><div className="colorPickerContainer" onClick={pickerClose}></div><div className="colorPicker">
                                        <ChromePicker
                                            color={v.DefaultColor}
                                            onChangeComplete={(e) => { handleChangeComplete(v, e); setDisabled(false)}}
                                        />
                                    </div></> : null}

                                </div>)}

                        </div>
                    </div>
                </div>
                <div className="config__bx fontsection">
                    <div className="formmodel-bx">
                        <label className="_l_able_cls" style={{ marginBottom: "20px" }}>
                            <strong>Social Media Links</strong>
                        </label>
                        <div className="__social__media__Section">
                            {defaultData.socialMediaJson && defaultData.socialMediaJson.map((v, i) =>
                                <div key={i} className="socialbx"><span>
                                    <i style={{ fontSize: '26px' }} className={v['MediaIcon']}></i>
                                </span>
                                    <div className="formmodel-bx bx">
                                        <label className="_l_able_cls">{v['MediaType']}</label>
                                        <input type="text" placeholder={`https://${v['MediaType']}.com/`}
                                            onChange={e => { socialMedia(v, e.target.value);  setDisabled(false)}} className="inputformmodel mediaKeyup" value={v['links']} />
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </div>
                {apiResponse !== "" ? <p className="successMessage">{apiResponse}</p> : <></>}
                <div className=" text-right margin-top50 ">
                    {/* <button type="button" className="nobg noborder grid_btn_1 __cancel_link">Cancel</button> */}
                    <button type="button" className="grid_btn_1 grid_btn_active __Save_link" disabled={isDisabled} onClick={saveDefaultSetting}>
                    <ButtonLoader IsLoader={isloader}>Save</ButtonLoader>

                    </button>
                </div>
            </div>
        </div></>
    )
}
export default Default