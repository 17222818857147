
import React, { useCallback, useEffect, useState } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import pareto from 'highcharts/modules/pareto';
pareto(Highcharts);

export default function SourceCountChart({ Data, Title }) {
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: '',
            align: 'right',
            verticalAlign: 'bottom'
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: [{
            type: '',

        }, {
            title: {
                text: ''
            },
            minPadding: 0,
            maxPadding: 0,
            max: 100,
            min: 0,
            opposite: true,
            labels: {
                format: '{value}%'
            }
        }],
        yAxis: {
            title: {
                text: ''
            }

        },
        tooltip: {
            crosshairs: true,
            shared: true,
            valueSuffix: '',

        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,

                },
                cursor: 'pointer',
            }
        },
        credits: {
            enabled: false
        },
        series: [

            {

                name: Title,
            }
        ]
    });
    const dataFilter = useCallback(() => {
        const ar = [];
        const reponse = [];
        let CumulativeArray = [];
        if (Data.length > 0) {
            CumulativeArray = [Data[0].totalCount];
            Data.reduce((prv, nesw) => {
                
                const previous = (!!prv.totalCount ? prv.totalCount : prv)
                const newone = previous + nesw.totalCount;
                CumulativeArray.push(newone);
                return newone;
            })
        }



        Data.forEach(function (v) {
            ar.push(v['totalCount']);
            reponse.push(new Date(v['distStatus']).getDate());
        });

        setOptionValue(prv => ({
            ...prv,
            series: [{
                type: 'area',
                name: 'Accumulative',
                data: CumulativeArray


            }, { type: 'column', name: 'Today', data: ar }],
            xAxis: { categories: reponse }
        }));
    }, [Data])
    useEffect(() => {
        dataFilter()
    }, [dataFilter])

    return (
        <div className=" nps_chart ">
            <div className="bx-boder-common white">
                <div className="_h_header drop-zone">
                    <h3>{Title}</h3>
                </div>
                <HighchartsReact highcharts={Highcharts} options={optionData} />
            </div>
        </div>
    )
}