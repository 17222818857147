
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import { useNavigate } from 'react-router';
import BlankData from '../../../../BlankData';


const EmailGoal = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [goalData, setGoalData] = useState([]);
    const navigate = useNavigate();

    const [deletePanel, setDeletePanel] = useState({
        flag: false,
        id: ""
    });

    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/goals/${tabId}/Details`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setGoalData(res['data']);
            });
    }, [APITOKEN, tabId])

    const deleteFN = item => {
        setDeletePanel(prv => ({
            ...prv,
            id: item.Id,
            flag: true
        }))
    }
    const confirmFn = () => {
        Client.delete(`Insights/goals/template/${deletePanel.id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    load();
                    setDeletePanel(prv => ({
                        ...prv,
                        id: "",
                        flag: false
                    }))
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }
    useEffect(() => {
        load()
    }, [load]);
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100 nobg">
            {deletePanel.flag ? <>
                <div className="_h_header white">
                    <h3>Delete</h3>
                </div>
                <table cellPadding="0" cellSpacing="0" width="100%">
                    <tbody>
                        <BlankData title="Do you want Delete Template." content="Connect it to give your landing pages and website a more professional look." />
                    </tbody>
                </table>
                <div className="text-right padding20 white">
                    <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={() => setDeletePanel(prv => ({
                        ...prv,
                        id: "",
                        flag: false
                    }))}>Cancel</button>
                    <button type="button" className="grid_btn_1  deleteBtn  _confirm" onClick={confirmFn}>Confirm</button>
                </div>
            </> : <>
                <div className="_h_header white border-radius2">
                    <h3>Email Goals</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => navigate('/createContent/66')}>Create New</button>
                    </span>
                </div>
                <div className="_list_fx">
                    <table className="smstable white" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Description</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {goalData && goalData.map((item, i) => <EmailGoalGrid item={item} key={i}
                                deleteFN={deleteFN}
                                setDeletePanel={setDeletePanel}
                            />)}
                        </tbody>

                    </table>
                </div>
            </>}
        </div>
    </>)
}
export default EmailGoal;

// Email Grid Method //
const EmailGoalGrid = ({ item, deleteFN }) => {
    const navigate = useNavigate();
    return (
        <tr><td className="body_table_cell">{item.Name}</td><td className="body_table_cell">{item.Description}</td><td className="body_table_cell"><button type="button" className="inline-block link _edit_template noborder nobg" onClick={() => navigate(`/create/templates/goal/CreateEmail?cam=${item.Id}&edit=true`)}>Edit</button><span style={{ "display": item.IsDefault ? 'none' : 'inline-block' }}>&nbsp; | &nbsp;</span><button type="button" style={{ "display": item.IsDefault ? 'none' : 'inline-block' }} className="inline-block link _edit_template noborder nobg" onClick={() => deleteFN(item)}>Delete</button></td></tr>
    )
}