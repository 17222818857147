import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useParams } from "react-router";
import SMSendingGrid from "./SMSendingGrid";
import { buildSMSObjectPost } from "../Create/Goal/GoalCommentPostObject";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";
export const SendingGoal = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [goalArrayObject, setGoalDetails] = useState([]);
    const [startDate, setStartDateSelected] = useState('');
    const [minDate, setMinDate] = useState('');
    const { id } = useParams();
    const [IsLoader, setIsLoader] = useState(false);

    const { Data ,ButtonDisplay } = props;
    const KPISetting = JSON.parse(Data.KPISettings);
    const [goalObject, setGoalObject] = useState(KPISetting);
    const [checkValidation, setValidationChcked] = useState([]);
    // === Drop down action change API Call === //
    const goalTemplateChange = useCallback((e) => {
        Client.get(`Insights/distribution/goals/${id}/setting_details?goaltemplateId=${e}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({data}) => {
                try {
                    const KPISetting = JSON.parse(data[0].KPISettings);
                    setGoalObject(KPISetting);
                    setStartDateSelected('');
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }, [APITOKEN, id])

    // === Goal Sending Drop down API Call === //
    const load = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Insights/goals/25/Details`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setGoalDetails(data);
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.error(err);
                })
    }, [APITOKEN]);


    const saveGoal = () => {
        const settingsValues = buildSMSObjectPost(goalObject);
        setIsLoader(true);
        const obj = {
            goalTemplateId: null,
            measureEndDateTime: startDate,
            settingsValues
        };
        Client.post(`Insights/distribution/goals/${id}/setting_details/25`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({data}) => {
                if (data.status) {
                    ButtonDisplay(false);
                    setGoalObject(goalObject);
                    setIsLoader(false);
                    setValidationChcked([]);
                }
            }).catch(err => {
                console.error(err);
                setIsLoader(false);
            })
    }
    useEffect(() => {
        load();
        const data = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        setStartDateSelected(data);
        setMinDate(data);
    }, [load, APITOKEN, minDate])
    return (
        <div className="dashbd_sec">
            <div className="sms_Sec_1 bx-boder-common width100">
                <div className="_h_header noborder">
                    <h3>Set SMS SendingGoals</h3>
                </div>
                <div className="go-suti">
                    <p>Prefill you goals based on Template. You can always adjust your numbers, but it is a great start.(Optional)</p>
                    <select className="inputformmodel width320" onChange={e => goalTemplateChange(e.target.value)}>
                        <option value="">Select</option>
                        {goalArrayObject.map((v, i) => <option key={i} value={v.Id}>{v.Name}</option>)}
                    </select>
                </div>
                <SMSendingGrid
                    setGoalObject={setGoalObject}
                    goalObject={goalObject}
                    setValidationChcked={setValidationChcked}
                    checkValidation={checkValidation}
                />
                <div className="b_top">
                    <div className="padding20">
                        <div className="formmodel-bx width295">
                            <label className="_l_able_cls">Meassure Goal achievment at End Date and Time</label>
                            <Datepicker className="inputformmodel "
                                value={startDate}
                                min={minDate}
                                onChange={e => setStartDateSelected(e.value)}
                                dataTimezone='utc'
                                dateFormat="YYYY.MM.DD"
                                locale={APITOKEN.config.countryCode}
                                controls={['calendar', 'time']}
                                placeholder="End Date"
                                touchUi={true}
                                timeFormat="HH:mm"
                                theme="ios"
                                themeVariant="dark"/>
                        </div>
                    </div>
                    <div className="padding20 text-right">

                        <button type="button" disabled={
                            (checkValidation.length > 0 && startDate !== "") ? false : true
                        } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={saveGoal}>
                             <ButtonLoader IsLoader={IsLoader}>
                               Save
                            </ButtonLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )

}

