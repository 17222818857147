

import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import solidGauge from "highcharts-solid-gauge";
import { useState } from "react";
highchartsMore(Highcharts);
solidGauge(Highcharts);
const DeliveredLive = ({ ...props }) => {
    const { Data } = props;
    const [options] = useState({
        chart: {
            type: 'solidgauge',
            width: 270,
            height: 400
        },
        title: null,
        accessibility: {
          enabled:false  
        },
        pane: {
            center: ['50%', '34%'],
            size: '90%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },

        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        exporting: {
            enabled: false
        },

        yAxis: {
            min: 0,
            max: Data.TotalReciepients,
            tickPositions: [0, Data.TotalReciepients],
            title: {
                y: -70,
                text: 'Delivered'
            },
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,

            labels: {
                y: 16,
                enabled: false
            }
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Delivered',
            data: [{ y: Data.Delivered, color: "#3aac68" }],
            dataLabels: {
                format: '<div style="text-align:center">' +
                    '<span style="font-size:25px"></span><br/>' +
                    '<span style="font-size:12px;opacity:0.4"></span>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: '',
                enabled: false
            },

        }]

    });

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </div>
    )

}

export default DeliveredLive
