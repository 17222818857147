
import './index.css';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../GlobalAPICall';
import CRMListDetails from './CRMListDetails';
import { encode as btoa } from 'base-64'

const CRMList = () => {
    const { APITOKEN } = useSelector(state => state);
    const [crmlist, setCRMList] = useState([]);
    const [listShow, setListDetailShow] = useState({ flag: false, id: '' });
    const [buttonShow, setButtonShow] = useState({ nextPage: false, previous: true });

    const [skip, setPaginationSkip] = useState(0);
    const [totalCount, setTotalCOunt] = useState(0);
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/dashboard/SubscriberListGrowth/KPI?skip=${skip}&limit=10`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        setCRMList(res['data']);
                        setTotalCOunt(res['data'].length === 0 ? 0:res['data'][0].Total);
                        if (res['data'].length < 10) {
                            setButtonShow({ nextPage: true })
                            if (skip === 0) {
                                setButtonShow({ nextPage: true, previous: true });
                            }
                        }
                    } catch (err) {
                        console.error(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, skip]); // <-- add any missing dependencies react warns about
    const pagination = (f) => {

        if (f === 0) {
            setPaginationSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setButtonShow({ previous: true })
            } else {
                setButtonShow({ previous: false });
            }
        } else {
            setPaginationSkip(skip => skip + 1);
            setButtonShow({ previous: false });
        }
    }
    const listDetails = (v) => {
        setListDetailShow(prv => ({ ...prv, flag: true, id: v.ListId }));
    }
    const crmlistOpen = ev => {
        window.location.replace(`${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&redurl=crm/people/subscribers/${ev.ListId}/1`)
    }
    const crmCreateList = () => {
        window.location.replace(`${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&redurl=crm/people/joinlist`)
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>

            <div className="overviewtable margin-top50 bx-boder-common white">
                <div className="_h_header">
                    <h3>CRM Lists</h3>
                    <span className="flex-right">
                        <button className="grid_btn_1 nobg vertical-middle" onClick={crmCreateList} type="button">Create List</button>
                    </span>
                </div>
                <div className="dash-list">
                    <table cellPadding="0" cellSpacing="0" className="smstable">
                        <thead>
                            <tr><th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Total</th>
                                <th className="table_cell_header">Today</th>
                                <th className="table_cell_header">Last 7 Days</th>
                                <th className="table_cell_header">Last 30 Days</th>
                                <th className="table_cell_header">Last 6 Months</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {crmlist.map((v, i) =>
                                <tr key={i}>
                                    <td className="body_table_cell noproperties">{v.ListName}</td>
                                    <td className="body_table_cell">{v.TotalCount}</td>
                                    <td className="body_table_cell">{v.TodayCount}</td>
                                    <td className="body_table_cell">{v.Last7DayCount}</td>
                                    <td className="body_table_cell">{v.Last30dayCount}</td>
                                    <td className="body_table_cell">{v.Last6MonthCount}</td>
                                    <td className="body_table_cell">
                                        <Link className="inline-block link -details-growth" onClick={() => listDetails(v)}>Details</Link>
                                        &nbsp; | &nbsp;
                                        <button type="button" onClick={() => crmlistOpen(v)} className="inline-block link">Open</button>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <div className="formmodel-bx text-right  b_top padding20 nomargin">
                    <span className="pagination_count vertical-middle ">
                        <span className="_pageno">{skip + 1}</span>-<span id="pagesize">{(skip + 1) * 10}</span> of <span className="_pageno">{totalCount}</span></span>
                    <button type="button" className="bigtext" disabled={buttonShow.previous} onClick={() => pagination(0)}><i className=" fas fa-caret-left"></i></button>
                    <button type="button" className="bigtext" disabled={buttonShow.nextPage} onClick={() => pagination(1)}><i className=" fas fa-caret-right"></i></button>
                </div>
            </div>
            {listShow.flag ? <div className="maskIngKPi"><CRMListDetails ListObject={listShow} SetListDetailShow={setListDetailShow} /> </div> : <></>}

        </>
    )
}

export default CRMList;
