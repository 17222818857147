import React, { useCallback, useEffect } from 'react';
import CatgoryList from './CatgoryList';

function SubCatgoryView({ dispatch, state, APITOKEN }) {
    const load = useCallback(async () => {
        const { data } = await CatgoryList(APITOKEN, state.languageCode, state.id);
        dispatch({ type: 'LOAD_VIEW', payload: { viewLoad: data } });
    }, [APITOKEN, dispatch, state.languageCode, state.id]);
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className='videoCaptionHtml'>
            <div className='videoCaptionContainer' style={{ width: '600px' }}>
                <div className="topn-header">
                    <span className="selected-title">View Sub Category</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => dispatch({ type: 'LOAD_VIEW', payload: { viewShow: false } })}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="">
                    <table cellPadding="0" cellSpacing="0" className="smstable ">
                        <thead>
                            <tr>
                                <th className="table_cell_header" width="15%">
                                    &nbsp;
                                </th>
                                <th className="table_cell_header" width="30%">
                                    Category Name
                                </th>
                                <th className="table_cell_header">
                                    Language Code
                                </th>
                                <th className="table_cell_header">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {state.viewLoad.map((item, index) => {
                                return <tr key={index}>
                                    <td className="body_table_cell">
                                       { item?.image && <img src={item?.image} width={30} alt={item.value} />}
                                    </td>
                                    <td className="body_table_cell">
                                        {item.value}
                                    </td>
                                    <td className="body_table_cell">

                                        {item.languageCode}
                                    </td>


                                    <td className="body_table_cell">
                                    <button type="button" className="link">Edit</button>
                                    {/* <button type="button" className="link" onClick={() => OnView(item)}>View</button>
                                    <button type="button" className="link" onClick={() => OnAdd(item)}>Add</button> */}

                                </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

    )
}

export default SubCatgoryView;