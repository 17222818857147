import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import Select from 'react-select';
import BlankData from "../../../BlankData";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";
import Languages from "../Track/Languages";
import CatgoryList from "../Categories/CatgoryList";
const years = [
    { name: "January", value: '1' },
    { name: "February", value: '2' },
    { name: "March", value: '3' },
    { name: "April", value: '4' },
    { name: "May", value: '5' },
    { name: "June", value: '6' },
    { name: "July", value: '7' },
    { name: "August", value: '8' },
    { name: "September", value: '9' },
    { name: "October", value: '10' },
    { name: "November", value: '11' },
    { name: "December", value: '12' },
]

const Speeches = () => {
    const { APITOKEN } = useSelector(state => state);
    const [loader, setLoader] = useState(false);
    const [lang, SetLang] = useState({
        data: [],
        Speaker: []
    });
    const [yearNumber, setYearNumber] = useState({ data: [], selected: "" });
    const [deleteId, setDeleteId] = useState({speakerId:"",speechId:""});
    const [categoriesList, SetCategoriesList] = useState([]);
    const [subCatgory, SetSubCatogry] = useState([]);
    const [speakerSpeeches, setSpeakerSpeeches] = useState([]);
    const [objectArray, setObject] = useState({
        speechId: 0,
        title: "",
        zZ_articleId: "",
        publishYear: "",
        publishMonth: "",
        categoryId: "",
        speakerId: 0,
        languageCode: "",
        subCategoryId: []
    })
    const onSpeechSpeakder = ev => {
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/SpeechsBySpeakerId?languageCode=${objectArray.languageCode}&speakerId=${parseInt(ev)}`, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                setSpeakerSpeeches(res.data);
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
        });

    }
    const catFilter = res => {
        const array = [];
        array.push(...res.map(x => ({ ...x, value: x.value, label: x.value })))
        return array;
    }
    const onChangeCat = value => {
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/AppCategories?languageCode=${value}`, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {

                SetCategoriesList(data);
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
        });

    }
    const onChangeFn = newValue => {
        setObject(prv => ({
            ...prv,
            languageCode: newValue
        }));
        speakerAPI(newValue);
    }

    const speakerAPI = newValue => {
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/Speakers?languageCode=${newValue}`, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                SetLang(prv => ({
                    ...prv,
                    Speaker: res.data
                }));
                onChangeCat(newValue);
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
        });
    }


    const load = useCallback(async () => {
        if (APITOKEN['token'] !== "") {
            const { data } = await Languages(APITOKEN);
            try {
                if (data.length > 0) {
                    SetLang(prv => ({
                        ...prv,
                        data
                    }));
                }
            } catch (err) {
                console.error(err);
            }
        }
        return (() => {
            SetLang({
                data: [],
                Speaker: []
            });
        });
    }, [APITOKEN]);

    const yearsLoad = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        (APITOKEN['token'] !== "") &&
            Client.get(`Mobile/PublishYear`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setYearNumber(prv => ({
                        ...prv,
                        data
                    }))
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    cancelToken.cancel();
                }
            });
    }, [APITOKEN]);

    const onChange = (newValue) => {
        if (newValue.length > 0) {
            setObject(prv => ({
                ...prv,
                subCategoryId: newValue
            }));
        } else {
            setObject(prv => ({
                ...prv,
                subCategoryId: []
            }));
        }
    }


    const Save = () => {
        let catId = "";
        setLoader(true);
        objectArray.subCategoryId.map(x => catId += `${x.id},`);
        objectArray['subCategoryIds'] = catId;
        objectArray['publishYear'] = yearNumber.selected;
        const PostObject = {
            ...objectArray,
        };
        delete PostObject['subCategoryId'];
        Client.post(`Mobile/SpeechesBySpeakerId`, PostObject)
            .then(({ data }) => {
                setLoader(false);
                if (data.Success) {
                    reset();
                    setSpeakerSpeeches([]);
                }
            }).catch(err => {
                setLoader(false);
                throw err;
            });
    }
    // Speeche Delete API Call //
    function confirmDelete() {
        Client.delete(`Mobile/Speeches?speakerId=${deleteId.speakerId}&speechId=${deleteId.speechId}`)
            .then(({ data }) => {
                if (data.Success) {
                    reset();
                    setSpeakerSpeeches([]);
                }
            }).catch(err => console.error(err));
    }
    function landDelete(ev) {
        setDeleteId({speakerId:ev.speakerId,speechId:ev.speechId});
    }


    const onEdit = item => {
        Client.get(`/Mobile/SpeechBySpeechId?languageCode=${objectArray.languageCode}&speakerId=${item.speakerId}&speechId=${item.speechId}`)
            .then(async ({ data }) => {
                try {
                    window.scrollTo(0, 0);
                    const response = await CatgoryList(APITOKEN, objectArray.languageCode, data.categoryId);
                    SetSubCatogry(catFilter(response.data));
                    const catgoriesArray = data?.subCategoryIds?.split(',') || [];
                    const newone = catgoriesArray.map(x => {
                        let result = [];
                        const newones = response.data.filter(y => parseInt(x) === y.id);
                        result = result.length === 0 ? newones[0] : [...result, newones]
                        return result;
                    });
                    const categoreyList = catFilter(newone);

                    setObject(prv => ({
                        ...prv,
                        speechId: data.speechId,
                        categoryId: data.categoryId,
                        title: data.title,
                        publishMonth: data.publishMonth,
                        speakerId: data.speakerId,
                        zZ_articleId: data.zZ_articleId,
                        subCategoryId: categoreyList

                    }));
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                throw err;
            });

        setYearNumber(prv => ({
            ...prv,
            selected: item.publishYear
        }))

    }

    const reset = () => {
        setObject(prv => ({
            ...prv,
            speechId: 0,
            languageCode: "",
            title: "",
            speakerId: "",
            zZ_articleId: "",
            publishYear: "",
            publishMonth: "",
            categoryId: "",
            subCategoryId: []
        }));
        setYearNumber(prv => ({ ...prv, selected: "" }))
        setDeleteId({speakerId:"",speechId:""});
    }
    const getCatgoryFn = async (event) => {
        const { value } = event.target;
        const { data } = await CatgoryList(APITOKEN, objectArray.languageCode, value);
        setObject(prv => ({
            ...prv,
            categoryId: parseInt(value),
            subCategoryId: []
        }));

        SetSubCatogry(catFilter(data))
    }

    useEffect(() => {
        load();
        yearsLoad();
    }, [load, yearsLoad])

    return (
        <>
            {deleteId.speakerId === "" &&
                <div className="bx-boder-common white width100 inline-block">
                    <div className="_h_header">
                        <h3>Speech</h3>
                    </div>
                    <div className="padding20">
                        <div style={{ maxWidth: '492px' }}>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Select language</label>
                                <select className="inputformmodel" value={objectArray.languageCode} onChange={e => onChangeFn(e.target.value)}>
                                    <option value="">Select</option>
                                    {lang.data.map((v, i) => <option value={v.LanguageCode} key={i}>{v.Value}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Select Speaker</label>
                                <select className="inputformmodel" value={objectArray.speakerId} onChange={e => {
                                    setObject(prv => ({
                                        ...prv,
                                        speakerId: parseInt(e.target.value)
                                    })); onSpeechSpeakder(e.target.value)
                                }}>
                                    <option value="">Select</option>
                                    {lang.Speaker.map((v, i) => <option value={v.speakerId} key={i}>{v.speakerName}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Article Id</label>
                                <input type="text" className="inputformmodel" placeholder="Article Id" value={objectArray.zZ_articleId} onChange={e => setObject(prv => ({
                                    ...prv,
                                    zZ_articleId: e.target.value
                                }))} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Speech Title</label>
                                <input type="text" className="inputformmodel" placeholder="Title" value={objectArray.title} onChange={e => setObject(prv => ({
                                    ...prv,
                                    title: e.target.value
                                }))} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Publish year</label>
                                <select className="inputformmodel"
                                    value={yearNumber.selected}
                                    onChange={e => setYearNumber(prv => ({ ...prv, selected: e.target.value }))}>
                                    <option value="">Select</option>
                                    {yearNumber.data.map((v, i) => <option key={i} >{v}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Publish Month</label>
                                <select className="inputformmodel" value={objectArray.publishMonth} onChange={e => setObject(prv => ({
                                    ...prv,
                                    publishMonth: e.target.value
                                }))}>
                                    <option value="">Select</option>
                                    {years.map((v, i) => <option value={v.value} key={i}>{v.name}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Category</label>
                                <select className="inputformmodel" onChange={getCatgoryFn} value={objectArray.categoryId}>
                                    <option value="">Select</option>
                                    {categoriesList.map((item, index) => <option key={index} value={item.id}>{item.value}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Sub Category</label>
                                <Select

                                    value={objectArray.subCategoryId}
                                    isMulti
                                    name="language"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={onChange}
                                    options={subCatgory}
                                />
                            </div>
                        </div>
                        <div className="formmodel-bx text-right">
                            <button type="button" className="grid_btn_1 nobg noborder" onClick={() => {
                                setObject(prv => ({
                                    ...prv,
                                    speechId: 0,
                                    title: "",
                                    zZ_articleId: "",
                                    publishYear: "",
                                    publishMonth: "",
                                    categoryId: "",
                                    speakerId: "",
                                    languageCode: "",
                                    subCategoryId: []
                                }));
                                setSpeakerSpeeches([]);
                                speakerAPI("");
                            }
                            }>Cancel</button>
                            <button type="button" className="grid_btn_1 grid_btn_active" disabled={
                                (objectArray.title !== "" && objectArray.zZ_articleId !== 0 && yearNumber.selected !== "" && objectArray.publishMonth !== "" &&
                                    objectArray.categoryId !== "" && objectArray.languageCode !== "") ? false : true
                            } onClick={Save}><ButtonLoader IsLoader={loader}>{objectArray.speechId !== 0 ? 'Update' : 'Save'}</ButtonLoader></button>
                        </div>
                    </div>
                    {speakerSpeeches.length > 0 ? <GridLanguage
                        GridData={speakerSpeeches}
                        OnDelete={landDelete}
                        onEdit={onEdit}
                    /> : <></>

                    }
                </div>
            }
            {speakerSpeeches.length > 0 ?
                <>
                    {deleteId.speakerId !== "" ? <div className="bx-boder-common white width100 inline-block"> <div className="_h_header">
                        <h3>Delete</h3>
                    </div><table
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%">
                            <tbody>
                                <BlankData title="Are you sure you want to delete this speech?" content="" />
                            </tbody>
                        </table>
                        <div className="text-right padding20">
                            <button className="grid_btn_1 noborder nobg" onClick={() => { setDeleteId({speakerId:"",speechId:""}); reset() }} type="button">Cancel</button>
                            <button className="grid_btn_1 deleteBtn" onClick={confirmDelete} type="button">Confirm</button>
                        </div>
                    </div> : <></>
                    }
                </>

                : <></>
            }
        </>

    )
}
export default Speeches

function GridLanguage({ GridData, OnDelete, onEdit }) {
    return (
        <div className="b_top">
            <table cellPadding="0" cellSpacing="0" className="smstable ">
                <thead>
                    <tr>
                        <th className="table_cell_header">
                            Speech Title
                        </th>
                        <th className="table_cell_header">
                            Article Id
                        </th>
                        <th className="table_cell_header">
                            Publish Month
                        </th>
                        <th className="table_cell_header">
                            Publish Year
                        </th>
                        <th className="table_cell_header">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {GridData && GridData.map((item, index) => {
                        return <tr key={index}>
                            <td className="body_table_cell">
                                {item.title}
                            </td>
                            <td className="body_table_cell">
                                {item.zZ_articleId}
                            </td>
                            <td className="body_table_cell">
                                {item.publishMonth}
                            </td>
                            <td className="body_table_cell">
                                {item.publishYear}
                            </td>
                            <td className="body_table_cell">
                                <button type="button" className="link" onClick={() => onEdit(item)}>Edit</button>
                                <button type="button" className="link" onClick={() => OnDelete(item)}>Delete</button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}
