import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DownLoadData } from '../../../DownloadChartJSON';
import HC_exporting from 'highcharts/modules/exporting';

HC_exporting(Highcharts)
const BestTime = ({...props}) => {
    const {APITOKEN} = useSelector(state => state);
    const [bestDayData, setBestDay] = useState("");
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 });
    const chart = useRef();
    const [downloadData, setDownloadData] = useState({
        data: DownLoadData,
        show: false
    });
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {

                colorByPoint: true,
                name: "BestTime",
                pointWidth: 30,
            }
        ]
    });
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/distribution/Email/BestTime/Connect?queueId=${props.id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                dataFilter(res['data'])
            }).catch(err => console.error(err));
            function handleWindowClick(){
                setDownloadData(prv => ({
                    ...prv,
                    show: false
                }))
            }
            window.addEventListener('click', handleWindowClick, false);
            return (() => {
                window.removeEventListener('click', handleWindowClick, false);
            })
    }, [APITOKEN,props.id]);

    const dataFilter = (data) => {
        const ar = [];
        data.forEach(async function (v, i) {
            ar.push({ name: v['HitsSlotname'], y: v['Percentage'] });

        });
        const maxXObjects = data.filter(function (o) { return o.Hits === Math.max.apply(Math, data.map(function (o) { return o.Hits; })) });
        setBestDay(maxXObjects[0].DisplaySlotNameText);
        setOptionValue({ series: [{ data: ar }] });
    }
    const exportChart = (e) => {
        e.stopPropagation();
        setDownloadData(prv => ({
            ...prv,
            show: !prv.show
        }))
    }
    const downloadImg = ev => {
        chart.current.chart.exportChart({
            type: ev.value
        })
    }

    return (
        <>
            <button type="button" className="graphProperties" onClick={exportChart} data-id="dailyrutine"><i className="fal fa-ellipsis-h"></i></button>
            {downloadData.show ?
                <div className="graphproperties-bx">
                    {downloadData['data'].map((v, i) => <div key={i} className="link grpahbx" onClick={() => downloadImg(v)}>{v.name}</div>)}
                </div>
                : <></>}
            <h3 className="_chart_title padding-top20 padding-bottom20">Best Time to Send Email</h3><p>Based on this Email sending.</p><h3 className="chart_t link  -bestti">{bestDayData}</h3><HighchartsReact highcharts={Highcharts} ref={chart} options={optionData} />
        </>
    )
}
export default BestTime;