import React, { useState } from 'react';

import UploadContacts from './components/UploadContacts';
import ImportRules from './components/ImportRules';
import Compliance from './components/Compliance';
import Preview from './components/Preview';
import Summary from './components/Summary';
import Mapping from './components/Mapping';

const ImportContacts = () => {

  const [activeTab, setActiveTab] = useState("upload_contacts");

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <ul className="list-import">
          <li className={((activeTab || "") === "upload_contacts") ? "active" : ""}>
            <p>Upload</p>
            <span className="front"></span>
          </li>
          <li className={((activeTab || "") === "preview") ? "active" : ""}>
            <span className="back"></span>
            <p>Preview</p>
            <span className="front"></span>
          </li>
          <li className={((activeTab || "") === "mapping") ? "active" : ""}>
            <span className="back"></span>
            <p>Mapping</p>
            <span className="front"></span>
          </li>
          <li className={((activeTab || "") === "import_rules") ? "active" : ""}>
            <span className="back"></span>
            <p>Import Rules</p>
            <span className="front"></span>
          </li>
          <li className={((activeTab || "") === "compliance") ? "active" : ""}>
            <span className="back"></span>
            <p>Compliance</p>
            <span className="front"></span>
          </li>
          <li className={((activeTab || "") === "summary") ? "active" : ""}>
            <span className="back"></span>
            <p>Summary</p>
          </li>
        </ul>

        {((activeTab || "") === "upload_contacts") && (
          <UploadContacts sendDataToParent={(data) => setActiveTab(data)} />
        )}

        {((activeTab || "") === "preview") && (
          <Preview sendDataToParent={(data) => setActiveTab(data)} />
        )}

        {((activeTab || "") === "mapping") && (
          <Mapping sendDataToParent={(data) => setActiveTab(data)} />
        )}

        {((activeTab || "") === "import_rules") && (
          <ImportRules sendDataToParent={(data) => setActiveTab(data)} />
        )}

        {((activeTab || "") === "compliance") && (
          <Compliance sendDataToParent={(data) => setActiveTab(data)} />
        )}

        {((activeTab || "") === "summary") && (
          <Summary sendDataToParent={(data) => setActiveTab(data)} />
        )}
      </div>
    </>
  )
}

export default ImportContacts;