import React, { useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useLocation } from 'react-router-dom';
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";

import ProfileTab from "./components/ProfileTab";
import Communication from "./components/Communication";
import Notes from "./components/Notes";
import Activity from "./components/Activity";
import { getProfileDetails, getPeopleCurrentLoyaltyLevelScoreDetail } from "../../../../services/crm";

const Profile = () => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from UR
  const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL
  const queryParams = new URLSearchParams(window.location.search);
  const peopleId = queryParams.get('id');
  const peopleSubscriberId = queryParams.get('pId');
  const previoudId = queryParams.get('previousId');
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("profile");
  const [profileDetails, setProfileDetails] = useState({});
  const [loyaltyScoreData, setLoyaltyScoreData] = useState([]);
  const [refreshProfileData, setRefreshProfileData] = useState(false);

  useEffect(() => {
    (async () => {
      try {

        let payload = {
          PeopleId: (peopleId || 0),
          ListId: listID,
          PeopleSubscriberId: (peopleSubscriberId || 0),
        };

        const response = await getProfileDetails(payload);

        setProfileDetails(_get(response, "data.data.CustomProfile[0]", []));
        setRefreshProfileData(false);
        //Loyalty ProgramFields Section API
        const respLoyaltyScore = await getPeopleCurrentLoyaltyLevelScoreDetail(peopleSubscriberId);
        setLoyaltyScoreData(_get(respLoyaltyScore, "data", []));

      } catch (error) {
      }
    })();
  }, [peopleId, listID, peopleSubscriberId, previoudId, refreshProfileData]);
  console.log("location: ", location);
  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum
            ToText="CRM List"
            To="/crm/people/listings"
            ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
            SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts ") }}
            ToSecondPage={`/crm/people/subscribers/${_get(location, "state.selectedListId", "")}/${creationSource}`}
            //To={`/crm/people/subscribers/${listID}/${creationSource}`}
            CurrentPage="Profile"
          />
        </div>

        <div className="mycontent">
          <ul className="tabing">
            <li><span className={((activeTab || "") === "profile") ? "active" : ""} onClick={() => setActiveTab("profile")}>Profile</span></li>
            <li><span className={((activeTab || "") === "communication") ? "active" : ""} onClick={() => setActiveTab("communication")}>Communication</span></li>
            <li><span className={((activeTab || "") === "notes") ? "active" : ""} onClick={() => setActiveTab("notes")}>Notes</span></li>
            <li><span className={((activeTab || "") === "activity") ? "active" : ""} onClick={() => setActiveTab("activity")}>Activity</span></li>
          </ul>

          <span className="fright">
            <img width="40" src={_get(loyaltyScoreData, "[0].badgeImagePath", "")} alt="" />
          </span>

          <h3 className="font-16 fw-medium fright me-2 mt-3">{`${!_isEmpty(_get(profileDetails, "Title", "")) ? _get(profileDetails, "Title", "") : ""} ${!_isEmpty(_get(profileDetails, "FirstName", "")) ? _get(profileDetails, "FirstName", "") : ""} ${!_isEmpty(_get(profileDetails, "LastName", "")) ? _get(profileDetails, "LastName", "") : ""}`}</h3>
        </div>

        <div className="clearfix"></div>

        {(((activeTab || "") === "profile")) && (
          <ProfileTab
            loyaltyScoreData={loyaltyScoreData}
            refreshProfileData={(args) => setRefreshProfileData(true)}
            currentListName={_get(location, "state.selecteListName", "All Contacts ")}
          />
        )}

        {(((activeTab || "") === "communication")) && (
          <Communication />
        )}

        {(((activeTab || "") === "notes")) && (
          <Notes />
        )}

        {(((activeTab || "") === "activity")) && (
          <Activity />
        )}
      </div>
    </>
  )
}

export default Profile;