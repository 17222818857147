import React, { useState }  from 'react';
import { Link } from 'react-router-dom';
import OverView from './Overview';
import SMSSending from './SMSSending';

const Tabing = ({ data }) => {
    const [isDisplay, setDisplay] = useState(true);
    const defaultId = 31;
    const [tabId, setTabid] = useState({id: defaultId, totalNumber: 0 });
    const clickmethod = item => {
        setTabid({
            id: item.id,
            totalNumber: item.id
        });
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index}
                            onClick={() => clickmethod(item)}>
                            <Link className={item.id === tabId.id ? 'active ' : ' '} id={item.id}>{item.name}</Link>
                        </li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId.id === defaultId ?
                    <OverView tabId={tabId} setDisplay={setDisplay} setTabid={clickmethod}  isDisplay={isDisplay} /> :  <SMSSending/> }
                </div>
            }
        </>
    )
}

export default Tabing;