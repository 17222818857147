import React, { useCallback, useEffect, useReducer, useState } from 'react'
// import Client from '../../../GlobalAPICall';
import { useSelector } from 'react-redux';

import '../index.css'
import CreatePlayList from './CreatePlayList';
import Client from '../../../GlobalAPICall';
import axios from 'axios';
import PlayListGrid from './PlayListGrid';
import TrackOpen from './TrackOpen';
import ViewPanel from './ViewPanel';
import DeleteListTemplate from '../../../ReuseTemplate';
import BlankData from '../../../BlankData';
import Languages from '../Track/Languages';

// const client = filestack.init('APBcz9XF7TEmEQpcF5wmxz');

function Reducer(state, action) {
    switch (action.type) {
        case 'CREATE':
            return { ...state, ...action.payload }
        case 'MEDIA':
            return { ...state, ...action.payload }
        case 'SPEAKER':
            return { ...state, ...action.payload }
        case 'TRACK':
            return { ...state, ...action.payload }
        case 'PLAYLIST':
            return { ...state, ...action.payload }
        case 'SPEECH':
            return { ...state, ...action.payload }
        case 'OPEN':
            return { ...state, ...action.payload }
        case 'VIEW':
            return { ...state, ...action.payload }
        case 'DELETEOPEN':
            return { ...state, ...action.payload }

        default:
            return state
    }
}


export default function PlayList() {
    const { APITOKEN } = useSelector(state => state);
    const [state, dispatch] = useReducer(Reducer, { display: false })
    const [lang, SetLang] = useState({
        data: [],
        Speaker: []
    });
    const [selectedObject, setObject] = useState({
        languageCode: "",
        speakerId: "",
        speechId: "",
        mediaId: "",
        videoId: "",
        trackIdArray: [],
        buttonShow: false,
    })

    const createPlaylist = () => {
        dispatch({ type: 'CREATE', payload: { display: true, flagTitle: 'Create' } })
    }

    const languageCode = useCallback(async () => {
        if (APITOKEN['token'] !== "") {
            const { data } = await Languages(APITOKEN); //  ** Languages API CALL   ** //
            try {
                if (data.length > 0) {
                    SetLang(prv => ({
                        ...prv,
                        data
                    }));
                }
            } catch (err) {
                console.error(err);
            }
        }
        return (() => {
            SetLang({
                data: [],
                Speaker: []
            });
        });
    }, [APITOKEN])


    const speakerAPI = newValue => {
        setObject(prv => ({
            ...prv,
            languageCode: newValue
        }))
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/Speakers?languageCode=${newValue}`, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                SetLang(prv => ({
                    ...prv,
                    Speaker: data
                }));
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
        });
    }


    const playListLoad = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/AdminPlayList`).then(({ data }) => {
            try {
                dispatch({ type: 'PLAYLIST', payload: { playList: data } });
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
        });

    }, [])
    const onSpeechSpeakder = useCallback((ev) => {
        Client.get(`Mobile/SpeechsBySpeakerId?languageCode=${selectedObject.languageCode}&speakerId=${parseInt(ev)}`).then(({ data }) => {
            try {
                dispatch({ type: 'SPEECH', payload: { speakderData: data } });
            } catch (err) {
                dispatch({ type: 'SPEECH', payload: { speakderData: [] } });
            }
        }).catch(err => dispatch({ type: 'SPEECH', payload: { speakderData: [] } }));

    }, [selectedObject]);

    const trackAPI = ev => {
        const cancelToken = axios.CancelToken.source();
        Client.get(`Mobile/${selectedObject.mediaId === 1 ? 'TracksBySpeechId' : 'VideosBySpeakerId'}?languageCode=${selectedObject.languageCode}${selectedObject.mediaId === 1 ? `&speechId=${ev}` : `&speakerId=${ev}`}`, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                dispatch({ type: 'MEDIA', payload: { trackData: data, selected: "" } });
            } catch (err) {
                dispatch({ type: 'MEDIA', payload: { trackData: [], selected: "" } });
            }
        }).catch(err => dispatch({ type: 'MEDIA', payload: { trackData: [] } }));
    }
    const reSet = () => {
        dispatch({
            type: 'CREATE', payload: {
                display: false,
                loader: false,
                name: "",
                description: "",
                imageUrl: "",
                listType: "",
                mediaDisabled: false,
                playListId: 0,
                flagTitle:""
            }
        });
        setObject(prv => ({
            ...prv,
            languageCode: "",
            speakerId: "",
            speechId: "",
            mediaId: "",
            trackId: "",
            playListId:0
        }))
    }
    const confirmDelete = () => {
        Client.delete(`Mobile/AppPlaylists?playListId=${selectedObject.playListId}&peopleSubscriberId=0`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                if (data.Success) {
                    playListLoad();
                    dispatch({
                        type: 'DELETEOPEN', payload: {
                            Deletedisplay: false,
                        }
                    })
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
    }

    useEffect(() => {
        languageCode();
        playListLoad();
    }, [languageCode, playListLoad])
    return (
        <>{!state.Deletedisplay ?
            <div className="bx-boder-common white width100 inline-block">
                <div className="_h_header">
                    <h3>Playlist</h3>
                    <span className='flex-right'>
                        <button className='grid_btn_1 grid_btn_active' onClick={createPlaylist}>Create Playlist</button>
                    </span>
                </div>
                <div className="">

                    <div className=''>
                        {state.playList && state.playList.length > 0 ?
                            <PlayListGrid Data={state.playList} dispatch={dispatch} setObject={setObject} reSet={reSet} SetLang={SetLang} />
                            : <table cellPadding="0" cellSpacing="" width="100%" className="defaultborder">
                                <tbody>
                                    <BlankData
                                        title="Playlist"
                                        content="No PlayList is added yet !!"
                                        colSpan="2" />
                                </tbody>
                            </table>}
                    </div>


                    {!!state.open ? <div className="createMaskingPlaylist">
                        <div>
                            <TrackOpen
                                dispatch={dispatch}
                                setObject={setObject}
                                state={state}
                                speakerAPI={speakerAPI}
                                trackAPI={trackAPI}
                                lang={lang}
                                selectedObject={selectedObject}
                                onSpeechSpeakder={onSpeechSpeakder}
                                APITOKEN={APITOKEN}
                                reSet={reSet}
                                playListLoad={playListLoad}
                            />

                        </div>

                    </div> : <></>}


                </div>
            </div>
            : <DeleteListTemplate
                title="Playlist"
                content="Are you sure you want to delete this playlist?"
                colspan="2"
                deleteDownload={() => confirmDelete()}
                onClick={() => dispatch({
                    type: 'DELETEOPEN', payload: {
                        Deletedisplay: false,
                    }
                })}
            />}
            {state.display && <CreatePlayList dispatch={dispatch} state={state} lang={lang} APITOKEN={APITOKEN} playListLoad={playListLoad} setObject={setObject} reSet={reSet} />}
            {state.viewDisplay && <ViewPanel dispatch={dispatch} state={state} APITOKEN={APITOKEN} object={selectedObject} />}
        </>
    )
}
