import axios from "axios";
import Client from "../../../GlobalAPICall";

const Languages = APITOKEN => {
    const cancelToken = axios.CancelToken.source();
    const response =  Client.get(`Mobile/Languages?viewAll=false`, {
        cancelToken: cancelToken.token,
        headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
        }
  })
    return response;
}
export default Languages;