import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom'
import BlankData from "../../../../BlankData";
import Client from "../../../../GlobalAPICall";
import DeleteListTemplate from "../../../../ReuseTemplate";

const UTMCampign = () => {

    const checkIsAuth = useSelector(state => state);
    const [templateData, setTemplateData] = useState([]);
    const [formVal, setFormVal] = useState('');
    let [deleteFlag, setDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const load = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`UTM/GetUTMCampaign`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                try {
                    setIsLoading(false)
                    setTemplateData(res['data']);
                } catch (err) {
                    console.error(err);
                }
            });
    }, [checkIsAuth]);

    useEffect(() => {
        load();
    }, [load])

    const deleteFN = (v) => {
        setDelete(true);
        setFormVal(v.rowUID);
    }
    const deleteConfirm = () => {
        Client.delete(`UTM/DeleteUTMCampaign/${formVal}`, {
            data: {},
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        }).then((res) => {
            try {
                if (res.data.success) {
                    setDelete(false);
                    load();
                    setFormVal('');
                }
            } catch (err) {
                console.error(err);
            }
        });
    }
    return (<>
        {deleteFlag ? <div className="margin-top50 inline-block width100">
            <DeleteListTemplate
                onClick={() => setDelete(false)}
                deleteDownload={deleteConfirm}
                title="Click on Confirm to delete this UTM Campaign."
                content="This will permanently delete and it can not be restored." />
        </div> :
            <div className="sms_Sec_1 bx-boder-common width100 nobg">
                <div className="_h_header white border-radius2">
                    <h3>UTM Medium</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 grid_btn_active _template_grid" onClick={() => navigate('/create/templates/UTMcampaign')}>Create UTM Campaign</button>
                    </span>
                </div>
                <div className="_list_fx">
                    <table className="smstable white" cellPadding="0" cellSpacing="0">
                        {templateData.length > 0 &&
                            <thead>
                                <tr>
                                    <th className="table_cell_header">Content Name</th>
                                    <th className="table_cell_header">Description</th>
                                    <th className="table_cell_header">Creation Time</th>
                                    <th className="table_cell_header">Created By</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {!isLoading &&
                                templateData.length === 0 ? <BlankData
                                title="You have not created any UTM Template."
                                content="Campaign Create UTM to easily measure and visualize the success of your campaigns and contacts build." colSpan="5" /> : templateData && templateData.map((item, i) => <Grid item={item} key={i} Deleteclick={deleteFN} />)
                            }
                        </tbody>

                    </table>
                </div>
            </div>
        }
    </>)
};
export default UTMCampign;

const Grid = ({ item, Deleteclick }) => {
    const navigate = useNavigate();

    return (
        <><tr><td className="body_table_cell excludeClass">{item.name}</td><td className="body_table_cell">{item.description}</td><td className="body_table_cell">{item.creationTime}</td><td className="body_table_cell">{item.createdBy}</td><td className="body_table_cell"><button type="button" className="link inline-block utm_tem_edit" onClick={() => navigate(`/create/templates/UTMcampaign?id=${item.rowUID}`)} >Edit</button><button type="button" className="link inline-block" onClick={() => Deleteclick(item)}>Delete</button></td></tr></>
    )
}