import { arrayMoveImmutable } from "array-move";
import React from "react";
import SortableList, { SortableItem } from "react-easy-sort";
import SortableOrder from "../SortableOrder";
import { ToastContainer, toast } from "react-toastify";

export default function PlayListGrid({
  Data,
  dispatch,
  setObject,
  reSet,
  SetLang,
}) {
  const onSortEnd = async (oldIndex, newIndex) => {
    const reorder = arrayMoveImmutable(Data, oldIndex, newIndex);
    const postObject = reorder.map(({ playListId }, index) => ({
      id: playListId,
      order: index + 1,
    }));
    debugger;
    dispatch({ type: "PLAYLIST", payload: { playList: reorder } });
    const { data } = await SortableOrder(
      { Id: 0, Type: "PlayList", Code: "en" },
      postObject
    );
    if (data.Success) {
      toast.success("Save successfully", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
        <table cellPadding="0" cellSpacing="0" className="smstable">
          <thead>
            <tr>
              <th className="table_cell_header" width="8%">
                &nbsp;
              </th>
              <th className="table_cell_header">Title</th>
              <th className="table_cell_header">Media Name</th>
              <th className="table_cell_header">Description</th>
              <th className="table_cell_header">language</th>
              <th className="table_cell_header" width="10%">
                Count
              </th>
              <th className="table_cell_header">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Data &&
              Data.map((item, index) => {
                return (
                  <SortableItem key={index}>
                    <tr>
                      <td className="body_table_cell">
                        <img src={item.image} alt={item.title} width={30} />
                      </td>
                      <td className="body_table_cell noproperties">
                        {item.title}
                      </td>
                      <td className="body_table_cell">{item.typeName}</td>
                      <td className="body_table_cell ">{item.description}</td>
                      <td className="body_table_cell ">{item.languageCode}</td>
                      <td className="body_table_cell ">{item.count}</td>
                      <td className="body_table_cell">
                        <button
                          className="inline-block link nobg noborder pointer"
                          onClick={() => {
                            dispatch({
                              type: "OPEN",
                              payload: {
                                showMedia: item.type === 1 ? true : false,
                                open: true,
                              },
                            });
                            SetLang((prv) => ({ ...prv, Speaker: [] }));
                            dispatch({
                              type: "MEDIA",
                              payload: { trackData: [] },
                            });
                            reSet();
                            setObject((prv) => ({
                              ...prv,
                              mediaId: item.type,
                              playListId: item.playListId,
                            }));
                          }}
                        >
                          Add{" "}
                        </button>
                        <button
                          className="inline-block link _edit_template noborder nobg pointer"
                          onClick={() =>
                            dispatch({
                              type: "CREATE",
                              payload: {
                                display: true,
                                name: item.title,
                                description: item.description,
                                imageUrl: item.image,
                                listType: item.typeName === "Audio" ? "1" : "2",
                                flagTitle: "Update",
                                playListId: item.playListId,
                                language: item.languageCode,
                                mediaDisabled: true,
                              },
                            })
                          }
                        >
                          Edit
                        </button>
                        <button
                          className="inline-block link nobg noborder pointer"
                          onClick={() => {
                            dispatch({
                              type: "VIEW",
                              payload: { viewDisplay: true },
                            });
                            setObject((prv) => ({
                              ...prv,
                              mediaId: item.type,
                              playListId: item.playListId,
                            }));
                          }}
                        >
                          View
                        </button>

                        <button
                          className="inline-block link nobg noborder pointer"
                          onClick={() => {
                            dispatch({
                              type: "DELETEOPEN",
                              payload: {
                                Deletedisplay: true,
                              },
                            });
                            setObject((prv) => ({
                              ...prv,
                              mediaId: item.type,
                              playListId: item.playListId,
                            }));
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </SortableItem>
                );
              })}
          </tbody>
        </table>
      </SortableList>
      <ToastContainer className="ToastCustom" />
    </>
  );
}
