import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import AutoCompleteInput from "./AutoCompleteInput";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const PrefferedSetting = () => {
  const { APITOKEN } = useSelector((state) => state);
  const [autoCompleteToggle, setAutoCompleteToggle] = useState(false);
  const [locale, setLocale] = useState("");
  const [validationCheck, setValidation] = useState(true);
  const [loader, setLoader] = useState(false);
  const [filterData, setFilterData] = useState({
    subdomains: "",
    TimeZone: "",
    landingpageurl: "",
    isdomainreadonly: true,
    IsNewsLetterAccepted: false,
  });
  const [dateFormatData, setDateFormate] = useState([]);
  const [setingData, setSettingData] = useState({
    timeZone: [],
    dateFormat: "",
  });
  const [dateFormateValue, setDateFormateValue] = useState("");
  const timezone = useCallback(() => {
    APITOKEN["token"] !== "" &&
      Client.get("Lookup/AvailableTimeZones", {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then((res) => {
        setSettingData({ timeZone: res["data"] });
      });
  }, [APITOKEN]);
  const languageWelot = useCallback(() => {
    var myDiv = document.getElementById("_lang");
    myDiv.innerHTML = "";
    if (!window._DEFAULT_Weglot) {
      return;
    }
    //Create array of options to be added
    var availableLanguages = window._DEFAULT_Weglot.options.languages
      .map(function (language) {
        return language.language_to;
      })
      .concat(window._DEFAULT_Weglot.options.language_from);

    //Create and append select list
    var selectList = document.createElement("select");
    selectList.id = "__language";
    selectList.classList.add("inputformmodel");
    selectList.classList.add("width317");
    myDiv.appendChild(selectList);

    var currentLang = window._DEFAULT_Weglot.getCurrentLang();
    const langu_ = APITOKEN.config.subscriptionInfo.UserLanguage;
    if (langu_ !== "en") {
      currentLang = langu_;
      window.selectListLang = langu_;
      window._DEFAULT_Weglot.switchTo(currentLang);
    }
    //Create and append the options
    setTimeout(function () {
      for (var i = 0; i < availableLanguages.length; i++) {
        var lang = availableLanguages[i];
        var option = document.createElement("option");
        option.value = lang;
        option.text = window._DEFAULT_Weglot.getLanguageName(lang);
        if (lang === currentLang) {
          option.selected = "selected";
          window.selectListLang = lang;
        }
        selectList.appendChild(option);
      }
    }, 1500);

    selectList.onchange = function () {
      window._DEFAULT_Weglot.switchTo(this.value || currentLang);
      window.selectListLang = this.value || currentLang;
      setValidation(false);
    };

    window._DEFAULT_Weglot.on("languageChanged", function (lang) {
      selectList.value = lang;
      window.selectListLang = lang;
      setValidation(false);
    });
  }, [APITOKEN]);
  const [response, setResponse] = useState({});
  const filterName = useCallback((response, locale) => {
    const filter = response.filter((x) => x.CultureCode === locale);
    return filter.length > 0
      ? filter[0].CountryName + " " + filter[0].DateFormat
      : "";
  }, []);
  const dateFormat = useCallback(
    (local) => {
      APITOKEN["token"] !== "" &&
        Client.get(
          `Lookup/OnBoardingPrefferedSettingsLocale/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
          {
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        ).then(({ data }) => {
          try {
            setDateFormateValue(filterName(data, local));
            setDateFormate(data);
          } catch (err) {
            console.log(err);
          }
        });
    },
    [APITOKEN, filterName]
  );

  useEffect(() => {
    APITOKEN["token"] !== "" &&
      Client.get("Lookup/OnBoardingSteps/app.j.com/10/GetStepsSavedInfo", {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then(async (res) => {
        try {
          const newArrayObject = res["data"].filter(
            (x) => x["stepId"] === "10"
          );
          if (newArrayObject.length > 0) {
            await setFilterData(newArrayObject[0]);
            // setLocale(newArrayObject[0].Locale);
            await dateFormat(newArrayObject[0].Locale);
          }
        } catch (err) {
          console.log(err);
        }
      });
    timezone();
    languageWelot();
  }, [APITOKEN, timezone, dateFormat, languageWelot]);

  const preferredSave = () => {
    const requestPayload = {
      subDomains: filterData.subdomains,
      language: window.selectListLang,
      timezone: filterData.TimeZone,
      locale: locale,
      isNewsLetterAccepted: filterData.IsNewsLetterAccepted,
      isDomainReadOnly: filterData.isdomainreadonly,
    };
    setLoader(true);
    APITOKEN["token"] !== "" &&
      Client.post(`User/PrefferedSettings`, requestPayload, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          setLoader(false);
          try {
            setResponse(res.data);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => console.error(err));
  };
  const autoComplete = (ev) => {
    if (ev === "") {
      setAutoCompleteToggle(false);
      dateFormat();
    } else {
      setAutoCompleteToggle(true);
      // if (ev.length <= 2) {
      //     dateFormat();
      // } else {
      const fil = dateFormatData.filter((x) =>
        x.CountryName.toLowerCase().includes(ev.toLowerCase())
      );
      setDateFormate(fil);
      // console.log(dateFormatData)
      // }
    }
  };
  const selectedValue = (v) => {
    setAutoCompleteToggle(false);
    setDateFormateValue(`${v.CountryName} ${v.DateFormat}`);
    setLocale(v.CultureCode);
    setValidation(false);
  };

  return (
    <>
      <div className="bx-boder-common white width100 inline-block">
        <div className="_h_header">
          <h3>Preferred settings</h3>
          {/* {JSON.stringify(filterData)} */}
        </div>
        <div className="padding20">
          <form>
            <div style={{ width: "492px" }}>
              <div className="formmodel-bx sx-mobile">
                <label className="_l_able_cls">
                  Set your unique sub-domain for your Landing Page URLs
                </label>
                <span className="drop-zone inline-block width100">
                  <span className="_default_domain_ex">https://</span>
                  <input
                    type="text"
                    style={{ paddingLeft: "70px" }}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        ...{ subdomains: e.target.value.replace(/\./g, "") },
                      });
                      setValidation(false);
                    }}
                    value={filterData.subdomains}
                    readOnly={filterData.isdomainreadonly}
                    className="inputformmodel readonly"
                  />
                  <em className="default__domain readonly">
                    {filterData.landingpageurl}
                  </em>
                </span>
              </div>
              <div className="formmodel-bx sx-mobile drop-zone">
                <label className="_l_able_cls">Language</label>
                <div id="_lang"></div>
              </div>
              <div className="formmodel-bx sx-mobile">
                <label className="_l_able_cls">Time Zone</label>
                <select
                  className="inputformmodel"
                  value={filterData.TimeZone}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      ...{ TimeZone: e.target.value },
                    });
                    setValidation(false);
                  }}
                >
                  <option value="">Select</option>
                  {setingData.timeZone.map((x, i) => (
                    <option key={i} value={x["Id"]}>
                      {x.Name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx sx-mobile drop-zone">
                <label className="_l_able_cls">Date Format</label>
                <AutoCompleteInput
                  value={dateFormateValue}
                  Data={dateFormatData}
                  Locale={filterData}
                  onChange={autoComplete}
                  onClick={selectedValue}
                  toggle={autoCompleteToggle}
                />
              </div>
              <div className="formmodel-bx sx-mobile">
                <label>&nbsp;</label>
                <input
                  type="checkbox"
                  className="inline-block vertical-middle"
                  value={filterData.IsNewsLetterAccepted}
                  onChange={(e) => {
                    setFilterData({
                      ...filterData,
                      ...{ IsNewsLetterAccepted: e.target.checked },
                    });
                    setValidation(false);
                  }}
                />
                <span className="inline-block sx-span pergbox">
                  I want to receive newsletters from{" "}
                  {APITOKEN.config &&
                    APITOKEN.config.subscriptionInfo.ThemeName}{" "}
                  with tips and tricks on how to use the platform.
                </span>
              </div>
              {response.Message !== undefined && (
                <p className="success">{response.Message}</p>
              )}
              <div className="formmodel-bx text-right margin-top50">
                <button
                  type="button"
                  className="grid_btn_1 grid_btn_active __Save_link"
                  disabled={validationCheck}
                  onClick={preferredSave}
                >
                  <ButtonLoader IsLoader={loader}>Save</ButtonLoader>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default PrefferedSetting;
