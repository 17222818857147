import { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';

export const SenderName = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const { ChangeEvent, Value, Array, SetSenderName, SmsSenderData, ReloadVar } = props;

    // Get All list from API Call
    const load = useCallback(() => {
        (APITOKEN.token !== "") &&
            Client.get('SMS/sendernames', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                // if (ReloadVar) {
                    SetSenderName(res['data']);
                    Array(res['data']);
                // }
            }).catch(err => console.error(err));
    }, [APITOKEN, Array, SetSenderName]);

    useEffect(() => {
        ReloadVar && load();
    }, [load,ReloadVar])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">SMS Sender Name</label>
            <select className="inputformmodel" value={Value.senderName} onChange={ChangeEvent}>
                <option value="">Select</option>
                {SmsSenderData.map((v, i) => (v.id !== -1) && <option key={i} value={v.senderValue}>{v.senderText}</option>)}
            </select>
        </div>
    )

}

