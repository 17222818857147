import Client from "../../GlobalAPICall";
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import getParameterByName from "../../ReuseTemplate/GetRoute";



const CreateGoogleTag = () => {
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const [campaignObject, setCamapignJson] = useState({
        data: [],
        selected: "",
    });
    // const [smsDetails, setSMSDetails] = useState([]);
    const [loader, setLoader] = useState(false);
    const [tagValue, setTagValue] = useState({
        applyAll: false,
        tagId: "GTM-",
    });
    const load = useCallback(() => {

        (APITOKEN['token'] !== "") &&
            Client.get('Connector/GetCampaignsDetailsForGTM?skip=0&limit=10000', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setCamapignJson(prv => ({
                            ...prv,
                            data,
                        }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN])
    // const filterData = respones => {
    //     return respones.filter(x => x.campaignId === getParameterByName('id', window.location.href))
    // }
    const loadCampaignDetails = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Connector/GetCampaignsDetailsForGTM?skip=0&limit=10000', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        const filter = data.filter(x => x.campaignId === getParameterByName('id', window.location.href));
                        setTagValue(prv => ({
                            ...prv,
                            tagId: filter[0].googleTagManagerID
                        }));
                        setCamapignJson(prv => ({
                            ...prv,
                            selected: filter[0].campaignId
                        }))
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN])
    const saveMethod = () => {
        const obj = {
            campaignId: campaignObject.selected,
            gtmCode: tagValue.tagId,
            isAddedToAllCampaigns: tagValue.applyAll
        };
        setLoader(true);
        (APITOKEN['token'] !== "") &&
            Client.post('Connector/Create_GTM', obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setLoader(false);
                        if (data.Success) {
                            navigate('/configDomain/tagsManager');
                        }

                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }

    useEffect(() => {
        if (getParameterByName('edit', window.location.href) !== null) {
            loadCampaignDetails();
        }
        load();

    }, [load, loadCampaignDetails])
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Google Tag Manager</h1>
                        <p className="sms_content">
                            Google Tag Manager helps you deploy and update measurement tags or tracking code on your websites and mobile apps without having to modify the code manually. Integrate your Google Tag Manager Account with our platform and ensure that you capture and track all click activity from your landing pages.
                        </p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Google Tag Manager</h3>
                    </div>
                    <div className="bx-pad">
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Select campaign</label>
                            <select className="inputformmodel width100" value={campaignObject.selected} onChange={ev => setCamapignJson(prv => ({
                                ...prv,
                                selected: ev.target.value
                            }))}>
                                <option value="">Select</option>
                                {campaignObject.data.map((v, i) => <option key={v.campaignId} value={v.campaignId}>{v.campaignName}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Google Tag Manager ID :</label>
                            <input type="text" value={tagValue.tagId} className="inputformmodel width100" onChange={ev => setTagValue(prv => ({
                                ...prv,
                                tagId: ev.target.value.indexOf('GTM-') !== -1 ? ev.target.value : 'GTM-'
                            }))} />
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls ">
                                <input type="checkbox" className="allcampaign inline-block vertical-middle" onChange={ev => setTagValue(prv => ({
                                    ...prv,
                                    applyAll: ev.target.checked
                                }))} />
                                <span className="inline-block vertical-middle">&nbsp;Apply to all campaigns</span>
                            </label>

                        </div>
                        <div className="formmodel-bx nomargin text-right">
                            <button type="button" disabled={
                                campaignObject.selected !== "" && tagValue.tagId !== "GTM-" ? false : true
                            } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={saveMethod}>
                                <ButtonLoader IsLoader={loader}>
                                    {getParameterByName('edit', window.location.href) !== null ? 'Update Integration' : 'Save Integration'}
                                </ButtonLoader>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default CreateGoogleTag;
