import SortableList, { SortableItem } from "react-easy-sort";
import LoaderBox from "../../ReuseTemplate/LoaderBox";
import InsightBlank from "../InsightBlank";
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import SpeakerChart from "./SpeakerChart";
import SpeechChart from "./SpeechChart";
import PlayListChart from "./PlayListChart";
import VideoChart from "./VideoChart";
import GridLoader from "../../ReuseTemplate/GridLoader";
import BlankData from "../../BlankData";
import { arrayMoveImmutable } from 'array-move';
import _ from "lodash";

const OverView = () => {
    const { APITOKEN } = useSelector(state => state);
    const [data, setdata] = useState([]);
    const [isDisplay, setDisplay] = useState(true);

    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/Mobile/Overview`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({data}) => {
                    const myOrderedResponse = _.sortBy(data, o => o.PinnedSequence);
                    setdata(myOrderedResponse);
                    setDisplay(false);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN]); // <-- add any missing dependencies react warns about

    const reSeQenceIsPinned = useCallback(async (newArray) => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.put(`Insights/dashboard/KPI/Pinned/ReSequence`,newArray, {
                 
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    if (data[0].Status) {
                        loadApiCall();
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN,loadApiCall]); // <-- add any missing dependencies react warns about

    const onSortEnd = (oldIndex, newIndex) => {
        const newdataPin = data.filter(x => x.IsPinned);
        const newArray = newdataPin.map(x => x.Id);
        // console.log(newArray)

        const reseq = arrayMoveImmutable(newArray, oldIndex, newIndex);
        setdata((array) => arrayMoveImmutable(array, oldIndex, newIndex));
        reSeQenceIsPinned(reseq);
    }
    const pinFn = oj => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.put(`Insights/dashboard/Mobile/KPI/${oj.IsPinned ? 0 : 1}/${oj.Id}`, {}, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    if (data[0].Status) {
                        loadApiCall()
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }

   
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            
            <div className="bx-boder-common"><div className="_insight_header white"><h3>Overview</h3><p className="content">Last 30 days aggregation. Green and red percent values are compared with previous 30 days.</p></div><div className={` white`}><div className=" white  ">{isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : data.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{data.map((v, i) => {
                return v.IsPinned && <SortableItem key={i}>
                
                <div className="bx-boder-common bx white detailsbx"><div className="drop-zone"><h3 className="title">{v['Name']}</h3><button type="button" className="pin" style={{ "fontSize": "15px", "color": v.PinRateColor, "fontWeight": 'bold', "marginTop": '8px' }} >{v.Rate}%</button><p className="content paddingbottom20">{v.PinnedDescription}</p></div><div className="px"><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody>
                    <tr>
                        <td>{v.CountLabel}</td>
                        <td className="-tvl" ><strong>{v['Count']}</strong></td>
                    </tr>
                    <tr>
                        <td>{v.RateLabel}</td><td className="-tvl link"><strong>{v['totalRecipients']}</strong></td></tr></tbody></table></div></div></div>
            </SortableItem>
        })}</SortableList>}</div>
        </div></div>
            <div className="overviewDaychart margin-top50 bx-boder-common white">
                <div className="chartharf  padding-right50"><h3 className="_chart_title padding-top20 padding-bottom20">Best Speaker</h3>
                    <p>Based on opens of your Speaker.</p>
                    <SpeakerChart />
                </div>
                <div className="chartharf "><h3 className="_chart_title padding-top20 padding-bottom20">Best Speech</h3>
                    <p>Based on opens of your Speech.</p>
                    <SpeechChart />
                </div>
            </div>
            <div className="overviewDaychart margin-top50 bx-boder-common white">
                <div className="chartharf "><h3 className="_chart_title padding-top20 padding-bottom20">Best Video</h3>
                    <p>Based on opens of your Video.</p>
                    <VideoChart />
                </div>
                <div className="chartharf  padding-right50"><h3 className="_chart_title padding-top20 padding-bottom20">Best Playlist</h3>
                    <p>Based on opens of your  Playlist.</p>
                    <PlayListChart />
                </div>

            </div><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>Aggregated Mobile Insights Last 30 Days</h3></div><div className="aggreategrid"><GridAggregated dataPin={data} IsDisplay={isDisplay} pinFn={pinFn} /></div></div></>
    )
}
export default OverView;


const GridAggregated = ({ dataPin, IsDisplay, pinFn }) => {
    return (
        <>
            {IsDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6]} /> :
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">{dataPin.length > 0 && <thead><tr><th className="table_cell_header">KPI</th><th className="table_cell_header">Description</th><th className="table_cell_header">Total</th><th className="table_cell_header">Unique User</th><th className="table_cell_header" width="20%">Compared With Previous 30 days</th><th className="table_cell_header">Action</th></tr></thead>}<tbody>{dataPin.length === 0 ? <BlankData title="The app has not been opened or installed." content="After opening or installed the app, you will find the insights here." colSpan="6" /> : dataPin.map((v, i) => <tr key={i}>
                    <td className="body_table_cell">{v['Name']}</td>
                    <td className="body_table_cell">{v['PinnedDescription']}</td>
                    <td className="body_table_cell">{v['Count']}</td>
                    <td className="body_table_cell">{v['totalRecipients']}</td>
                    <td className="body_table_cell" style={{ "color": v.PinRateColor, "fontWeight": 'bold' }} >{v['Rate']}%</td>
                    <td className="body_table_cell"><button type="button" className="link" onClick={() => pinFn(v)}>{v.IsPinned ? 'Remove Pin' : 'Pin'}</button></td>
                </tr>)}</tbody></table>
            }
        </>
    )
}
