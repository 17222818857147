
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import GenderPie from "./GenderPie";
import CityPie from "./CityPie";
import AgePie from "./AgePie";
import { CITY_COLOR, GENDER_COLOR } from "../../../constant/HightChartColor";

const Demographic = () => {
    const { id } = useParams();
    const {APITOKEN} = useSelector(state => state);
    const [demographicData, setDemographic] = useState();

    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            // Demographic Insight  ---- Tabing Information 
            Client.get(`Insights/distribution/SMS/${id}/Recipients/KPI?section=2`)
                .then(({ data }) => {
                    try {
                        const Data = JSON.parse(data[0].KPI || "[]");
                        const { Gender, City, Age } = Data[0];
                        const newArry = [...Gender.map((item, index) => ({ name: item['Value'], y: item['Count'], color: (item['Count'] === 0) ? '#ccc' : GENDER_COLOR[index] })) || []]
                        const cityArry = [...City.map((item, index) => ({ name: item['Value'], y: item['Count'], color: (item['Count'] === 0) ? '#ccc' : CITY_COLOR[index] })) || []]
                        const AgeArry = [...Age.map((item, index) => ({ name: item['Value'], y: item['Count'], color: (item['Count'] === 0) ? '#ccc' : CITY_COLOR[index] })) || []]

                        setDemographic([newArry, cityArry, AgeArry]);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    throw err;
                });
    }, [APITOKEN, id]);
    return (
        <>
            {demographicData &&
                <>
                    <GenderPie ChartData={demographicData[0]} />
                    <CityPie ChartData={demographicData[1]} />
                    <AgePie ChartData={demographicData[2]} />
                </>
            }
        </>
    )
}
export default Demographic;