import Client from "../GlobalAPICall";

export const AddFundRePayment = ({ obj, APITOKEN },cb) => {
    Client.post(`User/CreatePrepayment`, obj, {
        headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
        }
    })
        .then(({ data }) => {
            try {
                if (!!data['errorMessage']) {

                } else {
                    if (!!data.resp1) {
                        if (!!data['resp1'].action_link) {
                            const makeurl = `${data.resp1['action_link']}&callback_url=https://mypages.no/AuthCallback.aspx&redirect_url=https://mypages.no/AuthThankyou.aspx`;
                            window.open(makeurl, '_blank', 'location=yes,height=600,width=600,scrollbars=yes,status=yes');
                        } else {
                            if (!!data['resp1'].errors) {
                                // $(".wave").remove();
                                // $(".pay_char").show();
                            } else {
                                const makeurl = `${data.resp1['action_link']}&callback_url=https://mypages.no/AuthCallback.aspx&redirect_url=https://mypages.no/AuthThankyou.aspx`;
                                window.open(makeurl, '_blank', 'location=yes,height=600,width=600,scrollbars=yes,status=yes');
                            };
                        }
                    } else {
                        if (!!data.billingPortalLink) {
                            window.open(data['billingPortalLink'], '_blank');
                        }
                    }
                }
                cb(data);
                
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
}