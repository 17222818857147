import React, { useCallback, useEffect, useState } from "react";
import Client from "../GlobalAPICall";
import { useNavigate } from "react-router";
import BlankData from "../BlankData";
import DeleteListTemplate from "../ReuseTemplate";
import moment from "moment";
import getLocaleDateString from "../TimeFormat";

function ApprovalMakerRequest({ APITOKEN }) {
  const [getData, setData] = useState([]);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const [deleteRequestId, setDeleteRequestId] = useState("");

  const navigate = useNavigate();
  const load = useCallback(() => {
    APITOKEN.token &&
      Client.get(`/Email/pending/approvalrequest/Distributions`)
        .then(({ data }) => {
          setData(data);
        })
        .catch(err => {
          throw err;
        });
    return () => {
      setData([]);
    };
  }, [APITOKEN]);

  const deleteFn = () => {
    Client.delete(`SMS/${deleteRequestId}`)
      .then(({ data }) => {
        try {
          if (data.Success) {
            setDeleteRequest(false);
            load();
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const f = getLocaleDateString(APITOKEN.config.cultureCode);
  useEffect(() => {
    load();
  }, [load]);
  return (
    <>
      {deleteRequest ? (
        <div className="margin-top50 inline-block width100">
          <DeleteListTemplate
            title="Do you want to Delete this sending request?"
            content="Deleting the sending request won't delete the campaign."
            colSpan="5"
            onClick={() => setDeleteRequest(false)}
            deleteDownload={deleteFn}
          />
        </div>
      ) : (
        <div className="sms_Sec_1 bx-boder-common width100  margin-bottom">
          <div className="_h_header">
            <h3>Request</h3>
          </div>
          <table cellPadding="0" cellSpacing="smstable" className="smstable">
            {getData.length > 0 ? (
              <thead>
                <tr>
                  <th className="table_cell_header">Distribution Name</th>
                  <th className="table_cell_header">Requested To</th>
                  <th className="table_cell_header">User Email</th>
                  <th className="table_cell_header">Rejected By</th>
                  <th className="table_cell_header">Rejected Reason</th>
                  <th className="table_cell_header">Date</th>
                  <th className="table_cell_header">Actions</th>
                </tr>
              </thead>
            ) : (
              <></>
            )}
            <tbody>
              {getData.length > 0 ? (
                <>
                  {getData.map((item, index) => (
                    <tr key={index}>
                      <td className="body_table_cell">{item["Name"]}</td>
                      <td className="body_table_cell">
                        {item["RequestedToName"]}
                      </td>
                      <td className="body_table_cell">
                        {item["RequestedToEmail"]}
                      </td>
                      <td>{item?.RejectedByUserName}</td>
                      <td>{item?.RejectedReason}</td>
                      <td className="body_table_cell">
                        {" "}
                        {moment(item["RequestedDate"]).format(f)}
                      </td>
                      <td className="body_table_cell">
                        <button
                          type="button"
                          className="link"
                          onClick={() =>
                            navigate(`/Email/Emailsummary/${item.Id}`)
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="link"
                          style={{ display: "none" }}
                          onClick={() => {
                            setDeleteRequestId(item.Id);
                            setDeleteRequest(true);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <BlankData
                  title="You haven't submitted any review requests currently."
                  colSpan="8"
                  content="Once you have created and submitted a sending for approval, you will find it here."
                />
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

export default ApprovalMakerRequest;
