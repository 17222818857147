import { useCallback, useState } from "react";
import Client from "../../GlobalAPICall";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import CountryList from "../../ReuseTemplate/Country";

const SendTestContainer = ({ ...props }) => {
    const { SetSendTestObject, SendTestVal, SendObject } = props;
    const [countries, setCountries] = useState([]);
    const [newarrySearchData, setnewarrySearchData] = useState({ data: [], selected: '', name: '' });
    const [senTestResponse, setTestReponse] = useState({ show: false });

    const resetObject = () => {
        setTestReponse(prv => ({ ...prv, show: false }))
        SetSendTestObject({
            flag: true,
            recipinentsTest: [],
            addnumber: '',
            addButton: true
        });
        setnewarrySearchData({ data: [], selected: '', name: '' });
    }

    const countryLoad = useCallback(async () => {
        const { data } = await CountryList();
        try {
            setCountries(data);
            setnewarrySearchData(prv => ({
                ...prv,
                data,
            }))
        } catch (err) {
            console.error(err);
        }
    }, [])

    // Search country Name //
    const enterMobileNumber = (e) => {
        if (!isNaN(e.target.value)) {
            SetSendTestObject(prv => ({
                ...prv,
                addnumber: e.target.value,
                addButton: false
            }));
        } else {
            SetSendTestObject(prv => ({ ...prv, addButton: true }));
        }
    }

    //  Filter Country Code  //
    const countrySearch = event => {
        event.stopPropagation();
        const { value } = event.target;
        const searchCountry = countries.map(x => ({ ...x, classSeleted: false })).filter(item => item.countryname.toLowerCase().includes(value.toLowerCase()));
        searchCountry[0].classSeleted = value !== "" ? true : false;
        setnewarrySearchData(prev => ({
            ...prev,
            data: searchCountry,
            selected: '',
            name: value
        }));
    };

    // Selected Country Code //
    const selectCountryCode = (v) => {
        setnewarrySearchData(prev => ({
            ...prev,
            data: [],
            selected: v.countryCode,
            name: `${v.countryname}`
        }))
    };



    // Add Recipients Method //
    const addRecipients = () => {
        SetSendTestObject(prv => ({
            ...prv,
            recipinentsTest: [...prv.recipinentsTest,
            ...[{ Recipient: `${newarrySearchData.selected}${prv.addnumber}` }]],
            addnumber: "",
            addButton: true
        }));

    }

    // Remove Recipients //
    const removeRecipient = (ev) => {
        const newArray = SendTestVal.recipinentsTest.filter(x => x.Recipient !== ev.Recipient);
        SetSendTestObject(prv => ({ ...prv, recipinentsTest: newArray }))
    }

    // Sent Test API Call //
    const [IsLoader, setIsLoader] = useState(false);
    const sendTestAPICall = () => {
        const obj = SendObject();
        setIsLoader(true);
        obj['recipients'] = SendTestVal.recipinentsTest;
        obj['isDraft'] = false;
        try {
            Client.post(`SMS/Send/Test`, obj)
                .then(({ data }) => {
                    try {
                        setIsLoader(false);
                        if (data.Success) {
                            setTestReponse(prv => ({ ...prv, show: true }))
                        }
                    } catch (err) {
                        console.error(err);
                        setIsLoader(false);
                    }
                }).catch(err => {
                    console.error(err);
                    setIsLoader(false);
                })
        } catch (err) {
            console.error(err);
            setIsLoader(false);
        }
    }


    // Onload Country Code API //

    return (
        <div className="sendTestMask">
            <div className="_sendTestRemove extraclass">
                <div className="topn-header noborder">
                    <span className="selected-title">Send Test SMS</span>
                    <span style={{
                        padding: '20px',
                        float: 'right'
                    }} onClick={() => SetSendTestObject(prv => ({ ...prv, flag: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="_p_b_x noborder">
                    {senTestResponse.show ? <>
                        <p className="margin-bottom">Test SMS(s) have been sent to the entered mobile number(s).</p>
                        <div className="formmodel-bx text-right"><button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" onClick={resetObject}>Send New Test</button></div>
                    </> :
                        <><div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">Select Country</label>
                            <input
                                type="text"
                                className="inputformmodel inputselectBg pointer"
                                value={newarrySearchData.name}
                                onClick={countryLoad}
                                onChange={e => countrySearch(e)}
                                placeholder="Select Country"
                            />
                            {newarrySearchData.data.length > 0 && <ul className="country_filter">
                                {newarrySearchData.data.map((v, i) =>
                                    <li key={i} style={{ display: 'flex' }} className={`${v.classSeleted ? 'dropSeleted' : ''}`}>
                                        <span style={{
                                            width: '30px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            marginLeft: '10px'
                                        }}><img src={v.flagPath} alt="" style={{ maxWidth: '100%' }} /></span>
                                        <button
                                            type="button"
                                            className="nobg noborder pointer width100 inline-block text-left"
                                            onClick={() => selectCountryCode(v)}>{v.countryname}</button>
                                    </li>)}</ul>}
                        </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Mobile Number</label>
                                <input
                                    type="text"
                                    className="inputformmodel f-m-one"
                                    disabled
                                    value={newarrySearchData.selected} />
                                <input
                                    type="text"
                                    className="inputformmodel"
                                    placeholder="Mobile Number"
                                    value={SendTestVal.addnumber}
                                    onChange={e => SendTestVal.recipinentsTest.length === 5 ? e.stopPropagation() : enterMobileNumber(e)} style={{
                                        width: '247px',
                                        marginLeft: '10px'
                                    }} />
                                <button type="button" className="grid_btn_1 grid_btn_active btnadd fright" disabled={SendTestVal.addButton} onClick={addRecipients}>Add</button>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Recipients</label>
                                <div className="inputformmodel textareaModel">

                                    {SendTestVal.recipinentsTest.length > 0 ? SendTestVal.recipinentsTest.map((v, i) => <label key={i} className="l_b-bx"><span>{v.Recipient}</span><button className="removeRecipienSendtest" type="button" onClick={() => removeRecipient(v)}><i className="fas fa-trash-alt"></i></button></label>) : <p className="_sp">Add up to 5 mobile numbers.</p>}
                                </div>
                            </div>
                            <div className="formmodel-bx text-right">
                                <button type="button" disabled={
                                    SendTestVal.recipinentsTest.length > 0 ? false : true
                                } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={sendTestAPICall}>
                                    <ButtonLoader IsLoader={IsLoader}>
                                        Send Test
                                    </ButtonLoader>
                                </button>
                            </div>
                        </>}</div>
            </div>
        </div>
    )

}

export default SendTestContainer
