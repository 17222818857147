import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import WedgetGrid from "./WedgetGrid";

const Engagment = ({ tabId ,postObject }) => {
    const { id } = useParams();
    const checkIsAuth = useSelector(state => state);
    // const [wedgetData, setWedgetGridData] = useState([]);
    const [engagmentData, setEngagmentData] = useState([]);
    // const [ engamentPostObject ] = { fromDate: "", locale: "en-IN", toDate: "" }
    const loadApiCall = useCallback(async () => {
        try {
            (checkIsAuth['APITOKEN']['token'] !== "") &&
                Client.get(`Insights/landingpage/${id}/KPI/engagement`, {
                    headers: {
                        'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                    }
                }).then((res) => {
                    try {
                        const data = JSON.parse(res['data'][0].KPI);
                        setEngagmentData(prv=>([...prv,...data]));
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [checkIsAuth, id]);


    const loadApiEngagementCall = useCallback(async () => {
        try {
            (checkIsAuth['APITOKEN']['token'] !== "") &&
                Client.post(`Insights/Survey/${id}/Engagement`,postObject, {
                    headers: {
                        'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                    }
                }).then((res) => {
                    try {
                        filterObject(res['data']);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [checkIsAuth, id,postObject]);
    const filterObject = (res) => {
        for (const x of res) {
            setEngagmentData(prv => ([...prv, ...[{
                WidgetName: x['questionText'],
                WidgetId: x['questionId'],
                WidgetElementValue: x['questionType'],
                KPI: [],
                questionFlag: true,
                TotalResponses: x['totalResponses'],
                UniqueResponses: x['uniqueResponses'],
                MatrixResponse: x['matrixResponse'],
                Charts: x['charts'],
                SelectedChart: Object.values(x.charts)[0],
                OpentextResponse: x['opentextResponse']
            }]]));
        };
    }
    useEffect(() => {
        loadApiCall();
        loadApiEngagementCall();
    }, [loadApiCall, loadApiEngagementCall])
    return (
        <>
            <div className="overviewtable bx-boder-common white"><div className="_h_header" style={{ justifyContent: "space-between" }}><h3>Widget Clicks</h3><span className="fright font-16 goal_duration" style={{ display: "none" }}>Goal Duration:&nbsp;<span className="goalMeasureEndDate"></span></span></div><div className="widgetContainer"><WedgetGrid Data={engagmentData} setEngagmentData={setEngagmentData}  tabId={tabId} /></div></div>
        </>
    )
}

export default Engagment

