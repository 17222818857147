export const requireAll = requireContext =>
  requireContext.keys().map(requireContext);

// eslint-disable-next-line import/no-anonymous-default-export

export const objectToParams = (object) => {

  Object.entries(object).map(([key, val]) => val === null && delete object[key]);

  if (Object.keys(object).length > 0) {
    return new URLSearchParams(object).toString();
  } else {
    return "";
  }
}

export const objectToParamsWithEmptyValue = (object) => {

  //Object.entries(object).map(([key, val]) => val === null && delete object[key]);

  if (Object.keys(object).length > 0) {
    return new URLSearchParams(object).toString();
  } else {
    return "";
  }
}

const exportedObject = {
  requireAll,
  objectToParams
};

export default exportedObject;
