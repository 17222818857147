import React, { useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { useSelector } from "react-redux";
//import { get as _get, isEmpty as _isEmpty } from "lodash";
import getLocaleDateString from '../../../../../TimeFormat';
import moment from "moment";

import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

import { getEmailDetails, getMobileDetails, getPriorityTypes, addEditMobileNumber, addEditEmail } from "../../../../../services/crm";

const Communication = () => {
	const pathArray = window.location.pathname.split("/");
	const listID = _get(pathArray, "[4]", null); // get PKID from UR
	const queryParams = new URLSearchParams(window.location.search);
	const peopleSubscriberId = queryParams.get('pId');
	const { APITOKEN } = useSelector(state => state);
	const f = getLocaleDateString(APITOKEN.config.cultureCode);
	console.log("FFFF: ", f);

	const [emailData, setEmailData] = useState([]);
	const [mobileData, setMobileData] = useState(false);
	const [priorities, setPriorities] = useState([]);
	const [emailPriorities, setEmailPriorities] = useState([]);
	const [isAddEditMobileOpen, setIsAddEditMobileOpen] = useState(false);
	const [isAddEditEmailOpen, setIsAddEditEmailOpen] = useState(false);

	let defaultMobilePayload = { Priority: "", MobileNumber: "", MobileCountryCode: "", MarketingSMSOptin: 0, PeopleSubscriberId: peopleSubscriberId, SubscriberListId: listID, CommunicationType: 2, IsUpdate: 0 }
	const [mobilePayload, setMobilePayload] = useState(defaultMobilePayload);

	let defaultEmailPayload = { Priority: "", Email: "", MarketingEmailOptin: 0, PeopleSubscriberId: peopleSubscriberId, SubscriberListId: listID, CommunicationType: 1, IsUpdate: 0 }
	const [emailPayload, setEmailPayload] = useState(defaultEmailPayload);

	useEffect(() => {
		(async () => {
			try {

				let payload = {
					SubscriberListId: listID,
					PeopleSubscriberId: (peopleSubscriberId || 0),
				};

				const respEmails = await getEmailDetails(payload);
				setEmailData(_get(respEmails, "data.data", []));

				const respMobile = await getMobileDetails(payload);
				setMobileData(_get(respMobile, "data.data", []));

				const respPriorities = await getPriorityTypes();
				const priorities = _get(respPriorities, "data.data", []).map(element => {

					let isDisabled = _get(respMobile, "data.data", []).some(item => item.Priority === element.Type);

					if (_get(respMobile, "data.data", []).length < 1) {
						isDisabled = false;
					}
					return { ...element, isDisabled: isDisabled };
				});
				setPriorities(priorities);

				const prioritiesEmail = _get(respPriorities, "data.data", []).map(element => {

					let isDisabledEmail = _get(respEmails, "data.data", []).some(item => item.Priority === element.Type);

					if (_get(respEmails, "data.data", []).length < 1) {
						isDisabledEmail = false;
					}
					return { ...element, isDisabled: isDisabledEmail };
				});
				setEmailPriorities(prioritiesEmail);

			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [listID, peopleSubscriberId]);

	const toggleMobileDrawer = () => {
		setIsAddEditMobileOpen((prevState) => !isAddEditMobileOpen);
		setMobilePayload(defaultMobilePayload);
	}

	const toggleEmailDrawer = () => {
		setIsAddEditEmailOpen((prevState) => !isAddEditEmailOpen);
		setEmailPayload(defaultEmailPayload);
	}

	const handleMobileSubmit = () => {
		(async () => {
			try {

				let payload = { MobileData: [mobilePayload] };

				const respAddMobileNumber = await addEditMobileNumber(payload);
				if (_get(respAddMobileNumber, `data.data[0][0].RecordType`, "") === "created" || _get(respAddMobileNumber, `data.data[0][0].RecordType`, "") === "updated") {
					setMobilePayload(_cloneDeep(defaultMobilePayload));

					let payload = {
						SubscriberListId: listID,
						PeopleSubscriberId: (peopleSubscriberId || 0),
					};

					const respMobile = await getMobileDetails(payload);
					setMobileData(_get(respMobile, "data.data", []));

					setIsAddEditMobileOpen(false);
				}
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}

	const handleEmailSubmit = () => {
		(async () => {
			try {

				const { MarketingEmailOptinOutDateTime, ...finalEmailPayload } = emailPayload;
				let payload = { EmailData: [finalEmailPayload] };

				const respAddEmail = await addEditEmail(payload);
				if (_get(respAddEmail, `data.data[0][0].RecordType`, "") === "created" || _get(respAddEmail, `data.data[0][0].RecordType`, "") === "updated") {
					setEmailData(_cloneDeep(defaultEmailPayload));

					let payload = {
						SubscriberListId: listID,
						PeopleSubscriberId: (peopleSubscriberId || 0),
					};

					const respEmail = await getEmailDetails(payload);
					setEmailData(_get(respEmail, "data.data", []));

					setIsAddEditEmailOpen(false);
				}
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}

	const renderDrawer = () => {

		return (
			<Drawer
				open={isAddEditMobileOpen}
				onClose={() => toggleMobileDrawer()}
				direction="right"
				size={572}
				zIndex="9999"
			>
				<div className="topn-header">
					<span className="selected-title"> {(_get(mobilePayload, "IsUpdate", "") === 1) ? "Edit" : ""} Mobile Number</span>
					<span style={{ padding: '20px', float: 'right' }} onClick={() => toggleMobileDrawer()}>
						<i className="fa fal fa-times clsbtnNPS fright pointer"></i>
					</span>
				</div>
				<div className="scrollbar">
					<div className="padding20 b_top white text-center _h_header justify-content-between">
						<h3>Mobile Number</h3>
					</div>
					<div className="padding20">
						<div className="row">
							<div className="col-5 formmodel-bx">
								<label className="_l_able_cls">Priority</label>
								<select
									className="inputformmodel width100"
									value={_get(mobilePayload, "Priority", "")}
									onChange={(e) => setMobilePayload({ ...mobilePayload, Priority: _get(e, "target.value", "") })}
								>
									<option value="">Select</option>
									{(!_isEmpty(priorities)) && (priorities || []).map((priority, key) => {

										return (
											<option value={_get(priority, "PkId", "")} disabled={_get(priority, "isDisabled", "")}>{_get(priority, "Type", "")}</option>
										)
									})}
								</select>
							</div>
							<div className="row">
								<label className="_l_able_cls">Mobile Number</label>
								<div className="col-3 formmodel-bx">
									<input
										type="number"
										placeholder=""
										className="inputformmodel width100"
										value={_get(mobilePayload, "MobileCountryCode", "")}
										onChange={(e) => setMobilePayload({ ...mobilePayload, MobileCountryCode: _get(e, "target.value", "") })}
									/>
								</div>
								<div className="col-5 formmodel-bx">
									<input
										type="number"
										placeholder="Mobile Number"
										className="inputformmodel width100"
										value={_get(mobilePayload, "MobileNumber", "")}
										onChange={(e) => setMobilePayload({ ...mobilePayload, MobileNumber: _get(e, "target.value", "") })}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-5 formmodel-bx">
									<label className="_l_able_cls">Marketing SMS Opt-in</label>
									<select
										className="inputformmodel width100"
										value={_get(mobilePayload, "MarketingSMSOptin", "")}
										onChange={(e) => setMobilePayload({ ...mobilePayload, MarketingSMSOptin: _get(e, "target.value", "") })}
									>
										<option value="">Select</option>
										<option value="0">No-Consent</option>
										<option value="1">SMS Opt-in</option>
										<option value="2">SMS Opt-out</option>
									</select>
								</div>
								<div className="col-1 formmodel-bx"></div>
								<div className="col-5 formmodel-bx">
									<label className="_l_able_cls">Marketing SMS Opt-in DateTime</label>
									<p>{_get(mobilePayload, "MarketingSMSOptinOutDateTime", "")}</p>
								</div>
							</div>

							<div className="formmodel-bx text-right mt-12 mb-0">
								<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => toggleMobileDrawer()} >Cancel</button>
								<button
									type="button"
									className="btn btn-primary grid_btn_active btn-gape-15"
									disabled=""
									onClick={() => handleMobileSubmit()}>
									Save
								</button>

							</div>
						</div>
					</div>
				</div>
			</Drawer>
		)
	}

	const renderDrawerEmail = () => {

		return (
			<Drawer
				open={isAddEditEmailOpen}
				onClose={() => toggleEmailDrawer()}
				direction="right"
				size={742}
				zIndex="9999"
			>
				<div className="topn-header">
					<span className="selected-title">{(_get(emailPayload, "IsUpdate", "") === 1) ? "Edit" : ""} Email Address</span>
					<span style={{ padding: '20px', float: 'right' }} onClick={() => toggleEmailDrawer()}>
						<i className="fa fal fa-times clsbtnNPS fright pointer"></i>
					</span>
				</div>
				<div className="scrollbar">
					<div className="padding20 b_top white text-center _h_header justify-content-between">
						<h3>Email Address</h3>
					</div>
					<div className="padding20">
						<div className="row">
							<div className="col-5 formmodel-bx">
								<label className="_l_able_cls">Priority</label>
								<select
									className="inputformmodel width100"
									value={_get(emailPayload, "Priority", "")}
									onChange={(e) => setEmailPayload({ ...emailPayload, Priority: _get(e, "target.value", "") })}
								>
									<option value="">Select</option>
									{(!_isEmpty(emailPriorities)) && (emailPriorities || []).map((priority, key) => {

										return (
											<option value={_get(priority, "PkId", "")} disabled={_get(priority, "isDisabled", "")}>{_get(priority, "Type", "")}</option>
										)
									})}
								</select>
							</div>
							<div className="row">
								<label className="_l_able_cls">Email Address</label>
								<div className="col-12 formmodel-bx">
									<input
										type="text"
										placeholder="Email Address"
										className="inputformmodel width100"
										value={_get(emailPayload, "Email", "")}
										onChange={(e) => setEmailPayload({ ...emailPayload, Email: _get(e, "target.value", "") })}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-5 formmodel-bx">
									<label className="_l_able_cls">Email Subscribe Marketing</label>
									<select
										className="inputformmodel width100"
										value={_get(emailPayload, "MarketingEmailOptin", "")}
										onChange={(e) => setEmailPayload({ ...emailPayload, MarketingEmailOptin: _get(e, "target.value", "") })}
									>
										<option value="">Select</option>
										<option value="0">No-Consent</option>
										<option value="1">Subscribed</option>
										<option value="2">UnSubscribe</option>
									</select>
								</div>
								<div className="col-1 formmodel-bx"></div>
								<div className="col-5 formmodel-bx">
									<label className="_l_able_cls">Marketing SMS Opt-in DateTime</label>
									<p>{_get(emailPayload, "MarketingEmailOptinOutDateTime", "")}</p>
								</div>
							</div>

							<div className="formmodel-bx text-right mt-12 mb-0">
								<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => toggleEmailDrawer()} >Cancel</button>
								<button
									type="button"
									className="btn btn-primary grid_btn_active btn-gape-15"
									disabled=""
									onClick={() => handleEmailSubmit()}>
									Save
								</button>

							</div>
						</div>
					</div>
				</div>
			</Drawer>
		)
	}
	console.log("emailPayload: ", emailPayload);
	return (
		<>
			<div>
				<div className="bx-boder-common nobg my-13">
					<div className="_h_header white border-radius2">
						<h3>Mobile Number </h3>
						<span className="flex-right">
							<button className="btn btn-light" onClick={() => toggleMobileDrawer()}>Add Mobile Number</button>
						</span>
					</div>

					<div className="white">
						<div className="row">
							<div className="col-12">
								<div className="dataTable-primary">
									{((mobileData || []).length < 1) && (
										<div className="alert alert-warning my-5 mx-5">
											<strong>There is no mobile number registered on this contact.</strong>
											<br /><br />
										</div>
									)}
									{((mobileData || []).length > 0) && (
										<table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
											<thead>
												<tr>
													<th className="table_cell_header pt-3" width="60">
														<label className="_l_able_cls margin-bottom0 w-auto inline-block">
															<input type="checkbox" className="inline-block" />
														</label>
													</th>
													<th className="table_cell_header pt-3" width="60">Action</th>
													<th className="table_cell_header pt-3" width="100">Priority</th>
													<th className="table_cell_header pt-3">Mobile Country Code</th>
													<th className="table_cell_header pt-3">Mobile Number</th>
													<th className="table_cell_header pt-3">SMS Opt-in Marketing</th>
													<th className="table_cell_header pt-3">SMS Opt-in Marketing Date Time</th>
												</tr>
											</thead>
											<tbody>
												{(mobileData || []).map((row, key) => {
													const mobileNumber = _get(row, "Value", "").split('-');

													return (
														<tr key={key}>
															<td className="body_table_cell">
																<label className="_l_able_cls margin-bottom0 w-auto inline-block">
																	<input type="checkbox" className="inline-block" />
																</label>
															</td>
															<td className="body_table_cell">
																<span
																	className="text-07f cursor-pointer"
																	onClick={() => {
																		setMobilePayload({
																			...mobilePayload,
																			Priority: _get(row, "PriorityId", ""),
																			MobileCountryCode: (mobileNumber[0] || ""),
																			MobileNumber: (mobileNumber[1] || ""),
																			MarketingSMSOptin: _get(row, "MarketingSMSOptinStatus", ""),
																			MarketingSMSOptinOutDateTime: moment(_get(row, "MarketingSMSOptinOutDateTime", "")).format(f),
																			IsUpdate: 1
																		});

																		setIsAddEditMobileOpen(true);
																	}}
																>
																	Edit
																</span>
															</td>
															<td className="body_table_cell">
																{_get(row, "Priority", "'")}
															</td>
															<td className="body_table_cell">
																{(mobileNumber[0] || "")}
															</td>
															<td className="body_table_cell">
																{(mobileNumber[1] || "")}
															</td>
															<td className="body_table_cell">{(_get(row, "MarketingSMSOptinStatus", null) === 0) ? "No-Consent" : (_get(row, "MarketingSMSOptinStatus", null) === 1) ? "SMS Opt-in" : "SMS Opt-out"}
															</td>
															<td className="body_table_cell">
																{moment(_get(row, "MarketingSMSOptinOutDateTime", "'")).format(f)}
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="bx-boder-common nobg my-13">
					<div className="_h_header white border-radius2">
						<h3>Email Address  </h3>
						<span className="flex-right">
							<button className="btn btn-light" onClick={() => toggleEmailDrawer()}>Add Email</button>
						</span>
					</div>

					<div className="white">
						<div className="row">
							<div className="col-12">
								<div className="dataTable-primary">
									{((emailData || []).length < 1) && (
										<div className="alert alert-warning my-5 mx-5">
											<strong>You have not created any Email yet.</strong>
											<br /><br />
										</div>
									)}
									{((emailData || []).length > 0) && (
										<table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
											<thead>
												<tr>
													<th className="table_cell_header pt-3" width="60">
														<label className="_l_able_cls margin-bottom0 w-auto inline-block">
															<input type="checkbox" className="inline-block" />
														</label>
													</th>
													<th className="table_cell_header pt-3" width="60">Action</th>
													<th className="table_cell_header pt-3" width="100">Priority</th>
													<th className="table_cell_header pt-3">Email Address</th>
													<th className="table_cell_header pt-3">Email Subscribe Marketing</th>
													<th className="table_cell_header pt-3">Email Subscribe Marketing Date Time</th>
												</tr>
											</thead>
											<tbody>
												{(emailData || []).map((row, key) => {
													return (
														<tr key={key}>
															<td className="body_table_cell">
																<label className="_l_able_cls margin-bottom0 w-auto inline-block">
																	<input type="checkbox" className="inline-block" />
																</label>
															</td>
															<td className="body_table_cell">
																<span
																	className="text-07f cursor-pointer"
																	onClick={() => {
																		setEmailPayload({
																			...emailPayload,
																			Priority: _get(row, "PriorityId", ""),
																			Email: _get(row, "Value", ""),
																			MarketingEmailOptin: _get(row, "MarketingEmailOptinStatus", ""),
																			MarketingEmailOptinOutDateTime: moment(_get(row, "MarketingEmailOptinOutDateTime", "")).format(f),
																			IsUpdate: 1
																		});

																		setIsAddEditEmailOpen(true);
																	}}
																>
																	Edit
																</span>
															</td>
															<td className="body_table_cell">
																{_get(row, "Priority", "'")}
															</td>
															<td className="body_table_cell">
																{_get(row, "Value", "'")}
															</td>
															<td className="body_table_cell">
																{(_get(row, "MarketingEmailOptinStatus", null) === 0) ? "No-Consent" : (_get(row, "MarketingEmailOptinStatus", null) === 1) ? "Subscribed" : "UnSubscribed"}
															</td>
															<td className="body_table_cell">
																{moment(_get(row, "MarketingEmailOptinOutDateTime", "'")).format(f)}
															</td>
														</tr>
													)
												})}
											</tbody>
										</table>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{renderDrawer()}
			{renderDrawerEmail()}
		</>
	)
}

export default Communication;