export const DownLoadData = [
    {
        name: 'Download PNG image',
        id: 1, value: 'image/png'
    },
    { name: 'Download JPEG image', id: 2, value: 'image/jpeg' },
    { name: 'Download PDF document', id: 3, value: 'application/pdf' },
    { name: 'Download SVG vector image', id: 4, value: 'image/svg+xml' },
    // { name: 'Download excel', id: 5, value: 'application/vnd.ms-excel' },
    // { name: 'Download csv', id: 6, value: 'text/csv' }
]