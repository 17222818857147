
const LandingPageLoader = () => {
    return (
        <> {
            [1, 2, 3, 4].map((v, i) =>
            <div className="skeleton_landingPage_container margin-bottom" key={i}>
                <div className="-l-body-l white bx-boder-common border-radius2">
                    <div className="padding20">
                        <h3 className=" titleHover">
                            <span className="skeleton-loader" style={{ width: '40%' }}></span>
                        </h3>
                    </div>
                    <div className="_listing_bx_content b_top">
                        <div className="c-img">
                            <span className="skeleton-loader" style={{ height: '365px' }}></span>
                        </div>
                        <div className="-cam-right">
                            <div className="-cam-detail-wrap">
                                <span className="skeleton-loader" style={{ height: '30px' }}
                                ></span>
                                <span className="skeleton-loader" style={{ marginTop: '10px', height: '30px' }}></span>
                            </div>
                            <div className="list-btm">
                                <div className="cam-info"><span className="skeleton-loader"></span>
                                    <span className="skeleton-loader" style={{ marginTop: '10px' }}></span>
                                    <span className="skeleton-loader" style={{ marginTop: '10px' }}></span></div>
                                <div className="cam-qr inline-block" style={{ paddingLeft: '29px' }}><span className="skeleton-loader"></span>
                                    <span className="skeleton-loader" style={{ marginTop: '10px' }}></span>
                                    <span className="skeleton-loader" style={{ marginTop: '10px' }}></span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        </>
    )

}

export default LandingPageLoader
