
const VideoInfo = ({ data }) => {
    const { Name, DefaultJsonInfo } = data;
    const dataParse = JSON.parse(DefaultJsonInfo);
    return (
        <div>
            <h2>
                {Name}
                <label style={{ paddingTop: '5px' }} className="fright vertical-middle sms_content">Duration: {dataParse.Duration}</label>
            </h2>
            <p>{dataParse.HeaderDescription}</p>
            <div className="__video__play">
                <iframe id="1212" src={dataParse.VideoUrl} width="690" height="390" title={dataParse.HeaderTitle} ></iframe>
            </div>

        </div>
    )

}

export default VideoInfo
