import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import StepsDistribution from "../StepsTopBar";
import { encode as btoa } from "base-64";
import "./index.css";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import BalanceCheckFn from "../../ReuseTemplate/BalanceCheckFn";
import CheckerMakerContainer from "./CheckerMakerContainer";

export const EmailSummary = () => {
  const { APITOKEN } = useSelector(state => state);
  const { id } = useParams();
  const [allContentLoaded, setallContentLoaded] = useState({
    aggregated: false,
    editLoad: false,
  });
  const [getAllEmailSendingDetails, setAllEmailSendingDetails] = useState({});
  const [isloader, setLoader] = useState(true);
  const [lowBlance, setLowBalancePopup] = useState({
    display: false,
  });
  const [messageCount, setMessageCount] = useState({
    listWiseRecipientsDetails: [],
  });
  const navigate = useNavigate();
  let timerId = useRef(null);
  const [IsLoader, setIsLoader] = useState(false);
  const messageCountLoad = useCallback(() => {
    const cancelToken = axios.CancelToken.source();
    clearTimeout(timerId.current);
    APITOKEN.token &&
      Client.get(`Email/Distribution/${id}/Preview/AggregatedMessageCount`, {
        cancelToken: cancelToken.token,
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      })
        .then(({ data }) => {
          try {
            if (data["isPreviewReadyToSend"]) {
              setallContentLoaded(prv => ({ ...prv, aggregated: true }));
              setMessageCount(data);
            } else {
              timerId.current = setTimeout(function () {
                setallContentLoaded(prv => ({ ...prv, aggregated: false }));
                messageCountLoad();
              }, 4000);
            }
          } catch (err) {
            console.error(err);
            messageCountLoad();
          }
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            cancelToken.cancel();
          }
          messageCountLoad();
        });
    return () => {
      clearTimeout(timerId.current);
      cancelToken.cancel();
    };
  }, [APITOKEN, id]);
  const load = useCallback(() => {
    APITOKEN.token &&
      Client.get(`/Email/Distribution/${id}`, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      })
        .then(({ data }) => {
          setallContentLoaded(prv => ({ ...prv, editLoad: true }));
          try {
            setLoader(false);
            setAllEmailSendingDetails(data);
            if (!!data.recipientImportUrl) {
              filterImportFile(data);
            }
            messageCountLoad();
          } catch (err) {
            console.error(err);
            setallContentLoaded(prv => ({ ...prv, editLoad: false }));
          }
        })
        .catch(err => {
          setallContentLoaded(prv => ({ ...prv, editLoad: false }));
        });
  }, [APITOKEN, id, messageCountLoad]);

  const filterImportFile = data => {
    let split = data.recipientImportUrl.split("/");
    const fileName = split[split.length - 1].split(".");
    setAllEmailSendingDetails(prv => ({
      ...prv,
      recipientImportUrl: fileName[0],
    }));
  };

  const emailSend = () => {
    setIsLoader(true);
    setLowBalancePopup({ display: false });
    APITOKEN.token &&
      Client.post(
        `Email/Send/${id}/Confirm`,
        {},
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then(async ({ data }) => {
          try {
            setIsLoader(false);
            if (data.success) {
              if (getAllEmailSendingDetails.isQueuePaused) {
                await requeueJobFn();
              }
              data.IsScheduled
                ? navigate("/dashboard")
                : navigate(`/insights/EmailDetails/${data["Id"]}?f=13`);
            } else {
              setLowBalancePopup(prv => ({
                ...prv,
                display: true,
                ...data,
              }));
            }
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {
          console.error(err);
        });
  };
  const requeueJobFn = () => {
    Client.patch(
      `Email/ScheduleJobStatusRequeue/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(res => {
        try {
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => console.log(err));
  };
  const [spliceCount, setSpliceCount] = useState(10);
  const moreRecipients = () => {
    setSpliceCount(spliceCount === 14000 ? 10 : 14000);
  };
  const makerCheckerCondition = useMemo(() => {
    return APITOKEN?.config?.isSubUserOrOperator
      ? APITOKEN?.config?.subUserRoleType === 1 ||
        APITOKEN?.config?.subUserRoleType === null
        ? false
        : true
      : false;
  }, [APITOKEN]);
  const checkerCondition = useMemo(() => {
    return APITOKEN?.config?.isSubUserOrOperator
      ? APITOKEN?.config?.subUserRoleType === 0 ||
        APITOKEN?.config?.subUserRoleType === null
        ? false
        : true
      : false;
  }, [APITOKEN]);
  const gotoCRMList = id => {
    // window.location.replace(`${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&redurl=crm/people/subscribers/${id}/1/segment/list`);
    window.location.replace(
      `${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${
        APITOKEN.config.lcxtkf
      }&backUrl=${btoa(
        window.location.href
      )}&redurl=crm/people/subscribers/${id}/1`
    );
  };
  const [rejectionReason, setRejectReason] = useState({
    display: false,
    text: "",
    loader: false,
  });
  const rejectCheckerRequest = () => {
    setRejectReason(prv => ({ ...prv, display: true }));
  };
  const rejectionAPICall = () => {
    setRejectReason(prv => ({ ...prv, loader: true }));
    Client.patch(`Email/Send/Rejected/${id}`, { reason: rejectionReason.text })
      .then(({ data }) => {
        try {
          navigate("/dashboard");
        } catch (err) {
          setRejectReason(prv => ({ ...prv, loader: false }));
          console.error(err);
        }
      })
      .catch(err => {
        setRejectReason(prv => ({ ...prv, loader: false }));
        throw err;
      });
  };
  useEffect(() => {
    load();
    return () => {
      clearTimeout(timerId.current);
    };
  }, [load]);
  return (
    <>
      <StepsDistribution Name="Email" Active={4} Hide={true} UTM={true} />
      <div className="dashbd_sec _sms_Summary margin-bottom">
        <div className="sms_Sec_1 bx-boder-common inline-block">
          <div className="_h_header flex-direction-column">
            <h3>Email Summary</h3>
            <p>
              * The count of displayed contacts may vary during sending due to
              duplicates or excluded contacts.
            </p>
          </div>
          <div className="bx-pad">
            {isloader ? (
              <>
                {[1, 2, 3, 4].map(i => (
                  <div key={i}>
                    <div
                      className="skeleton-loader"
                      style={{ height: "50px", marginBottom: "20px" }}
                    ></div>
                    <div
                      className="skeleton-loader"
                      style={{ height: "20px", marginBottom: "20px" }}
                    ></div>
                  </div>
                ))}
              </>
            ) : (
              <table className="summary_table" cellPadding="0" cellSpacing="0">
                <tbody>
                  <tr>
                    <td>
                      <strong>Recipients</strong>
                    </td>
                    <td>
                      {allContentLoaded.aggregated ? (
                        <>
                          {messageCount.listWiseRecipientsDetails.length > 0 &&
                            messageCount.listWiseRecipientsDetails.map(
                              (v, i) => {
                                return (
                                  <div key={i} className="formmodel-bx">
                                    <div className="mainlist">
                                      <p className="viewpanel">
                                        <span>List:&nbsp;</span>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            navigate(
                                              `/Email/recipient?cam=${id}&type=48&edit=true`
                                            )
                                          }
                                          className="link inline-block noborder nobg"
                                        >
                                          {v["listDisplayName"]}
                                        </button>
                                        {checkerCondition ? (
                                          <button
                                            type="button"
                                            className="pointer"
                                            onClick={() =>
                                              gotoCRMList(v.listId)
                                            }
                                          >
                                            <i className="fas fa-eye"></i>
                                          </button>
                                        ) : (
                                          <></>
                                        )}
                                      </p>
                                      <div>
                                        Segment included: {v["includedMessage"]}
                                      </div>
                                      <p className="segmentlist"></p>
                                    </div>

                                    {!v["excludedListNames"]
                                      ? v["incluedSegmentTextType"] === 0 &&
                                        v["segmentsCountDetails"] !==
                                          undefined &&
                                        v["segmentsCountDetails"].map(
                                          (y, i) => (
                                            <div key={i}>
                                              <div
                                                style={{
                                                  display: y["isExcluded"]
                                                    ? "block"
                                                    : "none",
                                                }}
                                              >
                                                Excluded segment
                                              </div>
                                            </div>
                                          )
                                        )
                                      : v.excludedListNames.map((y, i) => (
                                          <p key={i}>
                                            <span>Excluded segment:&nbsp;</span>{" "}
                                            {y}
                                          </p>
                                        ))}
                                  </div>
                                );
                              }
                            )}
                          {messageCount.manualRecipients &&
                            messageCount.manualRecipients.length > 0 && (
                              <div className="manual_counts">
                                Manually added:&nbsp;
                                {messageCount.manualRecipients
                                  .filter((x, i) => i < spliceCount)
                                  .map((v, i) => (
                                    <button
                                      key={i}
                                      onClick={() =>
                                        navigate(
                                          `/Email/recipient?cam=${id}&type=48&edit=true`
                                        )
                                      }
                                      type="button"
                                      className="link inline-block nopadding"
                                    >
                                      {v["Recipient"] !== null
                                        ? v["Recipient"]
                                        : ""}
                                      {i ===
                                      messageCount.manualRecipients.length - 1
                                        ? ""
                                        : `,`}
                                      &nbsp;
                                    </button>
                                  ))}
                                {messageCount.manualRecipients &&
                                  messageCount.manualRecipients.length > 10 && (
                                    <button
                                      type="button"
                                      onClick={moreRecipients}
                                      className="toggle link"
                                    >
                                      {spliceCount > 10 ? "less" : "more.."}
                                    </button>
                                  )}
                              </div>
                            )}
                          {!!getAllEmailSendingDetails.recipientImportLink && (
                            <div>
                              Import:{" "}
                              <button
                                type="button"
                                onClick={() =>
                                  navigate(
                                    `/Email/recipient?cam=${id}&type=48&edit=true`
                                  )
                                }
                              >
                                {getAllEmailSendingDetails.recipientImportUrl}
                              </button>
                            </div>
                          )}
                        </>
                      ) : (
                        <span
                          className="skeleton-loader"
                          style={{ width: "30%" }}
                        ></span>
                      )}
                    </td>
                  </tr>
                  {getAllEmailSendingDetails.CcEmails && (
                    <tr>
                      <td>
                        <strong>CC</strong>
                      </td>
                      <td>
                        {getAllEmailSendingDetails.CcEmails.map(
                          (item, index) => (
                            <button
                              key={index}
                              type="button"
                              className="nobg noborder link pointer"
                              onClick={() =>
                                navigate(`/Email/Send/${id}?edit=true`)
                              }
                            >
                              {item.email},
                            </button>
                          )
                        )}
                      </td>
                    </tr>
                  )}

                  {getAllEmailSendingDetails.BCcEmails && (
                    <tr>
                      <td>
                        <strong>Bcc</strong>
                      </td>
                      <td>
                        {getAllEmailSendingDetails.BCcEmails.map(
                          (item, index) => (
                            <button
                              key={index}
                              type="button"
                              className="nobg noborder link pointer"
                              onClick={() =>
                                navigate(`/Email/Send/${id}?edit=true`)
                              }
                            >
                              {item.email},
                            </button>
                          )
                        )}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td>
                      <strong>Total</strong>
                    </td>
                    <td>
                      {allContentLoaded.aggregated ? (
                        <button
                          type="button"
                          className="nobg noborder link pointer"
                          onClick={() =>
                            navigate(
                              `/Email/recipient?cam=${id}&type=48&edit=true`
                            )
                          }
                        >
                          {messageCount.totalRecipientCount}
                        </button>
                      ) : (
                        <span
                          className="skeleton-loader"
                          style={{ width: "30%" }}
                        ></span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Sender Name </strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.SenderFrom.displayName}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Sender Email</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.SenderFrom.email}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Subject</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer word-wrap"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.Content.subject}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Reply To</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer"
                        style={{ display: "flex", flexWrap: "wrap" }}
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.ReplyTo_list.map((x, i) => (
                          <span key={i}>{x.email},</span>
                        ))}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Campaign Url</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer word-wrap"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.Content.detail.ShortUrl}
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Pre Header</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.Content.preHeaderText}
                      </button>
                    </td>
                  </tr>
                  {APITOKEN["config"].subscriptionInfo
                    ?.IsUTMVisibleOnSending ? (
                    <tr>
                      <td>
                        <strong>UTM</strong>
                      </td>
                      <td>
                        <button
                          type="button"
                          className="nobg noborder link pointer text-left"
                          onClick={() => navigate(`/Email/UTM/${id}?edit=true`)}
                        >
                          <>
                            {!!getAllEmailSendingDetails.utmParameters ? (
                              <>
                                {" "}
                                {
                                  getAllEmailSendingDetails.utmParameters
                                    .utm_campaign
                                }{" "}
                                <br></br>
                                {
                                  getAllEmailSendingDetails.utmParameters
                                    .utm_medium
                                }
                                <br></br>
                                {
                                  getAllEmailSendingDetails.utmParameters
                                    .utm_source
                                }{" "}
                                <br></br>
                                {
                                  getAllEmailSendingDetails.utmParameters
                                    .utm_content
                                }{" "}
                                <br></br>
                                {
                                  getAllEmailSendingDetails.utmParameters
                                    .utm_term
                                }
                              </>
                            ) : (
                              "-- Set UTM --"
                            )}
                          </>
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <></>
                  )}

                  <tr>
                    <td>
                      <strong>Attachment</strong>
                    </td>
                    <td>
                      {getAllEmailSendingDetails.attachments.map((v, i) => (
                        <span key={i}>
                          <span
                            className="link"
                            onClick={() =>
                              navigate(`/Email/Send/${id}?edit=true`)
                            }
                          >
                            {v.filename}
                          </span>
                          <br></br>
                        </span>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Email Option</strong>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="nobg noborder link pointer"
                        onClick={() => navigate(`/Email/Send/${id}?edit=true`)}
                      >
                        {getAllEmailSendingDetails.ScheduleDateTime ===
                        undefined
                          ? "Send Now"
                          : `${getAllEmailSendingDetails.ScheduleDateTime.dateTimeFormattedAsString}  ${getAllEmailSendingDetails.ScheduleDateTime.offset}`}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {rejectionReason.display ? (
              <>
                {" "}
                <div className="formmodel-bx margin-top50">
                  <label className="_l_able_cls">
                    <strong>Reason</strong>
                  </label>
                  <textarea
                    className="inputformmodel textareaModel"
                    value={rejectionReason.text}
                    placeholder="Reject reason"
                    onChange={e => {
                      setRejectReason(prv => ({
                        ...prv,
                        text: e.target.value,
                      }));
                    }}
                  ></textarea>
                </div>
                <div className="text-right  button_group">
                  <button
                    className="grid_btn_1 grid_btn_active"
                    type="button"
                    onClick={rejectionAPICall}
                    // disabled={rejectionReason.text !== "" ? false : true}
                  >
                    <ButtonLoader IsLoader={rejectionReason.loader}>
                      Done
                    </ButtonLoader>
                  </button>
                </div>
              </>
            ) : (
              <></>
            )}
            {makerCheckerCondition ? (
              <CheckerMakerContainer APITOKEN={APITOKEN} />
            ) : (
              <div className="text-right margin-top50 button_group">
                {allContentLoaded.aggregated && allContentLoaded.editLoad ? (
                  <>
                    {checkerCondition ? (
                      <button
                        className="grid_btn_1 noborder btn-gape-15 nobg"
                        type="button"
                        onClick={rejectCheckerRequest}
                      >
                        Reject
                      </button>
                    ) : (
                      <button
                        className="grid_btn_1 noborder btn-gape-15 nobg"
                        type="button"
                        onClick={requeueJobFn}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      className="grid_btn_1 grid_btn_active btn-gape-15"
                      disabled={makerCheckerCondition}
                      type="button"
                      onClick={messageCount.totalcount !== 0 && emailSend}
                    >
                      <ButtonLoader IsLoader={IsLoader}>
                        Send Email
                      </ButtonLoader>
                    </button>
                  </>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
        <div className=" phoneContainer margin-50">
          <label className="_previewContent">
            Preview Email -&nbsp;
            <a
              href={
                getAllEmailSendingDetails.Content &&
                getAllEmailSendingDetails.Content.detail.PreviewUrl
              }
              rel="noreferrer"
              target="_blank"
              id="_browser_preview"
              className="inline-block"
            >
              Open In Browser
            </a>
          </label>
          <div className="iphonePriview_Emailsend">
            {!getAllEmailSendingDetails.Content ? (
              <div className="padding20">
                {[1, 2, 3, 4].map((v, i) => (
                  <div key={i}>
                    <div
                      className="skeleton-loader"
                      style={{ height: "50px", marginBottom: "20px" }}
                    ></div>
                    <div
                      className="skeleton-loader"
                      style={{ height: "20px", marginBottom: "20px" }}
                    ></div>
                  </div>
                ))}
              </div>
            ) : (
              <iframe
                id="ab"
                title="preview"
                src={
                  getAllEmailSendingDetails.Content &&
                  getAllEmailSendingDetails.Content.detail.PreviewUrl
                }
                className="noborder"
              ></iframe>
            )}
          </div>
        </div>
      </div>
      {lowBlance.display ? (
        <BalanceCheckFn
          APITOKEN={APITOKEN}
          lowBlance={lowBlance}
          setLowBalancePopup={setLowBalancePopup}
          removeWaterMark={() => emailSend()}
        />
      ) : (
        <></>
      )}
    </>
  );
};
