import React, { useState, useEffect, Fragment } from 'react';
import { get as _get } from "lodash";
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';
import { Carousel } from 'react-responsive-carousel';

import iPhoneHeader from './../../../../assets/img/iphone-header.png';
import iPhoneFooter from './../../../../assets/img/iphone-footer.png';
import iconCall from './../../../../assets/img/icon-call.svg';
import iconEmail from './../../../../assets/img/icon-email.svg';
import iconDirection from './../../../../assets/img/icon-direction.svg';
import iconWebsite from './../../../../assets/img/icon-website.svg';

import { createStore } from "../../../../services/store";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const OpeningHours = ({ storeDetails, fetchStoreData }) => {

	const defaultHoursData = { isOpeningHoursVisible: false, mondayOpeningHours: "", tuesdayOpeningHours: "", wednesdayOpeningHours: "", thursdayOpeningHours: "", fridayOpeningHours: "", saturdayOpeningHours: "", sundayOpeningHours: "", };
	const [storeOpeningHours, setStoreOpeningHours] = useState(_get(storeDetails, "storeOpeningHours", defaultHoursData));
	const [isLoader, setIsLoader] = useState(false);

	// If Props changed from, re-populate values in fields
	useEffect(() => {

		setStoreOpeningHours(_get(storeDetails, "storeOpeningHours", defaultHoursData));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [storeDetails]);

	const handleRadioChange = (e) => {
		const isVisible = e.target.value === 'yes';
		setStoreOpeningHours((prevState) => ({
			...prevState,
			isOpeningHoursVisible: isVisible,
		}));
	};

	const handleInputChange = (e, day, type) => {
		const value = e.target.value;
		const currentHours = storeOpeningHours[`${day}OpeningHours`];
		let newHours;

		if (type === 'from') {
			const toTime = currentHours.split(' - ')[1] || '';
			newHours = `${value} - ${toTime}`;
		} else {
			const fromTime = currentHours.split(' - ')[0] || '';
			newHours = `${fromTime} - ${value}`;
		}

		setStoreOpeningHours((prevState) => ({
			...prevState,
			[`${day}OpeningHours`]: newHours,
		}));
	};

	const handleSubmit = () => {
		(async () => {
			try {

				setIsLoader(true);
				const mergedData = {
					"storeId": _get(storeDetails, "storeId", ""),
					"storeOpeningHours": storeOpeningHours,
					"isCompleted": true
				};

				const response = await createStore(mergedData);
				setIsLoader(false);
				if (_get(response, "flag", false) === false) {

				} else {
					fetchStoreData(true);
				}
			} catch (err) {
				console.log('Error occured when submitting data', err);
			}
		})();
		//sendDataToParent(storeOpeningHours, 4);
	};

	return (
		<div className="bx-boder-common width100 nobg mt-8">
			<div className="_h_header white border-radius2">
				<h3>Opening hours</h3>
			</div>

			<div className="p-5 white right-appscreenshot-height">
				<div className="row">
					<div className="col-8">
						<div className="formmodel-bx">
							<label className="_l_able_cls">Display opening hours in app</label>
							<div className="flex align-items-center gap-5">
								<label htmlFor="yes">
									<input
										type="radio"
										className="inline-block me-2"
										id="yes"
										name="displayopeninghours"
										value="yes"
										checked={storeOpeningHours.isOpeningHoursVisible}
										onChange={handleRadioChange}
									/>
									Yes
								</label>

								<label htmlFor="no">
									<input
										type="radio"
										className="inline-block me-2"
										id="no"
										name="displayopeninghours"
										value="no"
										checked={!storeOpeningHours.isOpeningHoursVisible}
										onChange={handleRadioChange}
									/>
									No
								</label>
							</div>
						</div>

						{storeOpeningHours.isOpeningHoursVisible && (
							['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'].map((day) => (
								<div className="formmodel-bx" key={day}>
									<label className="_l_able_cls">{day.charAt(0).toUpperCase() + day.slice(1)}</label>
									<div className="flex gap-3">
										<div>
											<label className="block mb-1">From Time</label>
											<input
												type="time"
												className="inputformmodel width186"
												value={storeOpeningHours[`${day}OpeningHours`].split(' - ')[0] || ''}
												onChange={(e) => handleInputChange(e, day, 'from')}
											/>
										</div>
										<div>
											<label className="block mb-1">To Time</label>
											<input
												type="time"
												className="inputformmodel width186"
												value={storeOpeningHours[`${day}OpeningHours`].split(' - ')[1] || ''}
												onChange={(e) => handleInputChange(e, day, 'to')}
											/>
										</div>
									</div>
								</div>
							))
						)}

						<div className="formmodel-bx text-right mt-12 mb-0">
							<button
								type="button"
								className="btn btn-primary btn-gape-15"
								onClick={handleSubmit}
							>
								<ButtonLoader IsLoader={isLoader}>
									Save
								</ButtonLoader>
							</button>
						</div>
					</div>

					<div className="right-appscreenshot mt-6 ms-auto me-auto">
						<div className="right-appscreenshot-banner">
							<div className="overflow-hidden">
								<img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
								<img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

								<p className="right-appscreenshot-banner-header-text">{(storeDetails.storeName || "")}</p>

								<div className="right-appscreenshot-storeimage">
									<Carousel showThumbs={false} swipeable={true} interval={2000} autoPlay infiniteLoop>
										{(_get(storeDetails, "image", []).length > 0) && _get(storeDetails, "image", []).map((image, i) => (
											<div key={i}>
												<img src={_get(image, "imageUrl")} alt="" />
											</div>
										))}
									</Carousel>
								</div>

								<div className="right-appscreenshot-rating">
									<div className="right-appscreenshot-rating-left">
										{(_get(storeDetails, "storeButtons.isRatingVisible", false) === true) && (
											<Fragment>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
													<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
												</svg>
												<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
													<path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
												</svg>
											</Fragment>
										)}
									</div>

									<div className="right-appscreenshot-rating-right">
										{((storeOpeningHours.isOpeningHoursVisible || false) === true) && (
											<p><span>Open</span> - Closing 16</p>
										)}
									</div>
								</div>

								{((storeOpeningHours.isOpeningHoursVisible || false) === true) && (
									<div className="right-appscreenshot-time-dropdown">
										{((storeOpeningHours.mondayOpeningHours || "") !== "") && (
											<div>
												<span>Monday</span>
												<span>{(storeOpeningHours.mondayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.tuesdayOpeningHours || "") !== "") && (
											<div>
												<span>Tuseday </span>
												<span>{(storeOpeningHours.tuesdayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.wednesdayOpeningHours || "") !== "") && (
											<div>
												<span>Wednesday </span>
												<span>{(storeOpeningHours.wednesdayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.thursdayOpeningHours || "") !== "") && (
											<div>
												<span>Thursday </span>
												<span>{(storeOpeningHours.thursdayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.fridayOpeningHours || "") !== "") && (
											<div>
												<span>Friday </span>
												<span>{(storeOpeningHours.fridayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.saturdayOpeningHours || "") !== "") && (
											<div>
												<span>Saturday </span>
												<span>{(storeOpeningHours.saturdayOpeningHours || "")}</span>
											</div>
										)}
										{((storeOpeningHours.sundayOpeningHours || "") !== "") && (
											<div>
												<span>Sunday</span>
												<span>{(storeOpeningHours.sundayOpeningHours || "")}</span>
											</div>
										)}

									</div>
								)}

								<div className="right-appscreenshot-icon">
									{(_get(storeDetails, "storeButtons.isPhoneNumberVisible", false) === true) && (
										<span>
											<img src={iconCall} alt="call" />
											Call
										</span>
									)}
									{(_get(storeDetails, "storeButtons.isEmailAddressVisible", false) === true) && (
										<span>
											<img src={iconEmail} alt="call" />
											email
										</span>
									)}
									{(_get(storeDetails, "storeButtons.isDirectionDisplayVisible", false) === true) && (
										<span>
											<img src={iconDirection} alt="call" />
											directions
										</span>
									)}
									{(_get(storeDetails, "storeButtons.isDisplayWebSiteVisible", false) === true) && (
										<span>
											<img src={iconWebsite} alt="call" />
											website
										</span>
									)}
								</div>

								<div className="right-appscreenshot-tabs">
									<div className="right-appscreenshot-tabs-links">
										<p className="active">Info</p>
										<p>Inbox</p>
										<p>cards</p>

										<span className="followed">Followed</span>
									</div>
								</div>

								<div className="right-appscreenshot-desc">
									{((storeDetails.storeInfo || "") !== "") && (storeDetails.storeInfo || "").split('\n').map((text, index) => (
										<Fragment key={index}>
											{text}
											<br />
										</Fragment>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OpeningHours;
