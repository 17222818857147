import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Client from '../../GlobalAPICall';
import StepsDistribution from '../StepsTopBar';
import './index.css'
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';
export const MobileSummary = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [IsLoader, setIsLoader] = useState(false);

    const [getInfo, setAllEmailSendingDetails] = useState({});
    const [isloader, setLoader] = useState(true);
    // const [messageCount, setMessageCount] = useState({ listWiseRecipientsDetails: [], manualRecipients: [] });
    const navigate = useNavigate();

    const load = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Mobile/Distribution/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setLoader(false);
                        setAllEmailSendingDetails(data);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {

                });
    }, [APITOKEN, id]);



    const emailSend = () => {
        setIsLoader(true);
        APITOKEN.token &&
            Client.post(`Mobile/Publish?id=${id}`, {}, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        if (data.Success) {
                            setIsLoader(false);
                            navigate('/dashboard');
                            // (data.IsScheduled) ? navigate('/dashboard') : navigate(`/insights/smsDetails/${data['Id']}?f=13`);
                        }

                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err);
                });
    }

    useEffect(() => {
        load();
    }, [load])
    return (
        <>
            <StepsDistribution Name="Mobile" Active={4} Hide={true} />
            <div className="dashbd_sec _sms_Summary margin-bottom">
                <div className="sms_Sec_1 bx-boder-common inline-block">
                    <div className="_h_header">
                        <h3>Mobile Summary</h3>
                    </div>
                    <div className="bx-pad">
                        {isloader ? <>
                            {[1, 2, 3, 4].map((i) => <div key={i}><div className="skeleton-loader" style={{ height: '50px', marginBottom: '20px' }}></div><div className="skeleton-loader" style={{ height: '20px', marginBottom: '20px' }}></div></div>)}
                        </> :
                            <table className="summary_table" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td><strong>Distribution	</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.pushNotificationTypeText}
                                        </button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Push Notification</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.pushNotificationText}
                                        </button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Classification</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.classificationName}
                                        </button></td>

                                    </tr>
                                    {/*<tr>
                                        <td><strong>Store Category</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.storeCategoryName}
                                        </button></td>

                                    </tr>*/}
                                    {(APITOKEN['config'].subscriptionInfo?.IsUserHasStore) && (
                                        <tr>
                                            <td><strong>Store</strong></td>
                                            <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                                {getInfo.storeName}
                                            </button></td>

                                        </tr>
                                    )}
                                    <tr>
                                        <td><strong>Content</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.contentType}
                                        </button></td>

                                    </tr>
                                    <tr>
                                        <td><strong>Landing Page</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.campaignName}
                                        </button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Title text in app</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.titleText}
                                        </button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Expiration date in app</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/Mobile/Send/${id}?edit=true`)}>
                                            {getInfo.expirationDate}
                                        </button></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        <div className="text-right margin-top50 button_group">
                            <button className="grid_btn_1 grid_btn_active btn-gape-15" disabled={isloader} type="button" onClick={emailSend}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    Send
                                </ButtonLoader>

                            </button>
                        </div>

                    </div>
                </div>
                {/* <div className="previewPanel margin-50 slideAnimation_active">
                    <label className="_previewContent">Preview SMS
                        {getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.detail.PreviewUrl !== "" && <>-&nbsp;<a href={getAllEmailSendingDetails.Content.detail.PreviewUrl} rel="noreferrer" target="_blank" id="_browser_preview" className="inline-block">Open In Browser</a></>}
                    </label>
                    <div className="iphonePriview prvewSMS">
                        {getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.typeId === 7 ? <><div className="smstxtphone">{getAllEmailSendingDetails.Message}</div></> : <iframe id="ab" title="preview" src={getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.detail.PreviewUrl} className="noborder"></iframe>}

                    </div>
                </div> */}
            </div>
        </>)

}

