import { useSelector } from 'react-redux';
import Performance from './PerFormance'
import { useCallback, useEffect, useState } from 'react';
import Client from '../../../../GlobalAPICall';
import { useParams } from 'react-router';
import WedgetGrid from './WedgetGrid';

const Engagment = () => {
  const { id } = useParams();
  const checkIsAuth = useSelector(state => state);
  const [wedgetData, setWedgetGridData] = useState([])

  const loadApiCall = useCallback(async () => {
    try {
      (checkIsAuth['APITOKEN']['token'] !== "") &&
        Client.get(`Insights/landingpage/${id}/KPI/engagement`, {
          headers: {
            'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
          }
        }).then((res) => {
          try {
            setWedgetGridData(res['data'])
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [checkIsAuth, id]);

  useEffect(() => {
    loadApiCall();
  }, [loadApiCall])
  return (
    <>
      <div className="bx-boder-common white">
        <Performance />
      </div>
      <div className="overviewtable bx-boder-common white  margin-top50">
        <div className="_h_header">
          <h3>Widget Clicks</h3>
        </div>
        <div className="widgetContainer"><WedgetGrid Data={wedgetData} /></div>
      </div>
    </>
  )

}

export default Engagment
