import { useCallback, useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link, useLocation } from "react-router-dom";
import BlankData from "../../BlankData";
import Client from "../../GlobalAPICall";
import DeleteListTemplate from "../../ReuseTemplate";
import UTMSlider from "./UTMSlider";

const CampaignURL = () => {
    const checkIsAuth = useSelector(state => state);
    const [camData, setCampaignData] = useState([]);
    const [campaignUTMTemplateURLData, setCampaignUTMTemplateURL] = useState([]);
    const [campaignShortData, setCampaignShortURL] = useState([]);
    const [editUTMValue, setEditUTMValue] = useState({});
    let [deletObject, setDeletObject] = useState({ id: "", flag: false });

    const { id } = useParams();
    const loacation = useLocation();
    const link = useRef(null);
    const shotLink = useRef(null);
    const load = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`Campaign/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                link.current.href = res['data'].SlugUrl;
                shotLink.current.href = res['data'].ShortUrl;
                setCampaignData(res['data']);
            });
    }, [checkIsAuth, id])
    const campaignUTMTemplateURL = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`UTM/CampaignUTMTemplateURL?campaignId=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                try {
                    setCampaignUTMTemplateURL(res['data']);
                } catch (err) {
                    console.log(err);
                }
            });
    }, [checkIsAuth, id])
    const campaignShortURL = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`UTM/CampaignUTMTemplateShortURL?campaignId=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                try {
                    setCampaignShortURL(res['data']);
                } catch (err) {
                    console.log(err);
                }

            });
    }, [checkIsAuth, id])
    useEffect(() => {
        load()
        campaignUTMTemplateURL();
        campaignShortURL();
    }, [load, campaignUTMTemplateURL, campaignShortURL]);
    const copy = (id) => {
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
    const deleteUTMURL = (o) => {
        setDeletObject({ id: o['rowUID'], flag: true })
    }
    const deleteEvent = () => {
        Client.delete(`UTM/CampaignUTMTemplateURL/${deletObject.id}`, {
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        }).then((res) => {
            try {
                if (res.data.success) {
                    campaignUTMTemplateURL();
                    campaignShortURL();
                    setDeletObject({ flag: false })
                }
            } catch (err) {
                console.log(err);
            }

        });

    }
    const [utmShow, setUTMEvent] = useState(false);
    const [editId, setEditId] = useState("");
    const createUTMURL = () => {
        setUTMEvent(true);
        setEditUTMValue({});
    }
    const editUTMCode = (v) => {
        setUTMEvent(true);
        setEditUTMValue({ ...editUTMValue, ...{ ...v } });
        setEditId(v['rowUID']);
    }
    return (
        <div className="dashbd_sec">
            <div className="sms_Sec_1 bx-boder-common width100">
                <div className="_h_header">
                    <h3>URL</h3>
                </div>
                <table cellPadding="0" cellSpacing="" className="smstable">
                    <thead>
                        <tr>
                            <th className="table_cell_header" width="80%">Original URL</th>
                            <th className="table_cell_header">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td className="body_table_cell full-url"><input className="width100 nobg" id="_rawUrl" type="text" defaultValue={camData.SlugUrl} readOnly /></td>
                            <td className="body_table_cell">
                                {/* <Link to="" className="link inline-block">Edit</Link> &nbsp; */}
                                <Link to={loacation.pathname} className="link inline-block -copy pointer" onClick={() => copy('_rawUrl')}>Copy</Link> &nbsp;
                                <Link ref={link} target="_blank" className="link inline-block pointer">Open</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table cellPadding="0" cellSpacing="" className="smstable b_top" style={{borderRadius:'0'}}>
                    <thead>
                        <tr>
                            <th className="table_cell_header" width="80%">Short URL</th>
                            <th className="table_cell_header">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr><td className="body_table_cell full-url"><input className="width100 nobg" id="_shortUrl" type="text" defaultValue={camData.ShortUrl} readOnly /></td>
                            <td className="body_table_cell">
                                {/* <Link to="" className="link inline-block">Edit</Link> &nbsp; */}
                                <Link to={loacation.pathname} className="link inline-block -copy pointer" onClick={() => copy('_shortUrl')}>Copy</Link> &nbsp;
                                <Link ref={shotLink} target="_blank" className="link inline-block pointer">Open</Link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>


            {deletObject.flag ? <div className="margin-top50"><DeleteListTemplate onClick={() => setDeletObject({ flag: false })} deleteDownload={deleteEvent} title="Click on Delete to delete this UTM." content="This will permanently delete this Folder and it can not be restored." colSpan="6" /></div> :
                <><div className="sms_Sec_1 bx-boder-common width100"><div className="_h_header">
                    <h3>Your UTM Codes URLs for specific channels</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 nobg" onClick={createUTMURL}>Create UTM URL</button>
                    </span>
                </div>
                    <table cellPadding="0" cellSpacing="" className="smstable">
                        {campaignUTMTemplateURLData.length === 0 ? <tbody><BlankData title="You have not created any URLs with UTM codes yet." content="Create URLs with UTM codes to get insights from specific channels." colSpan="6" /></tbody> : <>
                            <thead>
                                <tr>
                                    <th className="table_cell_header" >Name</th>
                                    <th className="table_cell_header" >UTM Codes URL</th>
                                    <th className="table_cell_header" >Creation Time</th>
                                    <th className="table_cell_header" >Created By</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaignUTMTemplateURLData && campaignUTMTemplateURLData.map((v, i) =>
                                    <tr key={i}>
                                        <td className="body_table_cell">{v['name']}</td>
                                        <td className="body_table_cell"><textarea className="width100 nobg noborder utmcodetextarea" id={`utmtemplate${i}`} type="text" readOnly value={camData.SlugUrl + '?' + v['utmCodes']}>{camData.SlugUrl}?{v['utmCodes']}</textarea></td>
                                        <td className="body_table_cell">{v['creationTime']}</td>
                                        <td className="body_table_cell">{v['createdBy']}</td>
                                        <td><button type="button" className="inline-block link  nobg noborder pointer nopadding" onClick={() => editUTMCode(v)} >Edit</button>&nbsp;
                                            <button type="button" className="inline-block link pointer nobg noborder" onClick={() => copy(`utmtemplate${i}`)}>Copy</button>&nbsp;
                                            <button type="button" className="inline-block link pointer nobg noborder nopadding" onClick={() => deleteUTMURL(v)}>Delete</button></td>
                                    </tr>
                                )}
                            </tbody>
                        </>}
                    </table>
                </div>
                </>
            }

            <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                <div className="_h_header">
                    <h3>Short URL</h3>
                </div>
                <table cellPadding="0" cellSpacing="" className="smstable">

                    {campaignShortData.length === 0 ? <tbody><BlankData title="You have not created any short URLs yet." content="A short URL is convenient when characters are limited and you are sharing the URL." colSpan="6" /></tbody> : <>
                        <thead>
                            <tr>
                                <th className="table_cell_header" >Short URL</th>
                                <th className="table_cell_header" >Domain</th>
                                <th className="table_cell_header" >Slug</th>
                                <th className="table_cell_header" >UTM Name</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                campaignShortData && campaignShortData.map((v, i) => <tr key={i}>
                                    <td className="body_table_cell">
                                        <textarea className="width100 nobg noborder utmcodetextarea" type="text" id={`shortTemplate${i}`} readOnly value={v['shortUrl']} style={{ height: '19px' }}>{v['shortUrl']}</textarea>
                                    </td>
                                    <td className="body_table_cell">{v['domain']}</td>
                                    <td className="body_table_cell">{v['slugName']}</td>
                                    <td className="body_table_cell">{v['name']}</td>
                                    <td>
                                        <button type="button" className="inline-block link noborder pointer nobg nopadding" onClick={() => copy(`shortTemplate${i}`)}>Copy</button>&nbsp;&nbsp;
                                        <a rel="noreferrer" href={v.shortUrl} target="_blank" className="inline-block link noborder pointer nobg">Open</a></td></tr>)
                            }
                        </tbody>
                    </>}
                </table>
            </div>
            {
                utmShow ? <UTMSlider sliderEvent={setUTMEvent} campaignUTMTemplateURL={campaignUTMTemplateURL} campaignShortURL={campaignShortURL} EditUTMValue={editUTMValue} EditId={editId} /> : <></>
            }
        </div>

    )
}
export default CampaignURL;
