import { request, requestAuth } from ".";
import { ENDPOINTS } from "./network";

export const getNonce = async () => {
  const res = await request(ENDPOINTS.getNonce);
  return res.data.data.message;
};

export const verifyNonce = async (payload) => {
  return request({
    ...ENDPOINTS.verifyNonce,
    payload,
  });
};

export const logout = async () => {
  // return request(ENDPOINTS.logout);
  localStorage.removeItem("persist:promoti");

  console.log("logout");
};

export const getProfile = async () => {
  return requestAuth(ENDPOINTS.profile);
};
