import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import GridLoader from "../../ReuseTemplate/GridLoader";
import getLocaleDateString from "../../TimeFormat";
import moment from "moment";
import CountryList from "../../ReuseTemplate/Country";

const CurrentUsage = ({ UsageData }) => {
    const { APITOKEN } = useSelector(state => state);
    const f = getLocaleDateString(APITOKEN.config.cultureCode)
    const [selectedCountry, setSelectedCountry] = useState({
        selectedValue: "",
        flag: ""
    });
    const [getResponse, setResponse] = useState({
        data: [],
        isDisplay: true
    });
    const [smsPricig, setSMSPricing] = useState([]);
    const [isloadSMS, setLoader] = useState(false);
    const [flagCountry, setFlagCountry] = useState(0);
    const [getCountryResponse, setCountryResponse] = useState([]);
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('User/Consumption', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setResponse({
                        data: res['data'],
                        isDisplay: false
                    });
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN]);
    const LoadSMS_Pricing = useCallback(() => {
        setLoader(true);
        (APITOKEN['token'] !== "" && !!UsageData.currency) &&
            Client.get(`User/SMS_Pricing/${UsageData.currency}/${selectedCountry.selectedValue}?billingCycle=None`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setLoader(false);
                    setSMSPricing(data);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => setLoader(false));
    }, [APITOKEN, UsageData.currency, selectedCountry.selectedValue]);
    const countryLoad = useCallback(async () => {
        const { data } = await CountryList();
        try {
            setCountryResponse(data);
            const filter = data.filter((item) => item.isOcountrycode === APITOKEN.config.countryCode);
            setSelectedCountry({
                selectedValue: filter[0]?.isOcountrycode || "",
                flag: filter[0]?.flagPath || ""
            });
        } catch (err) {
            console.log(err)
        }
    }, [APITOKEN]);
    const selectCpuntryFn = ev => {
        const filter = getCountryResponse.filter((item) => item.isOcountrycode === ev);
        setSelectedCountry({
            selectedValue: filter[0].isOcountrycode,
            flag: filter[0].flagPath
        });
        setFlagCountry(1);
    }
    useEffect(() => {
        load();

        LoadSMS_Pricing();
        flagCountry === 0 &&
            countryLoad();
    }, [load, countryLoad, LoadSMS_Pricing, flagCountry])
    return (
        <> <div className="white bx-boder-common width100">
            <div className="_h_header">
                <h3>Period: {APITOKEN.config.subscriptionInfo && moment(APITOKEN.config.subscriptionInfo.SubcriptionStartTime).format(f)} - {APITOKEN.config.subscriptionInfo && moment(APITOKEN.config.subscriptionInfo.SubscriptionEndTime).format(f)}</h3>
            </div>
            {getResponse.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5]} /> :
                <table className="smstable" cellPadding="0" cellSpacing="0">
                    <thead>
                        <tr><th className="table_cell_header"><strong>Resources</strong></th>
                            <th className="table_cell_header"><strong>Included</strong></th>
                            <th className="table_cell_header"><strong>Used</strong></th>
                            <th className="table_cell_header" style={{ display: 'none' }}><strong>Extra</strong></th>
                            <th className="table_cell_header"><strong>Previous Subscription </strong></th>
                            <th className="table_cell_header" style={{ display: (APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 12) ? 'none' : 'table-cell' }}><strong>Your Price Plan</strong></th>
                        </tr>
                    </thead>
                    <tbody>
                        {getResponse.data.length > 0 && getResponse.data.map((x, i) =>
                            <tr key={i}><td className="body_table_cell">{x['resourceName']}</td>
                                <td className="body_table_cell">{x['included']}</td>
                                <td className="body_table_cell">{x['unitsConsumed']}</td>
                                <td className="body_table_cell" style={{ display: (true) ? 'none' : 'block' }}>{x['extraUnits']}</td>
                                <td className="tb_previous">{x['previousSubscriptionUsage']}</td>
                                <td className="body_table_cell" style={{ display: (APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 12) ? 'none' : 'table-cell' }}>{(x['resourceName'] === 'SMS') ? <a href="#price_list_bx" className="link">Price List</a> : <>{x['currency']} {x['price']}</>} </td>
                            </tr>)}
                    </tbody>

                </table>
            }
        </div>
            {
                (APITOKEN.config && (APITOKEN.config.themeID === 6 || APITOKEN.config.themeID === 10 || APITOKEN.config.themeID === 12)) ? <></> :
                    <>
                        <div className="sms_Sec_1 bx-boder-common width100  margin-bottom">
                            <div className="padding20">
                                <h2 className="fontsize30">Select a country to see the different SMS prices</h2>
                            </div>
                            <div className="padding20">
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Country</label>
                                    <select className="inputformmodel" value={selectedCountry.selectedValue} onChange={e => selectCpuntryFn(e.target.value)}>
                                        {getCountryResponse && getCountryResponse.map((v, i) => <option key={i} value={v.isOcountrycode} >{v.countryname}</option>)}

                                    </select>
                                    <span className="flag">
                                        <img src={selectedCountry.flag} alt="" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="__billingSMS_bx text-center margin-bottom" id="price_list_bx">
                            {isloadSMS ? <></> : smsPricig.map((v, i) =>
                                <div className="__box__price__Info bx-boder-common __compare" key={i}>
                                    <div className="p__header">
                                        {v.Name}
                                    </div>
                                    <div className="price__details">
                                        <p className="nomargin fweight300 text-left">
                                            {v.Title}
                                        </p>
                                        <h3 className="cost_b  margin-bottom-none text-left">
                                            <span className="__symbol">{v.Symbol}&nbsp;</span>
                                            {(v['Price'] !== -1) ? v['Price'] : 'Quote'}
                                            <span className="m--y" style={{ verticalAlign: 'super', 'fontSize': '20px' }}>{(v['Price'] === -1) ? ` ` : '*'}</span>
                                        </h3>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="">
                            <h3 className="fweight300 font-nuito margin-bottom" style={{ fontSize: '20px' }}>
                                * SMS prices for all subscriptions are based on our price in Euro. For other currencies we are using a currency convertion. Exchange rates are updated every hour through our integration with  <a href="https://www.openexchangerates.org" target="_blank" rel="noreferrer" className="inline-block link" style={{ fontSize: '21px' }}>https://www.openexchangerates.org</a>
                                . So when a revenue movement occurs, we’ll use the most recently captured exchange rate to convert the amount into your default currency.

                            </h3>
                        </div>
                    </>
            }
        </>
    )

}

export default CurrentUsage
