import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall"
import OngoingResponse from "./OngoingResponse"
import ResponseGrid from "./ResponseGrid"
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import CountResponseChart from "./CountResponseChart";
import RateResponseChart from "./RateResponseChart";

const Response = ({ postObject }) => {
    const { APITOKEN } = useSelector(state => state);
    const [reponseData, setReponseData] = useState({});
    const [reponseChartData, setChartReponseData] = useState([]);
    const [loader, SetLoader] = useState(false);
    const { id } = useParams();
    const [tabId, setTabid] = useState(1);
    const loadApiCall = useCallback(async () => {
        SetLoader(true);
        (APITOKEN['token'] !== "") &&
            Client.post(`Insights/Survey/${id}/Responses`, postObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    SetLoader(false);
                    try {
                        setReponseData(data);
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.error(err);
                })
    }, [APITOKEN, id, postObject]); // <-- add any missing dependencies react warns about

    const loadApiChartCall = useCallback(async () => {

        (APITOKEN['token'] !== "") &&
            Client.post(`Insights/Survey/${id}/performance`, postObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    setChartReponseData(data);
                }).catch(err => {
                    console.error(err);
                })
    }, [APITOKEN, id, postObject]); // <-- add any missing dependencies react warns about

    const clickTab = (id) => {
        setTabid(id);
    }

    useEffect(() => {
        loadApiCall();
        loadApiChartCall();
    }, [loadApiCall, loadApiChartCall])
    return (
        <div>
            <div className="bx-boder-common white">
                <div className="_h_header noborder">
                    <h3 style={{ display: 'flex', flex: '1' }} className="drop-zone">Performance
                        <ul className="responsePreformceListing">
                            <li><button type="button" onClick={() => clickTab(1)} className={`${tabId === 1 ? 'active' : ''}`}>Count</button></li>
                            <li><button type="button" onClick={() => clickTab(2)} className={`${tabId === 2 ? 'active' : ''}`}>Rate</button></li>
                        </ul>
                    </h3>
                </div>
                <div className="padding20 white">
                    <div className="response_graph">
                        {tabId === 1 ? <CountResponseChart Data={reponseChartData} tabId={tabId} /> : <RateResponseChart tabId={tabId} Data={reponseChartData} />}
                    </div>
                </div>
                <div className="padding20">
                    <div className="_overviewPin white summary_o_v"></div>
                    <div className="-n-d-p">
                    </div>
                </div>
            </div>
            <ResponseGrid Data={reponseData} Loader={loader} APITOKEN={APITOKEN} />
            <OngoingResponse Data={reponseData} Loader={loader} APITOKEN={APITOKEN} />
        </div>
    )

}

export default Response
export const LoaderGrid = () => {
    return (
        <>
            {[1, 2, 3].map((x, i) => <div className="bx-boder-common margin-bottom" key={i}>
                <div className="_h_header">
                    <h3 className="skeleton-loader"><span className="skeleton-loader"></span></h3>
                </div>
                <div className="padding20">
                    <div className="skeleton-loader" style={{ height: '40px', marginBottom: '10px' }}></div>
                    <div className="skeleton-loader" style={{ height: '40px' }}></div>
                </div>
            </div>)}

        </>
    )
}