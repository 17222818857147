
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import BlankData from "../../../../BlankData";
import Client from "../../../../GlobalAPICall";
import GridLoader from "../../../../ReuseTemplate/GridLoader";


const RecipientGrid = ({TotalRecipients,APITOKEN}) => {
    const { id } = useParams();
    const [recipientData, setRecipientData] = useState({
        data: [],
        isDisplay: true
    });
    const [skip, setSkip] = useState(0);
    const [totalRecode, setTotalRecord] = useState(0);
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/Email/${id}/Recipients?skip=${skip}&limit=10`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    // if (data.length > 0) {
                    console.log(TotalRecipients)
                        if (((skip + 1) * 10) > TotalRecipients) {
                            setpainationButton(prv => ({ ...prv, next: true }));
                        } else {
                            if (data.length < 10) {
                                if (skip === 0) {
                                    setpainationButton(prv => ({ ...prv, previous: true, next: true }));
                                };
                            }
                            setpainationButton(prv => ({ ...prv, next: false }))
                        };
                    // }
                    setRecipientData(prv => ({
                        ...prv,
                        data,
                        isDisplay: false
                    }));
                    setTotalRecord(TotalRecipients);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id, skip,TotalRecipients]);
    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {recipientData.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6]} /> :
                <table cellPadding="0" className="smstable table-layout-default" cellSpacing="">
                    <thead>
                        {recipientData &&
                            recipientData.data.length === 0 ?
                            <BlankData title="You have no Recipients any A Page yet." content="With a template you are able to configure and change your Recipients Page without involving your technical derpartment." colSpan="8" /> : <tr>
                                <th className="table_cell_header">First Name</th>
                                <th className="table_cell_header">Last Name</th>
                                <th className="table_cell_header">Email</th>
                                <th className="table_cell_header">Click</th>
                                <th className="table_cell_header">Open</th>
                                <th className="table_cell_header">Mobile Number</th>
                                <th className="table_cell_header">Zip Code</th><th className="table_cell_header">Status</th>
                            </tr>
                        }

                    </thead>
                    <tbody>
                        <Grid DataGrid={recipientData} />
                    </tbody>
                </table>
            }
            {recipientData.data.length === 0 ? <></> :
                <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                    <span className="pagination_count vertical-middle">
                        <span>{skip + 1}</span>-
                        <span>{(skip + 1) * 10}</span> of&nbsp;
                        <span>{totalRecode}</span>
                    </span>
                    <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                    <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                </div>
            }

        </>
    )
}
export default RecipientGrid;

const Grid = ({ DataGrid }) => {
    return (
        <>
            {DataGrid && DataGrid.data.map((v, i) => <tr key={i}>
                <td className="body_table_cell">{v['FirstName']}</td>
                <td className="body_table_cell">{v['LastName']}</td>
                <td className="body_table_cell">{v['Email']}</td>
                <td className="body_table_cell">{v['Clicks']}</td>
                <td className="body_table_cell">{v['Opens']}</td>
                <td className="body_table_cell">{v['MobileNumber']}</td>
                <td className="body_table_cell">{v['ZipCode']}</td>
                <td className="body_table_cell">{v['Status']}</td>
            </tr>
            )}
        </>
    )
}

