import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router';
import { forEach } from 'lodash';

const Visitor = ({ ...props }) => {
    const {APITOKEN} = useSelector(state => state);
    const { id } = useParams();
    const { PostObject } = props;
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'area'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: '',
            align: 'right',
            verticalAlign: 'bottom'
        },
        xAxis: {
            type: 'datetime',
            accessibility: {
                rangeDescription: ''
            },
        },
        accessibility: {
          enabled:false  
        },
        yAxis: {
            title: {
                text: 'Total Responses'
            }

        },
        tooltip: {
            split: true,
            backgroundColor: '#fff',
            borderColor: '#eee',
            borderRadius: 3,
            borderWidth: 1,
            shadow: false,
            useHTML: false,
            crosshairs: true,
            shared: true,
            valueSuffix: '',
            xDateFormat: '%Y-%m-%d',
            formatter: function () {
                var s = [];
                const self = this;
                s.push('<table width="100%" cellspacing="0" cellpadding="0"><tr><td style="font-family:arial;background:#fff;color:#000;font-size:15px;padding:5px 0;font-weight:bold;text-align:center">' + this.x + '</td></tr></table>');
                forEach(self.points, function (point, i) {
                    s.push('<table width="100%"><tr><td style="font-size:12px;padding-bottom:5px;padding-top:5px;font-family:roboto;color:#1b283e;">' + point.series.name + ' </td><td style="padding-left:10px;text-align:right;"><b style="text-align:center; line-height:20px;min-width:20px;height:20px;color:#000;display:inline-block;font-family:roboto;padding:0 10px;font-size:12px";"> &nbsp;' + point.y + '</td></tr></table>');
                })
                return s;
            },

        },
       
        plotOptions: {
            area: {
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: 5,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {

                name: "Visitors",
            }
        ]
    });
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.post(`Insights/Survey/${id}/visitors`, PostObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    dataFilter(res.data);
                } catch (err) {
                    console.log(err);
                }

            });
    }, [APITOKEN, id, PostObject]);

    const dataFilter = (data) => {
        const ar = [];
        const reponse = [];
        data.forEach(async function (v, i) {
            ar.push({ name: v['name'], data: [] });
            for (var x of v['performanceKPI']) {
                ar[i].data.push(x['count']);
                reponse.push(x['captuerdAt'])
            };
        });
        setOptionValue(prv => ({
            ...prv,
            series: ar,
            xAxis:
            {
                categories: reponse,
                labels: {
                    formatter: function () {
                        return this.value;
                    },

                }
            }
        }));
    }


    return (
        <>
            <h3 className="_chart_title padding-top20 padding20">Responses in period</h3>
            <div className="b_top padding20">
                <HighchartsReact highcharts={Highcharts} options={optionData} />
            </div>
        </>
    )
}
export default Visitor;