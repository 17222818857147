import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import  LandingPageKPI  from './LandingPageKPI';
import Device from './Device';
import Engagment from './Engagment';
import Source from './Source';
const Tabing = ({ data }) => {
    const defaultId = 34;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <LandingPageKPI tabId={tabId} /> :
                    tabId === 36 ? <Device /> :
                        tabId === 35 ? <Engagment tabId={tabId} /> :
                            <Source />}
                </div>
            }
        </>
    )
}

export default Tabing;