import { Fragment, useEffect, useRef, useState } from 'react';
import { useNavigate } from "react-router";
import { get as _get } from "lodash";
import { Link } from 'react-router-dom';
import './../../styles/css/common.css';
import DataTable from 'react-data-table-component';
import StaticPaginationDesign from "../ReusableComponents/StaticPaginationDesign";
import TableRowsLoading from "../ReusableComponents/TableRowsLoading";

import { getCRMList, deleteCRMList, exportList } from "../../services/crm";

const CRMContacts = () => {

  const [timer, setTimer] = useState(null);
  const [tableCRMList, setCRMListData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [selectedDeleteIds, setDeleteListId] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchListName, setSearchListName] = useState("");
  const [refreshTableData, setRefreshTableData] = useState(true);
  const [deleteMesage, setDeleteMessage] = useState(true);
  const [showDeleteDropDown, setShowDeleteDropDown] = useState(true);
  const [isShowMultipleDeleteOption, setIsShowMultipleDeleteOption] = useState(false);
  const navigate = useNavigate();
  const wrapperRef = useRef(null);

  const COLUMNS = [
    {
      name: 'List name',
      selector: (item) => (
        <Link to={`/crm/people/subscribers/${_get(item, "PKID", "")}/${_get(item, "CreationSource", "")}`} state={{ selecteListName: _get(item, "ListName", "") }} > {_get(item, "ListName", "")}</Link >
      ),
    },
    {
      name: 'Contacts',
      selector: (item) => item.people,
    },
    {
      name: 'Segments',
      selector: (item) => (
        <Link
          to={`/crm/people/subscribers/${_get(item, "PKID", "")}/${_get(item, "CreationSource", "")}/segment/list`}
          state={{ selecteListName: _get(item, "ListName", "All Contacts"), selectedListId: _get(item, "PKID", "") }}
        > {item.SegmentCount}</Link >
      )
    },
    {
      name: 'Created on',
      selector: (item) => item.InsertDateTime, //.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', })
    },
    {
      name: (
        <Fragment>
          <span>Action</span>
          {(((isShowMultipleDeleteOption || false) === true)) && (
            <Fragment>
              <i
                className="fas fa-ellipsis-h ms-2 cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();

                  setShowDeleteDropDown(!showDeleteDropDown)
                }}>
              </i>

              {((showDeleteDropDown || false) === true) && (
                <ul className="drop_menu_toppanel drop_menu_delete" ref={wrapperRef}>
                  <li><Link className="paddingleft20" onClick={() => setShowDeleteSection(true)}>Delete the selected lists</Link></li>
                </ul>
              )}
            </Fragment>
          )}
        </Fragment>
      ),
      selector: (item) => (
        <>
          <div className="rdt_TableAction">
            <span>
              <Link to={`/crm/people/subscribers/${_get(item, "PKID", "")}/${_get(item, "CreationSource", "")}/create`} >Import</Link>
            </span>
            <span>
              <Link to={`/crm/people/subscribers/${_get(item, "PKID", "")}/${_get(item, "CreationSource", "")}`} state={{ selecteListName: _get(item, "ListName", "") }} >Open</Link>
            </span>

            {(((isShowMultipleDeleteOption || false) === true) && (_get(item, "IsReadOnly", false) === false)) && (
              <span className="btn-disabled">Delete</span>
            )}

            {(((isShowMultipleDeleteOption || false) === false) && (_get(item, "IsReadOnly", false) === false)) && (
              <span className="cursor-pointer" onClick={() => {
                setShowDeleteSection(true);
                setDeleteListId([_get(item, "PKID", "")]);
                setDeleteMessage(`Are you sure that you want to delete the list ${_get(item, "ListName", "")} along with its ${_get(item, "people", "")} subscribers?`);
              }}>Delete</span>
            )}

            {(_get(item, "people", 0) > 0) && (
              <span className="cursor-pointer" onClick={() => onExport(item)}>Export</span>
            )}
          </div>
        </>
      ),
    },
  ];

  // Fetch CRM List if search/pageNo/limit change
  useEffect(() => {
    (async () => {
      try {
        if (refreshTableData === true) {
          const payload = {
            search: (searchListName || null),
            skip: (pageNo || 0),
            limit: (limit || 10)
          }

          const response = await getCRMList(payload);

          setRefreshTableData(false);
          //Set CRM List Data in State
          setCRMListData(_get(response, "data.recordList", []));
          setTotalRows(_get(response, "data.totalCount", 0))
        }
      } catch (err) {
        console.log('Error occured when fetching list', err);
      }
    })();
  }, [refreshTableData, limit, pageNo, searchListName]);

  // To Toggle Show Bulk Delete Contact Menu
  useEffect(() => {

    function handleClickOutside(event) {

      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDeleteDropDown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const handlePageChange = (page) => {
    setPageNo(page);
    setRefreshTableData(true);
  };

  const onDelete = () => {
    (async () => {
      try {

        const response = await deleteCRMList({ listId: (selectedDeleteIds || []) });

        if (_get(response, "data.Success", false) === true) {

          setRefreshTableData(true);
          setDeleteListId(null);
          setDeleteMessage("");
          setShowDeleteSection(false);
        }
      } catch (err) {
      }
    })();
  }

  const onExport = (itemList) => {
    (async () => {
      try {
        const payload = {
          ListId: _get(itemList, "PKID", ""),
          ListType: _get(itemList, "ListCategoryTypeId", 0),
          Name: _get(itemList, "ListName", "")
        }

        const response = await exportList(_get(itemList, "CreationSource", 0), payload);
        if (_get(response, "data.success", false) === true) {

          navigate("/download");
        }
      } catch (err) {
      }
    })();
  }

  const onCancelDelete = () => {
    setShowDeleteSection(false);
    setDeleteListId(null);
    setDeleteMessage("");
  }

  const handleChange = (e) => {

    clearTimeout(timer);

    let clearTime = setTimeout(() => { setSearchListName(_get(e, "target.value", "")); setRefreshTableData(true); }, 500);
    setTimer(clearTime);
  }

  const handlePerRowsChange = (e) => {
    setLimit(e);
  }

  const deleteList = () => {

    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Delete</h3>
          </div>
          <div className="bx-pad">
            <div className="alert alert-warning margin-bottom24">
              {(deleteMesage || "")}
              <br />
              <br />
              <p>Note: This action can not be undone. The lists along with their subscribers would be deleted permanently from your database.</p>
            </div>

            <div className="formmodel-bx text-right nomargin">
              <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancelDelete()} >Cancel</button>

              <button
                type="button"
                className="btn btn-danger btn-gape-15"
                disabled=""
                onClick={() => onDelete()}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div ref={wrapperRef}></div>
      <div className="dashbd_sec margin-top100">
        <div className="mycontent">
          <ul className="tabing">
            <li><Link className="active">CRM List</Link></li>
            <span className="flex-right">
            </span>
          </ul>
          <Link to="/crm/people/importManage" className="btn btn-light fright npn_filter">Import Status</Link>
          <Link to="/crm/people/program" className="btn btn-light fright npn_filter">Loyalty Program</Link>
        </div>

        {((showDeleteSection || false) === true) && (
          deleteList()
        )}

        {((showDeleteSection || false) === false) && (
          <div className="sms_Sec_1 bx-boder-common width100 nobg">
            <div className="_h_header white border-radius2">
              <h3>Manage lists</h3>
              <span className="flex-right">
                <input type="text" placeholder="Search List" className="inputformmodel" defaultValue={(searchListName || "")} onChange={(e) => handleChange(e)} />
                <Link to="/crm/people/joinlist" className="btn btn-primary npn_filter" >Create List</Link>
              </span>
            </div>
            {((refreshTableData || false) === true) && (
              <TableRowsLoading />
            )}

            {((tableCRMList || []).length < 1) && ((refreshTableData || false) === false) && (
              <div className="p-4">
                <div className="alert alert-warning margin-bottom24">
                  You have not configured any Subscibe List.
                  <br /><br />
                  <p>With a template you are able to configure and change your Subscribe List without involving your technical derpartment.</p>
                </div>
                <div className="dataTable-primary">
                  <StaticPaginationDesign isShowItemsPerPage={true} />
                </div>
              </div>
            )}
            {((tableCRMList || []).length > 0) && ((refreshTableData || false) === false) && (
              <div className="dataTable-primary">
                <DataTable
                  columns={COLUMNS}
                  data={tableCRMList}
                  //progressPending={loading}
                  pagination
                  paginationServer
                  paginationTotalRows={totalRows}
                  onChangeRowsPerPage={(e) => handlePerRowsChange(e)}
                  onChangePage={(e) => handlePageChange(e)}
                  selectableRowDisabled={(e) => e.IsReadOnly}
                  selectableRows
                  onSelectedRowsChange={(e) => {

                    if (_get(e, "selectedCount", 0) === 0) {
                      setDeleteListId([]);
                    } else {

                      let selectedIds = [];
                      _get(e, "selectedRows", []).forEach(element => {
                        selectedIds.push(_get(element, "PKID", ""));
                      });
                      setDeleteListId(selectedIds);
                    }

                    if (_get(e, "selectedCount", 0) > 1) {
                      setIsShowMultipleDeleteOption(true);
                      setDeleteMessage(`Are you sure you want to delete the selected ${_get(e, "selectedCount", 0)} lists along with their subscribers?`);
                    } else {
                      setIsShowMultipleDeleteOption(false);
                      setShowDeleteDropDown(false);
                    }
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default CRMContacts;