import PrefferedSetting from "./PrefferedSetting"
import Landingpage from "./Landingpage"
import VerifyAccount from "./VerifyAccount"
import DesignSetting from "./DesignSetting"
const OnboardingBody = ({ ...props }) => {
    return (
        <>
            {props.Id === 12 ||
                props.Id === 9 ||
                props.Id === 16 ||
                props.Id === 18 ||
                props.Id === 20 ||
                props.Id === 21 ||
                props.Id === 22 ||
                props.Id === 24 ||
                props.Id === 17 ?
                <Landingpage {...props} /> : props.Id === 13 ? <VerifyAccount {...props}  /> : props.Id === 11 ? <DesignSetting {...props}/> :<PrefferedSetting {...props} />}
        </>
    )

}

export default OnboardingBody
