import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import GoalContainer from "./GoalContainer";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import axios from "axios";

const CreateGoal = () => {
  const { APITOKEN } = useSelector((state) => state);
  const [editGoalPurpose, setEditGoalPurpose] = useState({
    purposeDisable: false,
    landingDisable: false,
    edit: true,
  });
  const [goalPurpose, setGoalPurpose] = useState({
    data: [],
    selected: "",
    nextOptionShow: false,
    nextOptionData: [],
    nextOptionSelected: "",
  });
  const load = useCallback(() => {
    const cancelToken = axios.CancelToken.source();

    try {
      APITOKEN["token"] &&
        Client.get(
          `Lookup/goalpurposes?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
          {
            cancelToken: cancelToken.token,
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then((res) => {
            try {
              const { data } = res;
              setGoalPurpose((prv) => ({
                ...prv,
                data,
              }));
              setEditGoalPurpose((prv) => ({
                ...prv,
                edit: false,
              }));
            } catch (err) {
              console.error(err);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.error(err);
            }
          });
      return () => {
        cancelToken.cancel();
      };
    } catch (err) {
      console.error(err);
    }
  }, [APITOKEN, setEditGoalPurpose]);
  const goalPurposeFn = (ev) => {
    const { Id, Name, DataCategoryId } = JSON.parse(
      ev.target.childNodes[ev.target.selectedIndex].getAttribute("data-Id")
    );
    setGoalPurpose((prv) => ({
      ...prv,
      selected: ev.target.value,
      nextOptionShow: true,
      nextFileName: Name,
      goalDetailsContainerDisplay: false,
      DataCategoryId,
      nextOptionSelected: "",
    }));
    // === Next API call assign by Id === //
    nextAPICall(Id);
  };
  const nextAPICall = useCallback(
    (id) => {
      const cancelToken = axios.CancelToken.source();
      try {
        Client.get(`Insights/goals/landingpage/${id}`, {
          cancelToken: cancelToken.token,
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        })
          .then((res) => {
            try {
              const { data } = res;
              setGoalPurpose((prv) => ({
                ...prv,
                nextOptionData: data,
              }));
            } catch (err) {
              console.error(err);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.error(err);
            }
          });
        return () => {
          cancelToken.cancel();
        };
      } catch (err) {
        console.error(err);
      }
    },
    [APITOKEN]
  );
  const goalNextFn = (ev) => {
    const val = ev.target.value;
    setGoalPurpose((prv) => ({
      ...prv,
      nextOptionSelected: val,
      goalDetailsContainerDisplay: true,
      goalId: val,
      goaltemplateId: "",
    }));
  };
  const editGoalActivity = useCallback(() => {
    const cancelToken = axios.CancelToken.source();

    try {
      APITOKEN["token"] &&
        Client.get(
          `Insights/landing_page/goals/${getParameterByName(
            "cam",
            window.location.href
          )}`,
          {
            cancelToken: cancelToken.token,

            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then((res) => {
            try {
              const { data } = res;
              setGoalPurpose((prv) => ({
                ...prv,
                selected: data[0].DataCategoryId,
                nextOptionShow: true,
                DataCategoryId: data[0].DataCategoryId,
                nextOptionSelected: data[0].GoalLandingPageId,
                goalDetailsContainerDisplay: true,
                goaltemplateId: "",
                goalId: data[0].GoalLandingPageId,
                MeasureEndDateTime: data[0].MeasureEndDateTime,
                MeasureStartDateTime: data[0].MeasureStartDateTime,
              }));
              setEditGoalPurpose((prv) => ({
                ...prv,
                purposeDisable: true,
                landingDisable: true,
                editData: data[0],
                edit: true,
              }));
              nextAPICall(data[0].GoalPurposeId);
            } catch (err) {
              console.error(err);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.error(err);
            }
          });
      return () => {
        cancelToken.cancel();
      };
    } catch (err) {
      console.error(err);
    }
  }, [APITOKEN, nextAPICall]);
  useEffect(() => {
    load();
    if (getParameterByName("edit", window.location.href) !== null) {
      editGoalActivity();
    }
  }, [load, editGoalActivity]);
  return (
    <>
      <div className="dashbd_sec margin-top50">&nbsp;</div>
      <div className="dashbd_sec margin-top50">
        <div className="sms_Sec_1 bx-boder-common width100 nomargin">
          <div className="_h_header">
            <h3>Create New Goal</h3>
          </div>
          <div className="bx-pad">
            <div className="formmodel-bx">
              <label className="_l_able_cls">Goal Purpose</label>
              <select
                className="inputformmodel width100"
                onChange={(e) => goalPurposeFn(e)}
                value={goalPurpose.selected}
                disabled={editGoalPurpose.purposeDisable}
              >
                <option value="">Select</option>
                {goalPurpose["data"].length > 0 ? (
                  goalPurpose["data"].map((v, i) => (
                    <option
                      key={i}
                      value={v.DataCategoryId}
                      data-id={JSON.stringify(v)}
                    >
                      {v.Name}
                    </option>
                  ))
                ) : (
                  <></>
                )}
              </select>
            </div>
            {goalPurpose.nextOptionShow ? (
              <div className="formmodel-bx">
                <label className="_l_able_cls">
                  Select {goalPurpose.nextFileName}
                </label>
                <select
                  className="inputformmodel width100"
                  onChange={(e) => goalNextFn(e)}
                  value={goalPurpose.nextOptionSelected}
                  disabled={editGoalPurpose.landingDisable}
                >
                  <option value="">Select</option>
                  {goalPurpose["nextOptionData"].length > 0 ? (
                    goalPurpose["nextOptionData"].map((v, i) => (
                      <option key={i} value={v.id}>
                        {v.name}
                      </option>
                    ))
                  ) : (
                    <></>
                  )}
                </select>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        {goalPurpose.goalDetailsContainerDisplay ? (
          <GoalContainer
            goalPurpose={goalPurpose}
            setGoalPurpose={setGoalPurpose}
            EditObject={editGoalPurpose}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default CreateGoal;
