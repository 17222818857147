import { useCallback, useEffect, useMemo } from "react";
import { client } from 'filestack-react';
import { useDispatch } from "react-redux";
import { setEmailFileImport } from "../../Redux/Action";
import getParameterByName from "../../ReuseTemplate/GetRoute";

const FileUpload = ({ ...props }) => {
    const url = process.env;
    const dispatch = useDispatch();
    const { fileObject, setFileObject, setCount, count } = props;

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return { count: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), bytes: sizes[i] }
    }

    const configFileFN = useCallback(res => {
        const arry = [];
        for (let x of res) {
            arry.push({
                "type": x['mimetype'] || x['type'],
                "contentUrl": x['url'] || x['contentUrl'],
                "filename": x['filename'],
                "content": "",
                "disposition": "attachment",
                "contentId": x['filename'],
                "size": x['size']
            })
        }
        setFileObject(prv => ({ ...prv, FileAttachMent: [...prv.FileAttachMent, ...arry] }));
    }, [setFileObject])
    const emailCampaignBytes = useMemo(() => {
        return 0;
    }, []);

    const getSizeintoBytes = useCallback(async (res, arrayNew) => {
        for (const x of res) {
            arrayNew.push(x['size']);
        };
        dispatch(setEmailFileImport(arrayNew));

        let bytesCount = (arrayNew.length === 0)
            ? 0
            : (arrayNew.length > 1) ?
                arrayNew.reduce((p, n) => parseInt(p) + parseInt(n)) :
                arrayNew[0];

        const { bytes, count } = formatBytes(parseInt(bytesCount) + parseInt(emailCampaignBytes));

        if (bytes !== "KB" && bytes !== "Bytes") {
            if (count > 25) {
                setFileObject(prv => ({ ...prv, fileImportValidation: false }));
            } else {
                setFileObject(prv => ({ ...prv, fileImportValidation: true }));
            }
        } else {
            setFileObject(prv => ({ ...prv, fileImportValidation: true }));
        };
        configFileFN(res);
    }, [
        emailCampaignBytes,
        configFileFN,
        setFileObject,
        // FileImport,
        dispatch
    ]);
    const fileUPloadByFilePicker = useCallback(async () => {
        const clients = await client.init(url.REACT_APP_FILE_PICKER_API_KEY);
        const element = document.querySelector('.drop-container');
        element.innerHTML = "";
        const edit = getParameterByName('edit', window.location.href)
        let backupArray = edit !== null? fileObject.FileAttachMent.map(x=> parseInt(x.size)) :[];
        if (element !== null) {
            const options1 = {
                container: '.drop-container',
                displayMode: 'dropPane',
                maxFiles: 10,
                dropPane: {
                    overlay: true, // if this is true then overlays will conflict between instances
                    onSuccess: async (res) => {
                        await getSizeintoBytes(res, backupArray);
                    }
                }
            };
            clients.picker(options1).open();
        }

    }, [url, getSizeintoBytes,fileObject.FileAttachMent]);

    const removeFile = ev => {
        const newArray = fileObject['FileAttachMent'].filter(x => x.filename !== ev.filename);
        fileUPloadByFilePicker();
        fileObject.FileAttachMent = newArray;
        // if (newArray.length === 0) {
        //     fileUPloadByFilePicker();
        // }
        getSizeintoBytes(newArray, []);
        setFileObject(prv => ({ ...prv, FileAttachMent: newArray }));
    }

    useEffect(() => {
        if (count === 0) {
            fileUPloadByFilePicker();
            setCount(1)
        }
        return (() => {
            setCount(1)
        })
    }, [fileUPloadByFilePicker, count, setCount])

    return (
        <>
            {
                <>  <div className="drop-container"></div>
                    <div className="fileUpload_history">
                        {
                            fileObject['FileAttachMent'] && fileObject['FileAttachMent'].map((x, i) => <span key={i}>
                                <strong>{x['filename']}</strong>
                                <button type="button" className="link" onClick={() => removeFile(x)}><i className="fa fal fa-times"></i></button>
                            </span>)
                        }
                        {
                            !fileObject.fileImportValidation && <p className="validation file_upload_error">Attachment can not be uploaded more than 25 Mb.</p>
                        }
                    </div>
                </>
            }
        </>
    )

}

export default FileUpload
