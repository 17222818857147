import React, { useCallback, useEffect, useMemo } from 'react'
import * as filestack from 'filestack-js';
import Client from '../../../GlobalAPICall';
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';
import CatgoryList from './CatgoryList';

const client = filestack.init('APBcz9XF7TEmEQpcF5wmxz');

export default function SubCatgoryPopUp({ dispatch, state, APITOKEN, setLand }) {
    const imageUpload = (event, item) => {
        event.stopPropagation();
        const options = {
            maxFiles: 1,
            fromSources: ["local_file_system"],
            uploadInBackground: false,
            onOpen: () => console.log('opened!'),
            onUploadDone: (res) => {
                const newArray = state.subCat.map(v => (v.id === item.id ? { ...v, image: res.filesUploaded[0].url } : v));
                dispatch({
                    type: 'LOAD_CAT',
                    payload: {
                        ...state,
                        subCat: [...newArray]
                    }
                })
            },
        };
        client.picker(options).open();
    }
    const createSubCatgory = () => {
        dispatch({
            type: 'LOAD_CAT', payload: {
                ...state,
                loader: true
            }
        });
        const filterObjec = state.subCat.map(v => ({ ...v, id: v.id < 0 ? 0 : v.id, categoryId: state.catId }));

        Client.post('Mobile/AppSubCategory?languageCode=en', filterObjec, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                if (data.Success) {
                    dispatch({
                        type: 'LOAD_CAT', payload: {
                            ...state,
                            loader: false,
                            show: false
                        }
                    });
                }
            }).catch(err => console.error(err));
    }
    const ButtonDisabled = useMemo(() => {
        const newarrya = state.subCat.filter(x => x.value === "");
        return (newarrya.length === 0) ? false : true;
    }, [state.subCat])
    const load = useCallback(async () => {
        const { data } = await CatgoryList(APITOKEN, setLand, state.catId);
        dispatch({ type: 'LOAD_CAT', payload: { show: true, catId: state.catId, subCat: data } });
    }, [APITOKEN, dispatch, setLand, state.catId]);
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className='videoCaptionHtml'>
            <div className='videoCaptionContainer' style={{ width: '650px' }}>
                <div className="topn-header">
                    <span className="selected-title">Add Sub Category</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => dispatch({ type: 'LOAD_CAT', payload: { show: false, catId: 0 } })}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="padding20">
                    {state.subCat.map((item, index) => <div className="formmodel-bx cardback" key={index}
                    > {item?.image ? <img src={item?.image} width={30} alt={item.value} className='margin-right15' /> : <span className='margin-right15' style={{ width: '40px' }}>&nbsp;</span>} <input className='inputformmodel margin-right15' placeholder={`Sub Category ${index + 1}`} type='text' value={item.value}
                        onChange={event => dispatch({
                            type: 'LOAD_CAT', payload: {
                                ...state,
                                subCat: state.subCat.map((element) => element.id === item.id ? ({ ...element, value: event.target.value, show: true }) : ({ ...element }))
                            }
                        })} />
                        <button type="button" className='grid_btn_1' onClick={event => imageUpload(event, item)}>
                            Upload
                        </button></div>)}

                    <div className='text-right'>
                        <button type='button' className='grid_btn_1 margin-right15' disabled={ButtonDisabled} onClick={() => dispatch({
                            type: 'LOAD_CAT', payload: {
                                ...state,
                                subCat: [...state.subCat, { id: parseInt((-Math.random() * 1000).toFixed(0)), value: "", languageCode: setLand, categoryId: state?.catId }
                                ]
                            }
                        })}>Add</button>
                        <button type='button' className='grid_btn_1 grid_btn_active' onClick={createSubCatgory}>
                            <ButtonLoader IsLoader={state.loader}>Save</ButtonLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
