import React from 'react'
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from '../../GlobalAPICall';
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';
const ChangePlan = (props) => {
    const { APITOKEN } = useSelector(state => state);
    const { closePopup, Data ,setChangePlanDisplay ,setpriceDetailsPlanChangeFn  } = props;
    const [subscriptionPlanInterval, setSubscriptionPlanInterval] = useState([]);
    const [PlanId, setPlanId] = useState(1);
    const [plan, setPlan] = useState([]);
   
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Lookup/SubscriptionPlanInterval_tabs', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setSubscriptionPlanInterval(data);
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN]);
    const loadPlan = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`User/Subscription_Plans/Preview?billingCycle=${PlanId}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setPlan(data);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.log(err));
    }, [APITOKEN, PlanId]);
    const planChangeFn = ev => {

        setPlan(plan.map(x => x.productId === ev.productId ?({ ...x, loader: true }):{...x}));
        Client.get(`User/Preview_Product_Migration?productId=${ev.productId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                if (data.statusCode === 200) {
                    setpriceDetailsPlanChangeFn(prv => ({
                        ...prv,
                        display:true,
                        ...ev,
                        ...data.previewResp,
                        
                    }));
                    setChangePlanDisplay(false);
                    setPlan(plan.map(x => ({ ...x, loader: false })));
                }else if(data.statusCode === 400){
                    setpriceDetailsPlanChangeFn(prv => ({
                        ...prv,
                        erroMessage:data.message
                    }));
                }
                // setPlan(data);
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        load();
        loadPlan();
    }, [load, loadPlan])

    return (
        <>
            <div className="mask_change_Wrapper">
                <div className="change_Wrapper__Container">
                    <div className="topn-header">
                        <span className="selected-title">Change Plan</span>
                        <span className="" style={{ padding: '20px', float: 'right' }} onClick={() => closePopup(false)}><i className="fa fal fa-times clsbtnNoti fright pointer"></i></span>
                    </div>
                    <div className="padding20 down_gruadeheight">
                        <div className="text-right">
                            <ul className="upgrade_tabs">
                                {subscriptionPlanInterval.map((v, i) => <li key={i}><button onClick={() => setPlanId(v.Id)}
                                    className={(v['IsDefault']) ? `${PlanId !== v.Id ? '' : 'tab__selected'}` : `${PlanId === v.Id ? 'tab__selected' : ''}`}>{v['Name']}
                                    <span className="discounttab" style={{ display: (i > 0) ? 'inline-block' : 'none' }}>{v['DiscountAmount']}</span></button></li>)}
                            </ul>
                        </div>
                        <div id="__plan__packages" className="margin-bottom">
                            {plan.map((x, i) =>
                                <div className="__box_price_parent" style={{ display: x['isDefaultProduct'] ? 'none' : 'inline-block' }} key={i}>
                                    <div className="__box__price__Info nomargin bx-boder-common">
                                        <div className={`p__header ${(x['defaultSelected']) ? 'bgBlue' : ''}`}>
                                            {x.name}
                                        </div>
                                        <div className={`${x['defaultSelected'] ? 'bgSelectedPrice' : ''}`}>
                                            <div className="price__yellow" style={{ 'background': `#${x['stripColor']}` }}>
                                                {x.offerName}
                                            </div>
                                            <div className="price__details">
                                                <p className="nomargin text-center text-underline">{x.offerDescription}</p>
                                                <h3 className="cost_b  text-center">
                                                    <span className="__symbol">{x['symbol']}</span>
                                                    {x.price}
                                                    <span className="m--y">/ {x.billingCycle}</span>
                                                </h3>
                                                <button type="button" className="btnSign width100 pricelink text-center transparent" onClick={() => planChangeFn(x)}>
                                                 <ButtonLoader IsLoader={x.loader} color={true} >{x.upgradeDowngradeText}</ButtonLoader>
                                                </button>
                                                <p className="text-center" style={{ padding: '20px 0 0 0 ' }}>{x.couponText}</p>
                                            </div>
                                            <div className="subscription__Details__bx">
                                                <p className="nomargin fweight300">Included in this subscription:</p>
                                            </div>
                                            <ul className="p_description">
                                                {x.components.map((y, index) => <li key={index}><label>{y['name']}</label><span>{(y['maxUsage'] === 0) ? 'included' : (y['maxUsage'] === -1) ? 'Unlimited' : y['maxUsage']}</span></li>)}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        {Data.erroMessage && <div className="paymentErrorMessage"><h2>Error Message!</h2><p>{Data.erroMessage}</p></div>}
                        <div className="padding20" style={{ paddingLeft: '0' }}>
                            <h2 className="downgradtitle">Find the perect subscription for your needs</h2>
                            <br></br>
                            <p>Simulate your Monthly Cost based on your number of contacts, email sendings, SMS sendings, Landing Page creations and whitelabel domains you need.<br></br> Adjust the different resources in our calculator and we will suggest the best plan for your needs.</p>
                        </div>
                        <div className="padding20" style={{ paddingLeft: '0' }}>
                            <h2 className="downgradtitle">Compare Subscriptions</h2>
                            <br></br>
                            <p>Compare all our subscriptions to see what we offer in the different plans. Click on the compare button and a new session will open in your browser.</p>
                            <p className='compareimg'><br></br></p>
                            <p className="text-right"><br></br><a href={`https://www.jirafix.com/compare/?m=Monthly&cu=${Data.currency}`} className="grid_btn_1 grid_btn_darkBlue __Compare_link" rel="noreferrer" target="_blank">Compare</a></p>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default ChangePlan