import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";

export const UnsubscribeOption = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [unsunscribeContentVal, setUnscribeContent] = useState([]);
    const { Value, Selected } = props;
    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get(`/Unsubscribe/Consent/${Value.unsubscribeContent}/Details`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setUnscribeContent(res['data']);
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN, Value])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">Option</label>
            <select className="inputformmodel"
                value={Value.unsubscribeOption}
                onChange={e => Selected({ ...Value, ...{ unsubscribeOption: e.target.value, unsubscribeLink: e.target.childNodes[e.target.selectedIndex].getAttribute('data-id') || '' } })}>
                <option value="">Select</option>
                {unsunscribeContentVal.map((v, i) => <option key={i} value={v.Id} data-id={v.ShortUrl} >{v.Name}</option>)}
            </select>
        </div>
    )

}

