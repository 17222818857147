import { useCallback, useEffect, useState } from "react";
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
const BarChart = ({ ...props }) => {
    const { Data } = props;
    let colors = ['#e8f086', '#6fde6e', '#ff4241', '#a691ae', '#235fa4'];

    const [optionData, setOptionValue] = useState({
        colors: ['#e8f086', '#6fde6e', '#ff4241', '#a691ae', '#235fa4'],
        chart: {
            type: 'bar',

        },
        title: {
            align: "left",
            useHTML: true,
            style: { "font-size": "18px", "color": "#000", 'margin-bottom': '20px', 'font-family': 'roboto' }

        },
        accessibility: {
          enabled:false  
        },
        subtitle: {
            align: "left",
            useHTML: true,
            style: { "font-size": "18px", "color": "#000", 'marginTop': '20px', 'font-family': 'roboto' }

        },

        yAxis: {
            min: 0,
                title: {
                    text: '',
                    align: 'high'
                },
                labels: {
                    overflow: 'justify'
                },
        },

        xAxis: {
            title: {
                text: null,
            },

        },

        legend: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        tooltip: {
            useHTML: true,
            borderColor: '#fff',
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: '#fff',
            
        },
        plotOptions: {
            bar: {
                colorByPoint: true,
                colors: colors,
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return '' + this.point.text;
                    }
                }
            },
            series: {
                pointWidth: 20
            }
        },
    });
    const Chart = useCallback(() => {
        const filterData = [{ data: [], catgoriesName: [], title: "" }];
        if (Data['MatrixResponse'] !== undefined) {
            const newarray = _.unionBy(Data['MatrixResponse'], 'optionRawId');
            newarray.forEach(async function ($v, $i) {
                filterData[0].maintitle = Data.WidgetName;
                for (var x of Data['MatrixResponse']) {
                    if ($v['optionRawId'] === x['optionRawId']) {
                        filterData[0].data.push({
                            name: x['optionResponse'][0].optionText,
                            y: x['optionResponse'][0].totalCount,
                            text: x['optionResponse'][0].totalCount,
                            percentage: x['optionResponse'][0].countPer
                        });
                        filterData[0].catgoriesName.push(x['optionResponse'][0].optionText);
                        filterData[0].title = x.optionRowText;
                    };
                };

            });
        };
        setOptionValue(prv => ({
            ...prv,
            series: [{
                data: filterData[0].data
            }],
            xAxis: {
                categories: filterData[0].catgoriesName
            },
            title: {
                text: `${filterData[0]['maintitle']}`,
            },
            subtitle: {
                text: `${(filterData[0]['title'] !== undefined) ? 'Answer: ' + filterData[0].title : ''}`,
            },
            tooltip:{
                formatter: function () {
                    return `<div style="width:150px;font-size:14px;"><p  style="color:#666;margin-bottom:10px;font-size:15px;font-weight:bold;">${(filterData[0].title === undefined) ? this.point.category : filterData[0].title}</p><p style="margin-bottom:10px;color:#666">Total Count : ${this.point.y}</p><p  style="color:#666">Percentage : ${this.point.percentage}%</p></div>`;
                }
            }
        }));

    }, [Data]);
    useEffect(() => {
        Chart();
    }, [Chart])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={optionData} />
        </div>
    )

}

export default BarChart
