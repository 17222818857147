import HighchartsReact from 'highcharts-react-official'
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import Highcharts from 'highcharts';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import InsightBlank from '../../../InsightBlank';
import GridLoader from '../../../../ReuseTemplate/GridLoader';
import LoaderBox from '../../../../ReuseTemplate/LoaderBox';
import { SOURCE_CHART_COLOR } from '../../../constant/HightChartColor';
import { HightChartBestDay } from '../../../constant/HightChartBestProps';

const Source = () => {
  const { id } = useParams();
  const { APITOKEN } = useSelector(state => state);
  const [sourceArray, setSourceArray] = useState({
    data: [],
    isDisplay: true
  });
  let [isPinned, setIsPinned] = useState([]);

  // const [sourceChartObject, setSourceChartObject] = useState([]);
  Highcharts.setOptions({ colors: SOURCE_CHART_COLOR, borderWidth: 0 });

  const [DesktopData, setDesktop] = useState(HightChartBestDay);

  const loadApiCall = useCallback(async () => {
    setSourceArray(prv => ({ ...prv, isDisplay: true }));
    try {
      (APITOKEN['token'] !== "") &&
        Client.get(`Insights/landingpage/${id}/KPI/traffic_source`, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        }).then(({ data }) => {
          try {
            if (data.length === 0) {

            } else {
              const { TraficSourceKPI } = data[0];
              const parseTraficSourceKPI = JSON.parse(TraficSourceKPI || "[]");
              setSourceArray(prv => ({
                ...prv,
                data: parseTraficSourceKPI,
                isDisplay: false
              }));

              setIsPinned(parseTraficSourceKPI.filter(item => item.IsPinned) || []);
            }
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }, [APITOKEN, id]);
  const filterObject = (res) => {
    setDesktop({ series: [{ data: [...res.map(item => ({ name: item['Sources'], y: item['Traffic'] }))], pointWidth: 21 }] })
  }

  const loadTrafficCall = useCallback(async () => {
    try {
      (APITOKEN['token'] !== "") &&
        Client.get(`Insights/landingpage/${id}/traffic_insight/PerSource`)
          .then(async ({ data }) => {
            try {
              await filterObject(data)
            } catch (err) {
              console.log(err);
            }
          });
    } catch (err) {
      console.log(err);
    }
  }, [APITOKEN, id]);

  const pinMethod = value => {
    if (APITOKEN['token'] !== "") {
      let response = null;

      if (value['IsPinned']) {
        response = Client.delete(`Insights/landingpage/${id}/KPI/Pin/${value['KPIPKID']}`);
      } else {
        response = Client.put(`Insights/landingpage/${id}/KPI/Pin/${value['KPIPKID']}`, {});
      }
      response.then(({ data }) => {
        data[0]['Status'] &&
          loadApiCall();
        loadTrafficCall();
      });
    }
  }

  const onSortEnd = (oldIndex, newIndex) => {
    setIsPinned((array) => arrayMoveImmutable(array, oldIndex, newIndex));
  }
  useEffect(() => {
    loadApiCall();
    loadTrafficCall()
  }, [loadApiCall, loadTrafficCall])

  return (
    <div>
      <div className="bx-boder-common margin-bottom">
        <div className="_insight_header white">
          <h3>
            Desktop
          </h3>
        </div>
        <div className="padding20 white">
          {DesktopData && <HighchartsReact highcharts={Highcharts} options={DesktopData} />}
        </div>
        <div className={`${sourceArray.isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''} white`}>
          {sourceArray.isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> :

            <SortableList
              onSortEnd={onSortEnd}
              className="_overviewPin summary_o_v white"
              draggedItemClassName="dragged">{
                isPinned.map((v, i) =>
                  <SortableItem key={i}>
                    <div
                      className="bx-boder-common bx detailsbx white"
                      style={{ display: (v['IsPinned']) ? 'inline-block' : 'none' }}>
                      <div className="drop-zone">
                        <button className="pin" style={{ fontSize: '19px' }} onClick={() => pinMethod(v)} type="button">
                          <i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'none' : 'inline-block' }}></i>
                        </button>
                        <h3 className="title">{v['KPIName']}</h3>
                        <p className="content paddingbottom20">{v['PinnedDescription']}</p>
                      </div>
                      <div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                        <tbody>
                          <tr style={{ visibility: (v['KPICountLabel'] === undefined) ? 'hidden' : 'visible' }}>
                            <td>{v['KPICountLabel']}</td>
                            <td className=" -tvl"><strong>{v['Conversions']}</strong></td>
                          </tr>
                          <tr style={{ visibility: (v['KPIRateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['KPIRateLabel']}</td>
                            <td className="-tvl link"><strong>{v['Conversion Rate']}%</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                    </div>
                  </SortableItem>
                )}
            </SortableList>
          }

        </div>
      </div>
      <div className="bx-boder-common white">
        <div className="_h_header ">
          <h3>
            Traffic Performance Per Source
          </h3>
        </div>
        <div className="white">
          {sourceArray.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
            <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
              <thead>
                <tr>
                  <th className="table_cell_header">Source</th>
                  <th className="table_cell_header">Visitors</th>
                  <th className="table_cell_header">Bounce Rate</th>
                  <th className="table_cell_header">Conversion Rate</th>
                  <th className="table_cell_header">Conversion</th>
                  <th className="table_cell_header">Conversion Revenue</th>
                  <th className="table_cell_header">Action</th>
                </tr>
              </thead>
              <tbody>
                {sourceArray.data.length > 0 && sourceArray.data.map((v, i) =>
                  <tr key={i}>
                    <td className="body_table_cell">{v['Sources']}</td>
                    <td className="body_table_cell">{v['Visitors']}</td>
                    <td className="body_table_cell" style={{ paddingLeft: '10px' }}><strong className="">{v['Bounce Rate']}%</strong></td>
                    <td className="body_table_cell">{v['Conversion Rate']}%</td>
                    <td className="body_table_cell" >{v['Conversions']}</td>
                    <td className="body_table_cell">{v['Conversion Revenue']}</td>
                    <td className="body_table_cell">
                      <button type="button" className="link pinunpin" onClick={() => pinMethod(v)} >{(!v['IsPinned']) ? ' Pin' : 'Remove Pin'}</button></td>
                  </tr>)}
              </tbody>
            </table>
          }
        </div>
      </div>
    </div>
  )

}

export default Source
