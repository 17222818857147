import React from 'react'
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';
import * as filestack from 'filestack-js';
import Client from '../../../GlobalAPICall';

const client = filestack.init('APBcz9XF7TEmEQpcF5wmxz');

export default function CreatePlayList(props) {
    const { dispatch, state, APITOKEN, playListLoad, reSet ,lang} = props;

    const onUploadSuccess = () => {
        const options = {
            maxFiles: 1,
            fromSources: ["local_file_system"],
            uploadInBackground: false,
            onOpen: () => console.log('opened!'),
            onUploadDone: ({ filesUploaded }) => {

                dispatch({
                    type: 'CREATE', payload: {
                        imageUrl: filesUploaded[0].url
                    }
                });
            },
        };
        client.picker(options).open();

    }
    const savePlayL = () => {
        dispatch({ type: 'CREATE', payload: { loader: true } });
        Client.post(`/Mobile/AdminPlayList?title=${state.name}&description=${!!state.description ? state.description : ""}&image=${state.imageUrl}&listType=${state.listType}&id=${!!state.playListId ? state.playListId : 0}&languageCode=${state.language}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    if (data.Success) {
                        reSet();
                        playListLoad();
                    }
                } catch (err) {
                    dispatch({ type: 'CREATE', payload: { loader: false } });
                    reSet();
                }
            }).catch(err => {
                dispatch({
                    type: 'CREATE', payload: { loader: false }
                });
                reSet();
            })
    }

    return (
        <div className='createMaskingPlaylist'>
            <div className="videoCaptionContainer">
                <div className='topn-header'>
                    <span className='selected-title'>{state.flagTitle} Playlist</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => { dispatch({ type: 'CREATE', payload: { display: false  } }); reSet() }}>
                        <i className='fa fal fa-times fright pointer'></i>
                    </span>
                </div>
                <div className="padding20">
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Select language </label>

                        <select className="inputformmodel" value={state.language || ""} onChange={e => dispatch({ type: 'CREATE', payload: { language: e.target.value } })}>
                            <option value="">Select</option>
                            {lang.data.map((v, i) => <option value={v.LanguageCode} key={i}>{v.Value}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Title</label>
                        <input type='text' placeholder='Title' value={state.name || ""} onChange={e => dispatch({ type: 'CREATE', payload: { name: e.target.value } })} className='inputformmodel' />
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Description</label>
                        <textarea type='text' placeholder='Description' value={state.description || ""} onChange={e => dispatch({ type: 'CREATE', payload: { description: e.target.value } })} className='inputformmodel textareaModel'></textarea>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Select Media</label>
                        <select className="inputformmodel" value={state.listType || ""} disabled={state.mediaDisabled} onChange={e => dispatch({ type: 'CREATE', payload: { listType: e.target.value } })}>
                            <option value="">Select</option>
                            <option value="1">Audio</option>
                            <option value="2">Video</option>
                        </select>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Image Upload</label>
                        <button type='button' className='grid_btn_1' onClick={onUploadSuccess}>Image Upload</button>
                        {state.imageUrl &&
                        <p style={{marginTop:'10px'}}>
                            <img src={ state.imageUrl} alt="" width={30} />
                            </p>
                        }

                    </div>
                    <div className="text-right  ">
                        <button type="button" className="grid_btn_1 nobg noborder" onClick={() => { dispatch({ type: 'CREATE', payload: { display: false } }); reSet() }} >Cancel</button>
                        <button typeof="button" className="grid_btn_1 grid_btn_active" onClick={savePlayL} disabled={
                            (!state.name || !state.listType || !state.imageUrl || !state.language ) ? true : false
                        }
                            type="button" >
                            <ButtonLoader IsLoader={state.loader}>{state.flagTitle === 'Create' ? 'Save' : state.flagTitle}</ButtonLoader>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
