import { useCallback, useEffect, useState } from 'react';
import SMSendingGrid from '../../../../../Goal/SMSendingGrid'
import '../index.css'
import { useSelector } from 'react-redux';
import Client from '../../../../../GlobalAPICall';
import getParameterByName from '../../../../../ReuseTemplate/GetRoute';
import { buildSMSObjectPost } from '../../../../Goal/GoalCommentPostObject';
import { useNavigate } from 'react-router';
const CreateSMSGoal = () => {
    const { APITOKEN } = useSelector(state => state);
    const [goaltemplate, setGoaltemplate] = useState([]);
    const [goalObject, setGoalObject] = useState({});
    const [checkValidation, setValidationChcked] = useState([]);
    const navigate = useNavigate();
    const load = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.get(`Insights/goals/${getParameterByName('cam', window.location.href)}/setting_details`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            const KPISetting = JSON.parse(res.data[0].KPISettings);
                            setGoaltemplate(KPISetting);
                            setGoalObject(res.data[0])
                        } catch (err) {
                            console.error(err);
                        }
                    }).catch(err => {
                        console.log(err);
                    })
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN]);

    const saveGoal = () => {
        const goal_template_id = getParameterByName('cam', window.location.href);
        const edit = getParameterByName('edit', window.location.href);
        const obj = buildSMSObjectPost(goaltemplate);
        if (edit !== null) {
            PutAPICall(obj, goal_template_id);
        } else {
            PostAPICall(obj, goal_template_id);
        }
    }
    const PutAPICall = (obj, goal_template_id) => {
        Client.put(`Insights/goals/${goal_template_id}/setting_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.status) {
                        navigate('/create/templates/goalTemplate');
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.log(err);
            })
    }
    const PostAPICall = (obj, goal_template_id) => {
        Client.post(`Insights/goals/${goal_template_id}/setting_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.status) {
                        navigate('/create/templates/goalTemplate');
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        load();
    }, [load]);
    return (
        <div className="dashbd_sec">
            <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                <div className="_insight_header white">
                    <h3>{getParameterByName('edit', window.location.href) !== null ?'Edit':'Create'} your configuration for - <span className="templateName">{goalObject.Name}</span> </h3>
                </div>
                <SMSendingGrid
                    goalObject={goaltemplate}
                    setGoalObject={setGoaltemplate}
                    setValidationChcked={setValidationChcked}
                    checkValidation={checkValidation}
                />
                <div className="formmodel-bx nomargin text-right padding20 b_top">
                    <button type="button" className="grid_btn_1 noborder white btn-gape-15"
                        onClick={() => navigate('/create/templates/goalTemplate')}
                    >Cancel</button>
                    <button type="button" disabled={
                        checkValidation.length > 0 ? false : true
                    } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={saveGoal}>
                        {
                            getParameterByName('edit', window.location.href) !== null ? 'Update' : 'Save'
                        }
                    </button>
                </div>
            </div>
        </div>
    )

}

export default CreateSMSGoal
