import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { Nav } from "react-bootstrap";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAppContext } from "../../contexts/AppContext";
import { IoCopy } from "react-icons/io5";
import { RiLogoutCircleLine } from "react-icons/ri";
import { AuthenticationStatus } from "../../utils/constants/enums";
import Jdenticon from "react-jdenticon";
import networkIcon from "../../assets/img/networkIcon.png";
const OperationButton = ({ onClick, additionalClassName, children }) => (
  <Button
    onClick={onClick}
    id="connect-wallet"
    variant="outline-secondary"
    type="button"
    className={`custom-secondary btn px-5 ${additionalClassName}`}
  >
    {children}
  </Button>
);

export default function NavbarConnect({ updateWallet }) {
  const { user, authStatus, setAuthStatus } = useAppContext();
  const tokenSetUser = localStorage.getItem("tokenApi");
  useEffect(() => {
    // Retrieve stored values
    const storedAuthStatus = localStorage.getItem("authStatus");
    // Set the state based on retrieved values
    setAuthStatus(storedAuthStatus);
  }, [tokenSetUser]);

  useEffect(() => {
    // If not connected to wallet, remove tokenApi from localStorage
    if (authStatus !== "authenticated") {
      localStorage.removeItem("tokenApi");
    }
  }, [authStatus]);
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connectedToWallet =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === AuthenticationStatus.AUTHENTICATED);
        return (
          <div
            {...(!ready && {
              "aria-hidden": true,
              style: {
                opacity: 0,
                pointerEvents: "none",
                userSelect: "none",
              },
            })}
          >
            {connectedToWallet &&
            user &&
            authStatus === AuthenticationStatus.AUTHENTICATED ? (
              <div className="addres-info">
                <div className="wallet-address-button-container">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-secondary"
                      className="custom-secondary nav-btn JdenticonAvatar"
                    >
                      {user.avatar ? (
                        <img
                          src={user.avatar}
                          className="avatar"
                          alt="avatar"
                          width={40}
                          height={40}
                        />
                      ) : (
                        <Jdenticon
                          size="25"
                          value={account?.address || "default"}
                          className="JdenticonAvatar"
                        />
                      )}
                      <span className="d-none d-lg-block">
                        {account.displayName}
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Nav key="ProfileData">
                        <div className="ProfileData">
                          <h5 className="nickName">{user?.userName}</h5>
                          <p className="addressLable">
                            {user?.walletAddress
                              ? `${user.walletAddress.slice(
                                  0,
                                  6
                                )}…${user.walletAddress.slice(-6)}`
                              : ""}
                            <div className="copyBtn">
                              <IoCopy />
                            </div>
                          </p>
                        </div>
                      </Nav>
                      <Nav
                        key="ProfileData"
                        onClick={openAccountModal}
                        className="pointer"
                      >
                        <div className="ProfileDataBalance">
                          <img
                            alt={"networkIcon"}
                            src={networkIcon}
                            className="networkIcon"
                            width={30}
                            height={30}
                          />
                          <div className="ProfileDataBalanceText">
                            <p className="LableName">Balance</p>
                            <p className="LableBalance">
                              {account.displayBalance}
                            </p>
                          </div>
                        </div>
                      </Nav>
                      <Nav
                        key="Transactions"
                        onClick={openChainModal}
                        className="pointer"
                      >
                        <div className="ProfileDataBalance border-custome">
                          {chain.iconUrl && (
                            <img
                              alt={chain.name || "Chain icon"}
                              src={chain.iconUrl}
                              className="networkIcon"
                              width={30}
                              height={30}
                            />
                          )}
                          <div className="ProfileDataBalanceText">
                            <p className="LableName">Network</p>
                            <p className="LableBalance">{chain.name}</p>
                          </div>
                        </div>
                      </Nav>

                      <Nav key="Profile" onClick={openAccountModal}>
                        <span className="nav-link menuBottom">
                          <RiLogoutCircleLine />
                          Disconnect
                        </span>
                      </Nav>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            ) : !connectedToWallet ? (
              <>
                <OperationButton onClick={openConnectModal}>
                  {updateWallet ? "Use Connect Wallet" : "Connect Wallet"}
                </OperationButton>
              </>
            ) : chain.unsupported ? (
              <OperationButton
                onClick={openChainModal}
                additionalClassName="WrongNetwork"
              >
                Wrong network
              </OperationButton>
            ) : null}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
