import { Fragment, useEffect, useState, useRef, useCallback } from "react";
import { get as _get, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { useNavigate } from "react-router";
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import BreadCrum from "../../../ReuseTemplate/BreadCrum";
import StaticPaginationDesign from "../../ReusableComponents/StaticPaginationDesign";
import ImportStatusRowsLoading from "../../ReusableComponents/ImportStatusRowsLoading";
//import moment from "moment";

import { getImportStatusProgress, deleteImportStatus, fetchImportSummary, fetchImportDetails, exportList, fetchImportProgressLogsDetails } from "../../../services/crm";

const ImportStatus = () => {

  const navigate = useNavigate();
  const { APITOKEN } = useSelector(state => state);
  const [searchParams] = useSearchParams();
  const defaultActiveTab = ((searchParams.get('index') !== null) && (searchParams.get('index') === "3")) ? "Ongoing" : "Completed";
  const [activeTab, setActiveTab] = useState(defaultActiveTab);
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => { setIsOpen((prevState) => !isOpen) }
  const [isViewLogsOpen, setIsViewLogsOpen] = useState(false);
  const toggleViewLogDrawer = () => { setIsViewLogsOpen((prevState) => !isViewLogsOpen) }
  const [importTableData, setImportStatusTableData] = useState([]);
  const [showImportDetailsDropdown, setShowImportDetailsDropdown] = useState({});
  const [summaryData, setSummaryData] = useState({});
  const [viewLogsData, setViewLogsData] = useState([]);
  const [summaryDetailsData, setSummaryDetailsData] = useState({});
  const [showSummaryError, setShowSummaryError] = useState(false);
  const [selectedImportDetailData, setSelectedImportDetailData] = useState({});
  const [shoRowswLoading, setShowRowswLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const wrapperRef = useRef(null);

  useEffect(() => {
    if ((isOpen || false) === false) {
      setSummaryData({});
      setSummaryDetailsData({});
    }
  }, [isOpen]);

  const getImportStatusProgressList = useCallback((array, targetValue, property) => {
    (async () => {
      try {
        setShowRowswLoading(true);
        const payload = {
          status: activeTab,
          offset: (currentPage * 10),
          limit: 10
        }

        const response = await getImportStatusProgress(payload);

        setShowRowswLoading(false);
        setImportStatusTableData(_get(response, "data.data", []));
      } catch (err) {
      }
    })();
  }, [activeTab, currentPage]);

  useEffect(() => {

    getImportStatusProgressList();

  }, [getImportStatusProgressList]);

  const onDelete = (importId) => {
    (async () => {
      try {

        await deleteImportStatus((importId || ""));

        getImportStatusProgressList();
      } catch (err) {
      }
    })();

  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchImportDetailsData = async (importId, columnName) => {
    let tmpSummaryDetailsData = _cloneDeep(summaryDetailsData)
    const response = await fetchImportDetails(importId, columnName);

    tmpSummaryDetailsData = {
      ...tmpSummaryDetailsData,
      [columnName]: _get(response, "data.data", [])
    }
    setSummaryDetailsData(tmpSummaryDetailsData);
  }

  const showImportDetails = async (selectedImportData) => {
    toggleDrawer();

    const response = await fetchImportSummary(_get(selectedImportData, "ImportId", ""));

    const summaryResponse = _get(response, "data.data[0]", {});

    let summaryError = false;
    if (!_isEmpty(summaryResponse) && (_get(summaryResponse, "InvalidRecordsCount", "") !== "")) {
      const invalidRecordCountData = JSON.parse(_get(summaryResponse, "InvalidRecordsCount", ""));
      if ((invalidRecordCountData || null) !== null) {
        summaryError = true;
        (invalidRecordCountData || []).forEach(element => {
          fetchImportDetailsData(_get(selectedImportData, "ImportId", ""), _get(element, "Column", ""));
        });
      } else {
        //setSuccessfuRecords(summaryResponse)
      }
    }

    setSummaryData(summaryResponse);
    setShowSummaryError(summaryError);
  }

  const showViewLogsDetails = async (selectedImportData) => {
    toggleViewLogDrawer();

    const response = await fetchImportProgressLogsDetails(_get(selectedImportData, "ImportId", ""));

    const logsResponse = _get(response, "data.data", []);

    setViewLogsData(logsResponse);
  }

  const onExport = (columnName) => {
    (async () => {
      try {
        const payload = {
          Name: (columnName || ""),
          importId: _get(selectedImportDetailData, "ImportId", "")
        }

        const response = await exportList("Failed_CRM_Import", payload);

        if (_get(response, "data.success", false) === true) {

          navigate("/download");
        }
      } catch (err) {
      }
    })();
  }

  const renderDrawer = () => {

    return (
      <Drawer
        open={isOpen}
        onClose={() => toggleDrawer()}
        direction="right"
        size={650}
        zIndex="9999"
        className="modal-import-success"
      >
        <div className="topn-header">
          <span className="selected-title">Imported successfuly</span>
          <span style={{ padding: '20px', float: 'right' }} onClick={() => toggleDrawer()}>
            <i className="fa fal fa-times clsbtnNPS fright pointer"></i>
          </span>
        </div>
        <div className="scrollbar">
          <div className="">
            <div className="import-success-top">
              <p className="my-4">
                <i className="fal fa-file" aria-hidden="true"></i>
              </p>

              <h3>
                <strong>{_get(summaryData, "TotalSuccessfullyImportedRecords", 0)} Subscribers </strong>
                were imported successfully</h3>

              <h3>bringing the total to {_get(summaryData, "TotalRecordsInList", 0)} in this list</h3>
            </div>

            {((showSummaryError || false) === true) && (
              <div className="import-success-danger">
                <i className="fal fa-exclamation-circle me-2" aria-hidden="true"></i>
                {_get(summaryData, "SkippedRecords", 0)} subscribers weren't imported. See problems below.
              </div>
            )}

            {(!_isEmpty(summaryDetailsData)) && (
              Object.keys(summaryDetailsData).map((columnName, i) => {

                return (
                  <div className="bx-boder-common nobg my-13">
                    <div className="_h_header white border-radius2">
                      <h3>{(!_isEmpty(_get(summaryDetailsData, `${columnName}`, []))) ? _get(summaryDetailsData, `${columnName}[0].ColumnDisplayText`, "") : ""}</h3>
                      <span className="flex-right">
                        <button className="btn btn-light" onClick={() => onExport(columnName)}>Export</button>
                      </span>
                    </div>

                    <div key={i} className="white">
                      <div className="row">
                        <div className="col-12">
                          <div className="dataTable-primary">
                            <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                              <thead>
                                <tr>
                                  <th className="table_cell_header pt-3">Email</th>
                                  <th className="table_cell_header pt-3">Name</th>
                                  <th className="table_cell_header pt-3">MobileNumber</th>
                                  <th className="table_cell_header pt-3">Invalid {(columnName || "")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {_get(summaryDetailsData, `${columnName}`, []).map((data) => {
                                  return (
                                    <tr>
                                      <td className="body_table_cell">
                                        {_get(data, "Email", "")}
                                      </td>
                                      <td className="body_table_cell">
                                        {_get(data, "FirstName", "")}
                                      </td>
                                      <td className="body_table_cell">
                                        {_get(data, "MobileCountryCode", "")} {_get(data, "MobileNumber", "")}
                                      </td>
                                      <td className="body_table_cell">
                                        {_get(data, `${columnName}`, "")}
                                      </td>
                                    </tr>
                                  )
                                })}

                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </Drawer >
    )
  }

  const renderViewLogDrawer = () => {

    return (
      <Drawer
        open={isViewLogsOpen}
        onClose={() => toggleViewLogDrawer()}
        direction="right"
        size={650}
        zIndex="9999"
        className="modal-view-log"
      >
        <div className="topn-header">
          <span className="selected-title">Import logs</span>
          <span style={{ padding: '20px', float: 'right' }} onClick={() => toggleViewLogDrawer()}>
            <i className="fa fal fa-times clsbtnNPS fright pointer"></i>
          </span>
        </div>
        <div className="scrollbar">
          {(!_isEmpty(viewLogsData)) && (
            (viewLogsData || []).map((row, i) => {
              const accessInfo = { key: "CustomerData / Import / 74547551 / MOCK_DATA_(3).csv " }; //JSON.parse(_get(row, "AccessInfo", ""));

              return (
                <div className="bx-boder-common nobg my-13">
                  <div className="_h_header white border-radius2">
                    <div className="view-log-text">
                      <p>{_get(accessInfo, "Key", "")}</p>
                      <p>{_get(row, "Lastupdated", "")}</p>
                      <p>{_get(row, "ListName", "")}</p>
                    </div>
                    <span className="flex-right">
                      <button className="btn btn-light" onClick={() => { }}>Failed</button>
                      <button className="btn btn-light" onClick={() => { }}>View report</button>
                    </span>
                  </div>
                  <div className="description">
                    {_get(row, "Description", "")}
                  </div>
                  {(_get(APITOKEN, "config.subscriptionInfo.SupportUrl", "") !== "") && (
                    <div className="contact-support-section">
                      <Link
                        to='#'
                        onClick={(e) => {
                          window.location.href = `mailto:${_get(APITOKEN, "config.subscriptionInfo.SupportUrl", "")}`;
                          e.preventDefault();
                        }}
                      >
                        Contact support
                      </Link>
                    </div>
                  )}
                </div>
              );
            })
          )}
        </div>
      </Drawer >
    )
  }

  // To Toggle Show Import Details Menu
  useEffect(() => {

    function handleClickOutside(event) {

      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowImportDetailsDropdown({});
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleChangePage = (pageNo) => {
    setCurrentPage(pageNo - 1);
  }

  return (
    <>
      <div ref={wrapperRef}></div>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum ToText="CRM List" To="/crm/people/listings" ToSecondPage="" ToSecondPageText="" CurrentPage="Import Status" />
        </div>

        <div className="mycontent">
          <ul className="tabing">
            <li><Link className={((activeTab || "") === "Completed") ? "active" : ""} onClick={() => { setActiveTab("Completed"); setCurrentPage(0); }}>Completed</Link></li>
            <li><Link className={((activeTab || "") === "Draft") ? "active" : ""} onClick={() => { setActiveTab("Draft"); setCurrentPage(0); }}>Draft</Link></li>
            <li><Link className={((activeTab || "") === "Failed") ? "active" : ""} onClick={() => { setActiveTab("Failed"); setCurrentPage(0); }}>Failed</Link></li>
            <li><Link className={((activeTab || "") === "Ongoing") ? "active" : ""} onClick={() => { setActiveTab("Ongoing"); setCurrentPage(0); }}>Import</Link></li>
          </ul>
        </div>
        <div>
          {((shoRowswLoading || false) === true) && (
            <ImportStatusRowsLoading />
          )}

          {((importTableData || []).length < 1) && ((shoRowswLoading || false) === false) && (
            <div className="sms_Sec_1 width100 white margin-bottom50">
              <div className="bx-boder-common common_Sec_1">
                <div className="bx-pad p-5">
                  <div className="alert alert-warning">
                    We are completing your import.
                    <br />
                    <br />
                    <p>Once the import proccess is done you will find your import under the tab Completed, Draft or Failed. If your import gets failed you can click on the three dots to view the import log.</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {((shoRowswLoading || false) === false) && (importTableData || []).map((row, key) => {
            const accessInfo = (_get(row, "AccessInfo", "") !== "") ? JSON.parse(_get(row, "AccessInfo", "")) : "";
            return (
              <Fragment key={key}>
                <div className="bx-boder-common width100 margin-top50 nobg inline-block">
                  <div className="_h_header white border-radius2 flex">
                    <h3>{_get(row, "FileName", "")}</h3>
                    {(((activeTab || "") === "Completed") || ((activeTab || "") === "Failed")) && (
                      <Fragment>
                        <div className="bx-dropdown ms-auto">
                          <i className="fas fa-ellipsis-h cursor-pointer" onClick={(e) => {
                            e.stopPropagation();

                            setShowImportDetailsDropdown({ ...showImportDetailsDropdown, [key]: !showImportDetailsDropdown[key] })
                          }}></i>

                          {((showImportDetailsDropdown[key] === true)) && (
                            <ul className="drop_menu_toppanel end-auto" ref={wrapperRef}>
                              {((activeTab || "") === "Completed") && (
                                <li><Link className="paddingleft20" onClick={(e) => {
                                  setSelectedImportDetailData(row);
                                  showImportDetails(row);
                                }}>Import details</Link></li>
                              )}

                              {((activeTab || "") === "Failed") && (
                                <li><Link className="paddingleft20" onClick={(e) => {
                                  setSelectedImportDetailData(row);
                                  showViewLogsDetails(row);
                                }}>View Log</Link></li>
                              )}
                            </ul>
                          )}
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="aggreategrid">
                    <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                      <thead>
                        <tr>
                          <th className="table_cell_header">List Name</th>
                          <th className="table_cell_header">Imported Time</th>
                          {(((activeTab || "") === "Failed")) && (<th className="table_cell_header">Problem</th>)}
                          {(((activeTab || "") !== "Failed")) && (<th className="table_cell_header">Uploaded file</th>)}
                          <th className="table_cell_header">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="body_table_cell">{_get(row, "ListName", "")}</td>
                          <td className="body_table_cell">{_get(row, "Lastupdated", "")}</td>
                          {(_get(row, "Status", "") !== 'Failed') && (
                            <td className="body_table_cell">{_get(accessInfo, "fileName", "")}</td>
                          )}
                          {(_get(row, "Status", "") === 'InSufficientFunds') && (
                            <td className="body_table_cell">Click here</td>
                          )}
                          <td className="body_table_cell">
                            <span className="text-07f cursor-pointer" onClick={() => onDelete(_get(row, "ImportId", ""))}>Delete</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Fragment>
            )
          })}

          {((importTableData || []).length > 0) && (
            <div className="dataTable-primary dataTable-primary-450 mt-12">
              <StaticPaginationDesign
                className="bg-f5f"
                isShowItemsPerPage={true}
                rowsPerPage={10}
                currentPage={currentPage + 1}
                totalRecords={(_get(importTableData, "[0].TotalRecords", 0) || 0)}
                currentRecords={((importTableData || []).length || 0)}
                onChangePage={(pageNo) => handleChangePage(pageNo)}
              />
            </div>
          )}

          {renderDrawer()}
          {renderViewLogDrawer()}
        </div>
      </div>
    </>
  )
}
export default ImportStatus;