import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import getLocaleDateString from "../TimeFormat";
import moment from 'moment';

const NotificationInformation = () => {
    const checkIsAuth = useSelector(state => state);
    const [notificationData, setNotificationData] = useState([]);
    const { APITOKEN } = useSelector(state => state);

    const f = getLocaleDateString(APITOKEN.config.cultureCode)

    const load = useCallback(async () => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            await Client.get(`Campaign/Notifications/notifications`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then(async (res) => {
                try {
                    setNotificationData(res['data']);

                } catch (err) {
                    console.log(err);
                }
            });
    }, [checkIsAuth])
    useEffect(() => {
        load();
    }, [load])
    const sharecontentrequest = (o, name) => {
        checkIsAuth['APITOKEN']['token'] &&
            Client.get(`Campaign/sharecontentrequest/${o['Id']}/takeaction/${name}`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then(async (res) => {
                try {
                    if(res['data'].Success){
                        load()
                    }

                } catch (err) {
                    console.log(err);
                }
            });
    }
    return (
        <div className="padding20 notifictionbx">
            <ul className="notibox">
                {notificationData && notificationData.map((v, i) => <li key={i}>
                    <p>
                        <strong>{v['Username']}</strong>
                        <span className="fright">{moment(v['CreatedAt']).utc().format(f)}</span>
                    </p>
                    <p className="nomargin">{v['Heading']} <strong>{v.CampaignName}</strong></p>
                    <p className="n-content" style={{ display: (v['IsAccepted'] !== false) ? 'none' : 'block', margin: '16px 0' }}>{v['Description']}  </p>
                    <div style={{ display: (v['NotificationType'] === 'campaigntransfer') ? 'block' : 'none' }}>
                        <div className="btn-group" style={{ display: ((v['IsAccepted'] === undefined || v['IsAccepted'] === null) && (v['Status'] === "")) ? 'none' : ((v['IsAccepted'])) ? 'none' : 'inline-block' }}>
                            <button type="button" className="grid_btn_1 grid_btn_reject margin-right15 eventnotification font-size13 decline" onClick={() => sharecontentrequest(v, 'Decline')} >Decline</button>
                            <button type="button" className="grid_btn_1 grid_btn_active font-size13" onClick={() => sharecontentrequest(v, 'Approved')}>Accept</button>
                        </div>
                        <div className="btn-group" style={{ display: (v['Status'] === 'Accepted') ? 'inline-block' : 'none', marginTop: '16px' }}>
                            <button className="grid_btn_1" disabled={(v['Status'] === 'Accepted') ? true : false}>Accepted</button>
                        </div>
                        <div className="btn-group" style={{ display: (v['Status'] === 'Rejected') ? 'inline-block' : 'none', marginTop: '16px' }}>
                            <button className="grid_btn_1" disabled={(v['Status'] === 'Rejected') ? true : false}>Rejected</button>
                        </div>
                    </div>
                    <div style={{ display: (v['NotificationType'] === 'insufficientfund') ? 'block' : 'none' }} className="text-right">
                        <button type='button' className="grid_btn_1 font-size13 margin-right15 decline">Cancel</button>
                        <button className="grid_btn_1 grid_btn_active font-size13 redirect_btn" type='button' >{v['ButtonName']}</button>
                    </div>
                </li>)
                }
            </ul>
        </div >
    )

}

export default NotificationInformation
