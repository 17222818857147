import { useState } from "react"
import { Link, useLocation } from "react-router-dom"
import Activity from "./Activity";
import ForYou from "./ForYou";
import NotificationInformation from "./NotificationInformation";

const NotificationWrapper = ({ onClick ,wrapperRef }) => {

    const [panelShow, setPanelShow] = useState({
        id: 0,
    });
    const location = useLocation();

    const notificationEvent = (e,f) => {
        e.stopPropagation();
        window._DEFAULT_Weglot.switchTo(localStorage.getItem('wglang'));
        setPanelShow({ id: f })
    }

    return (
        <>
            <div className="_notification_container" ref={wrapperRef} onClick={e => e.stopPropagation()}>
                <div className="topn-header" >
                    <span className="selected-title">For You</span>
                    <span style={{ padding: '20px', float: 'right' }}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer" onClick={onClick}></i>
                    </span>
                </div>
                <div className="_h_header b_top white">
                    <ul>
                        <li>
                            <Link to={`${location.pathname}${location.search}`} className={panelShow.id === 0 ? 'notiSelected' : ''} onClick={e => notificationEvent(e,0)}>For You</Link>
                        </li>
                        <li>
                            <Link to={`${location.pathname}${location.search}`} className={panelShow.id === 1 ? 'notiSelected' : ''} onClick={e => notificationEvent(e,1)}>Activity</Link>
                        </li>
                        <li>
                            <Link to={`${location.pathname}${location.search}`} className={panelShow.id === 2 ? 'notiSelected' : ''} onClick={e => notificationEvent(e,2)}>Notifications</Link>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    {panelShow.id === 0 ? <ForYou /> : panelShow.id === 1 ? <Activity /> : <NotificationInformation />}
                </div>
            </div>
            <div className="maskingNoti"></div>
        </>
    )

}

export default NotificationWrapper
