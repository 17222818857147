import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Language from './Language';
import Speaker from './Speaker';
import Speeches from './Speeches';
import Track from './Track';
import Category from './Categories';
import Video from './Videos';
import Code from './Code';
import PlayList from './PlayList';


const Tabing = ({ data }) => {
    const defaultId = 84;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)}>
                            <Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link>
                        </li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <Speaker /> : tabId === 82 ?
                        <Language /> : tabId === 85 ?
                            <Speeches /> : tabId === 86 ?
                                <Track /> : tabId === 83 ?
                                    <Category /> : tabId === 87 ? <Video /> : tabId === 88 ? <Code /> : <PlayList />}
                </div>
            }
        </>
    )
}

export default Tabing;