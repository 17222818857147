import _ from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Client from "../../GlobalAPICall";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import { CopyPaste } from "../CopyPaste";
import { FileImport } from "../FileImport";
import { RecipientsPanel } from "../RecipientsPanel";
import StepsDistribution from "../StepsTopBar";
import { encode as btoa } from "base-64";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
export const EmailRecipientScreen = () => {
  const { APITOKEN } = useSelector(state => state);
  const [searchVal, setSearchData] = useState([]);
  const [isRecipients, setIsrecipients] = useState([]);
  const [editAccount] = useState(
    getParameterByName("edit", window.location.href)
  );

  let [recipientsObject, setRecipientsObject] = useState({
    ParentObject: [],
    NestedObject: [],
    ExcludedObject: [],
  });
  const [IsLoader, setIsLoader] = useState(false);
  const navigate = useNavigate();
  let [manuallRecipient, setManuallRecipient] = useState({});
  const [selectedRecipient, setSelectedRecipients] = useState([]);
  const [emailActive, setEmailDropDownActive] = useState(true);
  const wrapperRef = useRef(null);
  const [recipientsList, setRecipients] = useState([]);
  const [importObject, setFileImportObject] = useState({});
  const [duplicateData, setDuplicateData] = useState(false);
  const [recipientTab, setRecipientTab] = useState({
    manullay: false,
    import: false,
    copy: false,
  });
  const crmSearch = e => {
    e.stopPropagation();
    Client.get(`CRM/search/${e.target.value}/OnNameAndEmail`)
      .then(res => {
        try {
          setSearchData(res.data.data);
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };
  var debounced = _.debounce(crmSearch, 500, { maxWait: 1000 });
  const selectedRecipients = (e, v) => {
    e.stopPropagation();
    const index = _.findIndex(searchVal, function (x) {
      return x.PeopleSubscriberId === v.PeopleSubscriberId;
    });
    searchVal[index].selected = true;
    setSearchData(searchVal);

    setSelectedRecipients(pre => [
      ...pre,
      {
        recipient: v.Email,
        PeopleSubscriberId: v.PeopleSubscriberId.toString(),
      },
    ]);
  };
  const removeRecipients = (e, v) => {
    e.stopPropagation();
    const index = _.findIndex(searchVal, function (x) {
      return x.PeopleSubscriberId === parseInt(v.PeopleSubscriberId);
    });
    if (index !== -1) {
      searchVal[index].selected = false;
      setSearchData(searchVal);
      setSelectedRecipients(
        selectedRecipient.filter(x => x.recipient !== searchVal[index].Email)
      );
      return false;
    }
    setSelectedRecipients(
      selectedRecipient.filter(x => x.recipient !== v.recipient)
    );
  };
  const manuallyRecipientAdd = e => {
    // const newGUID = Math.floor(Math.random() * 1000000);
    setSelectedRecipients(prev => [
      ...prev,
      { recipient: `${manuallRecipient.value}`, PeopleSubscriberId: null },
    ]);
    setManuallRecipient({ value: "", flag: false });
  };
  const manuallyRecipient = e => {
    var mailformat = /\S+@\S+\.\S+/;
    const validEmail = e.target.value.match(mailformat);
    if (validEmail !== null) {
      setManuallRecipient({ value: e.target.value, flag: true });
    } else {
      setManuallRecipient({ value: e.target.value, flag: false });
    }
  };
  const groupObjectFilter = res => {
    const groupArray = [];
    const newarray = _.sortedUniqBy(res, "id");
    for (const x of newarray) {
      groupArray.push({ type: x.type, id: x.id });
    }
    return groupArray;
  };
  // const excludeGroupsFn = res => {
  //     const groupArray = [];
  //     for (const x of res) {
  //         groupArray.push({ type: x.type, id: x.id })
  //     }
  //     return groupArray;
  // }
  const step1Complete = () => {
    const CampaignId = getParameterByName("cam", window.location.href);
    const groups = groupObjectFilter([
      ...recipientsObject.ParentObject,
      ...recipientsObject.NestedObject,
    ]);
    const excludeGroups = groupObjectFilter(recipientsObject.ExcludedObject);
    const obj = {
      recipients: selectedRecipient,
      groups: [...groups],
      excludedGroups: excludeGroups,
      isDraft: true,
      isAddContactsToCRM: false,
      sendingBatchId: CampaignId,
      recipientImportUrl: importObject.hasOwnProperty("fileSource")
        ? importObject.fileSource.Location
        : "",
      hasDuplicateSending: duplicateData,
    };
    setIsLoader(true);
    if (getParameterByName("edit", window.location.href) !== null) {
      PutAPI(obj, CampaignId);
    } else {
      PostAPI(obj, CampaignId);
    }
  };

  const PostAPI = (obj, CampaignId) => {
    const clone = getParameterByName("clone", window.location.href);
    const redirect = getParameterByName("redirect", window.location.href);
    // const path = getParameterByName("cam", window.location.href);
    Client.post(`Email/Send/Batch_Step1`, obj)
      .then(({ data }) => {
        try {
          if (data.Success) {
            const CampaignType = getParameterByName(
              "ctype",
              window.location.href
            );
            APITOKEN["config"].subscriptionInfo?.IsUTMVisibleOnSending
              ? navigate(
                  `/Email/UTM/${`${
                    CampaignType !== null
                      ? `${data.Id}?ctype=${CampaignType}&cam=${
                          redirect !== null ? redirect : CampaignId
                        }`
                      : data.Id
                  }`}${clone !== null ? "?clone=" + clone : ""}`
                )
              : navigate(
                  `/Email/Send/${data.Id}?ctype=5${
                    clone !== null ? `&clone=${clone}` : ""
                  }${redirect !== null ? `&cam=${redirect}` : ""}`
                );
            setIsLoader(false);
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const PutAPI = (obj, CampaignId) => {
    Client.put(`Email/Send/Batch_Step1/${CampaignId}`, obj)
      .then(res => {
        try {
          if (res.data.Success) {
            setIsLoader(false);
            navigate(`/Email/Emailsummary/${res.data.Id}`);
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  const recordCount = 14000;
  const [totalPaste, setTotalPaste] = useState(0);
  const copyPasteFN = e => {
    const val = e.target.value;
    let recipientArray = [];
    if (val !== "") {
      const finalVal = val.split("\n").join(",").replace(/\s+/g, "");
      const slitString = finalVal.split(",").splice(0, recordCount);
      setTotalPaste(slitString.length);
      if (slitString.length <= recordCount) {
        for (let x of slitString) {
          if (x !== "") {
            recipientArray.push({ recipient: x, PeopleSubscriberId: null });
          }
        }
        setSelectedRecipients(recipientArray);
      }
    } else {
      setTotalPaste(0);
      setSelectedRecipients([]);
    }
  };
  const crmList = () => {
    window.location.replace(
      `${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${
        APITOKEN.config.lcxtkf
      }&backUrl=${btoa(window.location.href)}&redurl=crm/people/joinlist`
    );
  };

  const editCaseCheckboxSelected = (prvs, res) => {
    const response = [];
    for (let x of prvs) {
      for (let e of res.ExcludedGroups) {
        if (!!x.segmentations) {
          for (let s of x.segmentations) {
            if (e.id === s.id) {
              s.exclude = true;
              s.isSelected = true;
            }
          }
        }
      }
      for (let y of res.Groups) {
        if (y.type === 0) {
          x.disabled = x.isSelected;
          prvs[0].disabled = false;
          if (!!x.segmentations) {
            for (let s of x.segmentations) {
              s.disabledSeg = s.isSelected;
            }
          }
        }
        if (x.id === y.id && y.type === 0) {
          console.log("first");
          x.isSelected = y.isSelected;
          y.checked = y.isAnySegmentSelected;
          x.display = true;
          x["ParentCheckboxSelected"] = y.isSelected;
          if (!!y.segmentations) {
            for (let global of x.segmentations) {
              for (let segment of y.segmentations) {
                if (global.id === segment.id) {
                  global.disabledSeg = false;
                  global.isSelected = segment.isSelected;
                }
              }
              // global['ParentCheckboxSelected'] = y.isAnySegmentSelected;
              for (let b of res.ExcludedGroups) {
                if (global.id === b.id) {
                  global["exclude"] = true;
                  global["isSelected"] = true;
                }
              }
            }
          }
        } else if (x.id === y.id) {
          x.display = true;
          prvs[0].allContactSelected = true;
          x.isSelected = y.isSelected;
          prvs[0].disabled = x.type === 0 ? false : true;
          if (!!prvs[0].segmentations) {
            for (let g of prvs[0].segmentations) {
              g.disabledSeg = true;
            }
          }
          x.checked = y.isAnySegmentSelected;
          x["ParentCheckboxSelected"] = y.isSelected;
          if (!!y.segmentations) {
            for (let g of x.segmentations) {
              for (let s of y.segmentations) {
                if (g.id === s.id) {
                  g.isSelected = s.isSelected;
                  setIsrecipients(prv => [...prv, s]);
                }
              }
              // g['ParentCheckboxSelected'] = y.isAnySegmentSelected;
              for (let b of res.ExcludedGroups) {
                if (g.id === b.id) {
                  g["exclude"] = true;
                  x.display = true;
                  g.isSelected = true;
                  g.disabledSeg = b.isChecked;

                  setIsrecipients(prv => prv.filter(x => x.id !== b.id));
                  //check All contact checkbox true / false //
                }
              }
            }
          }
        }
      }
      response.push(x);
    }
    return response;
  };

  const recipientObjectFilter = (prevs, response) => {
    const obj = { ParentObject: [], NestedObject: [], ExcludedObject: [] };
    for (let group of response.Groups) {
      if (group.isSelected) {
        obj.ParentObject.push(group);
      }
      if (group.segmentations !== undefined) {
        for (let segment of group.segmentations) {
          if (segment.isSelected) obj.NestedObject.push(segment);
        }
      }
    }
    for (let exclude of response.ExcludedGroups) {
      obj["ExcludedObject"].push(exclude);
    }
    return {
      ...prevs,
      ...{
        ParentObject: obj.ParentObject,
        NestedObject: obj.NestedObject,
        ExcludedObject: obj.ExcludedObject,
      },
    };
  };
  const recipientEdit = useCallback(() => {
    const clone = getParameterByName("clone", window.location.href);
    APITOKEN.token &&
      Client.get(
        `Email/Distribution/${
          clone !== null
            ? clone
            : getParameterByName("cam", window.location.href)
        }`,
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then(result => {
          try {
            let response = result.data;
            response.Groups = !!response.Groups ? response.Groups : [];
            setRecipients(prvs => editCaseCheckboxSelected(prvs, response));
            setRecipientsObject(prevs =>
              recipientObjectFilter(prevs, response)
            );
            setSelectedRecipients(response.Recipients);

            setRecipientTab(prv => ({
              ...prv,
              manullay: response.Recipients.length > 0 ? true : false,
            }));
            setDuplicateData(response.hasDuplicateSending);
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {
          console.error(err);
        });
  }, [APITOKEN]);
  // Recipients count method //
  const countFetch = recipientElement => {
    setRecipients(
      recipientsList.map(x =>
        x.id === recipientElement.id ? { ...x, isLoader: true } : { ...x }
      )
    );
    Client.get(`Email/Contacts/Count/${recipientElement.id}`)
      .then(({ data }) => {
        try {
          const { recordCount, segmentations } = data;
          setRecipients(
            recipientsList.map(x =>
              x.id === recipientElement.id
                ? {
                    ...x,
                    recordCount,
                    segmentations:
                      !!segmentations &&
                      x.segmentations.map((y, i) => ({
                        ...y,
                        recordCount: segmentations[i].recordCount,
                      })),
                  }
                : { ...x }
            )
          );
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setSearchData([]);
      }
    }
    if (
      editAccount !== null ||
      getParameterByName("clone", window.location.href) !== null
    ) {
      recipientEdit();
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [recipientEdit, editAccount]);
  return (
    <>
      <StepsDistribution Name="Email" Active={1} Hide={true} UTM={true} />
      <div className="dashbd_sec margin-top50 margin-bottom">
        <div ref={wrapperRef}></div>
        <div className="white bx-boder-common width100">
          <div className="_h_header">
            <h3>Email Recipients</h3>
            <span className="flex-right">
              <button
                type="button"
                className="grid_btn_1 grid_btn_active"
                onClick={crmList}
              >
                Create List
              </button>
            </span>
          </div>
          <RecipientsPanel
            setRecipientsObject={setRecipientsObject}
            recipientsObject={recipientsObject}
            SetRecipients={setRecipients}
            RecipientsList={recipientsList}
            isRecipients={isRecipients}
            setIsrecipients={setIsrecipients}
            countFetch={countFetch}
          />
          <div className="_h_header b_top">
            <h3>&nbsp;</h3>
            <span className="flex-right">
              <button
                type="button"
                className="grid_btn_1 nopadding nobg  vertical-middle noborder"
              >
                Include Duplicate data{" "}
              </button>
              <div className="grid_btn_1 vertical-middle nobg noborder">
                <input
                  type="checkbox"
                  className="grid_btn_1"
                  checked={duplicateData}
                  onChange={e => setDuplicateData(e.target.checked)}
                />
              </div>
            </span>
          </div>
          <div className="">
            <div className="_h_header noborder">
              <h3>&nbsp;</h3>
              <span className="flex-right">
                <button
                  type="button"
                  className="grid_btn_1  margin-right15"
                  onClick={() =>
                    setRecipientTab(prev => ({
                      ...prev,
                      manullay: !prev.manullay,
                      import: false,
                      copy: false,
                    }))
                  }
                >
                  {recipientTab.manullay ? "Close" : "Add Recipients Manually"}
                </button>
                <button
                  type="button"
                  className="grid_btn_1  margin-right15"
                  onClick={() =>
                    setRecipientTab(prev => ({
                      ...prev,
                      manullay: false,
                      import: !prev.import,
                      copy: false,
                    }))
                  }
                >
                  {recipientTab.import ? "Close" : "Quick Import"}
                </button>
                <button
                  type="button"
                  className="grid_btn_1 "
                  onClick={() =>
                    setRecipientTab(prev => ({
                      ...prev,
                      manullay: false,
                      import: false,
                      copy: !prev.copy,
                    }))
                  }
                >
                  {recipientTab.copy ? "Close" : "Copy Paste Recipients"}
                </button>
              </span>
            </div>
            {recipientTab.manullay && (
              <div className="bx-pad b_top">
                <div className="formmodel-bx drop-zone">
                  <label className="_l_able_cls">Search CRM</label>
                  <input
                    type="text"
                    className="inputformmodel"
                    placeholder="Search CRM"
                    onChange={e => debounced(e)}
                  />
                  {searchVal.length > 0 && (
                    <div className="data_reponse_bx">
                      <ul>
                        {searchVal.map((v, i) => (
                          <li key={i}>
                            {" "}
                            <button
                              key={i}
                              type="button"
                              disabled={v.selected ? true : false}
                              className="CRM_Count"
                              onClick={e => selectedRecipients(e, v)}
                            >
                              {v.FirstName} {v.Email} {v.SubscriberListName}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="formmodel-bx">
                  <span>
                    <strong>
                      To be able to manually add contacts you have to agree that
                      you Email Opt-in consent.{" "}
                    </strong>
                    <strong
                      className="link inline-block agreeLink pointer"
                      onClick={() => setEmailDropDownActive(!emailActive)}
                    >
                      I Agree
                    </strong>{" "}
                    &nbsp; &nbsp;
                  </span>
                  <input
                    type="checkbox"
                    checked={!emailActive}
                    className="inline-block vertical-middle"
                    onChange={() => setEmailDropDownActive(!emailActive)}
                  />
                </div>
                <div className="formmodel-bx">
                  <label className="_l_able_cls">Email</label>
                  <input
                    type="text"
                    className="inputformmodel margin-right15"
                    placeholder="Email"
                    style={{ width: "calc(100% - 484px)" }}
                    value={manuallRecipient.value || ""}
                    disabled={emailActive}
                    onChange={e => manuallyRecipient(e)}
                  />

                  <button
                    type="button"
                    className="grid_btn_1 grid_btn_active btnadd"
                    disabled={manuallRecipient.flag ? false : true}
                    style={{
                      height: "35px",
                      verticalAlign: "middle",
                    }}
                    onClick={() => manuallyRecipientAdd()}
                  >
                    Add
                  </button>
                </div>
                <div className="formmodel-bx">
                  <label className="_l_able_cls">Recipients</label>
                  <div className="inputformmodel textareaModel overflowAuto">
                    {selectedRecipient.map((v, i) => (
                      <div className="l_b-bx" key={i}>
                        {v.recipient}
                        <span></span>
                        <button
                          type="button"
                          onClick={e => removeRecipients(e, v)}
                          className="nobg noborder pointer"
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            {recipientTab.import && (
              <>
                {importObject.sample_data &&
                importObject.sample_data.length > 0 ? (
                  <table cellPadding="0" cellSpacing="0" className="smstable">
                    <thead>
                      <tr>
                        <th className="table_cell_header">Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {importObject.sample_data.map((x, i) => (
                        <tr key={i}>
                          <td className="body_table_cell">
                            {x.Email || x["email"]}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <FileImport
                    SetFileImportObject={setFileImportObject}
                    APIName="Email"
                    Name="Email"
                  />
                )}
              </>
            )}
            {recipientTab.copy && (
              <CopyPaste
                copyPasteFN={copyPasteFN}
                TotalPaste={totalPaste}
                Name="Email"
                Placeholder="For multiple emails, use 'Enter' to separate emails. Don't use comma, semicolon etc."
              />
            )}

            {/* Button */}
            <div className="text-right padding20 b_top">
              <button
                className="grid_btn_1 grid_btn_active btn-gape-15 recipients_panel_fill"
                disabled={
                  selectedRecipient.length > 0 ||
                  recipientsObject.ParentObject.length > 0 ||
                  recipientsObject.NestedObject.length ||
                  importObject.hasOwnProperty("fileSource")
                    ? false
                    : true
                }
                type="button"
                onClick={step1Complete}
              >
                <ButtonLoader IsLoader={IsLoader}>
                  {getParameterByName("edit", window.location.href) !== null
                    ? "Update"
                    : "Continue"}
                </ButtonLoader>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
