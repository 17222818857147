import axios from "axios";
import { store } from './Redux/Store'
const url = process.env;

// Create a new axios client with default settings
const Client = axios.create({
   baseURL: url.REACT_APP_API_URL,
   headers: {
      is_calling_from_react_app: true,
   },
});

// Add an interceptor to modify the request before it is sent
Client.interceptors.request.use( function (config) {
   const token =  store.getState().APITOKEN.token;
   config.headers.Authorization = `Bearer ` + token;
   return config;
},(error) => {
   // Handle errors
   return Promise.reject(error);
});  


export const Export_Client = axios.create({
   baseURL: url.REACT_APP_API_URL_EXPORT,
   headers: { is_calling_from_react_app: true }
});
Export_Client.interceptors.request.use( function (config) {
   const token =  store.getState().APITOKEN.token;
   config.headers.Authorization = `Bearer ` + token;
   return config;
});  

export const Export_BULK_Client = axios.create({
   baseURL: url.REACT_APP_API_URL_BUlK_IMPORT,
   headers: { is_calling_from_react_app: true }
});
Export_BULK_Client.interceptors.request.use( function (config) {
   const token =  store.getState().APITOKEN.token;
   config.headers.Authorization = `Bearer ` + token;
   return config;
});  

// Export the axios client instance as the default export for this module

export default Client;