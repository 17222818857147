const ImportStatusRowsLoading = () => (
  <div className="skeleton-tab">
    <div className="bx-boder-common width100 margin-top50 white inline-block">
      <div className="_h_header border-radius2 skeleton-row">
        <div className="skeleton"></div>
      </div>

      <div className="skeleton-row">
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
      </div>
    </div>

    <div className="bx-boder-common width100 margin-top50 white inline-block">
      <div className="_h_header border-radius2 skeleton-row">
        <div className="skeleton"></div>
      </div>

      <div className="skeleton-row">
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
      </div>
    </div>
    
    <div className="bx-boder-common width100 margin-top50 white inline-block">
      <div className="_h_header border-radius2 skeleton-row">
        <div className="skeleton"></div>
      </div>

      <div className="skeleton-row">
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
        <div className="skeleton"></div>
      </div>
    </div>
  </div>
);

export default ImportStatusRowsLoading;  