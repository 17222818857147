import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import moment from 'moment';
import getLocaleDateString from '../../TimeFormat';

import { useLocation } from 'react-router-dom';
import DeleteListTemplate from "../../ReuseTemplate";
import BlankData from "../../BlankData";
const ShareWithYou = ({ Id }) => {
    const checkIsAuth = useSelector(state => state);
    const f = getLocaleDateString(checkIsAuth.APITOKEN.config.cultureCode);
    const location = useLocation();
    const [data, setData] = useState([]);
    const [deleteId, setDeleteId] = useState('');
    const [toggleValue, setToggle] = useState(false);
    const load = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`Campaign/sharecontentrequests/${Id}/campaign`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                try {
                    setData(res['data']);
                } catch (err) {
                    console.error(err);
                }

            });
    }, [checkIsAuth, Id])

    useEffect(() => {
        load()
    }, [load]);

    const actionEvent = (f, v, id,flag) => {
        setDeleteId(v.id)
        if (flag) {
            setToggle(true);
        } else {
            declineConfirm(id);
        }
    }
    const deleteConfirm = () => {
        Client.delete(`Campaign/sharecontentrequest/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.Success) {
                        setToggle(false);
                        load();
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => console.error(err))
    }
    const declineConfirm = takeactionId => {
        Client.get(`Campaign/sharecontentrequest/${deleteId}/takeaction/${takeactionId}`, {
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.Success) {
                        load();
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => console.error(err))
    }
    return (
        <>
            {toggleValue ? <DeleteListTemplate title="Do you want to delete the share campaign" content="This will permanently delete this template and it can not be restored." onClick={() => setToggle(false)} deleteDownload={deleteConfirm} /> :

                <div className="bx-boder-common width100 nobg">
                    {data.length > 0 ?
                        data.map((v, i) =>
                            <div className="-selection-select" key={i}>
                                <div className="_list_fx">
                                    <div className="list_title_folder">
                                        <div className={`_h_header border-bottom-left-radius white borderraduisAll ${(i === 1) ? 'b_top border-top-left-radius' : ''}`}>
                                            <h3 className="">{v.name}</h3>
                                        </div></div>
                                    <div className="list_table_listing">
                                        <table cellPadding="0" cellSpacing="0" className="smstable white" width="100%">
                                            {v.data.length > 0 &&
                                                <thead>
                                                    <tr>
                                                        <th className="table_cell_header">Name</th>
                                                        <th className="table_cell_header">Content</th>
                                                        <th className="table_cell_header">Share Date/Time</th>
                                                        <th className="table_cell_header">From</th>
                                                        <th className="table_cell_header">Actions</th>
                                                    </tr>
                                                </thead>
                                            }
                                            <tbody className="table_listing white">
                                                {v.data.length === 0 ? <BlankData title="These are the templetes which are shared with you but still not accepted." content="" colSpan="6" /> :
                                                    v.data.map((x, index) => <tr key={index}>
                                                        <td className="body_table_cell">{x['name']}</td>
                                                        <td className="body_table_cell">{x['content']}</td>
                                                        <td className="body_table_cell">{moment(x['createdDateTime']).format(f)}</td>
                                                        <td className="body_table_cell">{x['email']}</td>
                                                        <td className="body_table_cell">
                                                            <a
                                                                href={(i === 0) ? x['previewUrl'] : `${location.pathname}`} target={i === 0 ? '_blank' : '_self'}
                                                                onClick={(e) => { e.stopPropagation(); actionEvent(i, x, 1,false) }}
                                                                rel="noreferrer"
                                                                className={`inline-block link  ${(i === 1) ? '-link-ac' : ''}`}
                                                            >{(i === 0) ? 'View' : 'Accept'}
                                                            </a>
                                                            &nbsp; | &nbsp;
                                                            <button type="button" onClick={() => actionEvent(i, x, 2,true)} className={`inline-block link noborder nobg  pointer ${(i === 1) ? '-link-ac' : ''}`}>{(i === 0) ? 'Delete' : 'Decline'}</button>
                                                        </td>
                                                    </tr>)}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>)
                        : <></>}
                </div>
            } </>
    )

}

export default ShareWithYou
