
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import Client from '../GlobalAPICall';
import moment from 'moment';
import getLocaleDateString from '../TimeFormat';
import DeleteListTemplate from '../ReuseTemplate';
import BlankData from '../BlankData';
import CampaignPreview from '../ReuseTemplate/CampaignPreview';
import { useNavigate } from 'react-router';
import BalanceCheckFn from "../ReuseTemplate/BalanceCheckFn";
import getParameterByName from '../ReuseTemplate/GetRoute';
import { encode as btoa } from 'base-64'
import Cookies from 'js-cookie'

const ScheduledSedningDashboard = () => {
    const { APITOKEN } = useSelector(state => state);
    const [forSending, setdataForSending] = useState([]);
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [campaignId, setCampaignId] = useState('');
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const [previewDisplay, setPreviewDisplay] = useState(false);
    const [previewLink, setPreviewLink] = useState({});
    const navigate = useNavigate();
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/scheduledsendings`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({data}) => {
                    try {
                        setdataForSending(data);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false);
        }
    }, [APITOKEN]); // <-- add any missing dependencies react warns about
    const ScheduleJobStatusPause = ({ EditSummaryURL, Id }) => {
        Client.patch(`SMS/ScheduleJobStatusPause/${Id}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                if (data[0]['status']) {
                    navigate(EditSummaryURL);
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.log(err));
    }
    const deleteSending = () => {
        Client.delete(`SMS/${campaignId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                if (data.Success) {
                    setDeleteFlag(false);
                    loadApiCall();
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
        });
    }
    const previewCampaign = (v) => {
        setPreviewDisplay(!previewDisplay);
        setPreviewLink(v);
    }
    const reschedule = ev =>{
        if(!!ev.PaymentMetadata){
        setLowBalancePopup(prv=>({
            ...prv,
            balance:ev.PaymentMetadata,
            display:true
        }))
    }else{
        navigate(`${ev.DistributionType === '1'?`/SMS/SMSsummary/${ev.Id}`:`/Email/Emailsummary/${ev.Id}`}`);
    }
    }
    const gotoEditor = object => {

        const secreatKey = Cookies.get('secretKey');
        window.location.replace(`${APITOKEN.config.subscriptionInfo.EditorDomainUrl}${object.EditDesignUrl}&theme=1&type=${getParameterByName('type', window.location.href)}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}&lcxtkf=${APITOKEN.config.lcxtkf}`);
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {deleteFlag ? <div className="margin-top50 inline-block width100"><DeleteListTemplate title="Do you want to Delete this sending?" content="Deleting the scheduled sending won't delete the campaign." colSpan="5" onClick={() => setDeleteFlag(false)} deleteDownload={deleteSending} /></div> : forSending.length === 0 ? <div className=" margin-top50 inline-block width100"><table width="100%" cellPadding="0" cellSpacing="0" className="white defaultborder"><tbody><BlankData title="Currently you don’t have any scheduled sendings." content="Once you have created and scheduled a sending you will find it here." colSpan="5" /></tbody></table></div> : forSending.map((v, i) => <div className=" inline-block width100 white bx-boder-common border-radius2 margin-top50" key={i}>
                <div className="topbarListing">
                    <h3 className="titleHover">
                        <label style={{ fontSize: '20px', fontWeight: '400' }}>Schedued For {moment(JSON.parse(v['ScheduleDateTime'])["dateTime"]).format(f)}  {JSON.parse(v['ScheduleDateTime'])['offset']}</label>
                    </h3>
                    <span className="flex-right drop-zone">
                        <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -isReschedule-cam-" style={{ display: (v['IsRescheduleNeeded']) ? 'inline-block' : 'none' }} onClick={() =>reschedule(v)} >Reschedule</button>
                        <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -edit-cam-" onClick={()=>gotoEditor(v)} style={{ display: (v['IsSMSText'] === 'true') ? 'none' : 'inline-block' }}>Edit Design</button>
                        <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -edit-summary" onClick={() => ScheduleJobStatusPause(v)} disabled={!v['CanEdit']}>Edit Sending Summary</button>
                        <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle -delete-cam" onClick={() => { setDeleteFlag(true); setCampaignId(v.Id) }} disabled={!v['CanDelete']}>Delete Sending</button>
                    </span>
                </div>
                <div className="topbarListing">
                    <div className="titleHover">
                        <label className="font-16">Sending Name: {v[
                            'SendingName']}</label>
                    </div>
                    <div className="text-right" style={{ flex: '1', display: v['IsPaused'] ? 'block' : 'none' }}>
                        <button type="button" className="paused inline-block" onClick={() => ScheduleJobStatusPause(v)}>Sending has been paused to be resumed. </button>
                    </div>
                </div>
                <div className="_listing_bx_content">
                    <div className="c-img" style={{ overflow: 'hidden' }}>
                        <div className="preview-tag" onClick={()=>previewCampaign(v)}
                            style={{ display: (v['HasPreview']) ? 'flex' : 'none' }}>{(v['ContentType'] === 2) ? 'Landing Page Preview' :(v['ContentType'] === 5)? 'Email Preview':'Survey Preview'}</div>
                        <img src={v['ImageUrl']} alt="" width="270" /></div>
                    <div className="-cam-right">
                        <div className="-cam-detail-wrap">
                            <table cellPadding="0" cellSpacing="0" width="100%">
                                <thead>
                                    <tr>
                                        <th style={{ width: '40%' }}>Details</th>
                                        <th>Creation</th>
                                        <th>Ends</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{v['SendingDescription']}</td>
                                        <td>{v['Creation']}</td>
                                        <td>{(v['EndDate'] === null) ? '' : moment(v['EndDate']).format(f)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="list-btm">
                            <div className="cam-info">
                                <table cellPadding="0" cellSpacing="0">
                                    <tbody>
                                        <tr>
                                            <td>Scheduled By</td>
                                            <td>{v['ScheduledBy']}</td>
                                        </tr>
                                        <tr>
                                            <td>Date Time</td>
                                            <td>{moment(v['InsertDateTime']).format(f)}</td>
                                        </tr>
                                        <tr>
                                            <td>Recipients</td>
                                            <td>
                                                <span>{v.RecipientCount }</span></td>
                                        </tr>
                                        <tr>
                                            <td>Creation Name</td>
                                            <td className="noproperties">
                                                {v['CreationName']}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="cam-qr inline-block">
                                <img src={v['QrCodeUrl']} alt="" className="inline-block" width="120" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
            {previewDisplay ? <CampaignPreview Close={setPreviewDisplay} Link={previewLink} /> : <></>}
            {lowBlance.display ? <BalanceCheckFn APITOKEN={APITOKEN} lowBlance={lowBlance} setLowBalancePopup={setLowBalancePopup} removeWaterMark={() => {}} /> : <></>}
        </>
    )

}

export default ScheduledSedningDashboard
