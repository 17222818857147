import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router"
import Client from "../../../../GlobalAPICall";
import getParameterByName from '../../../../ReuseTemplate/GetRoute'
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";

export const CreateTemplate = () => {
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);
    const [templateObject, setCreateUTMTemplate] = useState({
        UTMCampaign: [],
        Medium: [],
        Source: [],
        Content: [],
        Term: [],
    })

    const [UTMTSelected, setCreateUTMTSelected] = useState({
        UTMCampaign: "",
        Medium: "",
        Source: "",
        Content: "",
        Term: "",
    })
    const [IsLoader, setIsLoader] = useState(false);



    const loadMedium = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMCategories/Medium`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data) {
                            setCreateUTMTemplate(prev => ({ ...prev, Medium: res.data }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])
    const loadSource = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMCategories/Source`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data) {
                            setCreateUTMTemplate(prev => ({ ...prev, Source: res.data }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])
    const loadContent = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMCategories/Content`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data) {
                            setCreateUTMTemplate(prev => ({ ...prev, Content: res.data }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])
    const loadTerm = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMCategories/Term`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data) {
                            setCreateUTMTemplate(prev => ({ ...prev, Term: res.data }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])
    const loadGetUTMCampaign = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMCampaign`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.length > 0) {
                            setCreateUTMTemplate(prev => ({ ...prev, UTMCampaign: res.data }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])

    const editUtmTemplate = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`UTM/GetUTMTemplates`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.length > 0) {
                            const newaray = res.data.filter(x => x['rowUID'] === getParameterByName('cam', window.location.href));
                            setCreateUTMTSelected({
                                UTMCampaign: newaray[0].campaignId,
                                Source: newaray[0].sourceId,
                                Medium: newaray[0].mediumId,
                                Content: newaray[0].contentId,
                                Term: newaray[0].termId,
                                FolderId: newaray[0].folderId
                            })
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                })
    }, [APITOKEN])


    useEffect(() => {
        loadGetUTMCampaign();
        loadMedium();
        loadSource();
        loadContent();
        loadTerm();
        if (getParameterByName('edit', window.location.href) !== null) {
            editUtmTemplate();
        }
    }, [loadGetUTMCampaign, loadMedium, loadSource, loadContent, loadTerm, editUtmTemplate]);


    const SaveFN = () => {
        setIsLoader(true);
        const obj = {
            "contentId": (getParameterByName('edit', window.location.href) !== null) ? UTMTSelected.FolderId : getParameterByName('cam', window.location.href),
            "campaignId": UTMTSelected.UTMCampaign,
            "mediumId": UTMTSelected.Medium,
            "sourceId": UTMTSelected.Source,
            "utmContentId": UTMTSelected.Content,
            "termId": UTMTSelected.Term
        };
        if (getParameterByName('edit', window.location.href) !== null) {
            putAPI(obj)
        } else {
            postAPI(obj)
        }
    }

    const putAPI = (obj) => {
        Client.put(`UTM/UpdateUTMTemplate/${getParameterByName('cam', window.location.href)}/template_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    setIsLoader(false);
                    if (data.success) {
                        navigate(`/create/templates/UTM?type=78`)
                    }
                } catch (err) {
                    console.error(err)
                }
            }).catch(err => {
                console.log(err);
                setIsLoader(false);
            });
    }

    const postAPI = (obj) => {
        Client.post(`UTM/SaveUTMTemplate`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    setIsLoader(false);
                    if (data.success) {
                        navigate(`/create/templates/UTM?type=78`)
                    }
                } catch (err) {
                    console.error(err)
                }
            }).catch(err => {
                console.log(err);
                setIsLoader(false);
            });
    }


    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/create/templates/UTM?type=78" ToText="Templates" CurrentPage="UTM Template" />
            </div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 white bx-boder-common width100">
                    <div className="_h_header">
                        <h3>UTM Template</h3>
                    </div>
                    <div className="padding20">
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">UTM Campaign</label>
                            <select className="inputformmodel width100"
                                onChange={e => setCreateUTMTSelected(prev => ({ ...prev, UTMCampaign: e.target.value }))}
                                value={UTMTSelected.UTMCampaign}>
                                <option value="">Select</option>
                                {templateObject.UTMCampaign.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">UTM Medium *</label>
                            <select className="inputformmodel width100"
                                onChange={e => setCreateUTMTSelected(prev => ({ ...prev, Medium: e.target.value }))}
                                value={UTMTSelected.Medium}
                            >
                                <option value="">Select</option>
                                {templateObject.Medium.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">UTM Source *</label>
                            <select className="inputformmodel width100" value={UTMTSelected.Source}
                                onChange={e => setCreateUTMTSelected(prev => ({ ...prev, Source: e.target.value }))}

                            >
                                <option value="">Select</option>
                                {templateObject.Source.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">UTM Content</label>
                            <select className="inputformmodel width100" value={UTMTSelected.Content}
                                onChange={e => setCreateUTMTSelected(prev => ({ ...prev, Content: e.target.value }))}>
                                <option value="">Select</option>
                                {templateObject.Content.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">UTM Term</label>
                            <select className="inputformmodel width100" value={UTMTSelected.Term}
                                onChange={e => setCreateUTMTSelected(prev => ({ ...prev, Term: e.target.value }))}>
                                <option value="">Select</option>
                                {templateObject.Term.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                            </select>
                        </div>
                    </div>
                    <div className="text-right padding20">
                        <button type="button" className="grid_btn_1 nobg noborder _no_cls_delete margin-right10" onClick={() => navigate(`/create/templates/UTM?type=78`)}>Cancel</button>
                        <button type="button" className="grid_btn_1 grid_btn_active save_Btn  margin-right10" onClick={SaveFN} disabled={
                            (UTMTSelected.Medium !== "" && UTMTSelected.Source !== "") ? false : true
                        }>   <ButtonLoader IsLoader={IsLoader}>
                                {getParameterByName('edit', window.location.href) !== null ? 'Update' : 'Save'}
                            </ButtonLoader>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

