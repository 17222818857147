
export const HightChartBestDay = {
    chart: {
        type: 'column'
    },
    title: {
        text: ''
    },
    subtitle: {
        text: ''
    },
    accessibility: {
        announceNewData: {
            enabled: true
        },
        enabled: false
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        title: {
            text: ''
        }

    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            borderWidth: 0,
            dataLabels: {
                enabled: false,
                format: '{point.y:.1f}%'
            }
        }
    },
    tooltip: {
        headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
        pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    },
    series: [{
        pointWidth: 30,
        colorByPoint: true
    }]
}

export const HightChartColorProps = { colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 }


export const maxHits = data => {
    return data.filter(function (o) { return o.Hits === Math.max.apply(Math, data.map(function (o) { return o.Hits; })) });
}
