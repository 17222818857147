import axios from 'axios';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Client from '../../GlobalAPICall';
import StepsDistribution from '../StepsTopBar';
import './index.css'
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';
export const SMSSummary = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    let timerId = useRef(null)
    const [IsLoader, setIsLoader] = useState(false);
    const [allContentLoaded, setallContentLoaded] = useState({ aggregated: false, editLoad: false })
    const [getAllEmailSendingDetails, setAllEmailSendingDetails] = useState({});
    const [isloader, setLoader] = useState(true);
    const [apiResponse, setAPIResponse] = useState({success:true});
    const [messageCount, setMessageCount] = useState({ listWiseRecipientsDetails: [], manualRecipients: [] });
    const navigate = useNavigate();
    const messageCountLoad = useCallback(() => {
        const cancelToken = axios.CancelToken.source();

        clearTimeout(timerId.current);
        APITOKEN.token &&
            Client.get(`SMS/Distribution/${id}/Preview/AggregatedMessageCount`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setMessageCount(prv => ({
                            ...prv,
                            ...data,
                            manualRecipients: data.manualRecipients
                        }));
                        if (data['isPreviewReadyToSend']) {
                            setallContentLoaded(prv => ({ ...prv, aggregated: true }));
                            setMessageCount(prv => ({
                                ...prv,
                                listWiseRecipientsDetails: data.listWiseRecipientsDetails
                            }));
                        } else {
                            timerId.current = setTimeout(function () {
                                setallContentLoaded(prv => ({ ...prv, aggregated: false }));
                                messageCountLoad();
                            }, 4000);
                        }
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                });
        return () => {
            clearTimeout(timerId.current);
            cancelToken.cancel();
        }
    }, [APITOKEN, id]);
    const load = useCallback(() => {
        APITOKEN.token &&
            Client.get(`SMS/Distribution/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setallContentLoaded(prv => ({ ...prv, editLoad: true }));
                        setLoader(false);
                        setAllEmailSendingDetails(data);
                        if (!!data.recipientImportUrl) {
                            filterImportFile(data);
                        }
                        messageCountLoad();

                    } catch (err) {
                        console.error(err);
                        setallContentLoaded(prv => ({ ...prv, editLoad: false }));
                    }

                }).catch((err) => {
                    setallContentLoaded(prv => ({ ...prv, editLoad: false }));
                });
    }, [APITOKEN, id, messageCountLoad]);

    const filterImportFile = data => {
        let split = data.recipientImportUrl.split("/");
        const fileName = split[split.length - 1].split('.');
        setAllEmailSendingDetails(prv => ({
            ...prv,
            recipientImportUrl: fileName[0]
        }))

    }

    const emailSend = () => {
        setIsLoader(true);
        setAPIResponse({success:true});
        APITOKEN.token &&
            Client.post(`SMS/Send/${id}/Confirm`, {}, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(async ({ data }) => {
                    try {
                        if (data.success) {
                            setIsLoader(false);
                            if (getAllEmailSendingDetails.isQueuePaused) {
                                await requeueJobFn();
                            }
                            (data.IsScheduled) ? navigate('/dashboard') : navigate(`/insights/smsDetails/${data['Id']}?f=13`);
                        }else{
                            setAPIResponse(data);
                            setIsLoader(false);
                        }

                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err);
                });
    }
    const requeueJobFn = () => {
        Client.patch(`SMS/ScheduleJobStatusRequeue/${id}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                // if (res.data[0]['status']) {
                //     navigate('/dashboard');
                // }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.log(err));
    }
    const [spliceCount, setSpliceCount] = useState(10);
    const moreRecipients = () => {
        setSpliceCount(spliceCount === 14000 ? 10 : 14000)
    }

    useEffect(() => {
        load();
        return (() => {
            clearTimeout(timerId.current);
        })
    }, [load])
    return (
        <>
            <StepsDistribution Name="SMS" Active={4} />
            <div className="dashbd_sec _sms_Summary margin-bottom">
                <div className="sms_Sec_1 bx-boder-common inline-block">
                    <div className="_h_header  flex-direction-column">
                        <h3>SMS Summary</h3>
                        <p>* The count of displayed contacts may vary during sending due to duplicates or excluded contacts.</p>
                    </div>
                    <div className="bx-pad">
                        {isloader ? <>

                            {[1, 2, 3, 4].map((i) => <div key={i}><div className="skeleton-loader" style={{ height: '50px', marginBottom: '20px' }}></div><div className="skeleton-loader" style={{ height: '20px', marginBottom: '20px' }}></div></div>)}

                        </> :
                            <table className="summary_table" cellPadding="0" cellSpacing="0">
                                <tbody>
                                    <tr>
                                        <td><strong>Recipients</strong></td>
                                        <td>
                                            {allContentLoaded.aggregated ?
                                                <>
                                                    {messageCount.listWiseRecipientsDetails.length > 0 &&
                                                        messageCount.listWiseRecipientsDetails.map((v, i) => {
                                                            return <div key={i} className='formmodel-bx'>
                                                                <div className="mainlist" ><p><span>List:&nbsp;</span>
                                                                    <button type="button" onClick={() => navigate(`/SMS/recipient?cam=${id}&type=47&edit=true`)}
                                                                        className="link inline-block noborder nobg">{v['listDisplayName']}</button></p>
                                                                    <p>Segment included: {v['includedMessage']}</p><p className="segmentlist"></p>
                                                                </div>

                                                                {!v['excludedListNames'] ? v['incluedSegmentTextType'] === 0 &&
                                                                    v['segmentsCountDetails'] !== undefined &&
                                                                    v['segmentsCountDetails'].map((y, i) =>
                                                                        <p key={i}>
                                                                            {/* <span>Segment:&nbsp;</span> */}
                                                                            {/* <button type="button" onClick={() => navigate(`/SMS/recipient?cam=${id}&type=47&edit=true`)}
                                                                        className="link inline-block ">{y['listDisplayName']}</button> */}
                                                                            <p style={{ display: y['isExcluded'] ? 'block' : 'none' }}>Excluded segment</p>
                                                                        </p>
                                                                    ) : v.excludedListNames.map((y, i) => <p key={i}><span>Excluded segment:&nbsp;</span> {y}</p>)}


                                                            </div>
                                                        })}
                                                    {messageCount.manualRecipients && messageCount.manualRecipients.length > 0 &&
                                                        <p className="manual_counts">
                                                            Manually added:&nbsp;
                                                            {messageCount.manualRecipients.filter((x, i) => i < spliceCount).map((v, i) =>
                                                                <button key={i} onClick={() => navigate(`/SMS/recipient?cam=${id}&type=47&edit=true`)}
                                                                    type="button"
                                                                    className="link inline-block nopadding">{(v['Recipient'] !== null) ? v['Recipient'] : ''}
                                                                    {(i === messageCount.manualRecipients.length - 1 ? '' : `,`)}&nbsp;</button>)
                                                            }
                                                            {messageCount.manualRecipients.length > 10 &&
                                                                <button type="button" onClick={moreRecipients} className="toggle link">{spliceCount > 10 ? 'less' : 'more..'}</button>}
                                                        </p>
                                                    }
                                                    {!!getAllEmailSendingDetails.recipientImportLink && <p>Import: <button type='button' onClick={() => navigate(`/SMS/recipient?cam=${id}&type=47&edit=true`)}>{getAllEmailSendingDetails.recipientImportUrl}</button></p>}
                                                </>
:<span className="skeleton-loader" style={{ width: '30%' }}></span>}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total Recipients</strong></td>
                                        <td>  {allContentLoaded.aggregated ? <button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/recipient?cam=${id}&type=47&edit=true`)}>{messageCount.totalRecipientCount}</button> :<span className="skeleton-loader" style={{ width: '30%' }}></span>}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Sender Name	</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.SenderFrom}</button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>SMS Content</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.Content.typeName}</button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Landing Page</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding word-wrap" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.Content.typeId === 7 ? getAllEmailSendingDetails.Content.typeName : `${getAllEmailSendingDetails.Content.detail.Name} ${getAllEmailSendingDetails.Content.detail.PreviewUrl}`}</button></td>

                                    </tr>
                                    <tr>
                                        <td><strong>SMS Text</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.Message}</button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total SMS</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{messageCount.totalMessageCount}</button></td>
                                    </tr>

                                    <tr>
                                        <td><strong>Shortner Url</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>
                                            {getAllEmailSendingDetails.ShortnerUrl?.ShortnerUrl}
                                        </button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Unsubscribe Content</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding word-wrap" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.Unsubscribe?.name}</button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Unsubscribe Link</strong></td>
                                        <td ><button type="button" className="nobg noborder link pointer  text-left nopadding word-wrap" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.Unsubscribe?.detail.name}<br />{getAllEmailSendingDetails.Unsubscribe?.detail.shortPreviewUrl
                                        }</button></td>
                                    </tr>
                                    <tr>
                                        <td><strong>SMS Option</strong></td>
                                        <td><button type="button" className="nobg noborder link pointer  text-left nopadding" onClick={() => navigate(`/SMS/Send/${id}?edit=true`)}>{getAllEmailSendingDetails.ScheduleDateTime === undefined ? 'Send Now' : `${getAllEmailSendingDetails.ScheduleDateTime.dateTimeFormattedAsString}  ${getAllEmailSendingDetails.ScheduleDateTime.offset}`}</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        {!apiResponse.success && <p className='validation'>{ apiResponse.Message }</p>}
                        <div className="text-right margin-top50 button_group">
                            {(allContentLoaded.aggregated && allContentLoaded.editLoad) ?
                                <>
                                    <button className="grid_btn_1 noborder btn-gape-15 nobg" type="button" onClick={requeueJobFn}>Cancel</button>
                                    <button className="grid_btn_1 grid_btn_active btn-gape-15"
                                        type="button"
                                        disabled={isloader}
                                        onClick={messageCount.totalcount !== 0 && emailSend}>
                                        <ButtonLoader IsLoader={IsLoader}>
                                            Send SMS
                                        </ButtonLoader>

                                    </button>
                                </>
                                : <></>}
                        </div>

                    </div>
                </div>
                <div className="previewPanel margin-50 slideAnimation_active">
                    <label className="_previewContent">Preview SMS
                        {getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.detail.PreviewUrl !== "" && <>-&nbsp;<a href={getAllEmailSendingDetails.Content.detail.PreviewUrl} rel="noreferrer" target="_blank" id="_browser_preview" className="inline-block">Open In Browser</a></>}</label>
                    <div className="iphonePriview prvewSMS">
                        {!getAllEmailSendingDetails.Content ? <div className="padding20">
                            {[1, 2, 3, 4].map((v, i) => <div key={i}><div className="skeleton-loader" style={{ height: '50px', marginBottom: '20px' }}></div><div className="skeleton-loader" style={{ height: '20px', marginBottom: '20px' }}></div></div>)}
                        </div> :
                            getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.typeId === 7 ? <><div className="smstxtphone">{getAllEmailSendingDetails.Message}</div></> : <iframe id="ab" title="preview" src={getAllEmailSendingDetails.Content && getAllEmailSendingDetails.Content.detail.PreviewUrl} className="noborder"></iframe>
                        }

                    </div>
                </div>
            </div>
        </>)

}

