import BrowserChart from "./BrowserChart"
import DesktopChart from "./DesktopChart"
import MobileChart from "./MobileChart"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import { DeviceLoader } from "../../../../ReuseTemplate";
const Device = () => {
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [deviceData, setDeviceData] = useState({})
    const [loader, setLoader] = useState(false);

    const loadApiCall = useCallback(async () => {
        setLoader(true);
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/SMS/${id}/KPI?tabId=12`)
                    .then(({ data }) => {
                        setLoader(false);
                        try {
                            if (data.length === 0) {
                                setDeviceData({})
                            } else {
                                setDeviceData(data['KPI'][0])
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    });
        } catch (err) {
            setLoader(false);
            throw err;
        }
    }, [APITOKEN, id]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {/* {JSON.stringify(Object.keys(deviceData).length)} */}
            {Object.keys(deviceData).length > 0 ?
                <><MobileChart Data={deviceData} />
                    <BrowserChart Data={deviceData} />
                    <DesktopChart Data={deviceData} />
                </>
                : <>{loader ? <DeviceLoader /> : <div className="padding20  bx-boder-common white"><div className="_nodata"><div id="nodatamessage"><strong>There is no data available yet.</strong><br /><br /></div></div></div>}</>}
        </>
    )
}
export default Device