import React from 'react'



import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BreadCrum from '../../../ReuseTemplate/BreadCrum';
import GridLoader from '../../../ReuseTemplate/GridLoader';
import BlankData from '../../../BlankData';
import Client from '../../../GlobalAPICall';
import Grid from './Grid';
const JavascriptList = () => {
    const { APITOKEN } = useSelector(state => state);
    const [smsDetails, setSMSDetails] = useState({
        data: [],
        isloader: false
    });
    const [showDeletePanel, setDeletePanel] = useState(true);
    const [guidId, setListGuidId] = useState("");

    const load = useCallback(() => {
        setSMSDetails({ data: [], isloader: true });
        (APITOKEN['token'] !== "") &&
            Client.get('/Lookup/CustomJavaScript', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setSMSDetails({ data, isloader: false });
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN]);


    // List Item Delete API Call //
    const deleteList = () => {
        Client.delete(`Lookup/CustomJavaScript?id=${guidId.id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                setDeletePanel(!showDeletePanel);
                load();
            } catch (err) {
                console.log(err);
            }

        }).catch(err => console.log(err));
    }

    const showDeletePanelFN = (f, item) => {
        setDeletePanel(!showDeletePanel);
        setListGuidId(item);
    }
    const navigate = useNavigate();

    const duplicateClick = item => {
        Client.post(`Campaign/duplicate?id=${item.id}`, {}, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    load()
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }
    useEffect(() => {
        load();
    }, [load])
    return (<>
        <div className="dashbd_sec margin-top50">&nbsp;</div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/configuration" ToText="Integrations" CurrentPage="Custom Javascript" />
        </div>
        <div className="dashbd_sec">
            {showDeletePanel ?
                <>
                    <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                        <div className="_h_header">
                            <h3>Custom Javascript</h3>
                            <span className="flex-right"><button className="grid_btn_1 grid_btn_active" onClick={() => navigate(`/customJavascript/create/97`)} type="button">Create</button></span>
                        </div>
                        {smsDetails.isloader ?
                            <GridLoader TDArray={[1, 2, 3, 4]} /> :
                            <table cellPadding="0" cellSpacing="0" className="smstable">
                                <thead>
                                    {smsDetails.data.length > 0 ?
                                        <tr>
                                            <td className="table_cell_header">Template Name</td>
                                            {/* <td className="table_cell_header">Template ID</td> */}
                                            {/* <td className="table_cell_header">Content Type</td> */}
                                            <td className="table_cell_header">Actions</td>
                                        </tr>
                                        : <BlankData title="You have not created any Custom Javascript yet." content="Click on the green button to create your first Custom Javascript." colSpan="4"/>
                                    }
                                </thead>
                                <tbody>
                                    {

                                        smsDetails.data.length > 0 && smsDetails.data.map((item, index) => {
                                            return <Grid item={item} key={index} showDeletePanelFN={showDeletePanelFN} DuplicateClick={duplicateClick} />
                                        })
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </> : <DeletePanel click={() => deleteList()} closeFn={() => setDeletePanel(!showDeletePanel)} />
            }
        </div>
    </>)
}
const DeletePanel = (props) => {
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            <div className="_h_header">
                <h3>Delete Template</h3>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <tbody>
                    <tr>
                        <td className="body_table_cell white">
                            <div className="_nodata margin-bottom">
                                <div id="nodatamessage">
                                    <strong>
                                        Do you want to delete the template ?.
                                    </strong>
                                    <br></br>
                                    <br></br>
                                    This will permanently delete this template and it can not be restored.
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={props.closeFn}>Cancel</button>
                                <button type="button" className="grid_btn_1 deleteBtn" onClick={props.click}>Delete</button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div></>)
}

export default JavascriptList;
