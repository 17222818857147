
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import axios from "axios";

const ResponseNps = ({ ...props }) => {
    const { PostObject ,SetNPSFilterDisplay} = props;
    const { APITOKEN } = useSelector(state => state);
    // const [trendData, settrendData] = useState([]);

    const [DesktopData, setDesktop] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        accessibility: {
            enabled: false
        },
        xAxis: {
            allowDecimals: false,
        },
        yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
                text: ''
            }
        },
        tooltip: {
            formatter: function () {
                return this.series.name + ': ' + this.y + '<br/>'
            }
        },

        plotOptions: {
            column: {
                stacking: 'normal'
            },
            series: {
                pointWidth: 16
            }
        }
    });

    const filterObject = (res) => {
        const new_array = [];
        const parse = !!res['series']?JSON.parse(res['series']).map((item) => ({ ...item, color: item.ColorCode, data: !!item.Data ? JSON.parse(item.Data) : [] })):[];
        if (!!res['xAxis']) {
            const date = res['xAxis'].split(',');
            for (var y of date) {
                new_array.push(new Date(y).getDate());
            };
        }

        setDesktop(prv => ({
            ...prv,
            series: parse,
            xAxis: {
                categories: new_array
            }
        }))
        return res;
    }

    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();

        try {
            APITOKEN['token'] &&
                Client.post(`Insights/NPSdashboard/DateWiseResponsewithCategory?reqType=Chart`, PostObject, {
                    cancelToken: cancelToken.token,
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(({data}) => {
                        try {
                            // if (response.hasOwnProperty('series')) {
                                filterObject(data);
                            // }
                            SetNPSFilterDisplay(false);
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        if (axios.isCancel(err)) {
                            console.error(err);
                        }
                    });
                    return () => {
                        cancelToken.cancel();
                    }
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, PostObject,SetNPSFilterDisplay]);
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className="padding20 nps_chart">
            <div className="bx-boder-common">
                <div className="_h_header drop-zone">
                    <h3>Responses</h3>
                </div>
                <HighchartsReact highcharts={Highcharts} options={DesktopData} />
            </div>
        </div>
    )

}
export default ResponseNps;
