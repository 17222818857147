import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting';
import { DownLoadData } from '../DownloadChartJSON';
import { HightChartBestDay, HightChartColorProps, maxHits } from '../constant/HightChartBestProps';
HC_exporting(Highcharts)
const BestDay = () => {
    const { APITOKEN } = useSelector(state => state);
    const [bestDayData, setBestDay] = useState("");
    Highcharts.setOptions(HightChartColorProps);
    const chart = useRef();
    const [downloadData, setDownloadData] = useState({
        data: DownLoadData,
        show: false
    });
    const [optionData, setOptionValue] = useState(HightChartBestDay);
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Insights/distribution/SMS/BestDay/Connect', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                dataFilter(data || []);
            }).catch(err => {
                throw err;
            });
        function handleWindowClick() {
            setDownloadData(prv => ({
                ...prv,
                show: false
            }))
        }
        window.addEventListener('click', handleWindowClick, false);
        return (() => {
            window.removeEventListener('click', handleWindowClick, false);
        })
    }, [APITOKEN]);

    const dataFilter = (data) => {
        const bestDayAvg = [...data.map(item => ({ name: item.Hitsday, y: item.Percentage }))];
        const { Hitsday } = maxHits(data)[0];
        setBestDay(Hitsday);
        setOptionValue({ series: [{ data: bestDayAvg, name: "BestDay" }] });
    }
    const exportChart = (e) => {
        e.stopPropagation();
        setDownloadData(prv => ({
            ...prv,
            show: !prv.show
        }))
    }
    const downloadImg = ev => {
        chart.current.chart.exportChart({
            type: ev.value
        })
    }


    return (
        <>
            <button type="button"
                className="graphProperties"
                style={{ "marginRight": "30px" }}
                onClick={exportChart}
                data-id="weeklychart"><i className="fal fa-ellipsis-h"></i>
            </button>
            {downloadData.show ?
                <div className="graphproperties-bx">
                    {downloadData['data'].map((v, i) => <div key={i} className="link grpahbx" onClick={() => downloadImg(v)}>{v.name}</div>)}
                </div>
                : <></>}
            <h3 className="_chart_title padding-top20 padding-bottom20">Best Day to Send SMS</h3>
            <p>Based on opens of your total SMS sending with SMS content.</p>
            <h2 className="chart_t link -bestdy">{bestDayData}</h2>
            <HighchartsReact highcharts={Highcharts} options={optionData} ref={chart} />
        </>
    )
}
export default BestDay;