import { FolderTab } from "./FolderTab";

export const FolderContent = () => {
    return (
        <><div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <FolderTab />
            </div>
        </>
    )

}

