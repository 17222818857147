import { useState } from "react";
import { useSelector } from "react-redux";

export const FileImport = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [disabledImport, setdisabledImport] = useState(false);
    const [progessBar, setLetPogressBarPosition] = useState('');
    const { SetFileImportObject ,APIName , Name } = props;
    const [errorResonse, setError] = useState("");
    const excelFileUpload = (e) => {
        const dropZoneElement = document.querySelector(".draganddropsection");
        updateExcel(dropZoneElement, e.target.files[0]);
    }

    const disabledImportFN = () => {
        setdisabledImport(!disabledImport);
        excelImportDragdrop();
    }

    const excelImportDragdrop = () => {
        const dropZoneElement = document.querySelector(".draganddropsection");
        dropZoneElement.addEventListener("dragover", (e) => {
            e.preventDefault();
            dropZoneElement.classList.add("drop-zone--over");
        },false);

        ["dragleave", "dragend"].forEach((type) => {
            dropZoneElement.addEventListener(type, (e) => {
                dropZoneElement.classList.remove("drop-zone--over");
            },false);
        });
        dropZoneElement.addEventListener("drop", (e) => {
            e.preventDefault();
            if (e.dataTransfer.files.length) {
                updateExcel(dropZoneElement, e.dataTransfer.files[0]);
                dropZoneElement.removeEventListener("drop", (e) => e.preventDefault())
            };
            dropZoneElement.classList.remove("drop-zone--over");
        },false);
    }
    const progressHandler = (event) => {
        var percent = (event.loaded / event.total) * 100;
        setLetPogressBarPosition(Math.round(percent) + '%');
    };
    const errorHandler = (event) => {
        console.log(event);
    };
    const completeHandler = (event) => {
        try {
            let { sample_data, fileSource, StatusCode, message, total_records } = JSON.parse(event.target.response);
            if (StatusCode === 422) {
                SetFileImportObject(prvs => ({ ...prvs, ...{ StatusCode } }));
                setError(message);
                excelImportDragdrop();
                setLetPogressBarPosition(0 + '%');
                
            } else {
                SetFileImportObject(prvs => ({ ...prvs, ...{ sample_data, fileSource, message, total_records } }))
            }
        } catch (err) {
            console.log(err);
        }
    }
    const fileImportAPI = (file) => {
        let formData = new FormData();
        var ajax = new XMLHttpRequest();
        formData.append("formfile", file, file.name);
        ajax.upload.addEventListener("progress", progressHandler, false);
        ajax.addEventListener("load", completeHandler, false);
        ajax.addEventListener("error", errorHandler, false);
        const url = process.env;
        ajax.open("POST", `${url.REACT_APP_API_URL_BUlK_IMPORT}/${APIName}/upload`);
        ajax.setRequestHeader('Authorization', 'Bearer ' + APITOKEN.token);
        ajax.send(formData);
    }
    const updateExcel = (element, file) => {
        fileImportAPI(file);
    };


    return (
        <><div className="b_top">
            <div className="width100">
                <div className="_h_header">
                    <h3>Upload File</h3>
                    <span className="flex-right">
                        <strong>To be able to quick import contacts you have to agree that you have {Name} Opt-in consent. <strong className="link inline-block agreeLink pointer"  onClick={() => disabledImportFN()} > I Agree &nbsp;</strong></strong>
                        <input type="checkbox" className="vertical-middle grid_btn_1 margin-right15" checked={disabledImport} onChange={() => disabledImportFN()} /></span>
                </div>
                <div className="import__information">
                    Click on the icon or Drag and Drop the CSV file from your computer to upload it.
                    <br />
                    If you are using Excel select "Save as" and use the file format UTF-8 (Comma-delimited) (.csv)
                    <br />
                    <br />
                    <p className="validation">{ errorResonse}</p>
                </div>
                <div className={`sms_Drag_conntainer ${!disabledImport ? 'notFileupload' : ''}`}>
                    <div className="draganddropsection">
                        <div style={{ width: '100%', background: '#fff', height: '4px', display: 'inline-block', position: 'relative', verticalAlign: 'top' }}><span style={{ position: 'absolute', height: '4px', background: 'rgb(2, 147, 255)', left: '0px', display: 'inline-block', width: progessBar }} ></span></div>
                        <input type="file" className="ImportfileUpload" onChange={excelFileUpload} />
                        <div className="dragtext"><i className="fal fa-cloud-upload"></i></div>
                    </div>
                </div>
            </div>
        </div></>
    )

}

