import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import getParameterByName from '../../../../ReuseTemplate/GetRoute';
import ReplyTo from "../../ReplyTo";
import Select from 'react-select';
const CreateEmailOptTrigger = () => {
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const [sendeEmail, setSenderEmail] = useState({ data: {} });
    const [replytoValue, setReplyTo] = useState({ data: [] });
    const [emailCampaignValue, setemailCampaign] = useState({ data: [] });
    const [optinConfirmLink, setOptinConfirmLink] = useState('');
    let [replyToShow, setReplyToggle] = useState(false);
    const [objectParameters, setObjectParameters] = useState({
        "senderName": "",
        "senderEmail": "",
        "subject": "",
        "preHeader": "",
        "typeDetailId": "",
        "replyTo": [],
    });
    const [buttonName, setButtonName] = useState('Save New Template');

    const senderListLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('Email/senderemails', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        senderEmailFilter(data);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);
    const catFilter = res => {
        const array = [];
        array.push(...res.map(x => ({ ...x, value: x.email.email, label: x.email.email })));
        return array;
    }
    const replyToLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('Email/replyto', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setReplyTo({ data: catFilter(data), selected: "" });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);
    const defaultOptinUrl = useCallback(() => {
        APITOKEN.token &&
            Client.get('Trigger/Templates/DefaultOptinUrl', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setOptinConfirmLink(data.OptinUrl);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);
    const emailCampaign = useCallback(() => {
        APITOKEN.token &&
            Client.get('Lookup/contentDetails/848', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        setemailCampaign({ data, url: "" });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);

    function senderEmailFilter(response) {
        if (response.hasConfiguredWhiteLableDomain) {
            response.whiteLableDomains.unshift({
                emailAddress: { email: 'Create Sender Email' },
                configured: response.hasConfiguredWhiteLableDomain
            });
        }
        setSenderEmail({ data: response })
    }
    const editReplyTo = data => {
        const newArray = [];
        for (const x of data) {
            newArray.push({ value: x.email, label: x.email })
        }
        return newArray;
    }
    const editEmailTriggeremail = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Trigger/Templates/${getParameterByName('cam', window.location.href)}/EmailOptin`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({data}) => {
                    try {
                        const {Sendername,senderEmail,Subject,preHeader,content,replyTo_List} = data[0];
                        data.length > 0 &&
                            setObjectParameters({
                                "senderName": Sendername,
                                "senderEmail":senderEmail,
                                "subject": Subject,
                                "preHeader": preHeader,
                                "typeDetailId": content.typeDetailId,
                                "replyTo": editReplyTo(replyTo_List),
                                "url": content.typeDetailUrl

                            });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN])

    
    const selectedReplyTo = () => {
        const getArray = [];
        for (const x of objectParameters.replyTo) {
            getArray.push({ email: x.value, displayName: "" })
        }
        return getArray;

    }
    const saveTemplateFn = () => {
        const id = getParameterByName('cam', window.location.href);
        const edit = getParameterByName('edit', window.location.href);
        const replyTolist = selectedReplyTo();
        const obj = {
            "id": id,
            "sendingType": "2",
            "senderName": objectParameters.senderName,
            "senderEmail": objectParameters.senderEmail,
            "subject": objectParameters.subject,
            "preHeader": objectParameters.preHeader,
            "content": {
                "typeId": 5,
                "typeDetailId": objectParameters.typeDetailId
            },
            // "replyTo": objectParameters.replyTo,
            replyTo_List: replyTolist,
            replyTo:"noreply@",
            "optinConfirmLink": optinConfirmLink
        };
        if (edit !== null) {
            putAPI(obj);
        } else {
            postAPI(obj);
        }

    }
    const putAPI = obj => {
        Client.put(`Trigger/Templates/${obj.id}/EmailOptin`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({data}) => {
                try {
                    if (data.Success) {
                        navigate(`/create/templates/emailDoubleOptin?type=${getParameterByName('type', window.location.href)}`)
                    } else {
                        console.error(data);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => {
                console.error(err);
            });
    }
    const postAPI = obj => {
        Client.post(`Trigger/Templates/EmailOptin`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({data}) => {
                try {
                    if (data.Success) {
                        navigate(`/create/templates/emailDoubleOptin?type=${getParameterByName('type', window.location.href)}`)
                    } else {
                        console.error(data);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => {
                console.error(err);
            });
    }
    const replyToMemo = useMemo(() => {
        const filterArray = replytoValue.data.filter(x => x.isVerified || x.id === '00000000-0000-0000-0000-000000000000');
        filterArray.map(x => ({ ...x, value: x.email.email, label: x.email.email }));
        return filterArray;
    }, [replytoValue.data]);
    
    // const replytoFN = (v) => {
    //     if (v === 'CreateNew') {
    //         setReplyToggle(true);
    //     } else {
    //         setReplyToggle(false);
    //     }
    // }
    const closeMethod = () => {
        setReplyToggle(replyToShow = !replyToShow)
    }
    const onChange = newValue => {
        if (newValue.length !== 0) {
            const filter = newValue.filter(x => x.id === "00000000-0000-0000-0000-000000000000");
            if (filter.length !== 0) {
                setReplyToggle(true);
            } else {
                setReplyToggle(false);
                if (newValue.length > 0) {
                    setObjectParameters(prv => ({
                        ...prv,
                        replyTo: [...newValue]
                    }))

                } else {
                    setObjectParameters(prv => ({
                        ...prv,
                        replyTo: []
                    }))
                }
            }
        } else {
            setObjectParameters(prv => ({
                ...prv,
                replyTo: []
            }))
        }


    }
    useEffect(() => {
        senderListLoad();
        replyToLoad();
        emailCampaign();
        defaultOptinUrl();
        if (getParameterByName('edit', window.location.href) !== null) {
            editEmailTriggeremail();
            setButtonName('Update')

        }
    }, [senderListLoad, replyToLoad, emailCampaign, defaultOptinUrl, editEmailTriggeremail])
    
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Email Double Opt-in</h1>
                        <p className="sms_content">
                            You are ready to send Email. Review the summary before clicking the Send button. You can edit by clicking on the blue text. Once the Email is sent - you can not make changes.
                        </p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec">
                <form>
                    <div className="sms_Sec_1 bx-boder-common inline-block">
                        <div className="_h_header">
                            <h3>Create New Template</h3>
                        </div>
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Sender Name</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.senderName}
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ senderName: e.target.value } })} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Sender Email</label>
                                <select className="inputformmodel"
                                    value={objectParameters.senderEmail}
                                    name="senderEmail"
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ senderEmail: e.target.value } })}>
                                    <option value="">Select</option>
                                    {sendeEmail.data.whiteLableDomains && sendeEmail.data.whiteLableDomains.map((v, i) =>
                                        <option key={i} value={v.emailAddress.email}>{v.emailAddress.email}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Reply To</label>
                                {/* <select className="inputformmodel"
                                    value={objectParameters.replyTo}
                                    onChange={e => { replytoFN(e.target.value); setObjectParameters({ ...objectParameters, ...{ replyTo: e.target.value } }) }}
                                >
                                    <option value="">Select</option>
                                    {replytoValue.data.map((v, i) => <option key={i}>{v.email.email}</option>)}
                                </select> */}
                                <Select

                                    value={objectParameters.replyTo}
                                    isMulti
                                    name="language"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={onChange}
                                    options={replyToMemo}
                                />

                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Subject</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.subject}
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ subject: e.target.value } })}
                                    placeholder="Create a subject text for your email." />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Pre Header</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.preHeader}
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ preHeader: e.target.value } })} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Email Campaign</label>
                                <select
                                    className="inputformmodel"
                                    value={objectParameters.typeDetailId}
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ typeDetailId: e.target.value, url: e.target.childNodes[e.target.selectedIndex].getAttribute('data-url') } })}
                                >
                                    <option value="">Select</option>
                                    {emailCampaignValue.data.map((v, i) => <option key={i} data-url={v.PreviewUrl} value={v.Id}>{v.Name}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx nomargin text-right">
                                <button
                                    className="grid_btn_1 nobg noborder"
                                    type="button"
                                    onClick={() => navigate(`/create/templates/email?type=${getParameterByName('type', window.location.href)}`)}>
                                    Cancel
                                </button>
                                <button className="grid_btn_1 grid_btn_active"
                                    disabled={objectParameters.senderName !== "" &&
                                        objectParameters.senderEmail !== "" &&
                                        objectParameters.replyTo.length !== 0 &&
                                        objectParameters.typeDetailId !== "" &&
                                        objectParameters.subject !== "" ? false : true}
                                    type="button" onClick={saveTemplateFn}>{buttonName}</button>
                            </div>
                        </div>
                    </div>
                    <div className="previewPanel">
                        <div className="iphonePriview">
                            {objectParameters.url !== "" || objectParameters.url !== undefined ? <div className="padding20"><iframe title="ss" className="noborder" width="100%" src={objectParameters.url} height="500"></iframe></div> :
                                <div className="previewDefaultMsg">
                                    A preview of your selected Email Campaign will be show here.
                                    <br></br>
                                    <br></br>
                                    Select Email Campaign to see the preview.
                                </div>
                            }
                        </div>
                    </div>
                </form>
                {replyToShow ? <ReplyTo ReplyToData={replytoValue} setValue={setReplyTo} Close={closeMethod} ReplytoAPI={replyToLoad} /> : <></>}


            </div>
        </>
    )
}
export default CreateEmailOptTrigger;