import { useState } from "react";
import { DefaultTemplate } from "./DefaultTemplate";
import { SaveTemplate } from "./SaveTemplate";
import { Link, useLocation } from "react-router-dom";


const Tabing = ({ ...props }) => {
    const defaultId = '4A2B4805-40A2-EA11-84AA-B22A0B32D9B6';
    const { Data ,APITOKEN } = props;
    const [tabId, setTabid] = useState(defaultId);
    const [tabIndex, setTabindex] = useState(0);
    const location = useLocation();

    const clickmethod = (item,index) => {
        setTabid(item.id);
        setTabindex(index)
    }
    return (
        <>
            <div className="lcCattemplateBx ">
                <ul className="lcCattemplateList">
                    {Data && Data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item,index)}><Link to={`${location.pathname}${location.search}`} className={item.id === tabId ? 'active ' : ' '}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div className="lcFlePan">
                    <div style={{display:'flex',flexGrow:1}}>
                        {tabId === defaultId ?
                            <SaveTemplate TemplateId={tabId} Index={tabIndex} APITOKEN={APITOKEN} />:<DefaultTemplate TemplateId={tabId} Index={tabIndex} APITOKEN={APITOKEN} /> }
                    </div>
                </div>
            }
        </>
    )

}

export default Tabing
