import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Tabing from "./Tabing";
import Client from "../../GlobalAPICall";
import './index.css'
const Configruation = () => {
    const { APITOKEN } = useSelector(state => state);
    const wrapperRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    const [tabData, setTab] = useState([]);
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Lookup/Config_tabs', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                const filtData = data.map(item => ({ ...item, show: (item.Id === 75 ? APITOKEN.config.isPhoneNumberVerified ? true : false : true) }));
                setTab(filtData);
            });
        document.addEventListener("click", handleClickOutside, false);
        return () => {
            document.removeEventListener("click", handleClickOutside, false);
        };
    }, [APITOKEN]);
    const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setIsVisible(true);
        }
    };

    return (
        <>
            <div className="dashbd_sec margin-top50" ref={wrapperRef}>&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <Tabing data={tabData} isVisible={isVisible} APITOKEN={APITOKEN}  />
            </div>
        </>
    )
}

export default Configruation