import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useParams } from 'react-router';
import axios from 'axios';
import moment from 'moment';
import { forEach } from 'lodash';

const Performance = () => {
    const {APITOKEN} = useSelector(state => state);
    const { id } = useParams();

    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'area'
        },
        title: {
            text: 'Landing Page performance'
        },
        subtitle: {
            text: '',
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'datetime',

            labels: {
                formatter: function () {
                    const date = this.value;
                    var splitedate = date.split('-');
                    return splitedate[2]
                }
            }

        },
        yAxis: {
            title: {
                text: ''
            }

        },
        tooltip: {
            backgroundColor: '#fff',
            borderColor: '#eee',
            borderRadius: 3,
            borderWidth: 1,
            shadow: false,
            useHTML: true,
            xDateFormat: '%Y-%m-%d',
            formatter: function () {
                var s = [];
                const self = this;
                s.push('<table width="100%" cellspacing="0" cellpadding="0"><tr><td style="font-family:arial;background:#fff;color:#000;font-size:15px;padding:5px 0;font-weight:bold;text-align:center">' + moment(this.x).format('YYYY-MM-DD') + '</td></tr></table>');
                forEach(self.points, function (point, i) {
                    s.push('<table width="100%"><tr><td style="font-size:12px;padding-bottom:5px;padding-top:5px;font-family:roboto;color:#1b283e;">' + point.series.name + ' </td><td style="padding-left:10px;text-align:right;"><b style="text-align:center; line-height:20px;min-width:20px;height:20px;color:#000;display:inline-block;font-family:roboto;padding:0 10px;font-size:12px";"> &nbsp;' + point.y + '</td></tr></table>');
                })
                return s;
            },
            shared: true,
            followTouchMove: true,
            followPointer: true,
            style: {
                color: '#666',
                fontWeight: 'normal',
            },

        },
        plotOptions: {
            series: {
                marker: {
                    enabled: true,

                },
                cursor: 'pointer',
            }
        },
        credits: {
            enabled: false
        },
        series: [
            {

                name: "Visitors",
            }
        ]
    });
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();

        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/landingpage/${id}/performance`)
                .then(({ data }) => {
                    dataFilter(data || []);
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        cancelToken.cancel();
                    }
                });
    }, [APITOKEN, id]);

    const dataFilter = (data) => {
        const ar = [];
        const reponse = [];
        data.forEach(function (v) {
            const $d = JSON.parse(v['PerformanceKPI']);
            v['dataChart'] = [];
            for (let x of $d) {
                ar.push(x['CaptuerdAt'])
                v['dataChart'].push(x['Count']);
            }
            reponse.push({
                name: v['Name'],
                id: v['Id'],
                data: v['dataChart'],
                lineWidth: 2,
                linkedTo: 'q',
                color: '#a2cef3',
                fillOpacity: 0.3,
                zIndex: 1,

                marker: {
                    enabled: false
                }
            });
        });
        setOptionValue(prv => ({
            ...prv,
            series: reponse,
            xAxis: { categories: ar }
        }));
    }


    return (
        <>
            <h3 className="_chart_title padding-top20 padding20">Landing Page Performance</h3><div className="b_top padding20"><HighchartsReact highcharts={Highcharts} options={optionData} /></div>
        </>
    )
}
export default Performance;