

import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import Queued from "./Queued";
import Submitted from "./Submitted";
import DeliveredLive from "./Delivered";
import BounceLive from "./Bounce";


const LiveSending = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [queueObject, setQueueObject] = useState({})
   
    const queuedAPIFN = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/distribution/Email/live/sending/Aggregrated?queueId=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setQueueObject(prv => ({ ...prv, ...res['data'] }));
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.log(err);
            });

    }, [APITOKEN, id]);

    useEffect(() => {
        var date = new Date();
        setTimeout(function() {
            let refreshIntervalId = setInterval(function(){
                queuedAPIFN();
                clearInterval(refreshIntervalId);
    
            }, 60000);
            queuedAPIFN();
        }, (60 - date.getSeconds()) * 1000);
        queuedAPIFN();
    }, [queuedAPIFN])

    return (
        <div className="bx-boder-common">
            <div className="_insight_header white">
                <h3>Live Sending</h3>
                <p className="content">&nbsp;</p>
            </div>
            <div className="livesend white summary_o_v">
                <p className="-sub">Your Email sending is on its way!</p>
                {queueObject.DistributionStatus !== undefined && queueObject.DistributionStatus['SyncStatus'] <= 100 && queueObject.DistributionStatus['SyncStatus'] > 0 ?
                    <figure className="highcharts-figure">
                        <div className="-l"><div className="chart-container"><Queued Data={queueObject}/>
                        </div><div id="-sms-live" className="g-txt">{queueObject.Queued} Email
                            </div></div>
                        <div className="-l"><div id="container-rpm" className="chart-container"><Submitted Data={queueObject}/></div><div id="-sub-live" className="g-txt">{queueObject.Submitted} Email
                        </div></div>
                        <div className="-l"><div id="container-delivered" className="chart-container"><DeliveredLive Data={queueObject}/></div><div id="-delive-live" className="g-txt">{queueObject.Delivered} Email
                        </div></div>
                        <div className="-l"><div id="container-failed" className="chart-container"><BounceLive Data={queueObject}/></div><div id="-fail-live" className="g-txt">{queueObject.Bounced} Email
                        </div></div>

                    </figure>
                    : <></>}
            </div>
        </div>
    )

}

export default LiveSending
