import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import { useSelector } from 'react-redux';
import GridLoader from '../../../../ReuseTemplate/GridLoader';

const RecipientsTable = () => {
    const { id } = useParams();
    const {APITOKEN} = useSelector(state => state);
    const [skip, setSkip] = useState(0);
    const [totalRecode, setTotalRecord] = useState(0);
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const [recipientsData, setRecipientsData] = useState({
        data: [],
        isDisplay: true
    });
    useEffect(() => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/SMS/${id}/Recipients?skip=0&limit=10`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token'],
                        'Content-Type': 'application/json'
                    }
                }).then(async (res) => {
                    try {
                        if (((skip + 1) * 10) > res.data[0].TotalRecipients) {
                            setpainationButton(prv => ({
                                ...prv,
                                next: true
                            }));
                        } else {
                            if (res['data'].length < 10) {
                                if (skip === 0) {
                                    setpainationButton(prv => ({
                                        ...prv,
                                        previous: true,
                                        next: true
                                    }));
                                };
                            }
                        };
                        await setRecipientsData(prv => ({
                            ...prv,
                            data: res['data'],
                            isDisplay: false
                        }));
                        setTotalRecord(res.data[0].TotalRecipients);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
        }
    }, [APITOKEN, id, skip]);
    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }
    return (
        <>
            {recipientsData.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
                <table className="smstable table-layout-default" cellPadding="0" width="0"><tbody><tr><th className="table_cell_header">First Name</th><th className="table_cell_header">Last Name</th><th className="table_cell_header">Email</th><th className="table_cell_header">Click</th><th className="table_cell_header">Open</th><th className="table_cell_header">Mobile Number</th><th className="table_cell_header">Zip Code</th><th className="table_cell_header">Status</th></tr></tbody><tbody>{<Grid Data={recipientsData.data} />}</tbody></table>
            }
            {recipientsData.data.length === 0 ? <></> :
                <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                    <span className="pagination_count vertical-middle">
                        <span>{skip + 1}</span>-
                        <span>{(skip + 1) * 10}</span> of&nbsp;
                        <span>{totalRecode}</span>
                    </span>
                    <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                    <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                </div>
            }
        </>

    )

}
export default RecipientsTable;

const Grid = ({ Data }) => {
    return (
        <>
            {Data && Data.map((v, i) => <tr key={i}><td className="body_table_cell">{v['FirstName']}</td><td className="body_table_cell">{v['LastName']}</td><td className="body_table_cell">{v['Email']}</td><td className="body_table_cell">{v['Clicks']}</td><td className="body_table_cell">{v['Opens']}</td><td className="body_table_cell">{v['MobileNumber']}</td><td className="body_table_cell">{v['ZipCode']}</td><td className="body_table_cell">{v['Status']}</td></tr>)}
        </>
    )

}