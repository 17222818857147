import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Client from "../../GlobalAPICall";
import { ALLTab } from "./ALLTab";
import { LandingPage } from "./LandingPage";
import { TemplateFolder } from "./TemplateFolder";

export const FolderTab = () => {
    const defaultId = 0;
    const [tabObject, setTabObject] = useState([]);
    const [tabId, setTabid] = useState({ id: defaultId });
    const [isLoading, setLoading] = useState(true);
    const [selectedArray, setTabSelectedArray] = useState([]);
    const { id } = useParams();
    const [paginationButtons, setpainationButtons] = useState({
        next: false,
        previous: true
    });
    const [totalRecode, setTotalRecord] = useState(0);
    const [skip, setSkip] = useState(1);

    const clickmethod = (item) => {
        setTabSelectedArray([]);
        setLoading(true);
        setTabid(item);
    }
    const { APITOKEN } = useSelector(state => state);

    const load = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Campaign/${id}/contenttypes`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {
                    setTabObject(res.data)
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })

    }, [APITOKEN, id]);

    const tabLoad = useCallback((itemId) => {
        APITOKEN['token'] &&
            Client.get(`Campaign/${itemId.id}/${id}/details?pageno=${skip}&pageSize=10`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {

                    setTabSelectedArray(res.data);
                    setLoading(false);
                    if (((skip) * 10) > itemId.totalCount) {
                        setpainationButtons(prv => ({ ...prv, next: true }));
                    } else {
                        if (res['data'].length < 10) {
                            if (skip === 0) {
                                setpainationButtons(prv => ({ ...prv, previous: true, next: true }));
                            };
                        }
                    };
                    setTotalRecord(itemId.totalCount);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })

    }, [APITOKEN, id, skip]);
    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 1) {
                setpainationButtons(prv => ({ ...prv, previous: true, next: false }))
            } else {
                setpainationButtons(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButtons(prv => ({ ...prv, previous: false }))
        }
    }
    const archieveFN = (id) => {
        Client.delete(`Campaign/${id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                if (res.data.Success) {
                    load();
                    tabLoad(tabId.id);

                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.error(err);
        })
    }

    useEffect(() => {
        tabLoad(tabId);
        load();
    }, [load, tabLoad, tabId])


    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {tabObject.length > 0 && tabObject.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId.id ? 'active' : ' '} id={item.id}>{item.name} ({item.totalCount}) </Link></li>
                    })}
                </ul>
            </div>
            {

                <div className="tab_body">
                    {
                        tabId.id === defaultId ?
                            <ALLTab Data={selectedArray} TabInfo={tabId} ArchieveFN={archieveFN} isLoading={isLoading} /> :
                            tabId.id === 6 ? <TemplateFolder TabId={tabId.id} TabInfo={tabId} Data={selectedArray} ArchieveFN={archieveFN} isLoading={isLoading} /> : <>
                                <LandingPage Data={selectedArray} TabId={tabId.id} TabInfo={tabId} ArchieveFN={archieveFN} isLoading={isLoading} />
                                <div className="formmodel-bx text-right nomargin ">
                                    <span className="pagination_count vertical-middle">
                                        <span>{skip}</span>-
                                        <span>{(skip) * 10}</span> of&nbsp;
                                        <span>{totalRecode}</span>
                                    </span>
                                    <button type="button" className="bigtext" disabled={paginationButtons.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                                    <button type="button" className="bigtext" disabled={paginationButtons.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                                </div>
                            </>
                    }
                </div>
            }
        </>
    )

}

