
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';


const BrowserChart = (props) => {
    const { Data } = props;
    console.log(Data);
    const [BrowserData, setBrowser] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        },

        series: [
            {
                name: "Mobile Device",
                colorByPoint: true,
                pointWidth: 62,
                data: []
            }
        ]
    });
    const loadApiCall = useCallback(async () => {
        const newArray = []
        await Data.Browser && Data.Browser.forEach(async (v, i) => {
            v['KPI'].forEach(async ($v, $i) => {
                if ($v['Id'] === 22) {
                    await newArray.push({
                        name: v['Browser'],
                        y: $v['Count'],
                        drilldown: v['Browser']
                    });
                }
            });
            setBrowser({ series: [{ data: newArray }] })
        });
    }, [Data])
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {Object.keys(Data).length > 0 && 
                
                <div className="bx-boder-common margin-top50">
                    <div className="_insight_header white">
                        <h3>
                            Browser
                        </h3>
                    </div>
                    {Data.Browser.length === 0 ?<div className="_nodata padding20 white">
                    <div id="nodatamessage">
                        <strong>Browser Data no found</strong><br></br><br></br>
                    </div>
                </div> : 
                    <>
                    <div className="padding20 white">
                        {BrowserData && <HighchartsReact highcharts={Highcharts} options={BrowserData} />}
                    </div>
                    <div className="padding20 white ">
                        <div className="_overviewPin _overviewPin_Device white summary_o_v padding20">
                            {
                                props['Data'].Browser && props['Data'].Browser.map((v, i) => {
                                    return <div className="bx-boder-common bx detailsbx white" key={i}><div className="drop-zone"><h3 className="title">{v['Browser']}</h3><p className="content paddingbottom20">&nbsp;</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                        <tbody>
                                            {
                                                v.KPI && v.KPI.map(($v, i) =>

                                                    <tr key={i}>

                                                        <td className="nopadding">
                                                            <table cellPadding="0" cellSpacing="0" width="100%" >
                                                                <tbody>
                                                                    <tr>
                                                                        <td>{$v.Name} {$v['CountLabel']}</td>
                                                                        <td className="-tvl"><strong className="pointer completeDetails">{$v['Count']}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{$v.Name} {$v['RateLabel']}</td>
                                                                        <td className="-tvl link"><strong style={{ color: $v['RateColor'] }}>{$v['Rate']}%</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>


                                                )

                                            }
                                        </tbody>
                                    </table>
                                    </div>
                                    </div>
                                })}

                        </div>
                        </div>
                        </>
                    }
                </div>
            }
        </>
    )
}
export default BrowserChart