
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import { DEVICE_OPTIONS } from '../../../constant/HightChartProps';

const MobileChart = (props) => {
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d62', '#fecc44', '#673ab7', '#60c9f7', "#2c53f7", "#ffa701"], borderWidth: 0 });
    const [mobileData, setMobile] = useState(DEVICE_OPTIONS);
    const loadApiCall = useCallback(async () => {
        const newArray = []
        await Object.keys(props['Data']).length > 0
            && props['Data'][0].hasOwnProperty('MobileDevice')
            && props['Data'][0].MobileDevice.forEach(async (v) => {
                v['KPI'].forEach(async ($v) => {
                    if ($v['Id'] === 153) {
                        await newArray.push({
                            name: v['DeviceOS'],
                            y: $v['Count'],
                            drilldown: v['DeviceOS']
                        });
                    }
                })
                setMobile({ series: [{ data: newArray ,name:"Mobile Device" }] })

            });
    }, [props])
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            <div className="bx-boder-common margin-bottom">
                <div className="_insight_header white">
                    <h3>Mobile Device</h3>
                </div>
                {!props['Data'][0].MobileDevice ? <div className="_nodata padding20 white">
                    <div id="nodatamessage">
                        <strong>Mobile Data no found</strong><br></br><br></br>
                    </div>
                </div> :
                    <>
                        <div className="padding20 white ">
                            {mobileData && <HighchartsReact highcharts={Highcharts} options={mobileData} />}
                        </div>
                        <div className="padding20 white">
                            <div className="_overviewPin _overviewPin_Device white summary_o_v">
                                {
                                    Object.keys(props['Data']).length > 0
                                    && !!props['Data'][0].MobileDevice
                                    && props['Data'][0].MobileDevice.map((v, index) => {
                                        return <div className="bx-boder-common bx detailsbx white" key={index}>
                                            <div className="drop-zone"><h3 className="title">{v['DeviceOS']}</h3>
                                                <p className="content paddingbottom20">&nbsp;</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                                    <tbody>
                                                        {
                                                            v.KPI && v.KPI.map(($v, i) =>

                                                                <tr key={i}>

                                                                    <td className="nopadding">
                                                                        <table cellPadding="0" cellSpacing="0" width="100%" >
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td>{$v['CountLabel']}</td>
                                                                                    <td className="-tvl"><strong className="pointer completeDetails">{$v['Count']}</strong></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>{$v['RateLabel']}</td>
                                                                                    <td className="-tvl link"><strong style={{ color: $v['RateColor'] }}>{$v['Rate']}%</strong></td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </td>

                                                                </tr>


                                                            )

                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    })}

                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
export default MobileChart