import React, { useEffect, useState } from 'react';
import { get as _get } from "lodash";
import BreadCrum from "../../../ReuseTemplate/BreadCrum";

import { getStoreDetails } from "../../../services/store";

import StoreDetails from './components/StoreDetails';
import ButtonFunctions from './components/ButtonFunctions';
import OpeningHours from './components/OpeningHours';
import StampCards from './components/StampCards';

const StoreEdit = () => {
  const pathArray = window.location.pathname.split("/");
  const storeID = _get(pathArray, "[3]", ""); // get StoreID from URL
  const [storeDetails, setStoreDetails] = useState({});
  const [activeLink, setActiveLink] = useState(1);
  const [refreshStoreData, setRefreshStoreData] = useState(false);

  // Fetch Store Details
  useEffect(() => {
    (async () => {
      try {

        const response = await getStoreDetails(storeID);

        //Set Store Details in State
        setStoreDetails(_get(response, "data", {}));
        setRefreshStoreData(false);
      } catch (err) {
        console.log('Error occured when fetching list', err);
      }
    })();
  }, [storeID, refreshStoreData]);

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum ToText="Stores" To="/store/97" ToSecondPageText="" CurrentPage={_get(storeDetails, "storeName", "")} />
        </div>

        <div className="mycontent">
          <ul className="tabing">
            <li><span className={`${(((activeLink || 1) === 1))} ? "active" : ""`} onClick={() => setActiveLink(1)}>Details</span></li>
            <li><span className={`${(((activeLink || 1) === 2))} ? "active" : ""`} onClick={() => setActiveLink(2)}>Buttons</span></li>
            <li><span className={`${(((activeLink || 1) === 3))} ? "active" : ""`} onClick={() => setActiveLink(3)}>Opening Hours</span></li>
            <li><span className={`${(((activeLink || 1) === 4))} ? "active" : ""`} onClick={() => setActiveLink(4)}>Stamp Cards</span></li>
          </ul>

          <div className="fright">
            <h3 className="font-16 fw-medium pe-2" style={{ marginTop: '10px' }}>{_get(storeDetails, "storeName", "")}</h3>
          </div>
        </div>

        <div className="clearfix"></div>

        {((activeLink || 1) === 1) && (
          <StoreDetails storeDetails={(storeDetails || {})} fetchStoreData={() => setRefreshStoreData(true)} />
        )}

        {((activeLink || 1) === 2) && (
          <ButtonFunctions storeDetails={(storeDetails || {})} fetchStoreData={() => setRefreshStoreData(true)} />
        )}

        {((activeLink || 1) === 3) && (
          <OpeningHours storeDetails={(storeDetails || {})} fetchStoreData={() => setRefreshStoreData(true)} />
        )}

        {((activeLink || 1) === 4) && (
          <StampCards storeDetails={(storeDetails || {})} fetchStoreData={() => setRefreshStoreData(true)} />
        )}
      </div>
    </>
  );
}

export default StoreEdit;