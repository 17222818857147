import React, { useCallback, useEffect, useReducer, useState } from 'react'
import Client from '../../GlobalAPICall';
import SourceCountChart from './SourceCountChart';
import BestDayChart from './BestDayChart';
import BestTimeChart from './BestTimeChart';
import BlankData from '../../BlankData';
function Reducer(state, action) {
    switch (action.type) {
      case 'APICOUNT':
        return { ...state, ...action.payload }
      case 'BESTDAY':
        return { ...state, ...action.payload }
      case 'BESTIME':
        return { ...state, ...action.payload }
      default:
        return state
    }
  }
  
export default function Speech({ Date, APITOKEN }) {
    const [state, dispatch] = useReducer(Reducer, {
        ApiCount: [],
        BestDay: [],
        Besttime: []
      });

    const load = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Insights/Mobile/DateWiseSourceCount?sourceType=Speech&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        dispatch({
                            type: 'APICOUNT',
                            payload: { ApiCount: data }
                          });
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err))
    }, [APITOKEN, Date]);
    const loadBestDay = useCallback(() => {
        APITOKEN.token &&
          Client.get(`Insights/Mobile/BestDaySourceCount?sourceType=Speech&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
            headers: {
              'Authorization': `Bearer ` + APITOKEN['token']
            }
          })
            .then(({ data }) => {
              try {
                dispatch({
                  type: 'BESTDAY',
                  payload: { BestDay: data }
                });
              } catch (err) {
                console.error(err);
              }
            }).catch(err => console.error(err))
      }, [APITOKEN, Date]);
      const loadTime = useCallback(() => {
        APITOKEN.token &&
          Client.get(`Insights/Mobile/BestTimeSourceCount?sourceType=Speech&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
            headers: {
              'Authorization': `Bearer ` + APITOKEN['token']
            }
          })
            .then(({ data }) => {
              try {
                dispatch({
                  type: 'BESTIME',
                  payload: { Besttime: data }
                });
              } catch (err) {
                console.error(err);
              }
            }).catch(err => console.error(err))
      }, [APITOKEN, Date]);
      useEffect(() => {
        load();
        loadBestDay();
        loadTime();
      }, [load, loadBestDay, loadTime])
    return (
        <div>
            {state.ApiCount.length > 0 && <SourceCountChart Data={state.ApiCount} Title="Total Opened Speech" />}
            <div className="overviewDaychart margin-top50 bx-boder-common white">
                <div className="chartharf  padding-right50" style={{ width: "51%" }}>
                    <BestDayChart Data={state.BestDay} Title="Speech" />
                </div>
                <div className="chartharf  padding-right50" style={{ width: "49%" }}>
                    <BestTimeChart Data={state.Besttime} Title="Speech" />
                </div>
        </div>
        <Grid APITOKEN={APITOKEN} Date={Date} />
        </div>
    )
}


const Grid = ({ APITOKEN, Date }) => {
  const [gridData, SetGridData] = useState([]);
  const Griload = useCallback(() => {
    APITOKEN.token &&
      Client.get(`Insights/Mobile/MobileSourceDetailData?sourceType=Speech&startDate=${Date.startDate}&endDate=${Date.endDate}`, {
        headers: {
          'Authorization': `Bearer ` + APITOKEN['token']
        }
      })
        .then(({ data }) => {
          try {
            SetGridData(data);
          } catch (err) {
            console.error(err);
          }
        }).catch(err => console.error(err))
  }, [APITOKEN, Date]);
  useEffect(() => {
    Griload();
  }, [Griload])
  return (
    <div className="-cam-sen-grid white drop-zone margin-top50 bx-boder-common">
      <div className="_h_header">
        <h3>Speech</h3>
      </div>
      <div className="">
        <table
          cellPadding="0"
          cellSpacing=""
          className="smstable table-layout-default white">
          {gridData.length === 0 ? ''
            : <thead>
              <tr>
                <th className="table_cell_header">Speech</th>
                <th className='table_cell_header'>Speaker</th>
                <th className="table_cell_header">Total</th>
                <th className="table_cell_header">Percentage</th>
                <th className="table_cell_header">Unique</th>
                <th className="table_cell_header">Unique Percentage</th>
              </tr>
            </thead>
          }

          <tbody>
            <>
              {gridData.length === 0 ?
                <BlankData title="No speech has been opened."
                  colSpan="8"
                  content="If speech is opened, you will find the insights here." /> :
                gridData.map((v, i) => <tr key={i}>
                  <td className="body_table_cell">{v['name']}</td>
                  <td className='body_table_cell'>{v.speakerName }</td>
                  <td className="body_table_cell">{v['total']}</td>
                  <td className="body_table_cell">{v['totalPer']}%</td>
                  <td className="body_table_cell">{v['unique']}</td>
                  <td className="body_table_cell">{v['uniquePer']}%</td>
                </tr>)}
            </>
          </tbody>
        </table>
      </div>
    </div>
  )
}