import { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from 'react-router-dom'
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import ReplyTo from "../../ReplyTo";
import { v4 as uuidv4 } from 'uuid';
import Select from 'react-select';
import getParameterByName from '../../../../ReuseTemplate/GetRoute';
import SenderNameEmail from "../../../../Send/Email/SenderNameEmail";
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';
const CreateEmailTrigger = () => {
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const [sendeEmail, setSenderEmail] = useState({ data: {} });
    const [replytoValue, setReplyTo] = useState({ data: [] });
    const [emailCampaignValue, setemailCampaign] = useState({ data: [] });
    const [senderNameContext, setSenderNameContext] = useState({
        display: false,
        buttonDisabled: true
    });
    const [IsLoader, setIsLoader] = useState(false);
    let [replyToShow, setReplyToggle] = useState(false);
    const [objectParameters, setObjectParameters] = useState({
        "senderName": "",
        "senderEmail": "",
        "subject": "",
        "preHeader": "",
        "typeDetailId": "",
        "replyTo": []
    });
    const [buttonName, setButtonName] = useState('Save New Template');
    const filterSendEmail = response => {
        const newObject = response.whiteLableDomains.map(x => ({ ...x, fieldDisabled: true, selectOptionDisabled: true }));
        const isdefault = response.whiteLableDomains.filter(x => x.isDefault);
        const whiteLabelDomainList = response.configuredWhiteLableDomains.map(x => x === isdefault[0].whiteLableDomain ? { name: x, isDefault: true } : { name: x, isDefault: false });

        response['configuredWhiteLableDomains'] = whiteLabelDomainList;
        return { ...response, whiteLableDomains: newObject };
    }
    const senderListLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('Email/senderemails', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        senderEmailFilter(filterSendEmail(data));
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {

                });
    }, [APITOKEN]);
    const catFilter = res => {
        const array = [];
        array.push(...res.map(x => ({ ...x, value: x.email.email, label: x.email.email })));
        return array;
    }
    const replyToLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('Email/replyto', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setReplyTo({ data: catFilter(data), selected: "" });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => console.error(err));
    }, [APITOKEN]);

    const emailCampaign = useCallback(() => {
        APITOKEN.token &&
            Client.get('Lookup/contentDetails/5', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setemailCampaign({ data: data, selected: "", url: "" });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => console.error(err));
    }, [APITOKEN]);

    function senderEmailFilter(response) {
        if (response.hasConfiguredWhiteLableDomain) {
            response.whiteLableDomains.unshift({
                emailAddress: { email: 'Create Sender Email' },
                configured: response.hasConfiguredWhiteLableDomain
            });
        };
        const res = [...response.whiteLableDomains, ...[{
            id: uuidv4(),
            email: null,
            isDefault: false,
            emailId: "",
            whiteLableDomain: "",
            fieldDisabled: false,
            selectOptionDisabled: true
        }
        ]];
        setSenderEmail({ data: { ...response, whiteLableDomains: res } })
    }
    const editReplyTo = data => {
        const newArray = [];
        for (const x of data) {
            newArray.push({ value: x.email, label: x.email })
        }
        return newArray;
    }
    const editEmailTriggeremail = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Trigger/Templates/${getParameterByName('cam', window.location.href)}/Email`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
            .then(({ data }) => {
                const {sendername,senderemail,subject,preHeader,content,replyTo_List} = data[0];
                    try {
                        data.length > 0 &&
                            setObjectParameters({
                                "senderName": sendername,
                                "senderEmail": senderemail,
                                "subject":subject,
                                "preHeader": preHeader,
                                "typeDetailId": content.typeDetailId,
                                "replyTo": editReplyTo(replyTo_List),
                                "url": content.previewUrl

                            });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch(err => console.error(err));
    }, [APITOKEN])



    const onChange = newValue => {

        if (newValue.length !== 0) {
            const filter = newValue.filter(x => x.id === "00000000-0000-0000-0000-000000000000");
            if (filter.length !== 0) {
                setReplyToggle(true);
            } else {
                setReplyToggle(false);
                if (newValue.length > 0) {
                    setObjectParameters(prv => ({
                        ...prv,
                        replyTo: [...newValue]
                    }))

                } else {
                    setObjectParameters(prv => ({
                        ...prv,
                        replyTo: []
                    }))
                }
            }
        } else {
            setObjectParameters(prv => ({
                ...prv,
                replyTo: []
            }))
        }


    }

    const selectedReplyTo = () => {
        const getArray = [];
        for (const x of objectParameters.replyTo) {
            getArray.push({ email: x.value, displayName: "" })
        }
        return getArray;

    }
    const saveTemplateFn = () => {
        const id = getParameterByName('cam', window.location.href);
        const edit = getParameterByName('edit', window.location.href);
        const replyTolist = selectedReplyTo();
        setIsLoader(true);
        const obj = {
            id: id,
            sendingType: "2",
            senderName: objectParameters.senderName,
            senderEmail: objectParameters.senderEmail,
            subject: objectParameters.subject,
            preHeader: objectParameters.preHeader,
            content: {
                typeId: 5,
                typeDetailId: objectParameters.typeDetailId
            },
            replyTo_List: replyTolist,
            replyTo:"noreply@"
        };
        if (edit !== null) {
            putAPI(obj);
        } else {
            postAPI(obj);
        }

    }
    const putAPI = putObject => {
        Client.put(`Trigger/Templates/${putObject.id}/Email`, putObject, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsLoader(false);
                try {
                    if (data.Success) {
                        navigate(`/create/templates/email?type=${getParameterByName('type', window.location.href)}`)
                    } else {
                        console.error(data);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => {
                setIsLoader(false);
            });
    }
    const postAPI = postObject => {
        Client.post(`Trigger/Templates/Email`, postObject, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsLoader(false);
                try {
                    if (data.Success) {
                        navigate(`/create/templates/email?type=${getParameterByName('type', window.location.href)}`)
                    } else {
                        console.error(data);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => {
                setIsLoader(false);
            });
    }
    const closeMethod = () => {
        setReplyToggle(replyToShow = !replyToShow)
    }
    // SendeName Popup Display //
    const senderNameFn = ev => {
        const ishow = (ev.target.value === "Create Sender Email") ? true : false;
        setSenderNameContext(prv => ({ ...prv, display: ishow }));
    }
    const enterSenderName = (ev, _vl) => {
        const { data } = sendeEmail;
        const filterItem = data.whiteLableDomains.map(x => x.id === _vl.id ? ({ ...x, emailId: ev.target.value, selectOptionDisabled: false }) : { ...x });
        setSenderEmail({ data: { ...data, whiteLableDomains: filterItem } });

    }
    const replyToMemo = useMemo(() => {
        const filterArray = replytoValue.data.filter(x => x.isVerified || x.id === '00000000-0000-0000-0000-000000000000');
        filterArray.map(x => ({ ...x, value: x.email.email, label: x.email.email }));
        return filterArray;
    }, [replytoValue.data]);

    useEffect(() => {
        senderListLoad();
        replyToLoad();
        emailCampaign();
        if (getParameterByName('edit', window.location.href) !== null) {
            editEmailTriggeremail();
            setButtonName('Update')

        }
    }, [senderListLoad, replyToLoad, emailCampaign, editEmailTriggeremail])



    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Email Trigger</h1>
                        <p className="sms_content">
                            Create an Email template that you could trigger from our API or Distribution page or include it as a trigger inside your Landing Pages, Emails or Signup Forms to send out a specific Email campaign.
                        </p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec">
                <form>
                    <div className="sms_Sec_1 bx-boder-common inline-block">
                        <div className="_h_header">
                            <h3>Create New Template</h3>
                        </div>
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Sender Name</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.senderName}
                                    placeholder="Sender Name"
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ senderName: e.target.value } })} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Sender Email</label>
                                <select className="inputformmodel"
                                    value={objectParameters.senderEmail}
                                    name="senderEmail"
                                    onChange={e => { setObjectParameters({ ...objectParameters, ...{ senderEmail: e.target.value === "Create Sender Email"?"":e.target.value } }); senderNameFn(e) }}>
                                    <option value="">Select</option>
                                    {sendeEmail.data.whiteLableDomains && sendeEmail.data.whiteLableDomains.map((v, i) =>
                                        v.emailAddress &&
                                        <option key={i} value={v.emailAddress.email}>{v.emailAddress.email}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Reply To</label>
                                <Select

                                    value={objectParameters.replyTo}
                                    isMulti
                                    name="language"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={onChange}
                                    options={replyToMemo}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Subject</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.subject}
                                    
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ subject: e.target.value } })}
                                    placeholder="Create a subject text for your email." />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Pre Header</label>
                                <input type="text"
                                    className="inputformmodel"
                                    value={objectParameters.preHeader}
                                    placeholder="Pre Header"
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ preHeader: e.target.value } })} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Email Campaign</label>
                                <select
                                    className="inputformmodel"
                                    value={objectParameters.typeDetailId}
                                    onChange={e => setObjectParameters({ ...objectParameters, ...{ typeDetailId: e.target.value, url: e.target.childNodes[e.target.selectedIndex].getAttribute('data-url') } })}
                                >
                                    <option value="">Select</option>
                                    {emailCampaignValue.data.map((v, i) => <option key={i} data-url={v.PreviewUrl} value={v.Id}>{v.Name}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx nomargin text-right">
                                <button className="grid_btn_1 nobg noborder" type="button" onClick={() => navigate(`/create/templates/email?type=${getParameterByName('type', window.location.href)}`)}>
                                    Cancel
                                </button>
                                <button className="grid_btn_1 grid_btn_active"
                                    disabled={objectParameters.senderName !== "" &&
                                        objectParameters.senderEmail !== "" &&
                                        objectParameters.replyTo.length !== 0 &&
                                        objectParameters.typeDetailId !== "" &&
                                        objectParameters.subject !== "" ? false : true}
                                    type="button" onClick={saveTemplateFn}>
                                    <ButtonLoader IsLoader={IsLoader}>{buttonName}</ButtonLoader>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="previewPanel">
                        <div className="iphonePriview">
                            {objectParameters.url !== "" || objectParameters.url !== undefined ? <div className="padding20"><iframe title="ss" className="noborder" width="100%" src={objectParameters.url} height="500"></iframe></div> :
                                <div className="previewDefaultMsg">
                                    A preview of your selected Email Campaign will be show here.
                                    <br></br>
                                    <br></br>
                                    Select Email Campaign to see the preview.
                                </div>
                            }
                        </div>
                    </div>
                </form>
                {replyToShow ? <ReplyTo ReplyToData={replytoValue} setValue={setReplyTo} Close={closeMethod} ReplytoAPI={replyToLoad} /> : <></>}
                {senderNameContext.display && <SenderNameEmail
                    setSenderNameProperties={setSenderNameContext}
                    Data={sendeEmail.data}
                    senderNameContext={senderNameContext}
                    EnterSenderName={enterSenderName}
                    SetSenderEmail={setSenderEmail}
                    Reload={senderListLoad}
                />}
            </div>
        </>
    )
}
export default CreateEmailTrigger;