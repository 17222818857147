import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import CurrentUsage from './CurrentUsage';
import { useSelector } from 'react-redux';
import Subscription from './Subscription';
import './index.css'
import AddFund from './AddFund';
import { useEffect } from 'react';
import Client from '../../GlobalAPICall';
const Tabing = ({ data }) => {
    const defaultId = 46;
    const { APITOKEN } = useSelector(state => state);
    const [getData, setData] = useState({});
    const load = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`User/Subscription`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setData(prv => ({ ...prv, ...data }));
                    } catch (err) {
                        console.log(err);
                    }
                })
                .catch(err => console.error(err));
    }, [APITOKEN]);

    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.Id);
    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.Id === tabId ? 'active ' : ' '} id={item.Id}>{item.Name}</Link></li>
                    })}
                </ul>
                <label className="_infoplan">
                    <span>Subscription: </span>
                    <strong>{APITOKEN.config.subscriptionInfo && APITOKEN.config.subscriptionInfo.SubscriptionName}</strong>
                </label>
            </div>
            {
                <div className="tab_body">{
                    tabId === defaultId ?
                        <CurrentUsage UsageData={getData} /> : tabId === 47 ? <Subscription Data={getData} APITOKEN={APITOKEN} TabReload={clickmethod} /> : <AddFund Data={getData} APITOKEN={APITOKEN} load={load} />}
                </div>
            }
        </>
    )
}

export default Tabing;