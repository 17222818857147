import Tabing from "./Tabing";
import React, { useEffect, useState } from "react";
import "./index.css";
import { Container } from "../App";

import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
const Dashboard = () => {
  const url = process.env;
  const [tabData, setTab] = useState([]);
  const { APITOKEN } = useSelector((state) => state);
  useEffect(() => {
    let callded = true;
    APITOKEN["token"] !== "" &&
      Client.get(
        `${url.REACT_APP_API_URL}/Insights/Dashboard/Categories?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`
      )
        .then(({ data }) => {
          try {
            if (data.length > 0) if (callded) setTab(data);
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    // document.title = title
    return () => {
      callded = false;
      setTab([]);
    };
  }, [APITOKEN, url.REACT_APP_API_URL]);

  return (
    <>
      <Container className="margin-top50">
        <div className="dashbd_sec margin-top50"></div>
      </Container>
      <div className="-dash-container margin-top50 width100 inline-block">
        {/* Tabing Dashboard */}
        {tabData.length > 0 ? <Tabing data={tabData} /> : <></>}
      </div>
    </>
  );
};

export default Dashboard;
