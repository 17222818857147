import { useCallback, useEffect, useState, useMemo } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import BlankData from "../../BlankData";
import { useNavigate } from "react-router";
import { Datepicker } from "@mobiscroll/react";
import GridLoader from "../../ReuseTemplate/GridLoader";
import axios from "axios";

const LandingPageSending = () => {
    const { APITOKEN } = useSelector(state => state);
    const [dataSMSSending, setdataSMSSending] = useState({
        data: [],
        isDisplay: true
    });
    const [totalRecode, setTotalRecord] = useState(0);
    const [skip, setSkip] = useState(0);
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const [minDate, setMinDate] = useState('');
    const [obj, setAPIObject] = useState(prv => ({
        graterThanVal: !prv ? 0 : prv.graterThanVal,
        searchValue: !prv ? "" : prv.searchValue,
        startDate: !prv ? "" : prv.startDate,
        endDate: !prv ? "" : prv.endDate
    }));

    const debounce = (func) => {
        let timer;
        return function (...args) {
            const context = this;
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                timer = null;
                func.apply(context, args);
            }, 500);
        };
    };

    const debouncedChangeHandler = useMemo(() => debounce(e => setAPIObject(prv => ({ ...prv, searchValue: e }))), []);
    const DateSelected = (e) => {
        const [startDate, endDate] = e.valueText.split(' - ');
        setAPIObject(prv => ({ ...prv, startDate, endDate }));
    }
    const loadApiCall = useCallback(async () => {
        setdataSMSSending(prv => ({
            ...prv,
            isDisplay: true
        }));
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/landingpage_list?total_visitors_greater_than=0&search=${obj.searchValue}&startDate=${obj.startDate}&endDate=${obj.endDate}&skip=${skip}&limit=10`)
                    .then(({ data }) => {
                        try {
                            setdataSMSSending(prv => ({
                                ...prv,
                                data: data || [],
                                isDisplay: false
                            }));
                            const { Total } = data.length === 0 ? 0 : data[0];
                            setTotalRecord(Total);
                            if (((skip + 1) * 10) > Total) {
                                setpainationButton(prv => ({ ...prv, next: true }));
                            } else {
                                if (data.length < 10) {
                                    if (skip === 0) {
                                        setpainationButton(prv => ({ ...prv, previous: true, next: true }));
                                    };
                                };
                                setpainationButton(prv => ({ ...prv, next: false }))
                            }
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        if (axios.isCancel(err)) {
                            console.error(err);
                        }

                    });
        } catch (err) {
            console.log(err);
        }

    }, [APITOKEN, obj, skip]); // <-- add any missing dependencies react warns about

    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }
    useEffect(() => {
        loadApiCall();
        setMinDate(() => (APITOKEN.config.hasOwnProperty('cultureCode')) && APITOKEN.config.currentTime.split('.')[0]);
    }, [loadApiCall, APITOKEN])

    return (
        <><div className="bx-boder-common">
            <div className="_insight_header white">
                <h3>Landing Page Sending</h3>
            </div>
            <div className="bx-pad white">
                <div className="total_res inline-block" >
                    <label className="inline-block" style={{ display: 'none' }}>Total Visitors </label>
                    <input
                        style={{ display: 'none' }}
                        type="text"
                        className="inline-block wi_70px border-radius4 inputformmodel text-center"
                        value={obj.graterThanVal}
                        onChange={e => setAPIObject(prv => ({ ...prv, graterThanVal: e.target.value }))} />
                </div>
                <div className="date-range inline-block dateRange">
                    <label className="inline-block">Date Range</label>
                    <Datepicker className="inline-block border-radius4  inputformmodel"
                        select="range"
                        max={minDate}
                        onChange={e => DateSelected(e)}
                        dataTimezone='utc'
                        dateFormat="YYYY.MM.DD"
                        display="center"
                        locale={APITOKEN.config.countryCode}
                        theme="ios"
                        controls={['calendar']} />
                    <input
                        type="text"
                        className="inline-block border-radius4 inputformmodel fright"
                        placeholder="Landing Page Name Search"
                        onChange={e => debouncedChangeHandler(e.target.value)} />

                </div>
            </div>
            <div className="-cam-sen-grid white drop-zone">
                <div className="b_top">
                    <SendingTable dataGrid={dataSMSSending} />
                </div>
            </div>
            {dataSMSSending.data.length > 0 ?
                <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                    <span className="pagination_count vertical-middle">
                        <span>{skip + 1}</span>-
                        <span>{(skip + 1) * 10}</span> of&nbsp;
                        <span>{totalRecode}</span>
                    </span>
                    <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                    <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                </div>
                : <></>}
        </div></>
    )
}

export default LandingPageSending;

const SendingTable = ({ dataGrid }) => {
    return (
        <>{dataGrid.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5]} /> :
            <table
                cellPadding="0"
                cellSpacing="0"
                className="smstable table-layout-default white">

                {dataGrid && dataGrid.data.length === 0 ? ''
                    : <thead>
                        <tr>
                            <th className="table_cell_header">Name</th>
                            <th className="table_cell_header">Status</th>
                            <th className="table_cell_header">Goals</th>
                            <th className="table_cell_header">Unique Visitors</th>
                            <th className="table_cell_header">Page View</th>
                            <th className="table_cell_header">Conversions</th>
                            <th className="table_cell_header">Conversions Rate</th
                            >
                        </tr>
                    </thead>
                }

                <tbody>
                    {dataGrid && dataGrid.data.length === 0 ? <BlankData title="You have not sent any LandingPage." colSpan="8" content="After you have sent an LandingPage you will find the insights here." /> : <Grid dataGrid={dataGrid} />}
                </tbody>
            </table>}
        </>
    )
}
const Grid = ({ dataGrid }) => {
    const navigate = useNavigate();
    return (
        <>
            {dataGrid && dataGrid.data.map((v, i) =>
                <tr key={i}><td className="body_table_cell"><button type="button" onClick={() => navigate(`/insights/landingpageDetails/${v['Id']}`)} className="link text-left detail_sms word-wrap">{v['Name']}</button></td><td className="body_table_cell">{v['Status']}</td><td className="body_table_cell">{v['HasGoal']}</td><td className="body_table_cell">
                    {v['Unique Vistors']}</td><td className="body_table_cell">{v['Page Views']}</td><td className="body_table_cell">{v['Conversions']}</td><td className="body_table_cell">{v['Conversion Rate']}</td></tr>
            )}
        </>
    )
}