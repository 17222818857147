
export const CreateUTM = ({ children,...props }) => {
    return (
        <>
            <div className="utm_container">
                <div className="topn-header">
                    <span className="selected-title ">UTM {props.Title} </span>
                    <span className="padding20 fright">
                        <i className="fa fal fa-times _clsbtn  fright pointer" onClick={props.close}></i>
                    </span>
                </div>
                <div className="_h_header">
                    <h3>UTM {props.Title}</h3>
                </div>
                <div className="padding20">
                    {children}
                </div>

            </div>
            <div className="_utm_mask"></div>
        </>
    )

}

