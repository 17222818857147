import React, { useCallback, useEffect, useState } from "react";
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import axios from "axios";
import BlankData from "../../../BlankData";
import * as filestack from "filestack-js";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";
import Languages from "../Track/Languages";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import SortableOrder from "../SortableOrder";
import { ToastContainer, toast } from "react-toastify";
const client = filestack.init("APBcz9XF7TEmEQpcF5wmxz");

// Language API Call on Load//

function Speaker() {
  const { APITOKEN } = useSelector((state) => state);
  const [langList, setLangList] = useState({
    data: [],
    gridData: [],
    selectedLang: "",
    DropDownList: [],
    selectedLangArray: [],
  });
  const [speakderVal, SetSpeakerValue] = useState({
    languageCode: "",
    speakerName: "",
    speakerDescription: "",
    speakerImage: "",
  });
  const [error, SetErrorHandle] = useState({});
  const [editId, SetEditHandle] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const onChange = useCallback(
    (newValue) => {
      let url = ``;
      if (!!newValue) {
        if (newValue !== "") {
          url = `/Mobile/Speakers?languageCode=${newValue}`;
          SetSpeakerValue((prv) => ({
            ...prv,
            languageCode: newValue,
          }));
        } else {
          url = `/Mobile/Speakers?languageCode=${langList.selectedLang}`;
          SetSpeakerValue((prv) => ({
            ...prv,
            languageCode: langList.selectedLang,
          }));
        }
      } else {
        url = `/Mobile/Speakers?languageCode=${langList.selectedLang}`;
        SetSpeakerValue((prv) => ({
          ...prv,
          languageCode: langList.selectedLang,
        }));
      }
      const cancelToken = axios.CancelToken.source();
      Client.get(url, {
        cancelToken: cancelToken.token,
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      })
        .then(({ data }) => {
          try {
            setLangList((prv) => ({
              ...prv,
              gridData: data,
            }));
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            cancelToken.cancel();
          }
        });
    },
    [APITOKEN, langList.selectedLang]
  );
  const load = useCallback(async () => {
    if (APITOKEN["token"] !== "") {
      const { data } = await Languages(APITOKEN);
      try {
        if (data.length > 0) {
          setLangList({
            data,
            selectedLang: data[0].LanguageCode,
            DropDownList: data.map((x) => ({
              ...x,
              value: x.LanguageCode,
              label: x.Value,
              select: false,
            })),
          });
          onChange();
        }
      } catch (err) {
        console.error(err);
      }
    }
    return () => {
      setLangList({
        data: [],
        gridData: [],
        selectedLang: "",
        DropDownList: [],
        selectedLangArray: [],
      });
    };
  }, [APITOKEN, onChange]);

  useEffect(() => {
    load();
  }, [load]);

  // Speaker Delete API Call //
  function confirmDelete() {
    Client.delete(`Mobile/Speakers?speakerId=${deleteId}`, {
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then((res) => {
        if (res.data.Success) {
          setDeleteId("");
          onChange(speakderVal.languageCode);
        }
      })
      .catch((err) => console.error(err));
  }
  function landDelete(ev) {
    setDeleteId(ev.speakerId);
    SetErrorHandle({});
  }
  const onUploadSuccess = () => {
    const options = {
      maxFiles: 1,
      fromSources: ["local_file_system"],
      uploadInBackground: false,
      onOpen: () => console.log("opened!"),
      onUploadDone: (res) => {
        console.log(res);
        SetSpeakerValue((prv) => ({
          ...prv,
          speakerImage: res.filesUploaded[0].url,
        }));
      },
    };
    client.picker(options).open();
  };

  const editItem = (item) => {
    window.scrollTo(0, 0);
    SetEditHandle(item.speakerId);
    SetSpeakerValue((prv) => ({
      ...prv,
      speakerName: item.speakerName,
      speakerDescription: item.speakerDescription,
      speakerImage: item.speakerImage,
      languageCode: item.languageCode,
    }));
  };

  const SpeakerSave = () => {
    setIsLoader(true);
    if (editId !== 0) {
      speakderVal["speakerId"] = editId;
    } else {
      speakderVal["speakerId"] = 0;
    }
    PostAPI(speakderVal);
  };
  const PostAPI = (obj) => {
    Client.post(`Mobile/Speakers`, obj, {
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then((res) => {
        setIsLoader(false);
        if (res.data.Success) {
          resetFn();
          onChange(obj.languageCode);
          SetErrorHandle((prv) => ({
            ...prv,
            message: res.data.Message,
            flag: true,
          }));
        } else {
          SetErrorHandle((prv) => ({
            ...prv,
            message: res.data.Message,
            flag: false,
          }));
        }
      })
      .catch((err) => setIsLoader(false));
  };

  const resetFn = () => {
    SetSpeakerValue({
      languageCode: "",
      speakerName: "",
      speakerDescription: "",
      speakerImage: "",
    });
    setLangList((prv) => ({
      ...prv,
      selectedLang: "",
    }));
    SetEditHandle(0);
    SetErrorHandle((prv) => ({ ...prv, message: "", flag: false }));
  };

  return (
    <>
      {deleteId === "" && (
        <div className="bx-boder-common white width100 inline-block">
          <div className="_h_header">
            <h3>Speaker</h3>
          </div>
          <div className="padding20">
            <div style={{ maxWidth: "492px" }}>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Select language</label>

                <select
                  className="inputformmodel"
                  value={speakderVal.languageCode}
                  onChange={(e) => onChange(e.target.value)}
                >
                  <option value="">Select</option>
                  {langList.DropDownList.map((v, i) => (
                    <option value={v.LanguageCode} key={i}>
                      {v.Value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Speaker Name</label>
                <input
                  type="text"
                  className="inputformmodel"
                  placeholder="Name"
                  value={speakderVal.speakerName}
                  onChange={(e) =>
                    SetSpeakerValue((prv) => ({
                      ...prv,
                      speakerName: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Speaker Description</label>
                <textarea
                  type="text"
                  className="inputformmodel textareaModel"
                  value={speakderVal.speakerDescription}
                  onChange={(e) =>
                    SetSpeakerValue((prv) => ({
                      ...prv,
                      speakerDescription: e.target.value,
                    }))
                  }
                  placeholder="Description"
                ></textarea>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Speaker Image</label>
                <button
                  type="button"
                  className="grid_btn_1"
                  onClick={onUploadSuccess}
                >
                  Image Upload
                </button>
                {speakderVal.speakerImage !== "" && (
                  <strong>{speakderVal.speakerImage}</strong>
                )}
              </div>
            </div>
            <div className="formmodel-bx text-right nomargin">
              <p className={`${error.flag ? "success" : "validation"} addfn`}>
                {error.message}
              </p>
              <button
                type="button"
                className="grid_btn_1 nobg noborder"
                onClick={resetFn}
              >
                Cancel
              </button>
              <button
                type="button"
                className="grid_btn_1 grid_btn_active"
                disabled={
                  speakderVal.speakerName !== "" &&
                  speakderVal.speakerDescription !== "" &&
                  speakderVal.speakerImage !== "" &&
                  speakderVal.languageCode.length > 0
                    ? false
                    : true
                }
                onClick={SpeakerSave}
              >
                <ButtonLoader IsLoader={isLoader}>
                  {editId !== 0 ? "Update" : "Save"}
                </ButtonLoader>
              </button>
            </div>
          </div>
          {langList.gridData?.length > 0 ? (
            <GridLanguage
              GridData={langList.gridData}
              OnDelete={landDelete}
              onEdit={editItem}
              setGrid={setLangList}
              selectedValue={speakderVal}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      {langList.gridData?.length === 0 ? (
        <></>
      ) : deleteId !== "" ? (
        <div className="bx-boder-common white width100 inline-block">
          {" "}
          <div className="_h_header">
            <h3>Delete</h3>
          </div>
          <table cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <BlankData
                title="Are you sure you want to delete this speaker?"
                content=""
              />
            </tbody>
          </table>
          <div className="text-right padding20">
            <button
              className="grid_btn_1 noborder nobg"
              onClick={() => {
                setDeleteId("");
                resetFn();
              }}
              type="button"
            >
              Cancel
            </button>
            <button
              className="grid_btn_1 deleteBtn"
              onClick={confirmDelete}
              type="button"
            >
              Confirm
            </button>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

export default Speaker;

function GridLanguage({ GridData, OnDelete, onEdit, setGrid, selectedValue }) {
  const onSortEnd = async (oldIndex, newIndex) => {
    const reorder = arrayMoveImmutable(GridData, oldIndex, newIndex);
    const postObject = reorder.map(({ speakerId }, index) => ({
      id: speakerId,
      order: index + 1,
    }));
    setGrid((prv) => ({
      ...prv,
      gridData: arrayMoveImmutable(prv.gridData, oldIndex, newIndex),
    }));

    const { data } = await SortableOrder(
      { Id: 0, Type: "Speaker", Code: selectedValue.languageCode },
      postObject
    );
    if (data.Success) {
      toast.success("Save successfully", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="b_top">
      <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
        <table cellPadding="0" cellSpacing="0" className="smstable ">
          <thead>
            <tr>
              <th className="table_cell_header">Speaker Image</th>
              <th className="table_cell_header">Speaker Name</th>
              <th className="table_cell_header">Speaker Description</th>
              <th className="table_cell_header">Language Code</th>
              <th className="table_cell_header">Actions</th>
            </tr>
          </thead>
          <tbody>
            {GridData &&
              GridData.map((item, index) => {
                return (
                  <SortableItem key={index}>
                    <tr>
                      <td className="body_table_cell">
                        <img
                          src={item.speakerImage}
                          alt={item.speakerName}
                          width={100}
                        />
                      </td>
                      <td className="body_table_cell">{item.speakerName}</td>
                      <td className="body_table_cell">
                        {item.speakerDescription}
                      </td>
                      <td className="body_table_cell">{item.languageCode}</td>
                      <td className="body_table_cell">
                        {/* <button type="button" className="link nopadding" onClick={() => OnDelete(item)}>Gallery</button> &nbsp; */}
                        <button
                          type="button"
                          className="link"
                          onClick={() => onEdit(item)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="link"
                          onClick={() => OnDelete(item)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </SortableItem>
                );
              })}
          </tbody>
        </table>
      </SortableList>
      <ToastContainer className="ToastCustom" />
    </div>
  );
}
