// import GoalSMSPurpose from "./GoalSMSPurpose";
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import SMSendingGrid from "../../Goal/SMSendingGrid";
import { Datepicker } from "@mobiscroll/react";
import ConversionGoalGrid from "../../Goal/ConversionGoalGrid";
import PerforrmaceGoalGrid from "../../Goal/PerforrmaceGoalGrid";
import { useNavigate } from "react-router";
import moment from "moment";
import {
    buildObjectPost,
    buildPerformanceObjectPost,
    buildSMSObjectPost,
    filterGoals,
    filterPerFormance
} from "./GoalCommentPostObject";

const GoalContainer = ({ ...props }) => {
    const { goalPurpose, setGoalPurpose, EditObject } = props;
    const { APITOKEN } = useSelector(state => state);
    const [postArray, setPostArray] = useState([]);
    const [checkValidation, setValidationChcked] = useState([]);
    const navigate = useNavigate();
    const [measureGoal, setMeasureGoal] = useState({
        minDate: '',
        endDate: '',
        startDate: '',
        sinceActivated: true
    });
    const [createdGoalList, setCreatedGoal] = useState([])
    const [goaltemplate, setGoaltemplate] = useState([]);
    const [valueDisabled, setValueDisabled] = useState(false);

    const loadGoalList = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.get(`Insights/landing_page/${goalPurpose.goalId}/goals/${getParameterByName('cam', window.location.href)}/setting_details/${goalPurpose.DataCategoryId}${goalPurpose.goaltemplateId === "" ? "" : `?goaltemplateId=${goalPurpose.goaltemplateId}`}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            const KPISetting = JSON.parse(res.data[0].KPISettings);
                            if (goalPurpose.DataCategoryId === 27 || goalPurpose.DataCategoryId === 56) {
                                setGoaltemplate(filterGoals(KPISetting));
                            } else {
                                setGoaltemplate(filterPerFormance(KPISetting));
                            }

                            setValueDisabled(false);
                        } catch (err) {
                            console.error(err);
                        }
                    }).catch(err => {
                        console.log(err);
                    })
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, goalPurpose])
    const load = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.get(`Insights/goals/${goalPurpose.DataCategoryId}/Details`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            const { data } = res;
                            setCreatedGoal(data);
                            loadGoalList()
                        } catch (err) {
                            console.error(err);
                        }
                    }).catch(err => {
                        console.log(err);
                    })
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, goalPurpose, loadGoalList]);



    const campaignSelected = (ev) => {
        setGoalPurpose(prv => ({
            ...prv,
            goaltemplateId: ev.target.value
        }))
    }


    const saveGoal = () => {
        const postObject = {
            goalLandingPageId: goalPurpose.goalId,
            goalTemplateId: getParameterByName('cam', window.location.href),
            measureEndDateTime: measureGoal.startDate,
            measureStartDateTime: measureGoal.endDate,

        }
        if (goalPurpose.DataCategoryId === 27 || goalPurpose.DataCategoryId === 56) {
            postObject['settingsValues'] = buildObjectPost(goaltemplate);
        } else if (goalPurpose.DataCategoryId === 25) {
            postObject['settingsValues'] = buildSMSObjectPost(goaltemplate);
        } else {
            postObject['settingsValues'] = buildPerformanceObjectPost(goaltemplate);
        }

        if (EditObject.edit) {
            PutAPICall(postObject);
        } else {
            PostAPICall(postObject);
        };
    }

    const PutAPICall = (postObject) => {

        try {
            Client.put(`Insights/landing_page/goals/${getParameterByName('cam', window.location.href)}/setting_details/${goalPurpose.DataCategoryId}`, postObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {
                    if (res.data.status || res.data.Success) {
                        navigate('/create/goalActivity');
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.error(err);
        }

    }

    const PostAPICall = (postObject) => {
        try {
            Client.post(`Insights/landing_page/goals/${getParameterByName('cam', window.location.href)}/setting_details/${goalPurpose.DataCategoryId}`, postObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {
                    if (res.data.status) {
                        navigate('/create/goalActivity');
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
        } catch (err) {
            console.error(err);
        }

    }
    useEffect(() => {
        load();
       
        setValidationChcked([]);
        if (EditObject.edit) {
            setMeasureGoal(prv => ({
                ...prv,
                minDate: APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0],
                startDate: moment(EditObject.editData.MeasureEndDateTime).format('YYYY-MM-DD hh:mm:ss'),
                endDate: moment(EditObject.editData.MeasureStartDateTime).format('YYYY-MM-DD hh:mm:ss'),
            }));
        } else {
            setMeasureGoal(() => ({
                minDate: APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0],
                startDate: APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0],
                endDate:''
            }));
        }
        
        
    }, [load, APITOKEN, EditObject])
    return (
        <>
            <div className="sms_Sec_1 bx-boder-common width100 margin-top">
                <div className="_h_header noborder">
                    <h3>Set {goalPurpose.nextFileName} Goals</h3>
                </div>
                <div className="go-suti">
                    <p>Prefill your Goals based on a Template. You can always adjust your numbers, but it is great start.(Optional)</p>
                    <select className="inputformmodel width320" onChange={campaignSelected} disabled={
                        EditObject.edit ? true : false
                    } >
                        <option>Select</option>
                        {createdGoalList.map((v, i) => <option key={i} value={v.Id}>{v.Name}</option>)}
                    </select>
                </div>
                {
                    goalPurpose.DataCategoryId === 25 ? <SMSendingGrid
                        goalObject={goaltemplate}
                        setGoalObject={setGoaltemplate}
                        setPostArray={setPostArray}
                        setValidationChcked={setValidationChcked}
                        checkValidation={checkValidation}
                    /> :
                        (goalPurpose.DataCategoryId === 27 || goalPurpose.DataCategoryId === 56) ? <ConversionGoalGrid
                            goalObject={goaltemplate}
                            setGoalObject={setGoaltemplate}
                            setPostArray={setPostArray}
                            postArray={postArray}
                            valueDisabled={valueDisabled}
                            setValidationChcked={setValidationChcked}
                            checkValidation={checkValidation}
                        /> : (goalPurpose.DataCategoryId === 26 || goalPurpose.DataCategoryId === 29) ? <PerforrmaceGoalGrid
                            goalObject={goaltemplate}
                            setGoalObject={setGoaltemplate}
                            setValidationChcked={setValidationChcked}
                            checkValidation={checkValidation}
                        /> : <></>
                }
            </div>
            <div className="sms_Sec_1 bx-boder-common width100 margin-top margin-bottom">
                <div className="_h_header">
                    <h3>Measure Goal from Start Date and Time</h3>
                </div>
                <div className="padding20 goalsetSecondSection">
                    <div className="formmodel-bx">
                        <label htmlFor="measure">
                            <input type="radio" id="measure" name="measure" checked onChange={e => setMeasureGoal(prv => ({
                                ...prv,
                                sinceActivated: e.target.checked
                            }))}
                                className="inline-block" style={{ position: 'relative', top: '2px' }} />
                            <span className="inline-block radiobtn"> &nbsp; &nbsp; Since my account was set to Active</span>
                        </label>
                    </div>
                    <div className="formmodel-bx">
                        <label htmlFor="date_t">
                            <input type="radio" id="date_t" name="measure" className="inline-block" style={{ position: 'relative', top: '2px' }} /> &nbsp;&nbsp;
                            <span className="goal_account width295 inline-block">
                                <Datepicker className="inputformmodel "
                                    value={measureGoal.startDate}
                                    min={measureGoal.minDate}
                                    onChange={e => setMeasureGoal(prv => ({
                                        ...prv,
                                        startDate: e.value
                                    }))}
                                    dataTimezone='utc'
                                    dateFormat="YYYY-MM-DD"
                                    locale={APITOKEN.config.countryCode}
                                    controls={['calendar', 'time']} placeholder="Start Time" />
                            </span>
                        </label>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Measure Goal achivement at End Date and Time?</label>
                        <span className="width320 goal_achivement">
                            <Datepicker className="inputformmodel "
                                value={measureGoal.endDate}
                                min={measureGoal.startDate}
                                onChange={e => setMeasureGoal(prv => ({
                                    ...prv,
                                    endDate: e.value
                                }))}
                                dataTimezone='utc'
                                dateFormat="YYYY-MM-DD"
                                locale={APITOKEN.config.countryCode}
                                controls={['calendar', 'time']} placeholder="End Date and Time" />
                        </span>
                    </div>
                    <div className="formmodel-bx text-right margin-top50">
                        <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => navigate('/create/goalActivity')}>Cancel</button>
                        {/* {checkValidation}
                        {JSON.stringify(measureGoal.endDate)} */}
                        <button type="button" disabled={
                            (measureGoal.endDate !== "" && checkValidation.length > 0) ? false : true
                        } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={saveGoal}>{(EditObject.edit) ? 'Update' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </>
    )

}

export default GoalContainer
