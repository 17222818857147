import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import { DownLoadData } from '../DownloadChartJSON';
import HC_exporting from 'highcharts/modules/exporting';
import Client from '../../GlobalAPICall';
HC_exporting(Highcharts)
const SpeakerChart = () => {
    const { APITOKEN } = useSelector(state => state);
    const chart = useRef();
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'pie'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        tooltip: {
            valueDecimals: 2,
            // pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
                '<b>{point.y}</b><br/><b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                colorByPoint: true,
                type: 'pie',
                size: '90%',
                innerSize: '50%',
                dataLabels: {
                    enabled: true,
                    crop: false,
                    distance: '0%',
                    style: {
                        fontWeight: 'normal',
                        fontSize: '13px'
                    },
                    connectorWidth: 0
                }
            },
            pie: {
                dataLabels: {
                    enabled: false
                },
                showInLegend: true
            }
        },
        colors: ['#FCE700', '#F8C4B4', '#f6e1ea', '#B8E8FC', '#BCE29E'],
        series: [{
            name: 'Brands',
            colorByPoint: true,

        }]
    });
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Insights/Mobile/TopListData?sourceType=Speaker&topCount=5', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                dataFilter(data);

            });

    }, [APITOKEN]);
    const dataFilter = (data) => {
        const ar = [];
        data.forEach(function (v, i) {
            ar.push({ name: v['sourceType'], y: v['totalCount'] });
        });
        // setBestDay(maxXObjects[0].Hitsday);
        // setOptionValue({ series: [{ data: ar }] });
        setOptionValue(prv => ({
            ...prv,
            series: [{ ...prv.series, data: ar }]
        }))
    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <HighchartsReact highcharts={Highcharts} ref={chart} options={optionData} />
    )

}

export default SpeakerChart