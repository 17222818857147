import Select from 'react-select';
import React, { useCallback, useEffect, useState } from 'react'
import Client from '../../GlobalAPICall';
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';
import { useNavigate, useParams } from 'react-router';

export default function CheckerMakerContainer({ APITOKEN }) {
    const [subUser, serSubUser] = useState([]);
    const [checker, selectedChecker] = useState([]);
    const [loader, setLoader] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

    const load = useCallback(() => {
        APITOKEN.token &&
            Client.get(`/user/active/subuser`)
                .then(({ data }) => {
                    serSubUser([...data.map(x => ({ ...x, value: x.SubUserId, label: x.UserEmail }))]);
                }).catch((err) => {
                    throw err;
                });
        return (() => {
            serSubUser([]);
        })
    }, [APITOKEN]);
    const onChange = value => {
        selectedChecker(value.map(item => item.SubUserId));
    }
    const givePermisstion = () => {
        setLoader(true)
        Client.patch(`Email/Send/SetCheckers/${id}`, checker)
            .then(({ data }) => {
                setLoader(false);
                if (data.success) {
                    navigate('/dashboard');
                }
                
            }).catch(err => {
                setLoader(false)
                throw err;
            })
    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className='margin-top50'>
            <div className="formmodel-bx">
                <label className="_l_able_cls"><strong>Checker</strong></label>
                <Select

                    // value={objectParameters.replyTo}
                    isMulti
                    name="language"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onChange}
                    options={subUser}
                />
            </div>

            <div className="text-right  button_group">
                <button className="grid_btn_1 grid_btn_active" type="button"
                    onClick={givePermisstion} disabled={checker.length === 0?true:false}>
                    <ButtonLoader IsLoader={loader}>
                        Request for approval
                    </ButtonLoader>

                </button>
            </div>
        </div>
    )
}
