import { useSelector } from "react-redux";

const StepsDistribution = ({ ...props }) => {
    const { Name, Active, Hide, UTM } = props;
    const { APITOKEN } = useSelector(state => state);
    return (
        <div className="">
            <div className="margin-top50">&nbsp;</div>
            <ul className="_step__ul margin-top50">
                <li className={`step1 ${Active === 1 ? 'active' : ''}`}>
                    <button type="button">1 - Select Recipients</button>
                    <span className="front"></span>
                </li>
                {APITOKEN['config'].subscriptionInfo?.IsUTMVisibleOnSending ?
                    <li className={`step2 ${Active === 5 ? 'active' : ''}`} style={{ display: !UTM ? 'none' : 'block' }}>
                        <span className="back"></span>
                        <button type="button">2 - UTM</button>
                        <span className="front"></span>
                    </li>
                    : <></>}
                <li className={`step2 ${Active === 2 ? 'active' : ''}`}>
                    <span className="back"></span>
                    <button type="button">{APITOKEN['config'].subscriptionInfo?.IsUTMVisibleOnSending?!UTM ? '2' : '3':'2'} - {Name} Details</button>
                    <span className="front"></span>
                </li>

                <li className={`step3 ${Active === 3 ? 'active' : ''}`} style={{ display: !!Hide ? 'none' : 'block' }}>
                    <span className="back"></span>
                    <button type="button">3 - Set Goals</button>
                    <span className="front"></span>
                </li>

                <li className={`step4 ${Active === 4 ? 'active' : ''}`}>
                    <span className="back"></span>
                    <button type="button">{APITOKEN['config'].subscriptionInfo?.IsUTMVisibleOnSending ? 4: Hide !== undefined ? (4 - 1) : 4} - Summary</button>
                </li>
            </ul>
        </div>
    )
}
export default StepsDistribution