
import _ from "lodash";
import { useCallback, useEffect } from "react";

const PerforrmaceGoalGrid = ({ ...props }) => {
    const { goalObject, setGoalObject, setValidationChcked, checkValidation } = props;

    const vailedFields = useCallback(() => {
        const goalrateNotBlank = goalObject.filter(x => (x['Goal Rate %'] !== "" && x['Goal Rate %'] !== undefined) || (x['Goal Quantity'] !== "" && x['Goal Quantity'] !== undefined)).map(v => v.Id);
        setValidationChcked(goalrateNotBlank);
    }, [goalObject, setValidationChcked]);

    const goalGetVal = (v, e, f) => {
        const index = _.findIndex(goalObject, function (o) { return o.Id === v.Id });
        const ind = _.findIndex(checkValidation, function (o) { return o === v.Id });
        goalObject[index].quantiryDisabled = f === 0 ? e.replace(/[^0-9]/g, '') !== '' ? true : false : false;
        goalObject[index].rateDisabled = f === 1 ? e.replace(/[^0-9]/g, '') !== '' ? true : false : false;
        goalObject[index]['Goal Rate %'] = f === 0 ? e.replace(/[^0-9]/g, '') : '';
        goalObject[index]['Goal Quantity'] = f === 1 ? e.replace(/[^0-9]/g, '') : '';
        const filtercheckValidation = _.sortedUniq(checkValidation);

        setGoalObject(() => ([...goalObject]));
        if (ind === -1) {
            filtercheckValidation.push(goalObject[index].Id);
            setValidationChcked(filtercheckValidation);
        } else {
            if (goalObject[index]['Goal Rate %'] === "" &&
                goalObject[index]['Goal Quantity'] === ""
            ) {
                setValidationChcked(filtercheckValidation.filter(x => x !== v.Id));
            }
        }

    }
    useEffect(() => {
        vailedFields();
    }, [vailedFields]);
    return (
        <>
            <table cellPadding="0" cellSpacing="0" className="smstable table-layout-default">
                <thead>
                    <tr>
                        <th className="table_cell_header" width="30%">KPI Name</th>
                        <th className="table_cell_header" width="46%">Description</th>
                        <th className="table_cell_header text-center">Goal Rate %</th>
                        <th className="table_cell_header text-center">Goal Quantity</th>
                    </tr>
                </thead>
                <tbody>
                    {goalObject.map((v, i) => <tr key={i}>
                        <td className="body_table_cell">{v['Name']}</td>
                        <td className="body_table_cell">{v['Description']}</td>
                        <td className="body_table_cell">
                            {v['Goal Rate %'] === undefined ? <></> :
                                <div className={`goalbx ${(v['Goal Rate %'] === undefined) ? 'remove' : ''}`}>
                                    <span className="margin-right15">{v['OperatorSymbol']}</span>
                                    <span className="drop-zone">
                                        <input style={{ width: '80px' }}
                                            type="text"
                                            value={v['Goal Rate %']}
                                            onChange={e => goalGetVal(v, e.target.value, 0)}
                                            className="inline-block inputformmodel goalvals percentage-padding-right"
                                            disabled={v.rateDisabled}
                                        />
                                        <b className="percentage">%</b>
                                    </span>
                                </div>
                            }
                        </td>
                        <td className="body_table_cell">
                            {v['Goal Quantity'] === undefined ? <></> :
                                <div className={(v['Goal Quantity'] === undefined) ? 'remove' : 'block'}>
                                    <span className="margin-right15">{v['OperatorSymbol']}</span>
                                    <input name="next"
                                        style={{ width: '80px' }}
                                        type="text"
                                        value={v['Goal Quantity']}
                                        onChange={e => goalGetVal(v, e.target.value, 1)}
                                        className="inline-block inputformmodel goalvals"
                                        disabled={v.quantiryDisabled}
                                    /></div>
                            }</td>
                    </tr>)}
                </tbody>
            </table>
        </>
    )

}

export default PerforrmaceGoalGrid
