
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import BlankData from '../../../../BlankData';
import { useNavigate } from 'react-router';

const SMSGoalTemplate = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [goalData, setGoalData] = useState([]);
    const [deleteId, setId] = useState({
        flag: true,
        id: ''
    });
    const navigate = useNavigate();
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/goals/${tabId}/Details`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setGoalData(res['data']);
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.log(err);
            });
    }, [APITOKEN, tabId]);

    const deleteConfirm = item => {
        setId(prv => ({
            ...prv,
            flag: false,
            id: item.Id
        }));
    }
    const deleteConfirmed = () => {
        Client.delete(`Insights/goals/template/${deleteId.id}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']

            }
        }).then(res => {
            try {
               // if (res.data.Success) {
                    load();
                    setId(prv => ({
                        ...prv,
                        flag: true,
                        id: ""
                    }));
               // }
            } catch (err) {
                console.error(err);
            }

        }).catch(err => {
            console.log(err);
        })
    }
    useEffect(() => {
        load();
    }, [load])
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100 nobg">
            {deleteId.flag ? <>
                <div className="_h_header white border-radius2">
                    <h3>SMS Goals</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => navigate('/createContent/62')}>Create New</button>
                    </span>
                </div>
                <div className="_list_fx">
                    <table className="smstable white" cellPadding="0" cellSpacing="0">
                        <thead>
                            <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Description</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {goalData && goalData.map((item, i) => <SMSGoalGrid item={item} key={i} Confirm={deleteConfirm} />)}
                        </tbody>

                    </table>
                </div>
            </> : <div className="white"><div className="_h_header"><h3>Delete Template</h3></div><table cellPadding="0" cellSpacing="0" width="100%" className="smstable">
                <tbody>
                    <BlankData title="Do you want Delete Template." content="Connect it to give your landing pages and website a more professional look." colSpan="5" />
                </tbody>
            </table> <div className="text-right padding20">
                    <button type="button"
                        onClick={() => setId(prv => ({
                            ...prv,
                            flag: false,
                            id: ""
                        }))} className="grid_btn_1 nobg noborder">Cancel</button> <button type="button" className="grid_btn_1  deleteBtn" onClick={deleteConfirmed}>Confirm</button></div></div>}</div>
    </>)
}

// SMS Goal Grid Method // 
const SMSGoalGrid = ({ item, Confirm }) => {
    const navigate = useNavigate();
    return (
        <tr><td className="body_table_cell">{item.Name}</td>
            <td className="body_table_cell">{item.Description}</td>
            <td className="body_table_cell">
                <button
                    type="button"
                    className="inline-block link _edit_template noborder nobg" onClick={() => navigate(`/create/templates/goal/CreateSMS?cam=${item.Id}&edit=true`)}>Edit</button>
                <span style={{ "display": item.IsDefault ? 'none' : 'inline-block' }}>&nbsp; | &nbsp;</span>
                <button type="button"
                    style={{ "display": item.IsDefault ? 'none' : 'inline-block' }}
                    className="inline-block link _edit_template noborder nobg" onClick={() => Confirm(item)}>Delete</button>
            </td>
        </tr>
    )
}


export default SMSGoalTemplate;
