import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BlankData from "../../../../BlankData";
import Client from "../../../../GlobalAPICall";
import { CreateUTM } from "./CreateUTM";
import getParameterByName from '../../../../ReuseTemplate/GetRoute'
import DeleteListTemplate from "../../../../ReuseTemplate";
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";
export const CreateUTMCampaign = () => {
    const { APITOKEN } = useSelector(state => state);
    const [suffixList, setSuffixList] = useState([]);
    const [PrefixList, setPrefixList] = useState([]);
    const [campaignList, setCampaignList] = useState([]);
    const navigate = useNavigate();
    const [IsLoader, setIsLoader] = useState(false);
    const [IsLoaderUtm, setIsLoaderUtm] = useState(false);
    const [apiResponse, setAPIResponse] = useState({});

    const [toggle, setToggle] = useState({
        prefix: false,
        campaign: false,
        suffix: false
    });
    const [deleteTemplate, setDeleteTemplate] = useState({
        prefix: false,
        campaign: false,
        suffix: false
    });

    const [formVal, setFormVal] = useState({
        name: "",
        description: "",
        edit: false
    });
    const [combiningVal, setCombiningWord] = useState({
        Prefix: "",
        Campaign: "",
        Suffix: ""
    });
    const suffixLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('UTM/GetUTMCategories/CampaignSuffix', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setSuffixList(result.data);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);
    const prefixLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('UTM/GetUTMCategories/CampaignPrefix', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setPrefixList(result.data);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);

    const campaignNameLoad = useCallback(() => {
        APITOKEN.token &&
            Client.get('UTM/GetUTMCategories/CampaignName', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setCampaignList(result.data);
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);

    const getVal = useCallback((res) => {
        const filterResp = res.filter(x => x['rowUID'] === getParameterByName('id', window.location.href));
        setCombiningWord({ Prefix: filterResp[0].prefixId, Campaign: filterResp[0].campaignId, Suffix: filterResp[0].suffixId })
    }, [])

    const getUtmCampaign = useCallback(() => {
        if (APITOKEN.token) {
            Client.get(`UTM/GetUTMCampaign`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    if (res.data.length !== 0) {
                        getVal(res.data)
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        }
    }, [APITOKEN, getVal])


    useEffect(() => {
        suffixLoad();
        prefixLoad();
        campaignNameLoad();
        if (getParameterByName('id', window.location.href) !== null) {
            getUtmCampaign();
        }
    }, [suffixLoad, prefixLoad, campaignNameLoad, getUtmCampaign])



    const saveFn = (f) => {
        setIsLoaderUtm(true);
        const obj = {
            description: formVal.description,
            name: formVal.name,
            isTagFiled: true
        };
        let apiName = null;
        if (f === 0) {
            apiName = 'CampaignPrefix';
        } else if (f === 1) {
            apiName = 'CampaignName';
        } else {
            apiName = 'CampaignSuffix';
        }
        formVal.edit ? preFixPutAPI(apiName, obj, f) : preFixPostAPI(apiName, obj, f);
    }

    const [prefixId, setPrefixId] = useState('');
    const preFixPutAPI = (apiName, obj, f) => {

        Client.put(`UTM/UpdateUTMCategories/${apiName}/${prefixId}/Categories_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                setIsLoaderUtm(false);
                if (data.success) {
                    setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } });
                    setFormVal({ name: "", description: "", edit: false });
                    if (f === 0) {
                        prefixLoad();
                    } else if (f === 1) {
                        campaignNameLoad();
                    } else {
                        suffixLoad();
                    }
                    setAPIResponse();

                } else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoaderUtm(false);
        });
    }
    const preFixPostAPI = (apiName, obj, f) => {
        Client.post(`UTM/SaveUTMCategories/${apiName}/Data`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                setIsLoaderUtm(false);
                if (data.success) {
                    setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } });
                    setFormVal({ name: "", description: "", edit: false });
                    if (f === 0) {
                        prefixLoad();
                    } else if (f === 1) {
                        campaignNameLoad();
                    } else {
                        suffixLoad();
                    }
                    setAPIResponse({})
                } else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoaderUtm(false);
        });;
    }




    const combiningFN = () => {
        setIsLoader(true);
        const obj = {
            campaignName: combiningVal.Campaign,
            prefix: combiningVal.Prefix,
            suffix: combiningVal.Suffix
        };
        const id = getParameterByName('id', window.location.href);
        (id !== null) ? putAPI(obj, id) : postAPI(obj);

    }

    const putAPI = (obj, id) => {
        Client.put(`UTM/UpdateUTMCampaign/${id}/Campaign_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                setIsLoader(false);
                if (data.success) {
                    navigate(`/create/templates/UTM?type=78`);
                    setCombiningWord({ Prefix: '', Suffix: '', Campaign: '' });
                    setAPIResponse({});
                } else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoader(false);
        });
    }

    const postAPI = (obj) => {
        Client.post(`UTM/SaveUTMCampaign`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                setIsLoader(false);
                if (data.success) {
                    navigate(`/create/templates/UTM?type=78`);
                    setCombiningWord({ Prefix: '', Suffix: '', Campaign: '' });
                    setAPIResponse({});
                } else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoader(false);
        });
    }
    const editFn = (v, i) => {
        setAPIResponse({})
        if (i === 0) {
            setToggle({ ...toggle, ...{ prefix: false, suffix: true, campaign: false } });
        } else if (i === 1) {
            setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: true } });
        } else {
            setToggle({ ...toggle, ...{ prefix: true, suffix: false, campaign: false } });
        }
        setFormVal({ ...formVal, ...{ name: v.name, description: v.description, edit: true } });
        setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: false } });
        setPrefixId(v.rowUID);
    }
    const deleteFN = (v, i) => {
        setAPIResponse({})
        if (i === 0) {
            setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: true, campaign: false } });
        } else if (i === 1) {
            setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: true } });
        } else {
            setDeleteTemplate({ ...deleteTemplate, ...{ prefix: true, suffix: false, campaign: false } });
        }
        setPrefixId(v.rowUID);
    }

    const deleteConfirm = (f) => {
        let apiName = null;
        if (f === 0) {
            apiName = 'CampaignPrefix';
        } else if (f === 1) {
            apiName = 'CampaignName';
        } else {
            apiName = 'CampaignSuffix';
        }
        UTMDeleteAPI(apiName, f)
    }
    const UTMDeleteAPI = (name, i) => {
        Client.delete(`UTM/DeleteUTMCategories/${name}/${prefixId}`, {
            data: {},
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then((({data}) => {
                try {
                    if (data.success) {
                        setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: false } });
                        if (i === 0) {
                            prefixLoad();
                        } else if (i === 1) {
                            campaignNameLoad();
                        } else {
                            suffixLoad();
                        }
                    }
                } catch (err) {
                    console.error(err);
                }

            }))
    }


    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/create/templates/UTM?type=78" ToText="Templates" CurrentPage="UTM Campaign" />
            </div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 white bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Create UTM Campaign Name</h3>
                    </div>
                    <div className="padding20">
                        <div className="utm-campaign">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">UTM Campaign Prefix Name</label>
                                <select
                                    className="inputformmodel width100"
                                    value={combiningVal.Prefix}
                                    onChange={e => setCombiningWord({ ...combiningVal, ...{ Prefix: e.target.value } })}>
                                    <option value="">Select</option>
                                    {PrefixList.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">UTM Campaign Name *</label>
                                <select
                                    className="inputformmodel width100"
                                    value={combiningVal.Campaign}
                                    onChange={e => setCombiningWord({ ...combiningVal, ...{ Campaign: e.target.value } })}>
                                    <option value="">Select</option>
                                    {campaignList.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}

                                </select>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">UTM Campaign Suffix Name</label>
                                <select
                                    className="inputformmodel width100"
                                    value={combiningVal.Suffix}
                                    onChange={e => setCombiningWord({ ...combiningVal, ...{ Suffix: e.target.value } })}>
                                    <option value="">Select</option>
                                    {suffixList.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className="formmodel-bx text-right margin-top50">
                        {apiResponse.message && <p className="validation">{ apiResponse.message }</p>}
                            <button
                                className="grid_btn_1  nobg noborder"
                                type="button"
                                onClick={() => navigate(`/create/templates/UTM?type=78`)}>Cancel</button>
                            <button
                                className="grid_btn_1 grid_btn_active btn-gape-15"
                                disabled={(
                                    combiningVal.Campaign !== "") ? false : true}
                                type="button"
                                onClick={combiningFN}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    {
                                        getParameterByName('id', window.location.href) !== null ? 'Update' : 'Save'
                                    }
                                </ButtonLoader>
                            </button>
                        </div>
                        <div className="margin-top50">
                            <p className="font-16">It could be clever to use both a prefix and a suffix when creating your UTM Campaign Name. This will make your Google Analtyics Report look better and it will be easier to compare data from different marketing activities. Lower case characters are set as default and hyphen is used for combining the words.<br></br><br></br></p>
                            <p className="font-16"><strong>Prefix Name</strong><br></br> We reccomend to use the prefix name for your global seasonal merketing activity under a joint prefix name.<br></br><br></br></p>
                            <p className="font-16">
                                <strong>Campaign Name</strong>
                                <br></br>
                                We reccomend you to use your email/landing page creation name. We have made this possible with a dynamic field.
                                <br></br><br></br>
                            </p>
                            <p className="font-16">
                                <strong>Suffix Name</strong>
                                <br></br>
                                We reccomend you to use a date format at the end of the UTM Campaign, this is also dynamically set.
                            </p>
                        </div>
                    </div>
                </div>
                {/* UTM Campaign Prefix */}

                {deleteTemplate.prefix ? <div className="margin-top50 inline-block width100"><DeleteListTemplate title="Click on Confirm to delete this UTM campaign." content="This will permanently delete and it can not be restored." colSpan="5" onClick={() => setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: false } })} deleteDownload={() => deleteConfirm(0)} /></div> :
                    <div className="sms_Sec_1 white bx-boder-common width100">
                        <div className="_h_header">
                            <h3>UTM Campaign Prefix</h3>
                            <span className="flex-right">
                                <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => {
                                    setToggle({ ...toggle, ...{ prefix: true, suffix: false, campaign: false } })
                                    setFormVal({
                                        name: "",
                                        description: "",
                                        edit: false
                                    });
                                    setAPIResponse({});
                                }
                                } >Create Prefix</button>
                            </span>
                        </div>
                        <table cellPadding="0" cellSpacing="0" className="smstable _prefix_table">
                            {PrefixList.length > 0 &&
                                <thead>
                                    <tr>
                                        <th className="table_cell_header">Prefix Name</th>
                                        <th className="table_cell_header">Description</th>
                                        <th className="table_cell_header">Creation Time</th>
                                        <th className="table_cell_header">Created By</th>
                                        <th className="table_cell_header">Actions</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {PrefixList.length > 0 ? PrefixList.map((x, i) => <tr key={i}>
                                    <td className="body_table_cell">{x['name']}</td>
                                    <td className="body_table_cell">{x['description']}</td>
                                    <td className="body_table_cell">{x['creationTime']}</td>
                                    <td className="body_table_cell">{x['createdBy']}</td>
                                    <td className="body_table_cell"><button className="link inline-block" type="button" onClick={() => editFn(x, null)}>Edit</button><button type="button" className="link inline-block" onClick={() => deleteFN(x, null)}>Delete</button></td></tr>) : <BlankData
                                    title="You have not created any UTM Template." content="Campaign Create UTM to easily measure and visualize the success of your campaigns and contacts build." colSpan="5" />}
                            </tbody>
                        </table>
                        {toggle.prefix &&
                            <CreateUTM Title="Campaign Prefix" close={() =>
                                {setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } });setAPIResponse({})}
                            } >
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Campaign Prefix Name</label>
                                    <input
                                        type="text"
                                        className="inputformmodel width100" placeholder="Name"
                                        value={formVal.name}
                                        onChange={e => setFormVal({ ...formVal, ...{ name: e.target.value } })} />
                                    {apiResponse.message && <p className="validation">{ apiResponse.message }</p>}
                                </div>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Description  </label>
                                    <input type="text"
                                        className="inputformmodel width100"
                                        placeholder="Optional"
                                        value={formVal.description}
                                        onChange={e => setFormVal({ ...formVal, ...{ description: e.target.value } })} />
                                </div>
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">
                                        <strong>Prefix Name</strong>
                                    </label>
                                    <p>We recommend to use the prefix name for your global seasonal marketing activity under a joint prefix name
                                        <br></br><br></br>
                                        Lower case characters are set as default and hyphen is used for combining the words.
                                    </p>
                                </div>
                                <div className="formmodel-bx text-right">
                                    <button className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } });  setAPIResponse({});}} type="button">Cancel</button>
                                    <button className="_clsbtn grid_btn_1 grid_btn_active"
                                        disabled={(formVal.name !== "") ? false : true}
                                        type="button" onClick={() => saveFn(0)} >
                                        <ButtonLoader IsLoader={IsLoaderUtm}>
                                            {formVal.edit ? 'Update' : 'Save'}
                                        </ButtonLoader>
                                    </button>
                                </div>
                            </CreateUTM>}
                    </div>
                }
                {/* UTM Campaign Name */}
                {deleteTemplate.campaign ? <div className="margin-top50 inline-block width100"><DeleteListTemplate title="Click on Confirm to delete this UTM campaign." content="This will permanently delete and it can not be restored." colSpan="5" onClick={() => setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: false } })} deleteDownload={() => deleteConfirm(1)} /></div> :
                    <div className="sms_Sec_1 white bx-boder-common width100">
                        <div className="_h_header">
                            <h3>UTM Campaign Name</h3>
                            <span className="flex-right">
                                <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => {
                                    setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: true } })
                                    setAPIResponse({});
                                    setFormVal({
                                        name: "",
                                        description: "",
                                        edit: false
                                    })
                                }
                                } >Create Name</button>
                            </span>
                        </div>

                        <table cellPadding="0" cellSpacing="0" className="smstable _prefix_table">
                            {campaignList.length > 0 &&
                                <thead>
                                    <tr>
                                        <th className="table_cell_header">Name</th>
                                        <th className="table_cell_header">Description</th>
                                        <th className="table_cell_header">Creation Time</th>
                                        <th className="table_cell_header">Created By</th>
                                        <th className="table_cell_header">Actions</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {campaignList.length > 0 ? campaignList.map((x, i) => <tr key={i}>
                                    <td className="body_table_cell">{x['name']}</td>
                                    <td className="body_table_cell">{x['description']}</td>
                                    <td className="body_table_cell">{x['creationTime']}</td>
                                    <td className="body_table_cell">{x['createdBy']}</td>
                                    <td className="body_table_cell"><button className="link inline-block" type="button" onClick={() => editFn(x, 1)}>Edit</button><button type="button" className="link inline-block" onClick={() => deleteFN(x, 1)}>Delete</button></td></tr>) : <BlankData
                                    title="You have not created any UTM Template." content="Campaign Create UTM to easily measure and visualize the success of your campaigns and contacts build." colSpan="5" />}
                            </tbody>
                        </table>
                        {toggle.campaign && <CreateUTM Title="Campaign Name" close={() => { setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } }); setAPIResponse({})}} >
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Campaign Name</label>
                                <input type="text" className="inputformmodel width100" placeholder="Name" value={formVal.name} onChange={e => setFormVal({ ...formVal, ...{ name: e.target.value } })} />
                                {apiResponse.message && <p className="validation">{ apiResponse.message }</p>}
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Description  </label>
                                <input type="text" className="inputformmodel width100" placeholder="Optional" value={formVal.description} onChange={e => setFormVal({ ...formVal, ...{ description: e.target.value } })} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">
                                    <strong>Campaign Name</strong>
                                </label>
                                <p>Lower case characters are set as default and hyphen is used for combining the words.
                                </p>
                            </div>
                            <div className="formmodel-bx text-right">
                                <button className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } });  setAPIResponse({});}} type="button">Cancel</button>
                                <button className="_clsbtn grid_btn_1 grid_btn_active" disabled={(formVal.name !== "" ) ? false : true} type="button" onClick={() => saveFn(1)} >
                                    <ButtonLoader IsLoader={IsLoaderUtm}>
                                        {formVal.edit ? 'Update' : 'Save'}
                                    </ButtonLoader>
                                </button>
                            </div>
                        </CreateUTM>}
                    </div>
                }
                {/* UTM Campaign Suffix */}
                {
                    deleteTemplate.suffix ? <div className="margin-top50 inline-block width100"><DeleteListTemplate title="Click on Confirm to delete this UTM campaign." content="This will permanently delete and it can not be restored." colSpan="5" onClick={() => setDeleteTemplate({ ...deleteTemplate, ...{ prefix: false, suffix: false, campaign: false } })} deleteDownload={() => deleteConfirm(null)} /></div> :
                        <div className="sms_Sec_1 white bx-boder-common width100">
                            <div className="_h_header">
                                <h3>UTM Campaign Suffix</h3>
                                <span className="flex-right">
                                    <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => {
                                        setToggle({ ...toggle, ...{ prefix: false, suffix: true, campaign: false } })
                                        setAPIResponse({});
                                        setFormVal({
                                            name: "",
                                            description: "",
                                            edit: false
                                        })
                                    }
                                    }>Create Suffix</button>
                                </span>
                            </div>
                            <table cellPadding="0" cellSpacing="0" className="smstable _prefix_table">
                                {suffixList.length > 0 &&
                                    <thead>
                                        <tr>
                                            <th className="table_cell_header">Suffix Name</th>
                                            <th className="table_cell_header">Description</th>
                                            <th className="table_cell_header">Creation Time</th>
                                            <th className="table_cell_header">Created By</th>
                                            <th className="table_cell_header">Actions</th>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {suffixList.length > 0 ? suffixList.map((x, i) => <tr key={i}>
                                        <td className="body_table_cell">{x['name']}</td>
                                        <td className="body_table_cell">{x['description']}</td>
                                        <td className="body_table_cell">{x['creationTime']}</td>
                                        <td className="body_table_cell">{x['createdBy']}</td>
                                        <td className="body_table_cell">
                                            <button className="link inline-block" type="button" onClick={() => editFn(x, 0)}>Edit</button><button type="button" className="link inline-block" onClick={() => deleteFN(x, 0)}>Delete</button></td></tr>) : <BlankData
                                        title="You have not created any UTM Template." content="Campaign Create UTM to easily measure and visualize the success of your campaigns and contacts build." colSpan="5" />}
                                </tbody>
                            </table>
                        </div>
                }
                {
                    toggle.suffix && <CreateUTM
                        Title="Suffix Name"
                        close={() => {
                            setToggle({
                                ...toggle, ...{
                                    prefix: false,
                                    suffix: false, campaign: false
                                }
                            });
                            setAPIResponse({})}} >
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Suffix Name</label>
                            <input type="text" className="inputformmodel width100" placeholder="Name" value={formVal.name} onChange={e => setFormVal({ ...formVal, ...{ name: e.target.value } })} />
                            {apiResponse.message && <p className="validation">{ apiResponse.message }</p>}
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Description  </label>
                            <input type="text" className="inputformmodel width100" placeholder="Optional" value={formVal.description} onChange={e => setFormVal({ ...formVal, ...{ description: e.target.value } })} />
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">
                                <strong>Suffix Name</strong>
                            </label>
                            <p>Lower case characters are set as default and hyphen is used for combining the words.
                            </p>
                        </div>
                        <div className="formmodel-bx text-right">
                            <button className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { setToggle({ ...toggle, ...{ prefix: false, suffix: false, campaign: false } }); setAPIResponse({});}} type="button">Cancel</button>
                            <button className="_clsbtn grid_btn_1 grid_btn_active" disabled={(formVal.name !== "") ? false : true} type="button" onClick={() => saveFn(null)} >
                                <ButtonLoader IsLoader={IsLoaderUtm}>
                                    {formVal.edit ? 'Update' : 'Save'}
                                </ButtonLoader>
                            </button>
                        </div>
                    </CreateUTM>
                }
            </div >
        </>
    )

}

