import { useCallback, useEffect, useState } from "react";
import Client from "../GlobalAPICall";
import { useSelector } from "react-redux";

const GrowthChannel = ({ ...props }) => {
    const { ListObject } = props;
    const { APITOKEN } = useSelector(state => state);
    const [growthObject, setPerGrowth] = useState({});
    const [total, setTotal] = useState(0);

    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/dashboard/SubscriberListGrowth/${ListObject.id}/PerChannel`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        if (res.data.length > 0) {
                            setPerGrowth(res.data[0]);
                            setTotal(countHits(res.data[0]));
                        } else {
                            setPerGrowth({});
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.log(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false);
        }
    }, [APITOKEN, ListObject]); // <-- add any missing dependencies react warns about
    const countHits = res => {
        const response = JSON.parse(res.CreationSourceKPI);
        console.log(response);
        
        const sumWithInitial = response.reduce(
            (accumulator, currentValue) => accumulator + currentValue.Count ,0
          );

        return sumWithInitial;




    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <div className="bx-boder-common perChannel-container width100 inline-block">
            <div className="_h_header relative">
                <h3>Growth Per Channel</h3>
            </div>

            <table cellPadding="0" cellSpacing="0" className="smstable width100" style={{ wordBreak: 'break-word' }}>
                <thead>
                    <tr>
                        {growthObject && JSON.parse(growthObject.CreationSourceKPI || '[]').map((v, i) => <th key={i} className="table_cell_header">{v['Name']}</th>)}
                        <th className="table_cell_header">Total</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {growthObject && JSON.parse(growthObject.CreationSourceKPI || '[]').map((v, i) => 
                            <td key={i} className="table_cell_header">{v.Count}</td>
                        )}
                        <td className="table_cell_header"><strong>{total}</strong></td>
                    </tr>
                </tbody>
            </table>
        </div>
    )

}

export default GrowthChannel
