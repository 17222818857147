import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import './index.css';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import WaterMarkRemove from "../../ReuseTemplate/WaterMarkRemove";
import { IndianTemplate } from "../../Create/Template/Trigger/SMS/IndianTemplate";
import { SenderName } from "../../Create/Template/Trigger/SMS/SenderName";
import { ShortURL } from "../../Create/Template/Trigger/SMS/ShortURL";
import { SMSContent } from "../../Create/Template/Trigger/SMS/SMSContent";
import { TemplateCampaign } from "../../Create/Template/Trigger/SMS/TemplateCampaign";
import { UnsubscribeContent } from "../../Create/Template/Trigger/SMS/UnsubscribeContent";
import { UnsubscribeOption } from "../../Create/Template/Trigger/SMS/UnsubscribeOption";
import { CheckIsUnicodeExists } from '../../ReuseTemplate/SMSCount';
import { getCursorPos } from '../../ReuseTemplate/GetCursorPoint';
import { MergeCodes } from '../../Create/Template/Trigger/SMS/MergeCodes';
import StepsDistribution from "../StepsTopBar";
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import SendTestContainer from "./SendTestContainer";
import SenderNameContainer from "./SenderNameContainer";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

function Reducer(state, action) {
    switch (action.type) {
        case 'STARTDATE':
            return { ...state, ...action.payload }
        default:
            return state
    }
}


export const SMSSend = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const [disabledIndianTemplate, setIndianTemplateDisabled] = useState(false);
    let [unsubscribeToggleVal, setUnsubscribeToggle] = useState(false);
    const defaultMessage = "Hi [firstname]! Here are this week's secret coupons. Based on your membership with us you also get a -20% discount. Best, Company";
    const CampaignType = getParameterByName('ctype', window.location.href)
    const CampaignId = getParameterByName('cam', window.location.href);
    let [messageCount, setMessageCount] = useState(0);
    const [senderNameReload, setSenderNameReload] = useState(Math.random());
    const [getTimeZone, setTimeZone] = useState({ data: [], selected: '', flag: false });
    const [smsSenderData, setSenderName] = useState([]);
    const [smsTemplateData, setTemplate] = useState([]);
    const [selectedCampaign, setFilterCampaign] = useState({});
    const [sehduleDropVal, setSehduleDropVal] = useState('now');
    const navigate = useNavigate();
    const [IsLoader, setIsLoader] = useState(false);
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    let [ctype, setCtype] = useState(false);

    const [shortCodeVal, setShortCodeVal] = useState({ first: '', last: '' });
    const [shortCodeShow, setShortCodeToggle] = useState(false);

    const [state, dispatch] = useReducer(Reducer, { StartDate: "" });

    const [senderNameProp, setSenderNameProperties] = useState({
        flag: false,
        newSenderInput: false,
        senderInputValue: ""
    });
    const [sendTestVal, setSendTestObject] = useState({
        flag: false,
        recipinentsTest: [],
        addnumber: '',
        addButton: true
    });

    const [selectedValue, setSelectedValue] = useState({
        smsContent: CampaignType !== null ? CampaignType : "",
        senderName: "",
        campaign: {
            Id: CampaignType !== null ?
                CampaignId : "",
            ContentTypeId: CampaignType !== null ?
                CampaignType : "",
        },
        smsText: defaultMessage,
        unsubscribeContent: "",
        unsubscribeOption: "",
        indianTemplateVal: "",
        campaignType: "1",
        shortURL: "",
        unsubscribeLink: "",
        SMSChartCount: defaultMessage.length
    });
    // === Message Count Method  === //
    const setStateCallback = useCallback((messageCount) => {
        const count = CheckIsUnicodeExists((messageCount), 0);
        setMessageCount(count);
    }, []);

    const CampaignName = useMemo(() => {
        const type = selectedValue.campaign?.ContentTypeId.toString() === "2" ?
            'Landing Page' : selectedValue.campaign?.ContentTypeId.toString() === "5" ?
                'Email' : selectedValue.campaign?.ContentTypeId.toString() === "4" ? 'Survey' : 'Workflow';
        return type;
    }, [selectedValue.campaign]);


    const [waterMarkshow, setWaterMarkRemove] = useState(false);
    const [sendNameArrayObject, setSenderNameArray] = useState([]);
    const [elementShow, setElementShow] = useState({
        smsContent: CampaignType !== null ? true : false,
        senderName: false,
        shorturl: true,
        unsubscribeContent: false
    });

    const checkSchduleSending = e => {
        const ev = e.target.value;
        if (ev !== 'now') {
            availableTimeZones();
        } else {
            setTimeZone(prv => ({ ...prv, data: [], flag: false }));
        }
        setSehduleDropVal(ev);
    }
    // AvailableTimeZones API Call //
    const availableTimeZones = useCallback(async () => {
        APITOKEN.token &&
            await Client.get('Lookup/AvailableTimeZones', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setTimeZone(prv => ({ ...prv, data: result.data, selected: APITOKEN.config.timeZoneOffset }));
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => console.error(err));
    }, [APITOKEN]);
    const defaultGUID = '00000000-0000-0000-0000-000000000000';
    // === SMS Distribution Edit API Call === //
    const templateEditSMS = useCallback(() => {
        (APITOKEN.token !== "") &&
            Client.get(`SMS/Distribution/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(async (res) => {
                try {
                    const data = res.data;

                    if (data.Content.typeId === 7) {
                        setElementShow({
                            smsContent: false,
                            senderName: true,
                            shorturl: false,
                            unsubscribeContent: data.Unsubscribe?.typeId === defaultGUID ? false : true
                        });

                    } else {
                        setElementShow({
                            smsContent: true,
                            senderName: true,
                            shorturl: true,
                            unsubscribeContent: data.Unsubscribe?.typeId === defaultGUID ? false : true
                        });
                    }
                    setIndianTemplateDisabled(APITOKEN.config.countryCode === "no"?false:true);

                    data.Unsubscribe === undefined ? setUnsubscribeToggle(false) : setUnsubscribeToggle(true);

                    setSelectedValue({
                        smsContent: data.Content.typeId,
                        senderName: data.SenderFrom,
                        campaign: data.Content.typeId === 7 ? { ContentTypeId: data.Content.typeId } : {
                            Name: data.Content.typeName,
                            Id: data.Content.typeDetailId,
                            PreviewUrl: data.Content.detail.previewUrl,
                            WaterMarkName: data.Content.detail.previewUrl,
                            ShortUrl: data.Content.detail.ShortUrl,
                            ContentTypeId: data.Content.typeId
                            , ...data.Content
                        },
                        smsText: data.Message,
                        unsubscribeContent: data.Unsubscribe === undefined ? "" : data.Unsubscribe?.id,
                        unsubscribeOption: data.Unsubscribe === undefined ? "" : data.Unsubscribe?.detail.id,
                        indianTemplateVal: APITOKEN.config.countryCode === "no" ? "null" : data.IndianSMSTemplateId,
                        campaignType: (data.ShortnerUrl === undefined) ? "" : data.ShortnerUrl.ShortnerUrlType,
                        shortURL: (data.ShortnerUrl === undefined) ? "" : data.ShortnerUrl?.ShortnerUrl,
                        unsubscribeLink: data.Unsubscribe?.typeShortUrl,
                        SMSChartCount: data.Message.length
                    });


                    if (data.ScheduleDateTime !== undefined) {
                        await availableTimeZones();
                        setSehduleDropVal('scheduleFor');
                        dispatch({ type: 'STARTDATE', payload: { StartDate: data.ScheduleDateTime.dateTimeFormattedAsString } });
                        await setTimeZone(prv => ({ ...prv, selected: data.ScheduleDateTime.offset }));
                    }
                    setStateCallback(`${data.Message} ${data.Content.typeDetailUrl} ${data.Unsubscribe?.typeShortUrl}`);

                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN, setStateCallback, id, availableTimeZones])
    const sendercontent = (e) => {
        if (e.target.value === '7') {
            setSelectedValue(prv => ({
                ...prv,
                smsContent: e.target.value,
                campaignType: '',
                shortURL: '',
                campaign: { ContentTypeId: e.target.value }

            }));
            setElementShow(prv => ({
                ...prv,
                shorturl: false,
                smsContent: false
            }));
        } else {
            setSelectedValue(prv => ({
                ...prv,
                smsContent: e.target.value,
                campaign: {
                    ContentTypeId: e.target.value,
                    Id: ""
                },
                campaignType: "1",
            }));
            setElementShow(prv => ({
                ...prv,
                smsContent: e.target.value === "" ? false : true,
                shorturl: true,
            }));
        }

    }
    const senderSenderName = (e) => {
        if (e.target.value === "") {
            setIndianTemplateDisabled(false);
            setSelectedValue(prv => ({
                ...prv,
                senderName: e.target.value,
                indianTemplateVal: "",
                smsText: defaultMessage
            }));
        } else {
            if (APITOKEN.config.countryCode !== "in") {
                setSelectedValue(prv => ({
                    ...prv,
                    indianTemplateVal: 'null',
                    senderName: e.target.value
                }));
            } else {
                setSelectedValue(prv => ({
                    ...prv,
                    senderName: e.target.value
                }));
            }
        }
        setElementShow(prv => ({
            ...prv,
            senderName: e.target.value === "" || e.target.value === '1' ? false : true
        }));


    }
    const campaignLink = v => {
        if (v.length === 0) {
            setSelectedValue(prv => ({
                ...prv,
                campaign: {
                    Id: "",
                    ContentTypeId: ""
                }
            }));
        } else {
            if (v[0]['HasWaterMark']) {
                setWaterMarkRemove(true);
                setElementShow(prv => ({
                    ...prv,
                    smsContent: true
                }));
            } else {
                setWaterMarkRemove(false);
            }
            setStateCallback(`${selectedValue.smsText} ${v[0].ShortUrl} ${!selectedValue.unsubscribeLink ? 0 : selectedValue.unsubscribeLink}`);
            setSelectedValue(prv => ({
                ...prv,
                campaign: v[0]
            }));
        }
    }
    // === Local Template Function ==== //
    const indianTemplate = v => {
        if (v.length !== 0) {
            const { smsText } = v[0];
            setSelectedValue(prv => ({
                ...prv,
                smsText: v.length === 0 ? defaultMessage : smsText,
                SMSChartCount: smsText.length,
                indianTemplateVal: v.length === 0 ? '' : v[0].templateId,
                campaign: {
                    ...prv.campaign,
                    Name: selectedValue.campaign.Name,
                }
            }));
            const booleanVal = (v.length !== 0) ? true : false;
            setIndianTemplateDisabled(booleanVal);
        } else {
            setSelectedValue(prv => ({
                ...prv,
                smsText: defaultMessage,
                SMSChartCount: defaultMessage.length,
                indianTemplateVal: ""
            }))
            setIndianTemplateDisabled(false);
        }
    }
    // === Unsubscribe Function ==== //
    const unsubscribeFN = (e) => {
        setSelectedValue(prv => ({
            ...prv,
            unsubscribeContent: e.target.value
        }));
        setElementShow(prv => ({
            ...prv,
            unsubscribeContent: e.target.value === "" ? false : true
        }));
    }
    //=== Post and Put Parameter Object ====//
    const createpostobject = () => {
        const obj = {
            "isDraft": true,
            "sendingBatchId": id,
            "sendername": selectedValue.senderName,
            "content": {
                isMessageEncrypted: false,
                "typeId": selectedValue.campaign.ContentTypeId,
                "indianSMSTemplateId": selectedValue.indianTemplateVal,
                "typeDetailId": (selectedValue.campaign.Id === undefined) ? null : selectedValue.campaign.Id,
                "sMSText": selectedValue.smsText,
            },
            "unsubscribe": {
                "typeId": (selectedValue.unsubscribeContent === "") ? "00000000-0000-0000-0000-000000000000" : selectedValue.unsubscribeContent,
                "typeDetailId": (selectedValue.unsubscribeOption === "") ? "00000000-0000-0000-0000-000000000000" : selectedValue.unsubscribeOption
            },
            isGeoLocationTrackingEnabled: false,

        };

        if (selectedValue.smsContent.toString() === "6") {
            delete obj["shortnerUrl"];
        } else {
            if (selectedValue.smsContent.toString() === "7") {
                delete obj['content'].typeDetailId;
                delete obj["shortnerUrl"];
            } else {
                obj["shortnerUrl"] = {
                    "shortnerUrl": (selectedValue.campaignType === "") ? "" : selectedValue.shortURL,
                    "shortnerUrlType": selectedValue.campaignType
                }
            }
        }
        if (getTimeZone.data.length === 0) {
            delete obj['scheduleDateTime']
        } else {
            obj['scheduleDateTime'] = {
                dateTime: state.StartDate,
                offset: getTimeZone.selected
            }
        }
        if (APITOKEN.config.countryCode === "no") {
            delete obj['content'].indianSMSTemplateId;
        }
        return obj;
    }
    // ===  SMS sending API Call === //
    const saveMethod = () => {
        const obj = createpostobject();
        setIsLoader(true);
        Client.put(`SMS/Send/Batch_step2/${id}`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                if (data.Success) {
                    (getParameterByName('edit', window.location.href) !== null) ?  navigate(`/SMS/SMSsummary/${id}`) : navigate(`/SMS/Goal/${id}`);
                    setIsLoader(true);
                }
            } catch (err) {
                console.log(err);
            }
        }).catch(err => console.error(err));
    }
    const closeWaterMarkPopup = (f) => {
        setWaterMarkRemove(f);
    }

    const reloadElement = () => {
        setSelectedValue(prv => ({
            ...prv,
            smsContent: selectedValue.smsContent,
            ...{
                campaign: {
                    ...selectedValue.campaign,
                    HasWaterMark: false,
                    Name: selectedValue.campaign.Name
                }
            }
        }));
        setElementShow({
            ...elementShow,
            ...{ smsContent: true }
        });
    }
    // const smsMessageFN = (e) => {
    //     setShortCodeToggle(true)
    //     const count = getCursorPos(e.target);
    //     const first = selectedValue.smsText.substring(0, count['start']);
    //     const last = selectedValue.smsText.substring(selectedValue.smsText.length, count['start']);
    //     setShortCodeVal({ first, last })
    // }

    // const shortCodeClick = (v) => {
    //     setSelectedValue(prv => ({ ...prv, smsText: `${shortCodeVal.first}${v.tag}${shortCodeVal.last}` }));
    // }

    const smsMessageFN = (e) => {
        setShortCodeToggle(true)
        const count = getCursorPos(e.target);
        setShortCodeVal({ count})
    }

    const shortCodeClick = (v) => {
        const first = selectedValue.smsText.substring(0, shortCodeVal.count['start']);
        const last = selectedValue.smsText.substring(selectedValue.smsText.length, shortCodeVal.count['start']);
        setSelectedValue(prv => ({ ...prv, smsText: `${first}${v.tag}${last}` }));
    }

    // Send Test Method //
    const sendTestSMS = () => {
        setSendTestObject(prv => ({ ...prv, flag: true }));
    }
    const closeShortCode = () => {
        setShortCodeToggle(false);
    }
    const senderNameFN = () => {
        setSenderNameProperties(prv => ({ ...prv, flag: true }))
    }

    const currentTimeAPIFn = ev => {
        setTimeZone(prv => ({ ...prv, selected: ev.target.value, flag: true }));
        Client.get(`utility/currenttime?timeZone=${ev.target.value}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    dispatch({ type: 'STARTDATE', payload: { StartDate: `${res.data.date} ${res.data.time} `, MinDate: `${res.data.date} ${res.data.time}` } });
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));

    }

    useEffect(() => {
        setStateCallback(defaultMessage);
        // ===  Campaign Watermark not removed condition === //
        if (CampaignType !== null && !ctype) {
            if (selectedCampaign?.HasWaterMark) {
                setCtype(true);
                setWaterMarkRemove(true);
                setElementShow(prv => ({
                    ...prv,
                    smsContent: true
                }));
                setSelectedValue(prv => ({
                    ...prv,
                    campaign: selectedCampaign

                }));
            }
            setSelectedValue(prv => ({
                ...prv,
                campaign: {
                    ...prv.campaign,
                    PreviewUrl: selectedCampaign.PreviewUrl,

                },
                // shortURL: selectedCampaign.PreviewUrl,

            }));
        }

        // === Edit case condition  === //
        if (getParameterByName('edit', window.location.href) !== null) {
            templateEditSMS();
        };
        // ===  Minimum Time Zone set for Calendar === //
        const data = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        dispatch({ type: 'STARTDATE', payload: { StartDate: data, MinDate: APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0] } });
        return (() => {
            !!selectedCampaign &&
                selectedCampaign.HasWaterMark &&
                setCtype(true);
        })

    }, [
        templateEditSMS,
        setStateCallback,
        APITOKEN.config,
        CampaignType,
        selectedCampaign,
        ctype
    ])
    return (
        <>
            <StepsDistribution Name="SMS" Active={2} />
            <div className="dashbd_sec margin-bottom">
                <div className="sms_Sec_1 bx-boder-common inline-block margin-bottom">
                    <div className="_h_header relative">
                        <h3>Send SMS</h3>
                        <span className="flex-right">
                            <button type="text" className="grid_btn_1" onClick={() => APITOKEN.config.countryCode === 'in' ? navigate('/create/templates/localSender?type=92') : senderNameFN()}>Sender Name</button>
                        </span>
                    </div>

                    <div className="bx-pad">
                        <SMSContent
                            ChangeEvent={sendercontent}
                            Selected={setSelectedValue}
                            Value={selectedValue} />

                        {elementShow.smsContent && <TemplateCampaign
                            Value={selectedValue}
                            ChangeEvent={campaignLink}
                            setTemplate={setTemplate}
                            smsTemplateData={smsTemplateData}
                            setFilterCampaign={setFilterCampaign}
                            CampaignId={CampaignId}
                        />}
                        <SenderName
                            ChangeEvent={senderSenderName}
                            Selected={setSelectedValue}
                            Value={selectedValue}
                            Array={setSenderNameArray}
                            SetSenderName={setSenderName}
                            SmsSenderData={smsSenderData}
                            ReloadVar={senderNameReload}
                        />
                        {elementShow.senderName && <IndianTemplate Value={selectedValue} ChangeEvent={indianTemplate} />}

                        {selectedValue.campaign.Id !== undefined &&
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Campaign Type</label>
                                <select
                                    className="inputformmodel"
                                    value={selectedValue.campaignType}
                                    onChange={(e) => { setElementShow({ ...elementShow, ...{ shorturl: (e.target.value === "1" || e.target.value === "") ? true : false } }); setSelectedValue({ ...selectedValue, ...{ campaignType: e.target.value } }) }}>
                                    <option value="">Select</option>
                                    <option value="1">With Unique URL</option>
                                    <option value="0">Without Unique URL</option>
                                </select>
                            </div>
                        }
                        {elementShow.shorturl && <ShortURL Value={selectedValue} Selected={setSelectedValue} />}
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">SMS Text</label>
                            <div className="drop-zone">
                                {shortCodeShow && <MergeCodes onClick={shortCodeClick} Value={selectedValue} CloseShortCode={closeShortCode} />}
                                <textarea className="inputformmodel textareaModel"
                                    value={selectedValue.smsText}
                                    disabled={disabledIndianTemplate}
                                    onClick={smsMessageFN}
                                    onChange={e => {
                                        setSelectedValue(prv => ({
                                            ...prv,
                                            smsText: e.target.value,
                                            SMSChartCount: e.target.value.length
                                        }));
                                        setStateCallback(e.target.value)
                                    }}>

                                </textarea>

                                <div className="smsCountInfor text-right">
                                    Total SMS characters : {selectedValue.SMSChartCount +
                                        (!selectedValue.campaign.ShortUrl ?
                                            0 :
                                            selectedValue.campaign.ShortUrl.length) +
                                        ((selectedValue.unsubscribeLink === '' || !selectedValue.unsubscribeLink) ?
                                            0 :
                                            selectedValue.unsubscribeLink.length)
                                    }
                                    <hr></hr>

                                    Number of SMS(s) : {messageCount}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="_h_header b_top">
                        <h3>Add Unsubscribe</h3>
                        <span className="flex-right">
                            <button
                                type="button"
                                className="grid_btn_1"
                                onClick={() => { setUnsubscribeToggle(!unsubscribeToggleVal); setElementShow(prv => ({ ...prv, unsubscribeContent: !prv.unsubscribeContent })) }}>{unsubscribeToggleVal ? 'Close' : 'Add'}</button>
                        </span>
                    </div>
                    {unsubscribeToggleVal && <div className="bx-pad">
                        <><UnsubscribeContent ChangeEvent={unsubscribeFN} Selected={setSelectedValue} Value={selectedValue} />
                            {(selectedValue.unsubscribeContent !== defaultGUID && selectedValue.unsubscribeContent !== "") && elementShow.unsubscribeContent && <UnsubscribeOption Selected={setSelectedValue} Value={selectedValue} />}
                        </>

                    </div>
                    }
                </div>
                <div className="previewPanel">
                    <div className="iphonePriview excludeClass padding20" style={{ boxSizing: 'border-box' }}>
                        {!!selectedValue.campaign.Id ? <iframe title="preview" src={selectedValue.campaign.PreviewUrl} className="noborder"></iframe> :
                            selectedValue.campaign.ContentTypeId === '7' ? <div className="smstxtphone margin-top50">{selectedValue.smsText}</div> :
                                <div className="previewDefaultMsg">
                                    Hi!
                                    <br></br>
                                    If you choose to add <span>SMS Content</span>
                                    to your SMS a preview will be displayed here.
                                </div>
                        }
                    </div>
                </div>
                <div className="sms_Sec_1 bx-boder-common inline-block drop-zone nomargin">
                    <div className="_h_header">
                        <h3>Send Options</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1 nobg" onClick={() => sendTestSMS()} disabled={
                                parseInt(selectedValue.smsContent) === 7 ? (sendNameArrayObject.length > 0 ? selectedValue.senderName !== "" ? selectedValue.indianTemplateVal !== "" ? true : false : false : true) && (selectedValue.campaignType === '' && selectedValue.shortURL === '')
                                    ? false : true :
                                    (selectedValue.smsContent !== "" &&
                                        (Object.keys(selectedValue.campaign).length === 0 ? false : selectedValue.campaign.HasWaterMark ? false : true) &&
                                        selectedValue.smsText !== "" &&
                                        (sendNameArrayObject.length > 0 ? selectedValue.senderName !== "" ? selectedValue.indianTemplateVal !== "" ? true : false : false : true) &&
                                        (selectedValue.campaignType === "" ? false : selectedValue.campaignType !== "1" ? true : selectedValue.shortURL !== '' ? true : false)
                                        ? false : true)
                            }>Send Test</button>
                        </span>
                    </div>
                    <div className="bx-pad">
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Schedule</label>
                            <select className="inputformmodel" value={sehduleDropVal} onChange={checkSchduleSending}>
                                <option value="now">Send Now</option>
                                <option value="scheduleFor">Schedule For</option>
                            </select>
                        </div>
                        {getTimeZone.data.length > 0 &&
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Schedule For</label>
                                <div className="relative width50" style={{ paddingRight: '20px' }}>
                                    <Datepicker className="inputformmodel" placeholder="Schedule date"
                                        min={state.MinDate}
                                        value={state.StartDate}
                                        // dataTimezone="utc"
                                        onChange={ev => dispatch({ type: 'STARTDATE', payload: { StartDate: ev.valueText } })}
                                        dateFormat="YYYY.MM.DD"
                                        touchUi={true}
                                        timeFormat="HH:mm"
                                        locale={APITOKEN.config.countryCode}
                                        theme="ios"
                                        themeVariant="dark"
                                        controls={['calendar', 'time']} />
                                </div>
                                <div className="relative width50">
                                    <select className="inputformmodel"
                                        value={getTimeZone.selected}
                                        onChange={e => currentTimeAPIFn(e)}
                                    >
                                        {getTimeZone.data.length > 0 && getTimeZone.data.map((v, i) => <option key={i} value={v.Id}>{v.Name}</option>)}
                                    </select>
                                </div>

                            </div>
                        }
                        {/* {JSON.stringify(Object.keys(selectedValue.campaign).length)} */}
                        <div className="formmodel-bx nomargin text-right">
                            <button type="button" className="grid_btn_1 grid_btn_active"
                                disabled={
                                    parseInt(selectedValue.smsContent) === 7 ? (sendNameArrayObject.length > 0 ?
                                        selectedValue.senderName !== "" ?
                                            selectedValue.indianTemplateVal !== "" ? true : false : false : true) &&
                                        (selectedValue.campaignType === '' && selectedValue.shortURL === '')
                                        ? false : true :
                                        (selectedValue.smsContent !== "" &&
                                            (Object.keys(selectedValue.campaign).length === 0 ?
                                                false : selectedValue.campaign.Id === "" || selectedValue.campaign.HasWaterMark ? false : true) &&
                                            selectedValue.smsText !== "" &&
                                            (sendNameArrayObject.length > 0 ?
                                                selectedValue.senderName !== "" ?
                                                    selectedValue.indianTemplateVal !== "" ?
                                                        true : false :
                                                    false : true) &&
                                            (selectedValue.campaignType === "" ?
                                                false : selectedValue.campaignType !== "1" ?
                                                    true : selectedValue.shortURL !== '' ?
                                                        true : false)
                                            ? false : true)
                                }
                                onClick={saveMethod}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    {getParameterByName('edit', window.location.href) !== null ? 'Update' : 'Continue'}
                                </ButtonLoader>

                            </button>

                        </div>
                    </div>
                </div>
                {sendTestVal.flag ? <SendTestContainer SetSendTestObject={setSendTestObject} SendTestVal={sendTestVal} SendObject={createpostobject} /> : <></>}
                {senderNameProp.flag ? <SenderNameContainer
                    SetSenderNameProperties={setSenderNameProperties}
                    SmsSenderData={smsSenderData}
                    SenderNameProp={senderNameProp}
                    Reload={setSenderNameReload}
                /> : <></>}
                {
                    waterMarkshow && <WaterMarkRemove
                        closeEvent={closeWaterMarkPopup}
                        StartDate={selectedValue.campaign.StartDate}
                        EndDate={selectedValue.campaign.EndDate}
                        CampaignData={selectedValue.campaign.WaterMarkName}
                        Data={selectedValue.campaign}
                        Id={selectedValue.campaign.Id}
                        Reload={reloadElement}
                        contentId={selectedValue.campaign.ContentTypeId}
                        setLowBalancePopup={setLowBalancePopup }
                        lowBlance={lowBlance}
                    >
                        <div>
                            <h3>Activate</h3>
                            <p>To Activate your {CampaignName} you need to set a Activation Period. Before you set the Activation Period a Watermark layer is disabling the Landing Page for interaction. You can still view the {CampaignName}.</p>
                        </div>
                        <div>
                            <h3>Activation Period</h3>
                            <p>In the Activation Period you visitors can interact with you {CampaignName} and you will find all registered activity under Insights.</p>
                        </div>
                        <div style={{ paddingBottom: '27px' }}>
                            <h3>Closing Time</h3>
                            <p>When the Closing Time is reached we will add a Watermark Text as a layer on top of your {CampaignName}, disabling it for interaction.</p>
                        </div>

                    </WaterMarkRemove>

                }
            </div >
        </>)

}

