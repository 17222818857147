

import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import Tabing from './Tabing';
import './index.css';
const LandingPageInsight = () => {
    const checkIsAuth = useSelector(state => state);
    const [tabData, setTab] = useState([]);
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('Insights/Categories/Landing_Page?queueId=00000000-0000-0000-0000-000000000000', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    
    return (
        <> 
        {/* <div className="smsHeader nomargin"> */}
            <div className="dashbd_sec">
                &nbsp;
            </div>
        {/* </div> */}
            <div className="dashbd_sec margin-top50">
                <Tabing  data={tabData} />
            </div></>
    )
}
export default LandingPageInsight;