import { SAVE_UPLOADED_CONTACTS, CREATE_IMPORT_SAVE_DATA } from '../actions/ActionImportContacts';

export function uploadedContact(state = null, { type, payload }) {
	switch (type) {
		case SAVE_UPLOADED_CONTACTS:
			return payload;

		default:
			return state;
	}
}

export function importContact(state = null, { type, payload }) {
	switch (type) {
		case CREATE_IMPORT_SAVE_DATA:
			console.log("CREATE_IMPORT_SAVE_DATA: ", payload);
			return payload;

		default:
			return state;
	}
}