import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";

const ForYou = () => {
    const checkIsAuth = useSelector(state => state);
    const [forYouData, setForYouData] = useState([]);
    const load = useCallback(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get(`Campaign/ForYou/notifications`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                try {
                    setForYouData(res['data']);
                } catch (err) {
                    console.log(err);
                }

            });
    }, [checkIsAuth])
    useEffect(() => {
        load();
    }, [load])
    return (
        <div className="foryou-content">
            {forYouData && forYouData.map((v, i) =>
                <div class="tab1list" key={i}><h2>{v.Title} <button type="button" class="graycolor fright delete"><i class="fal fa-times" style={{fontWeight: 500}}></i></button></h2><p><i className={v['LogoPath']} style={{ fontSize: '32px', marginRight: '15px', verticalAlign: 'top', display: (v['LogoPath'] === null) ? 'none' : 'inline-block' }}></i>{v['Description']}
                </p><div><button class="grid_btn_1 nobg margin-right15 vertical-middle kpifilterlink" disabled={(v['IsRead']) ? true : false}>{v['ButtonName']}</button></div></div>
            )}
        </div>
    )

}

export default ForYou
