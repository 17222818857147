import axios from "axios";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react"
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import { encode as btoa } from 'base-64'
import getParameterByName from "../ReuseTemplate/GetRoute";
import GridLoader from "../ReuseTemplate/GridLoader";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";
import BlankData from "../BlankData";
let isRecipientSeleced = [];
export const RecipientsPanel = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [loader, setLoader] = useState(false);
    let {
        SetRecipients,
        RecipientsList,
        recipientsObject,
        setRecipientsObject,
        isRecipients,
        setIsrecipients,
        countFetch
    } = props;
    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        const sendingType = getParameterByName('type', window.location.href);
        setLoader(true);
        APITOKEN.token &&
            Client.get(`${sendingType === '47' ? 'SMS' : sendingType === '95' ? 'Mobile' : 'Email'}/Contacts/Count?excludeCount=true`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((({ data }) => {
                    setLoader(false);
                    try {
                        SetRecipients(data);
                    } catch (err) {
                        console.error(err);
                    }
                })).catch(err => {
                    if (axios.isCancel(err)) {
                        console.log('cancel')
                    }
                }).catch(err => {
                    setLoader(false);
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN, SetRecipients]);


    const isRecipientsFilter = (recipients, singleRecipents) => {
        return recipients.filter(x => x.id !== singleRecipents.id);
    };

    const checkboxSelected = (x, e, parentId) => {
        if (x.type === 0) {
            for (var y of RecipientsList) {
                y['disabled'] = e.target.checked;
                const newon = !!y.segmentations && y.segmentations.map(x => ({ ...x, disabledSeg: e.target.checked }));
                y['segmentations'] = newon;

            };
            const index = _.findIndex(RecipientsList, function (o) { return o.id === x.id });
            RecipientsList[index].disabled = false;
            x.isSelected = (!e.target.checked) ? false : true;
            e.target.checked ? setRecipientsObject((prevs) => ({ ...prevs, ParentObject: [RecipientsList[index]] })) : setRecipientsObject((prevs) => ({ ...prevs, ParentObject: [] }));
            if (!!x.segmentations) {
                segmentationsFn(x, e, e.target.checked);
            }
            SetRecipients([...RecipientsList]);
        } else {
            isRecipientSeleced = isRecipients;
            isRecipientSeleced = (!e.target.checked) ? isRecipientSeleced.filter(y => y.id !== x.id) : [...isRecipientSeleced, ...[x]];
            // Start For Edit Condition //
            x.isSelected = (!e.target.checked) ? false : true;
            x.isAnySegmentSelected = (!e.target.checked) ? false : true;
            setIsrecipients(prv => (prv.filter(y => y.id !== x.id)));
            // End For Edit Condition //
            if (x.type === 1) {
                e.target.checked ? setRecipientsObject(prevs => ({
                    ...prevs,
                    ParentObject: [...prevs.ParentObject, x]
                })) : setRecipientsObject((prevs) => ({
                    ...prevs,
                    ParentObject: [...prevs.ParentObject.filter(y => y.id !== x.id)]
                }));
                if (!!x.segmentations) {
                    segmentationsFn(x, e, e.target.checked);
                }

            } else {
                x.isSelected = e.target.checked;
                if (!!x.ParentCheckboxSelected && x.ParentCheckboxSelected) {
                    x.exclude = true;
                    x.isSelected = true;
                }


                e.target.checked ? setRecipientsObject(prevs => ({
                    ...prevs,
                    NestedObject: [...prevs.NestedObject, x]
                })) : setRecipientsObject((prevs) => ({
                    ...prevs,
                    NestedObject: [...prevs.NestedObject.filter(y => y.id !== x.id)],
                    ExcludedObject: (!!x.ParentCheckboxSelected && x.ParentCheckboxSelected) ? [...prevs.ExcludedObject,...prevs.NestedObject.filter(y => y.id === x.id)] : [...prevs.ExcludedObject]
                }));
            }
           // SetRecipients([...RecipientsList]);
            const getAllContacts = RecipientsList[0];
            if (getAllContacts.type === 0) {
                if (x.parentType !== 0) {
                    RecipientsList[0].disabled = isRecipientSeleced.length > 0 ? true : false;
                    const firstRecipientSegments = !!getAllContacts.segmentations && getAllContacts.segmentations.map(x => ({ ...x, disabledSeg: isRecipientSeleced.length > 0? true :!e.target.checked ? false : true }));
                    RecipientsList[0].segmentations = firstRecipientSegments
                    SetRecipients([...RecipientsList]);    
                } else {
                    const restDisabledObject = RecipientsList.map(z => z.type > 0 ? ({ ...z, disabled: e.target.checked, segmentations: z.segmentations && z.segmentations.map(y => ({ ...y, disabledSeg: e.target.checked })) }) : { ...z });
                    SetRecipients([...restDisabledObject]);
                }
                
            }
        }
    }
    // Excluded Checkbox functionality // 

    const segmentationsFn = (x, e, flag) => {
        let newObjectexclude = {};
        for (let segment of x.segmentations) {
            segment['isSelected'] = e.target.checked;
            segment['ParentCheckboxSelected'] = flag;
            segment['disabledSeg'] = false;
            const index = _.findIndex(recipientsObject.NestedObject, function (o) { return o.id === segment.id });

            segment.exclude ? recipientsObject.NestedObject.filter(y => y.id !== x.id).map(x=> Object.assign(newObjectexclude,x)) : Object.assign(newObjectexclude,segment) ;
            delete segment['exclude'];
            setIsrecipients(prv => (prv.filter(y => y.id !== segment.id)));
            e.target.checked ? index === -1 &&
                setRecipientsObject((prevs) => ({
                    ...prevs,
                    NestedObject: [
                        ...prevs.NestedObject,
                        !segment.exclude && segment
                        ]
                })) :
                setRecipientsObject((prevs) => ({
                    ...prevs,
                    NestedObject: [...prevs.NestedObject.filter(y => y.id !== segment.id)],
                    ExcludedObject: [...prevs.ExcludedObject.filter(y => y.id !== segment.id)]
                }));
            isRecipientSeleced = !e.target.checked ? isRecipientsFilter(isRecipientSeleced, segment) : isRecipientSeleced;

        };
    }

    const excludeValueChange = (flag, ev) => {
        return RecipientsList.map(x => !x.segmentations ? { ...x } : {
            ...x, segmentations: x.segmentations.map(y => y.id === ev.id ? ({ ...y, exclude: flag }) : ({ ...y }))
        });
    }

    const checkExcludeInput = (recipients, flag, parentEv) => {
        if (parentEv.type === 0) {
            return recipients.map(z => z.type > 0 ? ({ ...z, disabled: flag, segmentations: z.segmentations && z.segmentations.map(y => ({ ...y, disabledSeg:flag })) }) : { ...z });
            
        } else {
            return recipients.map(x => ({ ...x, disabled: x.type === 0 ? flag : false,segmentations: x.segmentations && x.segmentations.map(y => ({ ...y, disabledSeg:x.type === 0 ? flag:false })) }));
        }
    }

    const excludeFn = (ev, prarentEv) => {
        let filterArray = null, excludePropsAdded = null, nestedArray = null, parentArray = null;
        if (!!ev.exclude) {
            nestedArray = recipientsObject['ExcludedObject'].filter(x => x.id === ev.id);
            filterArray = recipientsObject['ExcludedObject'].filter(x => x.id !== ev.id);
            setIsrecipients(nestedArray);
            setRecipientsObject(prvs => ({
                ...prvs,
                NestedObject: [...prvs.NestedObject, ...nestedArray],
                ExcludedObject: [...filterArray]
            }));
            excludePropsAdded = excludeValueChange(false, ev);
        } else {
            filterArray = recipientsObject['NestedObject'].filter(x => x.id === ev.id);
            nestedArray = recipientsObject['NestedObject'].filter(x => x.id !== ev.id);

            
            setIsrecipients(nestedArray);
            excludePropsAdded = excludeValueChange(true, ev);
            setRecipientsObject(prvs => ({
                ...prvs,
                NestedObject: [...nestedArray],
                ExcludedObject: [...prvs.ExcludedObject, ...filterArray]
            }));
        }
        parentArray = recipientsObject['ParentObject'].filter(x => x.id !== ev.id);
        if (nestedArray.length > 0 || parentArray.length > 0) {
            excludePropsAdded = checkExcludeInput(excludePropsAdded, true, prarentEv);
        } else {
            excludePropsAdded = checkExcludeInput(excludePropsAdded, false, prarentEv);
        }
        SetRecipients([...excludePropsAdded]);
    }



    useEffect(() => {
        load();
    }, [load])
    const crmSegment = (ev) => {
        window.location.replace(`${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&redurl=crm/people/subscribers/${ev.id}/1/segment/list`)
    }
    const recipientToggle = (v) => {
        v.display = !v.display;
        SetRecipients([...RecipientsList]);
    }
    return (
        <>
            {loader ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                <>
                    {RecipientsList.length === 0 ? <table cellPadding="0" cellSpacing="" width="100%"><tbody><BlankData title="You have not group yet." content="" colSpan="7" /></tbody></table> :
                        <table className="smstable" cellPadding="0" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th className="table_cell_header">Name</th>
                                    <th className="table_cell_header">Total Contacts</th>
                                    <th className="table_cell_header">Type</th>
                                    <th className="table_cell_header">Segment</th>
                                </tr>
                            </thead>
                            <tbody className="sms_dis">
                                {RecipientsList.map((v, i) => <tr key={i}>
                                    <td colSpan="4">
                                        <table width="100%" className="smstable" cellPadding="0" cellSpacing="0">
                                            <tbody>
                                                <tr>
                                                    <td className={`body_table_cell  ${v.segmentations !== undefined ? 'pointer' : ''} excludeClass`}>
                                                        {!!v.segmentations ? <i className={`fas ${v.display ? 'fa-angle-down' : 'fa-angle-right'} cellToggle defaultarrow`} onClick={() => recipientToggle(v)}></i> : <i className="defaultarrow"></i>}&nbsp;
                                                        <input type="checkbox"
                                                            value={v.id}
                                                            disabled={v.disabled}
                                                            checked={v.isSelected ? v.isSelected : false}
                                                            className="g-link"
                                                            onChange={e => checkboxSelected(v, e, v)} />
                                                        &nbsp;{v['name']}
                                                    </td>
                                                    <td className="body_table_cell">
                                                        {typeof v.recordCount === 'number' ?  <>{v.recordCount}</> : <button type="button" className="noborder nobg link" onClick={() => countFetch(v)}>
                                                            <ButtonLoader IsLoader={v.isLoader} color={true}>
                                                                Reachable Contacts
                                                            </ButtonLoader>
                                                        </button>}
                                                    </td>
                                                    <td className="body_table_cell">{v['typeText']}</td>
                                                    <td className="body_table_cell"><button
                                                        type="button"
                                                        className="link inline-block" onClick={() => crmSegment(v)}>Create Segment</button></td>
                                                </tr>

                                                {v.segmentations && <tr className="tr_nnested" style={{ display: v.display ? '' : 'none' }}>
                                                    <td colSpan="4">
                                                        <table className="smstable nested_table" cellPadding="0" cellSpacing="0">
                                                            <tbody>
                                                                {v.segmentations.map((x, index) => <tr key={index}>

                                                                    <td className="body_table_cell excludeClass">
                                                                        {/* {JSON.stringify(x.exclude)}
                                                                        {JSON.stringify(x.disabledSeg)} */}
                                                                        <input type="checkbox"
                                                                            value={x.id}
                                                                            disabled={x.exclude | x.disabledSeg}
                                                                            checked={x.isSelected ? x.isSelected : false}
                                                                            onChange={e => checkboxSelected(x, e, v)} className="g-link" /> &nbsp;<span className={`${x.exclude ? 'underline' : 'none'}`}>{x['name']}</span>
                                                                    </td>
                                                                    <td className="body_table_cell">
                                                                        {x['recordCount']}
                                                                    </td>
                                                                    <td className="body_table_cell">{x['typeText']}
                                                                        <button type="button" disabled={x.disabledSeg} className={`recipient_disabled ${x.isSelected ? 'excludedShow' : ''}`} onClick={() => excludeFn(x, v)}><i className="fal fa-minus-circle"></i>&nbsp;{x.exclude ? 'Excluded' : 'Exclude'} </button>
                                                                    </td>
                                                                    <td className="body_table_cell">&nbsp;</td>
                                                                </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>}
                                            </tbody>
                                        </table>
                                    </td>

                                </tr>)}
                            </tbody>
                        </table>
                    }
                </>
            }


{/*        
            ExcludedObject {JSON.stringify(recipientsObject.ExcludedObject)}  
            <br></br>
            <br></br>
            NestedObject  {JSON.stringify(recipientsObject.NestedObject)}
            <br></br>
            <br></br>
            
            ParentObject {JSON.stringify(recipientsObject.ParentObject)}  */}
            {/* {JSON.stringify(isRecipients)} */}
        </>
    )
}

