import axios from "axios";
import Client from "../../../../GlobalAPICall";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MergeCodes } from "../SMS/MergeCodes";
import { getCursorPos } from "../../../../ReuseTemplate/GetCursorPoint";
import { useNavigate } from "react-router";
import { CheckIsUnicodeExists } from "../../../../ReuseTemplate/SMSCount";
import getParameterByName from "../../../../ReuseTemplate/GetRoute";

const CreateTemplateLocalSender = () => {
    const { APITOKEN } = useSelector(state => state);
    const defaultMessage = "Hi [firstname]! Here are this week's secret coupons. Based on your membership with us you also get a -20% discount. Best, Company";
    const [createTemplateObject, setSenderNameGrid] = useState({
        SenderName: [],
        MessageType: [],
        Category: [],
        smsText: defaultMessage,
        SenderNameId: '',
        MessageTypeId: '',
        CategoryId: '',
        ContentTemplateId: '',
        ContentName: '',
        shortCode: []

    });
    let [messageCount, setMessageCount] = useState(defaultMessage);
    const navigate = useNavigate();
    const [shortCodeShow, setShortCodeToggle] = useState(false);
    const [shortCodeVal, setShortCodeVal] = useState({ first: '', last: '' });

    // ==== Get API For SMSHeaderValues Call ==== //

    const checkIfTeleMarketerIdExistsAPICall = useCallback(async () => {

        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            await Client.get(`Trigger/Templates/CheckIfTeleMarketerIdExists`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.success) {
                            const id = getParameterByName('id', window.location.href);
                            navigate(`/create/template/createTemplateSMS${id !== null?`?id=${id}`:''}`);
                        } else {
                            navigate(`/create/template/createSenderName`);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN, navigate]);
    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`Trigger/Templates/SMSHeaderValues`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setSenderNameGrid(prv => ({ ...prv, SenderName: res.data }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN]);
    // ==== Get API For MessageType Call ==== //
    const loadMessageType = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`Trigger/Templates/SMSSenderNameMessageType`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setSenderNameGrid(prv => ({ ...prv, MessageType: res.data }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN]);
    // ==== Get API For SMSCategories Call ==== //
    const loadCategory = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            Client.get(`Trigger/Templates/SMSCategories`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setSenderNameGrid(prv => ({ ...prv, Category: res.data }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN]);

    const shortCodeClick = (v) => {
        setSenderNameGrid(prv => ({ ...prv, smsText: `${shortCodeVal.first}${v.tag}${shortCodeVal.last}`, shortCode: [...prv.shortCode, v.tag] }));
    }
    const closeShortCode = () => {
        setShortCodeToggle(false);
    }
    const smsMessageFN = (e) => {
        setShortCodeToggle(true)
        const count = getCursorPos(e.target);
        const first = createTemplateObject.smsText.substring(0, count['start']);
        const last = createTemplateObject.smsText.substring(createTemplateObject.smsText.length, count['start']);
        setShortCodeVal({ first, last })
    }
    const abvar = (postcontent) => {
        for (let name of createTemplateObject.shortCode) {
            const index = postcontent.indexOf(name);
            if (index > -1) {
                postcontent = postcontent.replace(name, "{#var#}");

            }
        }
        return postcontent;
    }
    const SaveFn = () => {
        const varContent = abvar(createTemplateObject.smsText)
        const obj = {
            "smsContentTemplateId": 0,
            "messageType": parseInt(createTemplateObject.MessageTypeId),
            "categoryId": parseInt(createTemplateObject.CategoryId),
            "headerId": createTemplateObject.SenderNameId,
            "consentId": null,
            "templateName": createTemplateObject.ContentName,
            "templateUserMessage": createTemplateObject.smsText,
            "templateRequestMessage": `${varContent}{#var#}{#var#}{#var#}{#var#}`,
            "gatewayTemplateId": createTemplateObject.ContentTemplateId
        };
        if (getParameterByName('id', window.location.href) !== null) {
            obj['smsContentTemplateId'] = getParameterByName('id', window.location.href)
            PutAPICall(obj);
            
        } else {
            delete obj['smsContentTemplateId'];
            PostAPICall(obj)
        }
    }
    // ==== Put API Call ==== //
    const PutAPICall = (obj) => {
        Client.put(`Trigger/Templates/SMSContentTemplate`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.success) {
                        navigate(`/create/templates/localSender?type=92`);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }
    // ==== Post API Call ==== //
    const PostAPICall = (obj) => {
        Client.post(`Trigger/Templates/SMSContentTemplate`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.success) {
                        navigate(`/create/templates/localSender?type=92`);
                    }
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }


    const setStateCallback = useCallback((messageCount) => {
        const count = CheckIsUnicodeExists((messageCount), 0)
        setMessageCount(count);
    }, []);
    // ==== Get API For Edit Call ==== //
    const editFetchData = useCallback(() => {
        APITOKEN.token &&
            Client.get(`Trigger/Templates/SMSIndianContentTemplates?templateid=${getParameterByName('id', window.location.href)}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        const { categoryId, messageType, headerId, templateUserMessage, templateName, gatewayTemplateId } = res.data[0];
                        setSenderNameGrid(prv => ({
                            ...prv,
                            CategoryId: categoryId,
                            MessageTypeId: messageType,
                            SenderNameId: headerId,
                            smsText: templateUserMessage,
                            ContentName: templateName,
                            ContentTemplateId: gatewayTemplateId,
                        }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {

                })

    }, [APITOKEN])

    useEffect(() => {
        load();
        loadMessageType();
        loadCategory();
        setStateCallback(defaultMessage);
        checkIfTeleMarketerIdExistsAPICall()

        if (getParameterByName('id', window.location.href) !== null) {
            editFetchData()
        }

    }, [load, loadMessageType, loadCategory, setStateCallback, editFetchData,checkIfTeleMarketerIdExistsAPICall])
    return (
        <div className="dashbd_sec">
            <div className="sms_Sec_1 white bx-boder-common width100  margin-bottom">
                <div className="_h_header">
                    <h3>Please fill in your approved SMS Content Template values.</h3>
                </div>
                <div className="padding20">
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Sender Name</label>
                        <select className="inputformmodel width100" value={createTemplateObject.SenderNameId} onChange={e => setSenderNameGrid(prv => ({ ...prv, SenderNameId: e.target.value }))}>
                            <option value="">Select</option>
                            {createTemplateObject.SenderName.map((v, i) => <option key={i} value={v.id}>{v.headerName}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Message Type</label>
                        <select className="inputformmodel width100" value={createTemplateObject.MessageTypeId} onChange={e => setSenderNameGrid(prv => ({ ...prv, MessageTypeId: e.target.value }))}>
                            <option value="">Select</option>
                            {createTemplateObject.MessageType.map((v, i) => <option key={i} value={v.id}>{v.templateType}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Category</label>
                        <select className="inputformmodel width100" value={createTemplateObject.CategoryId} onChange={e => setSenderNameGrid(prv => ({ ...prv, CategoryId: e.target.value }))}>
                            <option value="">Select</option>
                            {createTemplateObject.Category.map((v, i) => <option key={i} value={v.id}>{v.categoryName}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Content Template ID</label>
                        <input className="inputformmodel width100" placeholder="Content Template ID" value={createTemplateObject.ContentTemplateId} onChange={e => setSenderNameGrid(prv => ({ ...prv, ContentTemplateId: e.target.value }))} />
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Content Name (Template Name)</label>
                        <input className="inputformmodel width100" placeholder="Content Name" value={createTemplateObject.ContentName} onChange={e => setSenderNameGrid(prv => ({ ...prv, ContentName: e.target.value }))} />
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">SMS Content Template Text</label>
                        <div className="drop-zone">
                            {shortCodeShow && <MergeCodes onClick={shortCodeClick} Value={createTemplateObject} CloseShortCode={closeShortCode} />}

                            <textarea className="inputformmodel textareaModel"
                                value={createTemplateObject.smsText}
                                onClick={smsMessageFN}
                                onChange={e => { setSenderNameGrid(prv => ({ ...prv, smsText: e.target.value })); setStateCallback(e.target.value) }}
                            >
                            </textarea>
                            <p className="smsCountInfor text-right">

                                Total SMS characters : {createTemplateObject.smsText.length}
                                <br></br>
                                Number of SMS(s) : {messageCount}
                            </p>
                        </div>
                    </div>
                    <div className="text-right">
                        <button type="button" className="grid_btn_1 nobg noborder _no_cls_delete margin-right10" onClick={() => navigate(`/create/templates/localSender?type=92`)} >Cancel</button>
                        <button type="button" disabled={
                            (createTemplateObject.SenderNameId !== "" && createTemplateObject.MessageTypeId !== "" && createTemplateObject.CategoryId !== "" && createTemplateObject.ContentTemplateId !== "" && createTemplateObject.ContentName !== "") ? false : true

                        } className="grid_btn_1 grid_btn_active save_Btn  margin-right10" onClick={SaveFn}>
                            {(getParameterByName('id', window.location.href) !== null) ? 'Update' : 'Continue'}</button>

                    </div>
                </div>
            </div>
        </div>
    )

}

export default CreateTemplateLocalSender
