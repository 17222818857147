import React, { useEffect, useState, useRef, Fragment } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { ChromePicker } from 'react-color';
import { Carousel } from 'react-responsive-carousel';
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';
import iPhoneHeader from './../../../../assets/img/iphone-header.png';
import iPhoneFooter from './../../../../assets/img/iphone-footer.png';
import iconCall from './../../../../assets/img/icon-call.svg';
import iconEmail from './../../../../assets/img/icon-email.svg';
import iconDirection from './../../../../assets/img/icon-direction.svg';
import iconWebsite from './../../../../assets/img/icon-website.svg';

import { createStore, fetchCountries } from "../../../../services/store";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ButtonFunctions = ({ storeDetails, fetchStoreData }) => {
	const existingPhoneNumber = _get(storeDetails, "storeButtons.phoneNumber", "");
	let partsPhoneNumber = [];
	if (existingPhoneNumber) {
		partsPhoneNumber = existingPhoneNumber.replace('+', '').split(' ');
	}

	const existingSinpeMovilPaymentPhoneNumber = _get(storeDetails, "storeButtons.sinpeMovilPaymentPhoneNumber", "");
	let partsSinpeMovilPaymentPhoneNumber = [];
	if (existingSinpeMovilPaymentPhoneNumber) {
		partsSinpeMovilPaymentPhoneNumber = existingSinpeMovilPaymentPhoneNumber.replace('+', '').split(' ');
	}

	const [formData, setFormData] = useState({
		isRatingVisible: _get(storeDetails, "storeButtons.isRatingVisible", false),
		ratingColor: _get(storeDetails, "storeButtons.ratingColor", ""),
		isPhoneNumberVisible: _get(storeDetails, "storeButtons.isPhoneNumberVisible", false),
		phoneNumber: {
			countryCode: (partsPhoneNumber.length === 2) ? partsPhoneNumber[0] : "",
			number: (partsPhoneNumber.length === 2) ? partsPhoneNumber[1] : ""
		},
		isSinpeMovilPaymentPhoneNumberVisible: _get(storeDetails, "storeButtons.isSinpeMovilPaymentPhoneNumberVisible", false),
		sinpeMovilPaymentPhoneNumber: {
			countryCode: (partsSinpeMovilPaymentPhoneNumber.length === 2) ? partsSinpeMovilPaymentPhoneNumber[0] : "",
			number: (partsSinpeMovilPaymentPhoneNumber.length === 2) ? partsSinpeMovilPaymentPhoneNumber[1] : ""
		},
		isEmailAddressVisible: _get(storeDetails, "storeButtons.isEmailAddressVisible", false),
		emailAddress: _get(storeDetails, "storeButtons.emailAddress", ""),
		isDirectionDisplayVisible: _get(storeDetails, "storeButtons.isDirectionDisplayVisible", false),
		address: _get(storeDetails, "storeButtons.address", ""),
		city: _get(storeDetails, "storeButtons.city", ""),
		zip: _get(storeDetails, "storeButtons.zip", ""),
		state: _get(storeDetails, "storeButtons.state", ""),
		municipal: _get(storeDetails, "storeButtons.municipal", ""),
		isoCountryCode: _get(storeDetails, "storeButtons.isoCountryCode", ""),
		country: _get(storeDetails, "storeButtons.country", ""),
		isDisplayWebSiteVisible: _get(storeDetails, "storeButtons.isDisplayWebSiteVisible", false),
		webSite: _get(storeDetails, "storeButtons.webSite", "")
	});

	const [validationErrors, setValidationErrors] = useState({
		phoneNumber: false,
		sinpeMovilPaymentPhoneNumber: false,
		emailAddress: false,
		webSite: false,
		address: false,
		city: false,
		zip: false,
		state: false,
		country: false,
	});
	const [formValid, setFormValid] = useState(false);
	const [showColorPicker, setShowColorPicker] = useState(false);
	const [countries, setCountries] = useState([]);
	const colorPickerRef = useRef(null);
	const [isLoader, setIsLoader] = useState(false);

	// If Props changed from, re-populate values in fields
	useEffect(() => {
		const existingPhoneNumber = _get(storeDetails, "storeButtons.phoneNumber", "");
		let partsPhoneNumber = [];
		if (existingPhoneNumber) {
			partsPhoneNumber = existingPhoneNumber.replace('+', '').split(' ');
		}

		const existingSinpeMovilPaymentPhoneNumber = _get(storeDetails, "storeButtons.sinpeMovilPaymentPhoneNumber", "");
		let partsSinpeMovilPaymentPhoneNumber = [];
		if (existingSinpeMovilPaymentPhoneNumber) {
			partsSinpeMovilPaymentPhoneNumber = existingSinpeMovilPaymentPhoneNumber.replace('+', '').split(' ');
		}

		setFormData({
			isRatingVisible: _get(storeDetails, "storeButtons.isRatingVisible", false),
			ratingColor: _get(storeDetails, "storeButtons.ratingColor", ""),
			isPhoneNumberVisible: _get(storeDetails, "storeButtons.isPhoneNumberVisible", false),
			phoneNumber: {
				countryCode: (partsPhoneNumber.length === 2) ? partsPhoneNumber[0] : "",
				number: (partsPhoneNumber.length === 2) ? partsPhoneNumber[1] : ""
			},
			isSinpeMovilPaymentPhoneNumberVisible: _get(storeDetails, "storeButtons.isSinpeMovilPaymentPhoneNumberVisible", false),
			sinpeMovilPaymentPhoneNumber: {
				countryCode: (partsSinpeMovilPaymentPhoneNumber.length === 2) ? partsSinpeMovilPaymentPhoneNumber[0] : "",
				number: (partsSinpeMovilPaymentPhoneNumber.length === 2) ? partsSinpeMovilPaymentPhoneNumber[1] : ""
			},
			isEmailAddressVisible: _get(storeDetails, "storeButtons.isEmailAddressVisible", false),
			emailAddress: _get(storeDetails, "storeButtons.emailAddress", ""),
			isDirectionDisplayVisible: _get(storeDetails, "storeButtons.isDirectionDisplayVisible", false),
			address: _get(storeDetails, "storeButtons.address", ""),
			city: _get(storeDetails, "storeButtons.city", ""),
			zip: _get(storeDetails, "storeButtons.zip", ""),
			state: _get(storeDetails, "storeButtons.state", ""),
			municipal: _get(storeDetails, "storeButtons.municipal", ""),
			isoCountryCode: _get(storeDetails, "storeButtons.isoCountryCode", ""),
			country: _get(storeDetails, "storeButtons.country", ""),
			isDisplayWebSiteVisible: _get(storeDetails, "storeButtons.isDisplayWebSiteVisible", false),
			webSite: _get(storeDetails, "storeButtons.webSite", "")
		});
	}, [storeDetails]);

	// On Page Refresh > Fetch Countries
	useEffect(() => {
		(async () => {
			try {
				const response = await fetchCountries();
				setCountries(_get(response, "data", []));
			} catch (err) {
				console.log('Error occurred when fetching categories', err);
			}
		})();
	}, []);

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value
		}));
	};

	const handlePhoneInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			phoneNumber: {
				...prevData.phoneNumber,
				[name]: value
			}
		}));
	};

	const handleSnipeMovilPhoneInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			sinpeMovilPaymentPhoneNumber: {
				...prevData.sinpeMovilPaymentPhoneNumber,
				[name]: value
			}
		}));
	};

	const handleRadioChange = (e) => {
		const { name, value } = e.target;
		const updatedFormData = {
			...formData,
			[name]: value === 'yes'
		};
		if (name === 'isPhoneNumberVisible' && value === 'no') {
			updatedFormData.phoneNumber = { countryCode: '', number: '' };
		}
		if (name === 'isSinpeMovilPaymentPhoneNumberVisible' && value === 'no') {
			updatedFormData.sinpeMovilPaymentPhoneNumber = { countryCode: '', number: '' };
		}
		if (name === 'isDirectionDisplayVisible' && value === 'no') {
			// Reset direction-related fields when direction display is set to No
			updatedFormData.address = '';
			updatedFormData.city = '';
			updatedFormData.zip = '';
			updatedFormData.state = '';
			updatedFormData.municipal = '';
			updatedFormData.country = '';
			setValidationErrors((prevErrors) => ({
				...prevErrors,
				address: false,
				city: false,
				zip: false,
				state: false,
				country: false,
			}));
		}
		setFormData(updatedFormData);
	};

	const handleRatingColorChange = (color) => {
		setFormData((prevData) => ({
			...prevData,
			ratingColor: color.hex
		}));
	};

	useEffect(() => {
		validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formData]);

	const validateForm = () => {

		let isValid = true;
		const errors = {};

		// Validate rating color if visible
		if (formData.isRatingVisible === true && !formData.ratingColor) {
			errors.ratingColor = 'Rating color is required.';
			isValid = false;
		}

		// Validate phone number fields if visible
		if (formData.isPhoneNumberVisible && (!formData.phoneNumber.countryCode || !formData.phoneNumber.number)) {
			errors.phoneNumber = true;
			isValid = false;
		}

		// Validate snipe movil phone number fields if visible
		if (formData.isSinpeMovilPaymentPhoneNumberVisible && (!formData.sinpeMovilPaymentPhoneNumber.countryCode || !formData.sinpeMovilPaymentPhoneNumber.number)) {
			errors.sinpeMovilPaymentPhoneNumber = true;
			isValid = false;
		}

		// Validate email address if visible
		if (formData.isEmailAddressVisible && !isValidEmail(formData.emailAddress)) {
			errors.emailAddress = true;
			isValid = false;
		}

		// Validate website URL if visible
		if (formData.isDisplayWebSiteVisible && !isValidUrl(formData.webSite)) {
			errors.webSite = true;
			isValid = false;
		}

		// Validate direction-related fields if display directions is Yes
		if (formData.isDirectionDisplayVisible && formData.address === '') {
			errors.address = true;
			isValid = false;
		}
		if (formData.isDirectionDisplayVisible && formData.city === '') {
			errors.city = true;
			isValid = false;
		}
		if (formData.isDirectionDisplayVisible && formData.zip === '') {
			errors.zip = true;
			isValid = false;
		}
		if (formData.isDirectionDisplayVisible && formData.state === '') {
			errors.state = true;
			isValid = false;
		}
		if (formData.isDirectionDisplayVisible && formData.country === '') {
			errors.country = true;
			isValid = false;
		}

		setValidationErrors(errors);
		setFormValid(isValid);
		return isValid;
	}

	useEffect(() => {
		const handleClickOutside = (event) => {

			if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
				setShowColorPicker(false);
			}
		};

		if (showColorPicker) {
			document.addEventListener('mousedown', handleClickOutside);
		} else {
			document.removeEventListener('mousedown', handleClickOutside);
		}

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [showColorPicker]);

	const handleContinue = () => {
		(async () => {
			try {
				if (formValid) {
					// Combine phone number parts into a single string
					const combinedPhoneNumber = (formData.phoneNumber.countryCode && formData.phoneNumber.number) ? `+${formData.phoneNumber.countryCode} ${formData.phoneNumber.number}` : "";
					const combinedSinpeMovilPaymentPhoneNumber = (formData.sinpeMovilPaymentPhoneNumber.countryCode && formData.sinpeMovilPaymentPhoneNumber.number) ? `+${formData.sinpeMovilPaymentPhoneNumber.countryCode} ${formData.sinpeMovilPaymentPhoneNumber.number}` : "";

					// Add additional processing or validation as needed before sending data
					const formValues = {
						...formData,
						phoneNumber: combinedPhoneNumber,
						sinpeMovilPaymentPhoneNumber: combinedSinpeMovilPaymentPhoneNumber,
					};

					setIsLoader(true);

					const mergedData = {
						"storeId": _get(storeDetails, "storeId", ""),
						"storeButtons": formValues,
					};

					const response = await createStore(mergedData);
					setIsLoader(false);
					if (_get(response, "flag", false) === false) {

					} else {
						fetchStoreData(true);
					}
				}
			} catch (err) {
				console.log('Error occured when submitting data', err);
			}
		})();
	};

	const isValidEmail = (email) => {
		// Simple email validation using regex
		return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
	};

	const isValidUrl = (url) => {
		try {
			new URL(url);
			return true;
		} catch (error) {
			return false;
		}
	};

	return (
		<>
			<div className="bx-boder-common width100 nobg mt-8">
				<div className="_h_header white border-radius2">
					<h3>Button functions</h3>
				</div>

				<div className="p-5 white right-appscreenshot-height">
					<div className="row">
						<div className="col-8">

							<div className="formmodel-bx">
								<label className="_l_able_cls">Display rating</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="yes">
										<input
											type="radio"
											className="inline-block me-2"
											id="yes"
											name="isRatingVisible"
											value="yes"
											checked={formData.isRatingVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="no">
										<input
											type="radio"
											className="inline-block me-2"
											id="no"
											name="isRatingVisible"
											value="no"
											checked={!formData.isRatingVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isRatingVisible === true && (
								<div className="formmodel-bx ps-5">
									<label className="_l_able_cls">Rating color</label>
									<div className="position-relative">
										<input
											type="text"
											className={`inputformmodel inline-block width100 ${validationErrors.ratingColor ? 'border-red' : ''}`}
											value={formData.ratingColor}
											readOnly
											onClick={() => setShowColorPicker(!showColorPicker)}
										/>
										{showColorPicker && (
											<div className="color-picker-store-popup" ref={colorPickerRef}>
												<ChromePicker
													color={formData.ratingColor}
													onChangeComplete={handleRatingColorChange}
												/>
											</div>
										)}
									</div>
								</div>
							)}

							<div className="formmodel-bx">
								<label className="_l_able_cls mb-3">Display phone number button</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="phoneYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="phoneYes"
											name="isPhoneNumberVisible"
											value="yes"
											checked={formData.isPhoneNumberVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="phoneNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="phoneNo"
											name="isPhoneNumberVisible"
											value="no"
											checked={!formData.isPhoneNumberVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isPhoneNumberVisible && (
								<div className="formmodel-bx ps-5">
									<label className="_l_able_cls">Phone number <span className="error-message">*</span></label>
									<div className="flex align-items-center gap-2">
										<input
											type="text"
											className={`inputformmodel inline-block wauto min-width-inherit ${validationErrors.phoneNumber ? 'border-red' : ''}`}
											name="countryCode"
											value={formData.phoneNumber.countryCode}
											onChange={(e) => handlePhoneInputChange(e)}
											style={{ width: '52px' }}
										/>

										<input
											type="number"
											className={`inputformmodel inline-block width100 ${validationErrors.phoneNumber ? 'border-red' : ''}`}
											name="number"
											value={formData.phoneNumber.number}
											onChange={(e) => handlePhoneInputChange(e)}
										/>
									</div>
								</div>
							)}

							<div className="formmodel-bx">
								<label className="_l_able_cls mb-3">Display snipe movil payment phone number button</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="sinpeMovilYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="sinpeMovilYes"
											name="isSinpeMovilPaymentPhoneNumberVisible"
											value="yes"
											checked={formData.isSinpeMovilPaymentPhoneNumberVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="sinpeMovilNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="sinpeMovilNo"
											name="isSinpeMovilPaymentPhoneNumberVisible"
											value="no"
											checked={!formData.isSinpeMovilPaymentPhoneNumberVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isSinpeMovilPaymentPhoneNumberVisible && (
								<div className="formmodel-bx ps-5">
									<label className="_l_able_cls">Phone number <span className="error-message">*</span></label>
									<div className="flex align-items-center gap-2">
										<input
											type="number"
											className={`inputformmodel inline-block wauto min-width-inherit ${validationErrors.sinpeMovilPaymentPhoneNumber ? 'border-red' : ''}`}
											name="countryCode"
											value={formData.sinpeMovilPaymentPhoneNumber.countryCode}
											onChange={(e) => handleSnipeMovilPhoneInputChange(e)}
											style={{ width: '52px' }}
										/>

										<input
											type="number"
											className={`inputformmodel inline-block width100 ${validationErrors.sinpeMovilPaymentPhoneNumber ? 'border-red' : ''}`}
											name="number"
											value={formData.sinpeMovilPaymentPhoneNumber.number}
											onChange={(e) => handleSnipeMovilPhoneInputChange(e)}
										/>
									</div>
								</div>
							)}

							<div className="formmodel-bx">
								<label className="_l_able_cls mb-3">Display email button</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="emailYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="emailYes"
											name="isEmailAddressVisible"
											value="yes"
											checked={formData.isEmailAddressVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="emailNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="emailNo"
											name="isEmailAddressVisible"
											value="no"
											checked={!formData.isEmailAddressVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isEmailAddressVisible && (
								<div className="formmodel-bx ps-5">
									<label className="_l_able_cls">Email <span className="error-message">*</span></label>
									<input
										type="email"
										className={`inputformmodel inline-block width100 ${validationErrors.emailAddress ? 'border-red' : ''}`}
										name="emailAddress"
										value={formData.emailAddress}
										onChange={(e) => handleInputChange(e)}
									/>
								</div>
							)}

							<div className="formmodel-bx">
								<label className="_l_able_cls mb-3">Display directions button</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="directionsYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="directionsYes"
											name="isDirectionDisplayVisible"
											value="yes"
											checked={formData.isDirectionDisplayVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="directionsNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="directionsNo"
											name="isDirectionDisplayVisible"
											value="no"
											checked={!formData.isDirectionDisplayVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isDirectionDisplayVisible && (
								<>
									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">Address <span className="error-message">*</span></label>
										<input
											type="text"
											className={`inputformmodel inline-block width100 ${validationErrors.address && !formData.address ? 'border-red' : ''}`}
											name="address"
											value={formData.address}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">City <span className="error-message">*</span></label>
										<input
											type="text"
											className={`inputformmodel inline-block width100 ${validationErrors.city && !formData.city ? 'border-red' : ''}`}
											name="city"
											value={formData.city}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">Zip <span className="error-message">*</span></label>
										<input
											type="text"
											className={`inputformmodel inline-block width100 ${validationErrors.zip && !formData.zip ? 'border-red' : ''}`}
											name="zip"
											value={formData.zip}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">State <span className="error-message">*</span></label>
										<input
											type="text"
											className={`inputformmodel inline-block width100 ${validationErrors.state && !formData.state ? 'border-red' : ''}`}
											name="state"
											value={formData.state}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">Municipal</label>
										<input
											type="text"
											className="inputformmodel inline-block width100"
											name="municipal"
											value={formData.municipal}
											onChange={(e) => handleInputChange(e)}
										/>
									</div>

									<div className="formmodel-bx ps-5">
										<label className="_l_able_cls">Country <span className="error-message">*</span></label>
										<select
											className={`inputformmodel width100 ${validationErrors.country && !formData.country ? 'border-red' : ''}`}
											value={`${formData.isoCountryCode},${formData.country}`}
											onChange={(e) => setFormData((prevData) => ({
												...prevData,
												isoCountryCode: (_get(e, "target.value", "") !== "") ? (_get(e, "target.value", "")).split(",")[0] : "",
												country: (_get(e, "target.value", "") !== "") ? (_get(e, "target.value", "")).split(",")[1] : ""
											}))}
										>
											<option value="">Select Country</option>
											{!_isEmpty(countries || []) && (countries || []).map((category, i) => (
												<option key={i} value={`${_get(category, "isOcountrycode", "")},${_get(category, "countryname", "")}`}>{_get(category, "countryname", "")}</option>
											))}
										</select>
									</div>
								</>
							)}

							<div className="formmodel-bx">
								<label className="_l_able_cls mb-3">Display website button</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="websiteYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="websiteYes"
											name="isDisplayWebSiteVisible"
											value="yes"
											checked={formData.isDisplayWebSiteVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										Yes
									</label>

									<label htmlFor="websiteNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="websiteNo"
											name="isDisplayWebSiteVisible"
											value="no"
											checked={!formData.isDisplayWebSiteVisible}
											onChange={(e) => handleRadioChange(e)}
										/>
										No
									</label>
								</div>
							</div>

							{formData.isDisplayWebSiteVisible && (
								<div className="formmodel-bx ps-5">
									<label className="_l_able_cls">Website <span className="error-message">*</span></label>
									<input
										type="text"
										className={`inputformmodel inline-block width100 ${validationErrors.webSite ? 'border-red' : ''}`}
										name="webSite"
										value={formData.webSite}
										onChange={(e) => handleInputChange(e)}
									/>
								</div>
							)}

							<div className="formmodel-bx text-right mt-12 mb-0">
								<button
									type="button"
									className={`btn btn-primary btn-gape-15 ${formValid ? '' : 'btn-disabled'}`}
									onClick={() => handleContinue({})}
								>
									<ButtonLoader IsLoader={isLoader}>
										Save
									</ButtonLoader>
								</button>
							</div>
						</div>

						<div className="col-4">
							<div className="right-appscreenshot mt-6 ms-auto me-auto">
								<div className="right-appscreenshot-banner">
									<div className="overflow-hidden">
										<img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
										<img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

										<p className="right-appscreenshot-banner-header-text">{(storeDetails.storeName || "")}</p>

										<div className="right-appscreenshot-storeimage">
											<Carousel showThumbs={false} swipeable={true} interval={2000} autoPlay infiniteLoop>
												{(_get(storeDetails, "image", []).length > 0) && _get(storeDetails, "image", []).map((image, i) => (
													<div key={i}>
														<img src={_get(image, "imageUrl")} alt="" />
													</div>
												))}
											</Carousel>
										</div>

										<div className="right-appscreenshot-rating">
											<div className="right-appscreenshot-rating-left">
												{((formData.isRatingVisible || false) === true) && (
													<Fragment>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(formData.ratingColor) ? (formData.ratingColor) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(formData.ratingColor) ? (formData.ratingColor) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(formData.ratingColor) ? (formData.ratingColor) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(formData.ratingColor) ? (formData.ratingColor) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(formData.ratingColor) ? (formData.ratingColor) : "#fea510"} viewBox="0 0 16 16">
															<path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
														</svg>
													</Fragment>
												)}
											</div>

											<div className="right-appscreenshot-rating-right">
												{(_get(storeDetails, "storeOpeningHours.isOpeningHoursVisible", false) === true) && (
													<p><span>Open</span> - Closing 16</p>
												)}
											</div>
										</div>

										<div className="right-appscreenshot-icon">
											{((formData.isPhoneNumberVisible || false) === true) && (
												<span>
													<img src={iconCall} alt="call" />
													Call
												</span>
											)}
											{((formData.isEmailAddressVisible || false) === true) && (
												<span>
													<img src={iconEmail} alt="call" />
													email
												</span>
											)}
											{((formData.isDirectionDisplayVisible || false) === true) && (
												<span>
													<img src={iconDirection} alt="call" />
													directions
												</span>
											)}
											{((formData.isDisplayWebSiteVisible || false) === true) && (
												<span>
													<img src={iconWebsite} alt="call" />
													website
												</span>
											)}
										</div>

										<div className="right-appscreenshot-tabs">
											<div className="right-appscreenshot-tabs-links">
												<p className="active">Info</p>
												<p>Inbox</p>
												<p>cards</p>

												<span className="followed">Followed</span>
											</div>
										</div>

										<div className="right-appscreenshot-desc">
											{((storeDetails.storeInfo || "") !== "") && (storeDetails.storeInfo || "").split('\n').map((text, index) => (
												<Fragment key={index}>
													{text}
													<br />
												</Fragment>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ButtonFunctions;
