import React, { useCallback, useEffect, useState } from "react";
import Client from "../GlobalAPICall";
import { useNavigate } from "react-router";
import BlankData from "../BlankData";
import moment from "moment";
import getLocaleDateString from "../TimeFormat";

function ApprovalRequest({ APITOKEN }) {
  const [getData, setData] = useState([]);
  const navigate = useNavigate();
  const f = getLocaleDateString(APITOKEN.config.cultureCode);
  const load = useCallback(() => {
    APITOKEN.token &&
      Client.get(`/email/pendingapproval/Distributions`)
        .then(({ data }) => {
          setData(data);
        })
        .catch(err => {
          throw err;
        });
    return () => {
      setData([]);
    };
  }, [APITOKEN]);

  const rejectionAPICall = id => {
    Client.patch(`Email/Send/Rejected/${id}`, {})
      .then(({ data }) => {
        try {
          load();
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        throw err;
      });
  };
  useEffect(() => {
    load();
  }, [load]);
  return (
    <div className="sms_Sec_1 bx-boder-common width100  margin-bottom">
      <div className="_h_header">
        <h3>Request</h3>
      </div>
      <table
        cellPadding="0"
        cellSpacing="0"
        className="smstable"
        style={{ tableLayout: "auto" }}
      >
        {getData.length > 0 ? (
          <thead>
            <tr>
              <th className="table_cell_header">Distribution Name</th>
              <th className="table_cell_header">Requester</th>
              <th className="table_cell_header">User Email</th>
              <th className="table_cell_header">Rejected By</th>
              <th className="table_cell_header">Rejected Reason</th>
              <th className="table_cell_header">Date</th>
              <th className="table_cell_header">Actions</th>
            </tr>
          </thead>
        ) : (
          <></>
        )}
        <tbody>
          {getData.length > 0 ? (
            <>
              {getData.map((item, index) => (
                <tr key={index}>
                  <td className="body_table_cell">{item["Name"]}</td>
                  <td className="body_table_cell">{item["RequestedByName"]}</td>
                  <td className="body_table_cell">
                    {item["RequestedByEmail"]}
                  </td>
                  <td className="body_table_cell">
                    {item?.RejectedByUserName}
                  </td>
                  <td className="body_table_cell">{item?.RejectedReason}</td>
                  <td className="body_table_cell">
                    {moment(item["RequestedDate"]).format(f)}
                  </td>
                  <td className="body_table_cell">
                    <button
                      type="button"
                      className="link"
                      disabled={item.IsRejected}
                      onClick={() => navigate(`/Email/Emailsummary/${item.Id}`)}
                    >
                      Check and Approve
                    </button>
                    |
                    <button
                      type="button"
                      className="link"
                      disabled={item.IsRejected}
                      onClick={() => rejectionAPICall(item.Id)}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <BlankData
              title="Currently, you have no sending requests to review."
              colSpan="8"
              content="If there is any review request, it  will be shown here."
            />
          )}
        </tbody>
      </table>
    </div>
  );
}

export default ApprovalRequest;
