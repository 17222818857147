import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import Tabing from './Tabing';
import './index.css';
const EmailInsight = () => {
    const checkIsAuth = useSelector(state => state);
    const [tabData, setTab] = useState([]);
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('Insights/Categories/Email?queueId=00000000-0000-0000-0000-000000000000', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    
    return (
        <> <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle _t_i">Email Insights</h1>
                    <p className="sms_content">Here you can find all your Email insights starting with an aggregating level and break down per sending to the lowest level statistic. You are able to pin your favourite KPIs to your Dashboard.</p>
                </div>
            </div>
        </div>
            <div className="dashbd_sec margin-top50">
                <Tabing  data={tabData} />
            </div></>
    )
}
export default EmailInsight;