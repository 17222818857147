export const isCSR = typeof window !== "undefined";

export const copyToClipboard = async (text) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(text);
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }
};

export function calculateTimeLeft(difference) {
  const timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    timeLeft.hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    timeLeft.minutes = Math.floor((difference / 1000 / 60) % 60);
    timeLeft.seconds = Math.floor((difference / 1000) % 60);
  }

  return timeLeft;
}

export const urlBuilder = (params, url = window.location.href) => {
  let query = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");
  return url + "?" + query;
};

export const calculatePurchaseData = ({
  balance,
  exchangeRate,
  userMax,
  minAllocation,
  userTotalPurchasedAmount,
  gasFees,
}) => {
  const ableToPurchase = Math.min(
    (balance - gasFees) / (exchangeRate / 100),
    userMax
  );

  const amountToCheck =
    userTotalPurchasedAmount >= minAllocation
      ? 0
      : minAllocation - userTotalPurchasedAmount;

  return { ableToPurchase, amountToCheck };
};
