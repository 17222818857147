import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Client from '../../GlobalAPICall';
import { DeleteFolderTemplateForCampaign } from '../../ReuseTemplate';
import { encode as btoa } from 'base-64'
import GridLoader from '../../ReuseTemplate/GridLoader';
import BlankData from '../../BlankData';

const Tabing = ({ data }) => {
    const defaultId = 0;
    const [tabId, setTabid] = useState(defaultId);

    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div>{tabId === defaultId ? <FolderTab /> : <>No data found</>}
                </div>
            }
        </>
    )
}

export default Tabing;
const FolderTab = () => {
    const navigate = useNavigate();
    const {APITOKEN} = useSelector(state => state);
    const [folderData, setFolderData] = useState([]);
    const [isloader, setLoader] = useState(true);
    const [deletePanel, setDeletePanel] = useState({ show: false, type: "" });

    const deleteFolder = (item) => {
        setDeletePanel(prv => ({ ...prv, show: true, type: item.id }));
    }
    const deleteDownload = (t) => {
        Client.delete(`Campaign/${deletePanel.type}/${t}/folder`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                if (data.Success) {
                    load();
                    setDeletePanel(prv => ({ ...prv, show: false, type: "" }));
                }
            } catch (err) {
                console.err(err);
            }
        }).catch(err => {
            console.error(err);
        });
    }
    const load = useCallback(() => {
        setLoader(true);
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/0/folders?detailViewReq=true`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    setLoader(false);
                    setFolderData(data);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }, [APITOKEN])
    useEffect(() => {
        load();
    }, [load]);
    return (<>
        {deletePanel.show ? <div className="margin-top50 inline-block width100"><DeleteFolderTemplateForCampaign title="Click on Delete to delete this Folder." content="This will permanently delete this Folder and it can not be restored." colSpan="5" onClick={() => setDeletePanel(prv => ({ ...prv, show: false, type: "" }))} deleteDownload={deleteDownload} /> </div> :
            <div className="sms_Sec_1 bx-boder-common width100 nobg">
                <div className="_h_header white border-radius2">
                    <h3>Folders</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={() => navigate('/createContent/42')}>Create Folder</button>
                    </span>
                </div>
                <div className="_list_fx">
                    {isloader === 0 ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                        <table className="smstable white" cellPadding="0" cellSpacing="0">

                            <thead>
                                {folderData.length > 0 ?
                                    <tr>
                                        <th className="table_cell_header">Name</th>
                                        <th className="table_cell_header">Description</th>
                                        <th className="table_cell_header">Type</th>
                                        <th className="table_cell_header">Creation</th>
                                        <th className="table_cell_header">Actions</th>
                                    </tr>
                                    : <BlankData title="Folders" content="Here you will find your campaigns that are saved in a Folder. A Folder could represent a client or a project which will make it easier to find your campaign designs." colSpan="5"/>}
                            </thead>

                            <tbody>
                                {folderData && folderData.map((item, i) => <Grid item={item} key={i} Event={() => deleteFolder(item)} />)}
                            </tbody>

                        </table>
                    }
                </div>
            </div>
        }
    </>)
}
const Grid = ({ item, ...props }) => {
    const { Event } = props;
    const navigate = useNavigate();

    return <><tr><td className="body_table_cell excludeClass">{item.name}</td><td className="body_table_cell">{item.description}</td><td className="body_table_cell">{item.access}</td><td className="body_table_cell">{item.creations}</td><td className="body_table_cell"><button type="button" className="link inline-block nopadding" onClick={() => navigate(`/folderContent/${item.id}`)}>Open</button>&nbsp; | &nbsp; <button type="button" className="link inline-block nopadding" onClick={() => navigate(`/createContent/32?id=${item.id}&editFolder=true`)}>Edit</button>&nbsp; | &nbsp; <button type="button" className="link inline-block nopadding" onClick={() => navigate(`/shareFolder/${item.id}?n=${btoa(item['name'])}`)} >Share</button>&nbsp; | &nbsp; <button type="button" className="link inline-block" onClick={Event}>Delete</button></td></tr></>
}