
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from "react-redux";
import Client from '../GlobalAPICall';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import { forEach } from 'lodash';
import moment from 'moment';

const UnSubscribed = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataSubscribed, setdataSubscribed] = useState({});
    const [showChart, setChartShow] = useState(false);
    const { ListObject } = props;
    const calculationGrowthCount = useCallback((response) => {
        const _o = JSON.parse(response);
        const obj = { name: 'EMAIL', data: [], color: '#0177ff' };
        const obj1 = { name: 'SMS', data: [], color: '#3aac69' };

        _o.forEach(async function (v, i) {
            if (v['SubscribeStatusSource'].toLocaleLowerCase() === "email") {
                obj['data'].push(v['Count']);
                if (obj['data'].length > 1) {
                    const pr = obj['data'].length - 1;
                    obj['data'][pr] += obj['data'][obj['data'].length - 2];
                }
            } else {
                obj1['data'].push(v['Count']);
                if (obj1['data'].length > 1) {
                    const pr = obj1['data'].length - 1;
                    obj1['data'][pr] += obj1['data'][obj1['data'].length - 2];
                }
            }
        });
        const heigtChartObject = {
            chart: {
                type: 'area',
                backgroundColor: '#fff'
            },
            title: {
                text: ' '
            },
            subtitle: {
                text: ' '
            },
            yAxis: {
                title: {
                    text: ' '
                }
            },
            xAxis: {
                type: 'datetime',
                plotLines: [{
                    dashStyle: 'dash',
                    width: 1,
                    color: '#d33'
                }],
                lineColor: "#52626f",
                categories: _o,
                labels: {
                    formatter: function () {
                        let data = this.value
                        data = data.SubscribedUnSubscribedAt;
                        return  moment(data).format('YYYY-MM-DD');
                    }
                }
            },
            tooltip: {
                split: true,
                backgroundColor: '#fff',
                borderColor: '#eee',
                borderRadius: 3,
                borderWidth: 1,
                shadow: false,
                useHTML: false,
                xDateFormat: '%Y-%m-%d',
                formatter: function () {
                    var s = [];
                    const self = this;
                    s.push('<table width="100%" cellspacing="0" cellpadding="0"><tr><td style="font-family:arial;background:#fff;color:#000;font-size:15px;padding:5px 0;font-weight:bold;text-align:center">' +  moment(this.x.SubscribedUnSubscribedAt).format('YYYY-MM-DD') + '</td></tr></table>');
                    forEach(self.points, function (point, i) {
                        s.push('<table width="100%"><tr><td style="font-size:12px;padding-bottom:5px;padding-top:5px;font-family:roboto;color:#1b283e;">' + point.series.name + ' </td><td style="padding-left:10px;text-align:right;"><b style="text-align:center; line-height:20px;min-width:20px;height:20px;color:#000;display:inline-block;font-family:roboto;padding:0 10px;font-size:12px";"> &nbsp;' + point.y + '</td></tr></table>');
                    })
                    return s;
                },
                shared: true,
                followTouchMove: true,
                followPointer: true,
                style: {
                    color: '#666',
                    fontWeight: 'normal',

                },

            },
            plotOptions: {
                series: {
                    marker: {
                        enabled: true,
                    },
                    cursor: 'pointer',
                }
            },
            series: [obj, obj1]
        }

        return { ...heigtChartObject }
    }, [])

    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/dashboard/SubscriberListGrowth/${ListObject.id}/Details?includeSubscribed=false&includeUnsubscribed=true`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        if (res.data.length > 0) {
                            setChartShow(true);
                            setdataSubscribed(calculationGrowthCount(res.data[0]['SubscriberStatusGrowthBySource']));
                        } else {
                            setChartShow(false);
                        }

                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.error(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false);
        }
    }, [APITOKEN, ListObject, calculationGrowthCount]); // <-- add any missing dependencies react warns about
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>{showChart ? <HighchartsReact highcharts={Highcharts} options={dataSubscribed} /> : <p className="font-16" style={{ padding: '18% 0', textAlign: 'center' }}>No Data</p>}</>
    )

}

export default UnSubscribed;
