
import { Link, useNavigate } from 'react-router-dom';
import getLocaleDateString from '../TimeFormat';
import moment from 'moment';
import GridLoader from '../ReuseTemplate/GridLoader';

const SendingSMSEmail = ({ ...props }) => {
    const { Data, IsLoaderDisplay ,APITOKEN } = props;
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const year = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const navigate = useNavigate();
    return (
        <div className="overviewtable margin-top50 bx-boder-common white">
            <div className="_h_header">
                <h3 style={{ "display": 'block' }}>Last 3 Sending (SMS,Email)</h3>
                <span className="flex-right">
                    <button className="grid_btn_1 nobg margin-right15 vertical-middle" type="button" onClick={() => navigate('/createContent/47')}>Send SMS</button>
                    <button className="grid_btn_1 nobg vertical-middle" type="button" onClick={() => navigate('/createContent/48')}>Send Email</button>
                </span>
            </div>
            <div className="sendingwidgets">
                {IsLoaderDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7]} /> :
                    <table cellSpacing="0" cellPadding="0" className="smstable table-layout-default">
                        <thead>
                            <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Creation</th>
                                <th className="table_cell_header">Sent</th>
                                <th className="table_cell_header excludeclassName">Delivered</th>
                                <th className="table_cell_header excludeclassName">Failed</th>
                                <th className="table_cell_header excludeclassName">Unsubscribed</th>
                                <th className="table_cell_header excludeclassName">Clicks</th>
                                <th className="table_cell_header excludeclassName">Unique Clicks</th>
                                <th className="table_cell_header excludeclassName">Click to open rate</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Data.map((v, i) =>
                                <tr key={i}>
                                    <td width="30%" className="body_table_cell"><p className="firstttd">{year[new Date(v['SentDate']).getMonth(f)]} {new Date(v['SentDate']).getDate()}</p><Link style={{ "wordBreak": "break-word", "width": "calc(100% - 36px)", "verticalAlign": "middle" }} to={v.DistributionType === 1 ?`/insights/smsDetails/${v.QueueId}`:`/insights/emailDetails/${v.QueueId}`} className="inline-block link"><span className="link inline-block noproperties" style={{ "paddingLeft": "15px" }}>{v.campaignName}</span></Link></td>
                                    <td className="body_table_cell">{v.SendingCreationType}</td><td className="body_table_cell">{moment(v['SentDate']).format(`${f}`)}</td>
                                    {JSON.parse(v.KPI).map((x, index) =>
                                        <td className="body_table_cell" key={index}><strong style={{ "color": x.CountColor, "display": (x['Count'] === undefined || x['Count'] === -1) ? 'none' : 'inline-block' }}>{x.Count}</strong><p style={{ "color": x.RateColor }}><strong>{x.Rate}%</strong></p></td>
                                    )}

                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )

}

export default SendingSMSEmail;
