import { useNavigate } from "react-router";
import getParameterByName from "../../../../ReuseTemplate/GetRoute";



const EmailGrid = ({ item, ...props }) => {
    const navigate = useNavigate()
    return (<>
        <tr>
            <td className="body_table_cell noproperties">{item.name}</td>
            <td className="body_table_cell ">{item.displayId}</td>
            <td className="body_table_cell ">{item.contentType}</td>
            <td className="body_table_cell"><button className="inline-block link _edit_template noborder nobg" onClick={()=>navigate(`/createContent/${getParameterByName('type',window.location.href)}?id=${item.id}`)}>Edit</button>|<button className="inline-block link nobg noborder" onClick={()=> props.showDeletePanelFN(false,item)}>Delete</button> <span style={{"display":item.isCompleted?'inline-block':'none'}}>|</span><button className="inline-block link nobg noborder" style={{"display":item.isCompleted?'inline-block':'none'}} onClick={()=> props.DuplicateClick(item)}>Duplicate</button></td>

        </tr>
    </>);
}

export default EmailGrid
