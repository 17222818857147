import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DownloadTab from './DownloadTab';
// import { useReducer } from 'react';


// const initialState = 0
// function reducer(state, action) {
//     switch (action.type) {
//       case 'TOTALCOUNT':
//         return state + action.payload;
//       default:
//         throw new Error();
//     }
//   }  
const Tabing = ({ data }) => {
    const defaultId = 0;
    const [tabId, setTabid] = useState(defaultId);
    // const [state, dispatch] = useReducer(reducer, initialState);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                {/* ({ JSON.stringify(state)}) */}
                
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} >
                            <>
                                <Link className={item.id === tabId ? 'active ' : ''}
                                    id={item.id}>{item.name}</Link>
                                
                            </> 
                            
                        </li>
                    })}
                </ul>
            </div>
            {
                <div className="tab_body">{tabId === 0 ?
                    <DownloadTab tabId={tabId} /> : <>no tab</>}
                </div>
            }
        </>
    )
}

export default Tabing;