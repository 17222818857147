import "./index.css";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Client from "../GlobalAPICall";
import TwoWayAuth from "./TwoWayAuth";
import Logo from "../assets/img/logo.png";
import NavbarConnect from "../layout/Navbar";
import { AppContext } from "../contexts/AppContext";
// import { AppContextProvider } from "../contexts/AppContextProvider";
export const Login = () => {
  const navigate = useNavigate();
  const url = process.env;
  const [user, setUserInfo] = useState({
    userName: "",
    password: "",
  });
  // const [error, setError] = useState({});
  const [forGetObject, setForgotObject] = useState({
    display: false,
    text: `Log in to your account by`,
  });
  const [emailVal, setEmail] = useState({ responseMessage: "", val: "" });
  // const [twowayAuth, setTwoAuth] = useState(true);
  const {
    twowayAuth,
    setTwoAuth,
    responseAPI,
    setResponse,
    error,
    setError,
    setReCallProfile,
  } = useContext(AppContext);

  // const [responseAPI, setResponse] = useState({});
  console.log("responseAPI", responseAPI);
  console.log("twowayAuth", twowayAuth);
  const loginForm = (e) => {
    e.preventDefault();
    setUserInfo((prv) => ({ ...prv, [e.target.name]: e.target.value }));
  };
  const AuthTokenUIGenrate = (e) => {
    e.preventDefault();
    const options = {
      userName: user.userName,
      password: user.password,
      validity: "LongLive",
      domain: window.location.origin,
    };
    try {
      const headers = new Headers({
        "Content-Type": "application/json",
        lc_r_me_mfa: `${
          !Cookies.get("lc_r_me_mfa_c") ? "" : Cookies.get("lc_r_me_mfa_c")
        }`,
      });
      fetch(`${url.REACT_APP_API_URL}/Authenticate/token/ui`, {
        method: "POST",
        headers,
        redirect: "follow",
        body: JSON.stringify(options),
      })
        .then(async (response) => await response.json())
        .then((data) => {
          setResponse(data);
          if (data.hasOwnProperty("client_secret")) {
            try {
              if (data.scope === "mfa") {
                setTwoAuth(false);
              } else {
                setTwoAuth(true);
                Cookies.set("secretKey", data.client_secret, { path: "/" });
                window.location.replace("/onboarding");
                setReCallProfile(true);
              }
            } catch (err) {
              throw new Error(err);
            }
          } else {
            // Check multipal account info //
            if (Array.isArray(data)) {
              navigate("/multiaccount", {
                state: {
                  data,
                  user: { name: user.userName, password: user.password },
                },
              });
            } else {
              Cookies.remove("secretKey");
              localStorage.removeItem("persist:promoti");
              setError({
                errorMessage: data.message,
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const forgotPasswordDisplay = () => {
    setForgotObject({ display: true, text: "Forgot password" });
  };

  const getEmailForPassword = (e) => {
    const emailElmtRegex = /\S+@\S+\.\S+/;
    const val = e.target.value;
    if (val.match(emailElmtRegex)) {
      setEmail({ val });
    } else {
      setEmail({ val: "" });
    }
  };

  const resetPasswordFn = () => {
    const obj = {
      emailOrMobileNumber: emailVal.val,
      type: "Email",
    };
    Client.post(`user/SendResetPasswordLink`, obj)
      .then((res) => {
        if (res.data.success) {
          setEmail((prv) => ({
            ...prv,
            responseMessage: `Please check your inbox!`,
            success: res.data.success,
          }));
        } else {
          setEmail((prv) => ({
            ...prv,
            responseMessage: `Email id does't exist.`,
            success: res.data.success,
          }));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const localCheck = localStorage.getItem("persist:promoti");
  console.log("localCheck", localCheck);

  useEffect(() => {
    if (localCheck) {
      localStorage.removeItem("persist:promoti");
    }
  }, [localCheck]);
  useEffect(() => {
    const secretKey = Cookies.get("secretKey");
    if (
      secretKey === undefined ||
      secretKey === "" ||
      secretKey === "undefined"
    ) {
      Cookies.remove("secretKey");
      localStorage.removeItem("persist:promoti");
      window.history.pushState("/login", "", "/login");
      navigate("/login");
    } else {
      window.history.pushState("/onboarding", "", "/onboarding");
      setReCallProfile(true);
      navigate("/onboarding");
    }
  }, [navigate]);

  return (
    <div className="bg__login">
      <div className="loginbox">
        {twowayAuth ? (
          <>
            <div className="section-title-wrap">
              <img src={Logo} alt="Logo" className="LogoApp" />
              <h1 className="section-title">{forGetObject.text}</h1>
              <div className="NavbarConnectConnectByWallet">
                <NavbarConnect />
                <span className="orBy">OR</span>
              </div>
            </div>
            {forGetObject.display ? (
              <>
                <div className="login-middlwe-section">
                  <div className="text-left nomargin-top formmodel-bx sms_content padding-top15">
                    So many passwords to remember,but we got you covered. You
                    can recover your password through email or SMS.
                  </div>
                  <div className="formmodel-bx margin-top50">
                    <label className="_l_able_cls font-19 font-Nunito">
                      Email
                    </label>
                    <input
                      type="text"
                      className="inputformmodel width368 height-increase margin-right15"
                      onChange={getEmailForPassword}
                    />
                    <button
                      type="button"
                      disabled={emailVal.val === "" ? true : false}
                      onClick={resetPasswordFn}
                      className="inline-block height-increase vertical-middle grid_btn_1 grid_btn_active"
                    >
                      Reset Password
                    </button>
                    <br></br>
                    <br></br>
                    {emailVal.responseMessage !== "" ? (
                      <p
                        className={emailVal.success ? "success" : "validation"}
                      >
                        {emailVal.responseMessage}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>

                  <p className="margin-bottom width100 inline-block text-center">
                    <button
                      className="link inline-block font-16"
                      onClick={() =>
                        setForgotObject((prv) => ({
                          ...prv,
                          display: false,
                          text: "Log in to your account by",
                        }))
                      }
                    >
                      <u>Return to log in</u>
                    </button>
                  </p>
                </div>
              </>
            ) : (
              <div className="login-middlwe-section">
                <div className="login-box">
                  {error.errorMessage && (
                    <p className="error">{error.errorMessage}</p>
                  )}
                  <form onSubmit={AuthTokenUIGenrate}>
                    <ul className="form-listing full">
                      <li>
                        <label className="_l_able_cls">User Name</label>
                        <input
                          type="text"
                          className="inputformmodel"
                          name="userName"
                          placeholder="Email"
                          onChange={loginForm}
                        />
                      </li>
                      <li>
                        <label className="_l_able_cls">Password</label>
                        <input
                          type="password"
                          className="inputformmodel"
                          name="password"
                          placeholder="**********"
                          onChange={loginForm}
                        />
                      </li>
                      <li className="keplog">
                        <p className="inline-block">
                          <label style={{ width: "auto" }}>
                            <span className="vertical-middle inline-block">
                              <input type="checkbox" />
                            </span>
                            &nbsp;
                            <span
                              style={{ fontSize: "16px" }}
                              className="vertical-middle inline-block"
                            >
                              Keep me logged in
                            </span>
                          </label>
                        </p>
                        <p className="inline-block forgotlink">
                          <button
                            className="nobg noborder link"
                            type="button"
                            onClick={forgotPasswordDisplay}
                          >
                            <u>Forgot password?</u>
                          </button>
                        </p>
                      </li>
                      <li className="text-right">
                        <button
                          type="submit"
                          className="btnLogin"
                          disabled={
                            user.userName !== "" && user.password !== ""
                              ? false
                              : true
                          }
                        >
                          Log in
                        </button>
                      </li>
                    </ul>
                  </form>
                </div>
              </div>
            )}
          </>
        ) : (
          <TwoWayAuth data={responseAPI} />
        )}
      </div>
    </div>
  );
};

export default Login;
