export const AuthenticationStatus = {
  LOADING: "loading",
  UNAUTHENTICATED: "unauthenticated",
  AUTHENTICATED: "authenticated",
};

export const CycleStatus = {
  LOADING: "loading",
  BEFORE: "before",
  LIVE: "live",
  AFTER: "after",
};
