import { Link, useNavigate } from "react-router-dom";

const TemplateListing = ({ item }) => {
    const navigate = useNavigate();
    return (
        <li className="pointer" onClick={()=>navigate(`${item.ReactPageUrl}?type=${item.Id}`)}>
            <div className="_temimg">
                <img src={item.ThumbNail} alt={item.Name} />
            </div>
            <div className="_tem_des">
                <h3 className="_tem_link pointer">{item.Name}</h3>
                <p>{item.Description}</p>

            </div>
            <Link to={`${item.ReactPageUrl}?type=${item.Id}`} className="_sel_tem">Select</Link>
        </li>
    )
}
export default TemplateListing; 