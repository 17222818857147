import GridAggregated from "./GridAggregated";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import TrafficeSource from "./TrafficeSource";
import InsightBlank from "../InsightBlank";
import LoaderBox from "../../ReuseTemplate/LoaderBox";

const OverView = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataPin, setdataPin] = useState([]);
    let [isPinned, setIsPinned] = useState([]);
    let [isDisplay, setDisplay] = useState(true);
    const [postObject] = useState({ "locale": "en-IN", "fromDate": "", "toDate": "" });
    const filterPinned = (res) => {
        const filter = [];
        for (let v of res) {
            if (v.isPinned) {
                filter.push(v);
            }
        }
        return filter;
    }
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.post(`Insights/SurveyKPIData?contentType=Survey_Insight`, postObject, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    setdataPin(res['data']);
                    setIsPinned(filterPinned(res.data));
                    setDisplay(false);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, postObject]); // <-- add any missing dependencies react warns about

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])


    const onSortEnd = (oldIndex, newIndex) => {
        setdataPin((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    }
    const pinMethod = (e) => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (e['isPinned']) {
                response = Client.delete(`Insights/survey/00000000-0000-0000-0000-000000000000/KPI/Pin/${e['id']}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            } else {
                response = Client.put(`Insights/survey/00000000-0000-0000-0000-000000000000/KPI/Pin/${e['id']}`, {}, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            }
            response.then((res) => {
                res['data']['success'] &&
                    loadApiCall();
            });
        }
    }
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Surveys Aggregated Overview</h3><p className="content">Last 30 days aggregation. Green and red percent values are compared with previous 30 days.</p></div><div className={`white ${isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''}`}><div className=" white  ">{isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataPin.map((v, i) => {
            return v.isPinned &&
                <SortableItem key={i}>
                    <div className="bx-boder-common bx white detailsbx" style={{ "display": v['isPinned'] ? 'inline-block' : 'none' }}><div className="drop-zone"><button
                        type="button"
                        className="pin"
                        style={{ "fontSize": "19px" }}
                        onClick={() => pinMethod(v)}>
                        <i className={(v['isPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'}></i>
                    </button>
                        <h3 className="title">{v['name']}</h3><p className="content paddingbottom20">{v['pinnedDescription']}</p></div><div className="px"><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr><td style={{ visibility: (v['countLabel'] == null || v['countLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['countLabel']}</td><td className="-tvl" ><strong style={{ "color": v['countColor'], "visibility": (v['countLabel'] === null || v['countLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['count']}</strong></td></tr><tr><td style={{ "visibility": (v['rateLabel'] === null || v['rateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['rateLabel']}</td><td className="-tvl link"><strong style={{ "color": v['rateColor'], "visibility": (v['rateLabel'] === null || v['rateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['rate']}%</strong></td></tr></tbody></table></div></div></div>
                </SortableItem>
        })}</SortableList>
        }</div><div className="-n-d-p"></div></div></div> <div className=" margin-top50 bx-boder-common white"><div className="chartharf  width100" ><TrafficeSource PostObject={postObject} /></div></div><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>Survey Traffic Sources</h3></div><div className="aggreategrid"><GridAggregated dataPin={dataPin} IsDisplay={isDisplay} onClick={e => pinMethod(e)} /></div></div></>
    )
}

export default OverView;








