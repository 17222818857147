import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';

export const ShortURL = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [smsTemplateData, setTemplate] = useState([]);
    const { Selected, Value } = props;
    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get(`Lookup/ShortnerUrls`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                try {
                    
                    setTemplate(data);
                    const defaultShortUrl = data.filter(item => item.type === 0);
                    Selected(prv => ({
                        ...prv,
                        shortURL: defaultShortUrl[0].url
                    }));
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN,Selected])
    //smsTemplateData.filter(x => x.url === e.target.value)[0].url  remove code from condition //
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">ShortURL Type</label>
            <select className="inputformmodel"
                value={Value.shortURL}
                onChange={e => Selected({ ...Value, ...{ shortURL: e.target.value !== "" ? e.target.value : '' } })}>
                <option value="">Select</option>
                {smsTemplateData.map((v, i) => <option key={i} value={v.url}>{v.url} {v.typeText}</option>)}
            </select>
        </div>
    )

}

