import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../GlobalAPICall';
import Tabing from './Tabing';
const MyAccount = ()=>{
    const checkIsAuth = useSelector(state => state);
    
    const [tabData, setTab] = useState([]);
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('User/Categories?contenttype=Profile', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    return(
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                    <Tabing data={tabData}/>
            </div>
        </>
    )
}

export default MyAccount