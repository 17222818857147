
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Client from '../GlobalAPICall';
import getLocaleDateString from '../TimeFormat';
import GridLoader from '../ReuseTemplate/GridLoader';

const SendingnotDone = () => {
    const { APITOKEN } = useSelector(state => state);
    const [wbsiteGrid, setWebsiteGrid] = useState({
        data: [],
        isDisplay: true
    });
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const year = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    const navigate = useNavigate();
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/dashboard/SubscriberListGrowth/LandingPageStatusReact?totalrecords=2`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        setWebsiteGrid(prv => ({
                            ...prv,
                            data: res['data'],
                            isDisplay: false
                        }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.error(err);
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
        return (() => {
            setWebsiteGrid({
                data: [],
                isDisplay: true
            });
        })
    }, [APITOKEN]); // <-- add any missing dependencies react warns about


    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <div className="overviewtable margin-top50 bx-boder-common white">
            <div className="_h_header">
                <h3 style={{ "display": 'block' }}>Website, Ads, Some and QR Code (We have not done any sending)</h3>
                <span className="flex-right">
                    <button className="grid_btn_1 nobg  vertical-middle" type="button" onClick={() => navigate('/createContent/2')}>Create Landing Page</button>

                </span>
            </div>
            <div className="sendingwidgets">
                {wbsiteGrid.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
                    <table cellSpacing="0" cellPadding="0" className="smstable table-layout-default">
                        {wbsiteGrid.data.length > 0 &&
                            <thead>
                                <tr><th className="table_cell_header">Name</th><th className="table_cell_header">Creation</th><th className="table_cell_header">Time</th><th className="table_cell_header excludeclassName">Visits</th><th className="table_cell_header excludeclassName">Unique Visitors</th><th className="table_cell_header excludeclassName">Bounce Rate</th><th className="table_cell_header excludeclassName">Form Submitted</th><th className="table_cell_header excludeclassName">CTOR</th></tr>
                            </thead>
                        }
                        <tbody>
                            {wbsiteGrid.data.map((v, i) =>
                                <tr key={i}>
                                    <td width="30%" className="body_table_cell">
                                        <p className="firstttd">{year[new Date(v['CreatedDateTime']).getMonth()]} {new Date(v['CreatedDateTime']).getDate()}</p>
                                        <Link to={`/insights/landingpageDetails/${v.CampaignId}`} className="inline-block link">
                                            <span className="link" style={{ paddingLeft: '15px' }}>
                                                <span className="link noproperties">{v['Name']}</span>
                                            </span></Link>
                                    </td>
                                    <td className="body_table_cell">{v['type']}</td>
                                    <td className="body_table_cell">{moment(v['CreatedDateTime']).format(f)}</td>
                                    {JSON.parse(v.KPI).map((x, index) =>
                                        <td className="body_table_cell" key={index}><strong style={{ "color": x.CountColor, "display": (x['Count'] === undefined || x['Count'] === -1) ? 'none' : 'inline-block' }}>{x.Count}</strong><p style={{ "color": x.RateColor }}><strong>{x.Rate}%</strong></p></td>
                                    )}
                                </tr>
                            )}
                        </tbody>
                    </table>
                }
            </div>
        </div>
    )

}

export default SendingnotDone;
