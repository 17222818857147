import { Link } from 'react-router-dom';
import styled from 'styled-components';
import React from "react";
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { setNavigationToggleFn } from '../Redux/Action';
import { useDispatch } from 'react-redux'

const SlidrbarLink = styled(Link)`
    display: inline-block;
    width: 100%;
    margin-bottom: 0px;
    &:hover{
        color:#a2e0d7;
    }
`;
const SidebarLabel = styled.label`
        display: inline-block;
        vertical-align: top;
        cursor: pointer;
        box-sizing: border-box;
        padding-left: 4px;
`;

const DropDownLink = styled(Link)`
    display: inline-block;
    width: 100%;
    text-decoration: none;
    padding: 12px 16px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    font-family: roboto;
    box-sizing: border-box;
    padding: 8px 16px !important;
    font-weight: 300!important;
    &:hover{
        color:#a2e0d7;
    }
`
const SunMenuLabel = styled.label`
    line-height: 19px !important;
    vertical-align: top;
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    box-sizing: border-box;
`
const SubMenu = ({ item, ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const { NagigationToggle ,setNavToggle } = props;
    const location = useLocation();
    const dispatch = useDispatch();

    const menuClick = ()=>{
        setNavToggle(dispatch(setNavigationToggleFn(false)));
    }
    return (
        <li className={item.classActive ? 'toggleSelected' : ''}>
            <SlidrbarLink  to={item.path === "" ? `${location.pathname}${location.search}` : item.path}
                onClick={e => { (NagigationToggle(item, e));menuClick() }}>
                <i className={item.icon}></i>
                <SidebarLabel>{item.title}</SidebarLabel>
                <i className={item.subNav && item.slider
                    ? item.openIcon
                    : item.subNav
                        ? item.dropdownIcon
                        : null} />
            </SlidrbarLink>
            {item.slider &&
                <div className="submenu submenulclheader create">
                    {item.subNav && item.subNav.map(i => {
                        return (
                                <DropDownLink key={Math.random()} 
                                    style={{ display: i.activeMobile === false ? 'none' : 'inline-block' }}
                                    to={(item.id === 4 ?
                                        APITOKEN.config.isPhoneNumberVerified ?
                                            i.path : `/onboarding` : i.path)} >
                                    <SunMenuLabel>{i.title}</SunMenuLabel>
                                </DropDownLink>

                        )
                    })}
                </div>
            }
        </li>
    )
}

export default SubMenu;