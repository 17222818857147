import axios from 'axios';
import Client from '../../../GlobalAPICall';

const headerPost = {
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
    Authorization: `bearer 8a1e8fbba6d46fcd7e63fb93a33c27c1`,
    'Content-Type': 'application/json'
};

const thumNailHeader = {
    Accept: 'application/vnd.vimeo.*+json;version=3.4',
    Authorization: `bearer 8a1e8fbba6d46fcd7e63fb93a33c27c1`,
    "Content-Type": "application/octet-stream",
}

export const ImportVideoVimeo = async fileSize => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_VIMEO_URL}`,
        headers: headerPost,
        data: {
            upload: {
                approach: 'tus',
                size: fileSize
            }
        }
    });

}

export const thumnailURI = async id => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_VIMEO_URL_THUMNAIL}videos/${id}/pictures`,
        headers: headerPost
    });

}


export const VideoThumnailAPI = async (videoId, url, APITOKEN) => {
    return await Client.post(`Mobile/VideoThumbNail?videoId=${videoId}&url=${url}`, {}, {
        headers: {
            Authorization: `Bearer ${APITOKEN.token}`,
        }
    });
}
export const CaptionAPI = async (videoId, url, APITOKEN,code) => {
    return await Client.post(`Mobile/VideoCaptions?videoId=${videoId}&url=${url}&languageCode=${code}`, {}, {
        headers: {
            Authorization: `Bearer ${APITOKEN.token}`,
        }
    });
}







export const uploadThumnail = async (vimeoUrl, link) => {

    await axios.get(link).then(async res => {
        const blob = new Blob([res.data]);
        const formData = new FormData();
        formData.append("file", blob);
        return await axios.put(vimeoUrl, formData, { headers: thumNailHeader });
    });

}

export const base64 = async (imgUrl, callback) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.height = image.naturalHeight;
        canvas.width = image.naturalWidth;
        ctx.drawImage(image, 0, 0);
        const dataUrl = canvas.toDataURL();
        callback && callback(dataUrl)
    }
    image.src = imgUrl;
}
