import React, { useEffect, useState } from 'react';
import { get as _get } from "lodash";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { getSegmentationList, deleteSegment, exportSegment } from "../../../../services/crm";
import TableRowsLoading from "../../../ReusableComponents/TableRowsLoading";
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";

const Segments = () => {
	const pathArray = window.location.pathname.split("/");
	const listID = _get(pathArray, "[4]", null); // get PKID from URL
	const creationSource = _get(pathArray, "[5]", null); // get creationSource from URL
	const location = useLocation();

	const navigate = useNavigate();
	const [segmentTableData, setSegmentationTableData] = useState([]);
	const [showDeleteSegmentSection, setShowDeleteSegmentSection] = useState(false);
	const [deleteSegmentId, setDeleteSegmentId] = useState(null);
	const [refreshTableData, setRefreshTableData] = useState(true);

	const segmentTableColumns = [
		{ name: "Name", selector: (item) => _get(item, "Name", ""), grow: 0.7 },
		{ name: "Description", selector: (item) => _get(item, "Description", ""), grow: 0.7 },
		{ name: "Type", selector: (item) => (_get(item, "segmentType", 1) === 1) ? "Static" : "Dynamic", grow: 0.2 },
		{ name: "Count", selector: (item) => _get(item, "dataCount", 0), grow: 0.1 },
		{
			name: "Actions",
			selector: (item) => (
				<div className="rdt_TableAction">
					<span className="text-07f">
						<Link
							to={`/crm/people/subscribers/${listID}/${creationSource}/segment/create?edit=${_get(item, "SegmentId", "")}`}
							state={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
						>
							Edit Name
						</Link>
					</span>
					<span className="text-b7b">|</span>
					<span className="text-07f">
						<Link
							to={`/crm/people/subscribers/${listID}/${creationSource}/segment/buildSegment/${_get(item, "SegmentId", "")}`}
							state={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
						>
							Edit Segment
						</Link>
					</span>
					<span className="text-b7b">|</span>
					<span className="text-07f cursor-pointer" onClick={() => onExport(item)}>Export</span>
					<span className="text-b7b">|</span>
					<span className="text-07f">
						<Link
							to={`/crm/people/subscribers/${listID}/${creationSource}/segment/view/${_get(item, "SegmentId", "")}`}
							state={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
						>
							View
						</Link>
					</span>
					<span className="text-b7b">|</span>
					<span className="text-07f cursor-pointer"
						onClick={() => {

							setShowDeleteSegmentSection(true);
							setDeleteSegmentId(_get(item, "SegmentId", ""));
						}}
					>
						Delete
					</span>
				</div>
			)
		},
	];

	useEffect(() => {
		(async () => {
			try {

				if (refreshTableData === true) {
					const response = await getSegmentationList({ subscriberListId: listID });

					setSegmentationTableData(_get(response, "data.data", []));
					setRefreshTableData(false);
				}
			} catch (err) {
			}
		})();
	}, [listID, refreshTableData]);

	const onExport = (selectedSegment) => {

		(async () => {
			try {

				const response = await exportSegment({ ListId: listID, SegmentId: _get(selectedSegment, "SegmentId", ""), Name: _get(selectedSegment, "Name", ""), ListType: (creationSource || 0) });

				if (_get(response, "data.success", false) === true) {

					navigate("/download");
				} else {
					//setErrorMessage(_get(response, "data.message", false));
				}
			} catch (err) {
			}
		})();
	}

	const onDelete = () => {
		(async () => {
			try {

				const response = await deleteSegment({ subscriberListId: listID, segmentId: deleteSegmentId });

				if (_get(response, "data.message", "") === "Success") {

					setRefreshTableData(true);
					setDeleteSegmentId(null)
					setShowDeleteSegmentSection(false);
				} else {
					//setErrorMessage(_get(response, "data.message", false));
				}
			} catch (err) {
			}
		})();
	}

	const onCancelDelete = () => {
		setShowDeleteSegmentSection(false);
		setDeleteSegmentId(null);
	}

	const deleteProgram = () => {

		return (
			<div className="sms_Sec_1 width100 white margin-bottom50">
				<div className="bx-boder-common common_Sec_1">
					<div className="_h_header">
						<h3 className="_t_i">Delete</h3>
					</div>
					<div className="bx-pad">
						<div className="alert alert-warning margin-bottom24">
							Are you sure that you want to delete the Segment?

						</div>

						<div className="formmodel-bx text-right nomargin">
							<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancelDelete()} >Cancel</button>

							<button
								type="button"
								className="btn btn-danger btn-gape-15"
								disabled=""
								onClick={() => onDelete()}>
								Confirm
							</button>
						</div>
					</div>
				</div>
			</div>
		)
	}
	console.log("location:::", location);
	return (
		<>
			<div className="dashbd_sec margin-top100">
				<div className="breadcrum">
					<BreadCrum
						To="/crm/people/listings"
						ToText="CRM List"
						SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts ") }}
						ToSecondPage={`/crm/people/subscribers/${_get(location, "state.selectedListId", "")}/${creationSource}`}
						ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
						CurrentPage="Segments"
					/>
				</div>

				{((showDeleteSegmentSection || false) === true) && (
					deleteProgram()
				)}

				{((showDeleteSegmentSection || false) === false) && (
					<div className="bx-boder-common width100 nobg margin-bottom50">
						<div className="_h_header white border-radius2">
							<h3>Segment</h3>
							<span className="flex-right">
								<Link
									to={`/crm/people/subscribers/${(listID || "")}/${(creationSource || 0)}/segment/create`}
									state={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
									className="btn btn-primary"
								>
									Create Segment
								</Link>
							</span>
						</div>
						<div>
							{((refreshTableData || false) === true) && (
								<TableRowsLoading />
							)}
							
							{((segmentTableData || []).length < 1) && ((refreshTableData || false) === false) && (
								<div className="p-5">
									<div className="alert alert-warning">
										You have not created any segments yet.
										<br /><br />
										<p>Create segment to address spesific groups of contacts.</p>
									</div>
								</div>
							)}
							{((segmentTableData || []).length > 0) && ((refreshTableData || false) === false) && (
								<div className="dataTable-primary">
									<DataTable
										columns={segmentTableColumns}
										data={segmentTableData}
									/>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</>
	)
}

export default Segments;