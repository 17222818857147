

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const NPSScore = ({ ...props }) => {
  const {ChartOption ,Data} = props;

    return (
        <div>
        <div className="padding20">
            {Data &&
                <div className="">
                    <div className="__nps_graph_real">
                        <div className="bt_bx">
                            <span className="nps_title_main">NPS Score</span>
                            <span className="nps_count" style={{ color:Data.npsScoreColor }}>{Data.npssCore}</span>
                        </div>
                        <div className="nps_subtitle">
                                <span className="f">Compared</span>
                            <span className="s" style={{ color: Data.compardColor }}>{Data.compared}</span>
                        </div>
                    </div>
                    <div id="npsScore" >
                        <HighchartsReact highcharts={Highcharts} options={ChartOption} />
                    </div>
                    <div style={{ display: 'flex', padding: '0  59px 20px 59px', top: '-10px' }}><div style={{ width: '100%' }}>-100</div><div style={{ width: '100%', textAlign: 'right' }}>100</div></div>
                </div>
            }
        </div>
        </div>
    )

}
export default NPSScore;
