
import { useCallback, useEffect, useState } from "react";
import Client, { Export_Client } from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import getLocaleDateString from '../../TimeFormat';

import BlankData from "../../BlankData";
import { useNavigate } from "react-router";
import moment from "moment";
import { Datepicker } from "@mobiscroll/react";
import GridLoader from "../../ReuseTemplate/GridLoader";
import _ from "lodash";
const EmailSending = () => {
    const { APITOKEN } = useSelector(state => state);
    const [dataEmailSending, setdataEmailSending] = useState({
        data: [],
        isDisplay: true
    });
    const [max, setDate] = useState("");
    const navigate = useNavigate();
    const [postObject, SetPostObject] = useState({ "locale": "en-IN", "fromDate": "", "toDate": "" });
    const [totalRecode, setTotalRecord] = useState(0);
    const [skip, setSkip] = useState(0);
    const [exportDisplay, setExportDisplay] = useState(false);
    const [paginationButton, setpainationButton] = useState({ next: false, previous: true });
    const [minDate, setMinDate] = useState('');
    const [obj, setAPIObject] = useState(prv => ({
        graterThanVal: prv === undefined ? 0 : prv.graterThanVal,
        searchValue: prv === undefined ? "" : prv.searchValue,
        startDate: prv === undefined ? "" : prv.startDate,
        endDate: prv === undefined ? "" : prv.endDate
    }));
    const selectedDate = ev => {
        const dateSplit = ev.valueText.split(' - ');
        SetPostObject({
            locale: APITOKEN.config.cultureCode,
            fromDate: dateSplit[0],
            toDate: dateSplit[1]
        })
    }
    const campaignSearch = e => {
        e.stopPropagation();
        setAPIObject(prv => ({ ...prv, searchValue: e.target.value }))
    }
    var debounced = _.debounce(campaignSearch, 1000);

    const DateSelected = (e) => {
        const filterDate = e.valueText.split(' - ');
        setAPIObject(prv => ({ ...prv, startDate: filterDate[0], endDate: filterDate[1] }));
    }


    const loadApiCall = useCallback(async () => {
        setdataEmailSending(prv => ({
            ...prv,
            isDisplay: true
        }));
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/Email/details?recipientsCountgreaterThan=${obj.graterThanVal}&search=${obj.searchValue}&startDate=${obj.startDate}&endDate=${obj.endDate}&skip=${skip}&limit=10`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    try {
                        setdataEmailSending(prv => ({
                            ...prv,
                            data,
                            isDisplay: false
                        }));
                        const { TotalCount } = data.length === 0 ? 0 : data[0];
                        setTotalRecord(TotalCount);
                        if (((skip + 1) * 10) > TotalCount) {
                            setpainationButton(prv => ({ ...prv, next: true }));
                        } else {
                            if (data.length < 10) {
                                if (skip === 0) {
                                    setpainationButton(prv => ({ ...prv, previous: true, next: true }));
                                };
                            };
                            setpainationButton(prv => ({ ...prv, next: false }))
                        }
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }

    }, [APITOKEN, obj, skip]); // <-- add any missing dependencies react warns about

    const pagination = (f) => {
        if (f === 0) {
            setSkip(skip => skip - 1);
            if ((skip - 1) === 0) {
                setpainationButton(prv => ({ ...prv, previous: true }))
            } else {
                setpainationButton(prv => ({ ...prv, previous: false }));
            }

        } else {
            setSkip(skip => skip + 1);
            setpainationButton(prv => ({ ...prv, previous: false }))
        }
    }

    const exportEmail = () => {
        const obj = {
            name: 'Email Distribution Summary',
            jsonData: JSON.stringify({ filter: postObject })
        }
        try {
            Export_Client.post(`export/EmailOverview`, obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    if (res.data.success) {
                        navigate('/download');
                    }
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }

    useEffect(() => {
        loadApiCall();
        const data = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        setMinDate(() => data);
        setDate(data);
    }, [loadApiCall, APITOKEN])

    return (
        <>
            {exportDisplay ? <div className="bx-boder-common white">
                <div className="_h_header">
                    <h3>Export</h3>
                    <span className="flex-right">
                        <div className="date-range inline-block dateRange">
                            <Datepicker className="inline-block border-radius4  inputformmodel"
                                max={max}
                                select="range"
                                touchUi={true}
                                onChange={e => selectedDate(e)}
                                dataTimezone='utc'
                                dateFormat="YYYY.MM.DD"
                                placeholder="Date Range"
                                locale={APITOKEN.config.countryCode}
                                display="center"
                                theme="ios"
                                controls={['calendar']} />


                        </div>
                    </span>
                </div>
                <div className="padding20">
                    <div className="_nodata margin-bottom nopadding">
                        <div id="nodatamessage"><strong>Prepare your insights for download.</strong><br /><br />If you want to get all your insights regarding this sending downloadable in excel format click on the Export button. Your exports will be stored inside your top right corner menu named Downloads.</div>
                    </div>
                    <div className="text-right">
                        <button type="button" className="grid_btn_1 nobg noborder   margin-right15" onClick={() => setExportDisplay(!exportDisplay)}>Cancel</button>
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={exportEmail}>Export</button>
                    </div>
                </div>
            </div> :
                <div className="bx-boder-common">
                    <div className="_insight_header white">
                        <h3 className="inline-block">Email Sendings</h3>
                        <span className="fright">
                            <button className="grid_btn_1" type="button" onClick={() => setExportDisplay(!exportDisplay)}>Export</button>
                        </span>
                    </div>
                    <div className="bx-pad white">
                        <div className="total_res inline-block">
                            <label className="inline-block">Total Recepients Greater Than</label>
                            <input type="text" className="inline-block wi_70px border-radius4 inputformmodel text-center" value={obj.graterThanVal} onChange={e => setAPIObject(prv => ({ ...prv, graterThanVal: e.target.value }))} />
                        </div>
                        <div className="date-range inline-block dateRange">
                            <label className="inline-block">Date Range</label>
                            <Datepicker className="inline-block border-radius4  inputformmodel"
                                select="range"
                                max={minDate}
                                onChange={e => DateSelected(e)}
                                dataTimezone='utc'
                                dateFormat="YYYY.MM.DD"
                                display="center"
                                theme="ios"
                                placeholder="Date Range"
                                locale={APITOKEN.config.countryCode}
                                controls={['calendar']} />
                            <input type="text" className="inline-block border-radius4 inputformmodel fright" placeholder="Name/Email Search" onChange={debounced} />

                        </div>
                    </div>

                    <>
                        <div className="-cam-sen-grid white drop-zone">
                            <div className="b_top">
                                <SendingTable DataGrid={dataEmailSending} />
                            </div>
                        </div>


                        {dataEmailSending.data.length > 0 ?
                            <div className="formmodel-bx text-right  b_top bx-pad nomargin white">
                                <span className="pagination_count vertical-middle">
                                    <span>{skip + 1}</span>-
                                    <span>{(skip + 1) * 10}</span> of&nbsp;
                                    <span>{totalRecode}</span>
                                </span>
                                <button type="button" className="bigtext" disabled={paginationButton.previous} onClick={() => pagination(0)}><i className="fas fa-caret-left"></i></button>
                                <button type="button" className="bigtext" disabled={paginationButton.next} onClick={() => pagination(1)}><i className="fas fa-caret-right"></i></button>
                            </div>
                            : <></>}
                    </>


                </div>}</>
    )
}

export default EmailSending;

const SendingTable = ({ DataGrid }) => {
    return (
        <>{
            DataGrid.isDisplay ?
                <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
                <table cellPadding="0" cellSpacing="" className="smstable table-layout-default white">
                    {DataGrid.data && DataGrid.data.length === 0 ? ''
                        : <thead>
                            <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Sent</th>
                                {/* <th className="table_cell_header">Goals</th> */}
                                <th className="table_cell_header">Total Recepients</th>
                                <th className="table_cell_header">Delivered</th>
                                <th className="table_cell_header">Submitted</th>
                                <th className="table_cell_header">Failed</th>
                                <th className="table_cell_header">Open</th>
                                <th className="table_cell_header">&nbsp;</th>
                            </tr>
                        </thead>
                    }

                    <tbody>
                        {DataGrid && DataGrid.data.length === 0 ? <BlankData title="You have not sent any Email." colSpan="8" content="After you have sent an Email you will find the insights here." /> : <Grid data={DataGrid.data} />}
                    </tbody>
                </table>

        }
        </>
    )
}
const Grid = ({ data }) => {
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    return (
        <>
            {data && data.map((v, i) => <tr key={i}>
                <td className="body_table_cell"><button type="button" onClick={() => navigate(`/insights/EmailDetails/${v['QueueId']}`)} className="link text-left detail_sms word-wrap">{v['CampaignName']}</button></td>
                <td className="body_table_cell">{moment(v['SentDate']).format(f)}</td>
                {/* <td className="body_table_cell">{v['HasGoal']}</td> */}
                <td className="body_table_cell">
                    {v['TotalReciepients']}</td>
                <td className="body_table_cell"><strong>{v['DeliveredRate']}%</strong> ({v['Delivered']})</td>
                <td className="body_table_cell"><strong>{v['SubmittedRate']}%</strong>({v['Submitted']})</td>
                <td className="body_table_cell"><strong>{v['FailedRate']}%</strong> ({v['Failed']})</td>
                <td className="body_table_cell"><strong>{v['OpenedRate']}%</strong>({v['Opened']})</td>
                <td className="body_table_cell"><button type="button" className="link" onClick={() => navigate(`/createContent/48?clone=${v.QueueId}`)}>Clone</button></td>
            </tr>)}
        </>
    )
}
