import React, { useEffect, useState, Fragment } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { PickerOverlay } from 'filestack-react';
import { Carousel } from 'react-responsive-carousel';

import addImage from './../../../../assets/img/icon-add-image.svg';
import sixDot from './../../../../assets/img/icon-six-dot.svg';
import iPhoneHeader from './../../../../assets/img/iphone-header.png';
import iPhoneFooter from './../../../../assets/img/iphone-footer.png';
import iconCall from './../../../../assets/img/icon-call.svg';
import iconEmail from './../../../../assets/img/icon-email.svg';
import iconDirection from './../../../../assets/img/icon-direction.svg';
import iconWebsite from './../../../../assets/img/icon-website.svg';

import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';
import { fetchCategories, createStore } from "../../../../services/store";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const FILE_PICKER_API_KEY = process.env.REACT_APP_FILE_PICKER_API_KEY;

const StoreDetails = ({ storeDetails, fetchStoreData }) => {
	const [showFileUploadSection, setShowFileUploadSection] = useState(false);
	const [activeAccordionId, setActiveAccordionId] = useState(null);
	const [formValid, setFormValid] = useState(false);
	const [categories, setCategories] = useState([]);
	const [isLoader, setIsLoader] = useState(false);

	let existingImages = [];
	if (_get(storeDetails, "image", []).length > 0) {
		existingImages = _get(storeDetails, "image", []).map((image, i) => {
			return { id: i, files: { imageUrl: _get(image, "imageUrl", ""), fileName: _get(image, "fileName", "") }, isOpen: false }
		})
	}
	const [formState, setFormState] = useState({
		storeName: _get(storeDetails, "storeName", ""),
		storeDescription: _get(storeDetails, "storeDescription", ""),
		categoryId: _get(storeDetails, "categoryId", ""),
		images: existingImages,
		storeInfo: _get(storeDetails, "storeInfo", ""),
		isStoreAutoFollowed: _get(storeDetails, "isStoreAutoFollowed", false),
		isStoreVisibleInApp: _get(storeDetails, "isStoreVisibleInApp", false)
	});

	const [errors, setErrors] = useState({
		storeName: '',
		storeDescription: '',
		categoryId: '',
		storeInfo: '',
		images: '',
	});

	useEffect(() => {
		let existingImages = [];
		if (_get(storeDetails, "image", []).length > 0) {
			existingImages = _get(storeDetails, "image", []).map((image, i) => {
				return { id: i, files: { imageUrl: _get(image, "imageUrl", ""), fileName: _get(image, "fileName", "") }, isOpen: false }
			})
		}
		setFormState({
			storeName: _get(storeDetails, "storeName", ""),
			storeDescription: _get(storeDetails, "storeDescription", ""),
			categoryId: _get(storeDetails, "categoryId", ""),
			images: existingImages,
			storeInfo: _get(storeDetails, "storeInfo", ""),
			isStoreAutoFollowed: _get(storeDetails, "isStoreAutoFollowed", false),
			isStoreVisibleInApp: _get(storeDetails, "isStoreVisibleInApp", false)
		});
	}, [storeDetails]);

	// On Page Refresh > Fetch Categories
	useEffect(() => {
		(async () => {
			try {
				const response = await fetchCategories();
				setCategories(_get(response, "data", []));
			} catch (err) {
				console.log('Error occurred when fetching categories', err);
			}
		})();
	}, []);

	const handleCategoryChange = (e) => {
		setFormState({ ...formState, categoryId: e.target.value });
	};

	const handleStoreInfoTextChange = (e) => {
		setFormState({ ...formState, storeInfo: e.target.value });
	};

	const handleStoreNameChange = (e) => {
		setFormState({ ...formState, storeName: e.target.value });
	};

	const handleStoreDescriptionChange = (e) => {
		setFormState({ ...formState, storeDescription: e.target.value });
	};

	const addAccordion = () => {
		const newId = formState.images.length + 1;
		setFormState(prevState => ({
			...prevState,
			images: [
				...prevState.images,
				{ id: newId, files: {}, isOpen: true }
			]
		}));
	};

	const toggleAccordion = (id) => {
		setFormState(prevState => ({
			...prevState,
			images: prevState.images.map(image =>
				image.id === id ? { ...image, isOpen: !image.isOpen } : image
			)
		}));
	};

	const deleteAccordion = (id) => {
		setFormState(prevState => ({
			...prevState,
			images: prevState.images.filter(image => image.id !== id)
		}));
	};

	useEffect(() => {
		validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formState]);

	const validateForm = () => {
		let isValid = true;
		const newErrors = { storeName: '', categoryId: '', images: '', storeInfo: '' };

		if (!formState.storeName) {
			newErrors.storeName = 'Store name is required.';
			isValid = false;
		}

		if (!formState.categoryId) {
			newErrors.categoryId = 'Category is required.';
			isValid = false;
		}

		if (formState.storeInfo.length < 1) {
			newErrors.storeInfo = 'Store info is required.';
			isValid = false;
		}

		if (formState.storeInfo.length > 1000) {
			newErrors.storeInfo = 'Store info text cannot exceed 1000 characters.';
			isValid = false;
		}

		if (formState.images.length > 3) {
			newErrors.images = 'Only 3 images are allowed.';
			isValid = false;
		}

		setErrors(newErrors);
		setFormValid(isValid);
		return isValid;
	};

	const handleSubmit = () => {
		(async () => {
			try {

				if (formValid) {
					setIsLoader(true);
					const mergedData = {
						"storeId": _get(storeDetails, "storeId", ""),
						"storeName": _get(formState, "storeName", ""),
						"storeDescription": _get(formState, "storeDescription", ""),
						"categoryId": parseInt(_get(formState, "categoryId", 0)),
						"image": (_get(formState, "images", []).length > 0) ? _get(formState, "images", []).map((v, i) => v.files) : [],
						"storeInfo": _get(formState, "storeInfo", ""),
						"isStoreVisibleInApp": _get(formState, "isStoreVisibleInApp", false),
						"IsStoreAutoFollowed": _get(formState, "isStoreAutoFollowed", false),
					};

					const response = await createStore(mergedData);
					setIsLoader(false);
					if (_get(response, "flag", false) === false) {

					} else {
						fetchStoreData(true);
					}
				}
				//sendDataToParent(formValues, 3);
			} catch (err) {
				console.log('Error occured when submitting data', err);
			}
		})();
	};

	const Accordion = ({ accordion }) => {
		return (
			<div className="formmodel-bx accordion-store">
				<div className="accordion-header" onClick={() => toggleAccordion(_get(accordion, "id", 0))}>
					<h4 className="flex align-items-center gap-2"><img src={sixDot} alt="" /> {`Image ${_get(accordion, "id", 0)}`}</h4>
					<div className="flex align-items-center gap-3">
						<span onClick={(e) => { e.stopPropagation(); deleteAccordion(_get(accordion, "id", 0)); }} style={{ cursor: 'pointer' }}>
							<i className="fas fa-trash"></i>
						</span>
						<span>
							<i className={`fas fa-chevron-${(_get(accordion, "isOpen", false) === true) ? 'up' : 'down'}`}></i>
						</span>
					</div>
				</div>

				{(_get(accordion, "isOpen", false) === true) && (
					<div className="accordion-content">
						<div className="position-relative">
							<div className="store-image">
								<div className="step-upload-file" onClick={() => {
									setShowFileUploadSection(true);
									setActiveAccordionId(_get(accordion, "id", 0));
								}}>
									<i className="fal fa-cloud-upload" aria-hidden="true"></i>
								</div>
							</div>
							{!_isEmpty(_get(accordion, "files", {})) && (
								<div className="uploaded-images">
									<img src={_get(accordion, "files.imageUrl", "")} alt="" />
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		);
	};

	const fileUploadSection = () => {
		return (
			<PickerOverlay
				apikey={FILE_PICKER_API_KEY}
				pickerOptions={{
					fromSources: ["local_file_system"],
					accept: ["image/*"],
					onClose: () => { setShowFileUploadSection(false); }
				}}
				onUploadDone={(res) => {
					setFormState(prevState => ({
						...prevState,
						images: prevState.images.map(image =>
							image.id === activeAccordionId ? { ...image, files: { imageUrl: _get(res, "filesUploaded[0].url", ""), fileName: _get(res, "filesUploaded[0].filename", "") } } : image
						)
					}));
					setShowFileUploadSection(false);
				}}
			/>
		);
	}

	return (
		<>
			<div className="bx-boder-common width100 nobg mt-8">
				<div className="_h_header white border-radius2">
					<h3>Create store name</h3>
				</div>

				<div className="p-5 white right-appscreenshot-height">
					<div className="row">
						<div className="col-8">
							<div className="formmodel-bx">
								<label className="_l_able_cls">Name <span className="error-message">*</span></label>
								<input
									type="text"
									className="inputformmodel inline-block width100"
									name="shopname"
									value={formState.storeName}
									onChange={(e) => handleStoreNameChange(e)}
								/>
							</div>

							<div className="formmodel-bx">
								<label className="_l_able_cls">Description</label>
								<textarea
									placeholder=""
									className="inputformmodel width100 textareaModel"
									value={formState.storeDescription}
									onChange={(e) => handleStoreDescriptionChange(e)}
								/>
							</div>

							<div className="formmodel-bx">
								<label className="_l_able_cls" id="categoryId">Category <span className="error-message">*</span></label>
								<select
									className="inputformmodel width100"
									value={formState.categoryId}
									onChange={handleCategoryChange}
								>
									<option value="">Select Category</option>
									{!_isEmpty(categories || []) && (categories || []).map((category, i) => (
										<option key={i} value={_get(category, "id", 0)}>{_get(category, "name", "")}</option>
									))}
								</select>
							</div>

							<div className="formmodel-bx">
								<label className="_l_able_cls">Store info text <span className="error-message">*</span></label>
								<textarea
									className="inputformmodel width100 textareaModel excludeclassName"
									value={formState.storeInfo}
									onChange={handleStoreInfoTextChange}
								/>
								<div className="text-right mt-3">
									<p>Characters used: <b>{formState.storeInfo.length}</b></p>
									<p>Characters in total: <b>1000</b></p>
								</div>
							</div>

							<div className="formmodel-bx">
								<label className="_l_able_cls">Store image</label>
								<p className="mt-2">Click on the icon or Drag and Drop an image from your computer to upload it. Image size equals to 500px x 500px. Lower than this size will lead to distortion of the image in the mobile app. You can upload up to three images.</p>
							</div>

							{formState.images.map(accordion => (
								<Accordion key={accordion.id} accordion={accordion} />
							))}

							{_get(errors, "images", "") && <p className="error-message">{errors.images}</p>}
							<div className="store-add-image mt-5 mb-10" onClick={addAccordion}>
								<img src={addImage} alt="" />
								<p>Add an image</p>
							</div>

							<div className="formmodel-bx inline-block">
								<label className="_l_able_cls mb-3">Store is visible inn App</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="StoreVisibleInAppYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="StoreVisibleInAppYes"
											name="isStoreVisibleInApp"
											value="yes"
											checked={_get(formState, "isStoreVisibleInApp", false)}
											onChange={(e) => setFormState((prevData) => ({ ...prevData, isStoreVisibleInApp: true }))}
										/>
										Yes
									</label>

									<label htmlFor="StoreVisibleInAppNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="StoreVisibleInAppNo"
											name="isStoreVisibleInApp"
											value="no"
											checked={!_get(formState, "isStoreVisibleInApp", false)}
											onChange={(e) => setFormState((prevData) => ({ ...prevData, isStoreVisibleInApp: false }))}
										/>
										No
									</label>
								</div>
							</div>

							<div className="formmodel-bx inline-block ms-15">
								<label className="_l_able_cls mb-3">Is Store Auto-followed</label>
								<div className="flex align-items-center gap-5">
									<label htmlFor="StoreAutoFollowedYes">
										<input
											type="radio"
											className="inline-block me-2"
											id="StoreAutoFollowedYes"
											name="isStoreAutoFollowed"
											value="yes"
											checked={_get(formState, "isStoreAutoFollowed", false)}
											onChange={(e) => setFormState((prevData) => ({ ...prevData, isStoreAutoFollowed: true }))}
										/>
										Yes
									</label>

									<label htmlFor="StoreAutoFollowedNo">
										<input
											type="radio"
											className="inline-block me-2"
											id="StoreAutoFollowedNo"
											name="isStoreAutoFollowed"
											value="no"
											checked={!_get(formState, "isStoreAutoFollowed", false)}
											onChange={(e) => setFormState((prevData) => ({ ...prevData, isStoreAutoFollowed: false }))}
										/>
										No
									</label>
								</div>
							</div>

							<div className="formmodel-bx text-right mt-12 mb-0">
								<button
									type="button"
									className={`btn btn-primary btn-gape-15 ${formValid ? '' : 'btn-disabled'}`}
									onClick={handleSubmit}
									disabled={!formValid}
								>
									<ButtonLoader IsLoader={isLoader}>
										Save
									</ButtonLoader>
								</button>
							</div>
						</div>

						<div className="col-4">
							<div className="right-appscreenshot mt-6 ms-auto me-auto">
								<div className="right-appscreenshot-banner">
									<div className="overflow-hidden">
										<img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
										<img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

										<p className="right-appscreenshot-banner-header-text">
											{(formState.storeName || "")}
										</p>

										<div className="right-appscreenshot-storeimage">
											<Carousel showThumbs={false} swipeable={true} interval={2000} autoPlay infiniteLoop>
												{((formState.images || []).length > 0) && formState.images.map((image, i) => (
													<div key={i}>
														<img src={_get(image, "files.imageUrl")} alt="" />
													</div>
												))}
											</Carousel>
										</div>

										<div className="right-appscreenshot-rating">
											<div className="right-appscreenshot-rating-left">
												{(_get(storeDetails, "storeButtons.isRatingVisible", false) === true) && (
													<Fragment>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
															<path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
														</svg>
														<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(storeDetails, "storeButtons.ratingColor", "")) ? (_get(storeDetails, "storeButtons.ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
															<path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
														</svg>
													</Fragment>
												)}
											</div>

											<div className="right-appscreenshot-rating-right">
												{(_get(storeDetails, "storeOpeningHours.isOpeningHoursVisible", false) === true) && (
													<p><span>Open</span> - Closing 16</p>
												)}
											</div>
										</div>

										<div className="right-appscreenshot-icon">
											{(_get(storeDetails, "storeButtons.isPhoneNumberVisible", false) === true) && (
												<span>
													<img src={iconCall} alt="call" />
													Call
												</span>
											)}
											{(_get(storeDetails, "storeButtons.isEmailAddressVisible", false) === true) && (
												<span>
													<img src={iconEmail} alt="call" />
													email
												</span>
											)}
											{(_get(storeDetails, "storeButtons.isDirectionDisplayVisible", false) === true) && (
												<span>
													<img src={iconDirection} alt="call" />
													directions
												</span>
											)}
											{(_get(storeDetails, "storeButtons.isDisplayWebSiteVisible", false) === true) && (
												<span>
													<img src={iconWebsite} alt="call" />
													website
												</span>
											)}
										</div>

										<div className="right-appscreenshot-tabs">
											<div className="right-appscreenshot-tabs-links">
												<p className="active">Info</p>
												<p>Inbox</p>
												<p>cards</p>

												<span className="followed">Followed</span>
											</div>
										</div>

										<div className="right-appscreenshot-desc">
											{((formState.storeInfo || "") !== "") && (formState.storeInfo || "").split('\n').map((text, index) => (
												<Fragment key={index}>
													{text}
													<br />
												</Fragment>
											))}
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>

			{showFileUploadSection && fileUploadSection()}
		</>
	);
}

export default StoreDetails;
