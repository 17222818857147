import { useCallback, useEffect, useState } from "react";
import _ from 'lodash';
import Highcharts from 'highcharts';
import HighchartsReact from "highcharts-react-official";
const StackBar = ({ ...props }) => {
    const { Data } = props;
    const [optionData, setOptionValue] = useState({
        colors: ['#e8f086', '#6fde6e', '#ff4241', '#a691ae', '#235fa4'],
        chart: {
            type: 'bar',

        },
        title: {
            align: "left",
            useHTML: true,
            style: { "font-size": "18px", "color": "#000", 'margin-bottom': '20px', 'font-family': 'roboto' }

        },
        accessibility: {
            enabled:false  
          },
        subtitle: {
            align: "left",
            useHTML: true,
            style: { "font-size": "18px", "color": "#000", 'marginTop': '20px', 'font-family': 'roboto' }

        },
        yAxis: {
            min: 0,

        },

        xAxis: {
            title: {
                text: null,
            },
        },
        tooltip: {
            useHTML: true,
            borderColor: '#fff',
            borderRadius: 3,
            borderWidth: 1,
            backgroundColor: '#fff',
            formatter: function () {
                return `<div style="width:150px;font-size:14px;"><p  style="color:#666;font-size:15px;font-weight:bold;">${this.point.series.name}</p><p style="margin-bottom:10px;color:#666">Total Count : ${this.point.y}</p></div>`;
            }
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        legend: {
            reversed: true
        },  

    });
    const Chart = useCallback(() => {
        if (Data['MatrixResponse'] !== undefined) {
            const newarray = _.unionBy(Data['MatrixResponse'], 'optionRawId');
            const filterData = [];
            const catgoriesName = [];
            newarray.forEach(async function ($v, $i) {
                filterData.push({ name: "", data: [], cat: [] });
                filterData[$i].maintitle = Data.WidgetName;

                for (var x of Data['MatrixResponse']) {
                    if ($v['optionRawId'] === x['optionRawId']) {
                        const count = x['optionResponse'][0]['totalCount'];
                        filterData[$i].name = x['optionRowText'];
                        catgoriesName.push(x['optionRowText']);
                        filterData[$i].data.push(count);
                    }
                };
            });
            setOptionValue(prv => ({
                ...prv,
                series: filterData,
                xAxis: {
                    categories: filterData[0].newNameText
                },
                title: {
                    text: `${filterData[0]['maintitle']}`,
                },
                subtitle: {
                    text: `${(filterData[0]['title'] !== undefined) ? 'Answer: ' + filterData[0].title : ''}`,
                }
            }));
        };
    }, [Data]);
    useEffect(() => {
        Chart();
    }, [Chart])

    return (
        <div>
            <HighchartsReact highcharts={Highcharts} options={optionData} />
        </div>
    )

}

export default StackBar
