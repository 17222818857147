import BlankData from "../../BlankData";
import { encode as btoa } from 'base-64'
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Cookies from 'js-cookie'

export const LandingPage = ({ ...props }) => {
    const { Data, TabId, TabInfo, ArchieveFN, isLoading } = props;
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const editCampagin = (ev) => {
        if (ev.RedirectToDomain !== "") {
            const secreatKey = Cookies.get('secretKey');
            window.location.replace(`${APITOKEN.config.subscriptionInfo.EditorDomainUrl}${ev.EditUrl}&lcxtkf=${APITOKEN.config.lcxtkf}&backUrl=${btoa(window.location.href)}&sk=${secreatKey}`);
        } else {
            navigate(ev.EditUrl);
        }
    }


    return (
        <>
            {isLoading ? <div></div> :
                Data.length > 0 ? Data.map((v, i) => <div key={i}>
                    <div className="width100 nobg" style={{ borderBottom: 0 }}>
                        <div className="-l-body-l white bx-boder-common border-radius2">
                            <div className="topbarListing">
                                <h3 className="link">{v['Name']}</h3>
                                <span className="flex-right drop-zone">
                                    <span style={{ display: (TabId === 7) ? 'none' : 'inline-block' }}>
                                        <button
                                            type="button"
                                            className="grid_btn_1 nobg margin-right15 vertical-middle"
                                            disabled={v['IsExpired']} onClick={() => editCampagin(v)}>Edit</button>
                                        <button
                                            type="button"
                                            className="grid_btn_1 nobg vertical-middle" onClick={() => ArchieveFN(v.Id)}>Archive</button>
                                    </span></span>
                            </div><div className="_listing_bx_content">
                                <div className="c-img" style={{ overflow: 'hidden' }}>
                                    <img width="270" alt="" src={(v['InProgress']) ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/Draft.jpg' : v['PreviewImagePath']} />
                                </div>
                                <div className="-cam-right">
                                    <div className="-cam-detail-wrap">
                                        <table width="100%" cellPadding="0" cellSpacing="0">
                                            <thead>
                                                <tr>
                                                    <th style={{ width: '40%' }}>Details</th>
                                                    <th>State</th>
                                                    <th>Status</th>
                                                    <th>Ends</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{v['Description']}</td>
                                                    <td>{v['State']}</td>
                                                    <td className="drop-zone">
                                                        <button type="button"
                                                            disabled={TabId === 7 ? true : false}
                                                            className={(v['Status'] === 'Live') ? 'live' : (v['InProgress'] === true) ? '-normal-text' : 'defaultActive'}
                                                            style={{ display: (v['IsExpired']) ? 'none' : 'inline-block' }}>{v['Status']}</button>
                                                        <span className="red" style={{ display: (!v['IsExpired']) ? 'none' : 'inline-block' }}>Expired</span>
                                                    </td>
                                                    <td className=" drop-zone">
                                                        <strong style={{ display: (v['IsExpired']) ? 'none' : 'inline-block' }}
                                                            className={(v['IsExpired']) ? '' : 'defaultActive'}>{(v['Status'] === 'Live') ? v['EndDate'] : (v['InProgress'] === true) ? '' : 'Set'} </strong>
                                                        <strong style={{ display: (!v['IsExpired']) ? 'none' : 'inline-block' }}>{v['EndDate']}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="list-btm">
                                        <div className="cam-info">
                                            <table cellPadding="0" cellSpacing="0">
                                                <tbody>
                                                    <tr>
                                                        <td>Folder</td>
                                                        <td>{(v['Foldername'] === undefined) ? "" : v['Foldername']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Created By</td>
                                                        <td>{v['CreatedBy']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Creation Date</td>
                                                        <td>{v['CreationDate']}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Last Modified</td>
                                                        <td>{(v['lstmodification'] === undefined) ? "" : v['lstmodification']}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="cam-qr"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="width100 nobg"
                            style={{ borderBottom: 0 }}>
                            <div className="-l-body-l white bx-boder-common border-radius2">
                                <div className="topbarListing">
                                    <h3 className="link">{v['Name']}</h3>
                                    <span className="flex-right drop-zone">
                                        <span style={{ display: (TabId === 7) ? 'none' : 'inline-block' }}>
                                            <button type="button" className="grid_btn_1 nobg margin-right15 vertical-middle">Edit</button>
                                            <button type="button" className="grid_btn_1 nobg vertical-middle">Archive</button></span>
                                    </span>
                                </div>
                                <div className="_listing_bx_content">
                                    <div className="c-img" style={{ overflow: 'hidden' }}>
                                        <img width="270" alt="" src={(v['InProgress']) ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/Draft.jpg' : v['PreviewImagePath']} />
                                    </div>
                                    <div className="-cam-right">
                                        <div className="-cam-detail-wrap">
                                            <table width="100%" cellPadding="0" cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '40%' }}>Details</th>
                                                        <th>State</th>
                                                        <th>Status</th>
                                                        <th>Ends</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{v['Description']}</td>
                                                        <td>{v['State']}</td>
                                                        <td className="drop-zone"><button type="button" disabled={TabId === 7 ? true : false}
                                                            className={(v['Status'] === 'Live') ? 'live' : (v['InProgress']) ? '-normal-text' : 'defaultActive'}
                                                            style={{ display: (v['IsExpired']) ? 'none' : 'inline-block' }}>{v['Status']}</button>
                                                            <span className="red" style={{ display: (!v['IsExpired']) ? 'none' : 'inline-block' }}>Expired</span>
                                                        </td>
                                                        <td className=" drop-zone">
                                                            <strong style={{ display: (v['IsExpired']) ? 'none' : 'inline-block' }}
                                                                className={(v['IsExpired']) ? '' : 'defaultActive '}>{(v['Status'] === 'Live') ? v['EndDate'] : (v['InProgress']) ? '' : 'Set'} </strong>
                                                            <strong style={{ display: (!v['IsExpired']) ? 'none' : 'inline-block' }}>{v['EndDate']}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="list-btm">
                                            <div className="cam-info">
                                                <table cellPadding="0" cellSpacing="0">
                                                    <tbody>
                                                        <tr>
                                                            <td>Folder</td>
                                                            <td>{(v['Foldername'] === undefined) ? "" : v['Foldername']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Created By</td>
                                                            <td>{v['CreatedBy']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Creation Date</td>
                                                            <td>{v['CreationDate']}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Last Modified</td>
                                                            <td>{(v['lstmodification'] === undefined) ? "" : v['lstmodification']}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="cam-qr"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>)
                    : <div className="width100 bx-boder-common nobg borderraduisAll"><div className="_h_header white borderraduisAll border-radius-bottom-none"><h3>{TabInfo.name}</h3></div><table cellPadding="0" cellSpacing="0" width="100%"><tbody><BlankData title="You have no created any Landing Page yet." content="With a template you are able to configure and change your Landing Page without involving your technical derpartment." colSpan="5" /></tbody></table></div>
            }
        </>
    )

}


