import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import Client from '../../GlobalAPICall';

const ShareCampaign = () => {

    const { type, id } = useParams();
    const checkIsAuth = useSelector(state => state);
    const [error, setError] = useState(true);
    const [shareObject, setShareObject] = useState({ email: "", description: "" });
    const [validationMessage, SetValidationMessage] = useState({ flag: true, message: "" });
    const navigate = useNavigate();
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const checkAccount = (v) => {
        if (!isValidEmail(v)) {
            setError(true);
            SetValidationMessage({ flag: false, message: "" })
        } else {
            setError(false);
            setShareObject({ ...shareObject, ...{ email: v } });

        }
    }
    const shareCampaignFN = () => {
        const data = {
            "ToUserEmail": shareObject.email,
            "message": shareObject.description,
        };
        Client.post(`Campaign/${id}/sharecontent`, data, {
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.success) {
                        SetValidationMessage({ flag: false, message: "" });
                        navigate('/shareCampaignDetails')
                    } else {
                        SetValidationMessage({ flag: true, message: res.data.message })
                    }
                } catch (err) {
                    console.log(err);
                }
            })
    }
    const backToroute = () => {
        if (type === '2') {
            navigate('/landingPage/2')
        } else if (type === '5') {
            navigate('/email/5')
        }
        else if (type === '4') {
            navigate('/survey/4')
        } else {
            navigate('/workflow/74')
        }
    }
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle _t_i">Share <span className="typetext">{type === '2' ? 'Landing Page' : type === '5'? 'Email':'Survey'}</span></h1>
                        <p className="sms_content">You can share your content with other users of the platform. Add the User Account, an optional message and click on Share. </p>
                    </div>
                </div>
            </div>
            <div className="wrSms margin-top50">
                <div className="bx-boder-common common_Sec_1">
                    <div className="_h_header">
                        <h3>Share</h3>
                    </div>
                    <div className="padding20">
                        <div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">User Account</label>
                            <input type="text" className="inputformmodel width100" onChange={e => checkAccount(e.target.value)} />
                            {validationMessage.flag ? <p className="validation">{validationMessage.message}</p> : <></>}
                        </div>
                        <div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">Optional Message</label>
                            <textarea type="text" className="inputformmodel width100 textareaModel" onChange={e => setShareObject({ ...shareObject, ...{ description: e.target.value } })}></textarea>
                        </div>
                        <div className="formmodel-bx text-right nomargin">
                            <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={backToroute}>Cancel</button>
                            <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15 pointer" onClick={shareCampaignFN} disabled={error}>Share</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ShareCampaign;