import { combineReducers } from 'redux';
import APITOKEN, { InsightSMSDetails, OnboardingStep, NavigationToggleReduce, LogoReducer, FileImport } from './Reducer';
import { importContact, uploadedContact } from './reducers/ReducerImportContacts';
const reducers = combineReducers({
    APITOKEN,
    InsightSMSDetails,
    OnboardingStep,
    NavigationToggleReduce,
    Logo: LogoReducer,
    FileImport,
    importContacts: combineReducers({
        uploadedContact,
        importContact
    })
})
export default reducers;