import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import LoaderBox from '../ReuseTemplate/LoaderBox';
const LastActivityOverView = ({ ...props }) => {
    const { Data, SetData, IsDisplay } = props;
    const onSortEnd = (oldIndex, newIndex) => {
        SetData((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    }
    return (
        <>
            {IsDisplay ? <div className="white _overviewPin summary_o_v_dashboard"><LoaderBox RepeatArray={[1, 2, 3, 4, 5]} /></div> :
                <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v_dashboard white" draggedItemClassName="dragged">
                    {Data.map((v, i) =>
                        <SortableItem key={i}>

                            <div className="bx-boder-common bx white detailsbx" >
                                <div className="drop-zone" style={{ borderBottom: '1px solid rgba(26,40,61,0.24)' }}>
                                    <span className="das-img">
                                        <img src={v['ImageUrl']} alt="" width="39" /></span>
                                    <div className="d-p-content"><h3 className="title">{v['Name']}</h3>
                                        <p className="content paddingbottom20  noborder">{v['InsightType']}</p>
                                    </div>
                                </div>
                                <div className="px">
                                    <div className="px">
                                        <table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                            <tbody>
                                                <tr>
                                                    <td>{v['CountLabel']}</td>
                                                    <td className="-tvl">
                                                        <strong style={{ color: v['CountColor'], display: (v['HasEnableButton']) ? 'none' : 'inline-block' }}>{v['Count'] === -1?'':v['Count']}</strong>
                                                        <a href={v['EnabledButtonNavigateUrl']} style={{ float: 'right', display: (v['HasEnableButton']) ? 'inline-block' : 'none' }} className="grid_btn_1 link">{v['EnabledButtonText']}</a></td></tr><tr><td>{v['RateLabel']}</td>
                                                    <td className="-tvl link">
                                                        <strong style={{ color: v['RateColor'], display: (v['HasEnableButton']) ? 'none' : 'inline-block' }}>{(v['RateLabel'] === "") ? '' : v['formattedComparedWithPreviousMonth'] !== '-1' ?v['formattedComparedWithPreviousMonth'] + '%':''}</strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </SortableItem>
                    )}
                </SortableList>

            }
        </>
    )
}
export default LastActivityOverView;