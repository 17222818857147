import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Client from "../../../GlobalAPICall";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";

const URLShort = ({ ...props }) => {
  const { setSelectedDoaminLabel, domainLabel } = props;
  const { APITOKEN } = useSelector(state => state);
  const [formData, setFormData] = useState({});
  const [apiResponse, setAPIResponse] = useState({ data: {} });
  const navigate = useNavigate();
  const [isloader, setLoader] = useState(false);

  const saveDomain = () => {
    const obj = {
      "domainName": formData.domainName,
      "domainType": domainLabel.id,
      "description": formData.description === undefined ? "" : formData.description
    };
    setLoader(true);
    Client.post(`Domain/WhiteLabel/Configure/Domain/setup`, obj, {
      headers: {
        Authorization: `Bearer ` + APITOKEN['token']
      }
    })
      .then(res => {
        try {
          setLoader(false);

          if (res.data.statusCode === 200) {
            navigate('/configuration?_id=39')
          } else {
            setAPIResponse(prv => ({
              ...prv,
              data: res.data
            }));
          }
        } catch (err) {
          console.error(err);
        }
      }).catch(err => {
        console.error(err);
      })
  }

  return (
    <div className="domain__body padding20">
      <p>Enter your own domain that you like to use as a URL shortner. In general you would like to choose a short domain, but remember that you can use any extension that makes sence for the purpose.</p>
      <div className="margin-top50">
        <div className="domain__half">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Enter your domain
            </label>
            <input type="text" placeholder="yourdomain.com" className="inputformmodel width100" onChange={e => setFormData(prv => ({ ...prv, domainName: e.target.value }))} />
            <p className='validation'>{apiResponse.data.result}</p>
          </div>
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Description
            </label>
            <textarea type="text" className="inputformmodel textareaModel width100" onChange={e => setFormData(prv => ({ ...prv, description: e.target.value }))} placeholder="Give an optional description of the domain purpose.">
            </textarea>
          </div>
        </div>
        <div className="domain__half fright">
          <div className="formmodel-bx">
            <label className="_l_able_cls">
              Preview
            </label>
            <div className="previewImg configDomain" style={{ height: `215px` }}>
              <img src="https://app-dev.promoti.ai/pages/admin/configuration/DomainSetUp/urlshortimg.png"
                alt=""
                style={{ maxWidth: '100%' }} />
              <div className="lslug">
                <span className="lpu">https://<b className="__shorturlpre">{
                  (formData.domainName !== undefined && formData.domainName !== "") ? formData.domainName : 'yourdomain.com'}</b>/bPhtLtaS</span></div>

              <div className="shorturl">
                <span className="lpu">https://<b className="__shorturlpre">{(formData.domainName !== undefined && formData.domainName !== "") ? formData.domainName : 'yourdomain.com'}</b>/bPhtLtaS</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="inline-block width100  text-right">
        <button className="grid_btn_1 nobg noborder vertical-middle" onClick={() => setSelectedDoaminLabel({ id: "" })} type="button">Cancel</button>
        <button type="button" className="grid_btn_1 grid_btn_active" disabled={
          (formData.domainName !== undefined && formData.domainName !== "") ? false : true
        } onClick={saveDomain}>
          <ButtonLoader IsLoader={isloader}>Continue</ButtonLoader>  
        </button>
      </div>
    </div>
  )

}
export default URLShort;