import axios from "axios";
import Client from "../../../GlobalAPICall";

const CatgoryList = (APITOKEN,languageCode,id) => {
    const cancelToken = axios.CancelToken.source();
    const response =  Client.get(`Mobile/AppSubCategories?languageCode=${languageCode}&categoryId=${id}`, {
        cancelToken: cancelToken.token,
        headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
        }
  })
    return response;
}
export default CatgoryList;