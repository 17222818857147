
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react'
import Highcharts from 'highcharts';
import myJsonFile from "../../ReuseTemplate/CountryMapJSON.json";
import highchartsMap from "highcharts/modules/map";
highchartsMap(Highcharts);

export default function MapChart({Mapdata}) {

    const [options, setMapOptions] = useState({
        chart: {
            map: myJsonFile,
            borderWidth: 0
        },

        title: {
            text: '',
            align: 'left'
        },

        subtitle: {
            text: 'Total number of installed app in multipal countries',
            align: 'left'
        },

        accessibility: {
            typeDescription: '',
            point: {
                describeNull: false
            }
            
        },

        legend: {
            enabled: false
        },
    })
   

    const filter = useCallback(() => {
        const newarray = [];
        for (const x of Mapdata) {
            newarray.push([x.distStatus, x.totalCount]);
        };
        setMapOptions(prv => ({
            ...prv,
            series:[{
                name: 'Country',
                data: newarray,
                dataLabels: {
                    enabled: true,
                    color: '#FFFFFF',
                    formatter: function () {
                        if (this.point.value) {
                            return this.point.name;
                        }
                    }
                },
                tooltip: {
                    headerFormat: '',
                    pointFormat: 'App installed count:- {point.value}'
                }
            }]

        }))
    },[Mapdata])

    useEffect(() => {
        filter()
    },[filter])


    return (
        <>
            <HighchartsReact highcharts={Highcharts}
                options={options}
                constructorType={'mapChart'} allowChartUpdate={true}
                immutable={false}
                updateArgs={[true, true, true]}
                containerProps={{ className: 'chartContainer' }} />
        </>

    )
}
