import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import _ from 'lodash';

const History = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [getData, setDataObject] = useState(filterAnswers());
    const { id } = props.Object;
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Journal/Fetch?eventName=landingpage&campaignId=${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setDataObject(filterAnswers(res['data']));
            });
    }, [APITOKEN, id])

    function filterAnswers(res) {
        let filter = [];
        if (res !== undefined) {
            for (let v of res) {
                const $v = JSON.parse(v['header']);
                $v[0]['array'] = {};
                $v[0]['array'] = JSON.parse(v['subHeadings']);
                filter.push(...$v);
            }
        }
        return filter;
    };
    const toggle = (e, o) => {
        e.stopPropagation();
        const index = _.findIndex(getData, function (x) { return x.PKId === o['PKId']; });
        getData[index].show = !o.show;
        for (let x of getData) {
            const createParentObjects = { ...x, ...{ show: x['show'] === undefined ? false : x['show'] } };
            x['header'] = JSON.stringify([createParentObjects]);
            if (x['array'].length === 0) {
                x['subHeadings'] = JSON.stringify([]);
            } else {
                for (let y of x['array']) {
                    const createChildObjects = { ...y };
                    x['subHeadings'] = JSON.stringify([createChildObjects]
                    )
                }
            }
        }
        setDataObject(filterAnswers(getData))
    }
    useEffect(() => {
        load()
    }, [load]);
    return (
        <> <div className="_history_container" onClick={e => e.stopPropagation()} >
            <div className="topn-header">History <span style={{ padding: '20px', float: 'right' }}><i className="fa fal fa-times clsbtnNoti fright pointer" onClick={e => { e.stopPropagation(); props.setHistoryToggle(false); }}></i></span>
            </div>
            <div className="history-content">
                <ul className="tab-content" >
                    {
                        getData.length > 0 ? getData.map((x, i) => <li key={i}><p style={{ marginBottom: '6px' }}><strong>{x['UserName']}</strong> <span className="fright">{x['TimeStamp']}</span></p><p className="-his-content inline-block" style={{ width: '100%', lineHeight: '23px' }}
                        >{x['ActionType']} {x['PageName']}. <button type="button" style={{ display: x.array.length === 0 ? 'none' : 'inline-block' }} className="inline-block link  nobg noborder pointer" onClick={e => toggle(e, x)} > {x.show ? 'Close details' : 'More details'}</button></p><div className="-more-details" style={{ display: x.show ? 'block' : 'none' }}>{
                            x.array.map((y, $i) => <div key={$i}><p style={{ paddingBottom: '6px' }}><strong>{y['UserName']}</strong> <span className="fright">{y['TimeStamp']}</span></p><p className="-his-content inline-block" style={{ width: '100%', marginBottom: '10px', lineHeight: '23px' }}>{y['ActionType']} {y['PageName']}</p></div>)
                        }</div></li>) : <li>There is no registered activity.</li>}
                </ul>
            </div>
        </div>
            <div className="utmmasking"></div></>
    )
}
export default History;