
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';
import { DEVICE_OPTIONS } from '../../../constant/HightChartProps';

const DesktopChart = (props) => {
    const [DesktopData, setDesktop] = useState(DEVICE_OPTIONS);
    const loadApiCall = useCallback(async () => {
        const newArray = []
        await props['Data'].Desktop && props['Data'].Desktop.forEach(async (v, i) => {
            v['KPI'].forEach(async ($v, $i) => {
                if ($v['Id'] === 10) {
                    await newArray.push({
                        name: v['Desktop'],
                        y: $v['Count'],
                        drilldown: v['Desktop']
                    });
                }
            });
            setDesktop({ series: [{ data: newArray,name:'Desktop' }] })
        });
    }, [props])
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            <div className="bx-boder-common margin-top50">
                <div className="_insight_header white">
                    <h3>
                        Desktop
                    </h3>
                </div>
                {!props['Data'].Desktop ? <div className="_nodata padding20 white">
                    <div id="nodatamessage">
                        <strong>Browser Data no found</strong><br></br><br></br>
                    </div>
                </div> :
                    <>
                        <div className="padding20 white">
                            {DesktopData && <HighchartsReact highcharts={Highcharts} options={DesktopData} />}

                        </div>
                        <div className="padding20 white ">
                            <div className="_overviewPin _overviewPin_Device white summary_o_v padding20">
                                {
                                    props['Data'].Desktop && props['Data'].Desktop.map((v, i) => {
                                        return <div className="bx-boder-common bx detailsbx white" key={i} ><div className="drop-zone"><h3 className="title">{v['DeviceOS']}</h3><p className="content paddingbottom20">&nbsp;</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                            <tbody>
                                                {
                                                    v.KPI && v.KPI.map(($v, i) =>

                                                        <tr key={i}>

                                                            <td className="nopadding">
                                                                <table cellPadding="0" cellSpacing="0" width="100%" >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>{$v['CountLabel']}</td>
                                                                            <td className="-tvl"><strong className="pointer completeDetails">{$v['Count']}</strong></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>{$v['RateLabel']}</td>
                                                                            <td className="-tvl link"><strong style={{ color: $v['RateColor'] }}>{$v['Rate']}%</strong></td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>

                                                        </tr>


                                                    )

                                                }
                                            </tbody>
                                        </table>
                                        </div>
                                        </div>
                                    })}

                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    )
}
export default DesktopChart