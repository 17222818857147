import React, { useState }  from 'react'
import AddFundPopup from './AddFundPopup';

const AddFund = ({ Data ,APITOKEN,load}) => {
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const addFund = () => {
        setLowBalancePopup(prv => ({
            ...prv,
            display: true,
            ...Data
        }))
    }
    return (
        <>
        <div className="white bx-boder-common width100">
        <div className="_h_header">
            <h3>Add Funds</h3>
        </div>
        <div className="padding20">
            <div className="__sub__bx">
                <div className="__b_w">
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Your Current Balance</label>
                            <label className="plan_info_subscription">
                                <em className="curreny_icon">{ Data.currency}</em>
                                <strong className="inline-block fo_subscription margin-right15">{Data.balance }</strong>

                            </label>
                            <button className="grid_btn_1 grid_btn_darkBlue change_plan_subscriber" onClick={addFund}>Add Fund</button>
                    </div>
                </div>
            </div>
        </div>
            </div>
            {lowBlance.display ? <AddFundPopup APITOKEN={APITOKEN} lowBlance={lowBlance} setLowBalancePopup={setLowBalancePopup} removeWaterMark={() => load()} /> : <></>}
        </>
    )
  
}

export default AddFund