import React, { useEffect, useState } from 'react';
import { get as _get, cloneDeep as _cloneDeep, isEmpty as _isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { Link, useLocation } from 'react-router-dom';
import "react-modern-drawer/dist/index.css";
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';

import { getCountryList, createContactManually } from "../../../../services/crm";

const CreateMannually = () => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from UR
  const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const defaultContactData = { SubscriberListId: listID, rowIndex: 1, FirstName: "", LastName: "", Email: "", MobileNumber: "", Country: "Norway", CountryCode: "47", selectedCountry: "47,Norway" };
  const [contactRows, setContactRows] = useState([defaultContactData]);
  const [errors, setErrors] = useState({});

  const addContactRow = () => {
    const tmpContactRows = _cloneDeep({ ...defaultContactData, rowIndex: (contactRows || []).length + 1 });
    setContactRows([...contactRows, tmpContactRows]);
  }

  useEffect(() => {
    (async () => {
      try {

        const response = await getCountryList();
        setAllCountries(_get(response, "data", []));
      } catch (err) {
        console.log('Error occured when fetching list', err);
      }
    })();
  }, []);

  const onSubmit = () => {
    (async () => {
      try {
        setIsLoader(true);
        let payload = [];

        contactRows.forEach(row => {
          const { rowIndex, selectedCountry, emailValidated, ...rest } = row; // remove extra keys from payload
          payload.push(rest);
        });

        const response = await createContactManually({ SubsriberData: payload });

        setIsLoader(false);

        let isError = false;
        let formErrors = { 0: {} };

        if (!_isEmpty(_get(response, "data.data", []))) {
          _get(response, "data.data", []).forEach((element, i) => {
            if (_get(element, "isValid", "") === false) {
              isError = true;
              formErrors = { ...formErrors, [i]: { MobileNumber: _get(element, "notvalidReason.reason", "") } };
            }
          });
        }

        if (!isError) {
          navigate(`/crm/people/subscribers/${listID}/${creationSource}`, { state: { selecteListName: _get(location, "state.selecteListName", "All Contacts ") } });
        } else {
          setErrors(formErrors);
        }

      } catch (err) {
        console.log('Error occured when submitting data', err);
      }
    })();
  }

  const handleInputChange = (rowIndex, key, value) => {
    const updatedContactData = contactRows.map(row => (
      (_get(row, "rowIndex", 0) === rowIndex) ?
        { ...row, [key]: value, emailValidated: key === "Email" ? true : _get(row, "emailValidated", "") } :
        row
    ));
    setContactRows(updatedContactData);
  };

  const handleInputChangeCountry = (rowIndex, country) => {
    let countryName = "";
    let countryCode = "";

    if (country !== "") {
      let selectedCountry = country.split(",");
      countryCode = selectedCountry[0];
      countryName = selectedCountry[1];
    }

    const updatedContactData = contactRows.map(row => (
      (row.rowIndex === rowIndex) ? { ...row, "selectedCountry": country, "Country": countryName, "CountryCode": countryCode } : row)
    );

    setContactRows(updatedContactData);
  };

  const validateEmail = (email) => {
    return (email === "") ? true : /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateRows = () => {

    return contactRows.every((row) =>
      (!_get(row, "emailValidated", true) || validateEmail(_get(row, "Email", ""))) &&
      _get(row, "selectedCountry", "") !== ""
    );
  };

  const removeContactRow = (rowIndex) => {
    const updatedContactData = contactRows.filter(row => row.rowIndex !== rowIndex);

    //Re arrange keys after removing index from array
    let newContactRows = [];
    (updatedContactData || []).forEach((row, i) => {
      newContactRows.push({ ...row, rowIndex: i });
    });

    setContactRows(newContactRows);
  };

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum
            ToText="CRM List"
            To="/crm/people/listings"
            ToSecondPage={`/crm/people/subscribers/${listID}/${creationSource}`}
            ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
            CurrentPage="Import Manually"
            SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts ") }}
          />
        </div>

        <div className="bx-boder-common width100 nobg margin-bottom50">
          <div className="_h_header white border-radius2">
            <span className="flex-right">
              <span className="grid_btn_1 fright npn_filter" onClick={() => addContactRow()}>Add Mannually</span>
            </span>
          </div>
          <div className="aggreategrid dataTable-primary">
            <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
              <thead>
                <tr>
                  <th className="table_cell_header pt-3" width="20%">First name </th>
                  <th className="table_cell_header pt-3" width="20%">Last name</th>
                  <th className="table_cell_header pt-3" width="20%">Email</th>
                  <th className="table_cell_header pt-3" width="20%">Phone</th>
                  <th className="table_cell_header pt-3" width="20%">Country</th>
                  <th className="table_cell_header pt-3" width="30px"> </th>
                </tr>
              </thead>
              <tbody>

                {(contactRows || []).map((row, index) => {

                  return (
                    <tr key={_get(row, "rowIndex", 0)}>
                      <td className="body_table_cell">
                        <div>
                          <input
                            type="text"
                            className="inputformmodel width100"
                            value={_get(row, "FirstName", "")}
                            onChange={(e) => handleInputChange(_get(row, "rowIndex", 0), "FirstName", _get(e, "target.value", ""))}
                          />
                        </div>
                      </td>
                      <td className="body_table_cell">
                        <div>
                          <input
                            type="text"
                            className="inputformmodel width100"
                            value={_get(row, "LastName", "")}
                            onChange={(e) => handleInputChange(_get(row, "rowIndex", 0), "LastName", _get(e, "target.value", ""))}
                          />
                        </div>
                      </td>
                      <td className="body_table_cell">
                        <div>
                          <input
                            type="text"
                            className={`inputformmodel width100 ${(_get(row, "emailValidated", "") && !validateEmail(_get(row, "Email", ""))) ? "inputform-error" : ""}`}
                            value={_get(row, "Email", "")}
                            onChange={(e) => handleInputChange(_get(row, "rowIndex", 0), "Email", _get(e, "target.value", ""))}
                          />
                        </div>
                      </td>
                      <td className="body_table_cell">
                        <div>
                          <input
                            type="number"
                            className="inputformmodel width100"
                            value={_get(row, "MobileNumber", "")}
                            onChange={(e) => handleInputChange(_get(row, "rowIndex", 0), "MobileNumber", _get(e, "target.value", ""))}
                          />
                          {(_get(errors, `[${index}].MobileNumber`, "")) && (<p className="error-message">Phone number is invalid</p>)}
                        </div>
                      </td>
                      <td className="body_table_cell">
                        <select
                          value={_get(row, "selectedCountry", "")}
                          className={`inputformmodel ${(_get(row, "selectedCountry", "") === "") ? "inputform-error" : ""}`}
                          onChange={(e) => handleInputChangeCountry(_get(row, "rowIndex", 0), _get(e, "target.value", ""))}
                        >
                          <option value="">Select</option>
                          {(allCountries || []).map((country, key) => {
                            return (
                              <option
                                key={`${_get(row, "rowIndex", 0)}-${key}`}
                                value={`${_get(country, "CountryCode", "")},${_get(country, "CountryName", "")}`}
                              >
                                {_get(country, "CountryName", "")}
                              </option>
                            )
                          })}
                        </select>
                      </td>
                      <td className="body_table_cell">
                        <i className="fas fa-user-minus gray" onClick={() => removeContactRow(_get(row, "rowIndex", 0))}></i>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>

          <div className="bx-pad text-right white">
            <Link
              className="btn link-light me-2"
              to={`/crm/people/subscribers/${listID}/${creationSource}`}
              state={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
            >
              Cancel
            </Link>
            <button className="btn btn-success" disabled={!validateRows()} onClick={() => onSubmit()}>
              <ButtonLoader IsLoader={isLoader}>
                Continue
              </ButtonLoader>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CreateMannually;