import React, { useEffect, useState, useCallback, Fragment } from 'react';
import { get as _get, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import { Datepicker } from "@mobiscroll/react";
import DataTable from 'react-data-table-component';
import Select from 'react-select';
import moment from "moment";

import { getSegmentationFields, getSegmentQueryJson, getSegmentOperators, submitSegmentation, updateLoyaltyPoints } from "../../../../../services/crm";
import getLocaleDateString from '../../../../../TimeFormat';

import ButtonLoader from '../../../../../ReuseTemplate/ButtonLoader';
import BreadCrum from "../../../../../ReuseTemplate/BreadCrum";

const SegmentCreate = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get List ID from URL
  const creationSource = _get(pathArray, "[5]", null); // get creationSource from URL
  const segmentID = _get(pathArray, "[8]", null); // get segment id from URL
  const [isLoader, setIsLoader] = useState(false);
  const [isSaveBtnLoader, setIsSaveBtnLoader] = useState(false);
  const [segmentationFieldsData, setSegmentationFieldsData] = useState([]);
  const [segmentJSONData, setSegmentJSONData] = useState([]);
  const [valueFieldJSON, setValueFieldJSON] = useState([]); // Second drop down in each row
  //const [btnDisplay, setBtnDisplay] = useState({ testButton: true, saveButton: false, testDisabled: true, notAPIHitPanelShow: false });
  //const [segmentResonseData, setSegmentResonseData] = useState({});
  const [segmentTableColumns, setSegmentTableColumns] = useState([]);
  const [segmentTableColumnsData, setsegmentTableColumnsData] = useState([]);
  const [resultRecordCount, setResultRecordCount] = useState(0);
  const [showContactListSection, setShowContactListSection] = useState(false);
  const [isLoyaltyProgramSelected, setIsLoyaltyProgramSelected] = useState(false);
  const { APITOKEN } = useSelector(state => state);
  const f = getLocaleDateString(APITOKEN.config.cultureCode);

  const checkLoyaltyProgram = (data) => {
    return Object.values(data).some(innerObj =>
      Object.values(innerObj).some(item => item?.firstDropDownValue?.FieldType === 8)
    );
  };

  const fetchSegmentationFields = useCallback(() => {
    (async () => {
      try {

        const payload = {
          subscriberListId: listID,
        }

        const response = await getSegmentationFields(payload);

        let tmpSegmentData = _cloneDeep(_get(response, "data.data", []));
        tmpSegmentData = tmpSegmentData.map((segment, i) => {

          if (_get(segment, "FieldType", "") === 0) {

            return segment = { ...segment, Field_Name: "Default fields" }
          } else if (_get(segment, "FieldType", "") === 1) {

            return segment = { ...segment, Field_Name: "Custom fields" }
          } else if (_get(segment, "FieldType", "") === 2) {

            return segment = { ...segment, Field_Name: "SMS" }
          } else if (_get(segment, "FieldType", "") === 6) {

            const landingpage = _get(segment, "data", []).filter((item) => _get(item, "CampaignType", "") === 2);
            const npsEmailPage = _get(segment, "data", []).filter((item) => _get(item, "CampaignType", "") === 5);

            return segment = { ...segment, Field_Name: "NPS", npsCampaign: [{ name: 'Landing Page', data: landingpage }, { name: 'Email', data: npsEmailPage }] }
          } else if (_get(segment, "FieldType", "") === 7) {

            return segment = { ...segment, Field_Name: "Stamps" }
          } else if (_get(segment, "FieldType", "") === 8) {

            return segment = { ...segment, Field_Name: "Loyalty Program" }
          }
          return segment;
        });
        setSegmentationFieldsData(tmpSegmentData);
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  }, [listID])

  const fetchSegmentationQueryJSON = useCallback(() => {
    (async () => {
      try {
        let jsonData = {};
        let tmpValueFieldJSON = { 0: { firstDropDownValue: { FieldType: 0, Field_Name: "Default fields" } } };
        const payload = {
          segmentId: segmentID,
          subscriberListId: listID,
        }

        const response = await getSegmentQueryJson(payload);
        //console.log("resonseJSONData: ", response);

        if (_get(response, "data.data[0].SelectedJsonData", null) !== null) {
          let responseJSONData = JSON.parse(_get(response, "data.data[0].SelectedJsonData", ""));
          //console.log("jsonData, valueFieldJSON, segmentationFieldsData: ", responseJSONData, valueFieldJSON, segmentationFieldsData);
          let isLoyaltyProgram = false;

          (responseJSONData || []).forEach((ele, i) => {
            jsonData[i] = ele;
            tmpValueFieldJSON = { ...tmpValueFieldJSON, [i]: { 0: {} } }
            console.log("ele: ", ele);
            (_get(ele, "array", [])).forEach((element, orIndex) => {
              //console.log("element 111111: ", element, _get(element, "OrFieldValue", ""), segmentationFieldsData, i, orIndex);
              jsonData[i]["array"][orIndex] = element;
              //console.log("CHECK: ", segmentationFieldsData, _get(element, "OrFieldValue", ""), (segmentationFieldsData || []).find(item => _get(item, "OrFieldValue", "") === _get(element, "Field_Name", ""))); 

              let firstDropdown = (segmentationFieldsData || []).find(item => _get(item, "Field_Name", "") === _get(element, "OrFieldValue", ""));
              console.log("firstDropdown xx: ", firstDropdown);
              if (_get(firstDropdown, "FieldType", 0) === 8) {
                isLoyaltyProgram = true;
              }

              let secondDropdown = _get(firstDropdown, "data", []).find(item => _get(item, "ElasticLabelName", "") === _get(element, "OrValue", ""));
              //console.log("secondDropdown: ", secondDropdown);
              let thirdDropdown = _get(element, "Operaterarray", []).find(item => _get(item, "name", "") === _get(element, "ConditionValue", ""));
              let fourthDropDown = _get(element, "OperaterValue", "");
              if ((_get(element, "htmlControlType", "") === "ListBox") || (_get(element, "htmlControlType", "") === "DropDown")) {
                //console.log("fourthDropDown inner: ", _get(element, "htmlControlTypeMetaJSON", []));
                fourthDropDown = _get(element, "htmlControlTypeMetaJSON", []).find(item => _get(item, "value", "") === _get(element, "OperaterValue", ""));
              }
              //console.log("fourthDropDown: ", fourthDropDown, i);
              tmpValueFieldJSON = {
                ...tmpValueFieldJSON,
                [i]: {
                  ...tmpValueFieldJSON[i],
                  [orIndex]: {
                    ...tmpValueFieldJSON[i][orIndex],
                    firstDropDownValue: firstDropdown,
                    secondDropDownData: _get(firstDropdown, "data", []),
                    secondDropDownDataValue: secondDropdown,
                    thirdDropDownDataValue: thirdDropdown,
                    fourthDropDownValue: fourthDropDown
                  }
                }
              }
            });

          });

          if (isLoyaltyProgram === true) {
            setIsLoyaltyProgramSelected(true);
          }
          setSegmentJSONData(jsonData);
          if (!_isEmpty(responseJSONData)) {
            submitTestFilter(true, true, jsonData, isLoyaltyProgram);
          }
        } else {

          jsonData = {
            0: {
              Name: "Or",
              array: {
                0: {
                  Name: "And",
                  OrFieldValue: 'Define a rule 1',
                  OrValue: 'Define a rule based on',
                  ConditionValue: 'Define a rule based on',
                }
              }
            }
          };
          setSegmentJSONData(jsonData);
        }
        setValueFieldJSON(tmpValueFieldJSON);
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listID, segmentID, segmentationFieldsData])

  //First Time Load
  useEffect(() => {
    (async () => {
      try {

        fetchSegmentationFields();
      } catch (err) {
        console.log('Error occured when fetching archived contacts list', err);
      }
    })();
  }, [fetchSegmentationFields]);


  useEffect(() => {
    (async () => {

      if (!_isEmpty(segmentationFieldsData)) {
        try {

          fetchSegmentationQueryJSON();
        } catch (err) {
          console.log('Error occured when fetching archived contacts list', err);
        }
      }
    })();
  }, [segmentationFieldsData, fetchSegmentationQueryJSON]);

  //const fetchSegmentOperators = useCallback((orObj, selectedOption) => {
  const fetchSegmentOperators = async (orObj, selectedOption) => {
    try {
      const payload = {
        propertyId: _get(selectedOption, "FieldId", ""),
        propertyType: _get(selectedOption, "FieldType", ""),
      }

      const response = await getSegmentOperators(payload);
      let operatorData = _get(response, "data.data", []);

      operatorData.forEach(element => {
        if (element['SQLOperator'] === "between") {
          element['isbettween'] = true;
        } else {
          element['isbettween'] = false;
        }
      });

      return operatorData;
    } catch (error) {
      //console.log("Error uploading: ", error);
    }
  }

  const createSegment = async (selectedOption, segment, orIndex, i, orSegment, fieldType, isDatePicker = false, isDateRangePicker = false) => {
    let tmpSegmentObject = segmentJSONData;
    let tmpValueFieldJSON = valueFieldJSON;

    //Fetch Operators on Change/Select fields

    if (fieldType === "1") {
      // Set selected value for first dropdown in payload array
      tmpSegmentObject = {
        ...tmpSegmentObject,
        [i]: {
          ...tmpSegmentObject[i],
          "selected": true,
          "array": {
            ...tmpSegmentObject[i]["array"],
            [orIndex]: {
              ...tmpSegmentObject[i]["array"][orIndex],
              "OrFieldValue": _get(selectedOption, "Field_Name", ""),
              "selected": true,
              "OrValue": "", // Reset set values after selecting second dropdown in pyload
              "OrShowingValue": "", // Reset set values after selecting second dropdown in pyload
              "IndexName": "", // Reset set values after selecting second dropdown in pyload
              "ConditionValue": "", // Reset third dropdown value in pyload 
              "OperaterValue": "",  // Reset fourth dropdown value in pyload
            }
          }
        }
      }

      // Set state management for first dropdown selected object to show selected values
      tmpValueFieldJSON = {
        ...tmpValueFieldJSON,
        [i]: {
          ...tmpValueFieldJSON[i],
          [orIndex]: {
            ...tmpValueFieldJSON[i][orIndex],
            firstDropDownValue: selectedOption,
            secondDropDownData: _get(selectedOption, "data", ""),
            secondDropDownDataValue: {},
            thirdDropDownDataValue: {},
            fourthDropDownValue: {}
          }
        }
      }

      // set selected loyalty flag true if Loyalty program is selected
      if (checkLoyaltyProgram(tmpValueFieldJSON)) {
        setIsLoyaltyProgramSelected(true);
      } else {
        setIsLoyaltyProgramSelected(false);
      }

      setValueFieldJSON(tmpValueFieldJSON);
    } else if (fieldType === "2") {
      // Set state management for secong dropdown selected object to show selected values
      tmpValueFieldJSON = { ...tmpValueFieldJSON, [i]: { ...tmpValueFieldJSON[i], [orIndex]: { ...tmpValueFieldJSON[i][orIndex], secondDropDownDataValue: selectedOption, thirdDropDownDataValue: {}, fourthDropDownValue: {} } } }
      setValueFieldJSON(tmpValueFieldJSON);

      // Fetch Operators for third dropdown 
      const operatorData = await fetchSegmentOperators(orSegment, selectedOption);

      tmpSegmentObject = {
        ...tmpSegmentObject,
        [i]: {
          ...tmpSegmentObject[i],
          "array": {
            ...tmpSegmentObject[i]["array"],
            [orIndex]: {
              ...tmpSegmentObject[i]["array"][orIndex],
              "OrValue": (_get(selectedOption, "ElasticLabelName", "") === "") ? "Define a rule based on" : _get(selectedOption, "ElasticLabelName", ""),
              "OrShowingValue": (_get(selectedOption, "DisplayName", "") === "") ? "Define a rule based on" : _get(selectedOption, "DisplayName", ""),
              "IndexName": (_get(selectedOption, "IndexName", "") === "") ? "" : _get(selectedOption, "IndexName", ""),
              "Operaterarray": operatorData,
              "ConditionFieldId": _get(selectedOption, "FieldId", ""),
              "ConditionFieldType": _get(selectedOption, "FieldType", ""),
              "ConditionValue": "", // Reset third dropdown value in pyload 
              "OperaterValue": "",  // Reset fourth dropdown value in pyload
              "htmlControlType": (_get(selectedOption, "HTMLControlType", "") !== "") ? _get(selectedOption, "HTMLControlType", "") : "",
              "htmlControlTypeMetaJSON": ((typeof _get(selectedOption, "JsonMetaData", "") !== "undefined") && (_get(selectedOption, "JsonMetaData", "") !== "")) ? JSON.parse(_get(selectedOption, "JsonMetaData", "")) : [],
              "calenderFlag": _get(selectedOption, "DataType", "")
            }
          }
        }
      }
    } else if (fieldType === "3") {
      // Set state management for third dropdown selected object to show selected values
      tmpValueFieldJSON = { ...tmpValueFieldJSON, [i]: { ...tmpValueFieldJSON[i], [orIndex]: { ...tmpValueFieldJSON[i][orIndex], thirdDropDownDataValue: selectedOption, fourthDropDownValue: {} } } }
      setValueFieldJSON(tmpValueFieldJSON);

      const isShow = _get(selectedOption, "IsShow", false);
      const isBettween = (_get(selectedOption, "SQLOperator", "") === "between") ? _get(selectedOption, "isbettween", false) : false;
      const SQLOperator = _get(selectedOption, "SQLOperator", "");

      let calenderFlag = _get(tmpSegmentObject, `[${i}]["array"][${orIndex}]['calenderFlag']`, "");
      if ((calenderFlag === "date") || (calenderFlag === "dateRange")) {
        if (SQLOperator !== "isProvided" || SQLOperator !== "isNotProvided") {
          if (_get(selectedOption, "IsRangeCalender", "")) {
            calenderFlag = 'dateRange';
          } else {
            calenderFlag = 'date';
          }
        }
      }

      let tmpObj = {
        ...tmpSegmentObject[i]["array"][orIndex],
        "ConditionValue": _get(selectedOption, "name", ""),
        "Condition": SQLOperator,
        "OperaterValue": "",  // Reset fourth dropdown value in pyload
        "IsShow": isShow,
        "calenderFlag": calenderFlag
      };

      if (isBettween === true) {
        tmpObj = { ...tmpObj, isbettween: isBettween };
      } else {
        delete tmpObj.isbettween;
      }

      tmpSegmentObject = {
        ...tmpSegmentObject,
        [i]: {
          ...tmpSegmentObject[i],
          "array": {
            ...tmpSegmentObject[i]["array"],
            [orIndex]: tmpObj
          }
        }
      }
    } else if (fieldType === "4") {
      let oprtatorValue = selectedOption;

      if (typeof selectedOption === "object" && isDatePicker === false && isDateRangePicker === false) {
        oprtatorValue = _get(selectedOption, "value", "");
      }
      if (isDatePicker === true) {
        oprtatorValue = moment(selectedOption).format("YYYY-MM-DD");
      }
      if (isDateRangePicker === true) {
        oprtatorValue = (!_isEmpty(selectedOption)) ? [moment(selectedOption[0]).format('YYYY-MM-DD'), moment(selectedOption[1]).format('YYYY-MM-DD')] : [];
      }
      // Set state management for fourth dropdown selected object to show selected values
      tmpValueFieldJSON = { ...tmpValueFieldJSON, [i]: { ...tmpValueFieldJSON[i], [orIndex]: { ...tmpValueFieldJSON[i][orIndex], fourthDropDownValue: selectedOption } } }

      setValueFieldJSON(tmpValueFieldJSON);

      tmpSegmentObject = {
        ...tmpSegmentObject,
        [i]: {
          ...tmpSegmentObject[i],
          "array": {
            ...tmpSegmentObject[i]["array"],
            [orIndex]: {
              ...tmpSegmentObject[i]["array"][orIndex],
              "OperaterValue": oprtatorValue
            }
          }
        }
      }
    }
    setSegmentJSONData(tmpSegmentObject);
  }

  const addInnerRow = (i, orIndex) => {
    let tmpSegmentObject = _cloneDeep(segmentJSONData);
    tmpSegmentObject = {
      ...tmpSegmentObject,
      [i]: {
        ...tmpSegmentObject[i],
        "array": {
          ...tmpSegmentObject[i]["array"],
          [orIndex + 1]: {
            "Name": "And",
            "OrFieldValue": 'Define a rule 1',
            "OrValue": 'Define a rule based on',
            "ConditionValue": 'Define a rule based on',
          }
        }
      }
    }

    setSegmentJSONData(tmpSegmentObject);
  }

  const addOuterRow = (i) => {
    let tmpSegmentObject = _cloneDeep(segmentJSONData);
    let tmpValueFieldJSON = _cloneDeep(valueFieldJSON);

    tmpSegmentObject = {
      ...tmpSegmentObject,
      [i + 1]: {
        //...tmpSegmentObject[i],
        "Name": "Or",
        "array": {
          //...tmpSegmentObject[i]["array"],
          "0": {
            "Name": "And",
            "OrFieldValue": 'Define a rule 1',
            "OrValue": 'Define a rule based on',
            "ConditionValue": 'Define a rule based on',
          }
        }
      }
    }

    tmpValueFieldJSON = {
      ...tmpValueFieldJSON, [i + 1]: { 0: {} }
    }

    setSegmentJSONData(tmpSegmentObject);
    setValueFieldJSON(tmpValueFieldJSON);
  }

  const deleteSegment = (i, orIndex) => {
    let tmpSegmentObject = _cloneDeep(segmentJSONData);
    let tmpValueFieldJSON = _cloneDeep(valueFieldJSON);

    delete tmpSegmentObject[i]["array"][orIndex];
    delete tmpValueFieldJSON[i][orIndex];

    if ((i === 0) && _isEmpty(tmpSegmentObject[i]["array"])) {
      tmpSegmentObject = {
        ...tmpSegmentObject,
        [i]: {
          ...tmpSegmentObject[i],
          "array": {
            ...tmpSegmentObject[i]["array"],
            0: {
              "Name": "And",
              "OrFieldValue": 'Define a rule 1',
              "OrValue": 'Define a rule based on',
              "ConditionValue": 'Define a rule based on',
            }
          }
        }
      }
      tmpValueFieldJSON = { 0: { 0: {} } };
    } else {
      if (_isEmpty(tmpSegmentObject[i]["array"])) {
        delete tmpSegmentObject[i];
        delete tmpValueFieldJSON[i];
      }
    }


    setSegmentJSONData(tmpSegmentObject);
    setValueFieldJSON(tmpValueFieldJSON);
  }

  const submitTestFilter = async (isSegmentEdit, isDefaultFetchSegmentData = false, jsonData, isLoyaltyProgram = false) => {
    if (isSegmentEdit === true) {
      setIsLoader(true);
    } else {
      setIsSaveBtnLoader(true);
    }

    let tmpisSelectedLoyaltyProgram = (isLoyaltyProgramSelected || false);
    if (isLoyaltyProgram === true) {
      tmpisSelectedLoyaltyProgram = true;
    }
    // If Loyalty program is selected then update loyalty points in CRM
    if (tmpisSelectedLoyaltyProgram === true) {

      const resLoyalty = await updateLoyaltyPoints({ listId: listID });
      if (_get(resLoyalty, "data.Status", null) !== 200) {
        return false;
      }
    }

    let tmpSegmentObject = (isDefaultFetchSegmentData === true) ? _cloneDeep(jsonData) : _cloneDeep(segmentJSONData);

    let segmentationPayload = [];
    Object.entries((tmpSegmentObject || {})).map(([orIndex, segments]) => {

      const segmentArray = _get(segments, "array", []);
      const segArr = Object.keys(segmentArray).map((andIndex) => segmentArray[andIndex]);
      segmentationPayload.push({ "Name": _get(segments, "Name", ""), "array": segArr, orpipe: true, selected: true });
      return true;
    });

    const finalPayload = {
      "IsSegmentEdit": isSegmentEdit,
      "segmentId": (isSegmentEdit === true) ? "null" : segmentID,
      "segmentQueryJson": JSON.stringify(segmentationPayload),
      "subscriberListId": listID
    }

    const response = await submitSegmentation(finalPayload);

    setIsLoader(false);
    setIsSaveBtnLoader(false);
    if (isSegmentEdit === false) {
      navigate(`/crm/people/subscribers/${(listID || "")}/1/segment/list`, { state: { selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") } });

    } else {
      const tableData = _get(response, "data.data.data", []);
      let segmentTableColumns = [];
      let segmentTableData = [];
      if (!_isEmpty(tableData)) {
        tableData.forEach(element => {
          segmentTableData.push(_get(element, "_source", {}));
        });

        segmentTableColumns = [{
          name: "FirstName",
          selector: (item) => (_get(item, `[FirstName]`, "")),
          minWidth: "200px",
        },
        {
          name: "LastName",
          selector: (item) => (_get(item, `[LastName]`, "")),
          minWidth: "200px",
        },
        {
          name: "Email",
          selector: (item) => (_get(item, `[Email]`, "")),
          minWidth: "200px",
        },
        {
          name: "MobileCountryCode",
          selector: (item) => (_get(item, `[MobileCountryCode]`, "")),
          minWidth: "200px",
        },
        {
          name: "MobileNumber",
          selector: (item) => (_get(item, `[MobileNumber]`, "")),
          minWidth: "200px",
        }];
        Object.keys(_get(tableData, `[0]._source`, {})).map((rowKey, i) => {

          if (!['FirstName', 'LastName', 'Email', 'MobileCountryCode', 'MobileNumber', "PeopleSubscriberId", "PeopleId", "SubscriberListId", "ListId", "AdminId", "DateSubscribed", "CreationTypeId", "SubscriberListName", "elasticId", "ElasticId", "Token", "SubscriberListPkId", "MarketingSingleOptInSmsText", "MarketingSingleOptInEmailText", "LandingPageDistributionStatus", "LandingPageEngagmentStatus", "NPSJsonData", "RecepientId", "SMSDistributionStatus", "QueueId", "StampsMetaData"].includes((rowKey || ""))) {
            return (
              segmentTableColumns.push({
                name: rowKey,
                selector: (item) => (_get(item, `[${rowKey}]`, "")),
                minWidth: "200px",
              })
            )
          }
          return true;
        });
      }
      setResultRecordCount(_get(response, "data.data.count", 0));
      setSegmentTableColumns(segmentTableColumns);
      setsegmentTableColumnsData(segmentTableData);
      setShowContactListSection(true);
    }
  }

  return (
    <>
      <div className="wrapper-full margin-top100">
        <div className="breadcrum">
          <BreadCrum
            ToText="CRM List"
            To="/crm/people/listings"
            ToSecondPage={`/crm/people/subscribers/${(listID || "")}/${(creationSource || "")}/`}
            SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
            ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
            ToThirdPage={`/crm/people/subscribers/${(listID || "")}/${(creationSource || "")}/segment/list`}
            ThirdBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
            ToThirdPageText="Segments"
            CurrentPage="Create a new segment"
          />
        </div>
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header noborder">
            <h3>Define Segment</h3>
          </div>

          <div className="">
            <div>

              {Object.entries((segmentJSONData || [])).map(([i, segment]) => {
                const isOrButtonDisabled = (typeof segmentJSONData[i + 1] !== "undefined") ? true : false;
                //const isOrButtonDisabled = _get(segment, "selected", false);
                return (
                  <>
                    <div key={i} className="segment-wrap-and">
                      {Object.entries((_get(segment, "array", []))).map(([orIndex, orSegment]) => {

                        const isDisabledAndButton = (typeof segmentJSONData[i]["array"][orIndex + 1] !== "undefined") ? true : false;
                        return (
                          <div key={orIndex} className="row align-items-center gap-5 mb-2">
                            {(_get(orSegment, "selected", false) === true) && (
                              <div className={`segment-and`}>
                                <span>
                                  <button className="segement-andbtn" onClick={() => addInnerRow(i, orIndex)} disabled={isDisabledAndButton}>
                                    <i className="fal fa-plus-circle" aria-hidden="true"></i> AND
                                  </button>
                                </span>
                              </div>
                            )}
                            <div className="col-segment col-segment-second">
                              <Select
                                value={_get(valueFieldJSON, `[${i}][${orIndex}].firstDropDownValue`, {})}
                                name="language"
                                className="custom-multi-select"
                                classNamePrefix="select"
                                options={(segmentationFieldsData || [])}
                                getOptionLabel={(option) => (_get(option, "Field_Name", ""))}
                                getOptionValue={(option) => (_get(option, "Field_Name", ""))}
                                onChange={(selectedOption) => createSegment(selectedOption, segment, orIndex, i, orSegment, '1')}
                              />
                            </div>

                            {(_get(orSegment, "selected", false) === true) && (
                              <Fragment>
                                <div className="col-segment">
                                  <Select
                                    value={_get(valueFieldJSON, `[${i}][${orIndex}].secondDropDownDataValue`, {})}
                                    name="language"
                                    className="custom-multi-select"
                                    classNamePrefix="select"
                                    options={_get(valueFieldJSON, `[${i}][${orIndex}].secondDropDownData`, [])}
                                    getOptionLabel={(option) => (_get(option, "FeildName", ""))}
                                    getOptionValue={(option) => (_get(option, "FeildName", ""))}
                                    onChange={(selectedOption) => { createSegment(selectedOption, segment, orIndex, i, orSegment, '2') }}
                                  />
                                </div>

                                <div className="col-segment">
                                  <Select
                                    value={_get(valueFieldJSON, `[${i}][${orIndex}].thirdDropDownDataValue`, {})}
                                    name="language"
                                    className="custom-multi-select"
                                    classNamePrefix="select"
                                    options={_get(orSegment, `Operaterarray`, [])}
                                    getOptionLabel={(option) => (_get(option, "name", ""))}
                                    getOptionValue={(option) => (_get(option, "name", ""))}
                                    onChange={(selectedOption) => { createSegment(selectedOption, segment, orIndex, i, orSegment, '3') }}
                                  />
                                </div>

                                <div className="col-segment">
                                  {(!_isEmpty(_get(orSegment, "ConditionValue", ""))) &&
                                    (["ListBox", "DropDown"].includes(_get(orSegment, "htmlControlType", ""))) &&
                                    (
                                      <Select
                                        value={_get(valueFieldJSON, `[${i}][${orIndex}].fourthDropDownValue`, {})}
                                        name="language"
                                        className="custom-multi-select"
                                        classNamePrefix="select"
                                        options={_get(orSegment, `htmlControlTypeMetaJSON`, [])}
                                        getOptionLabel={(option) => (_get(option, "value", ""))}
                                        getOptionValue={(option) => (_get(option, "id", ""))}
                                        onChange={(selectedOption) => { createSegment(selectedOption, segment, orIndex, i, orSegment, '4') }}
                                      />
                                    )}

                                  {(!_isEmpty(_get(orSegment, "ConditionValue", ""))) &&
                                    (!["ListBox", "DropDown", "Calender"].includes(_get(orSegment, "htmlControlType", ""))) &&
                                    (
                                      <input
                                        type="text"
                                        className="inputformmodel width100"
                                        value={_get(orSegment, "OperaterValue", "")}
                                        onChange={(e) => createSegment(_get(e, "target.value", ""), segment, orIndex, i, orSegment, '4')}
                                      />
                                    )}

                                  {(!_isEmpty(_get(orSegment, "ConditionValue", ""))) &&
                                    (["Calender"].includes(_get(orSegment, "htmlControlType", ""))) &&
                                    (_get(orSegment, "isbettween", false) !== true) &&
                                    (
                                      <Datepicker className="inputformmodel "
                                        value={_get(orSegment, "OperaterValue", "")}
                                        onChange={e => createSegment(_get(e, "value", ""), segment, orIndex, i, orSegment, '4', true)}
                                        //onClose={e => updateProfileFields(row, _get(e, "value", ""))}
                                        dataTimezone='utc'
                                        dateFormat={f}
                                        locale={APITOKEN.config.countryCode}
                                        controls={['calendar']}
                                        placeholder="Choose a date"
                                        touchUi={true}
                                        theme="ios"
                                        themeVariant="dark"
                                      />
                                    )}

                                  {(!_isEmpty(_get(orSegment, "ConditionValue", ""))) &&
                                    (["Calender"].includes(_get(orSegment, "htmlControlType", ""))) &&
                                    (_get(orSegment, "isbettween", false) === true) &&
                                    (
                                      <Datepicker className="inputformmodel "
                                        value={_get(orSegment, "OperaterValue", "")}
                                        select="range"
                                        onChange={e => createSegment(_get(e, "value", ""), segment, orIndex, i, orSegment, '4', false, true)}
                                        //onClose={e => updateProfileFields(row, _get(e, "value", ""))}
                                        dataTimezone='utc'
                                        dateFormat="YYYY-MM-DD"
                                        locale={APITOKEN.config.countryCode}
                                        controls={['calendar']}
                                        placeholder="Choose a date"
                                        touchUi={true}
                                        theme="ios"
                                        themeVariant="dark"
                                      />
                                    )}
                                </div>
                                <div className="col">
                                  <p className="text-07f cursor-pointer" onClick={() => deleteSegment(i, orIndex)}>Delete</p>
                                </div>
                              </Fragment>
                            )}

                          </div>
                        )
                      })}
                    </div>
                    {(_get(segment, "selected", false) === true) && (
                      <div className="segment-wrap-or">
                        <span>
                          <button className="segement-andbtn" onClick={() => addOuterRow(i)} disabled={isOrButtonDisabled}>
                            <i className="fal fa-plus-circle" aria-hidden="true"></i> OR
                          </button>
                        </span>
                      </div>
                    )}
                  </>
                )
              })}
            </div>
          </div>
        </div>

        <div className="text-right mt-3 mb-12">

          <button
            type="button"
            className="btn btn-primary btn-gape-15 me-4"
            onClick={() => submitTestFilter(false)}
            disabled={((_get(segmentJSONData, "[0].array[0].OrFieldValue", "") === "Define a rule 1") || ((isLoader || false) === true)) ? true : false}
          >
            <ButtonLoader IsLoader={isSaveBtnLoader}>
              Save
            </ButtonLoader>
          </button>

          <button
            type="button"
            className="btn btn-primary btn-gape-15"
            onClick={() => submitTestFilter(true)}
            disabled={((_get(segmentJSONData, "[0].array[0].OrFieldValue", "") === "Define a rule 1") || ((isSaveBtnLoader || false) === true)) ? true : false}
          >
            <ButtonLoader IsLoader={isLoader}>
              Test Filter
            </ButtonLoader>
          </button>
        </div>

        {((showContactListSection || false) === true) && (
          <Fragment>
            <div className="text-right mt-12 mb-3">
            </div>

            <div className="bx-boder-common common_Sec_1 mb-10">
              <div className="_h_header">
                <h3>Segmented Contacts {resultRecordCount}</h3>
              </div>

              <div className="pb-5">
                <div className="row">
                  <div className="col-12">
                    <div className="dataTable-primary dataTable-primary-510">
                      <DataTable
                        columns={segmentTableColumns}
                        data={segmentTableColumnsData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div >
    </>
  )
}

export default SegmentCreate;