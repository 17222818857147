import { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

export default function ColumnHorizental({ Data }) {
  const [optionData, setOptionValue] = useState({
    colors: ["#e8f086", "#6fde6e", "#ff4241", "#a691ae", "#235fa4"],
    chart: {
      type: "column",
    },
    title: {
      align: "left",
      useHTML: true,
      style: {
        "font-size": "18px",
        color: "#000",
        "margin-bottom": "20px",
        "font-family": "roboto",
      },
    },
    accessibility: {
      enabled: false,
    },
    subtitle: {
      align: "left",
      useHTML: true,
      style: {
        "font-size": "18px",
        color: "#000",
        marginTop: "20px",
        "font-family": "roboto",
      },
    },
    yAxis: {
      title: {
        text: "",
      },
    },

    xAxis: {
      accessibility: {
        rangeDescription: "",
      },
    },

    legend: {
      layout: "horizontal",
    },
    tooltip: {
      useHTML: true,
      borderColor: "#fff",
      borderRadius: 3,
      borderWidth: 1,
      backgroundColor: "#fff",
      formatter: function () {
        return `<div style="width:150px;font-size:14px;"><p  style="color:#666;font-size:15px;font-weight:bold;">${this.point.series.name}</p><p style="margin-bottom:10px;color:#666">Total Count : ${this.point.y}</p></div>`;
      },
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              layout: "horizontal",
              align: "center",
              verticalAlign: "bottom",
            },
          },
        },
      ],
    },
  });
  const Chart = useCallback(() => {
    if (Data["MatrixResponse"] !== undefined) {
      const newarray = _.unionBy(Data["MatrixResponse"], "optionRawId");
      const filterData = [];
      const catgoriesName = [];
      newarray.forEach(($v, $i) => {
        filterData.push({ name: "", data: [], newNameText: [] });
        filterData[$i].maintitle = Data.WidgetName;
        for (let x of Data["MatrixResponse"]) {
          if ($v["optionRawId"] === x["optionRawId"]) {
            const count = x["optionResponse"][0]["totalCount"];
            filterData[$i].name = x["optionRowText"];
            catgoriesName.push(x["optionRowText"]);
            filterData[$i].data.push(count);
          }
          filterData[$i].newNameText.push({
            tag: x.optionResponse[0].optionText,
          });
          filterData[$i].newNameText = _.unionBy(
            filterData[$i].newNameText,
            "tag"
          );
        }
      });
      setOptionValue(prv => ({
        ...prv,
        series: filterData,
        xAxis: {
          categories: filterData[0].newNameText.map(item => item.tag),
        },
        title: {
          text: `${filterData[0]?.name}`,
        },
        subtitle: {
          text: `${
            filterData[0]["title"] !== undefined
              ? "Answer: " + filterData[0].title
              : ""
          }`,
        },
      }));
    }
  }, [Data]);
  useEffect(() => {
    Chart();
  }, [Chart]);
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={optionData} />
    </div>
  );
}
