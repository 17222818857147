import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import { useParams } from "react-router";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";


const UTMSlider = ({ sliderEvent, campaignUTMTemplateURL, campaignShortURL, ...props }) => {
    const {APITOKEN} = useSelector(state => state);
    const [utmTemplateData, setUTMSelectData] = useState({ data: [], selectedValue: "" });
    const [shortUrlData, setShortUrlSelectData] = useState({ data: [], selectedValue: "" });
    const [utmName, setUTMName] = useState(props.EditUTMValue.name);
    const { id } = useParams();
    const [IsLoader, setIsLoader] = useState(false);

    const { templateId  } = props.EditUTMValue;
    const { EditId } = props;
    // const [disabledButton, setDisabledButton] = useState(true);
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`UTM/GetUTMTemplates`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setUTMSelectData({ data: res['data'], selectedValue: templateId && templateId.toLowerCase() });
                } catch (err) {
                    console.log(err);
                }
            });
    }, [APITOKEN, templateId])
    const shortURLload = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Lookup/ShortnerUrls`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setShortUrlSelectData({ data: res['data'], selectedValue: "" });
                } catch (err) {
                    console.log(err);
                }
            });
    }, [APITOKEN])
   
    const saveUTMCode = () => {
        const obj = {
            "name": utmName,
            "templateId": utmTemplateData.selectedValue,
            "campaignId": id,
            "customShortUrl": shortUrlData.selectedValue
        };
        setIsLoader(true);
        if (props.EditUTMValue.hasOwnProperty('templateId')) {
            putHit(obj);
            
        } else {
            postHit(obj)
        }

    }
    const putHit = (obj) => {
        Client.put(`UTM/CampaignUTMTemplateURL/${EditId}`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                if (res.data.success) {
                    sliderEvent(false);
                    campaignUTMTemplateURL();
                    campaignShortURL();
                    setIsLoader(false);
                }
            } catch (err) {
                console.log(err);
                setIsLoader(false);
            }
        });
    }
    const postHit = (obj) => {
        Client.post(`UTM/CampaignUTMTemplateURL`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                if (res.data.success) {
                    sliderEvent(false);
                    campaignUTMTemplateURL();
                    campaignShortURL();
                    setIsLoader(false);
                }
            } catch (err) {
                console.log(err);
                setIsLoader(false);
            }
        });
    }
    
    // const checkValidation = ({shorts,name,template}) => {
    //     if (name !== undefined && shorts === "" && template !== undefined) {
    //         setDisabledButton(false);
    //     } else {
    //         setDisabledButton(true);
    //     }
    // }
    useEffect(() => {
        load();
        shortURLload();
        if (props.EditUTMValue.hasOwnProperty('templateId')) {
            // setDisabledButton(false)
        }
    }, [load, shortURLload,props.EditUTMValue])
    return (
        <>
            <div className="UTM_codes_URL_body">
                <div className="topn-header">
                    <span>UTM Code</span>
                    <span style={{ padding: '20px', float: 'right' }}>
                        <i className="fa fal fa-times _utm_pops_cancel fright pointer" onClick={() => sliderEvent(false)}></i>
                    </span>
                </div>
                <div className="padding20">
                    <div className="formmodel-bx urlName">
                        <label className="_l_able_cls">UTM URL name</label>
                        <input type="text" className="inputformmodel width100" placeholder="Name" name="name" defaultValue={utmName}
                            onChange={e =>  setUTMName(e.target.value)} />
                    </div>
                    <div className="formmodel-bx urlName">
                        <label className="_l_able_cls">Select UTM Template Name</label>
                        <select type="text" className="inputformmodel width100" value={utmTemplateData.selectedValue} name="template"
                            onChange={e => setUTMSelectData({ ...utmTemplateData, ...{ selectedValue: e.target.value } })} >
                            <option value="">Select</option>
                            {utmTemplateData.data.map((v, i) => <option key={i} value={v.rowUID}>{v.name}</option>)}
                        </select>
                    </div>
                    <div className="formmodel-bx urlName">
                        <label className="_l_able_cls">Create Short URL for UTM template</label>
                        <select type="text" className="inputformmodel width100" value={shortUrlData.selectedValue} name="shorts"
                            onChange={e => setShortUrlSelectData(prv=>({ ...prv, selectedValue: e.target.value  }))} >
                            <option value="">Select</option>
                            {shortUrlData.data.map((v, i) => <option key={i} value={v.url}>{v.url}</option>)}
                        </select>
                    </div>
                </div>
                <div className="padding20 text-right">
                    <button type="button" className="grid_btn_1 nobg noborder _utm_pops_cancel" onClick={() => sliderEvent(false)}>Cancel</button>
                    <button type="button" className="grid_btn_1   grid_btn_active" disabled={
                        ((utmName !== undefined && utmName !== "") && shortUrlData.selectedValue !== ""  && utmTemplateData.selectedValue !== "")?false:true
                    } onClick={() => saveUTMCode()}>
                        <ButtonLoader IsLoader={IsLoader}>
									Save
								</ButtonLoader>
                    </button>
                </div>
            </div>
            <div className="utmmasking"></div>
        </>
    )
}
export default UTMSlider;