import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import moment from 'moment';
import getLocaleDateString from '../TimeFormat';
import _ from 'lodash';
const Activity = () => {
    const checkIsAuth = useSelector(state => state);
    const [activityData, setActivityData] = useState(filterAnswers());
    const { APITOKEN } = useSelector(state => state);

    const f = getLocaleDateString(APITOKEN.config.cultureCode)

    const load = useCallback(async () => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            await Client.get(`Journal/FetchAll?eventName=all`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then(async (res) => {
                try {
                    setActivityData(filterAnswers(res.data));
                } catch (err) {
                    console.log(err);
                }
            });
    }, [checkIsAuth])


    function filterAnswers(res) {
        let filter = [];
        if (res !== undefined) {
            for (let v of res) {
                const $v = JSON.parse(v['header']);
                $v[0]['array'] = {};
                $v[0]['array'] = JSON.parse(v['subHeadings']);
                filter.push(...$v);
            }
        }
        return filter;
    };

    useEffect(() => {
        load();
    }, [load])
    const toggle = (e, o) => {
        e.stopPropagation();
        const index = _.findIndex(activityData, function (x) { return x.PKId === o['PKId']; });
        activityData[index].show = !o.show;
        for (let x of activityData) {
            const createParentObjects = { ...x, ...{ show: x['show'] === undefined ? false : x['show'] } };
            x['header'] = JSON.stringify([createParentObjects]);
            if (x['array'].length === 0) {
                x['subHeadings'] = JSON.stringify([]);
            } else {
                for (let y of x['array']) {
                    const createChildObjects = { ...y };
                    x['subHeadings'] = JSON.stringify([createChildObjects])
                }
            }
        }
        setActivityData(filterAnswers(activityData))
    }
    return (
        <>
            <div className="history-content">
                <ul>
                    {activityData.length > 0 ? activityData.map((x, i) => <li key={i}><p style={{ marginBottom: '6px' }}><strong>{x['UserName']}</strong> <span className="fright">{moment(x.FinalTimeStamp).format(f)}</span></p><div className="-his-content inline-block" style={{ width: '100%', margin: 0, lineHeight: '23px' }}>{x['ActionType']} {x['PageName']}. <button type="button" style={{ display: x.array.length === 0 ? 'none' : 'inline-block' }} className="inline-block link  nobg noborder pointer" onClick={e => toggle(e, x)} > {x.show ? 'Close details' : 'More details'}</button><div className="-more-details" style={{ display: x.show ? 'block' : 'none' }}>{
                        x.array.map((y, $i) => <div key={$i}><p style={{ paddingBottom: '6px' }}><strong>{y['UserName']}</strong> <span className="fright">{moment(y['FinalTimeStamp']).format(f)}</span></p><p className="-his-content inline-block" style={{ width: '100%', marginBottom: '10px', lineHeight: '23px' }}>{y['ActionType']} {y['PageName']}</p></div>)
                    }</div></div></li>)
                    :<li>There is no registered activity.</li>}
                </ul>
            </div>
        </>
    )

}

export default Activity
