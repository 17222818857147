// import BestDay from "./BestDay";
// import BestTime from "./BestTime";
import GridAggregated from "./GridAggregated";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import TrafficeSource from "./TrafficeSource";
import InsightBlank from "../InsightBlank";
import LoaderBox from "../../ReuseTemplate/LoaderBox";
import axios from "axios";

const OverView = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataPin, setdataPin] = useState([]);
    let [isPinned, setIsPinned] = useState([]);
    let [isDisplay, setDisplay] = useState(true);

    const loadApiCall = useCallback(async () => {
        const cancelToken = axios.CancelToken.source();
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/landingpage/KPI/Overview`).then(({ data }) => {
                    setdataPin(data || []);
                    setIsPinned(data.filter(x => x.IsPinned));
                    setDisplay(false);
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                });
            return () => {
                cancelToken.cancel();
            }
        } catch (err) {
            console.log(err);
        }
    }, [APITOKEN]); // <-- add any missing dependencies react warns about

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])


    const onSortEnd = (oldIndex, newIndex) => {
        setdataPin((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    }
    const pinMethod = (e) => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (e['IsPinned']) {
                response = Client.delete(`Insights/landingpage/00000000-0000-0000-0000-000000000000/KPI/Pin/${e['Id']}`);
            } else {
                response = Client.put(`Insights/landingpage/00000000-0000-0000-0000-000000000000/KPI/Pin/${e['Id']}`, {});
            }
            response.then(({ data }) => {
                data[0]['Status'] &&
                    loadApiCall();
            });
        }
    }
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Overview</h3><p className="content">Last 30 days aggregation. Green and red percent values are compared with previous 30 days.</p></div><div className={`white ${isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''}`}><div className=" white  ">{isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataPin.map((v, i) => {
            return v.IsPinned &&
                <SortableItem key={i}>
                    <div className="bx-boder-common bx white detailsbx" style={{ "display": v['IsPinned'] ? 'inline-block' : 'none' }}><div className="drop-zone"><button type="button" className="pin" style={{ "fontSize": "19px" }} onClick={e => pinMethod(v)}><i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} data-id={v['Id']}></i></button><h3 className="title">{v['Name']}</h3><p className="content paddingbottom20">{v['PinnedDescription']}</p></div><div className="px"><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr><td style={{ visibility: (v['CountLabel'] == null || v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['CountLabel']}</td><td className="-tvl" ><strong style={{ "color": v['CountColor'], "visibility": (v['CountLabel'] === null || v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['Count']}</strong></td></tr><tr><td style={{ "visibility": (v['RateLabel'] === null || v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['RateLabel']}</td><td className="-tvl link"><strong style={{ "color": v['RateColor'], "visibility": (v['RateLabel'] === null || v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['Rate']}%</strong></td></tr></tbody></table></div></div></div>
                </SortableItem>
        })}</SortableList>
        }</div><div className="-n-d-p"></div></div></div> <div className=" margin-top50 bx-boder-common white"><div className="chartharf  width100" ><TrafficeSource /></div></div><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>Landing Page Insights
        </h3></div><div className="aggreategrid"><GridAggregated dataPin={dataPin} onClick={e => pinMethod(e)} IsDisplay={isDisplay} /></div></div></>
    )
}

export default OverView;








