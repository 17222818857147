import React, { Fragment, useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useLocation } from 'react-router-dom';
import "react-modern-drawer/dist/index.css";
import BreadCrum from "../../../../ReuseTemplate/BreadCrum";
import CreateEditCustomFields from "./CreateEditCustomFields";

import { getCustomFields, updateCustomFieldsPreference, getCustomFieldsTypes, deleteCustomField } from "../../../../services/crm";

const CustomFields = () => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from URL
  const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation();
  const toggleDrawer = () => { setIsOpen((prevState) => !isOpen) }
  const [tableDefaultFields, setDefaultFieldsData] = useState([]);
  const [tableCustomFields, setCustonFieldsData] = useState([]);
  const [customFieldTypes, setCustonFieldsTypes] = useState([]);
  const [isVisible, setIsVisible] = useState({});
  const [isRequired, setIsRequired] = useState({});
  const [isIncludeAll, setIsIncludeAll] = useState({});
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [customFieldFormData, setCustomFieldFormData] = useState(null);
  const [selectedDeleteCustomField, setSelectedDeleteCustomField] = useState({});
  const [isEdit, setIsEdit] = useState(true);
  const [selectedEditField, setSelectedEditField] = useState({});
  const [isDeleteFromCurrentListOnly, setIsDeleteFromCurrentListOnly] = useState(false);

  // If Add/Edit Custom Field Drawer close, then set isEdit flag false
  useEffect(() => {
    if ((isOpen || false) === false) {
      setIsEdit(false);
      setCustomFieldFormData(null);
      setSelectedEditField({});
    }
  }, [isOpen]);

  useEffect(() => {
    (async () => {
      try {
        const payload = {
          subscriberListId: listID,
          skip: 0,
          limit: 100
        }

        const customFieldTypes = await getCustomFieldsTypes(payload); //Fetch custom field types
        setCustonFieldsTypes(_get(customFieldTypes, "data.data", []));

        const response = await getCustomFields(payload); //Fetch custom fields

        //Set All checkboxes value & Saperate Default fields and Custom fields
        let defaultFileds = []; let customFields = [];
        let allVisible = {}; let allRequired = {}; let allIncludeAll = {};

        // Make an array with all preferences checkbox values
        _get(response, "data", []).forEach((row) => {
          allVisible = { ...allVisible, [row.CustomFieldId]: _get(row, "Visible", false) };

          allRequired = { ...allRequired, [row.CustomFieldId]: _get(row, "Mandatory", false) };

          allIncludeAll = { ...allIncludeAll, [row.CustomFieldId]: _get(row, "IncludeInAllLists", 0) };

          if (_get(row, "FieldType", 0) === 1) {
            defaultFileds.push(row);
          } else if (_get(row, "FieldType", 0) === 2) {
            customFields.push(row);
          }
        });

        setIsVisible(allVisible);
        setIsRequired(allRequired);
        setIsIncludeAll(allIncludeAll);
        setDefaultFieldsData(defaultFileds); // Set Default Fields Table Data
        setCustonFieldsData(customFields); // Set Custom Field Tablw Data
      } catch (err) {
      }
    })();
  }, [listID, refreshTableData]);

  const handleIsVisibleChange = async (column, isChecked, isCallAPI = true) => {
    const allVisible = { ...isVisible, [column.CustomFieldId]: isChecked }
    setIsVisible(allVisible);

    if (isCallAPI === true) {
      // Set isRequired as per isVisible value changes
      handleIsRequiredChange(column, isChecked, false);

      // Call Preference change API
      const payload = {
        "customFieldId": _get(column, "CustomFieldId", 0),
        "subscriberListId": listID,
        "labelName": _get(column, "DisplayName", ""),
        "dataTypeId": _get(column, "TypeId", 0),
        "isVisible": (isChecked === true) ? 1 : 0,
        "isMandatory": (isChecked === true) ? 1 : 0,
        "personalizationTag": _get(column, "Tags", ""),
        "metaData": _get(column, "MetaData", ""),
        "minLength": (_get(column, "MinLen", 50) !== "" && !_isEmpty(_get(column, "MinLen", 50))) ? _get(column, "MinLen", 0) : 50,
        "maxLength": (_get(column, "MaxLen", 50) !== "" && !_isEmpty(_get(column, "MaxLen", 50))) ? _get(column, "MaxLen", 0) : 50,
        "isUsedAsMergeKey": _get(column, "MergeKey", false),
        "isIncludedInAllList": (_get(column, "IncludeInAllLists", 0) === 1) ? true : false
      }

      // Call update custom field preference api
      const response = await updateCustomFieldsPreference(payload);

      if (_get(response, "flag", false) === true) {
        setRefreshTableData(!refreshTableData); // Refresh Table Data if response is a success
      }
    }
  };

  const handleIsRequiredChange = async (column, isChecked, isCallAPI = true) => {

    const allRequired = { ...isRequired, [column.CustomFieldId]: isChecked }
    setIsRequired(allRequired);

    if (isCallAPI === true) {
      // Set isVisible as per isRequired value changes
      if (isChecked === true) {
        handleIsVisibleChange(column, isChecked);
      }

      const existingIsVisible = (_get(column, "Visible", false) === true) ? 1 : 0;
      // Call Preference change API
      const payload = {
        "customFieldId": _get(column, "CustomFieldId", 0),
        "subscriberListId": listID,
        "labelName": _get(column, "DisplayName", ""),
        "dataTypeId": _get(column, "TypeId", 0),
        "isVisible": (isChecked === true) ? 1 : existingIsVisible,
        "isMandatory": (isChecked === true) ? 1 : 0,
        "personalizationTag": _get(column, "Tags", ""),
        "metaData": _get(column, "MetaData", ""),
        "minLength": (_get(column, "MinLen", 50) !== "" && !_isEmpty(_get(column, "MinLen", 50))) ? _get(column, "MinLen", 0) : 50,
        "maxLength": (_get(column, "MinLen", 50) !== "" && !_isEmpty(_get(column, "MinLen", 50))) ? _get(column, "MaxLen", 0) : 50,
        "isUsedAsMergeKey": _get(column, "MergeKey", false),
        "isIncludedInAllList": (_get(column, "IncludeInAllLists", 0) === 1) ? true : false
      }

      // Call update custom field preference api
      const response = await updateCustomFieldsPreference(payload);

      if (_get(response, "flag", false) === true) {
        setRefreshTableData(!refreshTableData); // Refresh Table Data if response is a success
      }
    }
  };

  const setEditCustomFieldFormData = (selectedField) => {

    setIsEdit(true);
    setSelectedEditField(selectedField);

    setCustomFieldFormData({
      ...customFieldFormData,
      customFieldName: _get(selectedField, "CustomFeildName", ""),
      customFieldType: (customFieldTypes || []).find(item => _get(item, "PKID", "") === _get(selectedField, "TypeId", "")),
      minMax: _get(selectedField, "MaxLen", ""),
      regex: (_get(selectedField, "RegularExpresssion", "") !== null) ? _get(selectedField, "RegularExpresssion", "") : "",
      customFieldOptions: (_get(selectedField, "MetaData", "") !== null || _get(selectedField, "MetaData", "") !== "") ? JSON.parse(_get(selectedField, "MetaData", "")) : [],
      isIncludedInAllList: (_get(selectedField, "IncludeInAllLists", "") === 1) ? true : false
    });

    toggleDrawer();
  }

  const onDeleteCustomField = (customFieldId, isDeleteFromAll) => {
    (async () => {
      try {

        const response = await deleteCustomField(customFieldId, isDeleteFromAll);

        if (_get(response, "data.statusCode", 500) === 200) {
          setShowDeleteSection(false);
          setSelectedDeleteCustomField({});
          setRefreshTableData(!refreshTableData);
        }
      } catch (err) {
      }
    })();
  }

  const handleIsIncludeAllChange = async (column, isChecked) => {

    const allInclude = { ...isRequired, [column.CustomFieldId]: isChecked }
    setIsIncludeAll(allInclude);
  };

  const deleteCustomFieldSection = () => {

    let isShowDeleteButton = false;
    let isShowDeleteFromCurrentList = true;
    let isShowDeleteFromAllList = true;

    if (_get(selectedDeleteCustomField, "IncludeInAllLists", 0) === 0) {

      isShowDeleteFromCurrentList = false;
      isShowDeleteFromAllList = false;
      isShowDeleteButton = true;
    }

    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Delete</h3>
          </div>
          <div className="bx-pad">
            <div className="alert alert-warning margin-bottom24">
              {(isDeleteFromCurrentListOnly === true) && (
                <Fragment>
                  <p className="mb-3">
                    <strong>
                      Are you sure you want to delete the custom field - {_get(selectedDeleteCustomField, "CustomFeildName", "")} from your current list only?
                    </strong>
                  </p>
                  <p>Note: It would continue to exist in other lists.</p>
                  <p className="my-10">Yes, delete the custom field - {_get(selectedDeleteCustomField, "CustomFeildName", "")} from the list - {_get(location, "state.selecteListName", "All Contacts ")}.</p>
                </Fragment>
              )}

              {(isShowDeleteFromAllList === true) && ((isDeleteFromCurrentListOnly || false) === false) && (
                <Fragment>
                  <p className="mb-3">
                    <strong>The custom field - {_get(selectedDeleteCustomField, "CustomFeildName", "")} is a part of all your lists.
                      Do you want to delete it from the current list only or from all your lists?</strong>
                  </p>
                  <p>Note: It would continue to exist in other lists.</p>
                </Fragment>
              )}

              {(isShowDeleteFromAllList === false) && ((isDeleteFromCurrentListOnly || false) === false) && (
                <span>Are you sure you want to delete?</span>
              )}
              <p className="mb-4"></p>
            </div>

            <div className="formmodel-bx text-right nomargin pt-5">
              <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { setShowDeleteSection(false); setIsDeleteFromCurrentListOnly(false); setSelectedDeleteCustomField({}); }} >Cancel</button>

              {(isShowDeleteButton === true) && ((isDeleteFromCurrentListOnly || false) === false) && (
                <button
                  type="button"
                  className="btn btn-danger btn-gape-15"
                  disabled=""
                  onClick={() => onDeleteCustomField(_get(selectedDeleteCustomField, "CustomFieldId", 0), false)}>
                  Delete
                </button>
              )}

              {((isDeleteFromCurrentListOnly || false) === true) && (
                <button
                  type="button"
                  className="btn btn-danger btn-gape-15 me-4"
                  disabled=""
                  onClick={() => onDeleteCustomField(_get(selectedDeleteCustomField, "CustomFieldId", 0), false)}
                >
                  Confirm
                </button>
              )}

              {(isShowDeleteFromCurrentList === true) && ((isDeleteFromCurrentListOnly || false) === false) && (
                <Fragment>
                  <button
                    type="button"
                    className="btn btn-danger btn-gape-15 me-4"
                    disabled=""
                    onClick={() => setIsDeleteFromCurrentListOnly(true)}
                  >
                    Delete from current list only
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-gape-15"
                    disabled=""
                    onClick={() => onDeleteCustomField(_get(selectedDeleteCustomField, "CustomFieldId", 0), true)}>
                    Delete from all lists
                  </button>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum
            ToText="CRM List"
            To="/crm/people/listings"
            ToSecondPage={`/crm/people/subscribers/${listID}/${creationSource}`}
            ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
            CurrentPage="Manage custom fields"
            SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts ") }}
          />
        </div>

        {((showDeleteSection || false) === true) && (
          deleteCustomFieldSection()
        )}

        {((showDeleteSection || false) === false) && (
          <Fragment>
            <div className="bx-boder-common width100 nobg margin-bottom50">
              <div className="_h_header white border-radius2">
                <h3>Default Fields</h3>
                <span className="flex-right">
                  <span className="btn btn-primary" onClick={() => toggleDrawer()}>Create Custom Fields</span>
                </span>
              </div>
              <div className="aggreategrid dataTable-primary">
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                  <thead>
                    <tr>
                      <th className="table_cell_header">Field name</th>
                      <th className="table_cell_header">Personalization tag</th>
                      <th className="table_cell_header" width="38%">Preference center</th>
                      <th className="table_cell_header" width="14%">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(tableDefaultFields || []).map((column, key) => {

                      const checkIsVisible = (!_isEmpty(isVisible)) ? _get(isVisible, `${column.CustomFieldId}`, false) : _get(column, "Visible", false);
                      const checkRequired = (!_isEmpty(isRequired)) ? _get(isRequired, `${column.CustomFieldId}`, false) : _get(column, "Mandatory", false);
                      const checkIncludeAll = (!_isEmpty(isIncludeAll)) ? _get(isIncludeAll, `${column.CustomFieldId}`, false) : _get(column, "IncludeInAllLists", false);

                      return (
                        <tr key={key}><td className="body_table_cell">{_get(column, "CustomFeildName", "")}</td>
                          <td className="body_table_cell">{_get(column, "Tags", "")}</td>
                          <td className="body_table_cell">
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              <input
                                type="checkbox"
                                className="inline-block"
                                checked={checkIsVisible}
                                onChange={(e) => handleIsVisibleChange(column, _get(e, "currentTarget.checked", false))}
                              /> &nbsp; Visible
                            </label>
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              <input
                                type="checkbox"
                                className="inline-block"
                                checked={checkRequired}
                                onChange={(e) => handleIsRequiredChange(column, _get(e, "currentTarget.checked", false))}
                              /> Required
                            </label>
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              <input
                                type="checkbox"
                                className="inline-block"
                                disabled={true}
                                checked={checkIncludeAll}
                                onChange={(e) => handleIsIncludeAllChange(column, _get(e, "currentTarget.checked", false))}
                              />
                              Include in All
                            </label>
                          </td>
                          <td className="body_table_cell">
                            {(_get(column, "CustomFeildName", "") === "Gender") && (
                              <span className="text-07f" onClick={() => setEditCustomFieldFormData(column)}>Edit</span>
                            )}
                          </td>
                        </tr>
                      )
                    })}

                    <tr>
                      <td colSpan="4">
                        <div className="_h_header border-top">
                          <h3>Custom Fields</h3>
                        </div>
                      </td>
                    </tr>
                    {(tableCustomFields || []).map((column, key) => {

                      const checkIsVisible = (!_isEmpty(isVisible)) ? _get(isVisible, `${column.CustomFieldId}`, false) : _get(column, "Visible", false);
                      const checkRequired = (!_isEmpty(isRequired)) ? _get(isRequired, `${column.CustomFieldId}`, false) : _get(column, "Mandatory", false);
                      const checkIncludeAll = (!_isEmpty(isIncludeAll)) ? _get(isIncludeAll, `${column.CustomFieldId}`, false) : _get(column, "IncludeInAllLists", false);

                      return (
                        <tr key={key}><td className="body_table_cell">{_get(column, "CustomFeildName", "")}</td>
                          <td className="body_table_cell">{_get(column, "Tags", "")}</td>
                          <td className="body_table_cell">
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              <input
                                type="checkbox"
                                className="inline-block"
                                checked={checkIsVisible}
                                onChange={(e) => handleIsVisibleChange(column, _get(e, "currentTarget.checked", false))}
                              /> &nbsp; Visible
                            </label>
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              <input
                                type="checkbox"
                                className="inline-block"
                                checked={checkRequired}
                                onChange={(e) => handleIsRequiredChange(column, _get(e, "currentTarget.checked", false))}
                              /> Required
                            </label>
                            <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                              {/*<input
                                type="checkbox"
                                className="inline-block"
                                disabled={true}
                                checked={checkIncludeAll}
                                onChange={(e) => handleIsIncludeAllChange(column, _get(e, "currentTarget.checked", false))}
                              />*/}
                              {(checkIncludeAll === 1) && (
                                <svg className="vertical-sub me-1 bi bi-check-circle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#198754" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05" />
                                </svg>
                              )}
                              {(!checkIncludeAll) && (
                                <svg className="vertical-sub me-1 bi bi-x-circle" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#dc3545" viewBox="0 0 16 16">
                                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                                </svg>
                              )}
                              Include in All
                            </label>
                          </td>
                          <td className="body_table_cell">
                            <span className="text-07f cursor-pointer" onClick={() => setEditCustomFieldFormData(column)}>Edit</span> | <span className="text-07f cursor-pointer" onClick={() => { setShowDeleteSection(true); setSelectedDeleteCustomField(column); }}>Delete</span>
                          </td>
                        </tr>
                      )
                    })}

                  </tbody>
                </table>
              </div>
            </div>

            <CreateEditCustomFields
              isOpen={isOpen}
              setIsOpen={(isOpen) => setIsOpen(isOpen)}
              refreshTableData={refreshTableData}
              setRefreshTableData={(refreshTableData) => setRefreshTableData(refreshTableData)}
              isEdit={isEdit}
              setIsEdit={(isEdit) => setIsEdit(isEdit)}
              customFieldTypes={customFieldTypes}
              selectedEditField={selectedEditField}
              setSelectedEditField={(selectedEditField) => setSelectedEditField(selectedEditField)}
              editCustomFieldFormData={customFieldFormData}
              setCustomFieldFormData={(customFieldFormData) => setCustomFieldFormData(customFieldFormData)}
            />
          </Fragment>
        )}
      </div>
    </>
  )
}

export default CustomFields;