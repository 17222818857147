export const SAVE_UPLOADED_CONTACTS = "SAVE_UPLOADED_CONTACTS";
export const saveUploadedContact = (payload) => {
    console.log("SAVE_UPLOADED_CONTACTS: ", payload);

    return {
        type: SAVE_UPLOADED_CONTACTS,
        payload
    };
};

export const CREATE_IMPORT_SAVE_DATA = "CREATE_IMPORT_SAVE_DATA";
export const createImportSaveData = (payload) => {
console.log("payload: ", payload);
    return {
        type: CREATE_IMPORT_SAVE_DATA,
        payload
    };
};