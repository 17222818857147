import { useCallback, useEffect, useState } from 'react';
import SortableList, { SortableItem } from 'react-easy-sort'
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import Client from '../../../../GlobalAPICall';
import { arrayMoveImmutable } from 'array-move';

import GridSurveyContainer from './GridSurveyContainer';
import GridLoader from '../../../../ReuseTemplate/GridLoader';
const SurveyKPI = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [surveyKpiData, setSurveyKpi] = useState({});
    const { id } = useParams();
    const [loader, setLoader] = useState(false);

    const loadApiCall = useCallback(async () => {
        setLoader(true);
        const requestPayload = {
            "locale": "en-IN",
            "fromDate": "",
            "toDate": ""
        };
        try {
            (APITOKEN['token'] !== "") &&
                Client.post(`Insights/Survey/00000000-0000-0000-0000-000000000000/KPI?queueId=${id}`, requestPayload, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token'],
                        'Content-Type': 'application/json'
                    }
                }).then(({ data }) => {
                    setLoader(false);
                    try {
                        setSurveyKpi(data);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
            setLoader(false);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id]);

    const onSortEnd = (oldIndex, newIndex) => {
        setSurveyKpi((array) => {
            return { ...surveyKpiData, kpi: arrayMoveImmutable(array['kpi'], oldIndex, newIndex) }
        })
    }
    const pinMethod = (e) => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (e['isPinned']) {
                response = Client.delete(`Insights/Survey/${id}/KPI/Pin/${e['id']}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            } else {
                response = Client.put(`Insights/Survey/${id}/KPI/Pin/${e['id']}`, {}, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            }
            response.then((res) => {
                try {
                    loadApiCall();
                } catch (err) {
                    console.log(err);
                }
            });
        }
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Overview - <span className="overviewtitle">{surveyKpiData.surveyName}</span></h3></div><div className="padding20 white"><SortableList className="_overviewPin summary_o_v white" onSortEnd={onSortEnd} draggedItemClassName="dragged">
            {surveyKpiData['kpi'] && surveyKpiData['kpi'].map((v, i) => v['isPinned'] && <SortableItem key={i}><div className="bx-boder-common bx detailsbx white" style={{ display: (v['isPinned']) ? 'inline-block' : 'none', border: `2px solid ${(v['kpiBasedGoalAchieved'] === 1) ? v['kpiBasedGoalBorderColor'] : 'none'}` }}><div className="drop-zone"><button type="button" onClick={e => pinMethod(v)} className="pin" style={{ fontSize: "19px" }}><i className={(v['isPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} style={{ display: (v['kpiBasedGoalAchieved'] === 1) ? 'none' : 'inline-block' }}></i><i style={{ display: (v['kpiBasedGoalAchieved'] === 1) ? 'inline-block' : 'none', color: (v['kpiBasedGoalAchieved']) ? v['pinRateColor'] : '' }} className="fas fa-trophy"></i></button><h3 className="title">{v['name']}</h3><p className="content paddingbottom20">{v['description']}</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr style={{ visibility: (v['countLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['countLabel']}</td><td className=" -tvl"><strong style={{ color: v['pinCountColor'] }}>{(v['count'] === -1) ? v['timeFormat'] : v['count']}</strong></td></tr><tr style={{ visibility: (v['rateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['rateLabel']}</td><td className="-tvl link"><strong style={{ color: v['pinRateColor'], visibility: (v['rate'] === -1) ? 'hidden' : 'visible' }}>{v['rate']}%</strong></td></tr></tbody></table></div></div></SortableItem>)}
        </SortableList></div></div>
            <div className="overviewtable margin-top50 bx-boder-common white">
                <div className="_h_header">
                    <h3>Survey Insights</h3>
                </div>
                <div className="aggreategrid">
                    {loader ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                        <GridSurveyContainer Data={surveyKpiData} tabId={tabId} onClick={e => pinMethod(e)} />}
                </div>
            </div></>
    )
}
export default SurveyKPI;