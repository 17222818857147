export const HightChartPieProps = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        colors: ['red', 'blue', 'yelow', 'green']

    },
    title: {
        text: 'Segment/Groups',
        align: 'center'
    },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        },
        enabled: false
    },

    legend: {
        align: 'right',
        verticalAlign: 'middle',
        layout: 'vertical',
        itemMarginBottom: 10,



    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false
            },
            showInLegend: true,
            pie: {
                size: '100%',
                dataLabels: {
                    enabled: false
                },

            }
        }
    },
    series: [{
        type: 'pie',
        colorByPoint: true,
        innerSize: '50%',
        name: 'Recipients',

    }]
}
