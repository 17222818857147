import { Fragment } from 'react';

const StaticPaginationDesign = ({ onChangePage, currentPage, isShowItemsPerPage, rowsPerPage = 10, totalRecords, currentRecords }) => {
	const totalPages = Math.ceil((totalRecords || 10) / (rowsPerPage || 0));
	
	return (
		<nav className="rdt_Pagination">
			{((isShowItemsPerPage || false) === true) && (
				<Fragment>
					<span className="rdt_Pagination-perpage">Items per page: {(rowsPerPage || 0)}</span>
					{/*<div className="rdt_Pagination-select">
                        <select aria-label="Rows per page:">
                            <option value="10" selected="">20</option>
                            <option value="15">100</option>
                            <option value="20">500</option>
                            <option value="25">1000</option>
                        </select>

                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path>
                        </svg>
                    </div>*/}
				</Fragment>
			)}
			<span className="rdt_Pagination-number">{(currentRecords || 0)} of {(totalRecords || 0)}</span>
			<div className="rdt_Pagination-arrow">
				{/*<button id="pagination-first-page" type="button" aria-label="First Page" aria-disabled="true" disabled="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path>
                        <path fill="none" d="M24 24H0V0h24v24z"></path>
                    </svg>
                </button>*/}
				<button
					type="button"
					disabled={(currentPage === 1) ? "disabled" : ""}
					onClick={() => {
						//setCurrentPage((currentPage) => (currentPage - 1))
						onChangePage(currentPage - 1)
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
						<path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path>
					</svg>
				</button>
				<button
					type="button"
					disabled={(currentPage === totalPages) ? "disabled" : ""}
					onClick={() => {
						//setCurrentPage((currentPage) => (currentPage + 1))
						onChangePage(currentPage + 1)
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
						<path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path>
					</svg>
				</button>
				{/*<button id="pagination-last-page" type="button" aria-label="Last Page" aria-disabled="true" disabled="">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                        <path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path>
                        <path fill="none" d="M0 0h24v24H0V0z"></path>
                    </svg>
                </button>*/}
			</div>
		</nav>
	)
}
export default StaticPaginationDesign;