import BrowserChart from "./BrowserChart"
import DesktopChart from "./DesktopChart"
import MobileChart from "./MobileChart"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import { DeviceLoader } from "../../../../ReuseTemplate";
const Device = () => {
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [deviceData, setDeviceData] = useState({});
    const [loader, setLoader] = useState(false);

    const loadApiCall = useCallback(async () => {
        setLoader(true);
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/landingpage/${id}/KPI/device`).then(({ data }) => {
                    setLoader(false);
                    try {
                        setDeviceData(JSON.parse(data[0]['KPI']));
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
            setLoader(false);
        }
    }, [APITOKEN, id]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {loader ? <DeviceLoader /> : deviceData[0] &&
                <>
                    {!deviceData[0].MobileDevice ? <></> : <MobileChart Data={deviceData} />}
                    {!deviceData[0].Browser ? <></> : <BrowserChart Data={deviceData} />}
                    {!deviceData[0].Desktop ? <></> : <DesktopChart Data={deviceData} />}
                </>
            }
        </>
    )
}
export default Device