import React, { useCallback, useEffect, useState, useRef } from "react";
import Client from "../../GlobalAPICall";

import { useSelector } from "react-redux";

const GettingStarted = () => {
  const { APITOKEN } = useSelector((state) => state);

  const [responseObject, setAPIResponse] = React.useState([]);

  const [checkAll, setCheckAll] = useState(false);
  const ref = useRef(false);

  const load = useCallback(() => {
    APITOKEN["token"] !== "" &&
      Client.get(
        `Lookup/OnBoarding/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/GettingStarted`,
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then((res) => {
          try {
            const allCheckBoxCheck =
              res["data"] && res["data"].every((x) => x["IsMenuAdded"] === 1);
            ref.current.checked = allCheckBoxCheck;
            setAPIResponse(res["data"]);
            setCheckAll(allCheckBoxCheck);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => console.error(err));
  }, [APITOKEN]);
  let getStatingArray = [];
  const checkAllfn = async (e) => {
    const ischecked = e.target.checked;
    getStatingArray = [];
    responseObject.forEach((element) => {
      if (!element["IsReadOnly"]) {
        getStatingArray.push({
          menuId: element.MenuId,
          isAdded: ischecked ? 1 : 0,
        });
        element["IsMenuAdded"] = ischecked ? 1 : 0;
      }
      return element;
    });

    await gettingStartedApiUpdated();
  };

  const singleCheckbox = async (element, e) => {
    const ischecked = e.target.checked;
    const id = element.MenuId;
    getStatingArray = [];
    getStatingArray.push({
      menuId: id,
      isAdded: ischecked ? 1 : 0,
    });
    gettingStartedApiUpdated();
  };

  const gettingStartedApiUpdated = () => {
    const headers = {
      Authorization: `Bearer ` + APITOKEN["token"],
      "Content-Type": "application/json",
    };
    const obj = {
      menu: getStatingArray,
    };
    Client.put(
      `Lookup/OnBoarding/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/GettingStarted`,
      obj,
      { headers }
    )
      .then(async (res) => {
        try {
          await load();
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => console.error(err));
  };
  const helpCenter = () => {
    window.open(APITOKEN.config.subscriptionInfo.HelpCenterUrl, "_blank");
  };
  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      <div className="bx-boder-common white width100 inline-block">
        <div className="_h_header">
          <h3>Getting Started</h3>
          <span className="flex-right">
            <button type="button" className="grid_btn_1" onClick={helpCenter}>
              Help Center Articles
            </button>
          </span>
        </div>
        <div className="padding20">
          <p className="margin-bottom">
            Select the steps you want to enable/disable in your Getting Started
            Steps.
          </p>
          <div className="getting_container">
            <div className="g_box">
              <label>All</label>
              <div className="relative inline-block">
                <label
                  className="switch-radiostyle labelclassName"
                  style={{ paddingBottom: "0" }}
                >
                  <input
                    type="checkbox"
                    defaultChecked={checkAll}
                    ref={ref}
                    name="selectALL"
                    className="ischeckedAll_started"
                    onChange={checkAllfn}
                  />
                  <span className="checkmark-slider"></span>
                </label>
              </div>
            </div>
            {responseObject &&
              responseObject.map((x, i) => {
                return (
                  <div key={i}>
                    <div className="g_box">
                      <label>{x["Name"]}</label>
                      <div className="relative inline-block">
                        <label
                          className="switch-radiostyle labelClass"
                          style={{ paddingBottom: "0" }}
                        >
                          <input
                            type="checkbox"
                            className="ischecked_started"
                            checked={x.IsMenuAdded}
                            disabled={x.IsReadOnly}
                            onChange={(e) => singleCheckbox(x, e)}
                          />
                          <span className="checkmark-slider"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};
export default GettingStarted;
