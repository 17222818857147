import _ from "lodash";
import { useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const ReplyTo = (props) => {
    const { APITOKEN } = useSelector(state => state);
    const { ReplyToData, ReplytoAPI, Close } = props;
    const defaultId = '00000000-0000-0000-0000-000000000000';
    const [blankField, setBlankField] = useState(false);
    const [replyTo, setReplyToField] = useState([]);
    const [loader, setLoader] = useState(false);
    const [replyToArray, setReplyToArray] = useState(() => {
        const response = [];
        for (const x of ReplyToData.data) {
            x.id !== defaultId &&
                response.push({ email: x.email.email, displayName: "" })
        };
        return response;
    });

    const addReplyto = v => {
        if (/\S+@\S+\.\S+/.test(v)) {
            setReplyToField([{
                email: v.trimStart(),
                displayName: "",
                id: Math.floor(Math.random() * 100)
            }])

        }
    }

    var debounced = _.debounce(addReplyto, 500, { 'maxWait': 1000 });

    const postAPI = () => {
        setLoader(true);
        setReplyToArray([...replyToArray, ...replyTo]);
        Client.post(`Email/replyto`, replyTo, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setLoader(false);
                try {
                    ReplytoAPI();
                    //setReplyToArray(ReplyToData);
                    Close()

                } catch (err) {
                    console.error(err);
                }
            }).catch((err) => {
                console.error(err);
                setLoader(false);
            });
    }
    const deleteAPI = ({id}) => {
        const aray = [id];
        Client.delete(`Email/replyto`, {
            data: aray,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    if (data.Success) ReplytoAPI();
                } catch (err) {
                    console.error(err);
                }

            }).catch(err => console.error(err))
    }
    const replytoVerify = ({id}) => {
        const aray = [id];

        Client.put(`Email/replyto/reverify`, aray, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    if (data.Success) ReplytoAPI();
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }
    const addRes = () => {
        setBlankField(true);
    }

    return (
        <>
            <div className="_history_container">
                <div className="topn-header">
                    <span className="selected-title">Reply To</span>
                    <span style={{ padding: '20px', float: 'right' }}><i className="fa fal fa-times fright pointer" onClick={() => Close()}></i></span>
                </div>
                <div className="padding20">
                    <ul className="_senderList">
                        {ReplyToData.data.length > 0 && ReplyToData.data.map((v, i) =>

                            <li key={i}>
                                {v.id !== defaultId ?
                                    <> <input readOnly className="inputformmodel" type="text" onChange={e => e.stopPropagation()} value={v['email'].email} />
                                        <button type="button" style={{ display: (v['isVerified']) ? 'none' : 'inline-block' }} className={`inline-block link ${(v['verifyFlagShow'] !== undefined) ? 'hidden' : ''}`} onClick={() => replytoVerify(v)}>Reverify</button>
                                        &nbsp;
                                        <button type="button" className="inline-block link" onClick={() => deleteAPI(v)} >Delete</button>

                                        <div className="replyoverlap"><p className="r-v" style={{ display: (v['verifyFlagShow'] !== undefined) ? 'none' : 'inherit' }}>{(v['isVerified'] === false ? <label className='red'>Not Verify</label> : <label className='green'>Verified</label>)}</p></div></> : <></>}
                            </li>

                        )}

                        {blankField ?
                            <li>
                                <input className="inputformmodel"
                                    type="text"
                                    placeholder="New Reply To"
                                    onChange={e => {
                                        debounced(e.target.value.trimStart());
                                    }} />
                            </li>
                            : <></>}
                    </ul>
                </div>
                <div className="padding20 text-right">
                    <button type="button" className="inline-block grid_btn_1 noborder nobg margin-right15 pointer" disabled={blankField} onClick={addRes}>Add</button>
                    <button type="button" className="inline-block grid_btn_1 noborder nobg margin-right15 pointer" onClick={() => props.ReplytoAPI()}>Refresh</button>
                    <button type="button" className="inline-block grid_btn_1 grid_btn_active  pointer" onClick={postAPI}>
                        <ButtonLoader IsLoader={loader}>Create</ButtonLoader>

                    </button>
                </div>
            </div>
            <div className='utmmasking'></div>
        </>
    )

}

export default ReplyTo
