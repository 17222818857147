import { RecipientsPanel } from "../RecipientsPanel";
import './index.css';

const DistributionSMS = () => {
    return (
        <RecipientsPanel></RecipientsPanel>
    )
}
export default DistributionSMS;

