import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";

const SMTP = () => {
    const {APITOKEN} = useSelector(state => state);
    const [smtpData, setSMTP] = useState([]);
    let [toggleValue, setToggleValue] = useState(false);
    let [smtpConfigured, setsmtpConfigured] = useState({isExists:false});
    const smtpFetchAPI = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`User/SMTPCredentials`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(({data}) => {
                try {
                    setSMTP(data);
                    setsmtpConfigured({isExists:true});
                } catch (err) {
                    console.log(err);
                }
            });
    }, [APITOKEN])

    const setLoaded = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`User/CheckIfSMTPConfigured`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(async ({data}) => {
                try {

                    if (data['isExists']) {
                        smtpFetchAPI();
                    }
                   setsmtpConfigured(data)
                } catch (err) {
                    console.log(err);
                }
            });

    }, [APITOKEN, smtpFetchAPI,setsmtpConfigured])

    
    const toggle = () => {
        setToggleValue(toggleValue = !toggleValue);
    }
    const genrtor = ()=>{
        smtpFetchAPI()
    }
    useEffect(() => {
        setLoaded();
    }, [setLoaded])
    return (
        <><div className=" width100 inline-block">
            <div className=" bx-boder-common width100 white margin-bottom">
                <div className="_h_header" style={{ flexDirection: "column", alignItems: "inherit" }}>
                    <div style={{ display: "flex" }}><h3>SMTP Configuration</h3>{!smtpConfigured.isExists ?
                        <span className="flex-right"><button type="button" className="grid_btn_1 grid_btn_active" onClick={genrtor}>Create SMTP Configuration</button></span> : ''} </div><p style={{ paddingTop: "5px" }}>Simple Mail Transfer Protocol (SMTP) is your way to send emails between two servers. It's simple! It's safe! And, it's efficient!</p></div>
                <div className="smtp_container padding20">
                    {smtpConfigured.isExists &&
                    <div style={{ fontSize: "16px", lineHeight: "22px" }}>
                        <span style={{ fontSize: "18px" }}>Set the server host in your email client or application to <strong> {smtpData['SMTPUserName']} </strong>
                        </span><p className="username">Username: <strong>{smtpData['Email_UserName']}</strong> <br /> Password: <strong className="_key" >{toggleValue ? smtpData.SendGrid_APIKey : '****************************************************'}</strong> <button type="button" className="inline-block link  font-16 _generate_key" onClick={toggle}>{toggleValue ? 'hide' : 'show'}</button> <br /> Set the port: <strong>587</strong> </p><p className="port"><strong>SMTP Ports</strong> <br /> For unencrypted or TLS connctions, use port <strong>25, 2525, or 587</strong> <br /> For SSL connection, use port <strong>465</strong></p>
                        </div>
                    }
            </div>
        </div></div></>
    )
}
export default SMTP