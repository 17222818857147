

import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Client from "../../../../GlobalAPICall";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import GridAggregatedContainer from './GridAggregatedContainer'
import Visitor from "./Visitor";
import InsightBlank from "../../../InsightBlank";
import getLocaleDateString from "../../../../TimeFormat"; import moment from "moment";
import LoaderBox from "../../../../ReuseTemplate/LoaderBox";
import GridLoader from "../../../../ReuseTemplate/GridLoader";
;
const LandingPageKPI = ({ tabId }) => {
  const { APITOKEN, InsightSMSDetails } = useSelector(state => state);
  const [loader, setLoader] = useState(false);

  const [dataAggrated, setdataAggrated] = useState({
    KPI: [],
    campaignName: "",
    isDisplay: true

  });
  let [isPinned, setIsPinned] = useState([]);
  const { id } = useParams();
  const filterPinned = res => {
    const filter = res.filter(item => item.IsPinned);
    return filter;
  }
  const loadApiCall = useCallback(async () => {
    setLoader(true);
    try {
      (APITOKEN['token'] !== "") &&
        Client.get(`Insights/landingpage/${id}/KPI/landing_page_kpi`, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        }).then(({ data }) => {
          setLoader(false);
          try {
            const { KPI, campaignName } = data;
            setdataAggrated({
              KPI: KPI,
              campaignName: campaignName,
              isDisplay: false
            });
            setIsPinned(filterPinned(KPI))
          } catch (err) {
            console.log(err);
          }
        });
    } catch (err) {
      console.log(err);
      setLoader(false);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [APITOKEN, id]);



  const onSortEnd = (oldIndex, newIndex) => {
    setdataAggrated((array) => {
      return { ...dataAggrated, KPI: arrayMoveImmutable(array['KPI'], oldIndex, newIndex) }
    })
  }
  const pinMethod = item => {
    if (APITOKEN['token'] !== "") {
      let response = null;

      if (item['IsPinned']) {
        response = Client.delete(`Insights/distribution/SMS/${id}/KPI/Pin/${item['Id']}`, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        });
      } else {
        response = Client.put(`Insights/distribution/SMS/${id}/KPI/Pin/${item['Id']}`, {}, {
          headers: {
            'Authorization': `Bearer ` + APITOKEN['token']
          }
        });
      }
      response.then(({data}) => {
        data[0]['Status'] &&
          loadApiCall();
      });
    }
  }
  useEffect(() => {
    loadApiCall();
  }, [loadApiCall])

  return (
    <><div className="bx-boder-common"><div className="_insight_header white"><h3>Overview - <span className="overviewtitle">{InsightSMSDetails.Name}</span><span className="fright font-16">Goal Duration: {moment(InsightSMSDetails.StartDate).format(getLocaleDateString(APITOKEN.config.cultureCode))}</span></h3></div><div className={`white ${dataAggrated.isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''}`}><div className="white">
      {dataAggrated.isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataAggrated['KPI'] && dataAggrated['KPI'].map((v, i) => {
        return v['IsPinned'] && <SortableItem key={i}>
          <div className="bx-boder-common bx detailsbx white" style={{ display: (v['IsPinned']) ? 'inline-block' : 'none', border: `2px solid ${(v['KPIBasedGoalAchieved'] === 1) ? v['KPIBasedGoalBorderColor'] : 'none'}` }} ><div className="drop-zone"><button className="pin" type="button" style={{ fontSize: "19px" }} onClick={e => pinMethod(v)} ><i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'none' : 'inline-block' }}></i><i style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'inline-block' : 'none', color: (v['KPIBasedGoalAchieved']) ? v['KPIBasedGoalRateOrQuantityColor'] : '' }} className="fas fa-trophy"></i></button><h3 className="title">{v['Name']}</h3><p className="content paddingbottom20">{v['Description']}</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr style={{ visibility: (v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['CountLabel']}</td><td className=" -tvl"><strong style={{ color: v['PinCountColor'] }}>{v['Count']}</strong></td></tr><tr style={{ visibility: (v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['RateLabel']}</td><td className="-tvl link"><strong style={{ color: v['PinRateColor'] }}>{v['Rate']}%</strong></td></tr></tbody></table></div></div>
        </SortableItem>
      })}</SortableList>
      }
    </div>
      <div className="-n-d-p"></div>
    </div>
    </div>
      <div className=" margin-top50 bx-boder-common white"><div className="chartharf width100"><Visitor /></div></div>
      <div className="overviewtable margin-top50 bx-boder-common white">
        <div className="_h_header">
          <h3>Landing Page Insights</h3>
        </div>
        <div className="aggreategrid">
          {loader ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
            <GridAggregatedContainer tabId={tabId} dataAggrated={dataAggrated} onClick={e => pinMethod(e)} />}
        </div>
      </div></>
  )
}

export default LandingPageKPI;
