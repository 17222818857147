import { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import solidGauge from "highcharts-solid-gauge";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);
solidGauge(Highcharts);
const Failed = (props) => {
    const { Data, OnClick } = props;
    const [options] = useState({
        chart: {
            type: 'solidgauge',
            width: 350,
            height: 400
        },
        title: null,
        accessibility: {
            enabled: false
        },
        pane: {
            center: ['50%', '35%'],
            size: '70%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        yAxis: {
            min: 0,
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            gridLineColor: 'transparent',
            lineColor: 'transparent',
            tickPositions: [0,Data['TotalRecipients']],
            max: Data['TotalRecipients'],
            title: {
                y: -70,
                text: 'Failed'
            },
            labels: {
                y: 16,
            }
        },


        credits: {
            enabled: false
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        series: [{
            name: 'Failed',
            data: [{ y: Data['KPI'][2].Count, color: '#ed264e' }],
            dataLabels: {
                format: '<div style="text-align:center">' +
                    '<span style="font-size:25px"></span><br/>' +
                    '<span style="font-size:12px;opacity:0.4"></span>' +
                    '</div>'
            }
        }]
    });
    return (
        <div className="text-center">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div className="g-txt grph-bx-wrappper"><div className="bx-boder-common bx-p detailsbx"><h3 className="title">{Data['KPI'][2].Name}</h3><p className="content paddingbottom20">{Data['KPI'][2].PinnedDescription}</p><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr><td>Status</td><td className="-tvl"><strong className={`${Data['KPI'][2].Count === 0 ? '' : 'link pointer'}`} onClick={e => { (Data['KPI'][2].Count === 0 ? e.stopPropagation() : OnClick(Data['KPI'][2])) }}>{Data['KPI'][2].Count}</strong></td></tr><tr><td>Status Rate</td><td className="-tvl link"><strong>{Data['KPI'][2].Rate}%</strong></td></tr></tbody></table></div></div>
            </div>
        </div>
    );
}
export default Failed;