import BrowserChart from "./BrowserChart"
import DesktopChart from "./DesktopChart"
import MobileChart from "./MobileChart"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import { DeviceLoader } from "../../../../ReuseTemplate";
const Device = () => {
    const { id } = useParams();
    const {APITOKEN} = useSelector(state => state);
    const [deviceData, setDeviceData] = useState([])
    const [loader, setLoader] = useState(false);

    const loadApiCall = useCallback(async () => {
        setLoader(true);
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/Email/${id}/KPI?tabId=12`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(({ data }) => {
                    setLoader(false);
                    try {
                        setDeviceData(data['KPI'])
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
            setLoader(false);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, id]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
            {deviceData.length > 0 && (deviceData[0]['Desktop'].length !== 0 || deviceData[0]['Browser'].length !== 0 || deviceData[0]['MobileDevice'].length !== 0) ?
                <>
                    <MobileChart Data={deviceData[0]} />
                    <BrowserChart Data={deviceData[0]} />
                    <DesktopChart Data={deviceData[0]} />
                </>
                : <>{loader ? <DeviceLoader /> : <div className=" bx-boder-common white"><table cellPadding="0" cellSpacing="0" width="100%"><tbody><tr><td className="body_table_cell white" colSpan="5"><div className="_nodata"><div id="nodatamessage"><strong>There is no data available yet.</strong><br /><br /></div></div></td></tr></tbody></table></div>}</>
            }
        </>
    )
}
export default Device