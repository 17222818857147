import React, { useEffect, useState, useCallback } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
//import { useNavigate } from "react-router";
import { useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { getSegmentQueryJson, submitSegmentation } from "../../../../../services/crm";

import TableRowsLoading from "../../../../ReusableComponents/TableRowsLoading";
import BreadCrum from "../../../../../ReuseTemplate/BreadCrum";

const ViewSegment = () => {

	const pathArray = window.location.pathname.split("/");
	const listID = _get(pathArray, "[4]", null); // get List ID from URL
	const creationSource = _get(pathArray, "[5]", null); // get creationSource from URL
	const segmentID = _get(pathArray, "[8]", null); // get segment id from URL
	//const [segmentJSONData, setSegmentJSONData] = useState([]);
	const [segmentTableColumns, setSegmentTableColumns] = useState([]);
	const [segmentTableColumnsData, setsegmentTableColumnsData] = useState([]);
	const location = useLocation();
	const [isLoader, setIsLoader] = useState(true);
	//const [resultRecordCount, setResultRecordCount] = useState(0);

	const submitTestFilter = useCallback((tmpSegmentObject) => {
		(async () => {

			setIsLoader(true);
			let segmentationPayload = [];
			let segmentTableColumns = [];
			(tmpSegmentObject || []).map((segments) => {

				const segmentArray = _get(segments, "array", []);
				const segArr = Object.keys(segmentArray).map((andIndex) => segmentArray[andIndex]);
				segmentationPayload.push({ "Name": _get(segments, "Name", ""), "array": segArr, orpipe: true, selected: true });
				return true;
			});

			const finalPayload = {
				"IsSegmentEdit": true,
				"segmentId": segmentID.toString(),
				"segmentQueryJson": JSON.stringify(segmentationPayload),
				"subscriberListId": listID
			}

			const response = await submitSegmentation(finalPayload);

			const tableData = _get(response, "data.data.data", []);
			let segmentTableData = [];
			if (!_isEmpty(tableData)) {
				tableData.forEach(element => {
					segmentTableData.push(_get(element, "_source", {}));
				});

				segmentTableColumns = [{
					name: "FirstName",
					selector: (item) => (_get(item, `[FirstName]`, "")),
				},
				{
					name: "LastName",
					selector: (item) => (_get(item, `[LastName]`, "")),
				},
				{
					name: "Email",
					selector: (item) => (_get(item, `[Email]`, "")),
				},
				{
					name: "MobileCountryCode",
					selector: (item) => (_get(item, `[MobileCountryCode]`, "")),
				},
				{
					name: "MobileNumber",
					selector: (item) => (_get(item, `[MobileNumber]`, "")),
				}];
				Object.keys(_get(tableData, `[0]._source`, {})).map((rowKey, i) => {

					if (!['FirstName', 'LastName', 'Email', 'MobileCountryCode', 'MobileNumber', "PeopleSubscriberId", "PeopleId", "SubscriberListId", "ListId", "AdminId", "DateSubscribed", "CreationTypeId", "SubscriberListName", "elasticId", "ElasticId", "Token", "SubscriberListPkId", "MarketingSingleOptInSmsText", "MarketingSingleOptInEmailText", "LandingPageDistributionStatus", "LandingPageEngagmentStatus", "NPSJsonData", "RecepientId", "SMSDistributionStatus", "QueueId", "StampsMetaData"].includes((rowKey || ""))) {
						return (
							segmentTableColumns.push({
								name: rowKey,
								selector: (item) => (_get(item, `[${rowKey}]`, "")),
							})
						)
					}
					return true;
				});
			}
			//setResultRecordCount(_get(response, "data.data.count", 0));
			setSegmentTableColumns(segmentTableColumns);
			setsegmentTableColumnsData(segmentTableData);
			setIsLoader(false);
		})();
	}, [listID, segmentID])

	const fetchSegmentationQueryJSON = useCallback(() => {
		(async () => {
			try {
				//let jsonData = [];
				const payload = {
					segmentId: segmentID,
					subscriberListId: listID,
				}

				const response = await getSegmentQueryJson(payload);

				if (_get(response, "data.data[0].SelectedJsonData", null) !== null) {
					let jsonData = JSON.parse(_get(response, "data.data[0].SelectedJsonData", ""));

					if (!_isEmpty(jsonData)) {
						submitTestFilter(jsonData);
					}
				}

				//setSegmentJSONData(jsonData);
			} catch (error) {
				console.log("Error uploading: ", error);
			}
		})();
	}, [listID, segmentID, submitTestFilter])

	//First Time Load
	useEffect(() => {
		(async () => {
			try {
				fetchSegmentationQueryJSON();
			} catch (err) {
				console.log('Error occured when fetching archived contacts list', err);
			}
		})();
	}, [fetchSegmentationQueryJSON]);

	return (
		<>
			<div className="dashbd_sec margin-top100">
				<div className="breadcrum">
					<BreadCrum
						ToText="CRM List"
						To="/crm/people/listings"
						ToSecondPage={`/crm/people/subscribers/${(listID || "")}/${(creationSource || "")}/`}
						SecondBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
						ToSecondPageText={_get(location, "state.selecteListName", "All Contacts ")}
						ToThirdPage={`/crm/people/subscribers/${(listID || "")}/${(creationSource || "")}/segment/list`}
						ThirdBreadCrumbState={{ selecteListName: _get(location, "state.selecteListName", "All Contacts "), selectedListId: (listID || "") }}
						ToThirdPageText="Segments"
						CurrentPage="View"
					/>
				</div>

				<div className="bx-boder-common common_Sec_1 mt-10">
					<div className="_h_header">
						<h3>View Segment</h3>
					</div>

					<div className="pb-5">
						<div className="row">
							<div className="col-12">
								{((isLoader || false) === true) && (
									<TableRowsLoading />
								)}

								{((segmentTableColumnsData || []).length < 1) && ((isLoader || false) === false) && (
									<div className="p-4">
										<div className="alert alert-warning">
											We can’t find any contacts to match your search.
											<br /><br />
											<p> Check your spelling or try a different search.</p>
										</div>

										<div className="dataTable-primary dataTable-primary-450">
											<div className="rdt_TableBody"></div>

										</div>
									</div>
								)}

								{((segmentTableColumnsData || []).length > 0) && ((isLoader || false) === false) && (
									<div className="dataTable-primary">
										<DataTable
											columns={segmentTableColumns}
											data={segmentTableColumnsData}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div >
		</>
	)
}

export default ViewSegment;