import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { HightChartColorProps } from "../../../constant/HightChartBestProps";
import { HightChartPieProps } from "../../../constant/HightChartPieProps";
const SegmentGroup = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    Highcharts.setOptions(HightChartColorProps);
    const [segementGroupData, setSegementGroup] = useState(HightChartPieProps);
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            // SMS Insight  ---- Tabing Information 
            Client.get(`Insights/distribution/SMS/${id}/Recipients/KPI?section=1`)
                .then(({ data }) => {
                    const Data = JSON.parse(data[0].KPI || "[]");
                    const newArry = [...Data.map(item => ({ name: item.GroupName, y: item.Count }))];
                    setSegementGroup({ series: [{ data: newArry }] });

                });
    }, [APITOKEN, id]);
    return (
        <HighchartsReact highcharts={Highcharts} options={segementGroupData} />
    )
}
export default SegmentGroup;