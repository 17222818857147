import React, {  useState } from 'react';
import { Link } from 'react-router-dom';
import LandingPageKPI from './LandingPageKPI';
import Device from './Device';
import Engagment from './Engagment';
import Source from './Source';
import Response from './Response';
import ExportTab from './Export';
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
// import moment from 'moment';
const Tabing = ({ data }) => {
    const { APITOKEN } = useSelector(state => state);
    
    const [max, setDate] = useState("");
    const [postObject ,SetPostObject] = useState({ "locale": "en-IN", "fromDate": "", "toDate": "" });
    const defaultId = 60;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    const selectedDate = ev => {
        const dateSplit = ev.valueText.split(' - ');
        SetPostObject({
            locale: APITOKEN.config.cultureCode,
            fromDate: dateSplit[0],
            toDate: dateSplit[1]
        })
    }
    useEffect(() => {
        const data = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        setDate(data);
    }, [APITOKEN.config])
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
                <div className="_DateRange fright">
                    <Datepicker placeholder="Date Range"
                    // value={[postObject.fromDate,postObject.toDate]}
                    max={max}
                    select="range"
                    touchUi={true}
                    onChange={e => selectedDate(e)}
                    dataTimezone='utc'
                    dateFormat="YYYY.MM.DD"
                    locale={APITOKEN.config.countryCode}
                    theme="ios"
                    controls={['calendar']} /></div>

            </div>
            {
                <div className="tab_body">{tabId === defaultId ?
                    <LandingPageKPI tabId={tabId} postObject={postObject} /> :
                    tabId === 65 ? <Response postObject={postObject}/> :
                        tabId === 62 ? <Device postObject={postObject}/> :
                            tabId === 61 ? <Engagment tabId={tabId} postObject={postObject} /> :
                                tabId === 66 ? <ExportTab postObject={postObject}/> :
                                    <Source tabId={tabId} postObject={postObject}/>}
                </div>
            }
        </>
    )
}

export default Tabing;