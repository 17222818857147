import { useCallback, useEffect, useMemo, useState } from "react";
import './index.css';
import { useSelector } from "react-redux"
import { useNavigate } from "react-router"
import Client from "../../../../GlobalAPICall"
import getParameterByName from "../../../../ReuseTemplate/GetRoute"
import WaterMarkRemove from "../../../../ReuseTemplate/WaterMarkRemove"
import { IndianTemplate } from "./IndianTemplate"
import { SenderName } from "./SenderName"
import { ShortURL } from "./ShortURL"
import { SMSContent } from "./SMSContent"
import { TemplateCampaign } from "./TemplateCampaign"
import { UnsubscribeContent } from "./UnsubscribeContent"
import { UnsubscribeOption } from "./UnsubscribeOption";
import { CheckIsUnicodeExists } from '../../../../ReuseTemplate/SMSCount';
import { getCursorPos } from '../../../../ReuseTemplate/GetCursorPoint';
import { MergeCodes } from './MergeCodes';
import SenderNameContainer from "../../../../Send/SMS/SenderNameContainer";
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";

export const CreateSMSTrigger = () => {
	const { APITOKEN } = useSelector(state => state);
	const [smsSenderData, setSenderName] = useState([]);
	const [disabledIndianTemplate, setIndianTemplateDisabled] = useState(false);
	let [unsubscribeToggleVal, setUnsubscribeToggle] = useState(false);
	const defaultMessage = "Hi [firstname]! Welcome to the loyalty club. Click on this link to see the latest news [uniqueurl]. Best, Your Company";
	let [messageCount, setMessageCount] = useState(defaultMessage);
	const navigate = useNavigate();
	const [selectedCampaign, setFilterCampaign] = useState({});
	const [smsTemplateData, setTemplate] = useState([]);
	const [isloader, setLoader] = useState(false);
	const [senderNameReload, setSenderNameReload] = useState(Math.random());
	const [senderNameProp, setSenderNameProperties] = useState({
		flag: false,
		newSenderInput: false,
		senderInputValue: ""
	});
	const [lowBlance, setLowBalancePopup] = useState({
		display: false
	});
	const [selectedValue, setSelectedValue] = useState({
		smsContent: "",
		senderName: "",
		campaign: {},
		smsText: defaultMessage,
		unsubscribeContent: "",
		unsubscribeOption: "",
		indianTemplateVal: "",
		campaignType: "1",
		shortURL: "",
		unsubscribeLink: ""
	});
	const setStateCallback = useCallback((messageCount) => {
		const count = CheckIsUnicodeExists((messageCount), 0)
		setMessageCount(count);
	}, []);
	const [waterMarkshow, setWaterMarkRemove] = useState(false);
	const [sendNameArrayObject, setSenderNameArray] = useState([]);
	const [elementShow, setElementShow] = useState({
		smsContent: false,
		senderName: false,
		shorturl: false,
		unsubscribeContent: false
	});
	const CampaignName = useMemo(() => {
		const type = selectedValue.campaign?.ContentTypeId === 2 ?
			'Landing Page' : selectedValue.campaign?.ContentTypeId === 5 ?
				'Email' : selectedValue.campaign?.ContentTypeId === 4 ? 'Survey' : 'Workflow';
		return type;
	}, [selectedValue.campaign]);

	const defaultGUID = '00000000-0000-0000-0000-000000000000';
	const templateEditSMS = useCallback(() => {
		(APITOKEN.token !== "") &&
			Client.get(`Trigger/Templates/${getParameterByName('cam', window.location.href)}/SMS`, {
				headers: {
					'Authorization': `Bearer ` + APITOKEN['token']
				}
			}).then((res) => {
				try {
					if (res.data.length !== 0) {
						const data = res.data[0];
						if (data.content.typeId === 7) {
							setElementShow({
								smsContent: false,
								senderName: true,
								shorturl: false,
								unsubscribeContent: data.unsubscribe.typeId === defaultGUID ? false : true
							});
							setIndianTemplateDisabled(false);
						} else {
							setElementShow({
								smsContent: true,
								senderName: true,
								shorturl: true,
								unsubscribeContent: data.unsubscribe.typeId === defaultGUID ? false : true
							});
							// setIndianTemplateDisabled(true);
						}

						data.unsubscribe === undefined ? setUnsubscribeToggle(false) : setUnsubscribeToggle(true);
						setSelectedValue({
							smsContent: data.content.typeId,
							senderName: data.sendername,
							campaign: data.content.typeId === 7 ? { ContentTypeId: data.content.typeId } : {
								Name: data.content.typeName,
								Id: data.content.typeDetailId,
								PreviewUrl: data.content.previewUrl,
								WaterMarkName: data.content.previewUrl,
								ShortUrl: data.content.typeDetailUrl
								, ...data.content
							},
							smsText: data.message,
							unsubscribeContent: data.unsubscribe.typeId === defaultGUID ? "" : data.unsubscribe.typeId,
							unsubscribeOption: data.unsubscribe.typeDetailId === defaultGUID ? "" : data.unsubscribe.typeDetailId,
							indianTemplateVal: data.content.indianSMSTemplateId,
							campaignType: (data.shortnerUrl.shortnerUrlType === 0) ? "" : data.shortnerUrl.shortnerUrlType,
							shortURL: (data.shortnerUrl.shortnerUrl === undefined) ? "" : data.shortnerUrl.shortnerUrl,
							unsubscribeLink: data.unsubscribe.typeShortUrl
						});
						setStateCallback(`${data.message} ${data.content.typeDetailUrl} ${data.unsubscribe.typeShortUrl}`);
					}

				} catch (err) {
					console.error(err);
				}
			});
	}, [APITOKEN, setStateCallback])

	useEffect(() => {
		setStateCallback(defaultMessage);
		if (getParameterByName('edit', window.location.href) !== null) {
			templateEditSMS();
		};
	}, [templateEditSMS, setStateCallback])
	const sendercontent = (e) => {

		if (e.target.value === '7') {
			setSelectedValue(prv => ({
				...prv,
				smsContent: e.target.value,
				campaignType: '',
				shortURL: '',
				campaign: { ContentTypeId: e.target.value }

			}));
			setElementShow(prv => ({
				...prv,
				shorturl: false,
				smsContent: false
			}));
		} else {
			setSelectedValue(prv => ({
				...prv,
				smsContent: e.target.value,
				campaign: {
					ContentTypeId: e.target.value,
					Id: ""
				},
				campaignType: "1",
			}));
			setElementShow(prv => ({
				...prv,
				smsContent: e.target.value === "" ? false : true,
				shorturl: true
			}));
		}
	}
	const senderSenderName = (e) => {
		if (e.target.value === "") {
			setIndianTemplateDisabled(false);
			setSelectedValue({
				...selectedValue,
				...{ senderName: e.target.value, indianTemplateVal: "", smsText: defaultMessage }
			});
		} else {
			if (APITOKEN.config.countryCode === "in") {
				setSelectedValue(prv => ({
					...prv,
					indianTemplateVal: '',
					senderName: e.target.value
				}));
			} else {
				setSelectedValue(prv => ({
					...prv,
					senderName: e.target.value
				}));
			}
		}
		setElementShow({
			...elementShow,
			...{ senderName: e.target.value === "" || e.target.value === '1' ? false : true }
		});
	}
	const campaignLink = (v) => {
		if (v.length === 0) {
			setSelectedValue(prv => ({
				...prv,
				campaign: {
					Id: "",
					ContentTypeId: ""
				}
			}));
		} else {
			if (v[0]['HasWaterMark']) {
				setWaterMarkRemove(true);
				setElementShow(prv => ({ ...prv, smsContent: true }));
			} else {
				setWaterMarkRemove(false);
			}
			setStateCallback(`${selectedValue.smsText} ${v[0].ShortUrl} ${selectedValue.unsubscribeLink}`);
			setSelectedValue(prv => ({
				...prv,
				campaign: v[0]
			}));
		}

	}
	const indianTemplate = (v) => {
		setSelectedValue(prv => ({
			...prv,
			smsText: v.length === 0 ? defaultMessage : v[0].smsText,
			indianTemplateVal: v.length === 0 ? '' : v[0].templateId,
			campaign: {
				...prv.campaign,
				Name: selectedValue.campaign.Name

			}
		}));
		if (v.length !== 0) {
			setIndianTemplateDisabled(true);
		} else {
			setIndianTemplateDisabled(false);
		}
	}


	const unsubscribeFN = (e) => {
		setSelectedValue(prv => ({
			...prv,
			unsubscribeContent: e.target.value
		}));
		setElementShow(prv => ({
			...prv,
			unsubscribeContent: e.target.value === "" ? false : true
		}));
	}
	const saveMethod = () => {
		const CampaignId = getParameterByName('cam', window.location.href);
		const obj = {
			"id": CampaignId,
			"sendername": selectedValue.senderName,
			"message": selectedValue.smsText,
			"content": {
				"typeId": selectedValue.campaign.ContentTypeId || selectedValue.campaign.typeId,
				"indianSMSTemplateId": selectedValue.indianTemplateVal,
				"typeDetailId": (selectedValue.campaign.Name === undefined) ? null : selectedValue.campaign.Id
			},
			"unsubscribe": {
				"typeId": (selectedValue.unsubscribeContent === "") ? "00000000-0000-0000-0000-000000000000" : selectedValue.unsubscribeContent,
				"typeDetailId": (selectedValue.unsubscribeOption === "") ? "00000000-0000-0000-0000-000000000000" : selectedValue.unsubscribeOption
			},

		};
		if (selectedValue.smsContent.toString() === "7") {
			delete obj['content'].typeDetailId;
			delete obj["shortnerUrl"];
		} else if (selectedValue.smsContent.toString() === "6") {
			delete obj["shortnerUrl"];
		} else {
			obj["shortnerUrl"] = {
				"shortnerUrl": (selectedValue.campaignType === "") ? "" : selectedValue.shortURL,
				"shortnerUrlType": selectedValue.campaignType
			}
		}
		if (APITOKEN.config.countryCode !== "in") {
			delete obj['content'].indianSMSTemplateId;
		}
		setLoader(true);
		if (getParameterByName('edit', window.location.href) !== null) {
			PutAPI(obj, CampaignId);
		} else {
			PostAPI(obj);
		}
	}
	const PostAPI = obj => {
		Client.post(`Trigger/Templates/SMS`, obj, {
			headers: {
				'Authorization': `Bearer ` + APITOKEN['token']
			}
		}).then(({ data }) => {
			try {
				setLoader(false);
				if (data.Success) {
					navigate(`/create/templates/sms?type=${getParameterByName('type', window.location.href)}`);
				}
			} catch (err) {
				console.log(err);
			}
		}).catch(err => console.error(err))
	}
	const PutAPI = (obj, CampaignId) => {
		Client.put(`Trigger/Templates/${CampaignId}/SMS`, obj, {
			headers: {
				'Authorization': `Bearer ` + APITOKEN['token']
			}
		}).then(({ data }) => {
			try {
				setLoader(false);
				if (data.Success) {
					navigate(`/create/templates/sms?type=${getParameterByName('type', window.location.href)}`);
				}
			} catch (err) {
				console.log(err);
			}
		}).catch(err => console.error(err))
	}
	const closeWaterMarkPopup = (f) => {
		setWaterMarkRemove(f);
	}
	const reloadElement = () => {

		setSelectedValue(prv => ({
			...prv,
			smsContent: selectedValue.smsContent,
			...{
				campaign: {
					...selectedValue.campaign,
					HasWaterMark: false,
					Name: selectedValue.campaign.Name
				}
			}
		}));
		setElementShow({
			...elementShow,
			...{ smsContent: true }
		});
	}
	const [shortCodeVal, setShortCodeVal] = useState({ first: '', last: '' });
	const [shortCodeShow, setShortCodeToggle] = useState(false);
	const smsMessageFN = (e) => {
		setShortCodeToggle(true)
		const count = getCursorPos(e.target);
		const first = selectedValue.smsText.substring(0, count['start']);
		const last = selectedValue.smsText.substring(selectedValue.smsText.length, count['start']);
		setShortCodeVal({ first, last })
	}

	const shortCodeClick = (v) => {
		setSelectedValue({ ...selectedValue, ...{ smsText: `${shortCodeVal.first}${v.tag}${shortCodeVal.last}` } });
	}
	const closeShortCode = () => {
		setShortCodeToggle(false);
	}
	const senderNameFN = () => {
		setSenderNameProperties(prv => ({ ...prv, flag: true }))
	}

	return (
		<div className="dashbd_sec">
			<div className="sms_Sec_1 bx-boder-common inline-block margin-bottom">
				<div className="_h_header relative">
					<h3>SMS Trigger</h3>
					<span className="flex-right">
						<button type="text" className="grid_btn_1" onClick={() => APITOKEN.config.countryCode === 'in' ? navigate('/create/templates/localSender?type=92') : senderNameFN()}>Sender Name</button>
					</span>
				</div>
				<div className="bx-pad">
					<SMSContent ChangeEvent={sendercontent} Selected={setSelectedValue} Value={selectedValue} />
					{elementShow.smsContent && <TemplateCampaign
						Value={selectedValue}
						ChangeEvent={campaignLink}
						setTemplate={setTemplate}
						smsTemplateData={smsTemplateData}
						setFilterCampaign={setFilterCampaign}
						selectedCampaign={selectedCampaign}
					/>}
					<SenderName
						ChangeEvent={senderSenderName}
						Selected={setSelectedValue}
						Value={selectedValue}
						Array={setSenderNameArray}
						SetSenderName={setSenderName}
						SmsSenderData={smsSenderData}
						ReloadVar={senderNameReload}
					/>

					{elementShow.senderName && <IndianTemplate Value={selectedValue} ChangeEvent={indianTemplate} />}

					{selectedValue.campaign.Name !== undefined &&
						<div className="formmodel-bx">
							<label className="_l_able_cls">Campaign Type</label>
							<select
								className="inputformmodel"
								value={selectedValue.campaignType}
								onChange={(e) => { setElementShow({ ...elementShow, ...{ shorturl: (e.target.value === "1" || e.target.value === "") ? true : false } }); setSelectedValue({ ...selectedValue, ...{ campaignType: e.target.value } }) }}>
								<option value="">Select</option>
								<option value="1">With Unique URL</option>
								<option value="0">Without Unique URL</option>
							</select>
						</div>
					}
					{elementShow.shorturl && <ShortURL Value={selectedValue} Selected={setSelectedValue} />}
					<div className="formmodel-bx">
						<label className="_l_able_cls">SMS Text</label>
						<div className="drop-zone">
							{shortCodeShow && <MergeCodes onClick={shortCodeClick} Value={selectedValue} CloseShortCode={closeShortCode} />}

							<textarea className="inputformmodel textareaModel"
								value={selectedValue.smsText}
								disabled={disabledIndianTemplate}
								onClick={smsMessageFN}
								onChange={(e) => { setSelectedValue({ ...selectedValue, ...{ smsText: e.target.value } }); setStateCallback(e.target.value) }}>

							</textarea>
							<p className="smsCountInfor text-right">
								Total SMS characters : {selectedValue.smsText.length +
									((selectedValue.campaign.ShortUrl === undefined) ? 0 : selectedValue.campaign.ShortUrl.length) +
									((selectedValue.unsubscribeLink === '' || selectedValue.unsubscribeLink === undefined) ? 0 : selectedValue.unsubscribeLink.length)
								}
								<br></br>
								Number of SMS(s) : {messageCount}
							</p>
						</div>
					</div>
				</div>
				<div className="_h_header b_top">
					<h3>Add Unsubscribe</h3>
					<span className="flex-right">
						<button type="button" className="grid_btn_1" onClick={() => setUnsubscribeToggle(unsubscribeToggleVal = !unsubscribeToggleVal)}>{unsubscribeToggleVal ? 'Close' : 'Add'}</button>
					</span>
				</div>
				<div className="bx-pad">
					{unsubscribeToggleVal && <><UnsubscribeContent ChangeEvent={unsubscribeFN} Selected={setSelectedValue} Value={selectedValue} />
						{elementShow.unsubscribeContent && <UnsubscribeOption Selected={setSelectedValue} Value={selectedValue} />}</>}

				</div>
				<div className="formmodel-bx nomargin text-right padding20">
					<button type="button" className="grid_btn_1 nobg noborder" onClick={() => navigate(`/create/templates/sms?type=8`)}>Cancel</button>
					{/* Object.keys(selectedValue.campaign).length === 0 && */}
					<button type="button" className="grid_btn_1 grid_btn_active"
						disabled={
							parseInt(selectedValue.smsContent) === 7 ?
								(sendNameArrayObject.length > 0 ?
									selectedValue.senderName !== "" ?
										APITOKEN.config.countryCode === "in" ? selectedValue.indianTemplateVal !== "" ?
											true : false :
											true :
										false :
									true) &&
									(selectedValue.campaignType === '' &&
										selectedValue.shortURL === '')
									? false : true :
								(selectedValue.smsContent !== "" &&
									(Object.keys(selectedValue.campaign).length === 0 ?
										false : selectedValue.campaign.Id === "" || selectedValue.campaign.HasWaterMark ? false : true) &&
									selectedValue.smsText !== "" &&
									(sendNameArrayObject.length > 0 ?
										selectedValue.senderName !== "" ?
											APITOKEN.config.countryCode === "in" ? selectedValue.indianTemplateVal !== "" ? true : false :
												true : false :
										true)
									&&
									(selectedValue.campaignType === "" ?
										false : selectedValue.campaignType !== "1" ?
											true : selectedValue.shortURL !== '' ?
												true : false)
									? false : true)
						}

						onClick={saveMethod}>
						<ButtonLoader IsLoader={isloader}>
							{getParameterByName('edit', window.location.href) !== null ? 'Update' : 'Save'}
						</ButtonLoader>

					</button>

				</div>
			</div>
			<div className="previewPanel">
				<div className="iphonePriview excludeClass padding20" style={{ boxSizing: 'border-box' }}>
					{selectedValue.campaign.Name !== undefined ?
						<iframe title="preview" id="previewIframeTrigger" src={selectedValue.campaign.PreviewUrl} className="noborder"></iframe> :
						selectedValue.campaign.ContentTypeId === 7 ? <div className="smstxtphone margin-top50">{selectedValue.smsText}</div> :
							<div className="previewDefaultMsg">
								Hi!
								<br></br>
								If you choose to add <span>SMS Content</span>
								to your SMS a preview will be displayed here.
							</div>
					}
				</div>
			</div>
			{senderNameProp.flag ? <SenderNameContainer
				SetSenderNameProperties={setSenderNameProperties}
				SmsSenderData={smsSenderData}
				SenderNameProp={senderNameProp}
				Reload={setSenderNameReload}
			/> : <></>}
			{
				waterMarkshow && <WaterMarkRemove
					closeEvent={closeWaterMarkPopup}
					StartDate={selectedValue.campaign.StartDate}
					EndDate={selectedValue.campaign.EndDate}
					CampaignData={selectedValue.campaign.WaterMarkName}
					Id={selectedValue.campaign.Id}
					Data={selectedValue.campaign}
					Reload={reloadElement}
					contentId={selectedValue.campaign.ContentTypeId}
					setLowBalancePopup={setLowBalancePopup}
					lowBlance={lowBlance}
				>
					<div>
						<h3>Activate</h3>
						<p>To Activate your {CampaignName} you need to set a Activation Period. Before you set the Activation Period a Watermark layer is disabling the {CampaignName} for interaction. You can still view the {CampaignName}.</p>
					</div>
					<div>
						<h3>Activation Period</h3>
						<p>In the Activation Period you visitors can interact with you {CampaignName} and you will find all registered activity under Insights.</p>
					</div>
					<div style={{ paddingBottom: '27px' }}>
						<h3>Closing Time</h3>
						<p>When the Closing Time is reached we will add a Watermark Text as a layer on top of your {CampaignName}, disabling it for interaction.</p>
					</div>

				</WaterMarkRemove>
			}
		</div >
	)

}

