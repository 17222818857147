import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import { CreateUTM } from "./CreateUTM";
import DeleteListTemplate from '../../../../ReuseTemplate'
import './index.css'
import BlankData from "../../../../BlankData";
import ButtonLoader from "../../../../ReuseTemplate/ButtonLoader";
import GridLoader from "../../../../ReuseTemplate/GridLoader";
const UTMTerm = () => {
    const {APITOKEN} = useSelector(state => state);
    const [templateData, setTemplateData] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [IsLoader, setIsLoader] = useState(false);

    const [formVal, setFormVal] = useState({
        name: "",
        description: ""
    });
    const [apiResponse, setAPIResponse] = useState({});

    const load = useCallback(() => {
        setIsLoading(true);
        (APITOKEN['token'] !== "") &&
            Client.get(`UTM/GetUTMCategories/Term`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({data}) => {
                try {
                    setIsLoading(false);
                    setTemplateData(data);
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN]);

    const openModel = () => {
        setToggle(true);
        setFormVal({ name: "", description: "" });
    }
    const saveFN = () => {
        setIsLoader(true)
        const obj = {
            description: formVal.description,
            name: formVal.name,
            isTagFiled: true
        };

        !formVal.id ? PostAPi(obj) : PutAPi(obj)
    }

    const PostAPi = (obj) => {
        Client.post(`UTM/SaveUTMCategories/Term/Data`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                setIsLoader(false);
                if (data.success) {
                    setToggle(false);
                    load();
                    setFormVal({ name: "", description: "" });
                }else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoader(false);
        });
    }
    const PutAPi = (obj) => {
        Client.put(`UTM/UpdateUTMCategories/Term/${formVal.id}/Categories_details`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                setIsLoader(false);
                if (data.success) {
                    setToggle(false);
                    load();
                    setFormVal({ name: "", description: "" });
                }else {
                    setAPIResponse({ message: data.message });
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.log(err);
            setIsLoader(false);
        });;
    }
    let [deleteFlag, setDelete] = useState(false);
    const deleteFN = (v) => {
        setDelete(true);
        setFormVal({ ...formVal, ...{ name: v.name, description: v.description, id: v.rowUID } });
    }
    const deleteConfirm = () => {

        Client.delete(`UTM/DeleteUTMCategories/Term/${formVal.id}`, {
            data: {},
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({data}) => {
            try {
                if (data.success) {
                    setDelete(false);
                    load();
                    setFormVal({ name: "", description: "" });
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

   
    const editFN = (v) => {
        setToggle(true)
        setFormVal({ ...formVal, ...{ name: v.name, description: v.description, id: v.rowUID } });
    }
    useEffect(() => {
        load()
    }, [load])
    return (<>

        {deleteFlag ? <div className="margin-top50 inline-block width100"><DeleteListTemplate onClick={() => setDelete(deleteFlag = !deleteFlag)} deleteDownload={deleteConfirm} title="Click on Confirm to delete this UTM Term." content="This will permanently delete and it can not be restored." /></div> :
            <div className="sms_Sec_1 bx-boder-common width100 nobg">
                <div className="_h_header white border-radius2">
                    <h3>UTM Term</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 _template_grid grid_btn_active" onClick={openModel}>Create Term</button>
                    </span>
                </div>
                <div className="_list_fx">
                { isLoading ? <GridLoader TDArray={[1, 2, 3, 4]} /> : <table className="smstable white" cellPadding="0" cellSpacing="0">
                        {templateData.length > 0 &&
                            <thead>
                                <tr>
                                    <th className="table_cell_header">Term Name</th>
                                    <th className="table_cell_header">Description</th>
                                    <th className="table_cell_header">Creation Time	</th>
                                    <th className="table_cell_header">Created By</th>
                                    <th className="table_cell_header">Actions</th>
                                </tr>
                            </thead>
                        }
                        <tbody>

                            { templateData.length === 0 ? <BlankData title="You have not created any UTM Term yet." content="The UTM Term parameter is used for paid search campaigns to track relevant ad-based keywords. This will help you identify the keywords which resulted on a visit on your site. Click on the green button to create a UTM Term." colSpan="5" /> : templateData && templateData.map((item, i) => <Grid item={item} key={i} EditOnclick={editFN} Deleteclick={deleteFN} />)}

                        </tbody>

                    </table>}
                </div>
            </div>
        }

        {toggle && <CreateUTM Title="Term" close={() => { setToggle(false); setAPIResponse({})}} >
            <div className="formmodel-bx">
                <label className="_l_able_cls">Term Name</label>
                <input type="text" className="inputformmodel width100" placeholder="Name" value={formVal.name} onChange={e => setFormVal({ ...formVal, ...{ name: e.target.value } })} />
                {apiResponse.message && <p className="validation">{ apiResponse.message }</p>}
            </div>
            <div className="formmodel-bx">
                <label className="_l_able_cls">Description  </label>
                <input type="text" className="inputformmodel width100" placeholder="Optional" value={formVal.description} onChange={e => setFormVal({ ...formVal, ...{ description: e.target.value } })} />
            </div>
            <div className="formmodel-bx">
                <label className="_l_able_cls">
                    <strong>Term Name</strong>
                </label>
                <p>Lower case characters are set as default and hyphen is used for combining the words.</p>
            </div>
            <div className="formmodel-bx text-right">
                <button className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => { setToggle(false); setAPIResponse({})}} type="button">Cancel</button>
                <button className="_clsbtn grid_btn_1 grid_btn_active" disabled={(formVal.name !== "") ? false : true} type="button" onClick={() => saveFN()}>
                <ButtonLoader IsLoader={IsLoader}>
                        {formVal.id === undefined ? 'Save' : 'Update'}
                </ButtonLoader>
                </button>
            </div>
        </CreateUTM>}
    </>)
};
export default UTMTerm;

const Grid = ({ item, EditOnclick, Deleteclick }) => {
    return (
        <><tr><td className="body_table_cell excludeClass">{item.name}</td><td className="body_table_cell">{item.description}</td><td className="body_table_cell">{item.creationTime}</td><td className="body_table_cell">{item.createdBy}</td><td className="body_table_cell"><button type="button" className="link inline-block" onClick={() => EditOnclick(item)}>Edit</button>&nbsp; <button type="button" className="link inline-block utm_tem_delet" onClick={() => Deleteclick(item)}>Delete</button></td></tr></>
    )
}