import React, { useEffect, useState } from 'react'
import { useCallback } from 'react';
import ChargifyPayment from '../../ReuseTemplate/ChargifyPayment';
import Client from '../../GlobalAPICall';
import { AddFundRePayment } from '../../ReuseTemplate/Payment';
import ButtonLoader from '../../ReuseTemplate/ButtonLoader';

export default function AddFundPopup(props) {
    const [getBlanceSetting, setGetBlanceSetting] = useState({
        isEnableAutoRecharge:false
    });
    const [reloadBalance, setPreloadBalance] = useState({
        isloader: false,
        response: false,
        isloaderaddFund: false
    });
    const { APITOKEN, lowBlance, setLowBalancePopup, removeWaterMark } = props;
    const [chargifyDisplay, setChargifyDisplay] = useState(false);
    const [addFundValue, setAddFund] = useState("");
    const [refreshLoader, setRefreshLoader] = useState(false);
    const [lowBalaceManagement, setLowBalaceManagement] = useState({
        resource: false,
        settings: false,
        threshold:"",
        refill:""
    });
    const lowBlanceSetting = useCallback(() => {
        Client.get('User/LowBalanceAlert_Settings', {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setPreloadBalance({
                    isloader: false,
                    response: false
                });
                setGetBlanceSetting(prv => ({...prv,...data,paymentMode:data.isEnableAutoRecharge}));
                setLowBalaceManagement(prv=>({...prv,threshold:data.lowBalance,refill:data.refilAmount}))
            }).catch(err => console.error(err));
    }, [APITOKEN]);

    const addFund = () => {
        const obj = {
            "amount": addFundValue,
            "details": "Add Prepaid Fund",
            "memo": "Add Prepaid Fund"
        };
        setPreloadBalance({
            isloader: false,
            response: false,
            isloaderaddFund: true
        });
        addFundCommonMethod(obj);
        
    }
    const addFundCommonMethod = obj => {
        AddFundRePayment({ obj, APITOKEN }, function (data) {
            setPreloadBalance(prv => ({
                ...prv,
                isloader: false,
                isloaderaddFund: false,
                response: true,
                ...data
            }));
            setAddFund("");
            removeWaterMark();
        });
    }
   
    const lowBalanceManagement = () => {
        const data = {
            "lowBalance": Number(lowBalaceManagement.threshold),
            "refilAmount": Number(lowBalaceManagement.refill),
            "paymentMode": 1
        };
        Client.post('User/LowBalanceAlert_Settings', data, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                if (data['Success']) {
                    setLowBalancePopup(prv => ({ ...prv, display: false }));
                    removeWaterMark();
                }
            }).catch(err => console.error(err));
    }
    const refreshBtn = () => {
        setRefreshLoader(true);
        Client.get('User/PaymentProfile', {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setRefreshLoader(false);
                setLowBalancePopup(prv => ({
                    ...prv,
                    paymentProfile: {
                        cardType: data.cardType,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        maskedCardNumber: data.maskedCardNumber,
                        expirationMonth: data.expirationMonth,
                        expirationYear: data.expirationYear
                    }
                }));
            }).catch(err =>
                setRefreshLoader(false)
            );
    }
    useEffect(() => {
        lowBlanceSetting()
    }, [lowBlanceSetting])
    return (
        <>
            <div className="balanceMasking _balancePopRemove"></div>
            <div className="__balanceCheckContainer">
                <div className="topn-header">
                    <span className="selected-title">{APITOKEN.config.paymentSource === "" || APITOKEN.config.paymentSource === null || APITOKEN.config.paymentSource === "onlinecardpayment" ? 'Add funds' : 'Support Contact'}</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => setLowBalancePopup(prv => ({ ...prv, display: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer _balancePopRemove"></i></span>
                </div>
                {APITOKEN.config.paymentSource === "" || APITOKEN.config.paymentSource === null || APITOKEN.config.paymentSource === "onlinecardpayment" ?

                    <div className="">

                        <div className="padding20">
                            {!!lowBlance['paymentProfile'] ?
                                <div className="formmodel-bx paymentCardbox__panel">
                                    <label className="_l_able_cls">Charge my existing credit card</label>
                                    <div className="card_info member__Card">
                                        <p className="card__logo">
                                            <img alt='card' width={30} src={lowBlance.paymentProfile.cardType === 'visa' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/visa.png' : lowBlance.paymentProfile.cardType === 'master' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/mastercard.png' : 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/amax.png'} />
                                        </p>
                                        <p className="cardMember_info">{lowBlance['paymentProfile'].firstName} {lowBlance['paymentProfile'].lastName}</p>
                                        <p className="card__no">{lowBlance['paymentProfile'].maskedCardNumber}</p>
                                        <p className="card__date">{lowBlance['paymentProfile'].expirationMonth}/{lowBlance['paymentProfile'].expirationYear}</p>
                                    </div>
                                </div>
                                : <></>}
                            <div className="formmodel-bx"><label className="_l_able_cls">Add new card</label>
                                <div className="card_info"><p>Click on the button Add card . A new session will open with your billing portal and you need to click “Update Payment Method to add your card. When you have added your card, go back to this session and click on refresh to continue with Payment Option 1 or Payment Option 2.</p><br></br><p className="text-right">
                                    <button className="grid_btn_1 grid_btn_darkBlue margin-right15" onClick={refreshBtn}>
                                        <ButtonLoader IsLoader={refreshLoader}>Refresh</ButtonLoader>
                                    </button>
                                    <button className="grid_btn_1  grid_btn_darkBlue_common billingPortal" onClick={() => setChargifyDisplay(true)}>Add Card</button>
                                </p>
                                </div>
                            </div>
                        </div>

                        <div className="padding20 b_top">

                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Add funds to balance</label>
                                <div className="f-box">
                                    <input type="text" className="inputformmodel p-60-right" disabled={lowBalaceManagement.resource && lowBalaceManagement.settings && lowBalaceManagement.refill && lowBalaceManagement.threshold ? true : false} value={addFundValue} onChange={e => setAddFund(e.target.value.replace(/[^0-9]/g, ''))} />
                                    <span className="currencyGloabel  text-center">
                                        {lowBlance.currency}
                                    </span>
                                </div>
                                <button type="button" className="grid_btn_1  mr25 grid_btn_darkBlue_common  fright" disabled={addFundValue !== "" ? false : true}
                                    onClick={addFund}
                                >
                                    <ButtonLoader IsLoader={reloadBalance.isloaderaddFund} color={true}>Add Funds</ButtonLoader>
                                </button>
                            </div>
                        </div>
                        {reloadBalance.response &&
                            <div className="lowBalanceBody">
                                {!!reloadBalance.errorMessage ? <>
                                    <div class="paymentErrorMessage">
                                        <h2>Payment failed!</h2>
                                        <p>We where not able to charge your credit card. Please try again or update your
                                            credit card details. To update your credit card
                                            <a href={reloadBalance['billingPortalLink']} target="_blanks" class="inline-block link">click here</a> and a new session will
                                            open with your billing portal. After you have updated your credit card
                                            information go back to this session and try the Pay Now button again.</p>
                                    </div>
                                </> : <>
                                    {!reloadBalance.resp1 ? !reloadBalance.billingPortalLink ?
                                        <div className="paymentSucessfull">
                                            <h2>Payment success!</h2>
                                            <p>Thank you for your payment. You will find your invoice under the menu
                                                Billing/Subscription or you can <a href="/billing" target="_blank" className="inline-block link">click here</a> and a new session will open with
                                                your billing portal.</p>
                                        </div>
                                        : <></> : <>
                                        {!reloadBalance.resp1.action_link && !!reloadBalance.resp1.errors && <div className="paymentErrorMessage 3dsecureMesage"><h2>Payment failed!</h2><p>{reloadBalance['resp1'].errors[0]}</p></div>}
                                    </>}
                                </>}
                            </div>
                        }
                        <div className="padding20 b_top">
                            <h3 className="sms_content addfn">
                                <strong>Low Balance Management</strong>
                            </h3>
                            <p className="low__balance">
                                <input type="checkbox" className="lowBalance_checkbox"
                                    checked={getBlanceSetting.isEnableAutoRecharge}
                                    onChange={() => setGetBlanceSetting(prv => ({
                                        ...prv,
                                        isEnableAutoRecharge: !getBlanceSetting.isEnableAutoRecharge,
                                        paymentMode: !getBlanceSetting.isEnableAutoRecharge
                                    }))} />
                                If you want to apply an automatic recharge will we automatically recharge your account registered credit card. The charge will be bashed on your spesified refill amount and will be performed when the balance falls below your balance threshold.  </p>
                            <div className="padding20 drop-zone padding-left28">
                                {getBlanceSetting.isEnableAutoRecharge ? <></> : <div className="checkableMasking"></div>}

                                <div className="formmodel-bx">
                                    <div className="b__threshold">
                                        <div>
                                            <label className="_l_able_cls">Balance Threshold</label>
                                            <div className="f-box">
                                                <input type="text" value={lowBalaceManagement.threshold} className="inputformmodel p-60-right"
                                                    onChange={e => setLowBalaceManagement(prv => ({
                                                        ...prv,
                                                        threshold: e.target.value.replace(/[^0-9]/g, '')
                                                    }))}
                                                />
                                                <span className="currencyGloabel  text-center">
                                                    {lowBlance.currency}
                                                </span>
                                            </div>
                                        </div>
                                        <div>
                                            <label className="_l_able_cls">Refill Amount</label>
                                            <div className="f-box">
                                                <input type="text" value={lowBalaceManagement.refill} className="inputformmodel p-60-right"
                                                    onChange={e => setLowBalaceManagement(prv => ({
                                                        ...prv,
                                                        refill: e.target.value.replace(/[^0-9]/g, '')
                                                    }))} />
                                                <span className="currencyGloabel  text-center">
                                                    {lowBlance.currency}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="formmodel-bx">
                                    <input type="checkbox" className="inline-block  vertical-top margin-right15 autoCharge" onChange={e => setLowBalaceManagement(prv => ({
                                        ...prv,
                                        resource: e.target.checked
                                    }))} />
                                    <span className="inline-block w90per">I accept that my balance is automatically charged for my accounts additional resource usage.</span>
                                </div>
                                <div className="formmodel-bx">
                                    <input type="checkbox" className="inline-block vertical-top margin-right15 lowBalance" onChange={e => setLowBalaceManagement(prv => ({
                                        ...prv,
                                        settings: e.target.checked
                                    }))} />
                                    <span className="inline-block w90per">I accept to be autmatically rechaged according to my low balance management settings.</span>
                                </div>
                                <div className="formmodel-bx text-right">
                                    <button type="button" className="grid_btn_1  mr25 grid_btn_darkBlue_common __add__balance" disabled={
                                        lowBalaceManagement.resource && lowBalaceManagement.settings && lowBalaceManagement.refill && lowBalaceManagement.threshold ? false : true
                                    } onClick={lowBalanceManagement}>Save</button>
                                </div></div>
                        </div>
                    </div> :
                    <div className='padding20'>You don't have sufficient balance in your account. Kindly contact <a href={`mailto:${APITOKEN.config.subscriptionInfo.SupportUrl}`} className='link inline-block'>{APITOKEN.config.subscriptionInfo.SupportUrl}</a></div>
                }
            </div>
            {chargifyDisplay && <ChargifyPayment setChargifyDisplay={setChargifyDisplay} reload={removeWaterMark} setPreloadBalance={setPreloadBalance} />}
        </>
    )
}
