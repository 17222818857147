import { Fragment, useEffect, useRef, useState } from "react";
import {
  get as _get,
  isEmpty as _isEmpty,
  cloneDeep as _cloneDeep,
} from "lodash";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import DataTable from "react-data-table-component";
import BreadCrum from "../../../ReuseTemplate/BreadCrum";
import StaticPaginationDesign from "../../ReusableComponents/StaticPaginationDesign";
import TableRowsLoading from "../../ReusableComponents/TableRowsLoading";
import moment from "moment";

import getLocaleDateString from "../../../TimeFormat";
import Filters from "./Filters";

import {
  getCRMListContacts,
  getArchivedContacts,
  deleteContact,
  searchCRMListContacts,
  getFilters,
  updateFilters,
} from "../../../services/crm";

const archivedContactTableColumns = [
  { name: "Name", selector: (item) => item.FirstName },
  { name: "Email", selector: (item) => item.Email },
  { name: "Phone", selector: (item) => item.MobileNumber },
  { name: "Address", selector: (item) => item.Country },
  { name: "Delete", selector: (item) => item.CreationDate },
];

const ContactsList = (props) => {
  //const data = { nodes };

  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from URL
  const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

  const location = useLocation();
  const [activeTab, setActiveTab] = useState("all");
  const [contactsTableColumns, setContactsTableColumns] = useState([]);
  const [tableContacts, setContactsData] = useState([]);
  const [searchName, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const [limit, setLimit] = useState(20);
  const [pageNo, setPageNo] = useState(0);
  const [latestSelectedContact, setLatestSelectedContact] = useState([]);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [selectedArchivedContacts, setSelectedArchivedContacts] = useState([]);
  const [archivedTableData, setArchivedTableData] = useState([]);
  const [archivedContactTableLimit, setArchivedContactTableLimit] =
    useState(20);
  const [archivedContactTablePageNo, setArchivedContactTablePageNo] =
    useState(0);
  const [refreshTableData, setRefreshTableData] = useState(true);
  const [refreshArchivedTableData, setRefreshArchivedTableData] =
    useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [filtersData, setFiltersData] = useState([]);
  const [selectedDragFilterItems, setSelectedDragFilterItems] = useState([]);
  const [remainingDragFilterItems, setRemainingDragFilterItems] = useState([]);
  const [finalDraggedArray, setFinalDraggedArray] = useState([]);
  const [showContactsTableLoader, setShowContactsTableLoader] = useState(false);
  const [showDeleteDropDown, setShowDeleteDropDown] = useState(true);
  const [showDeleteContactSection, setShowDeleteContactSection] = useState({
    flag: false,
    type: "",
  });
  const { APITOKEN } = useSelector((state) => state);
  const f = getLocaleDateString(APITOKEN.config.cultureCode);
  const wrapperRef = useRef(null);

  const [isOpenFilter, setIsOpenFilter] = useState(false);

  // Fetch Contacts if search/pageNo/limit change
  useEffect(() => {
    (async () => {
      try {
        if ((refreshTableData || false) === true) {
          setShowContactsTableLoader(true);

          let response = {};
          if ((searchName || null) === null) {
            const payload = {
              id: listID,
              CreationSource: creationSource,
              search: searchName || null,
              skip: pageNo || 0,
              limit: limit || 20,
            };

            response = await getCRMListContacts(payload);
          } else {
            const payload = {
              id: listID,
              search: searchName || null,
              skip: pageNo || 0,
              limit: limit || 20,
            };

            response = await searchCRMListContacts(payload);
          }
          setShowContactsTableLoader(false);

          // Set Contact Table data in State
          setContactsData(_get(response, "data.recordList", []));
          setTotalRows(_get(response, "data.totalCount", 0));
          setRefreshTableData(false);

          let contactsColumns = [];
          if (!_isEmpty(_get(response, "data.recordList", []))) {
            Object.keys(_get(response, `data.recordList[${0}]`, [])).map(
              (rowKey, i) => {
                if (
                  ![
                    "PeopleId",
                    "Site_Id",
                    "ExternalContactID",
                    "encode",
                    "People_SubscriberId",
                    "SubscriberlistId",
                  ].includes(rowKey || "")
                ) {
                  return contactsColumns.push({
                    name: rowKey,
                    sortable: true,
                    minWidth: "200px",
                    selector: (item) => _get(item, `[${rowKey}]`, ""),
                    reorder: true,
                    cell: (item) => {
                      if ((rowKey || "") === "Name") {
                        return (
                          <Link
                            to={`/crm/people/subscribers/${_get(
                              item,
                              `[SubscriberlistId]`,
                              ""
                            )}/${creationSource}/subscriberProfile?id=${_get(
                              item,
                              `[PeopleId]`,
                              ""
                            )}&pId=${_get(
                              item,
                              `[People_SubscriberId]`,
                              ""
                            )}&previousId=${listID || ""}`}
                            state={{
                              selecteListName: _get(
                                location,
                                "state.selecteListName",
                                "All Contacts "
                              ),
                              selectedListId: listID || "",
                            }}
                          >
                            {_get(item, `[${rowKey}]`, "")}
                          </Link>
                        );
                      } else if (["Creation Date"].includes(rowKey || "")) {
                        return moment(_get(item, `[${rowKey}]`, "")).format(f);
                      } else if (
                        ["DateofBirth", "Date of Birth "].includes(
                          rowKey || ""
                        ) &&
                        _get(item, `[${rowKey}]`, "") !== ""
                      ) {
                        return moment(_get(item, `[${rowKey}]`, "")).format(
                          "DD-MM-YYYY"
                        );
                      } else if (
                        ![
                          "PeopleId",
                          "Site_Id",
                          "ExternalContactID",
                          "encode",
                          "People_SubscriberId",
                          "SubscriberlistId",
                        ].includes(rowKey || "")
                      ) {
                        return _get(item, `[${rowKey}]`, "");
                      }
                    },
                  });
                }
                return true;
              }
            );
          }
          console.log("contactsColumns ddd: ", contactsColumns);
          setContactsTableColumns(contactsColumns);
        }
      } catch (err) {}
    })();
  }, [
    refreshTableData,
    searchName,
    pageNo,
    limit,
    creationSource,
    listID,
    location,
    f,
  ]);

  // Fetch Archived Contacts List
  useEffect(() => {
    (async () => {
      try {
        if (
          (refreshArchivedTableData || false) === true &&
          (activeTab || "") === "archived"
        ) {
          const payload = {
            ListId: listID,
            skip: archivedContactTablePageNo || 0,
            limit: archivedContactTableLimit || 20,
          };

          const response = await getArchivedContacts(payload);

          // Set Archived Contacts Table data in State
          setArchivedTableData(_get(response, "data.recordList", []));
          setRefreshArchivedTableData(false);
        }
      } catch (err) {
        console.log("Error occured when fetching archived contacts list", err);
      }
    })();
  }, [
    refreshArchivedTableData,
    archivedContactTablePageNo,
    archivedContactTableLimit,
    activeTab,
    listID,
  ]);

  // Fetch Filters List
  useEffect(() => {
    console.log("isOpenFilter: ", isOpenFilter);
    if (isOpenFilter === true) {
      (async () => {
        try {
          const response = await getFilters({ subscriberListId: listID });

          let seqIndex = 0;
          let selectedDragFilterItems = [];
          let remainingDragFilterItems = [];
          let filterFields = [
            { Name: "Default Fields", Array: [] },
            { Name: "Custom Fields", Array: [] },
          ];
          _get(response, "data", []).forEach((field) => {
            if (_get(field, "FeildType", 0) === 1) {
              // Set Custom Fields
              filterFields[0]["Array"].push(field);
            } else {
              // Set Default Fiels
              filterFields[1]["Array"].push(field);
            }

            if (
              _get(field, "Ismapped", false) === true &&
              _get(field, "IsReadOnly", false) !== true
            ) {
              seqIndex++;
              selectedDragFilterItems.push({ ...field, sequence: seqIndex }); // Array of fields that are included in draggable section
            } else if (
              _get(field, "Ismapped", false) === false &&
              _get(field, "IsReadOnly", false) !== true
            ) {
              remainingDragFilterItems.push(field); // Array of fields that are not included in draggable section
            }
          });
          console.log(
            "filterFields, selectedDragFilterItems, remainingDragFilterItems: ",
            filterFields,
            selectedDragFilterItems,
            remainingDragFilterItems
          );
          setFiltersData(filterFields);
          setSelectedDragFilterItems(selectedDragFilterItems);
          setRemainingDragFilterItems(remainingDragFilterItems);
          setFinalDraggedArray([
            ...selectedDragFilterItems,
            ...remainingDragFilterItems,
          ]);
        } catch (err) {
          console.log(
            "Error occured when fetching archived contacts list",
            err
          );
        }
      })();
    }
  }, [listID, isOpenFilter]);

  // Update Filters when
  const saveFilters = () => {
    (async () => {
      try {
        let tmpFinalDraggedArray = _cloneDeep(finalDraggedArray);

        tmpFinalDraggedArray = (tmpFinalDraggedArray || []).map((element) => {
          delete element["FeildType"];

          return element;
        });

        const response = await updateFilters({
          displaynames: tmpFinalDraggedArray,
        });

        if (_get(response, "data.statusCode", 500) === 200) {
          toggleDrawer();
          setRefreshTableData(true);
        }
      } catch (err) {
        console.log("Error occured when fetching archived contacts list", err);
      }
    })();
  };

  const toggleDrawer = () => {
    setIsOpenFilter((prevState) => !isOpenFilter);
  };

  const handleChange = (e) => {
    clearTimeout(timer);
    let clearTime = setTimeout(() => {
      setSearch(_get(e, "target.value", ""));

      setRefreshTableData(true);
    }, 500);
    setTimer(clearTime);
  };

  const handlePageChange = (page) => {
    setPageNo(parseInt(page) * parseInt(limit));
    setRefreshTableData(true);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLimit(newPerPage);
    setRefreshTableData(true);
  };

  const handleArchivedTablePageChange = (page) => {
    setArchivedContactTablePageNo(page);
    setRefreshArchivedTableData(true);
  };

  const handleArchivedTablePerRowsChange = async (newPerPage, page) => {
    setArchivedContactTableLimit(newPerPage);
    setRefreshArchivedTableData(true);
  };

  const onDelete = () => {
    (async () => {
      try {
        const response = await deleteContact({
          SubsriberData: selectedContacts || [],
        });

        if (_get(response, "data.statusCode", 500) === 200) {
          setRefreshTableData(true);
          setSelectedContacts([]);
          setShowDeleteContactSection({ flag: false, type: "" });
          setShowDeleteDropDown(false);
        }
      } catch (err) {}
    })();
  };

  const onDeleteArchived = () => {
    (async () => {
      try {
        const response = await deleteContact({
          SubsriberData: selectedArchivedContacts || [],
        });
        console.log("response delete: ", response);
        if (_get(response, "data.statusCode", 500) === 200) {
          setRefreshArchivedTableData(true);
          setSelectedArchivedContacts([]);
          setShowDeleteContactSection({ flag: false, type: "" });
          setShowDeleteDropDown(false);
        }
      } catch (err) {}
    })();
  };

  const onDeletePermanent = () => {
    (async () => {
      try {
        const response = await deleteContact({
          SubsriberData: [
            {
              ActionType: 3,
              SubscriberId: _get(
                selectedArchivedContacts,
                "[0].SubscriberId",
                0
              ),
              subscriberListId: _get(
                selectedArchivedContacts,
                "[0].subscriberListId",
                ""
              ),
            },
          ],
        });

        if (_get(response, "data.statusCode", 500) === 200) {
          setRefreshArchivedTableData(true);
          setSelectedArchivedContacts([]);
          setShowDeleteContactSection({ flag: false, type: "" });
          setShowDeleteDropDown(false);
        }
      } catch (err) {}
    })();
  };

  // To Toggle Show Bulk Delete Contact Menu
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowDeleteDropDown(false);
      }
    }
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [wrapperRef]);

  const handleFilterChange = (
    isChecked,
    selectedField,
    fieldType,
    fieldKey
  ) => {
    let tmpFiltersData = _cloneDeep(filtersData);
    let tmpSelectedFilters = _cloneDeep(selectedDragFilterItems);
    let tmpRemainingDragFilterItems = _cloneDeep(remainingDragFilterItems);
    console.log(
      "Default tmpFiltersData: ",
      tmpFiltersData,
      fieldKey,
      fieldType
    );

    if (isChecked === true) {
      tmpFiltersData[fieldType]["Array"][fieldKey]["Ismapped"] = true;

      tmpSelectedFilters.push({ ...selectedField, Ismapped: true }); // Add Selected Field to Draggable Filter Items

      // If checked Remove from Remaining Filter Array
      tmpRemainingDragFilterItems = (tmpRemainingDragFilterItems || []).filter(
        (item) =>
          _get(item, "CustomFieldUID", "") !==
          _get(selectedField, "CustomFieldUID", "")
      );

      //console.log("Checked, tmpRemainingDragFilterItems: ", tmpRemainingDragFilterItems);
    } else {
      tmpFiltersData[fieldType]["Array"][fieldKey]["Ismapped"] = false;

      const isExist = (tmpSelectedFilters || []).some(
        (item) =>
          _get(selectedField, "CustomFieldUID", "") ===
          _get(item, "CustomFieldUID", "")
      );

      if (isExist) {
        // Remove Selected Field from Draggable Filter Items
        tmpSelectedFilters = tmpSelectedFilters.filter(
          (item) =>
            _get(selectedField, "CustomFieldUID", "") !==
            _get(item, "CustomFieldUID", "")
        );
      }

      tmpRemainingDragFilterItems.push({ ...selectedField, Ismapped: false }); // If Unchecked add to Remaining Filter Array
      //console.log("Unhecked, tmpRemainingDragFilterItems: ", tmpRemainingDragFilterItems);
    }
    console.log("isChecked, field: ", isChecked, tmpSelectedFilters);
    console.log("tmpFiltersData: ", tmpFiltersData, fieldKey, fieldType);
    setFiltersData(tmpFiltersData);
    setSelectedDragFilterItems(tmpSelectedFilters);
    setRemainingDragFilterItems(tmpRemainingDragFilterItems);
    setFinalDraggedArray([
      ...tmpSelectedFilters,
      ...tmpRemainingDragFilterItems,
    ]);
  };

  const renderDrawer = () => {
    return (
      <Drawer
        open={isOpenFilter}
        onClose={() => toggleDrawer()}
        direction="right"
        zIndex="9999"
        className="modal-filter-drawer"
      >
        <div className="topn-header">
          <span className="selected-title">Filter</span>
          <span
            style={{ padding: "20px", float: "right" }}
            onClick={() => toggleDrawer()}
          >
            <i className="fa fal fa-times clsbtnNPS fright pointer"></i>
          </span>
        </div>
        <div className="modal-filter-scrollbar">
          <div className="p-4 b_top b_bottom white">
            <h3 className="font-16 fw-medium">
              Select the subscriber details that you want to display for the
              list Test Contact
            </h3>
          </div>
          <div className="container px-0">
            <div className="row gx-0">
              <div className="col-6 b_right">
                <div className="modal-filterLeft-scrollbar">
                  <div className="p-4 b_bottom">
                    <div className="flex mbsc-align-mbsc-align-items-start ">
                      <label className="_l_able_cls font-16 fw-medium mb-0">
                        Available subscriber details
                      </label>
                      <input
                        type="text"
                        placeholder="Search Contact"
                        className="inputformmodel width100"
                        value=""
                        onChange={(e) => {}}
                      />
                    </div>
                  </div>

                  <div className="p-4">
                    {(filtersData || []).map((row, key) => {
                      return (
                        <Fragment>
                          <h3 key={key} className="font-16 fw-medium mb-4">
                            {_get(row, "Name", "")}
                          </h3>
                          {_get(row, "Array", []).map((field, k) => {
                            return (
                              <div key={k} className="mb-2">
                                <label className="_l_able_cls font-16 inline-block font-Nunito">
                                  <input
                                    type="checkbox"
                                    className="inline-block"
                                    disabled={_get(field, "IsReadOnly", "")}
                                    checked={_get(field, "Ismapped", false)}
                                    onChange={(e) =>
                                      handleFilterChange(
                                        _get(e, "currentTarget.checked", false),
                                        field,
                                        key,
                                        k
                                      )
                                    }
                                  />
                                  &nbsp; {_get(field, "CustomFieldName", "")}
                                </label>
                              </div>
                            );
                          })}
                        </Fragment>
                      );
                    })}
                  </div>
                </div>

                <div className="mt-4 text-right px-4">
                  <button
                    className="btn btn-primary"
                    onClick={() => saveFilters()}
                  >
                    Save
                  </button>
                </div>
              </div>

              <div className="col-6">
                <div className="p-4 b_top b_bottom white">
                  <h3 className="font-16 fw-medium">
                    Subscriber details to be displayed
                  </h3>
                </div>

                <div className="p-4 py-6 white">
                  <Filters
                    selectedDragFilterItems={selectedDragFilterItems || []}
                    remainingDragFilterItems={remainingDragFilterItems || []}
                    setParentState={(data) => setFinalDraggedArray(data)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    );
  };

  const deleteContactSection = () => {
    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">
              {_get(showDeleteContactSection, "type", "") === "permanent_delete"
                ? "Permanent delete"
                : "Move to active"}
            </h3>
          </div>
          <div className="bx-pad">
            <div className="alert alert-warning margin-bottom24">
              <p className="mb-3">
                {_get(showDeleteContactSection, "type", "") ===
                  "permanent_delete" && (
                  <strong>
                    Are you sure you want to permenant delete the selected
                    contacts?
                  </strong>
                )}

                {_get(showDeleteContactSection, "type", "") ===
                  "move_active" && (
                  <strong>
                    Are you sure you want to unarchive the selected contacts?
                  </strong>
                )}
              </p>
              <p>
                In case the contact(s) you wish to unarchive already exist in
                the CRM list, such contact(s) will be merged with the existing
                contacts.
              </p>
            </div>

            <div className="formmodel-bx text-right nomargin">
              <button
                type="button"
                className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg"
                onClick={() => {
                  setShowDeleteDropDown(false);
                  setShowDeleteContactSection({ flag: false, type: "" });
                }}
              >
                Cancel
              </button>

              {_get(showDeleteContactSection, "type", "") ===
                "permanent_delete" && (
                <button
                  type="button"
                  className="btn btn-danger btn-gape-15"
                  disabled=""
                  onClick={() => onDeletePermanent(true)}
                >
                  Confirm
                </button>
              )}

              {_get(showDeleteContactSection, "type", "") === "move_active" && (
                <button
                  type="button"
                  className="btn btn-danger btn-gape-15"
                  disabled=""
                  onClick={() => onDeleteArchived(true)}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div ref={wrapperRef}></div>
      <div className="dashbd_sec margin-top100">
        <div className="breadcrum">
          <BreadCrum
            ToText="CRM List"
            To="/crm/people/listings"
            ToSecondPageText=""
            CurrentPage={_get(
              location,
              "state.selecteListName",
              "All Contacts "
            )}
          />
        </div>

        <div className="mycontent">
          <ul className="tabing">
            <li>
              <span
                className={(activeTab || "") === "all" ? "active" : ""}
                onClick={() => {
                  setActiveTab("all");
                  setRefreshTableData(true);
                }}
              >
                All
              </span>
            </li>
            <li>
              <span
                className={(activeTab || "") === "archived" ? "active" : ""}
                onClick={() => {
                  setRefreshArchivedTableData(true);
                  setActiveTab("archived");
                }}
              >
                Archived
              </span>
            </li>
          </ul>

          <Link
            to=""
            className="btn btn-light fright npn_filter"
            onClick={() => toggleDrawer()}
          >
            Filters
          </Link>
          <Link
            className="btn btn-light fright npn_filter"
            to={`/crm/people/subscribers/${listID}/${creationSource}/segment/list`}
            state={{
              selecteListName: _get(
                location,
                "state.selecteListName",
                "All Contacts "
              ),
              selectedListId: listID || "",
            }}
          >
            Segments
          </Link>

          <Link
            className="btn btn-light fright npn_filter"
            to={`/crm/people/subscribers/${listID}/${creationSource}/customfieldsettings`}
            state={{
              selecteListName: _get(
                location,
                "state.selecteListName",
                "All Contacts "
              ),
            }}
            //onClick={() => navigate(`/crm/people/subscribers/${listID}/${creationSource}/customfieldsettings`)}
          >
            Custom Fields
          </Link>
        </div>

        {_get(showDeleteContactSection, "flag", false) === true &&
          deleteContactSection()}

        {_get(showDeleteContactSection, "flag", false) === false && (
          <div className="sms_Sec_1 bx-boder-common width100 white">
            {(activeTab || "") === "all" && (
              <>
                <div className="_h_header">
                  <h3>All Contacts</h3>
                  <span className="flex-right">
                    <input
                      type="text"
                      placeholder="Search Contact"
                      className="inputformmodel"
                      defaultValue={searchName || ""}
                      onChange={(e) => handleChange(e)}
                    />

                    <Link
                      to={`/crm/people/subscribers/${listID}/${creationSource}/create`}
                      className="btn btn-primary fright npn_filter"
                    >
                      Import
                    </Link>
                    <Link
                      to={`/crm/people/subscribers/${listID}/${creationSource}/createmanual`}
                      state={{
                        selecteListName: _get(
                          location,
                          "state.selecteListName",
                          "All Contacts "
                        ),
                      }}
                      className="btn btn-light fright npn_filter"
                    >
                      Create Manually
                    </Link>
                  </span>

                  {(selectedContacts || []).length > 0 && (
                    <Fragment>
                      <div className="drop-menu-allcontact">
                        <i
                          className="fas fa-ellipsis-h ms-2 cursor-pointer"
                          onClick={(e) => {
                            e.stopPropagation();

                            setShowDeleteDropDown(!showDeleteDropDown);
                          }}
                        ></i>

                        {(showDeleteDropDown || false) === true && (
                          <ul
                            className="drop_menu_toppanel drop_menu_delete"
                            ref={wrapperRef}
                          >
                            <li>
                              <Link
                                className="paddingleft20"
                                onClick={() => onDelete(true)}
                              >
                                Archive the selected contacts
                              </Link>
                            </li>
                          </ul>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="aggreategrid">
                  {(showContactsTableLoader || false) === true && (
                    <TableRowsLoading />
                  )}
                  {(tableContacts || []).length < 1 &&
                    (showContactsTableLoader || false) === false && (
                      <div className="p-4">
                        {_isEmpty(searchName || "") && (
                          <div className="alert alert-warning margin-bottom24">
                            You have not imported any contacts to this list.
                            <br />
                            <br />
                            <p>
                              To import contacts click on the green button
                              "Import", or &nbsp;
                              <Link
                                to={`/crm/people/subscribers/${listID}/${creationSource}/create`}
                                className="inline-block"
                              >
                                click here.
                              </Link>
                            </p>
                          </div>
                        )}

                        {!_isEmpty(searchName || "") && (
                          <div className="alert alert-warning margin-bottom24">
                            We can’t find any contacts to match your search.
                            <br />
                            <br />
                            <p>
                              Check your spelling or try a different search.
                            </p>
                          </div>
                        )}

                        <div className="dataTable-primary dataTable-primary-450">
                          <div className="rdt_TableBody"></div>

                          <StaticPaginationDesign isShowItemsPerPage={true} />
                        </div>
                      </div>
                    )}
                  {(tableContacts || []).length > 0 &&
                    (showContactsTableLoader || false) === false && (
                      <div className="dataTable-primary dataTable-primary-510 dataTable-contacts">
                        <DataTable
                          columns={contactsTableColumns}
                          data={tableContacts}
                          //progressPending={loading}
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: "Items per page:",
                          }}
                          paginationServer
                          paginationTotalRows={20}
                          paginationRowsPerPageOptions={[20, 100, 500, 1000]}
                          onChangeRowsPerPage={(newPerPage, page) =>
                            handlePerRowsChange(newPerPage, page)
                          }
                          onChangePage={(e) => handlePageChange(e)}
                          selectableRows
                          onSelectedRowsChange={(e) => {
                            if (_get(e, "selectedCount", 0) === 0) {
                              setSelectedContacts([]);
                            } else {
                              let selectedIds = [];
                              _get(e, "selectedRows", []).forEach((element) => {
                                selectedIds.push({
                                  subscriberListId: listID,
                                  SubscriberId: _get(
                                    element,
                                    "People_SubscriberId",
                                    ""
                                  ),
                                  ActionType: 0,
                                });
                              });
                              if (selectedIds.length > 0) {
                                console.log(
                                  "LAST: ",
                                  selectedIds[0],
                                  selectedIds
                                );
                                setLatestSelectedContact(selectedIds[0]);
                              }
                              setSelectedContacts(selectedIds);
                            }
                          }}
                          conditionalRowStyles={[
                            // Apply custom styling to selected rows
                            {
                              when: (row) =>
                                (selectedContacts || []).some(
                                  (selectedRow) =>
                                    selectedRow.SubscriberId ===
                                    row.People_SubscriberId
                                ),
                              style: {
                                backgroundColor: "#b7e4ff !important", // Add your custom styling for selected rows here
                              },
                              classNames: ["custom-selected-row-class-check"],
                            },
                            {
                              when: (row) =>
                                row.People_SubscriberId ===
                                _get(latestSelectedContact, "SubscriberId", ""),
                              classNames: ["custom-selected-row-class"],
                            },
                          ]}
                        />
                      </div>
                    )}
                </div>
              </>
            )}

            {(activeTab || "") === "archived" && (
              <Fragment>
                <>
                  <div className="_h_header">
                    <h3>Archived</h3>
                    <span className="flex-right">
                      {(selectedArchivedContacts || []).length > 0 && (
                        <Fragment>
                          <div className="drop-menu-allcontact">
                            <i
                              className="fas fa-ellipsis-h ms-2 cursor-pointer"
                              onClick={(e) => {
                                e.stopPropagation();

                                setShowDeleteDropDown(!showDeleteDropDown);
                              }}
                            ></i>

                            {(showDeleteDropDown || false) === true && (
                              <ul
                                className="drop_menu_toppanel drop_menu_delete"
                                ref={wrapperRef}
                              >
                                <li>
                                  <Link
                                    className="paddingleft20"
                                    onClick={() => {
                                      setShowDeleteDropDown(false);
                                      setShowDeleteContactSection({
                                        flag: true,
                                        type: "permanent_delete",
                                      });
                                    }}
                                  >
                                    Permanent delete
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    className="paddingleft20"
                                    onClick={() => {
                                      setShowDeleteDropDown(false);
                                      setShowDeleteContactSection({
                                        flag: true,
                                        type: "move_active",
                                      });
                                    }}
                                  >
                                    Move to active
                                  </Link>
                                </li>
                              </ul>
                            )}
                          </div>
                        </Fragment>
                      )}
                    </span>
                  </div>
                  <div className="aggreategrid">
                    {(archivedTableData || []).length < 1 && (
                      <div className="p-4">
                        <div className="alert alert-warning margin-bottom24">
                          You have not Archived any contacts yet.
                          <br />
                          <br />
                          <p>
                            Once you have Archived a contact you will find it
                            here. Deleting an Archived Contact is irreversible.
                          </p>
                        </div>

                        <div className="dataTable-primary">
                          <StaticPaginationDesign isShowItemsPerPage={true} />
                        </div>
                      </div>
                    )}

                    {(archivedTableData || []).length > 0 && (
                      <div className="dataTable-primary dataTable-primary-510">
                        <DataTable
                          columns={archivedContactTableColumns}
                          data={archivedTableData}
                          //progressPending={loading}
                          pagination
                          paginationComponentOptions={{
                            rowsPerPageText: "Items per page:",
                          }}
                          paginationServer
                          paginationTotalRows={totalRows}
                          onChangeRowsPerPage={(newPerPage, page) =>
                            handleArchivedTablePerRowsChange(newPerPage, page)
                          }
                          onChangePage={(e) => handleArchivedTablePageChange(e)}
                          selectableRows
                          onSelectedRowsChange={(e) => {
                            if (_get(e, "selectedCount", 0) === 0) {
                              setSelectedArchivedContacts([]);
                            } else {
                              let selectedIds = [];
                              _get(e, "selectedRows", []).forEach((element) => {
                                selectedIds.push({
                                  subscriberListId: listID,
                                  SubscriberId: _get(
                                    element,
                                    "People_SubscriberId",
                                    ""
                                  ),
                                  ActionType: 1,
                                });
                              });
                              setSelectedArchivedContacts(selectedIds);
                            }
                          }}
                        />
                      </div>
                    )}
                  </div>
                </>
              </Fragment>
            )}
          </div>
        )}
      </div>

      {renderDrawer()}
    </>
  );
};
export default ContactsList;
