import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import BlankData from "../../BlankData";
import DeleteListTemplate from "../../ReuseTemplate";
import Client from "../../GlobalAPICall";
import Pagination from "../../ReuseTemplate/Pagination";

const Domain = () => {
    const { APITOKEN } = useSelector(state => state);
    const [domainData, setDomain] = useState({ data: [], totalNumber: 0 });
    const [deleteTemplate, setDeleteTemplate] = useState(false);
    const [domainPaginationData, setDomainPagination] = useState(0);
    const [buttonShow, setButtonShow] = useState({ nextPage: false, previous: true });
    const [deleteId, setDeleteId] = useState("");
    const navigate = useNavigate();
    const setLoaded = useCallback(() => {

        APITOKEN['token'] &&
            Client.get(`Domain/0/Domains?pageno=${domainPaginationData}&pageSize=10`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(async (res) => {
                if (res['data'].length === 0) {
                    setDomain(prv => ({
                        ...prv,
                        data: []
                    }));
                } else {
                    setDomain({
                        data: res['data'],
                        totalNumber: res['data'][0].TotalDomainCount
                    });
                }
                if (res['data'].length < 10) {
                    setButtonShow({ nextPage: true })
                    if (domainPaginationData === 0) {
                        setButtonShow({ nextPage: true, previous: true })
                    }

                }
            });

    }, [APITOKEN, domainPaginationData]);
    const pagination = (f) => {
        if (f === 0) {
            setDomainPagination(count => count - 10);
            if ((domainPaginationData - 10) === 0) {
                setButtonShow({ previous: true })
            } else {
                setButtonShow({ previous: false });
            }
        } else {
            setDomainPagination(count => count + 10);
            setButtonShow({ previous: false });
        }
    }
    const deleteFn = (o) => {
        setDeleteTemplate(true);
        debugger
        setDeleteId(o['Id']);

    }
    const closeDeleteTemplate = () => {
        setDeleteTemplate(false)
    }
    const deleteTemplateFN = () => {
        Client.delete(`Domain/${deleteId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                setDeleteTemplate(false)
                setLoaded();
            } catch (err) {
                console.log(err);
            }
        })
    }
    useEffect(() => {
        setLoaded();
    }, [setLoaded])
    return (
        <>
            {deleteTemplate ?
                <DeleteListTemplate
                    onClick={closeDeleteTemplate}
                    deleteDownload={deleteTemplateFN}
                    title="Please confirm if want to delete else cancel to go back.                    "
                    content=""
                    colSpan="5" /> :
                <div className="bx-boder-common width100  margin-bottom white">
                    <div className="_h_header"><h3>Domains</h3><span className="flex-right"><button type="button" className="grid_btn_1 grid_btn_active" onClick={()=> navigate('/configDomain')}>Add Domain</button></span></div>
                    <table className="smstable" cellPadding="0" cellSpacing="0">
                        {domainData.data.length === 0 ? '' : <thead>
                            <tr>
                                <th className="table_cell_header">Domain</th>
                                <th className="table_cell_header">Status</th>
                                <th className="table_cell_header">Description</th>
                                <th className="table_cell_header">Setup</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        }
                        <tbody>
                            {domainData.data.length === 0 ?
                                <BlankData
                                    title="You do not have any Domain connected yet."
                                    content="If you have a Domain you already own, you can connect it here to give your Landing Pages and Website a more professional look."
                                    colSpan="6"
                                /> :
                                <Grid
                                    Data={domainData}
                                    onClick={deleteFn}
                                />}
                        </tbody>
                    </table>
                    {domainData.length > 10 ? <div className="padding20">
                        <Pagination
                            pageCount={domainPaginationData}
                            totalCount={domainData}
                            buttonAction={buttonShow}
                            onClick={pagination}
                        /></div> : ''}
                </div>}</>
    )
}
export default Domain

const Grid = ({ Data, onClick }) => {
    return (
        <>
            {Data.data &&
                Data.data.map((v, i) => <tr key={i}>
                    <td className="body_table_cell">{v['Domain']}</td>
                    <td className="body_table_cell">
                        <strong style={{ color: (v['Isverified']) ? '#3bac69' : '#afafaf' }}>{(v['Isverified']) ? 'Verified' : 'Pending'}</strong></td>
                    <td className="body_table_cell">{v['Description']}</td>
                    <td className="body_table_cell">{v['SetUp']}</td>
                    <td>
                        <Link to={`/domainAuthenticate/${v.Id}`} className="link inline-block"><strong>Open</strong></Link>&nbsp; &nbsp;
                        <button type="button" onClick={() => onClick(v)} className="link inline-block __deletelink"><strong>Delete</strong></button>
                    </td>
                </tr>)}</>
    )
}