
import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import axios from "axios";
const Promoters = ({ ...props }) => {
    const { PostObject } = props;
    const { APITOKEN } = useSelector(state => state);
    const [promotionData, setpromotionData] = useState([]);

    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();

        try {
            APITOKEN['token'] &&
                Client.post(`Insights/NPSdashboard/CategoryWiseResponse`, PostObject, {
                    cancelToken: cancelToken.token,
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            setpromotionData(res.data);
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        if (axios.isCancel(err)) {
                            console.error(err);
                        }
                    });
                    return () => {
                        cancelToken.cancel();
                    }
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN, PostObject]);
    useEffect(() => {
        load();
    }, [load])
    return (
        
                <div className="padding20 margin-top50">
                    {promotionData.map((v,i)=> <div key={i} className="npsreport_graph "><div className="font-16">{v['categoryName']}</div><div className="font-16">{v['total']} ({v['per']}%)</div><div className="bar__graph"><div className="print__graph_percentage" style={{
                        width: `${v['per']}%`,
                        background:`${v['colorCode']}`
                    }}></div></div></div>)}
                </div>
     
    )

}

export default Promoters
