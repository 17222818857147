import { useEffect, useState, Fragment } from "react";
import { get as _get, isEmpty as _isEmpty, cloneDeep as _cloneDeep } from "lodash";
import DataTable from 'react-data-table-component';
import { PickerOverlay } from 'filestack-react';

import TableRowsLoading from "../../../ReusableComponents/TableRowsLoading";

import { getLoyaltyProgramsList, saveLoyaltyProgram, updateLoyaltyProgram, deleteLoyaltyProgram } from "../../../../services/crm";

const FILE_PICKER_API_KEY = process.env.REACT_APP_FILE_PICKER_API_KEY;

const AllLoyaltyProgram = () => {

  const loayaltyProgramColumns = [
    {
      name: "",
      selector: (item) => {
        return (
          (_get(item, "badgeImagePath", "") !== "") ? <img src={`${_get(item, "badgeImagePath", "")}`} alt={`${_get(item, "programLevel", "")}`} width="56" /> : ""
        )
      }
    },
    { name: "Program Level", selector: (item) => _get(item, "programLevel", "") },
    { name: "Start Points", selector: (item) => _get(item, "startPoints", 0) },
    { name: "End Points", selector: (item) => _get(item, "endPoints", 0) },
    { name: "Create date and time", selector: (item) => _get(item, "creationTime", "") },
    {
      name: "Actions",
      selector: (item) => (
        <>
          <span className="text-07f margin-left4 cursor-pointer"
            onClick={() => {

              setShowCreateProgramSection(true);
              setEditProgramId(_get(item, "rowId", null));
              setFormData({
                programLevel: _get(item, "programLevel", ""),
                startPoints: _get(item, "startPoints", 0),
                endPoints: _get(item, "endPoints", 0),
                badgeImagePath: _get(item, "badgeImagePath", "")
              });
            }}
          >
            Edit
          </span>
          <span className="text-07f margin-left4 cursor-pointer"
            onClick={() => {

              setShowDeleteProgramSection(true);
              setShowCreateProgramSection(false);
              setDeleteProgramId(_get(item, "rowId", null));
            }}
          >
            Delete
          </span>
        </>
      )
    },
  ];

  const defaultFormData = { programLevel: "", startPoints: 0, endPoints: 0, badgeImagePath: "" };
  const [formData, setFormData] = useState(defaultFormData);
  const [showCreateSection, setShowCreateProgramSection] = useState(false);
  const [showDeleteSection, setShowDeleteProgramSection] = useState(false);
  const [loayaltyProgramTableData, setLoayaltyProgramTableData] = useState([]);
  const [showFileUploadSection, setShowFileUploadSection] = useState(false);
  const [editProgramId, setEditProgramId] = useState(null);
  const [deleteProgramId, setDeleteProgramId] = useState(null);
  const [refreshTableData, setRefreshTableData] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {

    if (refreshTableData === true) {
      fetchLoyalotyPrograms();
    }
  }, [refreshTableData]);

  const fetchLoyalotyPrograms = () => {
    (async () => {
      try {
        const response = await getLoyaltyProgramsList();

        setLoayaltyProgramTableData(_get(response, "data", []));

        setRefreshTableData(false);
      } catch (err) {
        console.log("Error: ", err)
      }
    })();
  }

  const onSubmitForm = () => {
    (async () => {
      try {
        const response = await saveLoyaltyProgram(formData);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setShowCreateProgramSection(false);
          setFormData(_cloneDeep(defaultFormData));
          setErrorMessage(null);
        } else {
          setErrorMessage(_get(response, "data.message", false));
        }
      } catch (err) {
      }
    })();
  }

  const onEditForm = () => {
    (async () => {
      try {

        const response = await updateLoyaltyProgram(editProgramId, formData);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setEditProgramId(null)
          setErrorMessage(null);
          setShowCreateProgramSection(false);
        } else {
          setErrorMessage(_get(response, "data.message", false));
        }
      } catch (err) {
      }
    })();
  }

  const onDelete = () => {
    (async () => {
      try {

        const response = await deleteLoyaltyProgram(deleteProgramId);

        if (_get(response, "data.success", false) === true) {

          setRefreshTableData(true);
          setDeleteProgramId(null)
          setShowDeleteProgramSection(false);
        } else {
          setErrorMessage(_get(response, "data.message", false));
        }
      } catch (err) {
      }
    })();
  }

  const onCancelDelete = () => {
    setShowDeleteProgramSection(false);
    setDeleteProgramId(null);
  }

  const fileUploadSection = () => {

    return (
      <PickerOverlay
        apikey={FILE_PICKER_API_KEY}
        pickerOptions={{
          fromSources: ["local_file_system"],
          accept: ["image/*"],
          onClose: () => { setShowFileUploadSection(false); }
        }}
        onUploadDone={(res) => {
          const imgURL = (!_isEmpty(_get(res, "filesUploaded", []))) ? _get(res, "filesUploaded[0].url", []) : "";

          setFormData({ ...formData, badgeImagePath: imgURL });
          setShowFileUploadSection(false);
        }}
      />
    )
  }

  const createProgram = () => {

    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Create level</h3>
          </div>
          <div className="bx-pad">
            <div className="container">
              <div className="row">
                <div className="col-3">
                  <div className="margin-bottom24">
                    <label className="_l_able_cls">Program Level Name</label>
                    <input type="text" className="inputformmodel" value={_get(formData, "programLevel", "")} onChange={(e) => setFormData({ ...formData, programLevel: _get(e, "target.value", "") })} />
                  </div>
                </div>

                <div className="col-12">
                  <div className="inputIncrese-wrap">
                    <div>
                      <label className="_l_able_cls">Start Points</label>
                      <div className="inputIncrese-btn">
                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => setFormData({ ...formData, startPoints: (parseInt(_get(formData, "startPoints", 0)) - 1) })}
                        >-</button>

                        <input type="number" className="inputformmodel" value={_get(formData, "startPoints", 0)} onChange={(e) => setFormData({ ...formData, startPoints: _get(e, "target.value", 0) })} />

                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => {
                            const currentStartPoint = parseInt(_get(formData, "startPoints", 0));
                            const currentEndPoints = parseInt(_get(formData, "endPoints", 0));

                            const nextPoint = (currentStartPoint + 1);

                            // If End Points is Less than Start point then increase end points too
                            if (currentEndPoints <= currentStartPoint) {
                              setFormData({ ...formData, startPoints: nextPoint, endPoints: nextPoint });
                            } else {
                              setFormData({ ...formData, startPoints: nextPoint });
                            }
                          }}
                        >+</button>
                      </div>
                    </div>

                    <div>
                      <label className="_l_able_cls">End Points</label>
                      <div className="inputIncrese-btn">
                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => {
                            const currentStartPoint = parseInt(_get(formData, "startPoints", 0));
                            const currentEndPoints = parseInt(_get(formData, "endPoints", 0));

                            const nextPoint = (currentEndPoints - 1);

                            // If End Points is Greater than Start point then only increase end points too
                            if (currentEndPoints > currentStartPoint) {
                              setFormData({ ...formData, endPoints: nextPoint });
                            }
                          }}
                        >-</button>
                        <input type="number" className="inputformmodel" value={_get(formData, "endPoints", 0)} onChange={(e) => setFormData({ ...formData, endPoints: _get(e, "target.value", "") })} />
                        <button
                          className="inputBtn"
                          type="button"
                          onClick={(e) => setFormData({ ...formData, endPoints: (parseInt(_get(formData, "endPoints", 0)) + 1) })}
                        >+</button>
                      </div>
                    </div>

                    <div className="fileUpload-modal" onClick={() => setShowFileUploadSection(true)}>
                      {(_get(formData, "badgeImagePath", "") === "") && (
                        <Fragment>
                          <label className="_l_able_cls">File Upload</label>
                        </Fragment>
                      )}

                      {(_get(formData, "badgeImagePath", "") !== "") && (
                        <Fragment>
                          <label className="_l_able_cls _l_able_cls-hover">File Upload</label>
                          <img src={_get(formData, "badgeImagePath", "")} className="img-responsive" alt="" />
                        </Fragment>
                      )}
                    </div>

                    <div className="mt-6 ms-4">
                      {/* <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancel()} >Cancel</button> */}
                      {((editProgramId || null) === null) && (
                        <button
                          type="button"
                          className="btn btn-primary btn-gape-15"
                          disabled={((_get(formData, "programLevel", "") === "") || (parseInt(_get(formData, "startPoints", 0)) === 0)) ? true : false}
                          onClick={() => onSubmitForm()}>
                          Save
                        </button>
                      )}

                      {((editProgramId || null) !== null) && (
                        <button
                          type="button"
                          className="btn btn-primary btn-gape-15"
                          onClick={() => onEditForm()}
                          disabled={((_get(formData, "programLevel", "") === "")) ? true : false}
                        >
                          Update
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12">
                  <p className="error-message">{errorMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    )
  }

  const deleteProgram = () => {

    return (
      <div className="sms_Sec_1 width100 white margin-bottom50">
        <div className="bx-boder-common common_Sec_1">
          <div className="_h_header">
            <h3 className="_t_i">Delete</h3>
          </div>
          <div className="bx-pad">
            <div className="alert alert-warning margin-bottom24">
              Do you want to delete Program?
            </div>

            <div className="formmodel-bx text-right nomargin">
              <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => onCancelDelete()} >Cancel</button>

              <button
                type="button"
                className="btn btn-danger btn-gape-15"
                disabled=""
                onClick={() => onDelete()}>
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>

      {((showCreateSection || false) === true) && (
        createProgram()
      )}

      {((showDeleteSection || false) === true) && (
        deleteProgram()
      )}

      {((showFileUploadSection || false) === true) && (
        fileUploadSection()
      )}

      {((showDeleteSection || false) === false) && (
        <div className="sms_Sec_1 bx-boder-common width100 nobg">
          <div className="_h_header white border-radius2">
            <h3>Loyalty Program</h3>
            <span className="flex-right">
              <button type="button" className="btn btn-light" onClick={() => setShowCreateProgramSection(true)}>Create Level</button>
            </span>
          </div>
          <div className="dataTable-primary">
            {((refreshTableData || false) === true) && (
              <TableRowsLoading />
            )}
            {((refreshTableData || false) === false) && (
              <DataTable
                columns={loayaltyProgramColumns}
                data={loayaltyProgramTableData}
              />
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default AllLoyaltyProgram;
