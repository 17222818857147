
import { useParams } from 'react-router-dom';
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import BlankData from '../../../BlankData';

export const CompleteDetails = ({ ...props }) => {
    const { ClosePopup, Value, TabId } = props;
    const { object, WidgetId } = Value;
    const [videoData, setVideoData] = useState({ data: [], flag: false });
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [data, setData] = useState([]);
    const url = `Insights/distribution/Survey/00000000-0000-0000-0000-000000000000/${TabId}/${object.Id}/Recipients?skip=0&limit=10&surveyId=${id}`;
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(url, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        setData(res.data);
                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, url]);

    const videoDetails = (value) => {
        try {
            Client.get(`Insights/distribution/${value.MobileNumber}/${id}/${WidgetId}/VideoStatus`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setVideoData({ data: res.data, flag: true });
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }
    const pageViews = (value) => {
        try {
            Client.get(`Insights/distribution/Survey/${id}/00000000-0000-0000-0000-000000000000/${value.receipentId}/PageViews`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setVideoData({ data: res.data, flag: true, pageViews: true });
                } catch (err) {
                    console.log(err);
                }
            });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])


    return (
        <div className="maskingComplete">
            <div className="detail-popp">
                <div className="topn-header">
                    <span className="selected-title">{object.Name}</span>
                    <span style={{ padding: '0 20px', float: 'right' }}
                        onClick={() => ClosePopup(prv => ({ ...prv, flag: false }))}><i className="fa fal fa-times  pointer p-clsbtn "></i></span>
                </div>
                <div className={`_h_header ${videoData.flag ? 'noborder' : ''}`}>&nbsp;</div>
                {videoData.flag ? <div className="table_dealis_wiget scrollable">
                    <button type="button" className="cls_secondbx link" onClick={() => setVideoData(prv => ({ ...prv, flag: false }))}><i className="fas fa-arrow-left"></i></button>
                    <table
                        width="100%"
                        cellPadding="0"
                        cellSpacing="0"
                        className="smstable table-layout-default table_dealis_wigettable">
                        <thead>
                            {videoData.pageViews !== undefined ? <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Clicks</th>
                            </tr> :
                                <tr>
                                    <th className="table_cell_header">Event Action</th>
                                    <th className="table_cell_header">Event Time Duration</th>
                                    <th className="table_cell_header">Full Duration</th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {videoData.data.map((v, i) =>
                                videoData.pageViews !== undefined ?
                                    <tr key={i}> 
                                        <td className="body_table_cell">{v.pageName}</td>
                                        <td className="body_table_cell">{v.clicks}</td>
                                    </tr>
                                    :
                                    <tr key={i}>
                                        <td className="body_table_cell">{v.EventAction}</td>
                                        <td className="body_table_cell">{v.EventTimeDuration}</td>
                                        <td className="body_table_cell">{v.FullDuration}</td>
                                    </tr>
                            )}
                        </tbody>
                    </table>
                </div> :
                    <div className="table_dealis_wiget scrollable">
                        <table cellPadding="0" cellSpacing="0" className="smstable table-layout-default table_dealis_wigettable">
                            {data.length > 0 &&
                                <thead>
                                    <tr>
                                        <th className="table_cell_header">First Name</th>
                                        <th className="table_cell_header">Last Name</th>
                                        <th className="table_cell_header" style={{ display: 'none' }}>Company</th>
                                        <th className="table_cell_header">Email</th>
                                        <th className="table_cell_header">Mobile Number</th>
                                        <th className="table_cell_header">Zip Code</th>
                                        <th className="table_cell_header">{object.Name}</th>
                                        <th className="table_cell_header" >{object.Id === 227 ? 'KPI Status' : 'Status'}</th>
                                        <th className="table_cell_header" style={{ display: object.Name === "Plays" || object.Name === 'Unique Plays' ? 'table-cell' : 'none' }}>Video Status</th>
                                    </tr>
                                </thead>
                            }
                            <tbody>
                                {data.length > 0 ? data.map((v, i) =>
                                    <tr key={i}>
                                        <td className="body_table_cell">{v['FirstName']}</td>
                                        <td className="body_table_cell">{v['LastName']}</td>
                                        <td className="body_table_cell" style={{ display: 'none' }}>{v['Company']}</td>
                                        <td className="body_table_cell">{v['Email']}</td>
                                        <td className="body_table_cell">{v['MobileNumber']}</td>
                                        <td className="body_table_cell">{v['ZipCode']}</td>
                                        <td className="body_table_cell">{(object.Name === 'Plays' || object.Name === 'Unique Plays') ? v['Plays'] : v['Clicks']}</td>
                                        <td className="body_table_cell">{(object.Id === 227) ? <button type="button" onClick={() => pageViews(v)} className="kpi_status_cls link"  >More details</button> : v['Status']}</td>
                                        <td className="body_table_cell" style={{ display: (object.Name === 'Plays' || object.Name === 'Unique Plays') ? 'table-cell' : 'none' }}>
                                            <button type="button" onClick={() => videoDetails(v)} className="videoLink link" style={{ display: (v['VideoStatus'] !== 'Not Completed') ? 'none' : 'table-cell' }}>More Details</button>
                                            <span style={{ display: (v['VideoStatus'] === 'Not Completed') ? 'none' : 'table-cell' }}>{v['VideoStatus']}</span></td>
                                    </tr>
                                ) : <BlankData title="No data registered." content="There is no data registered for this KPI." colSpan="8" />}
                            </tbody>
                        </table>
                    </div>
                }
            </div>
        </div>
    )

}

export default CompleteDetails
