
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useState } from 'react';

const MobileChart = (props) => {
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d62', '#fecc44', '#673ab7', '#60c9f7', "#2c53f7", "#ffa701"], borderWidth: 0 });
    const [mobileData, setMobile] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            }
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },

        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b><br/>'
        },

        series: [
            {
                name: "Mobile Device",
                colorByPoint: true,
                pointWidth: 62,
                data: []
            }
        ]
    });
    const loadApiCall = useCallback(async () => {
        const newArray = []
        await props['Data'].length > 0 &&
        props['Data'].forEach(async (v, i) => {
            v.kpi.forEach(async x => {
                if (x['id'] === 225) {
                    await newArray.push({
                        name: v.name,
                        y: x['count'],
                        drilldown: v.name
                    });
                }
            });
            
            setMobile(prv => ({
                ...prv,
                series: [{
                    ...prv.series,
                    data: newArray
                }]
            }))

        });
    }, [props])
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <>
           
            <div className="bx-boder-common margin-bottom">
                <div className="_insight_header white">
                    <h3>Mobile Device</h3>
                </div> {props.Data.length === 0 ? <div className="_nodata padding20 white">
                    <div id="nodatamessage">
                        <strong>Mobile Device Data no found</strong><br></br><br></br>
                    </div>
                </div>:
               <> <div className="padding20 white ">
                    {mobileData && <HighchartsReact highcharts={Highcharts} options={mobileData} />}
                </div>
                <div className=" white">
                    <div className="_overviewPin _overviewPin_Device white summary_o_v">
                        {
                           Object.keys(props['Data']).length > 0  && props['Data'].map((v, index) => {
                                return <div className="bx-boder-common bx detailsbx white" key={index}><div className="drop-zone"><h3 className="title">{v['name']}</h3><p className="content paddingbottom20">&nbsp;</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                    <tbody>
                                        {
                                            v.kpi && v.kpi.map(($v, i) =>

                                                <tr key={i}>

                                                    <td className="nopadding">
                                                        <table cellPadding="0" cellSpacing="0" width="100%" >
                                                            <tbody>
                                                                <tr>
                                                                    <td>{$v['name']} {$v['countLabel']}</td>
                                                                    <td className="-tvl"><strong className="pointer completeDetails">{$v['count']}</strong></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>{$v['name']} {$v['rateLabel']}</td>
                                                                    <td className="-tvl link"><strong style={{ color: $v['rateColor'] }}>{$v['rate']}%</strong></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>


                                            )

                                        }
                                    </tbody>
                                </table>
                                </div>
                                </div>
                            })}

                    </div>
                </div>
               </> }
                </div>
            
        </>
    )
}
export default MobileChart