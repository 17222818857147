import React, { Fragment, useState, useEffect } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-modern-drawer/dist/index.css";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import CreateNewStore from './components/CreateNewStore';
import ButtonFunctions from './components/ButtonFunctions';
import OpeningHours from './components/OpeningHours';
import StampCards from './components/StampCards';
//import LoyaltyCards from './components/LoyaltyCards';

import { createStore } from "../../../services/store";

import iPhoneHeader from '../../../assets/img/iphone-header.png';
import iPhoneFooter from '../../../assets/img/iphone-footer.png';
import iconChevRight from '../../../assets/img/icon-chev-right.svg';
import iconCall from '../../../assets/img/icon-call.svg';
import iconEmail from '../../../assets/img/icon-email.svg';
import iconDirection from '../../../assets/img/icon-direction.svg';
import iconWebsite from '../../../assets/img/icon-website.svg';

const StoreCreate = () => {

  const urlParams = new URLSearchParams(window.location.search);
  const storeID = urlParams.get('cam'); // GET StoreID from URL
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState(1);
  const [createStoreData, setCreateStoreData] = useState({});
  const [buttonFunctionsData, setButtonFunctionsData] = useState(null);
  const [openingHoursData, setOpeningHoursData] = useState(null);
  const [stampCardsData, setStampCardsData] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [mobilePreviewStoreData, setMobilePreviewStoreData] = useState({});
  const [mobilePreviewButtonFunctionsData, setMobilePreviewButtonFunctionsData] = useState({});
  const [mobilePreviewOpeningHoursData, setMobilePreviewOpeningHoursData] = useState({});
  const [mobilePreviewStampCardsData, setMobilePreviewStampCardsData] = useState({});
  //const [loyaltyCardsData, setLoyaltyCardsData] = useState(null);

  const handleMobilePreviewUpdateData = (data, linkToActive) => {
    switch (linkToActive) {
      case 2: // CreateNewStore
        setMobilePreviewStoreData(data);
        break;
      case 3: // ButtonFunctions
        setMobilePreviewButtonFunctionsData(data);
        break;
      case 4: // OpeningHours
        setMobilePreviewOpeningHoursData(data);
        break;
      case 5: // StampCards
        setMobilePreviewStampCardsData(data);
        break;
      /*case 6: // LoyaltyCards
        setLoyaltyCardsData(data);
        break;*/

      default:
        break;
    }
  };

  const handleFinalDataUpdate = (data, linkToActive) => {
    switch (linkToActive) {
      case 2: // CreateNewStore
        setCreateStoreData(data);
        break;
      case 3: // ButtonFunctions
        setButtonFunctionsData(data);
        break;
      case 4: // OpeningHours
        setOpeningHoursData(data);
        break;
      case 5: // StampCards
        setStampCardsData(data);
        break;
      /*case 6: // LoyaltyCards
        setLoyaltyCardsData(data);
        break;*/

      default:
        break;
    }
    setActiveLink(linkToActive);
  };

  const onSubmitCreateStore = () => {
    (async () => {
      try {
        setIsLoader(true);

        const mergedData = {
          "storeId": (storeID || ""),
          "image": (_get(createStoreData, "images", []).length > 0) ? _get(createStoreData, "images", []).map((v, i) => v.files) : [],
          "categoryId": parseInt(_get(createStoreData, "categoryId", 0)),
          "storeInfo": _get(createStoreData, "storeInfo", ""),
          "storeButtons": buttonFunctionsData,
          "storeOpeningHours": openingHoursData,
          "stamps": stampCardsData,
          "isStoreVisibleInApp": true,
          "rateCount": 0,
          "isPushNotificationEnabled": false,
          "pushNotificationText": "",
          "isCompleted": (activeLink > 3) ? true : false
        };

        const response = await createStore(mergedData);
        setIsLoader(false);
        if (_get(response, "flag", false) === false) {

        } else {
          if ((isCancel || false) === true) {
            navigate("/store/97");
            return false;
          }
          if ((activeLink || 1) >= 5) {
            navigate("/store/97");
          }
        }

      } catch (err) {
        console.log('Error occured when submitting data', err);
      }
    })();
  }

  useEffect(() => {
    onSubmitCreateStore();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStoreData, buttonFunctionsData, openingHoursData, stampCardsData]);

  return (
    <>
      <div className="appscreenshot-wrap">
        <div className="dashbd_sec margin-top100">
          <div className="breadcrum">

            <Fragment>
              <Link className="link inline-block" to="/store/97"> Stores </Link>

              &nbsp; <i className="fas fa-caret-right" /> &nbsp;

              {((activeLink || 1) >= 1) && (
                <span className="link inline-block" >
                  Create new store
                </span>
              )}
              {((activeLink || 1) > 1) && (
                <Fragment>
                  &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                </Fragment>
              )}

              {((activeLink || 1) >= 2) && (
                <span className="link inline-block" >
                  Button Functions
                </span>
              )}

              {((activeLink || 1) > 2) && (
                <Fragment>
                  &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                </Fragment>
              )}

              {((activeLink || 1) >= 3) && (
                <span className="link inline-block" >
                  Opening Hours
                </span>
              )}

              {((activeLink || 1) > 3) && (
                <Fragment>
                  &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                </Fragment>
              )}

              {((activeLink || 1) >= 4) && (
                <span className="link inline-block" >
                  Stamp Cards
                </span>
              )}

              {((activeLink || 1) > 4) && (
                <Fragment>
                  &nbsp; <i className="fas fa-caret-right" /> &nbsp;
                </Fragment>
              )}

              {/*((activeLink || 1) >= 5) && (
              <span
                className="link inline-block"
                onClick={() => setActiveLink(5)}
              >
                Loyalty Cards
              </span>
            )*/}

            </Fragment>
          </div>

          {((activeLink || 1) === 1) && (
            <CreateNewStore
              sendDataToParent={(data, linkToActive) => handleFinalDataUpdate(data, linkToActive)}
              setMobilePreviewData={(data, linkToActive) => handleMobilePreviewUpdateData(data, linkToActive)}
              onCancel={(data, linkToActive) => {
                handleFinalDataUpdate(data, linkToActive);
                setIsCancel(true);
              }}
            />
          )}

          {((activeLink || 1) === 2) && (
            <ButtonFunctions
              sendDataToParent={(data, linkToActive) => handleFinalDataUpdate(data, linkToActive)}
              setMobilePreviewData={(data, linkToActive) => handleMobilePreviewUpdateData(data, linkToActive)}
              onCancel={(data, linkToActive) => {
                handleFinalDataUpdate(data, linkToActive);
                setIsCancel(true);
              }}
            />
          )}

          {((activeLink || 1) === 3) && (
            <OpeningHours
              sendDataToParent={(data, linkToActive) => handleFinalDataUpdate(data, linkToActive)}
              setMobilePreviewData={(data, linkToActive) => handleMobilePreviewUpdateData(data, linkToActive)}
              onCancel={(data, linkToActive) => {
                handleFinalDataUpdate(data, linkToActive);
                setIsCancel(true);
              }}
            />
          )}

          {((activeLink || 1) >= 4) && (
            <StampCards
              isLoader={(isLoader || false)}
              sendDataToParent={(data, linkToActive) => {
                handleFinalDataUpdate(data, linkToActive);
              }}
              setMobilePreviewData={(data, linkToActive) => {
                handleMobilePreviewUpdateData(data, linkToActive)
              }}
              onCancel={(data, linkToActive) => {
                handleFinalDataUpdate(data, linkToActive);
                setIsCancel(true);
              }}
            />
          )}

          {/*((activeLink || 1) >= 5) && (
          <LoyaltyCards sendDataToParent={(data, linkToActive) => handleFinalDataUpdate(data, linkToActive)} />
        )*/}

        </div>

        <div className="right-appscreenshot-right margin-top100">
          <p className="fw-bold text-center mb-8 pt-12">Preview Store</p>
          <div className={`right-appscreenshot ${((activeLink || 1) > 3) ? "hide" : ""}`}>
            <div className="right-appscreenshot-banner">
              <div className="overflow-hidden">
                <img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
                <img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

                <p className="right-appscreenshot-banner-header-text">
                  {(mobilePreviewStoreData.storeName || "")}
                </p>

                <div className="right-appscreenshot-storeimage">
                  <Carousel showThumbs={false} swipeable={true} interval={2000} autoPlay infiniteLoop>
                    {((mobilePreviewStoreData.images || []).length > 0) && mobilePreviewStoreData.images.map((image, i) => (
                      <div key={i}>
                        <img src={_get(image, "files.imageUrl")} alt="" />
                      </div>
                    ))}
                  </Carousel>
                </div>

                <div className="right-appscreenshot-rating">
                  <div className="right-appscreenshot-rating-left">
                    {((mobilePreviewButtonFunctionsData.isRatingVisible || false) === true) && (
                      <Fragment>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) ? (_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) ? (_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) ? (_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) ? (_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
                          <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill={(_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) ? (_get(mobilePreviewButtonFunctionsData, "ratingColor", "")) : "#fea510"} viewBox="0 0 16 16">
                          <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.56.56 0 0 0-.163-.505L1.71 6.745l4.052-.576a.53.53 0 0 0 .393-.288L8 2.223l1.847 3.658a.53.53 0 0 0 .393.288l4.052.575-2.906 2.77a.56.56 0 0 0-.163.506l.694 3.957-3.686-1.894a.5.5 0 0 0-.461 0z" />
                        </svg>
                      </Fragment>
                    )}
                  </div>

                  <div className="right-appscreenshot-rating-right">
                    {((mobilePreviewOpeningHoursData.isOpeningHoursVisible || false) === true) && (
                      <p><span>Open</span> - Closing 16
                      </p>
                    )}
                  </div>
                </div>

                {((mobilePreviewOpeningHoursData.isOpeningHoursVisible || false) === true) && (
                  <div className="right-appscreenshot-time-dropdown">
                    {((mobilePreviewOpeningHoursData.mondayOpeningHours || "") !== "") && (
                      <div>
                        <span>Monday</span>
                        <span>{(mobilePreviewOpeningHoursData.mondayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.tuesdayOpeningHours || "") !== "") && (
                      <div>
                        <span>Tuseday </span>
                        <span>{(mobilePreviewOpeningHoursData.tuesdayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.wednesdayOpeningHours || "") !== "") && (
                      <div>
                        <span>Wednesday </span>
                        <span>{(mobilePreviewOpeningHoursData.wednesdayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.thursdayOpeningHours || "") !== "") && (
                      <div>
                        <span>Thursday </span>
                        <span>{(mobilePreviewOpeningHoursData.thursdayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.fridayOpeningHours || "") !== "") && (
                      <div>
                        <span>Friday </span>
                        <span>{(mobilePreviewOpeningHoursData.fridayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.saturdayOpeningHours || "") !== "") && (
                      <div>
                        <span>Saturday </span>
                        <span>{(mobilePreviewOpeningHoursData.saturdayOpeningHours || "")}</span>
                      </div>
                    )}
                    {((mobilePreviewOpeningHoursData.sundayOpeningHours || "") !== "") && (
                      <div>
                        <span>Sunday</span>
                        <span>{(mobilePreviewOpeningHoursData.sundayOpeningHours || "")}</span>
                      </div>
                    )}

                  </div>
                )}

                <div className="right-appscreenshot-icon">
                  {((mobilePreviewButtonFunctionsData.isPhoneNumberVisible || false) === true) && (
                    <span>
                      <img src={iconCall} alt="call" />
                      Call
                    </span>
                  )}
                  {((mobilePreviewButtonFunctionsData.isEmailAddressVisible || false) === true) && (
                    <span>
                      <img src={iconEmail} alt="call" />
                      email
                    </span>
                  )}
                  {((mobilePreviewButtonFunctionsData.isDirectionDisplayVisible || false) === true) && (
                    <span>
                      <img src={iconDirection} alt="call" />
                      directions
                    </span>
                  )}
                  {((mobilePreviewButtonFunctionsData.isDisplayWebSiteVisible || false) === true) && (
                    <span>
                      <img src={iconWebsite} alt="call" />
                      website
                    </span>
                  )}
                </div>

                <div className="right-appscreenshot-tabs">
                  <div className="right-appscreenshot-tabs-links">
                    <p className="active">Info</p>
                    <p>Inbox</p>
                    <p>cards</p>

                    <span className="followed">Followed</span>
                  </div>
                </div>

                <div className="right-appscreenshot-desc">
                  {((mobilePreviewStoreData.storeInfo || "") !== "") && (mobilePreviewStoreData.storeInfo || "").split('\n').map((text, index) => (
                    <Fragment key={index}>
                      {text}
                      <br />
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={`right-appscreenshot ${((activeLink || 1) < 3) ? "hide" : ""}`}>
            <div className="right-appscreenshot-banner">
              <div className="overflow-hidden">
                <img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
                <img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

                <p className="right-appscreenshot-banner-header-text">Shop4You</p>

                <div className="right-appscreenshot-banner-feature">
                  {((mobilePreviewStampCardsData || []).length > 0) && (mobilePreviewStampCardsData || []).map(card => {
                    return (
                      <div className="right-appscreenshot-banner-feature-list">
                        <div className="right-appscreenshot-banner-feature-icon">
                          {!_isEmpty(_get(card, "icon", null)) && (
                            <img src={_get(card, "icon", "")} alt="" />
                          )}
                        </div>

                        <div className="right-appscreenshot-banner-feature-text">
                          {_get(card, "cardName", "")}
                        </div>

                        <div className="right-appscreenshot-banner-feature-arrow">
                          <img src={iconChevRight} alt="Arrow" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*<div className="right-appscreenshot-right margin-top100">
          <p className="fw-bold text-center mb-8 pt-12">Preview Store</p>

          <div className="right-appscreenshot">
            <div className="right-appscreenshot-banner">
              <div className="overflow-hidden">
                <img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
                <img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

                <p className="right-appscreenshot-banner-header-text">Shop4You</p>

                <div className="right-appscreenshot-creditcard">
                  <p className="right-appscreenshot-creditcard-text">ShopyCard</p>

                  <div className="right-appscreenshot-creditcard-image">
                    <img src={creditCardLogo} alt="logo" />
                    <p>10/18/2024</p>
                    <p>2277 88 9900</p>
                  </div>

                  <ul className="right-appscreenshot-creditcard-list">
                    <li>
                      <span>Card Name</span>
                      <span>ShopyCard</span>
                    </li>

                    <li>
                      <span>Card Holder</span>
                      <span>Bjørn Forslund</span>
                    </li>

                    <li>
                      <span>Card number</span>
                      <span>2277 88 9900</span>
                    </li>

                    <li>
                      <span>Expiry Date</span>
                      <span>10/18/2024</span>
                    </li>
                  </ul>

                  <div className="text-right me-4">
                    <button type="button" className="btn btn-light">Delete Card</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
      </div >
    </>
  );
}

export default StoreCreate;