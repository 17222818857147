import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import { useNavigate } from 'react-router-dom';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';
import getParameterByName from '../../../../ReuseTemplate/GetRoute';
import GridLoader from '../../../../ReuseTemplate/GridLoader';
import BlankData from '../../../../BlankData';

const Unsubscribe = () => {
    const { APITOKEN } = useSelector(state => state);
    const [smsDetails, setSMSDetails] = useState([]);
    const [isLoader, setLoader] = useState(false);
    const [showDeletePanel, setDeletePanel] = useState(true);
    const [guidId, setListGuidId] = useState("");

    // Get All list from API Call

    const load = useCallback(() => {
        setLoader(true);
        (APITOKEN['token'] !== "") &&
            Client.get('Unsubscribe/12/optouttemplate', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    setLoader(false);
                    try {
                        setSMSDetails(data);
                    } catch (err) {
                        console.error(err);

                    }
                }).catch(err => setLoader(false));
    }, [APITOKEN])


    // List Item Delete API Call //
    const deleteList = () => {
        Client.delete(`Unsubscribe/12/${guidId['campaignId']}/optouttemplate`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            load();
            setDeletePanel(!showDeletePanel);
        });
    }

    const showDeletePanelFN = item => {
        setDeletePanel(!showDeletePanel);
        setListGuidId(item);
    }
    const navigate = useNavigate();

    const duplicateClick = item => {
        Client.post(`Campaign/duplicate?id=${item.id}`, {}, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    load()
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }
    const viewLink = item => {
        window.open(item.unsubscribeUrl, "_blank")
    }
    useEffect(() => {
        load();
    }, [load])
    return (<>
        <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle">Unsubscribe</h1>
                    <p className="sms_content">Here you can create SMS and email unsubscribe templates. Get started on those triggers!</p>
                </div>
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="SMS and Email Unsubscribe" />
        </div>
        <div className="dashbd_sec">
            {showDeletePanel ?
                <>
                    <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                        <div className="_h_header">
                            <h3>My Templates</h3>
                            <span className="flex-right">
                                <button className="grid_btn_1 grid_btn_active" onClick={() => navigate(`/createContent/12`)} type="button">Create New Template</button></span>
                        </div>
                        {isLoader.isDisplay ? <GridLoader TDArray={[1, 2, 3, 4]} /> :
                            <table cellPadding="0" cellSpacing="0" className="smstable">
                                {smsDetails.length > 0 &&
                                    <thead>
                                        <tr>
                                            <td className="table_cell_header">Template Name</td>
                                            <td className="table_cell_header">Template ID</td>
                                            <td className="table_cell_header">Content Type</td>
                                            <td className="table_cell_header">Actions</td>
                                        </tr>
                                    </thead>
                                }
                                <tbody>
                                    {
                                        smsDetails.length > 0 ? smsDetails.map((item, index) => {
                                            return <SMSGrid
                                                item={item}
                                                key={index}
                                                showDeletePanelFN={showDeletePanelFN}
                                                DuplicateClick={duplicateClick}
                                                APITOKEN={APITOKEN}
                                                viewLink={viewLink}
                                            />
                                        })
                                            :<BlankData title="You have not configured any Unsubscribe Templates." content="With a template you are able to configure and change your Unsubscribe without involving your technical department." colSpan="4" />
                                    }
                                </tbody>
                            </table>
                        }
                    </div>
                </> : <DeletePanel click={() => deleteList()} closeFn={() => setDeletePanel(!showDeletePanel)} />
            }
        </div>
    </>)
}
const DeletePanel = (props) => {
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            <div className="_h_header">
                <h3>Delete Template</h3>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <tbody>
                    <tr>
                        <td className="body_table_cell white">
                            <div className="_nodata margin-bottom">
                                <div id="nodatamessage">
                                    <strong>
                                        Do you want to delete the template ?.
                                    </strong>
                                    <br></br>
                                    <br></br>
                                    This will permanently delete this template and it can not be restored.
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={props.closeFn}>Cancel</button>
                                <button type="button" className="grid_btn_1 deleteBtn" onClick={props.click}>Delete</button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div></>)
}

const SMSGrid = ({ item, ...props }) => {
    const navigate = useNavigate();
    return (<>
        <tr>
            <td className="body_table_cell noproperties">
                {item['templateName']}
            </td>
            <td className="body_table_cell">
                {item.templateId}
            </td>
            <td className="body_table_cell">
                {(getParameterByName('type', window.location.href) === '12') ? "SMS and Email Unsubscribe" : ""}
            </td>


            <td className="body_table_cell">


                <button className="inline-block link nobg noborder pointer" disabled={!!item['unsubscribeUrl'] ? false : true} onClick={() => props.viewLink(item)}>View</button>
                &nbsp;| &nbsp; &nbsp; <button className="inline-block link _edit_template noborder nobg pointer" onClick={() => navigate(`/createContent/${getParameterByName('type', window.location.href)}?id=${item.campaignId}`)}>Edit</button>  &nbsp; | &nbsp;

                <button className="inline-block link nobg noborder pointer" onClick={() => props.showDeletePanelFN(item)}>Delete</button>
            </td>
        </tr>
    </>);
}


export default Unsubscribe;