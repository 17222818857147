import VideoInfo from "./VideoInfo";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Client from "../GlobalAPICall";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useMemo, useState } from "react";
import { setOnboardingSteps } from "../Redux/Action";
import { encode as btoa } from "base-64";
import ButtonLoader from "../ReuseTemplate/ButtonLoader";

const Landingpage = ({ ...props }) => {
  const { data } = props;
  const { APITOKEN, OnboardingStep } = useSelector((state) => state);
  const dispatch = useDispatch();
  const filterVal = data.length > 0 && data[0];
  const navigate = useNavigate();
  const [isloader, setLoader] = useState(false);
  const isCompletedSteps = useMemo(() => {
    try {
      if (!filterVal.MenuId) {
        return false;
      }
      const getVal =
        OnboardingStep.Data &&
        OnboardingStep.Data.filter((x) => x.MenuId === filterVal.MenuId);

      return getVal[0].IsCompleted;
    } catch (err) {
      console.log(err);
    }
  }, [OnboardingStep.Data, filterVal.MenuId]);

  let index = null;
  if (data.length > 0) {
    index = _.findIndex(props.tabData, function (o) {
      return o.MenuId === filterVal.MenuId;
    });
  }
  const load = useCallback(() => {
    Client.get(
      `Lookup/OnBoardingMenus/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    ).then((res) => {
      try {
        let count = 0;
        for (const x of res.data) {
          if (x["IsCompleted"]) {
            count++;
          }
        }
        dispatch(setOnboardingSteps({ Data: res.data, count }));
      } catch (err) {
        console.log(err);
      }
    });
  }, [APITOKEN, dispatch]);

  const onboardingSav = ({ isCompleted, stepId, isSkipped }) => {
    setLoader(isSkipped ? false : true);

    const obj = {
      color: "",
      companyLogo: "",
      fontId: 0,
      isCompleted: isCompleted,
      isNewsLetterAccepted: false,
      isSkipped: isSkipped,
      language: "",
      locale: "",
      stepId: stepId,
      subDomains: "",
      timezone: "",
    };
    Client.post(
      `Lookup/OnBoardingSteps/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/SaveSteps`,
      obj,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then((res) => {
        setLoader(false);
        try {
          load();
          props.Click(
            props.tabData[index + 1] === undefined
              ? props.tabData[0].MenuId
              : props.tabData[index + 1].MenuId
          );
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => console.error(err));
  };
  const crmLink = () => {
    window.location.replace(
      `${APITOKEN.config.subscriptionInfo.CRMDomainUrl}/crma.aspx?lcxtkf=${
        APITOKEN.config.lcxtkf
      }&backUrl=${btoa(window.location.href)}`
    );
  };

  return (
    <>
      {data.length > 0 ? (
        <>
          <VideoInfo data={filterVal} />
          <div className="formmodel-bx text-right margin-top50">
            <button
              type="button"
              className="nobg noborder grid_btn_1"
              onClick={() =>
                onboardingSav({
                  isCompleted: false,
                  isSkipped: true,
                  stepId: filterVal.MenuId,
                })
              }
            >
              Skip
            </button>
            {filterVal.MenuId === 21 || filterVal.MenuId === 9 ? (
              <></>
            ) : (
              <button
                type="button"
                onClick={(e) =>
                  filterVal.MenuId === 18
                    ? crmLink()
                    : navigate(
                        `${
                          filterVal.MenuId === 12
                            ? "/landingpage/2"
                            : filterVal.MenuId === 16
                            ? APITOKEN.config.isPhoneNumberVerified
                              ? "/createContent/47"
                              : e.stopPropagation()
                            : filterVal.MenuId === 17
                            ? APITOKEN.config.isPhoneNumberVerified
                              ? "/createContent/48"
                              : e.stopPropagation()
                            : filterVal.MenuId === 20
                            ? "/create/goalActivity"
                            : filterVal.MenuId === 22
                            ? "/configuration?_id=42"
                            : "/createContent/4"
                        }`
                      )
                }
                className="blColor grid_btn_1  grid_btn_1  margin-right15"
              >
                {filterVal.MenuId === 12
                  ? "Create a Landing Page"
                  : filterVal.MenuId === 16
                  ? "Send SMS"
                  : filterVal.MenuId === 17
                  ? "Create Email"
                  : filterVal.MenuId === 18
                  ? "Import Contacts"
                  : filterVal.MenuId === 20
                  ? "Create Goals"
                  : filterVal.MenuId === 22
                  ? "API Documentation"
                  : "Create Survey"}
              </button>
            )}
            <button
              type="button"
              className="grid_btn_1 grid_btn_active __Save_link"
              disabled={isCompletedSteps}
              id={filterVal.MenuId}
              onClick={() =>
                onboardingSav({
                  isCompleted: true,
                  isSkipped: false,
                  stepId: filterVal.MenuId,
                })
              }
            >
              <ButtonLoader IsLoader={isloader}>Done</ButtonLoader>
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Landingpage;
