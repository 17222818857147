import { useCallback, useEffect, useReducer, useState } from "react";
import './index.css';
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import getParameterByName from "../../ReuseTemplate/GetRoute";
import StepsDistribution from "../StepsTopBar";
import { Datepicker } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import { TemplateCampaign } from "../../Create/Template/Trigger/SMS/TemplateCampaign";

import WaterMarkRemove from "../../ReuseTemplate/WaterMarkRemove";
import AppStore from "./APPStore";
import * as filestack from 'filestack-js';
import moment from "moment/moment";
// import moment from "moment";
// import getLocaleDateString from "../../TimeFormat";
const url = process.env;
const client = filestack.init(url.REACT_APP_FILE_PICKER_API_KEY);


function Reducer(state, action) {
    switch (action.type) {
        case 'STARTDATE':
            return { ...state, ...action.payload }
        case 'EXPIRATIONDATE':

            return { ...state, ...action.payload }

        default:
            return state
    }
}

export const MobileSend = () => {
    const { APITOKEN } = useSelector(state => state);
    const { id } = useParams();
    const CampaignId = getParameterByName('cam', window.location.href);
    const [getTimeZone, setTimeZone] = useState({ data: [], selected: '' });
    const [sehduleDropVal, setSehduleDropVal] = useState('now');
    const navigate = useNavigate();
    const [waterMarkshow, setWaterMarkRemove] = useState(false);
    const [IsLoader, setIsLoader] = useState(false);
    const [state, dispatch] = useReducer(Reducer, { StartDate: "", MinDate: "", ExpirationDate: "" });
    const [smsTemplateData, setTemplate] = useState([]);
    const [classifications, setClassifications] = useState([]);
    //const [storeCategories, setStoreCategories] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [selectedCampaign, setFilterCampaign] = useState({});
    const [displayPushText, setDisplayPushText] = useState({
        display: false,
        text: ""
    });
    const [lowBlance, setLowBalancePopup] = useState({
        display: false
    });
    const [selectedVal, SetSelectedVal] = useState({
        storeVal: "",
        DistributionVal: "",
        CategoryVal: "",
        Title: "",
        DistributionText: "",
        classificationId: "",
        storeCategoryId: "",
        storeId: "",
        smsContent: "",
        appStore: "",
        campaign: {
            Id: "",
        },
        campaignLogo: "",
        editStoreId: "",
        editCategoryId: ""
    })

    const checkSchduleSending = (e) => {
        const ev = e.target.value;
        if (ev !== 'now') {
            availableTimeZones();
        } else {
            setTimeZone(prv => ({ ...prv, data: [] }));
        }
        setSehduleDropVal(ev);
    }

    const campaignLink = v => {
        if (v[0]['HasWaterMark']) {
            setWaterMarkRemove(true);
        } else {
            setWaterMarkRemove(false);
        }
        SetSelectedVal(prv => ({
            ...prv,
            campaign: v[0]
        }));
    }
    const closeWaterMarkPopup = (f) => {
        setWaterMarkRemove(f);
    }
    // AvailableTimeZones API Call //
    const availableTimeZones = useCallback(async () => {
        APITOKEN.token &&
            await Client.get('Lookup/AvailableTimeZones', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setTimeZone(prv => ({ ...prv, data: result.data, selected: APITOKEN.config.timeZoneOffset }));
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {

                });
    }, [APITOKEN]);

    const reloadElement = () => {
        SetSelectedVal(prv => ({
            ...prv,
            smsContent: selectedVal.smsContent,
            ...{
                campaign: {
                    ...selectedVal.campaign,
                    HasWaterMark: false,
                    Name: selectedVal.campaign.Name
                }
            }
        }));
    }

    const PostObjectFN = () => {
        const obj = {
            "distributionId": id,
            "pushNotificationType": parseInt(selectedVal.DistributionVal),
            "pushNotificationText": displayPushText.text,
            "titleText": selectedVal.Title,
            "descriptionText": selectedVal.DistributionText,
            "expirationDate": state.ExpirationDate,
            "isScheduled": true,
            "campaignId": selectedVal.campaign.Id,
            "scheduledDateTime": sehduleDropVal === 'now' ? "" : state.StartDate,
            "appId": parseInt(selectedVal.appStore),
            "contentId": getParameterByName('edit', window.location.href) !== null ? selectedVal.contentId : getParameterByName('cam', window.location.href),
            "contentTypeId": 95,
            "campaignLogo": selectedVal.campaignLogo,
            "classificationId": selectedVal.classificationId,
            "storeCategoryId": selectedVal.storeCategoryId,
            "storeId": selectedVal.storeId
        }

        return obj;
    }
    const mobileSendFn = () => {
        const PostObject = PostObjectFN();
        setIsLoader(true);
        try {
            Client.put(`Mobile/Distribution`, PostObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        if (data.Success) {
                            navigate(`/Mobile/summary/${id}`);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                })
                .catch(err => {
                    console.error(err);
                    setIsLoader(false)
                })

        } catch (err) {
            console.log(err);
            setIsLoader(false)
        }
    }

    const currentTimeAPIFn = ev => {
        setTimeZone(prv => ({ ...prv, selected: ev.target.value, flag: true }));
        Client.get(`utility/currenttime?timeZone=${ev.target.value}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    dispatch({
                        type: 'STARTDATE', payload: {
                            StartDate: `${res.data.date} ${res.data.time} `,
                            MinDate: `${res.data.date} ${res.data.time}`,
                            ExpirationDate: `${res.data.date} ${res.data.time} `
                        }
                    });
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));

    }
    const templateEditSMS = useCallback(() => {
        (APITOKEN.token !== "") &&
            Client.get(`Mobile/Distribution/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(async (res) => {
                try {
                    const data = res.data;
                    SetSelectedVal(prv => ({
                        ...prv,
                        DistributionVal: data.pushNotificationType.toString(),

                        appStore: data.appId,
                        smsContent: 2,
                        campaign: {
                            Id: data.campaignId
                        },
                        contentId: data.contentId,
                        Title: data.titleText,
                        DistributionText: data.descriptionText,
                        campaignLogo: data.campaignLogo,
                        classificationId: data.classificationId,
                        storeCategoryId: data.storeCategoryId,
                        storeId: data.storeId,
                        editStoreId: data.storeId,
                        editCategoryId: data.storeCategoryId,
                    }));
                    setDisplayPushText({
                        display: data.pushNotificationType === 1 ? true : false,
                        text: data.pushNotificationType === 1 ? data.pushNotificationText : "",
                    });


                    dispatch({
                        type: 'EXPIRATIONDATE', payload: {
                            ExpirationDate: `${data.expirationDate}`
                        }
                    });
                    const schduleDate = data.scheduledDateTime === "" ? 'now' : data.scheduledDateTime;
                    setSehduleDropVal(schduleDate)

                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN, id])


    // Campaign Image upload method//
    const imageUpload = () => {
        const options = {
            maxFiles: 1,

            fromSources: ["local_file_system"],
            uploadInBackground: false,
            onOpen: () => console.log('opened!'),
            onUploadDone: ({ filesUploaded }) => {

                SetSelectedVal(prv => ({
                    ...prv,
                    campaignLogo: filesUploaded[0].url
                }))
            },
        };
        client.picker(options).open();
    }


    useEffect(() => {
        // === Edit case condition  === //
        if (getParameterByName('edit', window.location.href) !== null) {
            templateEditSMS();
        };
        const data = APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0];
        // const formatCountry = getLocaleDateString(APITOKEN.config.locale);
        // const newFormat =  moment(new Date(new Date(data).setHours(new Date(data).getHours() + 2))).format(formatCountry)
        const currentDate = new Date(data);

        dispatch({
            type: 'STARTDATE', payload: {
                StartDate: data,
                MinDate: APITOKEN.config.hasOwnProperty('cultureCode') && APITOKEN.config.currentTime.split('.')[0],
                ExpirationDate: moment(currentDate.setMonth(currentDate.getMonth() + 1)).format('YYYY.MM.DD HH:mm')
            }
        });

    }, [
        APITOKEN.config,
        templateEditSMS
    ])

    useEffect(() => {
        /*(APITOKEN.token !== "") &&
            Client.get("Mobile/Categories?categoryType=Store", {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(async (res) => {
                try {
                    const data = res.data;

                    setStoreCategories(data);
                    SetSelectedVal(prv => ({
                        ...prv,
                        storeId: ""
                    }));
                } catch (err) {
                    console.error(err);
                }
            });*/

        (APITOKEN.token !== "") &&
            Client.get("Mobile/ClassificationsForNotification", {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(async (res) => {
                try {
                    const data = res.data;

                    setClassifications(data);
                } catch (err) {
                    console.error(err);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [APITOKEN]);

    useEffect(() => {
        SetSelectedVal(prv => ({
            ...prv,
            classificationId: selectedVal.classificationId
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classifications]);

    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get(`Mobile/Stores?storeCategoryId=${(selectedVal.storeCategoryId || "")}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(async (res) => {
                try {
                    const data = res.data;

                    setStoreList(data);

                    if (selectedVal.storeCategoryId === selectedVal.editCategoryId) {
                        SetSelectedVal(prv => ({
                            ...prv,
                            storeId: selectedVal.editStoreId
                        }));
                    } else {
                        // If category ID is different then edit category make storeId null
                        SetSelectedVal(prv => ({
                            ...prv,
                            storeId: ""
                        }))
                    }
                } catch (err) {
                    console.error(err);
                }
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [APITOKEN, selectedVal.storeCategoryId])

    return (
        <>
            <StepsDistribution Name="App" Active={2} Hide={true} />
            <div className="dashbd_sec margin-bottom">
                <div className="sms_Sec_1 bx-boder-common inline-block margin-bottom">
                    <div className="_h_header relative">
                        <h3>App Content</h3>
                    </div>
                    <div className="bx-pad">
                        <AppStore
                            APITOKEN={APITOKEN}
                            Value={selectedVal}
                            isRequired={true}
                            ChangeEvent={SetSelectedVal}
                        />
                        {/* <StoreSelect
                            ChangeEvent={SetSelectedVal}
                            Value={selectedVal}
                            APITOKEN={APITOKEN}
                        /> */}
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Distribution <span className="error-message">*</span></label>
                            <select className="inputformmodel" value={selectedVal.DistributionVal} onChange={e => {
                                SetSelectedVal(prv => ({
                                    ...prv,
                                    DistributionVal: e.target.value
                                }));
                                setDisplayPushText(prv => ({
                                    ...prv,
                                    display: e.target.value === '1' ? true : false,
                                    text: e.target.value !== '1' ? "" : prv.text
                                }))
                            }}>
                                <option value="">Select</option>
                                <option value="1">With Push Notification</option>
                                <option value="2">Directly in Store, without Push Notification</option>
                            </select>
                        </div>
                        {displayPushText.display && <div className="formmodel-bx">
                            <label className="_l_able_cls">Push Notification Text <span className="error-message">*</span></label>
                            <textarea className="inputformmodel textareaModel" value={displayPushText.text} onChange={e => setDisplayPushText(prv => ({
                                ...prv,
                                text: e.target.value
                            }))}
                                placeholder="Push Notification Text"></textarea>
                        </div>}

                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Classification<span className="error-message">*</span></label>
                            <select className="inputformmodel"
                                value={selectedVal.classificationId}
                                onChange={(e) => SetSelectedVal(prv => ({
                                    ...prv,
                                    classificationId: e.target.value
                                }))}
                            >
                                <option value="">Select</option>
                                {(classifications || []).map((v, i) => <option key={i} value={v.PKID}>{v.Name}</option>)}
                            </select>
                        </div>

                        {/*<div className="formmodel-bx">
                            <label className="_l_able_cls">Store Category<span className="error-message">*</span></label>
                            <select className="inputformmodel"
                                value={selectedVal.storeCategoryId}
                                onChange={(e) => SetSelectedVal(prv => ({
                                    ...prv,
                                    storeCategoryId: e.target.value
                                }))}
                            >
                                <option value="">Select</option>
                                {(storeCategories || []).map((v, i) => <option key={i} value={v.id}>{v.name}</option>)}
                            </select>
                        </div>*/}

                        {(APITOKEN['config'].subscriptionInfo?.IsUserHasStore) && (
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Store <span className="error-message">*</span></label>
                                <select className="inputformmodel"
                                    value={selectedVal.storeId}
                                    onChange={(e) => SetSelectedVal(prv => ({
                                        ...prv,
                                        storeId: e.target.value
                                    }))}
                                >
                                    <option value="">Select</option>
                                    {(storeList || []).map((v, i) => <option key={i} value={v.storeId}>{v.storeName}</option>)}
                                </select>
                            </div>
                        )}

                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Content <span className="error-message">*</span></label>
                            <select className="inputformmodel" value={selectedVal.smsContent} onChange={e => SetSelectedVal(prv => ({
                                ...prv,
                                smsContent: e.target.value
                            }))}>
                                <option value="">Select</option>
                                <option value="2">Landing Page</option>
                            </select>
                        </div>
                        {selectedVal.smsContent !== "" && <TemplateCampaign
                            Value={selectedVal}
                            ChangeEvent={campaignLink}
                            setTemplate={setTemplate}
                            smsTemplateData={smsTemplateData}
                            setFilterCampaign={setFilterCampaign}
                            CampaignId={CampaignId}
                            isRequired={true}
                        />}
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Title text in app <span className="error-message">*</span></label>
                            <input type="text" className="inputformmodel"
                                value={selectedVal.Title}
                                onChange={e => SetSelectedVal(prv => ({
                                    ...prv,
                                    Title: e.target.value

                                }))} placeholder="Title" maxLength={30} />
                            <div className="smsCountInfor text-right">
                                <hr></hr>
                                Number of characeters in use:
                                <span className="text-app-count"> {selectedVal.Title.length}</span>
                            </div>
                            <div className="smsCountInfor text-right nopadding" style={{ color: "#000" }}>
                                <hr></hr>
                                Maximum Number of characeters:
                                <span className="text-app-count"> {30 - selectedVal.Title.length}</span>
                            </div>
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Distribution text in app <span className="error-message">*</span></label>
                            <textarea type="text" placeholder="Distribution text" maxLength={300} value={selectedVal.DistributionText} className="inputformmodel textareaModel"
                                onChange={e => SetSelectedVal(prv => ({
                                    ...prv,
                                    DistributionText: e.target.value
                                }))}
                            ></textarea>
                            <div className="smsCountInfor text-right">
                                <hr></hr>
                                Number of characeters in use:

                                <span className="text-app-count"> {selectedVal.DistributionText.length}</span>
                            </div>
                            <div className="smsCountInfor text-right nopadding" style={{ color: "#000" }}>
                                <hr></hr>
                                Maximum Number of characeters:
                                <span className="text-app-count">

                                    {300 - selectedVal.DistributionText.length}
                                </span>
                            </div>
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Expiration date in app <span className="error-message">*</span></label>
                            <Datepicker className="inputformmodel"
                                placeholder="Expiration"
                                min={state.MinDate}
                                value={state.ExpirationDate}
                                onChange={ev => dispatch({ type: 'EXPIRATIONDATE', payload: { ExpirationDate: ev.valueText } })}
                                dataTimezone='utc'
                                touchUi={true}
                                timeFormat="HH:mm"
                                dateFormat="YYYY.MM.DD"
                                locale={APITOKEN.config.countryCode}
                                theme="ios"
                                themeVariant="dark"
                                controls={['calendar', 'time']} />
                        </div>
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Image <span className="error-message">*</span></label>
                            {selectedVal.campaignLogo !== "" &&
                                <div target="_blank" className="link inline-block">
                                    <img src={selectedVal.campaignLogo} width="150px" alt="" />
                                </div>
                            }

                        </div>
                        <div className="formmodel-bx">
                            <button type="button" className="grid_btn_1" onClick={imageUpload}>Upload</button>
                        </div>
                    </div>
                </div>
                <div className="sms_Sec_1 bx-boder-common inline-block drop-zone nomargin">
                    <div className="_h_header">
                        <h3>Send Options</h3>
                    </div>
                    <div className="bx-pad">
                        <div className="formmodel-bx">
                            <label className="_l_able_cls">Schedule <span className="error-message">*</span></label>
                            <select className="inputformmodel" value={sehduleDropVal} onChange={checkSchduleSending}>
                                <option value="now">Send Now</option>
                                <option value="scheduleFor">Schedule For</option>
                            </select>
                        </div>
                        {getTimeZone.data.length > 0 &&
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Schedule For</label>
                                <div className="relative width50" style={{ paddingRight: '20px' }}>
                                    <Datepicker className="inputformmodel" placeholder="End date"
                                        min={state.MinDate}
                                        value={state.StartDate}
                                        onChange={ev => dispatch({ type: 'STARTDATE', payload: { StartDate: ev.valueText } })}
                                        dataTimezone='utc'
                                        dateFormat="YYYY.MM.DD"
                                        touchUi={true}
                                        timeFormat="HH:mm"
                                        locale={APITOKEN.config.countryCode}
                                        theme="ios"
                                        themeVariant="dark"
                                        controls={['calendar', 'time']} />
                                </div>
                                <div className="relative width50">
                                    <select className="inputformmodel" value={getTimeZone.selected} onChange={currentTimeAPIFn}>
                                        {getTimeZone.data.length > 0 &&
                                            getTimeZone.data.map((v, i) => <option key={i} value={v.Id}>{v.Name}</option>)}
                                    </select>
                                </div>

                            </div>
                        }
                        <div className="formmodel-bx nomargin text-right">

                            <button type="button" className="grid_btn_1 grid_btn_active" disabled={
                                (selectedVal.Title !== "" &&
                                    selectedVal.DistributionText !== "" &&
                                    selectedVal.smsContent !== "" &&
                                    selectedVal.classificationId !== "" &&
                                    //selectedVal.storeCategoryId !== "" &&
                                    (APITOKEN['config'].subscriptionInfo?.IsUserHasStore ? (selectedVal.storeId !== "") ? true : false : true) &&
                                    ((selectedVal.DistributionVal !== "" && selectedVal.DistributionVal === '1') ? displayPushText.text !== "" ? true : false : true) &&
                                    selectedVal.campaign.Id !== "" &&
                                    !selectedVal.campaign.HasWaterMark &&
                                    selectedVal.campaignLogo !== "" &&
                                    selectedVal.appStore !== "") ? false : true
                            } onClick={mobileSendFn}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    {getParameterByName('edit', window.location.href) !== null ? 'Update' : 'Continue'}
                                </ButtonLoader>
                            </button>

                        </div>
                    </div>
                </div>

                {
                    waterMarkshow && <WaterMarkRemove
                        closeEvent={closeWaterMarkPopup}
                        StartDate={selectedVal.campaign.StartDate}
                        EndDate={selectedVal.campaign.EndDate}
                        CampaignData={selectedVal.campaign.WaterMarkName}
                        Data={selectedVal.campaign}
                        Id={selectedVal.campaign.Id}
                        Reload={reloadElement}
                        contentId={selectedVal.campaign.ContentTypeId}
                        setLowBalancePopup={setLowBalancePopup}
                        lowBlance={lowBlance}
                    >
                        {selectedCampaign && <></>}
                        <div>
                            <h3>Activate</h3>
                            <p>To Activate your Landing Pageyou need to set a Activation Period. Before you set the Activation Period a Watermark layer is disabling the Landing Page for interaction. You can still view the Landing Page.</p>
                        </div>
                        <div>
                            <h3>Activation Period</h3>
                            <p>In the Activation Period you visitors can interact with you Landing Page and you will find all registered activity under Insights.</p>
                        </div>
                        <div style={{ paddingBottom: '27px' }}>
                            <h3>Closing Time</h3>
                            <p>When the Closing Time is reached we will add a Watermark Text as a layer on top of your Landing Page, disabling it for interaction.</p>
                        </div>

                    </WaterMarkRemove>

                }
            </div >
        </>)

}

