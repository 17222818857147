




import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
// import { DownLoadData } from '../DownloadChartJSON';
import HC_exporting from 'highcharts/modules/exporting';
import Client from '../../GlobalAPICall';
HC_exporting(Highcharts)
const VideoChart = () => {
    const { APITOKEN } = useSelector(state => state);
    const chart = useRef();
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'bar'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            // categories: ['Africa', 'America', 'Asia', 'Europe', 'Oceania'],
            title: {
                text: 'Video'
            },
            // gridLineWidth: 1,
            lineWidth: 0
        },
        plotOptions: {
            bar: {
                borderRadius: '1%',
                dataLabels: {
                    enabled: true
                },
                groupPadding: 0.1
            }
        },
        yAxis: {

            gridLineWidth: 0
        },
        tooltip: {
           enabled:false
        },
        credits: {
            enabled: false
        },
        // colors: ['#FCE700', '#F8C4B4', '#f6e1ea', '#B8E8FC', '#BCE29E'],
        series: [{
            name: 'Brands',
            colorByPoint: true,
            data: []
        }]
    });
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Insights/Mobile/TopListData?sourceType=Video&topCount=5', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                dataFilter(data);

            });

    }, [APITOKEN]);
    const dataFilter = (data) => {
        const colors = ['#FCE700', '#F8C4B4', '#f6e1ea', '#B8E8FC', '#BCE29E'];
        const ar = [];
        data.forEach(function (v, i) {
            ar.push({ name: v['sourceType'], data: [v['totalCount']], color: colors[i] });
        });
        setOptionValue(prv => ({
            ...prv,
            series: ar
        }))
    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <HighchartsReact highcharts={Highcharts} ref={chart} options={optionData} />
    )

}

export default VideoChart