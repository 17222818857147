import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import Tabing from './Tabing';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';
const UTMTemplate = ()=> {
    const checkIsAuth = useSelector(state => state);
    const [tabData, setTab] = useState([]);

    // Get All list from API Call
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('Insights/Categories/UTM/UTM', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    return (<>
        <div className=" nomargin">
            <div className="dashbd_sec">
               &nbsp;
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="UTM" />
        </div>
        <div className="dashbd_sec margin-top50">
                <Tabing data={tabData}/>
            </div>
     </>)
}

export default UTMTemplate;