import { Datepicker } from "@mobiscroll/react"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client, { Export_Client } from "../../GlobalAPICall";
import moment from "moment";
import { useNavigate } from "react-router";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

export const SMTP = () => {
    const { APITOKEN } = useSelector(state => state);
    const [dateFormat, setDate] = useState({
        maxDate: '',
        value: ''
    });
    const [gridData, setGrid] = useState([]);
    const navigate = useNavigate();
    const [exportMenu, setExportMenu] = useState(false);
    const [IsLoader, setLoader] = useState(false);
    const allFN = () => {
        const { value } = dateFormat;
        const fromDate = moment(value[0]).format('YYYY-MM-DD');
        const obj = {
            "fromDate": moment(new Date(fromDate).setYear(1970)).format('YYYY-MM-DD'),
            "toDate": moment().format('YYYY-MM-DD')
        };
        exportFn(obj);
    }
    const exportBtn = () => {
        const { value } = dateFormat;
        setLoader(true);
        const obj = {
            "fromDate":!!value[0]?moment(value[0]).format('YYYY-MM-DD'):moment(new Date().setDate(new Date().getDate()-7)).format('YYYY-MM-DD') ,
            "toDate": moment(value[1]).format('YYYY-MM-DD')
        };
        exportFn(obj);
    }

    const exportFn = (redirectObject) => {
        const obj = {
            "emailRecipient": APITOKEN.config.userEmail,
            "name": "SMTPSending",
            ...redirectObject
        };
        try {
            Export_Client.post(`export/Email`, obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    setLoader(false);
                    try {
                        if (res.data.success) {
                            navigate('/download');
                        };
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.log(err);
                    setLoader(false);
                })
        } catch (err) {
            console.log(err);
        }
    }

    const load = useCallback((v) => {
        const splitDate = v.split(' - ');
        try {
            Client.get(`Insights/distribution/SMTP/details?startDate=${splitDate[0]}&endDate=${splitDate[1]}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                setGrid(data);
            }).catch(err => console.error(err));
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN])

    useEffect(() => {
        load(" - ");
        setDate(prv => ({
            ...prv,
            maxDate: (APITOKEN.config.hasOwnProperty('cultureCode')) && APITOKEN.config.currentTime.split('.')[0]
        }));
    }, [APITOKEN, load]);
    return (
        <div className="bx-boder-common white">
            <div className="_insight_header white">
                <h3>SMTP Sending</h3><p className="content">&nbsp;</p>
            </div>
            <div className="bx-pad white">
                <div className="_h_header noborder nopadding">
                    <Datepicker
                        select="range"
                        className="inputformmodel "
                        max={dateFormat.maxDate}
                        onChange={e => {
                            setDate(prv => ({
                                ...prv,
                                value: e.value
                            }));
                            load(e.valueText)
                        }}
                        touchUi={true}
                        dataTimezone='utc'
                        dateFormat="YYYY-MM-DD"
                        locale={APITOKEN.config.countryCode}
                        controls={['calendar']}
                        placeholder="Start Time" />

                    <span className="flex-right drop-zone">
                        <button type="button" className="grid_btn_1  export_Email margin-right15" onClick={exportBtn}><ButtonLoader IsLoader={IsLoader} color={true } >Export</ButtonLoader></button>
                        <button type="button" className="grid_btn_1 nobg vertical-top link" onClick={() => setExportMenu(!exportMenu)}><i className="fal fa-ellipsis-h" style={{ fontSize: '24px' }}></i></button>
                        {exportMenu && <div className="cam-more-drop">
                            <ul className="cm-ul">
                                <li>
                                    <button type="button" onClick={allFN} className="_all">
                                        Export All
                                    </button>
                                </li>
                            </ul>
                        </div>}
                    </span>
                </div>
            </div>
            <div className="-cam-sen-grid white b_top">
                <div className="">
                    <table width="100%"
                        cellPadding="0" cellSpacing="0"
                        className="smstable table-layout-default white">
                        {gridData.length !== 0 &&
                            <thead>
                                <tr>
                                    <th className="table_cell_header">Date</th>
                                    <th className="table_cell_header">Count</th>
                                </tr>
                            </thead>
                        }
                        <tbody className="smtPGridTbody">

                            {gridData.length > 0 ? gridData.map((v, i) => <tr key={i}>
                                <td className="body_table_cell white">
                                    {v.sentDate}
                                </td>
                                <td className="body_table_cell white">
                                    {v.totalSending}
                                </td>
                            </tr>) :
                                <tr>
                                    <td className="body_table_cell white" colSpan="8">
                                        <div className="_nodata">
                                            <div id="nodatamessage"><strong>Prepare your insights for download.</strong><br /><br />If you want to get all your insights regarding this sending downloadable in excel format click on the Export button. Your exports will be stored inside your top right corner menu named Downloads.</div>
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )

}

