import axios from "axios";
import { toast } from "react-toastify";
import { ENDPOINTS } from "./network";
import { isCSR } from "../helpers";

const token = localStorage.getItem("tokenApi");

const PUBLIC_ENDPOINTS = [
  ENDPOINTS.getNonce,
  ENDPOINTS.verifyNonce,
  ENDPOINTS.profile,
];
const axiosInstanceAuth = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const requestAuth = ({ method, url, payload }) =>
  axiosInstanceAuth[method](url, payload);

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: isCSR ? `Bearer ${token}` : null,
  },
});

export const request = ({ method, url, payload }) =>
  axiosInstance[method](url, payload);

const signOutLocally = () => {
  const toastId = "session-expired";

  localStorage.removeItem("jwt");

  if (!toast.isActive(toastId)) {
    toast.error("Your session has expired: Please log in again to continue", {
      toastId,
    });
  }
  // setTimeout(() => {
  //   window.location.href = "/"; // ! change based on project
  // }, 2000);
};

axiosInstance.interceptors.request.use(function onFulfilled(config) {
  config.headers["x-href"] = document.location.href;
  const jwt = localStorage.getItem("jwt");
  config.headers.Authorization = `Bearer ${jwt}`;

  if (PUBLIC_ENDPOINTS.map(({ url }) => url).includes(config.url || "")) {
    return { ...config };
  } else {
    if (!jwt) {
      signOutLocally();
    }
    return { ...config };
  }
});
