import React, { useCallback, useEffect, useState } from 'react'
import Tabing from './Tabing';
import { useSelector } from 'react-redux';
import Client from '../../GlobalAPICall';
import axios, { isCancel } from 'axios';

function MobileAPP() {
    const { APITOKEN } = useSelector(state => state);
    const [tabData, setTab] = useState([]);
    const load = useCallback(() => {
        const cancelToken = axios.CancelToken.source();
        (APITOKEN['token'] !== "") &&
            Client.get('Campaign/Mobile/datacategories', {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setTab(res['data']);
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                if (isCancel(err)) {
                    cancelToken.cancel();
                }
            });
        return (() => {
            cancelToken.cancel();
        })
    }, [APITOKEN]);

    useEffect(() => {
        load()
    }, [load]);
    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>
            <div className="dashbd_sec margin-top50">
                <Tabing data={tabData} />
            </div>
        </>
    )
}

export default MobileAPP
