import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import Client from "../../../GlobalAPICall";
import axios from "axios";
import { useSelector } from "react-redux";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";
import * as filestack from "filestack-js";
import SubCatgoryPopUp from "./SubCatgoryPopUp";
import Languages from "../Track/Languages";
import SubCatgoryView from "./SubCatgoryView";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import SortableOrder from "../SortableOrder";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const client = filestack.init("APBcz9XF7TEmEQpcF5wmxz");

const Reducer = (state, { type, payload }) => {
  switch (type) {
    case "LOAD_CAT":
      return { ...state, ...payload };
    case "LOAD_VIEW":
      return { ...state, ...payload };
    default:
      return state;
  }
};

const initliazation = {
  subCat: [
    {
      id: parseInt((-Math.random() * 1000).toFixed(0)),
      languageCode: "",
      value: "",
      categoryId: "",
    },
  ],
  show: false,
  catId: 0,
  loader: false,
  viewLoad: [],
  viewShow: false,
};
function Category() {
  const [isDisplay, setDisplay] = useState({ toggle: false, id: "" });
  const [lang, SetLang] = useState({
    data: [],
  });
  const [categoriesList, SetCategoriesList] = useState([]);
  const [selectedLang, SetSelectedLang] = useState("");
  const [buttonShow, setButtonshow] = useState(false);
  const { APITOKEN } = useSelector((state) => state);

  const [isLoader, setIsLoader] = useState(false);
  const [state, dispatch] = useReducer(Reducer, {
    ...initliazation,
  });

  const load = useCallback(async () => {
    if (APITOKEN["token"] !== "") {
      const { data } = await Languages(APITOKEN);
      try {
        if (data.length > 0) {
          SetLang((prv) => ({
            ...prv,
            data,
          }));
        }
      } catch (err) {
        console.error(err);
      }
    }
    return () => {
      SetLang({
        data: [],
      });
    };
  }, [APITOKEN]);
  const onChangeFn = (ev) => {
    const cancelToken = axios.CancelToken.source();
    SetSelectedLang(ev);
    setButtonshow(true);
    Client.get(`Mobile/AppCategories?languageCode=${ev}`, {
      cancelToken: cancelToken.token,
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then((res) => {
        try {
          SetCategoriesList(res.data);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          cancelToken.cancel();
        }
      });
  };

  // Delete Categories Fields //
  const OnDelete = (item) => {
    if (item.id < 0) {
      const newArray = categoriesList.filter((x) => x.id > 0);
      SetCategoriesList([...newArray]);
    } else {
      setDisplay({ toggle: true, id: item.id });
    }
  };

  // Get Categories Value to Onchange  //
  const onFieldChange = (ev, item) => {
    const newArray = categoriesList.map((v) =>
      v.id === item.id ? { ...v, value: ev, languageCode: selectedLang } : v
    );
    SetCategoriesList([...newArray]);
  };

  // Add Extra Fiels in Categories  //
  const addMoreFields = () => {
    SetCategoriesList((prv) => [
      ...prv,
      ...[
        {
          id: -Math.floor(Math.random() * 1000),
          languageCode: "",
          value: "",
        },
      ],
    ]);
  };

  const saveFN = () => {
    setIsLoader(true);
    const filterArray = categoriesList.map((v) => ({
      ...v,
      id: v.id < 0 ? 0 : v.id,
    }));
    postAPI(filterArray);
  };
  const postAPI = (postdata) => {
    Client.post(`Mobile/AppCategory?languageCode=${selectedLang}`, postdata, {
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then(({ data }) => {
        setIsLoader(false);
        if (data.Success) {
          onChangeFn(selectedLang);
        }
      })
      .catch((err) => setIsLoader(false));
  };

  const deleteConfirmFn = () => {
    Client.delete(
      `Mobile/Categories?categoryId=${isDisplay.id}&languageCode=${selectedLang}`,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(({ data }) => {
        if (data.Success) {
          setDisplay({ toggle: false, id: "" });
          onChangeFn(selectedLang);
        }
      })
      .catch((err) => console.error(err));
  };

  const ButtonDisabled = useMemo(() => {
    const newarrya = categoriesList.filter((x) => x.value === "");
    return newarrya.length === 0 ? false : true;
  }, [categoriesList]);
  const OnAddClick = (element) => {
    dispatch({
      type: "LOAD_CAT",
      payload: {
        ...state,
        catId: element.id,
        show: true,
        subCat: initliazation.subCat.map((item) => ({
          ...item,
          languageCode: element.languageCode,
          categoryId: element.id,
        })),
      },
    });
  };
  const onViewClick = (element) => {
    dispatch({
      type: "LOAD_VIEW",
      payload: { ...initliazation, viewLoad: [], viewShow: true, ...element },
    });
  };
  useEffect(() => {
    load();
  }, [load]);
  return (
    <>
      {!isDisplay.toggle && (
        <>
          <div className="bx-boder-common white width100 inline-block">
            <div className="_h_header">
              <h3>Categories</h3>
            </div>
            <div className="padding20">
              <div style={{ maxWidth: "492px" }}>
                <div className="formmodel-bx">
                  <label className="_l_able_cls">Select language</label>
                  <select
                    className="inputformmodel"
                    value={selectedLang}
                    onChange={(e) => onChangeFn(e.target.value)}
                  >
                    <option value="">Select</option>
                    {lang.data.map((v, i) => (
                      <option value={v.LanguageCode} key={i}>
                        {v.Value}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {categoriesList.length > 0 ? (
              <>
                <Grid
                  Data={categoriesList}
                  OnDelete={OnDelete}
                  onFieldChange={onFieldChange}
                  SetCategoriesList={SetCategoriesList}
                  OnAdd={OnAddClick}
                  OnView={onViewClick}
                  setLand={selectedLang}
                />
              </>
            ) : (
              <></>
            )}{" "}
            {buttonShow && (
              <div className="formmodel-bx text-right padding20 nomargin">
                <button
                  type="button"
                  className="grid_btn_1 margin-right15"
                  onClick={addMoreFields}
                >
                  Add More
                </button>
                <button
                  type="button"
                  className="grid_btn_1 grid_btn_active"
                  disabled={ButtonDisabled}
                  onClick={saveFN}
                >
                  <ButtonLoader IsLoader={isLoader}>Save</ButtonLoader>
                </button>
              </div>
            )}
          </div>
        </>
      )}
      {isDisplay.toggle && (
        <div className="bx-boder-common white width100 inline-block">
          <div className="_h_header">
            <h3>Delete</h3>
          </div>
          <table cellPadding="0" cellSpacing="0" width="100%">
            <tbody>
              <tr>
                <td className="body_table_cell white">
                  <div className="_nodata">
                    <div id="nodatamessage">
                      <strong>
                        Are you sure you want to delete this category?
                      </strong>
                      <br></br>
                    </div>
                  </div>
                  <div className="text-right margin-top50">
                    <button
                      type="button"
                      onClick={() => setDisplay({ toggle: false })}
                      className="grid_btn_1 noborder nobg"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="grid_btn_1 deleteBtn"
                      onClick={deleteConfirmFn}
                    >
                      Confirm
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
      {/* {JSON.stringify(state.subCat)} */}
      {state.show ? (
        <SubCatgoryPopUp
          dispatch={dispatch}
          state={state}
          APITOKEN={APITOKEN}
          setLand={selectedLang}
        />
      ) : (
        <></>
      )}
      {state.viewShow ? (
        <SubCatgoryView dispatch={dispatch} state={state} APITOKEN={APITOKEN} />
      ) : (
        <></>
      )}
    </>
  );
}

export default Category;

const Grid = ({
  Data,
  onFieldChange,
  OnDelete,
  SetCategoriesList,
  OnAdd,
  OnView,
  setLand,
}) => {
  const imageUpload = (event, item) => {
    event.stopPropagation();
    const options = {
      maxFiles: 1,
      fromSources: ["local_file_system"],
      uploadInBackground: false,
      onOpen: () => console.log("opened!"),
      onUploadDone: (res) => {
        const newArray = Data.map((v) =>
          v.id === item.id ? { ...v, image: res.filesUploaded[0].url } : v
        );
        SetCategoriesList(newArray);
      },
    };
    client.picker(options).open();
  };
  const onSortEnd = async (oldIndex, newIndex) => {
    const reorder = arrayMoveImmutable(Data, oldIndex, newIndex);
    const postObject = reorder.map(({ id }, index) => ({
      id,
      order: index + 1,
    }));
    SetCategoriesList((array) => arrayMoveImmutable(array, oldIndex, newIndex));
    const { data } = await SortableOrder(
      { Id: 0, Type: "Category", Code: setLand },
      postObject
    );
    if (data.Success) {
      toast.success("Save successfully", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <>
      <div className="b_top">
        <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
          <table cellPadding="0" cellSpacing="0" className="smstable ">
            <thead>
              <tr>
                <th className="table_cell_header" width="6%">
                  &nbsp;
                </th>
                <th className="table_cell_header" width="30%">
                  Category Name
                </th>
                <th className="table_cell_header">Language Code</th>
                <th className="table_cell_header">Image Upload</th>

                <th className="table_cell_header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {Data.map((item, index) => {
                return (
                  <SortableItem key={index}>
                    <tr>
                      <td className="body_table_cell">
                        {item.image && (
                          <img
                            src={item.image}
                            className="margin-right15"
                            alt={item.value}
                            width="30"
                          />
                        )}
                      </td>
                      <td className="body_table_cell">
                        <input
                          type="text"
                          className="inputformmodel"
                          value={item.value}
                          onChange={(e) => onFieldChange(e.target.value, item)}
                        />
                      </td>
                      <td className="body_table_cell">{item.languageCode}</td>
                      <td className="body_table_cell">
                        <button
                          type="button"
                          className="grid_btn_1"
                          onClick={(event) => imageUpload(event, item)}
                        >
                          Upload
                        </button>
                      </td>
                      <td className="body_table_cell">
                        {/* <button type="button" className="link" onClick={() => onEdit(item)}>Edit</button> */}
                        <button
                          type="button"
                          className="link"
                          onClick={() => OnDelete(item)}
                        >
                          Delete
                        </button>
                        {/* <button type="button" className="link" onClick={() => OnView(item)}>View</button> */}
                        <button
                          type="button"
                          className="link"
                          onClick={() => OnAdd(item)}
                        >
                          Edit
                        </button>
                      </td>
                    </tr>
                  </SortableItem>
                );
              })}
            </tbody>
          </table>
        </SortableList>
        <ToastContainer className="ToastCustom" />
      </div>
    </>
  );
};
