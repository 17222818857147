import { useState } from "react"

export const CopyPaste = ({ ...props }) => {
    const [disabledCopy, setDisabledCopy] = useState(false);
    const { copyPasteFN ,Placeholder ,TotalPaste ,Name} = props;
    return (
        <div className="bx-pad bx-pad b_top">
            <div className="formmodel-bx nomargin">
                <label className="_l_able_cls">Copy Paste  <span className="fright">
                    <strong>To be able to copy paste contacts you have to agree that you have {Name} Opt-in consent. <strong className="link inline-block agreeLink pointer">I Agree</strong> &nbsp;</strong>
                    <input type="checkbox" className="vertical-middle grid_btn_1 margin-right15" checked={disabledCopy} onChange={() => setDisabledCopy(!disabledCopy)} /></span></label>
                <div className={`copy_paste_container  drop-zone ${!disabledCopy?'copy_paste_cover':''}`}>
                    <textarea rows="" cols="" disabled={!disabledCopy} placeholder={Placeholder} onChange={copyPasteFN} className="inputformmodel width100 textareaModel"></textarea>
                </div>
                <p style={{ color: '#b4b4b4', fontSize: '13px', marginTop: '5px' }}><span>Maximum record paste 14k</span><span className="fright" style={{ color: '#3aac69' }}>Total record pasted: <span className="tototalRecrodPaste">{TotalPaste}</span></span></p>
            </div>
        </div>
    )
}