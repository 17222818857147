import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Client from "../../../../GlobalAPICall";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import GridAggregatedContainer from './GridAggregatedContainer'
import BestDay from "./BestDay";
import BestTime from './BestTime';
import InsightBlank from "../../../InsightBlank";
import LoaderBox from "../../../../ReuseTemplate/LoaderBox";
const EmailKPI = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataAggrated, setdataAggrated] = useState({
        KPI: [],
        campaignName: "",
        isDisplay: true
    });
    const { id } = useParams();
    let [isPinned, setIsPinned] = useState([]);
    const filterPinnedFN = res => {
        const filter = [];
        try {
            for (let v of res) {
                if (v.IsPinned) {
                    filter.push(v);
                }
            }
        } catch (err) {
            console.error(err);
        }
        return filter;
    }
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                await Client.get(`Insights/distribution/Email/${id}/KPI?tabId=${tabId}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then((res) => {
                    try {
                        setIsPinned(filterPinnedFN(res['data'].KPI));
                        setdataAggrated({
                            KPI: res['data'].KPI,
                            campaignName: res['data'].campaignName,
                            isDisplay: false
                        });

                    } catch (err) {
                        console.log(err);
                    }
                });
        } catch (err) {
            console.log(err);
        } finally {
            //console.log(false); // <-- set loading false when done no matter what
        }
    }, [APITOKEN, tabId, id]);

    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])


    const onSortEnd = (oldIndex, newIndex) => {
        setdataAggrated((array) => {
            return { ...dataAggrated, KPI: arrayMoveImmutable(array['KPI'], oldIndex, newIndex) }
        })
    }
    const pinMethod = (e) => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (e['IsPinned']) {
                response = Client.delete(`Insights/distribution/Email/${id}/KPI/Pin/${e['Id']}`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            } else {
                response = Client.put(`Insights/distribution/Email/${id}/KPI/Pin/${e['Id']}`, {}, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                });
            }
            response.then((res) => {
                res['data'][0]['Status'] &&
                    loadApiCall();
            });
        }
    }
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Overview - <span className="overviewtitle">{dataAggrated.campaignName}</span></h3></div><div className={`white ${dataAggrated.isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''}`}><div className=" white  ">{dataAggrated.isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataAggrated['KPI'] && dataAggrated['KPI'].map((v, i) => {

            return v['IsPinned'] && <SortableItem key={i}>
                <div className="bx-boder-common bx detailsbx white" style={{ display: (v['IsPinned']) ? 'inline-block' : 'none', border: `2px solid ${(v['KPIBasedGoalAchieved'] === 1) ? v['KPIBasedGoalBorderColor'] : 'none'}` }} ><div className="drop-zone"><button className="pin" type="button" style={{ fontSize: "19px" }} onClick={e => pinMethod(v)} ><i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'none' : 'inline-block' }}></i><i style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'inline-block' : 'none', color: (v['KPIBasedGoalAchieved']) ? v['KPIBasedGoalRateOrQuantityColor'] : '' }} className="fas fa-trophy"></i></button><h3 className="title">{v['Name']}</h3><p className="content paddingbottom20">{v['Description']}</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr style={{ visibility: (v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['CountLabel']}</td><td className=" -tvl"><strong style={{ color: v['PinCountColor'] }}>{v['Count']}</strong></td></tr><tr style={{ visibility: (v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['RateLabel']}</td><td className="-tvl link"><strong style={{ color: v['PinRateColor'] }}>{v['Rate']}%</strong></td></tr></tbody></table></div></div>
            </SortableItem>
        })}</SortableList>}</div><div className="-n-d-p"></div></div></div> <div className="overviewDaychart margin-top50 bx-boder-common white"><div className="chartharf  padding-right50" style={{ "width": "51%" }}><BestDay id={id} /></div><div className="chartharf padding-right50" style={{ "width": "49%" }}><BestTime id={id} /></div></div><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>Email Sending Insights</h3></div><div className="aggreategrid"><GridAggregatedContainer tabId={tabId} dataAggrated={dataAggrated} onClick={e => pinMethod(e)} /></div></div></>
    )
}

export default EmailKPI;





