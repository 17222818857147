import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import Client from "../../../GlobalAPICall";
import { useSelector } from "react-redux";
import BlankData from "../../../BlankData";
import * as filestack from "filestack-js";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";
import Tags from "../Tags";
import Languages from "./Languages";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import SortableOrder from "../SortableOrder";
import { ToastContainer, toast } from "react-toastify";

const url = process.env;
const client = filestack.init(url.REACT_APP_FILE_PICKER_API_KEY);
const Track = () => {
  const { APITOKEN } = useSelector((state) => state);
  const [lang, SetLang] = useState({
    data: [],
    Speaker: [],
  });
  const [pushNotification, setPushNoty] = useState({
    value: "",
    notificationText: "",
    shareType: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [speakerSpeeches, setSpeakerSpeeches] = useState([]);
  const [trackData, setTrackArray] = useState([]);
  const [isLoader, setIsLoader] = useState(false);
  const [objectArray, setObject] = useState({
    trackId: 0,
    speakerId: "",
    speechId: "",
    trackNumber: "",
    chapterName: "",
    lengthOfTrack: "",
    trackUrl: "",
    fileName: "",
    languageCode: "",
    searchTags: [],
  });
  const onSpeechSpeakder = (ev) => {
    const cancelToken = axios.CancelToken.source();
    Client.get(
      `Mobile/SpeechsBySpeakerId?languageCode=${
        objectArray.languageCode
      }&speakerId=${parseInt(ev)}`,
      {
        cancelToken: cancelToken.token,
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(({ data }) => {
        try {
          setSpeakerSpeeches(data);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          cancelToken.cancel();
        }
      });
  };
  // Onchange get track data //
  const trackGrid = (ev) => {
    if (ev !== "") {
      trackAPI(ev);
      // setObject(prv => ({
      //     ...prv,
      //     speechId: ev
      // }));
    } else {
      setObject((prv) => ({
        ...prv,
        speechId: "",
      }));
      setTrackArray([]);
    }
  };
  // Track API Call //
  const trackAPI = (ev) => {
    Client.get(
      `Mobile/TracksBySpeechId?languageCode=${objectArray.languageCode}&speechId=${ev}`
    )
      .then(({ data }) => {
        try {
          setTrackArray(data);
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        throw err;
      });
  };

  const onChangeFn = (newValue) => {
    setObject((prv) => ({
      ...prv,
      languageCode: newValue,
    }));
    speakerAPI(newValue);
  };
  const speakerAPI = (newValue) => {
    const cancelToken = axios.CancelToken.source();
    Client.get(`Mobile/Speakers?languageCode=${newValue}`, {
      cancelToken: cancelToken.token,
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then(({ data }) => {
        try {
          SetLang((prv) => ({
            ...prv,
            Speaker: data,
          }));
        } catch (err) {
          console.error(err);
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          cancelToken.cancel();
        }
      });
  };

  const load = useCallback(async () => {
    if (APITOKEN["token"] !== "") {
      const { data } = await Languages(APITOKEN);
      try {
        if (data.length > 0) {
          SetLang((prv) => ({
            ...prv,
            data,
          }));
        }
      } catch (err) {
        console.error(err);
      }
    }
    return () => {
      SetLang({
        data: [],
        Speaker: [],
      });
    };
  }, [APITOKEN]);

  const restFn = () => {
    setSpeakerSpeeches([]);
    setObject((prv) => ({
      ...prv,
      trackId: 0,
      speakerId: "",
      speechId: "",
      trackNumber: "",
      chapterName: "",
      lengthOfTrack: "",
      trackUrl: "",
      fileName: "",
      languageCode: "",
      searchTags: [],
    }));
    speakerAPI("");
    setTrackArray([]);
    setPushNoty({ value: "", notificationText: "", shareType: "" });
  };
  const Save = () => {
    setIsLoader(true);
    const PostObjectAppend = {
      ...objectArray,
      pushNotificationType: parseInt(pushNotification.value),
      pushNotificationText: pushNotification.notificationText,
      shareType: pushNotification.shareType,
    };
    Client.post(`Mobile/Tracks`, PostObjectAppend, {
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then(({ data }) => {
        setIsLoader(false);
        if (data.Success) {
          restFn();
        }
      })
      .catch((err) => setIsLoader(false));
  };
  // Speeche Delete API Call //
  function confirmDelete() {
    const splitString = deleteId.split("-");
    Client.delete(
      `Mobile/Tracks?speechId=${splitString[0]}&trackId=${splitString[1]}`,
      {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }
    )
      .then(({ data }) => {
        if (data.Success) {
          setDeleteId("");
          trackGrid(objectArray.speechId);
        }
      })
      .catch((err) => console.error(err));
  }
  function landDelete({ trackId, speechId }) {
    setDeleteId(`${speechId}-${trackId}`);
  }

  const onEdit = ({
    chapterName,
    speakerId,
    zZ_articleId,
    trackUrl,
    trackId,
    tags,
    pushNotificationType,
    pushNotificationText,
    shareWith,
  }) => {
    setObject((prv) => ({
      ...prv,
      chapterName,
      speakerId,
      zZ_articleId,
      trackUrl,
      trackId,
      searchTags: tags || [],
    }));
    setPushNoty((prv) => ({
      ...prv,
      value: pushNotificationType,
      notificationText: pushNotificationText,
      shareType: shareWith,
    }));
  };
  const onUploadSuccess = () => {
    const options = {
      maxFiles: 1,
      fromSources: ["local_file_system"],
      uploadInBackground: false,
      onOpen: () => console.log("opened!"),
      onUploadDone: ({ filesUploaded }) => {
        setObject((prv) => ({
          ...prv,
          trackUrl: filesUploaded[0].url,
          fileName: filesUploaded[0].originalFile.name,
        }));
      },
    };
    client.picker(options).open();
  };
  useEffect(() => {
    load();
  }, [load]);

  return (
    <>
      {deleteId === "" && (
        <div className="bx-boder-common white width100 inline-block">
          <div className="_h_header">
            <h3>Tracks</h3>
          </div>
          <div className="padding20">
            <div style={{ maxWidth: "492px" }}>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Select language</label>
                <select
                  className="inputformmodel"
                  value={objectArray.languageCode}
                  onChange={(e) => onChangeFn(e.target.value)}
                >
                  <option value="">Select</option>
                  {lang.data.map((v, i) => (
                    <option value={v.LanguageCode} key={i}>
                      {v.Value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Select Speaker</label>
                <select
                  className="inputformmodel"
                  value={objectArray.speakerId}
                  onChange={(e) => {
                    setObject((prv) => ({
                      ...prv,
                      speakerId: e.target.value,
                    }));
                    onSpeechSpeakder(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {lang.Speaker.map((v, i) => (
                    <option value={v.speakerId} key={i}>
                      {v.speakerName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Select Speech</label>
                <select
                  className="inputformmodel"
                  value={objectArray.speechId}
                  onChange={(e) => {
                    setObject((prv) => ({
                      ...prv,
                      speechId: e.target.value,
                    }));
                    trackGrid(e.target.value);
                  }}
                >
                  <option value="">Select</option>
                  {speakerSpeeches.map((v, i) => (
                    <option value={v.speechId} key={i}>
                      {v.title}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Chapter Name</label>
                <input
                  type="text"
                  className="inputformmodel"
                  placeholder="Name"
                  value={objectArray.chapterName}
                  onChange={(e) =>
                    setObject((prv) => ({
                      ...prv,
                      chapterName: e.target.value,
                    }))
                  }
                />
              </div>
              {/* Tag Component */}
              <Tags Set={setObject} Get={objectArray} />
              {/* End Tag Component */}
              <div className="formmodel-bx">
                <label className="_l_able_cls">Push Notification</label>
                <select
                  className="inputformmodel"
                  value={pushNotification.value}
                  onChange={(event) =>
                    setPushNoty((prv) => ({
                      ...prv,
                      value: event.target.value,
                      notificationText: "",
                    }))
                  }
                >
                  <option value="">Select</option>
                  <option value="1">With Push Notification</option>
                  <option value="2">Without Push Notification</option>
                </select>
              </div>
              {parseInt(pushNotification.value) === 1 ? (
                <div className="formmodel-bx">
                  <label className="_l_able_cls">Push Notification Text</label>
                  <textarea
                    className="inputformmodel textareaModel"
                    value={pushNotification.notificationText}
                    placeholder="Push Notification Text"
                    onChange={(event) =>
                      setPushNoty((prv) => ({
                        ...prv,
                        notificationText: event.target.value,
                      }))
                    }
                  ></textarea>
                </div>
              ) : (
                <></>
              )}
              <div className="formmodel-bx">
                <label className="_l_able_cls">Share</label>
                <select
                  className="inputformmodel"
                  value={pushNotification.shareType}
                  onChange={(event) =>
                    setPushNoty((prv) => ({
                      ...prv,
                      shareType: event.target.value,
                    }))
                  }
                >
                  <option value="">Select</option>
                  <option value="Internal">Internal</option>
                  <option value="External">External</option>
                  <option value="Both">Both</option>
                </select>
              </div>

              <div className="formmodel-bx">
                <label className="_l_able_cls">Audio Upload</label>
                <button
                  type="button"
                  className="grid_btn_1"
                  onClick={onUploadSuccess}
                >
                  Upload File
                </button>
                {objectArray.fileName !== "" && (
                  <strong>{objectArray.fileName}</strong>
                )}
              </div>
            </div>
            <div className="formmodel-bx text-right">
              <button
                type="button"
                className="grid_btn_1 nobg noborder"
                onClick={restFn}
              >
                Cancel
              </button>
              <button
                type="button"
                className="grid_btn_1 grid_btn_active"
                disabled={
                  (
                    objectArray.speakerId !== "" &&
                    objectArray.speechId !== "" &&
                    objectArray.chapterName !== "" &&
                    objectArray.trackUrl !== "" &&
                    pushNotification.shareType !== "" &&
                    pushNotification.value !== ""
                      ? pushNotification.value === "1"
                        ? pushNotification.notificationText !== ""
                          ? true
                          : false
                        : true
                      : false
                  )
                    ? false
                    : true
                }
                onClick={Save}
              >
                <ButtonLoader IsLoader={isLoader}>
                  {objectArray.trackId !== 0 ? "Update" : "Save"}
                </ButtonLoader>
              </button>
            </div>
          </div>
          {trackData.length > 0 ? (
            <GridLanguage
              GridData={trackData}
              OnDelete={landDelete}
              onEdit={onEdit}
              setGrid={setTrackArray}
              selectedValue={objectArray}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      {trackData.length > 0 ? (
        <>
          {deleteId !== "" ? (
            <div className="bx-boder-common white width100 inline-block">
              {" "}
              <div className="_h_header">
                <h3>Delete</h3>
              </div>
              <table cellPadding="0" cellSpacing="0" width="100%">
                <tbody>
                  <BlankData
                    title="Are you sure you want to delete this track?"
                    content=""
                  />
                </tbody>
              </table>
              <div className="text-right padding20">
                <button
                  className="grid_btn_1 noborder nobg"
                  onClick={() => setDeleteId("")}
                  type="button"
                >
                  Cancel
                </button>
                <button
                  className="grid_btn_1 deleteBtn"
                  onClick={confirmDelete}
                  type="button"
                >
                  Confirm
                </button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default Track;

function GridLanguage({ GridData, OnDelete, onEdit, setGrid, selectedValue }) {
  const onSortEnd = async (oldIndex, newIndex) => {
    const reorder = arrayMoveImmutable(GridData, oldIndex, newIndex);
    const postObject = reorder.map(({ trackId }, index) => ({
      id: trackId,
      order: index + 1,
    }));
    setGrid((array) => arrayMoveImmutable(array, oldIndex, newIndex));

    const { data } = await SortableOrder(
      {
        Id: selectedValue.speechId,
        Type: "Audio",
        Code: selectedValue.languageCode,
      },
      postObject
    );
    if (data.Success) {
      toast.success("Save successfully", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  return (
    <div className="b_top">
      <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
        <table cellPadding="0" cellSpacing="0" className="smstable ">
          <thead>
            <tr>
              <th className="table_cell_header">Title</th>
              <th className="table_cell_header">Chapter Name</th>
              <th className="table_cell_header">Length Of Track</th>

              <th className="table_cell_header">Actions</th>
            </tr>
          </thead>

          <tbody>
            {GridData &&
              GridData.map((item, index) => {
                return (
                  <SortableItem key={index}>
                    <tr>
                      <td className="body_table_cell">
                        {item.trackNumber} - {item.speechTitle}
                      </td>
                      <td className="body_table_cell">{item.chapterName}</td>
                      <td className="body_table_cell">{item.lengthOfTrack}</td>
                      <td className="body_table_cell">
                        <button
                          type="button"
                          className="link"
                          onClick={() => onEdit(item)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="link"
                          onClick={() => OnDelete(item)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  </SortableItem>
                );
              })}
          </tbody>
        </table>
      </SortableList>
      <ToastContainer className="ToastCustom" />
    </div>
  );
}
