
// import BestDay from "./BestDay";
// import BestTime from "./BestTime";
import GridAggregated from "./GridAggregated";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import Client from "../../../../GlobalAPICall";
import InsightBlank from "../../../InsightBlank";
import TrafficeSource from './TrafficeSource'
import { useParams } from "react-router";
import LoaderBox from "../../../../ReuseTemplate/LoaderBox";
import CompleteDetails from "../CompleteDetails";
const LandingKPI = ({ tabId }) => {
    const { APITOKEN, InsightSMSDetails } = useSelector(state => state);
    const [dataPin, setdataPin] = useState([]);
    let [isPinned, setIsPinned] = useState([]);
    let [isDisplay, setDisplay] = useState(true);
    const [isDisplayComplete, setDetailsDisplay] = useState({ flag: false, object: {} });
    const showCompleteDetails = (childObject) => {
        setDetailsDisplay(prv => ({ ...prv, flag: true, object: childObject }));
    }
    const { id } = useParams();
    const loadApiCall = useCallback(async () => {
        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/SMS/${id}/KPI?tabId=${tabId}`)
                    .then(({ data }) => {
                        setDisplay(false);
                        try {
                            setdataPin(data.KPI || []);
                            setIsPinned(data.filter(item => item.IsPinned));

                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        throw err;
                    });
        } catch (err) {
            console.log(err);
        }
    }, [APITOKEN, id, tabId]); // <-- add any missing dependencies react warns about


    const onSortEnd = (oldIndex, newIndex) => {
        setdataPin((array) => arrayMoveImmutable(array, oldIndex, newIndex))
    }
    const pinMethod = item => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (item['IsPinned']) {
                response = Client.delete(`Insights/distribution/SMS/00000000-0000-0000-0000-000000000000/KPI/Pin/${item['Id']}`);
            } else {
                response = Client.put(`Insights/distribution/SMS/00000000-0000-0000-0000-000000000000/KPI/Pin/${item['Id']}`, {});
            }
            response.then(({ data }) => {
                data[0]['Status'] &&
                    loadApiCall();
            });
        }
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Landing Page KPIs for - {InsightSMSDetails.name}</h3><p className="content"></p></div><div className={`${isDisplay ? '' : isPinned.length === 0 ? 'padding20' : ''} white`}><div className="white">{isDisplay ? <div className="_overviewPin summary_o_v"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataPin.map((v, i) => {
            return v.IsPinned &&
                <SortableItem key={i}>
                    <div className="bx-boder-common bx white detailsbx" style={{ "display": v['IsPinned'] ? 'inline-block' : 'none' }}><div className="drop-zone"><button type="button" className="pin" style={{ "fontSize": "19px" }} onClick={e => pinMethod(v)}><i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} data-id={v['Id']}></i></button><h3 className="title">{v['Name']}</h3><p className="content paddingbottom20">{v['PinnedDescription']}</p></div><div className="px"><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr><td style={{ visibility: (v['CountLabel'] == null || v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['CountLabel']}</td><td className="-tvl" ><strong style={{ "color": v['CountColor'], "visibility": (v['CountLabel'] === null || v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['Count']}</strong></td></tr><tr><td style={{ "visibility": (v['RateLabel'] === null || v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['RateLabel']}</td><td className="-tvl link"><strong style={{ "color": v['RateColor'], "visibility": (v['RateLabel'] === null || v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}>{v['Rate']}%</strong></td></tr></tbody></table></div></div></div>
                </SortableItem>
        })}</SortableList>
        }</div><div className="-n-d-p"></div></div></div> <TrafficeSource /><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>SMS Sending Landing Page Insights</h3></div><div className="aggreategrid">
            <GridAggregated dataPin={dataPin} tabId={tabId} onClick={e => pinMethod(e)} IsDisplay={isDisplay} showCompleteDetails={showCompleteDetails} /></div></div>
            {isDisplayComplete.flag ? <CompleteDetails ClosePopup={setDetailsDisplay} Value={isDisplayComplete} TabId={tabId} /> : <></>}
        </>
    )
}

export default LandingKPI;








