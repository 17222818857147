import { useState } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import solidGauge from "highcharts-solid-gauge";
import highchartsMore from "highcharts/highcharts-more";

highchartsMore(Highcharts);
solidGauge(Highcharts);
const Submitted = (props) => {
    const { Data } = props;
    const [options] = useState({
        chart: {
            type: 'solidgauge'
        },
        title: null,
        pane: {
            center: ['50%', '35%'],
            size: '70%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
        accessibility: {
            enabled: false
        },
        yAxis: {
            min: 0,
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            gridLineColor: 'transparent',
            lineColor: 'transparent',
            tickPositions: [0,Data['TotalRecipients']],
            max: Data['TotalRecipients'],
            title: {
                y: -70,
                text: 'Soft Bounce'
            },
            labels: {
                y: 16,
            }
        },


        credits: {
            enabled: false
        },
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        series: [{
            name: 'Soft Bounce',
            data: [{ y: Data['KPI'][1].Count, color: '#ffcc44' }],
            dataLabels: {
                format: '<div style="text-align:center">' +
                    '<span style="font-size:25px"></span><br/>' +
                    '<span style="font-size:12px;opacity:0.4"></span>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: ''
            }
        }]
    });
    return (
        <div className="text-center">
            <HighchartsReact highcharts={Highcharts} options={options} />
            <div className="g-txt grph-bx-wrappper"><div className="bx-boder-common bx-p detailsbx"><h3 className="title">{Data['KPI'][1].Name}</h3><p className="content paddingbottom20">{Data['KPI'][1].PinnedDescription}</p><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr><td>Status</td><td className="link -tvl"><strong className="pointer completeDetails">{Data['KPI'][1].Count}</strong></td></tr><tr><td>Status Rate</td><td className="-tvl link"><strong>{Data['KPI'][1].Rate}%</strong></td></tr></tbody></table></div></div>
            </div>
        </div>
    );
}
export default Submitted;