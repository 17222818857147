import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import Client from '../../../GlobalAPICall';
import BreadCrum from '../../../ReuseTemplate/BreadCrum';
import { useNavigate, useParams } from 'react-router';
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';
import getParameterByName from '../../../ReuseTemplate/GetRoute';

export default function CreateJavascript() {
    const text = "<script> tag";
    const [IsLoader, setIsLoader] = useState(false);
    const { id } = useParams();
    const { APITOKEN } = useSelector(state => state);
    const [typeJavascript, setJavascriptType] = useState([]);
    const [isEdit] = useState(getParameterByName('edit', window.location.href));
    const [value, setVal] = useState({});
    const navigate = useNavigate();
    const typeLoad = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Lookup/JavaScriptPlacement', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        setJavascriptType(data);
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN]);


    const saveFN = () => {
        setIsLoader(true);
        const obj = {
            "placementId": parseInt(value.placeholder),
            "customJavaScript": value.script,
            "id": isEdit !== null?id:0,
            "name": value.name
        };
        if (isEdit !== null) {
            Put(obj);
        } else {
            Post(obj);
        }
       
    }
    const Put = (obj) => {
        Client.put(`/Lookup/CustomJavaScript`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsLoader(true);
                try {
                    if (data.success)
                        navigate('/customjavascript?type=97')
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => setIsLoader(true));
    };
    const Post = (obj) => {
        Client.post(`/Lookup/CustomJavaScript`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsLoader(true);
                try {
                    if (data.success)
                        navigate('/customjavascript?type=97')
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => setIsLoader(true));
    };

    const editScript = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('/Lookup/CustomJavaScript', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(({ data }) => {
                    try {
                        const { javascriptMetaData, name ,...s } = data.filter((item) => item.id === parseInt(id))[0];
                        setVal({
                            name,
                            placeholder: s.id,
                            script:javascriptMetaData
                        })
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => console.error(err));
    }, [APITOKEN,id]);

    useEffect(() => {
        typeLoad();
        if (isEdit!== null) {
            editScript()
        }
    }, [typeLoad,editScript,isEdit])

    return (
        <>
            <div className="dashbd_sec margin-top50">&nbsp;</div>

            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/customjavascript?type=97" ToText="Custom Javascript" CurrentPage="Create" />
            </div>
            <div className='dashbd_sec  margin-bottom'>

                <div className='sms_Sec_1 bx-boder-common width100'>
                    <div className='_h_header'>
                        <h3>Create</h3>
                    </div>
                    <div className='padding20'>
                        <div className='sms_Sec_1 nomargin'>
                            <div className='formmodel-bx'>
                                <label className='_l_able_cls'>Name</label>
                                <input type='text' value={value.name} className='inputformmodel' onChange={event => setVal(prv => ({ ...prv, name: event.target.value }))} placeholder='Name' />
                            </div>
                            <div className='formmodel-bx'>
                                <label className='_l_able_cls'>Placement</label>
                                <select className='inputformmodel' value={value.placeholder} onChange={event => setVal(prv => ({ ...prv, placeholder: event.target.value }))}>
                                    <option value="">Select</option>
                                    {typeJavascript.map(item => <option value={item.id}>{item.name}</option>)}
                                </select>
                            </div>
                        </div>
                        <div className='formmodel-bx'>
                            <label className='_l_able_cls'>Paste or type script below. {text}</label>
                            <textarea className='inputformmodel textareaModel width100'
                                value={value.script}
                                onChange={event => setVal(prv => ({ ...prv, script: event.target.value }))} style={{ height: '400px' }}></textarea>
                        </div>
                        <div className='formmodel-bx text-right nomargin'>
                            <button type='button' className='grid_btn_1 noborder nobg' onClick={() => navigate('/customjavascript?type=97')}>Cancel</button>
                            <button type='button' className='grid_btn_1 grid_btn_active'
                                disabled={(!!value.name && !!value.placeholder && !!value.script) ? false : true}
                                onClick={saveFN}>
                                <ButtonLoader IsLoader={IsLoader}>
                                    {isEdit !== null?'Update':'Save'}
                                </ButtonLoader></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
