
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import BlankData from '../../BlankData';
import Client from '../../GlobalAPICall';
import getLocaleDateString from '../../TimeFormat';
import moment from 'moment';

const Tabing = ({ data }) => {
    const defaultId = 0;
    const [tabId, setTabid] = useState(defaultId);
    const clickmethod = (item) => {
        setTabid(item.id);
    }
    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => clickmethod(item)} ><Link className={item.id === tabId ? 'active ' : ' '} id={item.id}>{item.name}</Link></li>
                    })}
                </ul>
            </div>
            {
                <div>{tabId === defaultId ? <FolderTab /> : <>No data found</>}
                </div>
            }
        </>
    )
}

export default Tabing;
const FolderTab = () => {
    const { APITOKEN } = useSelector(state => state);
    const [folderData, setFolderData] = useState([]);
    const [deleteId, setId] = useState({
        flag: false,
        id: ''
    });
    const navigate = useNavigate();
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Insights/landing_page/goals`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setFolderData(res['data']);
            });
    }, [APITOKEN]);
    const deleteConfirm = item => {
        setId(prv => ({
            ...prv,
            flag: true,
            id: item.Id
        }));
    }
    const deleteConfirmed = () => {
        Client.delete(`Campaign/${deleteId.id}`,  {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']

            }
        })
            .then(res => {
                try {
                    if(res.data.Success){
                        load();
                    setId(prv => ({
                        ...prv,
                        flag: false,
                        id: ""
                    }));
                }
                } catch (err) {
                    console.error(err);
                }

            }).catch(err => {
                console.log(err);
            })
    }
    useEffect(()=>{
        load();
    },[load])
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            {!deleteId.flag ? <>
                <div className="_h_header border-radius2">
                    <h3>Goals</h3>
                    <span className="flex-right">
                        <button type="button" className="grid_btn_1 margin-right15" onClick={()=> navigate('/create/templates/goalTemplate')}>Goal Templates</button>
                        <button
                            type="button"
                            className="grid_btn_1 grid_btn_active"
                            onClick={() => navigate('/createContent/22')}>Create Goal</button>
                    </span>
                </div>
                <div className="_list_fx">
                    <table className="smstable white" cellPadding="0" cellSpacing="0">
                        {folderData.length === 0 ? '' : <thead>
                            <tr>
                                <th className="table_cell_header">Name</th>
                                <th className="table_cell_header">Description</th>
                                <th className="table_cell_header">End Date</th>
                                <th className="table_cell_header">Actions</th>
                            </tr>
                        </thead>
                        }
                        <tbody>
                            {folderData.length === 0 ? <BlankData title="You have not created any Goals yet." colSpan="5" content="Create Goals to easily measure and visualize the success of your campaigns and contacts build." /> : folderData.map((item, i) => <Grid APITOKEN={APITOKEN} item={item} key={i} Confirm={deleteConfirm} />)}
                        </tbody>

                    </table>
                </div>

            </> : <><div className="_h_header"><h3>Delete Template</h3></div><table cellPadding="0" cellSpacing="0" width="100%" className="smstable">
                <tbody>
                    <BlankData title="Do you want Delete Template." content="Connect it to give your landing pages and website a more professional look." colSpan="5" />
                </tbody>
            </table> <div className="text-right padding20">
                <button type="button" 
                onClick={()=> setId(prv => ({
                        ...prv,
                        flag: false,
                        id: ""
                    }))} className="grid_btn_1 nobg noborder">Cancel</button> <button type="button" className="grid_btn_1  deleteBtn" onClick={deleteConfirmed}>Confirm</button></div></>}</div>
    </>)
}
const Grid = ({ item, APITOKEN, Confirm }) => {

    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const navigate = useNavigate();

    return <><tr><td className="body_table_cell excludeClass">{item.Name}</td>
        <td className="body_table_cell">{item.GoalPurpose}</td>
        <td className="body_table_cell">{moment(item.GoalEndDate).format(f)}</td>
        <td className="body_table_cell">
            <button type="button" className="link inline-block utm_tem_delet" onClick={() => navigate(`/create/createGoalActivity?cam=${item.Id}&edit=true`)}>Edit</button>&nbsp; |
            &nbsp; <button type="button" className="link inline-block" onClick={() => Confirm(item)}>Delete</button></td>
    </tr></>
}