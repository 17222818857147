
const LoaderBox = ({...props}) => {
    const {RepeatArray} = props;
    return (
        <> 
        {RepeatArray.map((v,i)=>
        <div className="skeleton_overview_section bx-boder-common bx" key={i}><div className="drop-zone"><span className="skeleton-loader" style={{width:'48%'}}></span><div className="content paddingbottom20"><span className="skeleton-loader" style={{width:'90%',marginTop: '10px'}}
        ></span></div><div className=""><div className="drop-zone"><span className="skeleton-loader" style={{width:'48%',marginTtop: '10px'}}></span><div className="content paddingbottom20 noborder"><span className="skeleton-loader" style={{width:'90%',marginTop: '10px',
        }}></span></div></div></div></div></div>
        )}</>
    )

}

export default LoaderBox
