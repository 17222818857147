import axios from "axios";
import { get as _get } from "lodash";
import { store } from "../Redux/Store";

// Create a new axios client with default settings
const apiAllCall = () => {

  const url = process.env;
  let apiURL = url.REACT_APP_API_URL;

  const storeData = store.getState();
  const token = _get(storeData, "APITOKEN.token", "");

  const apiAllCall = axios.create({
    baseURL: apiURL,
    headers: {
      is_calling_from_react_app: true,
      "Authorization": `Bearer ${token}`,
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  // Add an interceptor to modify the request before it is sent
  apiAllCall.interceptors.response.use(
    (response) => { return response },
    (error) => {
      //const statusCode = _get(error, "response.status", "");

      //if (statusCode === 401) store.dispatch(deleteOauthToken());

      return Promise.reject(error);
    }
  );

  return apiAllCall;
}

export default apiAllCall;