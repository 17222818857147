import React, { /*useEffect,*/ useState, useCallback } from 'react';
import { get as _get } from "lodash";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useDropzone } from 'react-dropzone';

import { importContacts, subscribersCreateImport } from "../../../../services/crm";
import { saveUploadedContact, createImportSaveData } from "../../../../Redux/actions/ActionImportContacts";

const UploadContacts = ({ sendDataToParent }) => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from UR

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  //const [selectedFile, setSelectedFile] = useState({ file: {}, progress: 1 });

  const onDrop = useCallback((acceptedFiles) => {

    const selectedFile = acceptedFiles[0];

    //setSelectedFile({ file: selectedFile, progress: 1 });

    (async () => {
      try {
        let data = new FormData();
        data.append("formfile", selectedFile);

        const response = await importContacts(data, ({ progress = 0 }) => setProgress(progress));

        if ((response.flag || false) === true) {

          // Save Uploaded Contact File response in Redux
          dispatch(saveUploadedContact(_get(response, "data", {})));

          const payload = {
            FileName: _get(response, "data.fileName", ""),
            fileSourceTypeId: _get(response, "data.fileSourceTypeId", 0),
            importSourceTypeId: _get(response, "data.importSourceTypeId", 0),
            importTypeId: 1,
            fileSource: JSON.stringify(_get(response, "data.fileSource", {}))
          }
          const resImport = await subscribersCreateImport(listID, payload);
          // Save Create Import Contact File response in Redux
          dispatch(createImportSaveData(_get(resImport, "data", {})));

          sendDataToParent("preview");
        }

      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  }, [dispatch, sendDataToParent, listID]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
    multiple: false,
    maxFiles: 1,
  });

  const onCancel = () => {
    dispatch(saveUploadedContact({}));

    navigate("/crm/people/listings");
  }

  return (
    <>
      <div className="bx-boder-common nobg my-12">
        <div className="_h_header white border-radius2">
          <h3>Upload File
            <p>Please use headers in the imported file for better results.</p>
          </h3>
        </div>

        <div className="white">
          <div className="step-upload">
            <div className="step-upload-text">
              <p> Click on the icon or Drag and Drop the CSV file from your computer to upload it. </p>
              <p> If you are using Excel select "Save as" and use the file format UTF-8 (Comma-delimited) (.csv) </p>
            </div>

            <div className="step-upload-progress" style={{ width: `${(progress || 0)}%` }}></div>

            <div {...getRootProps()}>
              <div className="step-upload-file">
                <i className="fal fa-cloud-upload" aria-hidden="true"></i>
                <input {...getInputProps()} />
              </div>
            </div>

            <div className="p-5 b_top text-right">
              <button className="btn link-light" onClick={() => onCancel()}> Cancel </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UploadContacts;