import React, { useState } from 'react'
import Cookies from 'js-cookie'
import bcrypt from "bcryptjs-react";


function TwoWayAuth({ data }) {
    const url = process.env;

    const [code, setCode] = useState("");
    const [responseAPI, setRespone] = useState({});
    const [rememberMe, setrememberMe] = useState(false);
    function onlyNumberKey(evt) {

        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }
    const verify = () => {
        try {
            fetch(`${url.REACT_APP_API_URL}/Authenticate/verifyCode?secretKey=${data.client_secret}&code=${code}&hasRememberme=${rememberMe}`, {
                "method": 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                body: JSON.stringify()
            })
                .then(async (response) => await response.json())
                .then(async (res) => {
                    if (res.hasOwnProperty('client_secret')) {
                        setRespone({});
                        setCode("");
                        if (rememberMe) {
                            const hash = await bcrypt.hashSync(res.client_id, 11);
                            await Cookies.set('lc_r_me_mfa_c', hash, { expires: 30, path: '/', secure: true });
                        }
                        await Cookies.set('secretKey', res.client_secret, { path: '/' });
                        window.location.replace('/onboarding');
                    } else {
                        setRespone(res);
                    }

                })
        } catch (err) {

        }
    }
    const onHandler = event => {
        const isNAN = onlyNumberKey(event);
        setCode(isNAN ? event.target.value : "")
    }
    return (
        <div>
            <div className="section-title-wrap">
                <h1 className="section-title">Two factor authentication
                    <p style={{ fontSize: '16px', paddingTop: '10px' }}>We have send an SMS with verification code to ********{data.last4number} </p>
                </h1>

            </div>
            <div className="login-middlwe-section">
                <div className="formmodel-bx nomargin" style={{ paddingTop: '20px' }}>
                    <label className="_l_able_cls font-19 font-Nunito">Code</label>
                    <input type="text" value={code} className="inputformmodel height-increase margin-right15" placeholder='Enter Code' onKeyUp={onHandler} onChange={onHandler} />
                    {responseAPI.status ? <></> : <p className='validation'>{responseAPI.message}</p>}
                </div>
                <div className="formmodel-bx">
                    <label className="_l_able_cls font-16 inline-block vertical-middle font-Nunito">
                        <input type="checkbox" className="vertical-middle inline-block" onChange={event => setrememberMe(event.target.checked)} /> &nbsp; Remember this device for 30 days
                    </label>


                </div>
                <div className="formmodel-bx text-right" style={{ paddingTop: '20px' }}>
                    <button type="button" disabled={code.length >= 4 ? false : true} className="btnLogin" onClick={verify}>Verify</button>
                </div>
            </div>

        </div>
    )
}

export default TwoWayAuth
