import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import "./index.css";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import NavbarConnect from "../../layout/Navbar";
import { useAppContext } from "../../contexts/AppContext";
import { ethers } from "ethers"; // or use `web3-validator` as mentioned above
import { toast } from "react-toastify";
import { FaWallet } from "react-icons/fa6";

const Profile = () => {
  const { APITOKEN } = useSelector((state) => state);
  const {
    newUserWalletAddress,
    setNewUserWalletAddress,
    updateWalletAddress,
    updateWallet,
  } = useAppContext();
  const userWalletAddress = APITOKEN?.config?.userWalletAddress;
  const userToken = APITOKEN.token;

  useEffect(() => {
    setNewUserWalletAddress(userWalletAddress);
  }, [userWalletAddress]);
  const [loader, setLoader] = useState(false);
  const [profileObject, setProfileObject] = useState({
    firstName: "",
    lastName: "",
    companyName: "",
    mobileNumber: "",
    countryName: "",
    email: "",
  });
  const [responseData, setSuccessResponse] = useState({});
  const [buttomDisabled, setButtonDisabled] = useState(true);
  useEffect(() => {
    APITOKEN["token"] !== "" &&
      Client.get("User/Profile", {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      }).then((res) => {
        try {
          const { userName } = res["data"];
          var lastName = ``;
          const getVlSplit = userName.split(" ");
          getVlSplit.forEach(function (v, i) {
            if (i > 0) {
              lastName += " " + v;
            }
          });
          res["data"].firstName = getVlSplit[0];
          res["data"].lastName = lastName;
          setProfileObject((prv) => ({
            ...prv,
            ...res["data"],
          }));
        } catch (err) {
          console.error(err);
        }
      });
  }, [APITOKEN]);
  const handleChange = (e) => {
    const newData = { ...profileObject };
    newData[e.target.name] = e.target.value;
    setProfileObject(newData);
    if (newData[e.target.name] !== "") {
      setButtonDisabled(false);
    }
  };
  const saveProfile = (event) => {
    event.preventDefault();
    const obj = {
      firstName: profileObject.firstName,
      lastName: profileObject.lastName,
      userName: `${profileObject.firstName} ${profileObject.lastName}`,
      companyName: profileObject.companyName,
    };
    setLoader(true);
    const headers = {
      Authorization: `Bearer ` + APITOKEN["token"],
      "Content-Type": "application/json",
    };
    setSuccessResponse({});
    try {
      Client.put(`User/profile`, obj, { headers })
        .then((res) => {
          try {
            setLoader(false);
            setSuccessResponse((prv) => ({
              ...prv,
              success: res["data"].success,
              message: res["data"].message,
            }));
            setButtonDisabled(true);
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => console.error(err));
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateWalletAddress = () => {
    if (!ethers.utils.isAddress(newUserWalletAddress)) {
      toast.error("Invalid wallet address");
      return;
    }

    updateWalletAddress(newUserWalletAddress, userToken);
  };

  return (
    <>
      <div className="bx-boder-common white width100 inline-block">
        <div className="_h_header">
          <h3>My Profile </h3>
        </div>
        <div
          className={`padding20 nomargin pb-0 text-left ${
            updateWallet ? "updateWallet" : ""
          }`}
        >
          <div className="WalletAddress" style={{ width: "492px" }}>
            {updateWallet && <FaWallet className="addWalletIcon" />}
            <label className="_l_able_cls">
              {" "}
              {updateWallet ? "Add New Wallet Address" : "Wallet Address"}
            </label>
            {!updateWallet && (
              <input
                type="text"
                className="inputformmodel"
                name="newAddresss"
                value={newUserWalletAddress}
                onChange={(e) => setNewUserWalletAddress(e.target.value)}
              />
            )}
            {updateWallet ? (
              <NavbarConnect updateWallet={updateWallet ? true : false} />
            ) : (
              <button
                className="UpdateWalletAddress"
                onClick={handleUpdateWalletAddress}
              >
                Update Wallet Address
              </button>
            )}
          </div>
        </div>
        <div className="padding20">
          <form>
            <div style={{ width: "492px" }}>
              <div className="formmodel-bx">
                <label className="_l_able_cls">First Name</label>
                <input
                  type="text"
                  onChange={handleChange}
                  className="inputformmodel"
                  name="firstName"
                  value={profileObject.firstName}
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Last Name</label>
                <input
                  type="text"
                  onChange={handleChange}
                  className="inputformmodel"
                  name="lastName"
                  value={profileObject.lastName}
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Company Name</label>
                <input
                  type="text"
                  onChange={handleChange}
                  className="inputformmodel"
                  name="companyName"
                  value={profileObject.companyName}
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Mobile</label>
                <span className="spanlabel">{profileObject.countryCode}</span>
                <input
                  type="text"
                  className="inputformmodel"
                  name="mobileNumber"
                  readOnly
                  value={profileObject.mobileNumber}
                  style={{ width: "calc(100% - 50px)", verticalAlign: "top" }}
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Country</label>
                <input
                  type="text"
                  className="inputformmodel"
                  name="countryName"
                  readOnly
                  value={profileObject.countryName}
                />
              </div>
              <div className="formmodel-bx">
                <label className="_l_able_cls">Email</label>
                <input
                  type="text"
                  className="inputformmodel"
                  name="email"
                  readOnly
                  value={profileObject.email}
                />
              </div>
            </div>
          </form>

          {responseData.success ? (
            <>
              <p className="success">{responseData.message}</p>
            </>
          ) : (
            <></>
          )}
        </div>
        <div className="padding20 nomargin text-right">
          <button
            type="button"
            className="grid_btn_1 grid_btn_active btn-gape-15"
            disabled={buttomDisabled}
            onClick={saveProfile}
          >
            <ButtonLoader IsLoader={loader}>Save</ButtonLoader>
          </button>
        </div>
      </div>
    </>
  );
};
export default Profile;
