import { useEffect, useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import Client from "../../../GlobalAPICall";
import ButtonLoader from "../../../ReuseTemplate/ButtonLoader";
import Select from 'react-select';

const Code = () => {
    const { APITOKEN } = useSelector(state => state);
    const [country, setCountryData] = useState({
        data: [],
        selectedCountry: [],
        selected: ""
    });
    const [mobileNumber, setMobileNumber] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const [isloader, setIsloader] = useState(false);
    const Filter = res => {
        const array = [];
        array.push(...res.map(x => ({ ...x, value: x.countryname, label: x.countryname })))
        return array;
    }
    const countryLoad = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Lookup/countries?viewAll=false`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    const { countryname ,countryCode} = data.filter(x => x['isOcountrycode'] === APITOKEN.config.countryCode)[0];
                    setCountryData({
                        data: Filter(data),
                        selected: {value:countryname,label:countryname,code:countryCode}
                    });
                } catch (err) {
                    console.log(err);
                }
            });
    }, [APITOKEN]);

    const codeSave = () => {
        setIsloader(true);
        Client.post(`/Mobile/${country.selected.code}/${mobileNumber}/SendVerificationCodeWithOutSMS`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsloader(false);
                try {
                    setApiResponse(data.message);
                    setMobileNumber("");
                } catch (err) {

                }


            }).catch(err => setIsloader(false));
    }

    const onChange = (newValue) => {
        setCountryData(prv => ({
            ...prv,
            selected: {value:newValue.countryname,label:newValue.countryname,code:newValue.countryCode}
        }))
    }
    useEffect(() => {
        countryLoad();
    }, [countryLoad])
    return (
        <>

            <div className="bx-boder-common white width100 inline-block">
                <div className="_h_header">
                    <h3>Activation Code</h3>
                </div>
                <div className="">
                    <div style={{ maxWidth: '492px' }}>
                        <div className="padding20">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Country Code</label>
                                <Select
                                    value={country.selected}
                                    name="language"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={onChange}
                                    options={country.data}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Mobile Number</label>
                                <input type="text" placeholder="Mobile Number" className="inputformmodel" value={mobileNumber} onChange={e => setMobileNumber(e.target.value.replace(/[^0-9]/g, ''))} />
                            </div>
                            {apiResponse !== "" && <p className="validation" style={{ color: '#059771' }}>{apiResponse}</p>}
                           
                        </div>
                        
                    </div>
                    <div className="padding20 text-right">
                                <button type="button" className="grid_btn_1 grid_btn_active" disabled={(mobileNumber !== "" && country.selected !== "") ? false : true} onClick={codeSave}>
                                    <ButtonLoader IsLoader={isloader}>Generate</ButtonLoader>
                                </button>
                            </div>
                </div>
            </div>
        </>
    )
}
export default Code;