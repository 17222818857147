import { useCallback, useEffect, useState } from "react";
import Client from "../GlobalAPICall";

const KPIFilter = ({ ...props }) => {
  const { Close, Load, APITOKEN } = props;
  const [recordObject, setdataRecord] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const hasAllKPIEnabled = (data) => {
    for (let v of data) {
      if (v["HasAllKPIEnabled"] === 1) {
        setSelectAll(true);
      } else {
        setSelectAll(false);
        break;
      }
    }
  };

  const loadApiCall = useCallback(async () => {
    try {
      APITOKEN["token"] !== "" &&
        Client.get(
          `Insights/dashboard/KPI/List?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
          {
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then((res) => {
            try {
              setdataRecord(res["data"]);
              hasAllKPIEnabled(res["data"]);
            } catch (err) {
              console.log(err);
            }
          })
          .catch((err) => {
            console.error(err);
          });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [APITOKEN]); // <-- add any missing dependencies react warns about

  const childCheckboxFn = (child, parent, flag) => {
    try {
      const url =
        flag === 0
          ? `Insights/dashboard/${parent.InsightType}/KPI/${
              parent.HasAllKPIEnabled === 0 ? 1 : 0
            }`
          : flag === 2
          ? `Insights/dashboard/KPI/${parent.Ispinned ? 1 : 0}`
          : `Insights/dashboard/${parent.InsightType}/KPI/${
              child.IsPinned === 0 ? 1 : 0
            }/${child.Id}`;
      Client.put(url, flag === 0 || flag === 2 ? [] : {}, {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      })
        .then((res) => {
          try {
            if (res.data[0].Status) {
              Load();
              loadApiCall();
            }
          } catch (err) {
            console.log(err);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  };
  useEffect(() => {
    loadApiCall();
  }, [loadApiCall]);
  return (
    <>
      <div className="kpifilterContainer">
        <div className="topn-header">
          <span className="selected-title">
            Select the KPIs you would like to display on the dashboard.
          </span>
          <span
            style={{ padding: "20px", float: "right" }}
            onClick={() => Close(false)}
          >
            <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
          </span>
        </div>
        <div className="_h_header b_top white">
          <label
            className="containerradio"
            style={{
              padding: "0 0 0 20px",
              marginLeft: "3px",
              fontSize: "16px",
              lineHeight: "15px",
            }}
          >
            <strong>Select ALL</strong>
            <input
              type="checkbox"
              name="selectAll"
              onChange={(e) =>
                childCheckboxFn({}, { Ispinned: e.target.checked }, 2)
              }
              checked={selectAll}
            />
            <span className="checkmarks"></span>
          </label>
        </div>
        <div
          className="padding20 kpifilterpanel  width100"
          style={{ maxHeight: "79%", overflow: "auto" }}
        >
          {recordObject.map((v, i) => (
            <div key={i}>
              <h2 style={{ marginBottom: "10px" }}>
                <label>
                  <label className="containerradios">
                    <strong>&nbsp;&nbsp;{v["InsightTypeName"]}</strong>
                    <input
                      type="checkbox"
                      checked={v["HasAllKPIEnabled"]}
                      onChange={() => childCheckboxFn({}, v, 0)}
                      disabled={v["IsReadOnly"]}
                      className="selectFilterSingle"
                    />
                    <span
                      className={`checkmarks ${
                        v["IsReadOnly"] ? "" : "disabledcheckbox"
                      }`}
                    ></span>
                  </label>
                </label>
              </h2>
              <div className="f-list">
                {JSON.parse(v.KPI).map((x, index) => (
                  <label className="shw-def" key={index}>
                    <label
                      className="containerradios"
                      style={{ padding: "0 0 0 12px" }}
                    >
                      &nbsp;&nbsp;{x["Name"]}
                      <input
                        type="checkbox"
                        checked={x["IsPinned"]}
                        onChange={() => childCheckboxFn(x, v, 1)}
                        disabled={x["IsReadOnly"]}
                        className="hover-btn-default"
                      />
                      <span
                        className={`checkmarks ${
                          x["IsReadOnly"] ? "" : "disabledcheckbox"
                        }`}
                      ></span>
                    </label>
                  </label>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
export default KPIFilter;
