import React, { useCallback, useEffect, useState } from "react";
import StepsDistribution from "../StepsTopBar";
import { useNavigate, useParams } from "react-router";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";
import getParameterByName from "../../ReuseTemplate/GetRoute";

const UTM = () => {
  const navigate = useNavigate();
  const { APITOKEN } = useSelector(state => state);
  const { id } = useParams();
  const [templateData, setTemplateData] = useState({
    data: [],
    selected: "",
    name: "",
    medium: "",
    source: "",
    content: "",
    term: "",
  });
  const [IsLoader, setLoader] = useState(false);

  const templateLoad = useCallback(() => {
    APITOKEN.token &&
      Client.get("UTM/GetUTMTemplates", {
        headers: {
          Authorization: `Bearer ` + APITOKEN["token"],
        },
      })
        .then(({ data }) => {
          try {
            setTemplateData(prv => ({
              ...prv,
              data,
            }));
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {});
  }, [APITOKEN]);
  const templateChangeSetVal = ({ target }) => {
    const id = target.value;
    const filterVal = templateData.data.filter(x => x.rowUID === id);
    const [getFlVal] = filterVal;
    setTemplateData(prv => ({
      ...prv,
      selected: getFlVal?.rowUID || "",
      name: getFlVal?.campaignName || "",
      medium: getFlVal?.mediumName || "",
      source: getFlVal?.sourceName || "",
      content: getFlVal?.content || "",
      term: getFlVal?.term || "",
    }));
  };
  const utmSave = () => {
    setLoader(true);
    const obj = {
      templateId: templateData.selected,
      utm_campaign: templateData.name,
      utm_medium: templateData.medium,
      utm_source: templateData.source,
      utm_content: templateData.content,
      utm_term: templateData.term,
    };
    patchApiCall(obj, false);
  };
  const patchApiCall = (obj, isSkipped) => {
    const clone = getParameterByName("clone", window.location.href);
    const path = getParameterByName("cam", window.location.href);
    Client.patch(`Email/Send/SetUTM/${id}?isSkipped=${isSkipped}`, obj, {
      headers: {
        Authorization: `Bearer ` + APITOKEN["token"],
      },
    })
      .then(({ data }) => {
        setLoader(false);
        try {
          if (getParameterByName("edit", window.location.href) !== null) {
            if (data.Success) {
              navigate(`/Email/Emailsummary/${id}`);
            }
          } else {
            if (data.Success) {
              if (path === null) {
                navigate(
                  `/Email/Send/${id}${
                    path !== null
                      ? `?cam=${path}`
                      : `${clone !== null ? "?clone=" + clone : ""}`
                  }`
                );
              } else {
                navigate(
                  `/Email/Send/${id}?ctype=5${
                    clone !== null ? `&clone=${clone}` : ""
                  }${path !== null ? `&cam=${path}` : ""}`
                );
              }
            }
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => setLoader(false));
  };
  const utmeditFn = useCallback(() => {
    const clone = getParameterByName("clone", window.location.href);
    APITOKEN.token &&
      Client.get(`/Email/Distribution/${clone !== null ? clone : id}`)
        .then(({ data }) => {
          try {
            if (!!data.utmParameters) {
              const {
                templateId,
                utm_campaign,
                utm_content,
                utm_medium,
                utm_source,
                utm_term,
              } = data.utmParameters;
              setTemplateData(prv => ({
                ...prv,
                selected: templateId,
                name: utm_campaign,
                medium: utm_medium,
                source: utm_source,
                content: utm_content,
                term: utm_term,
              }));
            }
          } catch (err) {
            console.error(err);
          }
        })
        .catch(err => {
          throw err;
        });
  }, [APITOKEN, id]);
  const skip = () => {
    patchApiCall({}, true);
  };
  useEffect(() => {
    templateLoad();
    if (
      getParameterByName("edit", window.location.href) !== null ||
      getParameterByName("clone", window.location.href) !== null
    ) {
      utmeditFn();
    }
  }, [templateLoad, utmeditFn]);
  return (
    <>
      <StepsDistribution Name="Email" Active={5} Hide={true} UTM={true} />
      <div className="dashbd_sec">
        <div className="sms_Sec_1 bx-boder-common inline-block white margin-top50">
          <div className="_h_header">
            <h3>UTM</h3>
          </div>
          <div className="bx-pad">
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">UTM Template</label>
              <select
                type="text"
                value={templateData.selected}
                className="inputformmodel"
                onChange={templateChangeSetVal}
              >
                <option value="">Select</option>
                {templateData.data.map(v => (
                  <option key={v.rowUID} value={v.rowUID}>
                    {v.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">Campaign Name</label>
              <input
                type="text"
                name="Name"
                value={templateData.name}
                onChange={e =>
                  setTemplateData(prv => ({
                    ...prv,
                    name: e.target.value,
                  }))
                }
                placeholder="Name"
                className="inputformmodel keyup"
              />
            </div>
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">UTM Medium</label>
              <input
                type="text"
                name="Medium"
                value={templateData.medium}
                onChange={e =>
                  setTemplateData(prv => ({
                    ...prv,
                    medium: e.target.value,
                  }))
                }
                placeholder="Medium"
                className="inputformmodel keyup"
              />
            </div>
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">UTM Source</label>
              <input
                type="text"
                name="Source"
                value={templateData.source}
                onChange={e =>
                  setTemplateData(prv => ({
                    ...prv,
                    source: e.target.value,
                  }))
                }
                placeholder="Source"
                className="inputformmodel keyup"
              />
            </div>
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">UTM Content</label>
              <input
                type="text"
                name="Content"
                value={templateData.content}
                onChange={e =>
                  setTemplateData(prv => ({
                    ...prv,
                    content: e.target.value,
                  }))
                }
                placeholder="Content"
                className="inputformmodel keyup"
              />
            </div>
            <div className="formmodel-bx relative">
              <label className="_l_able_cls">UTM Term</label>
              <input
                type="text"
                name="Term"
                value={templateData.term}
                placeholder="Term"
                onChange={e =>
                  setTemplateData(prv => ({
                    ...prv,
                    term: e.target.value,
                  }))
                }
                className="inputformmodel keyup"
              />
            </div>
            <div className="text-right">
              <button
                type="button"
                className="grid_btn_1 noborder btn-gape-15 nobg cls_goal"
                onClick={() => skip()}
              >
                Skip{" "}
              </button>
              <button
                type="button"
                disabled={
                  templateData.medium !== "" && templateData.source !== ""
                    ? false
                    : true
                }
                className="grid_btn_1 grid_btn_active btn-gape-15"
                onClick={() => utmSave()}
              >
                <ButtonLoader IsLoader={IsLoader}>
                  {getParameterByName("edit", window.location.href) !== null
                    ? "Update"
                    : "Continue"}
                </ButtonLoader>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UTM;
