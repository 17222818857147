import _ from "lodash";
import { useCallback, useEffect } from "react";
const SMSendingGrid = ({ ...props }) => {
    const { goalObject, setGoalObject, checkValidation, setValidationChcked } = props;

    const vailedFields = useCallback(() => {
        const goalrateNotBlank = goalObject.filter(x => x['Goal Rate %'] !== "").map(v => v.Id);
        setValidationChcked(goalrateNotBlank);
    }, [goalObject, setValidationChcked]);

    const goalGetVal = (v, e) => {
        const { value } = e.target;
        const index = _.findIndex(goalObject, function (o) { return o.Id === v.Id });
        const ind = _.findIndex(checkValidation, function (o) { return o === v.Id });
        goalObject[index]['Goal Rate %'] = value.replace(/[^0-9]/g, '');
        const filtercheckValidation = _.sortedUniq(checkValidation);
        if (ind === -1) {
            filtercheckValidation.push(goalObject[index].Id);
            setValidationChcked(filtercheckValidation);
        } else {
            if (goalObject[index]['Goal Rate %'] === "") {
                setValidationChcked(filtercheckValidation.filter(x => x !== v.Id));
            }
        }
        setGoalObject(() => ([...goalObject]));
    }
    useEffect(() => {
        vailedFields();
    }, [vailedFields])
    return (
        <>
            <table cellPadding="0" cellSpacing="0" className="smstable table-layout-default">
                <thead>
                    <tr>
                        <th className="table_cell_header" width="30%">KPI Name</th>
                        <th className="table_cell_header" width="58%">Description</th>
                        <th className="table_cell_header">Goal Rate %</th>
                    </tr>
                </thead>
                <tbody>
                    {goalObject.map((v, i) => <tr key={i}><td className="body_table_cell">{v['Name']}</td><td className="body_table_cell">{v['Description']}</td><td className="body_table_cell"><span className="margin-right15">{v['OperatorSymbol']}</span><span className="drop-zone"><input style={{ width: '80px' }} type="text" value={v['Goal Rate %']} disabled={v.disabled} onChange={e => goalGetVal(v, e)} className="inline-block inputformmodel goalval percentage-padding-right" /><b className="percentage">%</b></span></td></tr>)}
                </tbody>
            </table></>
    )

}

export default SMSendingGrid
