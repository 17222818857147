
const GridLoader = ({ ...props }) => {
    const { TDArray } = props;
    return (
        <table cellPadding="0" cellSpacing="0" width="100%" className="smstable white"><tbody>{[1, 2, 3, 4, 5].map((x, i) => <tr key={i}>{TDArray.map((y, index) => <td className="body_table_cell" key={index}><span className="skeleton-loader"></span></td>)}</tr>)}</tbody></table>
    )

}

export default GridLoader
