import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import EmailGrid from './EmailGrid';
import { useNavigate } from 'react-router-dom';
import './index.css'
import GridLoader from '../../../../ReuseTemplate/GridLoader';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';
import BlankData from '../../../../BlankData';
const Email = () => {
    const { APITOKEN } = useSelector(state => state);
    const [emailDetails, setEmailDetails] = useState({
        data: [],
        isDisplay: true
    });
    const [showDeletePanel, setDeletePanel] = useState(true);
    const [guidId, setListGuidId] = useState("");
    const navigate = useNavigate();

    // Get All list from API Call
    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Trigger/Templates/Email', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setEmailDetails(prv => ({
                        ...prv,
                        data: res['data'],
                        isDisplay: false
                    }));
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }, [APITOKEN])
    // List Item Delete API Call //
    const deleteList = () => {
        Client.delete(`Trigger/Templates/${guidId.id}/Email`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                setDeletePanel(!showDeletePanel);
                load();
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
    }

    const showDeletePanelFN = (f, item) => {
        setDeletePanel(!showDeletePanel);
        setListGuidId(item);
    }
    const duplicateClick = item => {
        Client.post(`Campaign/duplicate?id=${item.id}`, {}, {
            headers: {
                Authorization: `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                try {
                    load()
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                console.error(err);
            })
    }
    useEffect(() => {
        load()
    }, [load])
    return (<>
        <div className="dashbd_sec margin-top50">&nbsp;</div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="Email Triggers" />
        </div>
        <div className="dashbd_sec">
            {showDeletePanel ?
                <>
                    <div className="sms_Sec_1 bx-boder-common width100 margin-bottom">
                        <div className="_h_header">
                            <h3>Email Triggers</h3>
                            <span className="flex-right"><button className="grid_btn_1 grid_btn_active" type="button" onClick={() => navigate(`/createContent/9`)}>Create Template</button></span>
                        </div>
                        {emailDetails.isDisplay ? <GridLoader TDArray={[1, 2, 3]} /> :
                            <table cellPadding="0" cellSpacing="0" className="smstable">
                                <thead>
                                    {emailDetails.data.length > 0 ?
                                        <tr>
                                            <td className="table_cell_header">Template Name</td>
                                            <td className="table_cell_header">Template ID</td>
                                            <td className="table_cell_header">Content Type</td>
                                            <td className="table_cell_header">Actions</td>
                                        </tr>
                                        : <BlankData title="You have not configured any Email Triggers Templates." content="With a template you are able to configure and change your Email Triggers without involving your technical department." colSpan="4"/>}
                                </thead>
                                <tbody>{

                                    emailDetails.data.length > 0 && emailDetails.data.map((item, index) => {
                                        return <EmailGrid item={item} key={index} showDeletePanelFN={showDeletePanelFN} DuplicateClick={duplicateClick} />
                                    })
                                }
                                </tbody>
                            </table>
                        }
                    </div>
                </> : <DeletePanel click={() => deleteList()} closeFn={() => setDeletePanel(!showDeletePanel)} />
            }
        </div>
    </>)
}
const DeletePanel = (props) => {
    return (<>
        <div className="sms_Sec_1 bx-boder-common width100">
            <div className="_h_header">
                <h3>Delete Template</h3>
            </div>
            <table cellPadding="0" cellSpacing="0" className="smstable">
                <tbody>
                    <tr>
                        <td className="body_table_cell white">
                            <div className="_nodata margin-bottom">
                                <div id="nodatamessage">
                                    <strong>
                                        Do you want to delete the template?.
                                    </strong>
                                    <br></br>
                                    <br></br>
                                    This will permanently delete this template and it can not be restored.
                                </div>
                            </div>
                            <div className="text-right">
                                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={props.closeFn}>Cancel</button>
                                <button type="button" className="grid_btn_1 deleteBtn" onClick={props.click}>Delete</button>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div></>)
}

export default Email;