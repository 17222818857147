import { useSelector } from "react-redux";
import Client from "../../../GlobalAPICall";
import { useCallback, useEffect, useState } from "react";
import './index.css'
import _ from "lodash";
import EmailDomain from "./EmailDomain";
import LandingPageDomain from "./LandingPageDomain";
import URLShort from "./URLShortener";
import { useNavigate } from "react-router";
const ConfigDomain = () => {
    const { APITOKEN } = useSelector(state => state);
    const [data, setData] = useState([]);
    const [domainLabel, setSelectedDoaminLabel] = useState({
        id: "",
        flag: false
    });
    const navigate = useNavigate();
    const load = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Lookup/DomainWhiteLabelingTypes`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setData(res.data);
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.log(err);
                })
    }, [APITOKEN])
    const selectedBx = ev => {
        for (const x of data) {
            x.selected = false;
        };
        const index = _.findIndex(data, function (x) { return x.Id === ev.Id });
        data[index].selected = true;
        setData([...data]);
        setSelectedDoaminLabel(prv => ({
            ...prv,
            flag: true,
            selectedId: data[index].Id
        }))

    }
    useEffect(() => {
        load();
    }, [load])
    return (
        <>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>
                            {domainLabel.id === "" ? 'Configure Domain' : domainLabel.id === 1 ? 'Email Domain White Labeling' : domainLabel.id === 2 ? 'Landing Page URL White Labeling' : 'URL Shortner White Labeling'}
                        </h3>
                    </div>
                    {domainLabel.id === "" ?
                        <>

                            <div className="domain__body padding20">
                                <p>You are able to configure you own domains for different purposes. This will be good  for customer recognition and brand value.</p>
                                <div className="domain__box__grid margin-top50">
                                    {data.map((v, i) => <div key={i} className="bx" onClick={() => selectedBx(v)}>
                                        <label>{v['Name']}</label>
                                        <div className={`divimg d__Onclick ${v.selected ? 'selected' : ''}`}>
                                            <div className="d__img"><img src={v['ImageUrl']} alt="" className="configimg" /></div>
                                            <div className="b__d inline-block width100">
                                                <button type="button" className="grid_btn_1 nobg vertical-middle fright " onClick={() => selectedBx(v)}>Select</button>
                                            </div>
                                        </div>
                                    </div>)}
                                </div>
                            </div>
                            <div className="inline-block width100 margin-top50 text-right padding20 __web-inspector-hide-shortcut__">
                                <button type="button" className="grid_btn_1 nobg noborder vertical-middle" onClick={()=> navigate('/configuration?_id=39')}>Cancel</button>
                                <button type="button" disabled={domainLabel.flag ? false : true} onClick={() => setSelectedDoaminLabel(prv => ({
                                    ...prv,
                                    id: prv.selectedId
                                }))}
                                    className="grid_btn_1 grid_btn_active">Continue</button>
                            </div>

                        </> : domainLabel.id === 1 ?
                            <EmailDomain setSelectedDoaminLabel={setSelectedDoaminLabel} domainLabel={domainLabel} /> :
                            domainLabel.id === 2 ?
                                <LandingPageDomain setSelectedDoaminLabel={setSelectedDoaminLabel} domainLabel={domainLabel} /> :
                                <URLShort setSelectedDoaminLabel={setSelectedDoaminLabel} domainLabel={domainLabel} />}
                </div>
            </div>
        </>
    )
}
export default ConfigDomain;