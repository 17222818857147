import { useState } from "react";
import Client from "../../GlobalAPICall";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const SendTestContainer = ({ ...props }) => {
    const { SetSendTestObject, SendTestVal, SendObject } = props;
    const [newarrySearchData, setnewarrySearchData] = useState({ data: [], selected: '', name: '' });
    const [senTestResponse, setTestReponse] = useState({ show: false });

    const resetObject = () => {
        setTestReponse(prv => ({ ...prv, show: false }))
        SetSendTestObject({
            flag: true,
            recipinentsTest: [],
            addEmail: '',
            addButton: true
        });
        setnewarrySearchData({ data: [], selected: '', name: '' });
    }

    // Search country Name //
    const enterEmail = (e) => {
        SetSendTestObject(prv => ({
            ...prv,
            addEmail: e.target.value,
        }));
        if (/.+@.+\.[A-Za-z]+$/.test(e['target'].value)) {
            SetSendTestObject(prv => ({
                ...prv,
                addEmail: e.target.value,
                addButton: false
            }));
        } else {
            SetSendTestObject(prv => ({ ...prv, addButton: true }));
        }
    }

    // Add Recipients Method //
    const addRecipients = () => {

        SetSendTestObject(prv => ({
            ...prv,
            recipinentsTest: [...prv.recipinentsTest,
            ...[{ Recipient: `${newarrySearchData.selected}${prv.addEmail}`, peopleSubscriberId: null }]],
            addEmail: "",
            addButton: true
        }));

    }

    // Remove Recipients //
    const removeRecipient = (ev) => {
        const newArray = SendTestVal.recipinentsTest.filter(x => x.Recipient !== ev.Recipient);
        SetSendTestObject(prv => ({ ...prv, recipinentsTest: newArray }))
    }

    // Sent Test API Call //
    const [IsLoader, setIsLoader] = useState(false);
    const sendTestAPICall = () => {
        const obj = SendObject();
        setIsLoader(true);
        obj['recipients'] = SendTestVal.recipinentsTest;
        obj['isdraft'] = false;
        try {
            Client.post(`Email/Send/Test`, obj)
                .then(({data}) => {
                    try {
                        setIsLoader(false);
                        if (data.Success) {
                            setTestReponse(prv => ({ ...prv, show: true }))
                        }
                    } catch (err) {
                        console.error(err);
                        setIsLoader(false);
                    }
                }).catch(err => {
                    console.error(err);
                    setIsLoader(false);
                })
        } catch (err) {
            console.error(err);
            setIsLoader(false);
        }
    }
    return (
        <div className="sendTestMask">
            <div className="_sendTestRemove extraclass">
                <div className="topn-header noborder">
                    <span className="selected-title">Send Test Email</span>
                    <span style={{
                        padding: '20px',
                        float: 'right'
                    }} onClick={() => SetSendTestObject(prv => ({ ...prv, flag: false }))}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="_p_b_x noborder">
                    {senTestResponse.show ? <>
                        <p class="margin-bottom">Test Email(s) have been sent to the entered Email address(s).</p>
                        <div className="formmodel-bx text-right"><button type="button" className="grid_btn_1 grid_btn_active btn-gape-15" onClick={resetObject}>Send New Test</button></div>
                    </> :
                        <>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Email</label>
                                <input
                                    type="text"
                                    className="inputformmodel"
                                    placeholder="Email"
                                    value={SendTestVal.addEmail}
                                    onChange={e => SendTestVal.recipinentsTest.length === 5 ? e.stopPropagation() : enterEmail(e)} style={{
                                        width: '347px',
                                    }} />
                                <button type="button" className="grid_btn_1 grid_btn_active btnadd fright" disabled={SendTestVal.addButton} onClick={addRecipients}>Add</button>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Recipients</label>
                                <div className="inputformmodel textareaModel" style={{ overflow: 'auto' }}>

                                    {SendTestVal.recipinentsTest.length > 0 ? SendTestVal.recipinentsTest.map((v, i) => <label key={i} className="l_b-bx"><span>{v.Recipient}</span><button className="removeRecipienSendtest" type="button" onClick={() => removeRecipient(v)}><i className="fas fa-trash-alt"></i></button></label>) : <p className="_sp">Add up to 5 Emails. </p>}
                                </div>
                            </div>
                            <div className="formmodel-bx text-right">
                                <button type="button" disabled={
                                    SendTestVal.recipinentsTest.length > 0 ? false : true
                                } className="grid_btn_1 grid_btn_active btn-gape-15" onClick={sendTestAPICall}>
                                    <ButtonLoader IsLoader={IsLoader}>
                                        Send Test
                                    </ButtonLoader>
                                </button>
                            </div>
                        </>}</div>
            </div>
        </div>
    )

}

export default SendTestContainer
