import React, { useEffect, useState } from 'react';
import { get as _get, cloneDeep as _cloneDeep } from "lodash";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { getLookupConsents, importSubscriberList } from "../../../../services/crm";

const Compliance = ({ sendDataToParent }) => {

	const navigate = useNavigate();
	const { importContact } = useSelector(state => _get(state, "importContacts", {}));
	const [termsAndConditionConsent, setTermsAndConditionConsent] = useState([]);
	const [selectedConsents, setSelectedConsents] = useState([]);
	const [isContinueDisabled, setIsContinueDisabled] = useState(true);

	useEffect(() => {
		(async () => {
			try {
				const respTemrmsConsent = await getLookupConsents({ moduleId: 3 });
				setTermsAndConditionConsent(_get(respTemrmsConsent, "data.data[0]", []));

				//const respMergeConsent = await getLookupConsents({ moduleId: 6 });
				//setMergeRulesConsent(_get(respMergeConsent, "data.data[0]", []));

			} catch (error) {
				console.log("Error: ", error);
			}
		})();
	}, []);

	const handleOnChange = ({ currentTarget }) => {
		const { checked, value } = currentTarget;
		const tmpConsents = _cloneDeep(selectedConsents);

		if (checked) {

			tmpConsents.push(value);
		} else {
			const index = tmpConsents.indexOf(value);
			tmpConsents.splice(index, 1);
		}
		setSelectedConsents(tmpConsents);
	}

	useEffect(() => {
		let btnDisabled = true;
		let mandatoryConstentsCount = 0;

		_get(termsAndConditionConsent, "ConsentDetails", []).forEach((consent) => {

			if (_get(consent, "Ismandatory", false) === true) {
				mandatoryConstentsCount++;
			}
		});

		if ((selectedConsents || []).length === mandatoryConstentsCount) {
			btnDisabled = false;
		}

		setIsContinueDisabled(btnDisabled);
	}, [selectedConsents, termsAndConditionConsent]);

	const onContinue = () => {
		(async () => {
			try {
				const payload = {
					ComplianceJson: (selectedConsents || []),
					StepId: 5,
				}
				const response = await importSubscriberList(_get(importContact, "data.ImportId", ""), payload);

				if (_get(response, "flag", true) === true) {
					sendDataToParent("summary");
				}
			} catch (error) {
				console.log("Error: ", error);
			}
		})();
	}
	return (
		<>
			<div className="bx-boder-common nobg my-12">
				<div className="_h_header white border-radius2">
					<h3>Before we import these contacts for you, we need you to confirm the following</h3>
				</div>

				<div className="white">
					<div className="step-compliance">
						<div className="p-5">
							<p className="mb-12">There are different laws and regulations all over the world.This platform gives you the opportunity to communicate with the people in Europe and the US. We need you to confirm that you have read and understood the GDPR and TCPA regulations for sending SMS and emails to people inside these regions.</p>

							{_get(termsAndConditionConsent, "ConsentDetails", []).map((row, key) => {

								return (
									<div key={key} className={(key > 0) ? "mt-5" : ""}>
										<input
											id={`consent-${key}`}
											className="inline-block vertical-middle"
											type="checkbox"
											checked={selectedConsents.includes(_get(row, "ConsentDetailsId", ""))}
											value={_get(row, "ConsentDetailsId", "")}
											onChange={(e) => handleOnChange(e)}
										/>
										<label htmlFor={`consent-${key}`} className="ps-3">
											<span className="line-height-23">{_get(row, "Text", "")}</span>
											<br />
											<p className="ps-6">{_get(row, "Description", "")}</p></label>
									</div>
								)
							})}
						</div>

						<div className="p-5 text-right">
							<button className="btn link-light" onClick={() => navigate("/crm/people/listings")}>Cancel</button>
							<button className="btn btn-success" disabled={isContinueDisabled} onClick={() => onContinue()}>Continue</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Compliance;