import { useNavigate } from "react-router";

const SMSOPTINGrid = ({ item, ...props }) => {
    const navigate = useNavigate();
    return (<>
        <tr>
            <td className="body_table_cell noproperties">{item.name}</td>
            <td className="body_table_cell ">{item.displayId}</td>
            <td className="body_table_cell"><button className="inline-block link _edit_template noborder nobg" onClick={()=> navigate(`/createContent/27?id=${item.id}`)}>Edit</button>  &nbsp; | &nbsp;<button className="inline-block link nobg noborder" onClick={()=> props.showDeletePanelFN(false,item)}>Delete</button>&nbsp; <span style={{"display":item.isCompleted?'inline-block':'none'}}>| &nbsp;</span><button className="inline-block link nobg noborder" style={{"display":item.isCompleted?'inline-block':'none'}}>Duplicate</button></td>

        </tr>
    </>);
}

export default SMSOPTINGrid
