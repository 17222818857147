import { useCallback, useEffect, useState } from "react";
import { SenderName } from "../SMS/SenderName";
import { IndianTemplate } from "../SMS/IndianTemplate";
import { MergeCodes } from "../SMS/MergeCodes";
import { getCursorPos } from "../../../../ReuseTemplate/GetCursorPoint";
import { CheckIsUnicodeExists } from "../../../../ReuseTemplate/SMSCount";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import { ShortURL } from "../SMS/ShortURL";
import { useNavigate } from "react-router";
import getParameterByName from "../../../../ReuseTemplate/GetRoute";

const CreateSMSOptTrigger = () => {
    const { APITOKEN } = useSelector(state => state);
    const [SMSCampaignValue, setSMSCampaign] = useState({ data: [] });
    const navigate = useNavigate();
    const [sendNameArrayObject, setSenderNameArray] = useState([]);
    const defaultMessage = "Hi [firstname]! Welcome to the loyalty club. Click on this link to see the latest news [uniqueurl]. Best, Your Company";
    const [shortCodeShow, setShortCodeToggle] = useState(false);
    const [senderNameReload] = useState(Math.random());

    let [messageCount, setMessageCount] = useState(defaultMessage);
    const [shortCodeVal, setShortCodeVal] = useState({ first: '', last: '' });
    const [smsSenderData, setSenderName] = useState([]);
    // const [disabledIndianTemplate, setIndianTemplateDisabled] = useState(false);
    const [selectedValue, setSelectedValue] = useState({
        senderName: "",
        campaign: {},
        smsText: defaultMessage,
        indianTemplateVal: "",
        campaignType: "",
        shortURL: ""
    });
    const [elementShow, setElementShow] = useState({
        smsContent: false,
        senderName: false,
        shortURL: false,
    })
    const setStateCallback = useCallback((messageCount) => {
        const count = CheckIsUnicodeExists((messageCount), 0)
        setMessageCount(count);
    }, []);
    const indianTemplate = (v) => {
        setSelectedValue(prv => ({
            ...prv,
            smsText: v.length === 0 ? defaultMessage : v[0].smsText,
            indianTemplateVal: v.length === 0 ? '' : v[0].templateId,
            campaign: {
                ...prv.campaign,
                Name: selectedValue.campaign.Name

            }
        }));
        // if (v.length !== 0) {
        //     setIndianTemplateDisabled(true);
        // } else {
        //     setIndianTemplateDisabled(false);
        // }
    }
    const senderSenderName = (e) => {
        if (e.target.value === "") {
           // setIndianTemplateDisabled(false);
            setSelectedValue(prv => ({
                ...prv,
                ...{ senderName: e.target.value, indianTemplateVal: "", smsText: defaultMessage }
            }));
        } else {
            setSelectedValue(prv => (
                { ...prv, senderName: e.target.value }
            ));
        }
        setElementShow(prv => ({
            ...prv,
            ...{ senderName: e.target.value === "" || e.target.value === '1' ? false : true }
        }));
    }
    const shortCodeClick = (v) => {

        setSelectedValue({ ...selectedValue, ...{ smsText: `${shortCodeVal.first}${v.tag}${shortCodeVal.last}` } });

    }
    const smsMessageFN = (e) => {
        setShortCodeToggle(true)
        const count = getCursorPos(e.target);
        const first = selectedValue.smsText.substring(0, count['start']);
        const last = selectedValue.smsText.substring(selectedValue.smsText.length, count['start']);
        setShortCodeVal({ first, last })
    }
    const closeShortCode = () => {
        setShortCodeToggle(false);
    }
    const smsCampaign = useCallback(() => {
        APITOKEN.token &&
            Client.get('Lookup/contentDetails/748', {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then((result) => {
                    try {
                        setSMSCampaign({ data: result.data, url: "" });
                    } catch (err) {
                        console.error(err);
                    }

                }).catch((err) => {
                    console.error(err)
                });
    }, [APITOKEN]);
    const saveFn = () => {
        const id = getParameterByName('cam', window.location.href);
        const edit = getParameterByName('edit', window.location.href);
        const obj = {
            "id" :id,
            "message": selectedValue.smsText,
            "sendername": selectedValue.senderName,
            "content": {
                "typeId": 2,
                "typeDetailId": selectedValue.campaign.typeDetailId,
                "indianSMSTemplateId": selectedValue.indianTemplateVal
            },
            "shortnerUrl": {
                "shortnerUrl": selectedValue.shortURL,
                "shortnerUrlType": selectedValue.campaignType
            },
        };
        if (edit !== null) {
            
            putAPICall(obj);
        } else {
            
            postAPICall(obj)
        };

    }
    const putAPICall = (obj) => {
        try {
            Client.put(`Trigger/Templates/${obj.id}/SMSoptIn`, obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {
                    if (res.data.Success) {
                        navigate('/create/templates/smsDoubleOptin?type=27');
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.log(err);
            })
        } catch (err) {
            console.error(err);
        }
    }
    const postAPICall = (obj) => {
        try {
            Client.post(`Trigger/Templates/SMSoptIn`, obj, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(res => {
                try {
                    if (res.data.Success) {
                        navigate('/create/templates/smsDoubleOptin?type=27');
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err => {
                console.log(err);
            })
        } catch (err) {
            console.error(err);
        }
    }
    const templateEditSMS = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.get(`Trigger/Templates/${getParameterByName('cam', window.location.href)}/SMSoptin`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                }).then(res => {
                    try {
                        if (res.data.length === 0) {
                            return false;
                        }
                        const data = res.data[0];
                        setElementShow(prv => ({
                            ...prv,
                            senderName: true,
                            shortURL: (data.shortnerUrl.shortnerUrlType === 1) ? true : false
                        }))
                        setSelectedValue(prv => (
                            {
                                ...prv,
                                senderName: data.Sendername,
                                smsText: data.message,
                                typeDetailId: data.content.typeDetailId,
                                campaign: {
                                    typeDetailId: data.content.typeDetailId,
                                    url: data.content.typeDetailUrl,
                                },
                                indianTemplateVal: data.content.indianSMSTemplateId,
                                campaignType: data.shortnerUrl.shortnerUrlType,
                                shortURL: data.shortnerUrl.shortnerUrl
                            }
                        ));
                        setStateCallback(`${data.message}`);
                        // if (data.content.indianSMSTemplateId !== undefined) {
                        //     setIndianTemplateDisabled(true);
                        // } else {
                        //     setIndianTemplateDisabled(false);
                        // };
                    } catch (err) {
                        console.log(err);
                    }
                }).catch(err => {
                    console.error(err);
                })
        } catch (err) {
            console.log(err);
        }
    }, [APITOKEN, setStateCallback])
    useEffect(() => {
        setStateCallback(defaultMessage);
        smsCampaign();
        if (getParameterByName('edit', window.location.href) !== null) {
            templateEditSMS();
        };
    }, [setStateCallback, smsCampaign, templateEditSMS])
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Create New Double Opt-in</h1>
                        <p className="sms_content">
                            By using a double opt-in confirmation method, the chance of spam addresses among your contacts will be reduced. When they click on the link to the content you add under SMS Double Opt-in Content, they are opted-in.
                        </p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec">
                <form>
                    <div className="sms_Sec_1 bx-boder-common inline-block">
                        <div className="_h_header">
                            <h3>Create new double Opt-in SMS</h3>
                        </div>
                        <div className="bx-pad">
                            <SenderName
                                ChangeEvent={senderSenderName}
                                Selected={setSelectedValue}
                                Value={selectedValue}
                                Array={setSenderNameArray}
                                SetSenderName={setSenderName}
                                SmsSenderData={smsSenderData}
                                ReloadVar={senderNameReload}
                            />
                            {elementShow.senderName && <IndianTemplate Value={selectedValue} ChangeEvent={indianTemplate} />}
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">SMS Text</label>
                                <div className="drop-zone">
                                    {shortCodeShow && <MergeCodes onClick={shortCodeClick} Value={selectedValue} CloseShortCode={closeShortCode} />}

                                    <textarea className="inputformmodel textareaModel"
                                        value={selectedValue.smsText}
                                        onClick={smsMessageFN}
                                        onChange={(e) => { setSelectedValue({ ...selectedValue, ...{ smsText: e.target.value } }); setStateCallback(e.target.value) }}>

                                    </textarea>
                                    <p className="smsCountInfor text-right">

                                        Total SMS characters : {selectedValue.smsText.length}
                                        <br></br>
                                        Number of SMS(s) : {messageCount}
                                    </p>
                                </div>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Landing page</label>
                                <select
                                    className="inputformmodel"
                                    value={selectedValue.typeDetailId}
                                    onChange={e => setSelectedValue(prv => ({
                                        ...prv,
                                        typeDetailId: e.target.value,
                                        campaign: {
                                            typeDetailId: e.target.value,
                                            url: e.target.childNodes[e.target.selectedIndex].getAttribute('data-url')
                                        }
                                    }))}
                                >
                                    <option value="">Select</option>
                                    {SMSCampaignValue.data.map((v, i) => <option key={i} data-url={v.PreviewUrl} value={v.Id}>{v.Name}</option>)}
                                </select>
                            </div>
                            {selectedValue.campaign.typeDetailId !== undefined &&
                                <div className="formmodel-bx">
                                    <label className="_l_able_cls">Campaign Type</label>
                                    <select
                                        className="inputformmodel"
                                        value={selectedValue.campaignType}
                                        onChange={(e) => { setElementShow(prv => ({ ...prv, shortURL: (e.target.value === "1" || e.target.value === "") ? true : false })); setSelectedValue(prv => ({ ...prv, campaignType: e.target.value })) }}>
                                        <option value="">Select</option>
                                        <option value="1">With Unique URL</option>
                                        <option value="0">Without Unique URL</option>
                                    </select>
                                </div>
                            }
                            {elementShow.shortURL &&
                                <ShortURL Value={selectedValue} Selected={setSelectedValue} />
                            }
                        </div>
                        <div className=" text-right padding20">
                            <button type="button" className="grid_btn_1 nobg noborder _cls_btn" onClick={() => navigate('/create/templates/smsDoubleOptin?type=27')}>Cancel</button>
                            <button type="button" className="grid_btn_1 grid_btn_active" disabled={
                                (selectedValue.campaignType !== "" &&
                                    selectedValue.shortURL !== "" &&
                                    selectedValue.senderName &&
                                    sendNameArrayObject.length > 0 &&
                                    selectedValue.campaign.typeDetailId !== undefined) ? false : true
                            } onClick={saveFn}>{(getParameterByName('edit', window.location.href) !== null) ? 'Update' : 'Continue'}</button>
                        </div>
                    </div>

                </form>



            </div>

        </>
    )
}
export default CreateSMSOptTrigger;