import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Nav";

import styled from "styled-components";
import Onboarding from "./Onboarding";
import { useSelector } from "react-redux";
import Redirect from "./Redirect";
import { RoutesArray } from "./Routes";
import Cookies from "universal-cookie";
import { useEffect } from "react";

const Layout = styled.div`
  margin-left: ${(props) => (props.NavFlag ? "80px" : "250px")};
  box-sizing: border-box;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  margin-top: 56px;
`;
export const Container = styled.div`
  max-width: 1082px;
  margin: auto;
  padding: 0 50px;
`;
function App() {
  const { NavigationToggleReduce, APITOKEN } = useSelector((state) => state);
  const cookies = new Cookies();
  const secretKey = cookies.get("secretKey");
  useEffect(() => {
    if (APITOKEN.token) {
      localStorage.setItem("tokenApi", APITOKEN.token);
    }
  }, [APITOKEN.token]);
  return (
    <Router>
      {APITOKEN.token !== "" && !!secretKey && <Navbar />}
      <Layout NavFlag={NavigationToggleReduce}>
        <Routes>
          {RoutesArray.map((item, index) => (
            <Route key={index} path={item.path} exact element={item.element} />
          ))}
          <Route path="*" element={<Onboarding />} />
          <Route path="/redirect" element={<Redirect />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
