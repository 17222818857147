import {  useState } from 'react';
import Tabing from './Tabing';
import './index.css';
const MobileInsight = () => {

    const [tabData] = useState([
        { name: 'Overview', id: 1 },
        { name: 'App', id: 2 },
        { name: 'Speaker', id: 3 },
        { name: 'Speech', id: 4 },
        { name: 'Playlist', id: 5 },
        { name: 'Video', id: 6 },
    ]
        );
    
    return (
        <> <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle _t_i">Mobile Insights</h1>
                    <p className="sms_content">Here, you can find all your Mobile insights, starting from an aggregated level and breaking down per activity to the lowest level of statistics. You have the ability to pin your favorite KPIs to your dashboard.</p>
                </div>
            </div>
        </div>
            <div className="dashbd_sec margin-top50">
                <Tabing  data={tabData}/>
            </div></>
    )
}
export default MobileInsight;