//import CRM from "./CRM";
import Folder from "./Create/Folder";
import { FolderContent } from "./Create/Folder/FolderContent";
import ShareFolder from "./Create/Folder/ShareFolder";
import Goal from "./Create/Goal";
import CreateGoal from "./Create/Goal/CreateGoal";
import MobileAPP from "./Create/MobileAPP";
import Template from "./Create/Template";
import AnonymousUnsubscribe from "./Create/Template/Trigger/Anonymous";
import CreateAnonymousUnsubscribeUnsubscribe from "./Create/Template/Trigger/Anonymous/Create";
import Email from "./Create/Template/Trigger/Email";
import CreateEmailTrigger from "./Create/Template/Trigger/Email/Create";
import EmailOPTIN from "./Create/Template/Trigger/EmailDoubleOpt";
import CreateEmailOptTrigger from "./Create/Template/Trigger/EmailDoubleOpt/Create";
import GoalTemplate from "./Create/Template/Trigger/GoalTemplate";
import CreateEmailGoal from "./Create/Template/Trigger/GoalTemplate/CreateEmailGoal";
import CreateLandingPageGoal from "./Create/Template/Trigger/GoalTemplate/CreateLandingPageGoal.js";
import CreateSMSGoal from "./Create/Template/Trigger/GoalTemplate/CreateSMSGoal";
import EmailConversionGoalTemplate from "./Create/Template/Trigger/GoalTemplate/EmailConversionGoalTemplate";
import LandingPageConversionGoalTemplate from "./Create/Template/Trigger/GoalTemplate/LandingPageConversion";
import LocalSender from "./Create/Template/Trigger/LocalSender";
import CreateSenderName from "./Create/Template/Trigger/LocalSender/CreateSenderName";
import CreateTemplateLocalSender from "./Create/Template/Trigger/LocalSender/CreateTemplate";
import SMS from "./Create/Template/Trigger/SMS";
import { CreateSMSTrigger } from "./Create/Template/Trigger/SMS/Create";
import SMSOPTIN from "./Create/Template/Trigger/SMSDoubleOpt";
import CreateSMSOptTrigger from "./Create/Template/Trigger/SMSDoubleOpt/Create";
import UTMTemplate from "./Create/Template/Trigger/UTMTemplate";
import { CreateTemplate } from "./Create/Template/Trigger/UTMTemplate/CreateTemplate";
import { CreateUTMCampaign } from "./Create/Template/Trigger/UTMTemplate/CreateUTMCampaign";
import Unsubscribe from "./Create/Template/Trigger/Unsubscribe";
import CreateUnsubscribe from "./Create/Template/Trigger/Unsubscribe/Create";
import CreateContent from "./CreateContent";
import SelectTemplate from "./CreateContent/SelectTemplate";
import Dashboard from "./Dashboard";
import EmailInsight from "./Insights/Email";
import EmailDetails from "./Insights/Email/Details";
import LandingPageInsight from "./Insights/LandingPage";
import LandingPageDetails from "./Insights/LandingPage/Details";
import MobileInsight from "./Insights/Mobile";
import NPS from "./Insights/NPS";
import SMSInsight from "./Insights/SMS";
import SMSDetails from "./Insights/SMS/Details";
import Survey from "./Insights/Survey";
import SurveyDetails from "./Insights/Survey/Details";
import Login from "./Login";
import MultipalUser from "./Login/MultipalUser";
import Logout from "./Logout";
import CampaignURL from "./MyContent/CampaignURL";
import EmailCampaign from "./MyContent/Email";
import Embedded from "./MyContent/Embedded";
import LandingPageCampaign from "./MyContent/Landing";
import ShareCampaign from "./MyContent/ShareCampaign";
import ShareCampaignDetails from "./MyContent/ShareCampaign/ShareCampaignDetails";
import SlugCreate from "./MyContent/Slug";
import SurveyCampaign from "./MyContent/Survey";
import StoreList from "./MyContent/Store";
import StoreCreate from "./MyContent/Store/StoreCreate";
import StoreEdit from "./MyContent/Store/StoreEdit";
import Onboarding from "./Onboarding";
import { EmailRecipientScreen } from "./Send/Email/EmailRecipientScreen";
import EmailSend from "./Send/Email/EmailSend";
import { EmailSummary } from "./Send/Email/EmailSummary";
import UTM from "./Send/Email/UTM";
import DistributionMobile from "./Send/Mobile";
import { RecipientMobileScreen } from "./Send/Mobile/RecipientScreen";
import { MobileSend } from "./Send/Mobile/Send";
import { MobileSummary } from "./Send/Mobile/Summary";
import DistributionSMS from "./Send/SMS";
import GoalSMS from "./Send/SMS/Goal";
import { RecipientScreen } from "./Send/SMS/RecipientScreen";
import { SMSSend } from "./Send/SMS/SMSSend";
import { SMSSummary } from "./Send/SMS/SMSSummary";
import Billing from "./Setting/Billing";
import CreateAnalytics from "./Setting/Configruation/CreateAnalytics";
import CreateGoogleTag from "./Setting/Configruation/CreateGoogleTag";
import DomainAuthenticate from "./Setting/Configruation/DomainAuthenticate";
import ConfigDomain from "./Setting/Configruation/DomainSetUp";
import GoogleAnalaticsDetails from "./Setting/Configruation/GoogleAnalaticsDetails";
import GoogleTagDetails from "./Setting/Configruation/GoogleTagDetails";
import JavascriptList from "./Setting/Configruation/Javascript";
import CreateJavascript from "./Setting/Configruation/Javascript/Create";
import Download from "./Setting/Download";
import MyAccount from "./Setting/MyAccount";
import Configuration from './Setting/Configruation';
import CRMContacts from "./pages/CRMContacts";
import ImportContacts from "./pages/CRMContacts/ImportContacts";
import CreateList from "./pages/CRMContacts/CreateList";
import LoyaltyProgram from "./pages/CRMContacts/LoyaltyProgram";
import ImportStatus from "./pages/CRMContacts/ImportStatus";
import ContactsList from "./pages/CRMContacts/ContactsList";
import CustomFields from "./pages/CRMContacts/ContactsList/CustomFields";
import Segments from "./pages/CRMContacts/ContactsList/Segments";
import SegmentName from "./pages/CRMContacts/ContactsList/Segments/SegmentName";
import SegmentCreate from "./pages/CRMContacts/ContactsList/Segments/SegmentCreate";
import ViewSegment from "./pages/CRMContacts/ContactsList/Segments/ViewSegment";
import CreateMannually from "./pages/CRMContacts/ContactsList/CreateMannually";
import Profile from "./pages/CRMContacts/ContactsList/Profile";

export const RoutesArray = [
    { path: '/login', element: <Login /> },
    { path: '/logout', element: <Logout /> },
    { path: '/multiaccount', element: <MultipalUser /> },

    { path: '/', element: <Onboarding /> },
    { path: '/onboarding', element: <Onboarding /> },
    { path: '/dashboard', element: <Dashboard /> },
    { path: '/mobileApp', element: <MobileAPP /> },
    { path: '/create/folderDetails', element: <Folder /> },
    { path: '/create/goalActivity', element: <Goal /> },
    { path: '/create/createGoalActivity', element: <CreateGoal /> },
    { path: '/distribution/SMS', element: <DistributionSMS /> },
    { path: '/distribution/Mobile', element: <DistributionMobile /> },
    { path: '/landingpage/:id', element: <LandingPageCampaign /> },
    { path: '/Email/:id', element: <EmailCampaign /> },
    { path: '/survey/:id', element: <SurveyCampaign /> },
    { path: '/createContent/:id', element: <CreateContent /> },
    { path: '/SelectTemplate', element: <SelectTemplate /> },

    { path: '/campaignURL/:id', element: <CampaignURL /> },
    { path: '/embeddedCode/:id', element: <Embedded /> },
    { path: '/shareCampaign/:id/:type', element: <ShareCampaign /> },
    { path: '/shareFolder/:id', element: <ShareFolder /> },
    { path: '/folderContent/:id', element: <FolderContent /> },


    { path: '/shareCampaignDetails', element: <ShareCampaignDetails /> },
    { path: '/slugPublish/:id', element: <SlugCreate /> },

    { path: '/SMS/recipient', element: <RecipientScreen /> },
    { path: '/SMS/Send/:id', element: <SMSSend /> },
    { path: '/SMS/Goal/:id', element: <GoalSMS /> },
    { path: '/Email/recipient', element: <EmailRecipientScreen /> },
    { path: '/Email/Send/:id', element: <EmailSend /> },
    { path: '/Email/UTM/:id', element: <UTM /> },

    { path: '/Email/Emailsummary/:id', element: <EmailSummary /> },
    { path: '/SMS/SMSsummary/:id', element: <SMSSummary /> },
    { path: '/Mobile/recipient', element: <RecipientMobileScreen /> },
    { path: '/Mobile/Send/:id', element: <MobileSend /> },
    { path: '/Mobile/summary/:id', element: <MobileSummary /> },


    { path: '/create/templates', element: <Template /> },
    { path: '/create/templates/sms', element: <SMS /> },
    { path: '/create/templates/email', element: <Email /> },
    { path: '/create/templates/localSender', element: <LocalSender /> },
    { path: '/create/template/createSenderName', element: <CreateSenderName /> },
    { path: '/create/template/createTemplateSMS', element: <CreateTemplateLocalSender /> },

    { path: '/create/templates/unsubscribe', element: <Unsubscribe /> },

    { path: '/create/templates/unsubscribe/create', element: <CreateUnsubscribe /> },

    { path: '/create/templates/optTemplate', element: <AnonymousUnsubscribe /> },
    { path: '/create/templates/optTemplate/create', element: <CreateAnonymousUnsubscribeUnsubscribe /> },
    { path: '/create/templates/smsDoubleOptin', element: <SMSOPTIN /> },
    { path: '/create/templates/emailDoubleOptin', element: <EmailOPTIN /> },
    { path: '/create/templates/goalTemplate', element: <GoalTemplate /> },
    { path: '/create/templates/goal/CreateSMS', element: <CreateSMSGoal /> },
    { path: '/create/templates/goal/CreateLandingPageGoal', element: <CreateLandingPageGoal /> },
    { path: '/create/templates/goal/CreateEmail', element: <CreateEmailGoal /> },
    { path: '/create/templates/goal/CreateLandingPageConversionGoal', element: <LandingPageConversionGoalTemplate /> },
    { path: '/create/templates/goal/CreateEmailConversionGoal', element: <EmailConversionGoalTemplate /> },



    { path: '/create/templates/UTM', element: <UTMTemplate /> },
    { path: '/email/trigger/create', element: <CreateEmailTrigger /> },
    { path: '/emailOptIn/trigger/create', element: <CreateEmailOptTrigger /> },
    { path: '/smsOptIn/trigger/create', element: <CreateSMSOptTrigger /> },
    { path: '/sms/trigger/create', element: <CreateSMSTrigger /> },
    { path: '/create/templates/UTMcampaign', element: <CreateUTMCampaign /> },
    { path: '/create/templates/editUTMTemplate', element: <CreateTemplate /> },


    { path: '/insights/sms', element: < SMSInsight /> },
    { path: '/insights/smsDetails/:id', element: < SMSDetails /> },
    { path: '/insights/emailDetails/:id', element: < EmailDetails /> },
    { path: '/insights/email', element: < EmailInsight /> },
    { path: '/insights/landingPage', element: < LandingPageInsight /> },
    { path: '/insights/landingpageDetails/:id', element: < LandingPageDetails /> },
    { path: '/insights/survey', element: < Survey /> },
    { path: '/insights/surveyDetails/:id', element: < SurveyDetails /> },
    { path: '/insights/NPS', element: < NPS /> },
    { path: '/insights/Mobile', element: < MobileInsight /> },
    //{ path: '/CRM', element: < CRM /> },
    { path: '/crm/people/listings', element: < CRMContacts /> },
    { path: '/crm/people/joinlist', element: < CreateList /> },
    { path: '/crm/people/program', element: < LoyaltyProgram /> },
    { path: '/crm/people/importManage', element: < ImportStatus /> },
    { path: '/crm/people/subscribers/:id/:source', element: < ContactsList /> },
    { path: '/crm/people/subscribers/:id/:source/create', element: < ImportContacts /> },
    { path: '/crm/people/subscribers/:id/:source/customfieldsettings', element: < CustomFields /> },
    { path: '/crm/people/subscribers/:id/:source/segment/list', element: < Segments /> },
    { path: '/crm/people/subscribers/:id/:source/segment/create', element: < SegmentName /> },
    { path: '/crm/people/subscribers/:id/:source/segment/buildSegment/:segmentId', element: < SegmentCreate /> },
    { path: '/crm/people/subscribers/:id/:source/segment/view/:segmentId', element: < ViewSegment /> },
    { path: '/crm/people/subscribers/:id/:source/createmanual', element: < CreateMannually /> },
    { path: '/crm/people/subscribers/:id/:source/subscriberProfile', element: < Profile /> },

    { path: '/store/:id', element: < StoreList /> },
    { path: '/store/create', element: < StoreCreate /> },
    { path: '/store/edit/:id', element: < StoreEdit /> },



    { path: '/download', element: <Download /> },


    { path: '/myaccount', element: <MyAccount /> },
    { path: '/billing', element: <Billing /> },
    { path: '/configuration', element: <Configuration /> },
    { path: '/domainAuthenticate/:id', element: <DomainAuthenticate /> },
    { path: '/configDomain', element: <ConfigDomain /> },
    { path: '/configDomain/tag/create/:id', element: <CreateGoogleTag /> },
    { path: '/configDomain/analytics/create/:id', element: <CreateAnalytics /> },

    { path: '/configDomain/tagsManager', element: <GoogleTagDetails /> },
    { path: '/configDomain/googleAnalatics', element: <GoogleAnalaticsDetails /> },
    { path: '/customjavascript', element: <JavascriptList /> },
    { path: '/customJavascript/create/:id', element: <CreateJavascript /> },


]

