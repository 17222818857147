import React, { useEffect, useState } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import DataTable from 'react-data-table-component';

import { saveUploadedContact } from "../../../../Redux/actions/ActionImportContacts";

const Preview = ({ sendDataToParent }) => {
  // const pathArray = window.location.pathname.split("/");
  // const listID = _get(pathArray, "[4]", null); // get PKID from UR
  // const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

  const dispatch = useDispatch();
  const { uploadedContact } = useSelector(state => _get(state, "importContacts", {}));

  const [sampleDataTableColumns, setSampleDataTableColumns] = useState([]);

  useEffect(() => {
    let tmpSampleDataTableColumns = [];

    if (!_isEmpty(_get(uploadedContact, "sample_data", []))) {
      Object.keys(_get(uploadedContact, "sample_data.[0]", [])).map((title, i) => {
        return (
          tmpSampleDataTableColumns.push({
            name: title,
            selector: (item) => _get(item, `[${title}]`, ""),
            minWidth: "200px"
          })
        );
      });
    }
    setSampleDataTableColumns(tmpSampleDataTableColumns);
  }, [uploadedContact]);

  const onCancel = () => {
    dispatch(saveUploadedContact({}));
    sendDataToParent("upload_contacts");
  }

  return (
    <>
      <div className="bx-boder-common nobg my-12">
        <div className="_h_header white border-radius2">
          <h3>Here is a preview of your uploaded file. Does it look ok? If not, cancel and read our import guide
            &nbsp;<a className="text-07f inline-block fw-medium font-16" href="/">here</a>.
          </h3>

          <span className="flex-right"> Total number of rows : <strong>{_get(uploadedContact, "total_records", 0)}</strong></span>
        </div>

        <div className="white">
          <div className="step-preview">
            <div className="dataTable-primary dataTable-primary-510">
              <DataTable
                columns={sampleDataTableColumns}
                data={_get(uploadedContact, "sample_data", [])}
              />
            </div>

            <div className="p-5 b_top text-right">
              <button className="btn link-light" onClick={() => onCancel()}>Cancel</button>
              <button className="btn btn-success" onClick={() => sendDataToParent("mapping")}>Continue</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Preview;