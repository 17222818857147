import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react'
import getLocaleDateString from '../../TimeFormat';
import Client from '../../GlobalAPICall';
import ButtonLoader from '../../ReuseTemplate/ButtonLoader'
import ChargifyPayment from '../../ReuseTemplate/ChargifyPayment';
export default function ConfirmUpgradePlan(props) {
    const { Data, setData, APITOKEN, Reload } = props;
    const format = getLocaleDateString(APITOKEN.config.cultureCode);
    const [loader, setLoader] = useState({
        confirm: false
    });
    const [chargifyDisplay, setChargifyDisplay] = useState(false);

    const refreshPayMendInfo = useCallback(() => {
        Client.get('User/PaymentProfile', {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                setData(prv => ({
                    ...prv,
                    ...data
                }));
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.log(err));
    }, [APITOKEN, setData]);
    const confirmPlan = () => {
        setLoader({
            confirm: true
        })
        const obj = {
            "productId": Data.productId,
            "productPricePointId": 0,
            "includeCoupons": false,
            "includeInitialCharge": false,
            "includeTrial": false,
            "preservePeriod": false
        };
        debugger
        Client.post('User/Create_Product_Migration', obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            setLoader({
                confirm: false
            })
            try {
                if (data.statusCode === 200) {
                    setData({
                        display: false
                    })
                    Reload({ item: 47 })
                } else {
                    if (!!data.error.action_link) {
                        const makeurl = `${data['error']['action_link']}&redirect_url=${window.location.origin}/billing`;
                        window.location.replace(makeurl);
                    } else {
                        if (data['statusCode'] !== 200) {
                            setData(prv => ({
                                errorMessage: data.error.errors[0]
                            }))
                        } else {
                            setData({
                                display: false
                            })
                            Reload({ item: 47 })
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err =>
            setLoader({
                confirm: false
            }));
    }
    useEffect(() => {
        refreshPayMendInfo()
    }, [refreshPayMendInfo])
    return (
        <>
            <div className="upgrade_account_wrappermasking">
                <div className=" upgrade_account_wrapper">
                    <div className="topn-header">
                        <span className="selected-title">Confirm this plan change</span>
                        <span style={{ padding: '20px', float: 'right' }} onClick={() => setData(prv => ({ ...prv, display: false }))}>
                            <i className="fa fal fa-times clsbtnNoti fright pointer _balancePopRemove"></i></span>
                    </div>
                    <div className="">
                        <div className="slider_commin_height"><div className="light_yellow">
                            <div className="_h_header noborder" style={{ paddingLeft: '20px', paddingBottom: 0 }}>
                                <h3>Details</h3>
                            </div>
                            <table width="100%" className="__upgradplan_table" cellPadding="0" cellSpacing="0">
                                <thead>
                                    <tr>
                                        <th>Resource</th>
                                        <th width="59%">Details</th>
                                        <th>Total Cost</th>
                                    </tr>
                                </thead>
                                <tbody className="details_plan">
                                    {Data.resourceDetail && Data.resourceDetail.map((v, i) => <tr key={i}>
                                        <td>{v['resource']}</td>
                                        <td>{v['description']}</td>
                                        <td>{v['currencyCode']} {v['cost']}</td>
                                    </tr>)}
                                </tbody>
                            </table>
                            <table width="100%" className="__upgradplan_table b_top" cellPadding="0" cellSpacing="0">
                                <tbody className="total_details_plan">
                                    {Data.totalDetail && Data.totalDetail.map((v, i) => <tr key={i}>
                                        <td className={(i === 3) ? 'totalcost' : ''}>{v['totalHeader']}</td>
                                        <td width="59%">&nbsp;</td>
                                        <td className={(i === 3) ? 'totalcost' : ''}>{v['currencyCode']} {v['cost']}</td>
                                    </tr>)}
                                </tbody></table></div><p style={{ padding: '10px 20px' }}>Your subscription will renew again on <span className="renew_able">
                                    {moment(Data['subscriptionTillDate']).format(format)}</span>.</p>
                            {Data.errorMessage && <div className="paymentErrorMessage"><h2>Payment failed!</h2><p>{Data.errorMessage}</p></div>}
                            <div className="payment_method">
                                <table width="74%"
                                    style={{ marginTop: '30px' }} className="__upgradplan_table" cellPadding="0" cellSpacing="0">
                                    <thead>
                                        <tr>
                                            <th style={{ display: (Data['isCreditCard']) ? 'inline-block' : 'none', paddingBottom: '21px' }}>Payment Method</th>
                                        </tr></thead>
                                    <tbody>
                                        <tr>
                                            <td className="nopadding">
                                                <div style={{ display: (Data['isCreditCard']) ? 'inline-block' : 'none' }} className="isCreditCardshow">
                                                    <div className="card_info member__Card" style={{ width: '100%' }}>
                                                        <p className="card__logo">
                                                            <img alt='card' width={30} src={Data.cardType === 'visa' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/visa.png' : Data.cardType === 'master' ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/mastercard.png' : 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/amax.png'} />
                                                        </p>
                                                        <p className="cardMember_info">{Data.firstName} {Data.lastName}</p>
                                                        <p className="card__no">{Data.maskedCardNumber}</p>
                                                        <p className="card__date">{Data.expirationMonth}/{Data.expirationYear}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <button type="button" className="grid_btn_1 grid_btn_darkBlue __confirm__payment__btn" style={{ display: (Data['isCreditCard']) ? 'inline-block' : 'none' }} onClick={confirmPlan}>
                                        <ButtonLoader IsLoader={loader.confirm}>Confirm</ButtonLoader></button>
                                    <button style={{ display: (Data['isCreditCard']) ? 'none' : 'inline-block' }} type="button"
                                        className="grid_btn_1 grid_btn_darkBlue addCard_btn upgrad__card" onClick={() => setChargifyDisplay(true)}>Add card</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="upgradeMasking"></div>
                </div>
            </div>
            {chargifyDisplay && <ChargifyPayment setChargifyDisplay={setChargifyDisplay} reload={() => { }} />}
        </>
    )
}
