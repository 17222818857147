import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

const TrafficeSource = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const { PostObject } = props;
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 });

    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {

                colorByPoint: true,
                name: "Landing Page Traffic",
                pointWidth: 21,
            }
        ]
    });
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.post('Insights/targetSourceCount/00000000-0000-0000-0000-000000000000', PostObject, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    dataFilter(data)
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => console.error(err));
    }, [APITOKEN, PostObject]);

    const dataFilter = (data) => {
        const ar = [];
        data.forEach(function (v) {
            ar.push({ name: v['sources'], y: v['traffic'] });
        });
        setOptionValue({ series: [{ data: ar }] });
    }


    return (
        <>
            <h3 className="_chart_title padding-top20 padding20">Landing Page Traffic Sources</h3>
            <div className="b_top padding20"><HighchartsReact highcharts={Highcharts} options={optionData} /></div>
        </>
    )
}
export default TrafficeSource;