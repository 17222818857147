import { AxiosRequestMethods } from "../constants/network";

export const ENDPOINTS = {
  getNonce: {
    method: AxiosRequestMethods.GET,
    url: "/Authenticate/wallet/message",
  },
  verifyNonce: {
    method: AxiosRequestMethods.POST,
    url: "/Authenticate/token/wallet",
  },
  updateWallet: {
    method: AxiosRequestMethods.POST,
    url: "/User/wallet",
  },
  logout: {
    method: AxiosRequestMethods.POST,
    url: "/logout",
  },
  profile: {
    method: AxiosRequestMethods.GET,
    url: "/User/Profile",
  },
};
