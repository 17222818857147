
const Pagination = ({...props}) => {
    
    return (
        <div className="pagination excludeClass">
            <div className="text-right">
                <span className="pagination_count vertical-middle">
                    <span className="nextcount excludeClass">{props.pageCount + 1}</span>-<span className="pagesize excludeClass">{props.skip}</span> of <span className="_pageno totalCount">{props.totalCount.totalNumber}</span>
                </span>
                <button type="button" className="bigtext previousePage pointer excludeClass" disabled={props.buttonAction.previous} onClick={() => props.onClick(0)} ><i className="fas fa-caret-left"></i></button>
                <button type="button" className="bigtext previousePage  pointer excludeClass" disabled={props.buttonAction.nextPage} onClick={() => props.onClick(1)} ><i className="fas fa-caret-right"></i></button>
            </div>
        </div>
    )
}
export default Pagination;