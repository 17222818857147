import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Client from '../../../../GlobalAPICall';
import Tabing from './Tabing';
import BreadCrum from '../../../../ReuseTemplate/BreadCrum';
const GoalTemplate = () => {
    const checkIsAuth = useSelector(state => state);
    const [tabData, setTab] = useState([]);

    // Get All list from API Call
    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            Client.get('Insights/Dashboard/Goal/Categories', {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                setTab(res['data']);
            });
    }, [checkIsAuth]);
    return (<>
        <div className="smsHeader nomargin">
            <div className="dashbd_sec">
                <div className="left_h_p">
                    <h1 className="smstitle">Set your Goals</h1>
                    <p className="sms_content">Setting goals gives a long-term vision and short-term motivation. It focuses your acquisition of knowledge, and helps to organize time and resources to reach the goals and improve your ROI.</p>
                </div>
            </div>
        </div>
        <div className="dashbd_sec margin-top50">
            <BreadCrum To="/create/templates" ToText="Templates" CurrentPage="Goals" />
        </div>
        <div className="dashbd_sec margin-top50">
                <Tabing data={tabData}/>
            </div>
     </>)
}
export default GoalTemplate;