import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client"; // For rendering the React application
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS for styling
import "./index.css"; // Custom CSS for the application

import "@rainbow-me/rainbowkit/styles.css"; // RainbowKit styles for wallet connection UI
import App from "./App"; // Main application component
import { Provider, useDispatch, useSelector } from "react-redux"; // Redux hooks and Provider
import {
  setLogoFn,
  setNavigationToggleFn,
  setToken,
  deleteToken,
} from "./Redux/Action"; // Redux actions for managing state
import Cookies from "universal-cookie"; // For managing cookies
import { store, persistor } from "./Redux/Store"; // Redux store and persistor for state persistence
import { PersistGate } from "redux-persist/lib/integration/react"; // For persisting Redux state across sessions
import { WagmiProvider } from "wagmi"; // Wagmi provider for Ethereum-based apps
import { wagamiConfig } from "./config/wagami"; // Configuration for Wagmi provider
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"; // React Query for data fetching and caching
import { queryClientConfig } from "./config/queryClients"; // Configuration for React Query client
import { AppContextProvider } from "./contexts/AppContextProvider"; // Custom context provider for global state
import { ToastContainer } from "react-toastify"; // For displaying toast notifications
import { useAppContext } from "./contexts/AppContext";

// Environment variables
const url = process.env;
// Creating root element for React rendering
const root = ReactDOM.createRoot(document.getElementById("root"));
// Creating instance of cookies
const cookies = new Cookies();
// Getting secretKey from cookies
const secretKey = cookies.get("secretKey");

// Creating a new QueryClient instance with custom configuration
const queryClient = new QueryClient(queryClientConfig);

// LayoutProvider component manages authentication, layout, and theme settings
const LayoutProvider = () => {
  // Extracting state from Redux store using useSelector
  const { APITOKEN, NavigationToggleReduce } = useSelector((state) => state);
  // const { shouldUpdate } = useAppContext();
  // Using useDispatch hook to dispatch Redux actions
  const dispach = useDispatch();

  // Function to authenticate using secretKey from cookies
  const AuthScreatKey = () => {
    try {
      fetch(
        `${url.REACT_APP_API_URL}/Authenticate/config?secretKey=${secretKey}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          redirect: "follow",
          body: JSON.stringify({}),
        }
      )
        .then((response) => response.json())
        .then((data) => {
          try {
            if (data.statusCode > 300) {
              // If the response indicates failure, remove secretKey and redirect to login
              cookies.remove("secretKey");
              dispach(deleteToken());
              window.location.replace("/login");
            } else {
              // If authentication is successful, store the token and configuration in Redux
              dispach(
                setToken({
                  token: data?.token?.access_token,
                  TokenObject: { ...data?.token },
                  config: { ...data?.config },
                })
              );
              // Optionally set the logo using setLogoFn
              // dispach(setLogoFn(data?.config?.subscriptionInfo?.LogoUrl));
            }
          } catch (err) {
            console.log("err", err);
            // On error, remove secretKey and redirect to login
            cookies.remove("secretKey");
            dispach(deleteToken());
            window.location.replace("/login");
          }
        })
        .catch((err) => {
          console.log(err);
          // On error, redirect to login
          window.location.replace("/login");
        });
    } catch (err) {
      console.log(err);
    }
  };
  // useEffect(() => {
  //   AuthScreatKey();
  // }, [shouldUpdate]);

  // Check if secretKey exists, if not, redirect to login
  if (
    secretKey === undefined ||
    secretKey === "" ||
    secretKey === "undefined"
  ) {
    cookies.remove("secretKey");
    if (window.location.pathname !== "/redirect") {
      window.history.pushState("/login", "", "/login");
    }
  } else {
    // If secretKey exists and the user is on the login page, redirect to onboarding
    window.location.pathname === "/login" &&
      window.history.pushState("/onboarding", "", "/onboarding");
    // If the API token is not set, authenticate using the secretKey
    if (APITOKEN.token === "") {
      AuthScreatKey();
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    // Handle window resize to toggle navigation based on screen width
    function handleResize(e) {
      if (e.target.innerWidth < 1286) {
        dispatch(setNavigationToggleFn(true));
      } else {
        dispatch(setNavigationToggleFn(false));
      }
    }

    // Function to update favicon based on the current domain
    const faviconUpdate = async () => {
      const favicon = document.getElementById("favicon");
      if (favicon) {
        if (window.location.href.indexOf("promoti") !== -1) {
          favicon.href = "/favicon.ico";
        } else if (window.location.href.indexOf("movitext") !== -1) {
          favicon.href =
            "https://deoqpqrmgsvan.cloudfront.net/Favicon/movitext-favicon.ico";
        } else if (window.location.href.indexOf("pinnacle") !== -1) {
          favicon.href =
            "https://deoqpqrmgsvan.cloudfront.net/Favicon/pinnacle-favicon.ico";
        } else if (window.location.href.indexOf("alendei") !== -1) {
          favicon.href =
            "https://alendei.com/wp-content/uploads/2023/03/cropped-Untitled-design-2023-03-07T101243_492-32x32.webp";
        } else {
          favicon.href =
            "https://deoqpqrmgsvan.cloudfront.net/Favicon/favicon.ico";
        }
      }

      // Initialize and switch the language using Weglot
      await window._DEFAULT_Weglot.initialize({
        api_key: "wg_e1742716061cdf05e62161361edc48207",
        cache: true,
        hide_switcher: true,
        remove_unused_link_hooks: true,
      });

      await window._DEFAULT_Weglot.switchTo(localStorage.getItem("wglang"));
    };

    // Add resize event listener to update navigation state
    window.addEventListener("resize", handleResize);

    // Update favicon based on domain
    faviconUpdate();

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, NavigationToggleReduce, APITOKEN]);
};

// Render the application to the DOM
root.render(
  <>
    {/* Check if the user agent is Edge or Opera, and show a message if not supported */}
    {navigator.userAgent.includes("Edge") ||
    navigator.userAgent.includes("Operaa") ? (
      <div className="browserInfoContainer">This browser is not supported.</div>
    ) : (
      <>
        {/* Wrap the application with necessary providers */}
        <WagmiProvider config={wagamiConfig}>
          <QueryClientProvider client={queryClient}>
            <AppContextProvider>
              <ToastContainer className="ToastCustom" />
              <Provider store={store}>
                <PersistGate loading={false} persistor={persistor}>
                  <LayoutProvider />
                  <App />
                </PersistGate>
              </Provider>
            </AppContextProvider>
          </QueryClientProvider>
        </WagmiProvider>
      </>
    )}
  </>
);
