
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../../../GlobalAPICall";
import GenderPie from "./GenderPie";
import CityPie from "./CityPie";
import AgePie from "./AgePie";

const genderColor = ['#7bb5ec', '#90ed7d', '#434348'];
const cityColor = ['#7bb5ec', '#434348', '#90ed7d', "#f7a35c", "#8085e9", "#f15c7f", "#e5d453", "#2b908e", "#f45a5b", "#91e8e1"];
const Demographic = () => {
    const { id } = useParams();
    const checkIsAuth = useSelector(state => state);
    const [demographicData, setDemographic] = useState();

    useEffect(() => {
        (checkIsAuth['APITOKEN']['token'] !== "") &&
            // Demographic Insight  ---- Tabing Information 
            Client.get(`Insights/distribution/Email/${id}/Recipients/KPI?section=2`, {
                headers: {
                    'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
                }
            }).then((res) => {
                const newArry = [], cityArry = [], AgeArry = [];
                const Data = res['data'][0].KPI;
                const { Gender, City, Age } = Data[0];
                Gender.forEach(function (v, i) {
                    newArry.push({ name: v['Value'], y: v['Count'], color: (v['Count'] === 0) ? '#ccc' : genderColor[i] });
                });
                City.forEach(function (v, i) {
                    cityArry.push({ name: v['Value'], y: v['Count'], color: (v['Count'] === 0) ? '#ccc' : cityColor[i] });
                });
                Age.forEach(function (v, i) {
                    AgeArry.push({ name: v['Value'], y: v['Count'], color: (v['Count'] === 0) ? '#ccc' : cityColor[i] });
                });
                setDemographic([newArry, cityArry, AgeArry]);

            });
    }, [checkIsAuth, id]);
    return (
        <>
            {demographicData &&
                <>
                    <GenderPie ChartData={demographicData[0]} />
                    <CityPie ChartData={demographicData[1]} />
                    <AgePie ChartData={demographicData[2]} />
                </>
            }
        </>
    )
}
export default Demographic;