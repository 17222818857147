import { useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Client from "../../../../GlobalAPICall";
import SortableList, { SortableItem } from 'react-easy-sort'
import { arrayMoveImmutable } from 'array-move';
import GridAggregatedContainer from './GridAggregatedContainer'
import BestDay from "./BestDay";
import BestTime from './BestTime';
import InsightBlank from "../../../InsightBlank";
import LoaderBox from "../../../../ReuseTemplate/LoaderBox";
const SMSKPI = ({ tabId }) => {
    const { APITOKEN } = useSelector(state => state);
    const [dataAggrated, setdataAggrated] = useState({
        KPI: [],
        campaignName: "",
        isDisplay: true
    });
    const { id } = useParams();
    let [isPinned, setIsPinned] = useState([]);
    const loadApiCall = useCallback(async () => {

        try {
            (APITOKEN['token'] !== "") &&
                Client.get(`Insights/distribution/SMS/${id}/KPI?tabId=${tabId}`)
                    .then(({ data }) => {
                        const { KPI, campaignName } = data;
                        try {
                            setdataAggrated({
                                KPI: KPI || [],
                                campaignName: campaignName,
                                isDisplay: false
                            });
                            setIsPinned(KPI.filter(x => x.IsPinned));
                        } catch (err) {
                            console.log(err);
                        }
                    });
        } catch (err) {
            console.log(err);
        }
    }, [APITOKEN, tabId, id]);
    
    const onSortEnd = (oldIndex, newIndex) => {
        setdataAggrated((array) => {
            return { ...dataAggrated, KPI: arrayMoveImmutable(array['KPI'], oldIndex, newIndex) }
        })
    }
    const pinMethod = item => {
        if (APITOKEN['token'] !== "") {
            let response = null;
            if (item['IsPinned']) {
                response = Client.delete(`Insights/distribution/SMS/${id}/KPI/Pin/${item['Id']}`);
            } else {
                response = Client.put(`Insights/distribution/SMS/${id}/KPI/Pin/${item['Id']}`, {});
            }
            response.then(({ data }) => {
                data[0]['Status'] &&
                    loadApiCall();
            });
        }
    }
    useEffect(() => {
        loadApiCall();
    }, [loadApiCall])
    return (
        <><div className="bx-boder-common"><div className="_insight_header white"><h3>Overview - <span className="overviewtitle">{dataAggrated.campaignName}</span></h3></div><div className="white"><div className="white">{dataAggrated.isDisplay ? <div className="_overviewPin summary_o_v white"><LoaderBox RepeatArray={[1, 2, 3, 4]} /></div> : isPinned.length === 0 ? <InsightBlank /> : <SortableList onSortEnd={onSortEnd} className="_overviewPin summary_o_v white" draggedItemClassName="dragged">{dataAggrated['KPI'] && dataAggrated['KPI'].map((v, i) => {

            return v['IsPinned'] && <SortableItem key={i}>
                <div className="bx-boder-common bx detailsbx white" style={{ display: (v['IsPinned']) ? 'inline-block' : 'none', border: `2px solid ${(v['KPIBasedGoalAchieved'] === 1) ? v['KPIBasedGoalBorderColor'] : 'none'}` }} ><div className="drop-zone"><button className="pin" type="button" style={{ fontSize: "19px" }} onClick={e => pinMethod(v)} ><i className={(v['IsPinned']) ? 'fas fa-thumbtack' : 'fal fa-thumbtack'} style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'none' : 'inline-block' }}></i><i style={{ display: (v['KPIBasedGoalAchieved'] === 1) ? 'inline-block' : 'none', color: (v['KPIBasedGoalAchieved']) ? v['KPIBasedGoalRateOrQuantityColor'] : '' }} className="fas fa-trophy"></i></button><h3 className="title">{v['Name']}</h3><p className="content paddingbottom20">{v['Description']}</p></div><div className="px"><table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary"><tbody><tr style={{ visibility: (v['CountLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['CountLabel']}</td><td className=" -tvl"><strong style={{ color: v['PinCountColor'] }}>{v['Count']}</strong></td></tr><tr style={{ visibility: (v['RateLabel'] === undefined) ? 'hidden' : 'visible' }}><td>{v['RateLabel']}</td><td className="-tvl link"><strong style={{ color: v['PinRateColor'] }}>{v['Rate']}%</strong></td></tr></tbody></table></div></div>
            </SortableItem>
        })}</SortableList>}</div><div className="-n-d-p"></div></div></div> <div className="overviewDaychart margin-top50 bx-boder-common white"><div className="chartharf  padding-right50" style={{ "width": "51%" }}><BestDay id={id} APITOKEN={APITOKEN} /></div><div className="chartharf padding-right50" style={{ "width": "49%" }}><BestTime id={id} APITOKEN={APITOKEN} /></div></div><div className="overviewtable margin-top50 bx-boder-common white"><div className="_h_header"><h3>Aggregated SMS Insights Last 30 Days</h3></div><div className="aggreategrid"><GridAggregatedContainer tabId={tabId} dataAggrated={dataAggrated} setdataAggrated={setdataAggrated} onClick={e => pinMethod(e)} /></div></div></>
    )
}

export default SMSKPI;





