import { useState } from "react";
import GrowthChannel from "./GrowthChannel";
import Trend from "./Trend";
import Subscribed from "./Subscribed";
import UnSubscribed from "./UnSubscribed";


const CRMListDetails = ({ ...props }) => {
    const [tabId, setTabid] = useState(1);
    const { ListObject, SetListDetailShow } = props;
    const clickmethod = (n) => {
        setTabid(n)
    }
    return (
        <div className="-subdetails-container">
            <div className="topn-header">List Growth Details
                <span style={{ padding: '20px', float: 'right' }} onClick={() => SetListDetailShow(prv => ({ ...prv, flag: false, id: '' }))}><i className="fa fal fa-times clsbtnlg fright pointer"></i></span></div><div className="padding20 growth-b"><p style={{ fontSize: '16px' }}>Details about your List trend.</p><div style={{ marginTop: '15px' }} className="scrollbar">
                    <section className="bx-boder-common tbbtm n-bx _h_header">
                        <ul className="g-listul">
                            <li><button type="button" onClick={() => clickmethod(1)} className={1 === tabId ? 'active ' : ''}>List Trend</button></li>
                            <li><button type="button" onClick={() => clickmethod(2)} className={2 === tabId ? 'active ' : ''}>Subscribed</button></li>
                            <li><button type="button" onClick={() => clickmethod(3)} className={3 === tabId ? 'active ' : ''}>Unsubscribed</button></li>
                        </ul>
                    </section>
                    {tabId === 1 ?
                        <section className="g-container-">
                            <div className="width100 bx-boder-common inline-block tb">
                                <Trend ListObject={ListObject} />
                            </div>
                            <GrowthChannel ListObject={ListObject} />
                        </section>
                        : tabId === 2 ?
                            <section className="g-container-">
                                <div id="growthSinglesubcribeList" className="width100 bx-boder-common inline-block tb">
                                    <Subscribed ListObject={ListObject} />
                                    {/* <p className="font-16" style={{ padding: '18% 0', textAlign: 'center' }}>No Data</p> */}
                                </div>
                            </section>
                            :
                            <section className="g-container-">
                                <div id="growthSingleUbscribeList" className="width100 bx-boder-common inline-block tb">
                                <UnSubscribed ListObject={ListObject} />
                                    {/* <p className="font-16" style={{ padding: '18% 0', textAlign: 'center' }}>No Data</p> */}
                                </div>
                            </section>
                    }
                </div></div></div>
    )

}

export default CRMListDetails
