import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Client from "../../GlobalAPICall";

const Embedded = () => {
    const { APITOKEN } = useSelector(state => state);
    const [getData, setDataObject] = useState([]);
    const [iframeValue, setIframeProperty] = useState({ width: 629, height: 500 });
    const { id } = useParams();

    const load = useCallback(() => {
        (APITOKEN['token'] !== "") &&
            Client.get(`Campaign/${id}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                setDataObject(res['data']);
            });
    }, [APITOKEN, id])
    useEffect(() => {
        load();
    }, [load])
    const copy = (id) => {
        var copyText = document.getElementById(id);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
    }
    return (
        <>
            <div className="dashbd_sec">&nbsp;</div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Embed Value</h3>
                        <span className="flex-right">
                            <p style={{ paddingRight: '150px', fontSize: '16px' }}>Actions</p>
                        </span>
                    </div>
                    <div className="padding20">
                        <div className='sms_Sec_1 inline-block nomargin'>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">URL</label>
                                <input type="text" className="inputformmodel" readOnly id="url" defaultValue={getData.ShortUrl} onChange={e => e.stopPropagation()} />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Code</label>
                                <textarea className="inputformmodel textareaModel" id="iframe" readOnly value={`<iframe  src="${getData['ShortUrl']}" width="${iframeValue.width}" height="${iframeValue.height}" frameborder="0" webkitAllowFullScreen mozallfullscreen allowFullscreen></iframe>`} onChange={e => e.stopPropagation()} ></textarea>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Size</label>
                                <input type="text" className="inputformmodel margin-right15" onChange={e => { e.stopPropagation(); setIframeProperty({ ...iframeValue, ...{ width: e.target.value } }) }} value={iframeValue.width} style={{ width: '100px' }} />
                                <input type="text" className="inputformmodel" value={iframeValue.height} onChange={e => { e.stopPropagation(); setIframeProperty({ ...iframeValue, ...{ height: e.target.value } }) }} style={{ width: '100px' }} />
                            </div>
                        </div>
                        <div className="-action-section">
                            <label className="_l_able_cls">
                                <button type="button" className="inline-block link nobg noborder pointer" onClick={() => copy('url')}>Copy</button>
                                <a href={getData.ShortUrl} target="_blank" rel="noreferrer" className="inline-block link nobg noborder pointer">Open</a>
                            </label>
                            <label className="_l_able_cls" style={{ marginTop: '76px' }}>
                                <button type="button" className="inline-block link nobg noborder nopadding pointer" onClick={() => copy('iframe')}>Copy</button>
                            </label>
                        </div>
                    </div>
                </div>
                <div className="sms_Sec_1 bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Embed Preview</h3>
                    </div>
                    <div className="padding20 width100 inline-block">
                        <div className="frampreview" style={{margin: 'auto'}}>
                            <iframe title="ss" src={getData['ShortUrl']}  width={iframeValue.width} height={iframeValue.height}></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Embedded;