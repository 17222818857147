import BlankData from "../BlankData";

const DeleteListTemplate = ({ onClick, deleteDownload, ...props }) => {
    return (
        <div className="white bx-boder-common width100 white">
            <div className="_h_header"><h3>Delete</h3></div>
            <table cellPadding="0" cellSpacing="0" width="100%" className="smstable ">
                <tbody>
                    <BlankData title={props.title} content={props.content} colSpan={props.colSpan} />
                </tbody>

            </table>
            <div className="text-right padding20">
                {props.children}
                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={onClick}>Cancel</button>
                <button type="button" className="grid_btn_1  deleteBtn  _confirm" onClick={deleteDownload}>Confirm</button>
            </div>
        </div>
    )
}
export default DeleteListTemplate;


const DeleteTemplateForCampaign = ({ onClick, deleteDownload, ...props }) => {
    return (
        <div className="white bx-boder-common width100 white">
            <div className="_h_header"><h3>Delete Template</h3></div>
            <table cellPadding="0" cellSpacing="0" width="100%" className="smstable ">
                <tbody>
                    <BlankData title={props.title} content={props.content} colSpan={props.colSpan} />
                </tbody>

            </table>
            <div className="text-right padding20">
                {props.children}
                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={onClick}>Cancel</button>
                <button type="button" className="grid_btn_1 nobg noborder deleteBtn margin-right15" onClick={() => deleteDownload(true)}>Delete with Insight</button>
                <button type="button" className="grid_btn_1  deleteBtn  _confirm" onClick={() => deleteDownload(false)}>Delete without Insight</button>
            </div>
        </div>
    )
}
export { DeleteTemplateForCampaign };


const DeleteFolderTemplateForCampaign = ({ onClick, deleteDownload, ...props }) => {
    return (
        <div className="white bx-boder-common width100 white">
            <div className="_h_header"><h3>Delete Template</h3></div>
            <table cellPadding="0" cellSpacing="0" width="100%" className="smstable ">
                <tbody>
                    <BlankData title={props.title} content={props.content} colSpan={props.colSpan} />
                </tbody>

            </table>
            <div className="text-right padding20">
                {props.children}
                <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={onClick}>Cancel</button>
                <button type="button" className="grid_btn_1 nobg noborder deleteBtn margin-right15" onClick={() => deleteDownload(1)}>Delete with Content</button>
                <button type="button" className="grid_btn_1  deleteBtn  _confirm" onClick={() => deleteDownload(0)}>Delete Only Folder</button>
            </div>
        </div>
    )
}
export { DeleteFolderTemplateForCampaign };




export const DeviceLoader = () => {
    return (
        <>  <div className="bx-boder-common margin-bottom">
            <div className="_insight_header white">
                <h3 className="skeleton-loader" style={{ height: '25px', width: '300px' }}>&nbsp;</h3>
            </div>
            <div className="padding20 white ">
                <span className="skeleton-loader" style={{ height: '225px' }}></span>
            </div>
            <div className="padding20 white">
                <div className="_overviewPin _overviewPin_Device white summary_o_v">
                    {[1, 2, 3].map((v, index) => {
                        return <div className="bx-boder-common bx detailsbx white" key={index}>
                            <div className="drop-zone"><h3 className="skeleton-loader" style={{ height: '20px' }}>&nbsp;</h3>
                                <p className="content paddingbottom20">&nbsp;</p></div><div className="px">
                                <table cellPadding="0" cellSpacing="0" width="100%" className="-details-summary">
                                    <tbody>
                                        {
                                            [1, 2].map(($v, i) =>

                                                <tr key={i}>

                                                    <td className="nopadding">
                                                        <table cellPadding="0" cellSpacing="0" width="100%" >
                                                            <tbody>
                                                                <tr>
                                                                    <td><span className="skeleton-loader" style={{ height: '15px', width: '50px' }}></span></td>
                                                                    <td className="-tvl">
                                                                        <span className="skeleton-loader" style={{ height: '15px', width: '100px' }}></span>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span className="skeleton-loader" style={{ height: '15px', width: '50px' }}></span></td>
                                                                    <td className="-tvl link">
                                                                        <span className="skeleton-loader" style={{ height: '15px' }}></span>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>

                                                </tr>


                                            )

                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    })}
                </div>
            </div>

        </div>
        </>
    )
}
