import BlankData from "../../../../BlankData"
import GridLoader from "../../../../ReuseTemplate/GridLoader";

const GridAggregated = ({ dataPin, onClick, tabId, IsDisplay, showCompleteDetails }) => {
    return (
        <>
            {IsDisplay ? <GridLoader TDArray={[1, 2, 3, 4, 5, 6, 7, 8]} /> :
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">{dataPin.length > 0 && <thead><tr><th className="table_cell_header">KPI</th><th className="table_cell_header">Description</th><th className="table_cell_header">Status</th><th className="table_cell_header">Status %</th><th className="table_cell_header">Goal (clicked)</th><th className="table_cell_header">Goal (%)	</th><th className="table_cell_header">Achived</th><th className="table_cell_header">Action</th></tr></thead>}<tbody>{dataPin.length === 0 ? <BlankData title="You have not sent any SMS" content="After you have sent an SMS you will find the aggredated SMS Insight for the Last 30 Days here." colSpan="6" /> : <Grid dataPin={dataPin} onClick={onClick} tabId={tabId} showCompleteDetails={showCompleteDetails} />}</tbody></table>
            }
        </>
    )
}
export default GridAggregated;

const Grid = ({ dataPin, tabId, showCompleteDetails }) => {
    return (
        <>
            {dataPin && dataPin.map((v, i) =>
                <tr key={i}>
                    <td className="body_table_cell">{v['Name']}</td>
                    <td className="body_table_cell">{v['Description']}</td>
                    <td className="body_table_cell" style={{ color: v['CountColor'], paddingLeft: '10px' }}>
                        <strong style={{ display: (v['Count'] === -1) ? 'none' : 'table-cell' }} onClick={e => v['Count'] === 0 ? e.stopPropagation() : showCompleteDetails(v)} className={(v['Count'] === 0) ? '' : (v['Id'] === 74) ? '' : 'link pointer completeDetails'}>{(v['IsConversionRevenue']) ? v['FormattedConversionRevenueCount'] : v['Count']}</strong></td>
                    <td className="body_table_cell" style={{ color: v['RateColor'] }}>
                        <span style={{ display: (v['IsConversionRevenue']) ? 'table-cell' : (v['Rate'] === -1) ? 'none' : 'table-cell' }}>
                            {(v['IsConversionRevenue']) ? '-' : v['Rate'] + '%'}
                        </span>
                    </td>
                    <td className="body_table_cell">
                        {(v['IsGoalSetup']) ? (v['IsConversionRevenue']) ? v['FormattedConversionRevenueGoal'] : v['KPIBasedGoalQuantity'] : 'NA'}
                    </td>
                    <td className="body_table_cell" >
                        {(v['IsConversionRevenue']) ? '-' : (v['KPIBasedGoalSetup']) ? v['KPIBasedGoalRate'] + '%' : 'N/A'}
                    </td>
                    <td className="body_table_cell"><i style={{
                        color: tabId === 30 ?
                            v['KPIBasedGoalRateOrQuantityColor'] :
                            v['KPIBasedGoalRateColor'], fontSize: '19px', display: (v['IsGoalSetup'] ? (v['KPIBasedGoalSetup']) ? 'table-cell' : 'none' : 'none')
                    }} className="fas fa-trophy"></i>
                    </td>
                    <td className="body_table_cell">
                        <button type="button" className="link pinunpin">{(!v['IsPinned']) ? ' Pin' : 'Remove Pin'}</button>
                    </td>
                </tr>
            )
            }
        </>
    )
}
