import { useNavigate } from "react-router"
import BreadCrum from "../../../../ReuseTemplate/BreadCrum"
import { useCallback, useEffect, useState } from "react";
import './index.css'
import getParameterByName from "../../../../ReuseTemplate/GetRoute";
import Client from "../../../../GlobalAPICall";
import { useSelector } from "react-redux";
function CreateAnonymousUnsubscribeUnsubscribe() {
    const navigate = useNavigate();
    const { APITOKEN } = useSelector(state => state);

    const [UnSubscribeButton, setUnSubscribeButton] = useState({
        text: true,
        email: false,
        sms: false,
        notFound: false
    });
    
    const [unsubscribeObject, setUnsubscribeObject] = useState({
        Header: "Please enter your email address or mobile number to verify who you are.",
        UnsubscribeText: "You will receive an activation link that you have to confirm to opt-out.",
        Button: "Submit",
        Confirmation: "We have sent you an activation link.",
        Email: {
            Text: "Email Verification",
            Template: [],
            Selected: ""

        },
        SMS: {
            Text: "Mobile Number Verification",
            Template: [],
            Selected: ""
        },
        NotFound: "There is no user with the given email / mobile number in our database."

    })

    const smsLoad = useCallback(() => {
        APITOKEN['token'] &&
        Client.get(`Trigger/Templates/SMS`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                setUnsubscribeObject(prv => ({
                    ...prv,
                    SMS: {
                        ...prv.SMS,
                        Template: res.data
                    }
                }))
            } catch (err) {
                console.error(err);
            }
            //setTemplates(res['data']);
        }).catch(err => console.error(err));
    }, [APITOKEN])
    const emailLoad = useCallback(() => {
        APITOKEN['token'] &&
        Client.get(`Trigger/Templates/Email`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then((res) => {
            try {
                setUnsubscribeObject(prv => ({
                    ...prv,
                    Email: {
                        ...prv.Email,
                        Template: res.data
                    }
                }))
            } catch (err) {
                console.error(err);
            }
            //setTemplates(res['data']);
        }).catch(err => console.error(err));
    }, [APITOKEN])


    const saveFn = () => {
        const templateId = getParameterByName('cam', window.location.href);
        console.log(unsubscribeObject);
        const obj = {
            "opt_top_label": unsubscribeObject.Header,
            "opt_top_header": unsubscribeObject.UnsubscribeText,
            "opt_top_search": unsubscribeObject.Button,
            //"opt_submit_text": unsubscribeObject.Undo.Text,
            "opt_template_id": templateId,
            "opt_templatetype": "14",
            "anonymousOptOutEmailStatus": unsubscribeObject.Email ? 1 : 0,
            "anonymousOptOutSMSStatus": unsubscribeObject.SMS ? 1 : 0,
            "opt_update_only_text" : unsubscribeObject.Confirmation,
            "opt_email_text": unsubscribeObject.Email.Text,
            "opt_sms_text": unsubscribeObject.SMS.Text,
            "opt_thanks_content": unsubscribeObject.NotFound,
            "emailSMSTriggerId":JSON.stringify({email:unsubscribeObject.Email.Selected,sms:unsubscribeObject.SMS.Selected}),
            //"listId":garray,
            //"excludedLists":$scope.groupExcluded,
        };
        
        Client.put(`Unsubscribe/UnsubscribeTemplate?templateId=${templateId}`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                if (res.data.success) {
                    navigate(`/create/templates/optTemplate?type=14`);
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
    }
    const edit_temFn = useCallback(() => {
        const templateId = getParameterByName('cam', window.location.href);
        APITOKEN['token'] &&
        Client.get(`Unsubscribe/UnSubscribeTemplate?typeid=AnonymousUnSubscribe&templateId=${templateId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                const data = res.data;
                setUnsubscribeObject({
                    Header: data.opt_top_label,
                    UnsubscribeText: data.opt_top_header,
                    Button: data.opt_top_search,
                    Confirmation: data.opt_update_only_text,
                    Email: {
                        Text: data.opt_email_text,
                        Template: [],
                        Selected: JSON.parse(data.emailSMSTriggerId).email
                    },
                    SMS: {
                        Text: data.opt_sms_text,
                        Template: [],
                        Selected: JSON.parse(data.emailSMSTriggerId).sms
                    },
                    NotFound: data.opt_thanks_content
                });
                setUnSubscribeButton({
                    text: true,
                    email: true,
                    sms: true,
                    notFound: true
                })
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));

    }, [APITOKEN])

    useEffect(() => {
        smsLoad();
        emailLoad();
        if (getParameterByName('edit', window.location.href) !== null) {
            edit_temFn();
        }
    }, [smsLoad, emailLoad, edit_temFn])
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle">Create Anonymous Web Unsubscribe</h1>
                        <p className="sms_content">Here you can create SMS and Email Triggers, Opt-out link and Birthday Automation. Get started on those triggers!</p>
                    </div>
                </div>
            </div>
            <div className="dashbd_sec margin-top50">
                <BreadCrum To="/create/templates/optTemplate?type=14" ToText="Unsubscribe" CurrentPage="Anonymous Web Unsubscribe" />
            </div>
            <div className="dashbd_sec">
                <div className="sms_Sec_1 bx-boder-common inline-block margin-bottom">
                    <div className="_h_header relative">
                        <h3>Unsubscribe</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1" onClick={() => setUnSubscribeButton(prv => ({
                                ...prv,
                                text: !prv.text
                            }))}>{UnSubscribeButton.text ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.text ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Header</label>
                                <textarea className="inputformmodel textareaModel"
                                    value={unsubscribeObject.Header}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Header: e.target.value
                                    }))}
                                ></textarea>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <textarea className="inputformmodel textareaModel"
                                    value={unsubscribeObject.UnsubscribeText}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        UnsubscribeText: e.target.value
                                    }))}
                                ></textarea>
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Submit Button Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Button}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Button: e.target.value
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Confirmation Message</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Confirmation}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Confirmation: e.target.value
                                    }))}
                                />
                            </div>

                        </div>
                        : <></>}
                    <div className="_h_header relative b_top">
                        <h3>Opt-out Email</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1"
                                onClick={() => setUnSubscribeButton(prv => ({
                                    ...prv,
                                    email: !prv.email
                                }))}>{UnSubscribeButton.email ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.email ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.Email.Text}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Email: {
                                            ...prv.Email,
                                            Text: e.target.value
                                        }
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Email Trigger Template</label>
                                <select className="inputformmodel" value={unsubscribeObject.Email.Selected}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        Email: {
                                            ...prv.Email,
                                            Selected: e.target.value
                                        }
                                    }))}
                                >
                                    <option value="">Select</option>
                                    {unsubscribeObject.Email.Template.map((v, i) => <option key={i} value={v.id}>{v.name}</option>)}
                                </select>
                            </div>
                        </div>
                        : <></>}
                    <div className="_h_header relative">
                        <h3>Opt-out SMS</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1"
                                onClick={() => setUnSubscribeButton(prv => ({
                                    ...prv,
                                    sms: !prv.sms
                                }))}>{UnSubscribeButton.sms ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.sms ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.SMS.Text}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        SMS: {
                                            ...prv.SMS,
                                            Text: e.target.value
                                        }
                                    }))}
                                />
                            </div>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">SMS Trigger Template</label>
                                <select className="inputformmodel" value={unsubscribeObject.SMS.Selected}

                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        SMS: {
                                            ...prv.SMS,
                                            Selected: e.target.value
                                        }
                                    }))}
                                >
                                    <option value="">Select</option>
                                    {unsubscribeObject.SMS.Template.map((v, i) => <option key={i} value={v.id}>{v.name}</option>)}
                                </select>

                            </div>
                        </div>
                        : <></>}
                    <div className="_h_header relative">
                        <h3>User not Found</h3>
                        <span className="flex-right">
                            <button type="button" className="grid_btn_1"
                                onClick={() => setUnSubscribeButton(prv => ({
                                    ...prv,
                                    notFound: !prv.notFound
                                }))}>{UnSubscribeButton.notFound ? 'Edit' : 'Add'}</button>
                        </span>
                    </div>
                    {UnSubscribeButton.notFound ?
                        <div className="bx-pad">
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Text</label>
                                <input className="inputformmodel"
                                    value={unsubscribeObject.NotFound}
                                    onChange={e => setUnsubscribeObject(prv => ({
                                        ...prv,
                                        NotFound: e.target.value

                                    }))}
                                />
                            </div>
                        </div>
                        : <></>}

                    <div className="formmodel-bx nomargin text-right padding20">
                        <button type="button"
                            className="grid_btn_1 nobg noborder"
                            onClick={() => navigate(`/create/templates/optTemplate?type=14`)}>Cancel</button>
                        <button type="button" className="grid_btn_1 grid_btn_active" 
                        disabled={unsubscribeObject.Button !== '' && 
                            unsubscribeObject.Email.Selected !== "" &&
                            unsubscribeObject.SMS.Selected !== "" ? false : true} onClick={saveFn}
                        >Save</button>

                    </div>
                </div>
                <div className="previewPanel">
                    <div className="iphonePriview">
                        <div className="ovflow_preview">
                            {UnSubscribeButton.text ?
                                <div className=" perivewHeader lightblue">
                                    <div className="heading">{unsubscribeObject.Header}</div>
                                    <p>{unsubscribeObject.UnsubscribeText}</p>
                                </div>
                                : <></>}

                            <div className="perivewHeader">
                                {UnSubscribeButton.email ?
                                    <div className="formmodel-bx">
                                        <h2 className="font-16">{unsubscribeObject.Email.Text}</h2>
                                        <input type="text" className="inputformmodel" placeholder="abc@example.com" />
                                    </div>
                                    : <></>}
                                {UnSubscribeButton.sms ?
                                    <div>
                                        <h2 className="font-16">{unsubscribeObject.SMS.Text}</h2>
                                        <input type="text" className="inputformmodel formmodel-bx" placeholder="998857545" />
                                        <div className="">
                                            <button type="button" className="btn_green">{unsubscribeObject.Button}</button>
                                        </div>
                                    </div>
                                    : <></>}
                            </div>


                            {/* {UnSubscribeButton.reasons ?
                                <>
                                    
                                    <div className="perivewHeader">
                                        <h2 className="font-16">{unsubscribeObject.Reasons.Header}</h2>
                                        {unsubscribeObject.Reasons.ReasonUnsubscribe.map((v, i) => 
                                            v.isSelected ? <p key={i} className="drop-zone fromPreview">
                                                <input type="radio" className="inline-block vertical-middle margin-right10"
                                                    onChange={e => e.stopPropagation()} name="radioUnsubscribe" />
                                                <span className="prradio">{v.text}</span>
                                            </p> : <></>)}
                                    </div>
                                    <div className="restorebtn">
                                        <button type="button" className="btn_green">{unsubscribeObject.Reasons.Button}</button>
                                    </div>
                                </>
                                : <></>} */}
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}

export default CreateAnonymousUnsubscribeUnsubscribe
