import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import LastSend from "./LastSend";
import CRMList from "./CRMList";
import SendingSMSEmail from "./SendingSMSEmail";
import SendingnotDone from "./SendingnotDone";
import LastActivityOverView from "./LastActivityOverView";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import KPIFilter from "./KPIFilter";
import ScheduledSedningDashboard from "./ScheduledSedningDashboard";
import axios from "axios";
import ApprovalRequest from "./ApprovalRequest";
import ApprovalMakerRequest from "./ApprovalMakerRequest";
const Tabing = ({ data }) => {
  const { APITOKEN } = useSelector((state) => state);
  const [recordObject, setdataRecord] = useState([]);
  const [isLoaderDisplay, setLoaderDisplay] = useState(true);
  const [kpiFilterDisplay, setKpiFilterDisplay] = useState(false);
  const [overviewPin, setdataOverViewPin] = useState([]);
  const [isDisplay, setDisplay] = useState(true);
  const defaultId = 22;
  const [tabId, setTabid] = useState(defaultId);
  const clickmethod = (item) => {
    setTabid(item.id);
  };

  const loadApiCall = useCallback(async () => {
    const cancelToken = axios.CancelToken.source();
    try {
      APITOKEN["token"] !== "" &&
        Client.get(
          `Insights/dashboard/LastSending/KPI?noofrecords=3&domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
          {
            cancelToken: cancelToken.token,
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then((res) => {
            try {
              setdataRecord(res["data"]);
              setLoaderDisplay(false);
            } catch (err) {
              console.log(err);
            }
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.error(err);
            }
            console.error(err);
          });
      return () => {
        cancelToken.cancel();
      };
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false); // <-- set loading false when done no matter what
    }
  }, [APITOKEN]); // <-- add any missing dependencies react warns about
  const loadApiOverviewCall = useCallback(async () => {
    const cancelToken = axios.CancelToken.source();

    try {
      APITOKEN["token"] !== "" &&
        Client.get(
          `Insights/dashboard/Default/22/KPI?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
          {
            cancelToken: cancelToken.token,
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then((res) => {
            try {
              setDisplay(false);
              setdataOverViewPin(res["data"]);
            } catch (err) {
              if (axios.isCancel(err)) {
                console.error(err);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      return () => {
        cancelToken.cancel();
      };
    } catch (err) {
      console.log(err);
    } finally {
      //console.log(false);
    }
  }, [APITOKEN]); // <-- add any missing dependencies react warns about
  const kpifilter = () => {
    setKpiFilterDisplay(true);
  };
  useEffect(() => {
    loadApiCall();
    loadApiOverviewCall();
  }, [loadApiCall, loadApiOverviewCall]);
  return (
    <>
      <div className="mycontent">
        <ul className="tabing">
          {data &&
            data.map((item, index) => {
              return (
                <li key={index} onClick={() => clickmethod(item)}>
                  <Link
                    className={item.id === tabId ? "active " : " "}
                    id={item.id}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
      {
        <div>
          {tabId === defaultId ? (
            <div className="tab_body">
              <div className="_tab_content">
                <div className="bx-boder-common">
                  <div className="_insight_header white">
                    <h3>
                      KPI Overview{" "}
                      <button
                        type="button"
                        className="grid_btn_1 nobg  vertical-middle fright kpifilterlink"
                        onClick={kpifilter}
                      >
                        KPI Filter
                      </button>
                    </h3>
                    <p className="content">
                      Last 30 Days aggregation. Green and red percent values are
                      compared with previous 30 Days.
                    </p>
                  </div>
                  <div className="white">
                    <LastActivityOverView
                      Data={overviewPin}
                      SetData={setdataOverViewPin}
                      IsDisplay={isDisplay}
                    />
                  </div>
                </div>
                {recordObject.length > 0 && (
                  <LastSend Data={recordObject[0]} APITOKEN={APITOKEN} />
                )}
                <CRMList />
                {recordObject.length > 0 && (
                  <SendingSMSEmail
                    Data={recordObject}
                    IsLoaderDisplay={isLoaderDisplay}
                    APITOKEN={APITOKEN}
                  />
                )}
                <SendingnotDone />
                {kpiFilterDisplay ? (
                  <div className="maskIngKPi">
                    <KPIFilter
                      Close={setKpiFilterDisplay}
                      Load={loadApiOverviewCall}
                      APITOKEN={APITOKEN}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          ) : tabId === 92 ? (
            <ApprovalRequest APITOKEN={APITOKEN} />
          ) : tabId === 93 ? (
            <ApprovalMakerRequest APITOKEN={APITOKEN} />
          ) : (
            <ScheduledSedningDashboard />
          )}
        </div>
      }
    </>
  );
};

export default Tabing;
