import { createContext, useContext } from "react";
import { AuthenticationStatus } from "../utils/constants/enums";

export const AppContext = createContext({
  authStatus: AuthenticationStatus.LOADING,
  user: null,
});

AppContext.displayName = "AppAuthContext";

export function useAppContext() {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within the AppContextProvider");
  }
  return context;
}
