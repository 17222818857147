import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import Client from '../../../GlobalAPICall';
import DeleteListTemplate from '../../../ReuseTemplate';
import BlankData from '../../../BlankData';
import GridLoader from '../../../ReuseTemplate/GridLoader';

function ViewPanel({ dispatch, state, object, APITOKEN }) {
    const [data, setData] = useState([]);
    const [deleteShow, setDeleteShow] = useState({
        id: "",
        flag: false
    });
    const [loader, setLoader] = useState(false);
    const load = useCallback(() => {
        setLoader(true);
        const cancelToken = axios.CancelToken.source();
        const url = object.mediaId === 1 ? `Mobile/AppTracksPlayList?playListId=${object.playListId}&peopleSubscriberId=0&skip=0&limit=100` : `Mobile/AppVideosByPlayList?playListId=${object.playListId}&peopleSubscriberId=0&skip=0&limit=100`;
        Client.get(url, {
            cancelToken: cancelToken.token,
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            setLoader(false);
            try {
                setData(data);
            } catch (err) {
                console.error(err);
                setLoader(false);
            }
        }).catch(err => {
            if (axios.isCancel(err)) {
                cancelToken.cancel();
            }
            setLoader(false);
        });
    }, [object, APITOKEN]);
    const deleteFn = ev => {
        setDeleteShow({ id: object.mediaId === 1 ? ev.trackId : ev.videoId, flag: true });
    }

    const confirmDelete = () => {
        const url = object.mediaId === 1 ? `Mobile/AppTracksPlayList?playListId=${object.playListId}&peopleSubscriberId=0&trackId=${deleteShow.id}` : `Mobile/AppVideoFromPlayList?playListId=${object.playListId}&peopleSubscriberId=0&videoId=${deleteShow.id}`;
        Client.delete(url, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                load();
                setDeleteShow({ id: "", flag: false });
            } catch (err) {
                console.error(err);
            }
        }).catch(err => console.error(err));
    }

    useEffect(() => {
        load()
    }, [load])
    return (
        <div className='createMaskingPlaylist'>
            <div className="trackContainer">
                <div className='topn-header'>
                    <span className='selected-title'>{state.flagTitle} PlayList</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={() => {
                        dispatch({
                            type: 'VIEW',
                            payload: {
                                viewDisplay: false
                            }
                        });
                    }}>
                        <i className='fa fal fa-times fright pointer'></i>
                    </span>
                </div>
                {loader ? <GridLoader TDArray={[1, 2, 3, 4, 5]} /> : <>
                    {data.length === 0 ? <div className='padding20'> <table cellPadding="0" cellSpacing="" width="100%" className="defaultborder">
                        <tbody>
                            <BlankData
                                title={object.mediaId === 1 ? 'Audio' : 'Video'}
                                content={object.mediaId === 1 ? 'No audio has been added to this playlist yet.' : 'No video has been added to this playlist yet.'}
                                colSpan="2" />
                        </tbody>
                    </table></div> :


                        <> {deleteShow.flag ? <div className='padding20'><DeleteListTemplate
                            title={object.mediaId === 1 ? 'Track' : 'Video'} content={object.mediaId === 1 ? 'Are you sure you want to remove this track from the playlist?' : 'Are you sure you want to remove this video from the playlist?'} colSpan="2" deleteDownload={() => confirmDelete()} onClick={() => setDeleteShow({ id: "", flag: false })} /> </div> :
                            <div className='body_panel'>
                                <table cellPadding="0" cellSpacing="0" className="smstable">
                                    <thead>
                                        <tr>
                                            <td className="table_cell_header">Title</td>
                                            <td className='table_cell_header'>Speaker Name</td>
                                            <td className="table_cell_header">Actions</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((v, i) => <tr key={i}>
                                            <td className='body_table_cell'>
                                                <>
                                                    {object.mediaId === 1 ? `${v.trackNumber} - ${v.speechTitle}` : `${v.videoTitle}`}
                                                </>

                                            </td>
                                            <td className='body_table_cell'>{v.speakerName}</td>
                                            <td>
                                                <button className='nobg noborder link' onClick={() => deleteFn(v)}>Delete</button>
                                            </td>
                                        </tr>)}
                                    </tbody>
                                </table>
                            </div>
                        }</>
                    }
                </>}
            </div>

        </div>
    )
}

export default ViewPanel
