
export const SlideBarData = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: 'fas fa-tachometer-alt',
        slider: false,
        id: 1,
    },
    {
        title: 'Create',
        path: "",
        icon: 'fas fa-pencil-alt',
        dropdownIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader',
        openIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader flip',
        slider: false,
        id: 2,
        subNav: [
            {
                title: 'Landing Page',
                path: '/landingPage/2',
                subId: 11
            },
            {
                title: 'Email',
                path: '/email/5',
                subId: 12
            },
            {
                title: 'Survey',
                path: '/survey/4',
                subId: 13
            },
            {
                title: 'Store',
                path: '/store/97',
            },
            // {
            //     title: 'Workflow',
            //     path: '/workflow/74',
            // },
            {
                title: 'Templates',
                path: '/create/templates',
                subId: 14
            },
            {
                title: 'Folder',
                path: '/create/folderDetails',
                subId: 15
            },
            {
                title: 'Goal',
                path: '/create/goalActivity',
                subId: 16
            },
            {
                title: 'Mobile App',
                path: '/mobileApp',
                subId: 17
            }
        ]
    },
    /*{
        title: 'CRM Contacts',
        path: '/CRM',
        icon: 'fas fa-user-friends',
        slider: false,
        id: 3,
    },*/
    {
        title: 'CRM Contacts',
        path: '/crm/people/listings',
        icon: 'fas fa-user-friends',
        slider: false,
        id: 3,
    },
    {
        title: 'Send',
        path: '',
        icon: 'fas fa-paper-plane',
        dropdownIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader',
        openIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader flip',
        slider: false,
        id: 4,
        subNav: [
            {
                title: 'SMS',
                path: '/createContent/47',
                subId: 21
            },
            {
                title: 'Email',
                path: '/createContent/48',
                subId: 22
            },
            // {
            //     title: 'Workflow',
            //     path: '/createContent/76',
            // },
            {
                title: 'Mobile',
                path: '/createContent/95',
                subId: 233,

            },

        ]
    },
    {
        title: 'Insights',
        path: '',
        icon: 'fas fa-chart-bar',
        dropdownIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader',
        openIcon: 'fas fa-caret-right text-align-right rotate rotatelclheader flip',
        slider: false,
        id: 5,
        subNav: [
            {
                title: 'SMS',
                path: '/insights/sms',
                subId: 31
            },
            {
                title: 'Email',
                path: 'insights/email',
                subId: 32
            },
            {
                title: 'Landing Page',
                path: '/insights/landingPage',
                subId: 33
            },
            {
                title: 'Survey',
                path: '/insights/survey',
                subId: 34
            },
            {
                title: 'Mobile',
                path: '/insights/Mobile',
                subId: 36
            },
            {
                title: 'NPS',
                path: '/insights/NPS',
                subId: 35
            },


        ]
    },
]