function ButtonLoader({ children, IsLoader, color }) {
    return (
        <>{
            !IsLoader ? children : <>
                <div className="wave">
                    <span className={`dot ${!!color?'blue':'white'}`}></span>
                    <span className={`dot ${!!color?'blue':'white'}`}></span>
                    <span className={`dot ${!!color?'blue':'white'}`}></span>
                </div>
            </>
        }</>
    )
}
export default ButtonLoader;