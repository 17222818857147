// SMS Sending Create Post Object //

export const buildSMSObjectPost = (goaltemplate) => {
    const array = [];
    for (let x of goaltemplate) {
        x['Goal Rate %'] !== "" &&
            array.push({
                id: x.Id,
                quantity: null,
                rate: x['Goal Rate %'],
            })
    };
    return array;
}
    // SMS Performance Create Post Object //

export const buildPerformanceObjectPost = (goaltemplate) => {
    const array = [];
    for (let x of goaltemplate) {
        if (x['Goal Rate %'] !== "" || x['Goal Quantity']) {
            array.push({
                id: x.Id,
                quantity: x['Goal Quantity'],
                rate: x['Goal Rate %'],
            })
        }
    };
    return array;
}

export const buildObjectPost = (goaltemplate) => {
    const array = [];
    for (let x of goaltemplate) {
        if (x['Goal Rate %'] !== "" || x['Goal Quantity'] || x['Goal Value'] === "") {
            array.push({
                id: x.Id,
                quantity: x['Goal Quantity'] === '' ? null : x['Goal Quantity'],
                rate: x['Goal Rate %'] === '' ? null : x['Goal Rate %'],
                value: x['Goal Value'] === '' ? null : x['Goal Value'],
                widgetid: x.WidgetId
            });
        }
    }
    return array;
}


export const filterPerFormance = (res) => {
    const filter = [];
    for (let x of res) {
        if (x['Goal Rate %'] === undefined && x['Goal Quantity'] === "") {
            x['quantiryDisabled'] = false;
        } else if (x['Goal Rate %'] === "" && x['Goal Quantity'] === "") {
            x['quantiryDisabled'] = false;
            x['rateDisabled'] = false;

        } else if (x['Goal Quantity'] === undefined && x['Goal Rate %'] === "") {
            x['rateDisabled'] = false;
        }
        else if (x['Goal Quantity'] !== "" && x['Goal Rate %'] === "") {
            x['quantiryDisabled'] = false;
            x['rateDisabled'] = true;
        }
        else if (x['Goal Quantity'] === "" && x['Goal Rate %'] !== "") {
            x['quantiryDisabled'] = true;
            x['rateDisabled'] = false;
        }
        filter.push(x);
    }
    return filter;
};

export const filterGoals = (res) => {
    const filter = [];
    for (let x of res) {
        x['quantiryDisabled'] = (x['Goal Rate %'] === '') ? false : true;
        x['rateDisabled'] = (x['Goal Quantity'] === '') ? false : true;
        filter.push(x);
    }
    return filter;
};