import React, { useEffect, useState, useCallback, useRef, Fragment } from 'react';
import { get as _get, find as _find, cloneDeep as _cloneDeep, isEmpty as _isEmpty } from "lodash";
import { useSelector } from "react-redux";
import ReactFlagsSelect from "react-flags-select";
import DataTable from 'react-data-table-component';

import CreateEditCustomFields from "../../ContactsList/CustomFields/CreateEditCustomFields";

import { getLookupConsents, importSubscriberList, getCountryList, fetchMappingFields, getCustomFieldsTypes } from "../../../../services/crm";

const mobilePhoneFieldName = "MobileNumber";
const emailFieldName = "Email";
const marketingSingleOptInSms = "MarketingSingleOptInSms";
const marketingDoubleOptInSms = "MarketingDoubleOptInSms";
const marketingSingleOptInEmail = "MarketingSingleOptInEmail";
const marketingDoubleOptInEmail = "MarketingDoubleOptInEmail";
const mobileCountryCode = "MobileCountryCode";

const Mapping = ({ sendDataToParent }) => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from UR
  //const creationSource = _get(pathArray, "[5]", null); // get CreationSource from URL

  const { uploadedContact } = useSelector(state => _get(state, "importContacts", {}));
  const { importContact } = useSelector(state => _get(state, "importContacts", {}));
  const [marketingMobileConsentData, setMarketingMobileConsentData] = useState({});
  const [selectedMarketingMobileConsentData, setSelectedMarketingMobileConsentData] = useState("");
  const [marketingEmailConsentData, setMarketingEmailConsentData] = useState({});
  const [selectedMarketingEmailConsentData, setSelectedMarketingEmailConsentData] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [mappingFields, setMappingFields] = useState([]);
  const [mappingTableColumns, setMappingTableColumns] = useState([]);
  const [columnHeaderMappingsJson, setColumnHeaderMappingsJson] = useState({});
  const [isSelectedMobileField, setIsSelectedMobileField] = useState(false);
  const [isSelectedMobileCountryCode, setMobileCountryCode] = useState(false);
  const [isSelectedEmailField, setIsSelectedEmailField] = useState(false);
  const [isSelectedMarketingSingleOptInSms, setIsSelectedMarketingSingleOptInSms] = useState(false);
  const [isSelectedMarketingSingleOptInEmail, setIsSelectedMarketingSingleOptInEmail] = useState(false);
  const [mappingTableData, setMappingTableData] = useState([]);
  const [doNotImport, setDoNotImport] = useState(false);
  const [isFileContainMobileColumn, setIsFileContainMobileColumn] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showEmailAndMobileNotSelectedError, setShowEmailAndMobileNotSelectedError] = useState(false);
  const [showStepTwo, setShowStepTwo] = useState(false);
  const [btnContinueClickedOnce, setBtnContinueClickedOnce] = useState(false);
  const [showColumnMappingNotSelectedError, setShowColumnMappingNotSelectedError] = useState(false);
  const [showMappingFieldsSection, setshowMappingFieldsSection] = useState([]);
  //const [selectedMappingRow, setSelectedMappingRow] = useState(null);
  const [customFieldIsOpen, setCustomFieldIsOpen] = useState(false);
  const [fieldName, setCustomFieldName] = useState("");
  const [refreshTableData, setRefreshTableData] = useState(false);
  const [customFieldTypes, setCustonFieldsTypes] = useState([]);
  const [isShowFieldSelectedAlready, setShowFieldSelectedAlready] = useState({ flag: false, fieldName: "" });
  const refs = useRef([]);

  const isValueInArray = useCallback((array, targetValue, property) => {
    return array.some(obj => {
      return (obj[property].trim().toLowerCase() === targetValue.trim().toLowerCase());
    });
  }, []);

  //On page load fetch required details
  useEffect(() => {
    (async () => {
      //Fetch consents
      const respFirstConset = await getLookupConsents({ moduleId: 8 });
      setMarketingMobileConsentData(_get(respFirstConset, "data.data[0]", []));

      const respSecondConset = await getLookupConsents({ moduleId: 9 });
      setMarketingEmailConsentData(_get(respSecondConset, "data.data[0]", []));

      //Fetch Countries
      const respCountries = await getCountryList();
      setCountryList(_get(respCountries, "data", []));
    })();
  }, []);

  // Fetch mapping fields based on List ID
  useEffect(() => {
    (async () => {
      //Fetch mapping fields
      const respMappingFields = await fetchMappingFields({ ListId: listID });
      setMappingFields(_get(respMappingFields, "data.data[0]", []));

      const payload = {
        subscriberListId: listID,
        skip: 0,
        limit: 100
      }

      const customFieldTypes = await getCustomFieldsTypes(payload); //Fetch custom field types
      setCustonFieldsTypes(_get(customFieldTypes, "data.data", []));
      //setMappingFields(_get(respMappingFields, "data", []));
    })();
  }, [listID, refreshTableData]);

  useEffect(() => {
    (async () => {
      try {
        // Preapare Payload for create import API
        let columns = [];
        _get(uploadedContact, "fileHeaders", []).forEach(columnHeader => {
          columns.push({ headerName: columnHeader, field: columnHeader }); // Part of Create Import API 
        });

        let rows = [];
        let mappingData = [];
        _get(uploadedContact, "sample_data", []).some(function (sample, i) {

          rows.push(sample); // Part of Create Import API 

          // Prepare Mapping Table data
          Object.keys(sample).map((title, k) => {

            // If key 0 0 add first row in mappingData with columnName and Sample0
            if (i === 0) {
              mappingData.push({ ColumnName: title, [`Sample${(i + 1)}`]: _get(sample, `${title}`, "") })
            } else {
              mappingData.forEach((row, m) => {

                if (_get(row, "ColumnName", "") === title) {
                  return (
                    mappingData[m] = {
                      ...row,
                      [`Sample${(i + 1)}`]: _get(sample, `${title}`, ""),
                    }
                  )
                }
              });
            }
            return false;
          });

          // Break loop after two sample data
          return i === 1;
        });

        setMappingTableData(mappingData); // Set Mapping table data
        setshowMappingFieldsSection((mappingData || []).map(() => false));
        refs.current = (mappingData || []).map(() => React.createRef());

        const payload = {
          StepId: 2,
          isFirstRowAsHeader: true,
          otherInfo: JSON.stringify({ column: columns, row: rows, Importresponse: importContact })
        }
        await importSubscriberList(_get(importContact, "data.ImportId", ""), payload); // Import Subscriber List

      } catch (error) {
        console.log("Error: ", error);
      }
    })();
  }, [importContact, uploadedContact]);

  const setMappingFieldValue = (row, colName, i, event) => {
    const selVal = _get(row, "LabelName", "").trim().toLocaleLowerCase();
    const selFieldObj = _find((mappingFields || []), (mf) => ((mf.LabelName || "").trim().toLowerCase() === selVal));
    let tmpCols = { ...columnHeaderMappingsJson };

    if (selVal) {

      tmpCols = { ...tmpCols, [colName]: {} };

      tmpCols = {
        ...tmpCols,
        [colName]: {
          ...tmpCols[colName],
          "headerName": colName,
          "mappedHeaderName": _get(selFieldObj, "LabelName", ""),
          "headerSequence": i,
          "alreadymappedHeaderName": _get(selFieldObj, "LabelName", ""),
          "CustomFieldTypeId": _get(selFieldObj, "CustomFieldTypeId", 0),
          "CustomFieldId": _get(selFieldObj, "CustomFieldId", ""),
          "IsEdit": false,
          "show_dialog": false,
        }
      };
    } else if (tmpCols[colName]) {
      delete tmpCols[colName];
    }

    setColumnHeaderMappingsJson(tmpCols);
    //validateIfColumnMappedOrNot();
    setshowMappingFieldsSection((prev) => {
      const newVisibleDropdowns = [...prev];
      newVisibleDropdowns[i] = false;
      return newVisibleDropdowns;
    });

    //setSelectedMappingRow(null);
  }

  useEffect(() => {
    (async () => {

      const mappingTblColumns = [
        { name: "Column Name", selector: (item) => _get(item, "ColumnName", "") },
        { name: "Sample 1", selector: (item) => _get(item, "Sample1", "") },
        { name: "Sample 2", selector: (item) => _get(item, "Sample2", "") },
        {
          name: "Map to field",
          width: "300px",
          selector: (item, i) => {
            const colName = _get(item, "ColumnName", "");
            const isError = _get(item, "isError", false);
            const tmpColms = _get(columnHeaderMappingsJson, `[${colName}]` || {});
            const selValueOriginal = (tmpColms) ? _get(tmpColms, "mappedHeaderName", "") : "";
            //const selValue = (tmpColms) ? _get(tmpColms, "mappedHeaderName", "").trim().toLowerCase() : "";
            const alreadyMppedHeaders = Object.values((columnHeaderMappingsJson || {})).map((obj) => obj.alreadymappedHeaderName);

            return (
              <Fragment key={`parent-${i}-${String(!showMappingFieldsSection[i])}`}>
                <div
                  className={`custom-dropdown  ${(((isError || false) === true) && ((doNotImport || false) === false)) ? "inputform-error-full" : ""}`}
                  key={`${i}-${String(!showMappingFieldsSection[i])}`}
                  ref={refs.current[i]}
                >
                  <div
                    className="custom-dropdown-text"
                    onClick={(e) => {
                      //setshowMappingFieldsSection((showMappingFieldsSection) => ({ ...showMappingFieldsSection, [i]: !showMappingFieldsSection[i] }));
                      setshowMappingFieldsSection(
                        (showHideDeleteAction) => {
                          //const newVisibleDropdowns = [...showHideDeleteAction];
                          //newVisibleDropdowns[i] = !newVisibleDropdowns[i];
                          const newVisibleDropdowns = showHideDeleteAction.map((visible, index) => (index === i ? !visible : false));
                          return newVisibleDropdowns;
                        });
                      //setSelectedMappingRow(i);
                    }}
                  >
                    {(selValueOriginal || "Select an appropriate field")}
                  </div>

                  <div className={`custom-dropdown-field ${(showMappingFieldsSection[i] === true) ? "display-block" : "hide"}`}>
                    <ul className="custom-dropdown-menu">
                      <li className="custom-dropdown-item">
                        <h6>Default fields</h6>
                        <ul className="custom-dropdown-menu-sub">
                          {(mappingFields || []).map((row, key) => {
                            return (
                              (_get(row, "FieldType", 0) === 0) && (
                                <li
                                  key={`default-${key}`}
                                  className="custom-dropdown-item-sub"
                                  onClick={(e) => (!(alreadyMppedHeaders || []).includes(_get(row, "LabelName", ""))) ? setMappingFieldValue(row, colName, i, e) : setShowFieldSelectedAlready({ flag: true, fieldName: _get(row, "LabelName", "") })}
                                >
                                  <button
                                    className={`${((alreadyMppedHeaders || []).includes(_get(row, "LabelName", ""))) ? "field-disabled" : ""} btn`}
                                    type="button"
                                  >
                                    {_get(row, "LabelName", "")}
                                  </button>
                                </li>
                              )
                            )
                          })}
                        </ul>
                      </li>

                      <li className="custom-dropdown-item">
                        <h6>Custom fields</h6>

                        <ul className="custom-dropdown-menu-sub">
                          {(mappingFields || []).map((row, key) => {
                            return (
                              (_get(row, "FieldType", 0) === 1) && (
                                <li
                                  key={`custom-${key}`}
                                  className={`custom-dropdown-item-sub ${((alreadyMppedHeaders || []).includes(_get(row, "LabelName", ""))) ? "disabled" : ""}`}
                                  onClick={() =>
                                    (!(alreadyMppedHeaders || []).includes(_get(row, "LabelName", ""))) ? setMappingFieldValue(row, colName, i) : {}
                                  }
                                >
                                  <button className="btn" type="button">{_get(row, "LabelName", "")}</button>
                                </li>
                              )
                            )
                          })}
                        </ul>
                      </li>
                    </ul>

                    <div className="custom-dropdown-footer">
                      <button className="btn btn-secondary" type="button" onClick={() => { setCustomFieldIsOpen(true); setCustomFieldName(colName); }}>Create custom field</button>
                    </div>
                  </div>
                </div>

              </Fragment>
            )
          }
        },
      ];
      setMappingTableColumns(mappingTblColumns);

      // Check "mobile number" field selected, and set flag based on that
      //const isMobileFieldSelected = _find((columnHeaderMappingsJson || []), (ch) => ((ch.mappedHeaderName || "").trim().toLowerCase() === (mobilePhoneFieldName).toLowerCase()));

      const isMobileFieldSelected = _find((columnHeaderMappingsJson || []), (ch) => ((ch.mappedHeaderName || "").trim().toLowerCase() === (mobilePhoneFieldName).trim().toLowerCase()));
      setIsSelectedMobileField(!!isMobileFieldSelected);

      const isMobileCountryCodeSelected = _find((columnHeaderMappingsJson || []), (ch) => ((ch.mappedHeaderName || "").trim().toLowerCase() === (mobileCountryCode).trim().toLowerCase()));
      setMobileCountryCode(!!isMobileCountryCodeSelected);

      const isEmailFieldSelected = _find((columnHeaderMappingsJson || []), (ch) => ((ch.mappedHeaderName || "").trim().toLowerCase() === (emailFieldName).trim().toLowerCase()));
      setIsSelectedEmailField(!!isEmailFieldSelected);

      const isMarketingSMSSelected = _find((columnHeaderMappingsJson || []), (ch) => (((ch.mappedHeaderName || "").trim().toLowerCase() === (marketingSingleOptInSms).trim().toLowerCase()) || ((ch.mappedHeaderName || "").trim().toLowerCase() === (marketingDoubleOptInSms).trim().toLowerCase())));
      setIsSelectedMarketingSingleOptInSms(!!isMarketingSMSSelected);

      const isMarketingEmailSelected = _find((columnHeaderMappingsJson || []), (ch) => (((ch.mappedHeaderName || "").trim().toLowerCase() === (marketingSingleOptInEmail).trim().toLowerCase()) || ((ch.mappedHeaderName || "").trim().toLowerCase() === (marketingDoubleOptInEmail).trim().toLowerCase())));
      setIsSelectedMarketingSingleOptInEmail(!!isMarketingEmailSelected);

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnHeaderMappingsJson, mappingFields, mappingTableData, btnContinueClickedOnce, showMappingFieldsSection, isValueInArray]);

  useEffect(() => {
    if (!btnContinueClickedOnce) {
      let havingMobileField = false;
      // let tmpColMappingsJson = [];
      //let tmpColMappingsJson = {};
      let tmpColMappingsJson = _cloneDeep(columnHeaderMappingsJson);

      (mappingTableData || []).forEach((mtd, i) => {

        const findObj = (mappingFields || []).filter(obj => {
          return (obj["LabelName"].trim().toLowerCase() === _get(mtd, "ColumnName", "").trim().toLowerCase());
        });

        if ((findObj || []).length > 0) {

          if (!havingMobileField) { havingMobileField = (_get(findObj, "[0].LabelName", "") === mobilePhoneFieldName); }

          tmpColMappingsJson = {
            ...tmpColMappingsJson,
            [_get(mtd, "ColumnName", "")]: {
              "headerName": _get(mtd, "ColumnName", ""),
              "mappedHeaderName": _get(findObj, "[0].LabelName", ""),
              "headerSequence": i,
              "alreadymappedHeaderName": _get(findObj, "[0].LabelName", ""),
              "CustomFieldTypeId": _get(findObj, "[0].CustomFieldTypeId", 0),
              "CustomFieldId": _get(findObj, "[0].CustomFieldId", ""),
              "show_dialog": false,
              "isError": false
            }
          };
        }
      });

      setColumnHeaderMappingsJson(tmpColMappingsJson);
      setIsSelectedMobileField(havingMobileField);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappingFields, mappingTableData, btnContinueClickedOnce]);

  // When drop down value (select a column value) changed and form is already submitted once then to recheck validation
  useEffect(() => {
    if (btnContinueClickedOnce) {
      validateIfColumnMappedOrNot();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnHeaderMappingsJson]);

  //If Uncheck Do Not Import Checkbox then check for validation
  useEffect(() => {
    if (!_isEmpty(columnHeaderMappingsJson)) {
      validateIfColumnMappedOrNot();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doNotImport]);

  const validateIfColumnMappedOrNot = (isReturnValue) => {
    let error = false;
    let tmMappingTableJSON = _cloneDeep(mappingTableData);
    let allSelectedHeader = Object.keys((columnHeaderMappingsJson || {}));

    tmMappingTableJSON = (tmMappingTableJSON || []).map((mtd, i) => {

      if (!allSelectedHeader.includes(_get(mtd, "ColumnName", ""))) {
        error = true;
        return mtd = {
          ...mtd,
          isError: true
        };
      } else {
        return mtd = {
          ...mtd,
          isError: false
        };
      }
    });

    setMappingTableData(tmMappingTableJSON);
    setShowColumnMappingNotSelectedError(error);

    if (isReturnValue === true) {
      return error;
    }
  }

  const validateAndShowNext = () => {
    setBtnContinueClickedOnce(true);
    let error = false;

    if (!doNotImport) {

      error = validateIfColumnMappedOrNot(true);
    }

    if (!isSelectedEmailField && !isSelectedMobileCountryCode && !isSelectedMobileField) {
      error = true;
      setShowEmailAndMobileNotSelectedError(true);
      return false;
    }

    if (
      !error &&
      ((isSelectedMobileField || false) === true) && ((isSelectedMarketingSingleOptInEmail || false) === true) &&
      ((isSelectedEmailField || false) === true) && ((isSelectedMarketingSingleOptInSms || false) === true)
    ) {

      onContinue();
    } else if (!error) {
      setShowStepTwo(true);
    }
  }
  const onContinue = () => {
    (async () => {
      try {
        let finalColumnHeaderMappinJSON = [];

        let allSelectedHeader = Object.keys((columnHeaderMappingsJson || {}));
        finalColumnHeaderMappinJSON = (mappingTableData || []).map((column, i) => {

          if (!(allSelectedHeader || []).includes(_get(column, "ColumnName", ""))) {
            return {
              "headerName": _get(column, "ColumnName", ""),
              "mappedHeaderName": "Don't import this column",
              "newMapped": true,
              "headerSequence": i,
              "show_dialog": false
            }
          } else {
            let tmpObject = columnHeaderMappingsJson[_get(column, "ColumnName", "")];
            delete tmpObject["isError"];
            return tmpObject;
          }
        });

        let payload = {
          StepId: 3,
          columnHeaderMappingsJson: JSON.stringify(finalColumnHeaderMappinJSON || []),
          MobileCountryCodeOptinOptinOutJson: {
            MobileCountryCodeMapping: {
              IsCountryCodeIncluded: (isFileContainMobileColumn),
              SelectedCountryCode: _get(selectedCountry, "secondary", null)
            },
            OptInOptoutConsent: [
              {
                Type: 1,
                ConsentId: (selectedMarketingMobileConsentData || null)
              },
              {
                Type: 2,
                ConsentId: (selectedMarketingEmailConsentData || null)
              }
            ]
          }
        }

        const response = await importSubscriberList(_get(importContact, "data.ImportId", ""), payload);

        if (_get(response, "flag", true) === true) {
          sendDataToParent("import_rules");
        }

      } catch (error) {
        console.log("Error: ", error);
      }
    })();

  }

  const onCancel = () => {
    sendDataToParent("upload_contacts");
  }

  useEffect(() => {
    const handleClickOutsideAll = (event) => {

      refs.current.forEach((ref, index) => {
        if (ref.current && !ref.current.contains(event.target)) {
          setshowMappingFieldsSection((prev) => {

            const newVisibleDropdowns = [...prev];
            newVisibleDropdowns[index] = false;
            return newVisibleDropdowns;
          });
        }
      });
    };

    document.addEventListener('mousedown', handleClickOutsideAll);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideAll);
    };
  }, []);

  const stepTwo = () => {
    let countryFlagValidation = true;
    if ((isSelectedMobileCountryCode || false) === true) {
      countryFlagValidation = true;
    } else if (isFileContainMobileColumn === null) {
      countryFlagValidation = false;
    } else if (isFileContainMobileColumn === false && selectedCountry === null) {
      countryFlagValidation = false;
    }

    let allCountriesJSON = {};
    let allCountries = [];
    if ((countryList || []).length > 0) {
      (countryList || []).forEach(element => {
        allCountries.push(_get(element, "ISOcountrycode", "").toUpperCase());

        allCountriesJSON[_get(element, "ISOcountrycode", "").toUpperCase()] = {
          primary: `${_get(element, "CountryName", "")},`, secondary: _get(element, "CountryCode", ""), code: _get(element, "ISOcountrycode", "").toUpperCase()
        };
      });
    }

    return (
      <div className="bx-boder-common nobg my-12">
        <div className="_h_header white border-radius2">
          <h3>We need some extra information to complete your mapping</h3>
        </div>

        {((isSelectedMobileCountryCode || false) === false) && ((isSelectedMobileField || false) === true) && (
          <div className="white">
            <div className="step-mapping">
              <div className="p-5">
                <label className="_l_able_cls">
                  Before proceeding we need to know more about your mobile number to get this correct for potential SMS sendings.
                </label>
                <label className="_l_able_cls">
                  The best way is if your uploaded file contained a separate column with mobile country code, then you can easily map it.
                </label>

                <label className="_l_able_cls mb-3">Let try to sort this out. Did your mobile numbers also contain mobile country codes?</label>
                <div className="mb-3">
                  <label htmlFor="yes">
                    <input type="radio" className="inline-block" id="yes" name="measure" onChange={(e) => setIsFileContainMobileColumn(true)} /> Yes
                  </label>
                </div>
                <div className="mb-2">
                  <label htmlFor="no">
                    <input type="radio" className="inline-block" id="no" name="measure" onChange={(e) => setIsFileContainMobileColumn(false)} /> No
                  </label>
                </div>
              </div>
            </div>
          </div>
        )}

        {(isFileContainMobileColumn === true) && (
          <div className="white">
            <div className="step-mapping">
              <div className="p-5">
                <div className="mb-8 bx-boder-common width100 dataTable-primary">
                  <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                    <thead>
                      <tr>
                        <th className="table_cell_header pt-3">Sample1</th>
                        <th className="table_cell_header pt-3">Sample2</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="body_table_cell"></td>
                        <td className="body_table_cell"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}

        {(isFileContainMobileColumn === false) && (
          <div className="white">
            <div className="step-mapping">
              <div className="p-5">
                <label className="_l_able_cls">
                  To gain speed in the import process, could you please specify the country of the majority of the mobile numbers?
                </label>
                {/*<select className="inputformmodel width368 mxw-100" value={selectedCountry} onChange={(e) => setSelectedCountry(_get(e, "target.value", null))}>
                  <option value="">Select</option>
                  {(countryList || []).map((row, key) => {
                    return (
                      <option value={_get(row, "CountryCode", "")}>{`${_get(row, "CountryName", "")}, ${_get(row, "CountryCode", "")}`}</option>
                    )
                  })}
                </select>*/}
                <ReactFlagsSelect
                  selected={_get(selectedCountry, "code", "")}
                  countries={allCountries}
                  customLabels={allCountriesJSON}
                  className="country-code-select"
                  placeholder="Select Country"
                  fullWidth={false}
                  searchable={true}
                  onSelect={(selected) => {
                    setSelectedCountry(_get(allCountriesJSON, `[${selected}]`, null));
                  }}
                />
              </div>
            </div>
          </div>
        )}

        {(((isSelectedMobileField || false) === true) && ((isSelectedMarketingSingleOptInSms || false) === false)) && (
          <div className="white">
            <div className="step-mapping">
              <div className="p-5">
                <label className="_l_able_cls">
                  You have mapped mobile numbers, but not the mobile number opt-in/opt-out status. We need you to set the status to continue with the import.
                </label>
                <select className="inputformmodel width100 mxw-100" value={selectedMarketingMobileConsentData} onChange={(e) => setSelectedMarketingMobileConsentData(_get(e, "target.value", null))}>
                  <option value="">Select</option>
                  {_get(marketingMobileConsentData, "ConsentDetails", []).map((row, key) => {
                    return (
                      <option key={key} value={_get(row, "ConsentDetailsId", "")}>{_get(row, "Description", "")}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        )}


        {(((isSelectedEmailField || false) === true) && ((isSelectedMarketingSingleOptInEmail || false) === false)) && (
          <div className="white">
            <div className="step-mapping">
              <div className="p-5">
                <label className="_l_able_cls">
                  You have mapped email address, but not the email subscribe/unsubscribe status. We need you to set the status to continue with the import.
                </label>
                <select className="inputformmodel width100 mxw-100" value={selectedMarketingEmailConsentData} onChange={(e) => setSelectedMarketingEmailConsentData(_get(e, "target.value", null))}>
                  <option value="">Select</option>
                  {_get(marketingEmailConsentData, "ConsentDetails", []).map((row, key) => {
                    return (
                      <option key={key} value={_get(row, "ConsentDetailsId", "")}>{_get(row, "Description", "")}</option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
        )}

        <div className="p-5 text-right">
          <button className="btn link-light" onClick={() => onCancel()}>Cancel</button>
          <button
            className="btn btn-success"
            disabled={((((isSelectedMobileField || false) === true) && (((selectedMarketingMobileConsentData || "") === "" && (isSelectedMarketingSingleOptInSms || false) === false) || (countryFlagValidation === false))) || (((isSelectedEmailField || false) === true) && ((selectedMarketingEmailConsentData || "") === "" && (isSelectedMarketingSingleOptInEmail || false) === false))) ? true : false}
            onClick={() => onContinue()}
          >Continue</button>
        </div>
      </div>
    );
  }

  return (
    <>
      {(!showStepTwo) && (
        <div className="bx-boder-common nobg my-12">
          <div className="_h_header white border-radius2">
            <h3>Mapping</h3>
          </div>

          <div className="white">
            <div className="step-mapping">
              <div className="dataTable-primary dataTable-primary-510">
                <DataTable
                  columns={mappingTableColumns}
                  data={mappingTableData}
                />
              </div>

              <div className="p-5 b_top text-right">
                {((showColumnMappingNotSelectedError || false) === true) && ((doNotImport || false) === false) && (
                  <div className="alert alert-warning margin-bottom24">
                    <strong>You have not mapped the fields highlighted with red color.</strong><br />
                    Please map these fields, select "Don't import this column" or select the "Do not import data in unmapped
                    fields" to not import any of the columns with unmapped fields.
                  </div>
                )}
                <div className="mb-4">
                  <input
                    id="do_not_import"
                    className="inline-block"
                    type="checkbox"
                    onChange={e => setDoNotImport(!doNotImport)}
                    checked={doNotImport}
                  />
                  <label className="inline-block cursor-pointer" htmlFor="do_not_import">&nbsp;Do not import data in unmapped fields</label>
                </div>
                <button className="btn link-light" onClick={() => onCancel()}>Cancel</button>
                <button className="btn btn-success" onClick={() => validateAndShowNext()}>Continue</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {(showStepTwo) && (
        stepTwo()
      )}

      {((showEmailAndMobileNotSelectedError || false) === true) && (
        <Fragment>
          <div className="file-type-popup show">
            <p>The imported file must contain an Email, a MobileNumber and a MobileCountryCode. In order to proceed,
              please assign these field names to appropriate columns of the file being imported.</p>

            <button className="btn-cancel mt-8" onClick={() => setShowEmailAndMobileNotSelectedError(false)}>Cancel</button>
          </div>

          <div className="file-type-popup-backdrop show"></div>
        </Fragment>
      )}

      {(_get(isShowFieldSelectedAlready, "flag", false) === true) && (
        <Fragment>
          <div className="file-type-popup show">
            <p>The {_get(isShowFieldSelectedAlready, "fieldName", "")} field name has been already assigned to a column. Please select a different field name to proceed.</p>

            <button className="btn-cancel mt-8" onClick={() => setShowFieldSelectedAlready({ flag: false, fieldName: "" })}>Cancel</button>
          </div>

          <div className="file-type-popup-backdrop show"></div>
        </Fragment>
      )}

      <CreateEditCustomFields
        isOpen={customFieldIsOpen}
        setIsOpen={(isOpen) => setCustomFieldIsOpen(isOpen)}
        refreshTableData={refreshTableData}
        setRefreshTableData={(refreshTableData) => setRefreshTableData(refreshTableData)}
        isEdit={false}
        setIsEdit={() => { }}
        customFieldTypes={customFieldTypes}
        selectedEditField={{}}
        setSelectedEditField={() => { }}
        customFieldFormData={null}
        setCustomFieldFormData={() => { }}
        FieldName={(fieldName || "")}
      />
    </>
  )
}

export default Mapping;