import GridLoader from "../../../../ReuseTemplate/GridLoader";

const GridAggregatedContainer = ({ dataAggrated, onClick, tabId }) => {
    return (
        <>
            {dataAggrated.isDisplay ? <GridLoader TDArray={[1,2,3,4,5,6,7,8]}/> :
                <table width="100%" cellPadding="0" className="smstable" cellSpacing="0">
                    <thead>
                        <tr>
                            <th className="table_cell_header">KPI</th>
                            <th className="table_cell_header">Description</th>
                            <th className="table_cell_header">Status</th>
                            <th className="table_cell_header">Status %</th>
                            <th className="table_cell_header" width="20%">Goal  (clicked)</th>
                            <th className="table_cell_header">Goal (%)</th><th className="table_cell_header">Achived</th>
                            <th className="table_cell_header">Action</th>
                        </tr>
                    </thead>
                    <tbody><Grid dataAggrated={dataAggrated} onClick={onClick} /></tbody>
                </table>
            }
        </>
    )
}

export default GridAggregatedContainer;

const Grid = ({ dataAggrated, onClick, tabId }) => {

    return (
        <>
            {dataAggrated['KPI'] && dataAggrated['KPI'].map((v, i) =>
                <tr key={i}><td className="body_table_cell">{v['Name']}</td>
                    <td className="body_table_cell">{v['Description']}</td>
                    <td className="body_table_cell" style={{ color: v['CountColor'], paddingLeft: '10px' }}><strong style={{ display: (v['Count'] === '-1') ? 'none' : 'table-cell' }} className="">{(v['IsConversionRevenue']) ? (v['FormattedConversionRevenueCount'] === undefined) ? '' : v['FormattedConversionRevenueCount'] : (v['Count'] === undefined) ? '' : v['Count']}</strong></td>
                    <td className="body_table_cell" style={{ color: v['RateColor'] }}><span style={{ display: (v['IsConversionRevenue']) ? 'table-cell' : (v['Rate'] === -1) ? 'none' : 'table-cell' }}>{(v['IsConversionRevenue']) ? (v['Rate'] === undefined) ? '' : v['Rate'] + '%' : (v['Rate'] === undefined) ? '' : v['Rate'] + '%'}</span></td><td className="body_table_cell">{(v['IsConversionRevenue']) ? v['KPIBasedGoalQuantity'] : (v['KPIBasedGoalSetup']) ? v['KPIBasedGoalQuantity'] : 'N/A'}</td><td style={{ display: (tabId === 30) ? 'table-cell' : 'none' }} className="body_table_cell">{(v['IsConversionRevenue']) ? v['FormattedConversionRevenueGoal'] : v['KPIBasedGoalQuantity']}</td><td className="body_table_cell" >{(v['IsConversionRevenue']) ? v['FormattedConversionRevenueGoal'] : (v['KPIBasedGoalSetup']) ? (v['KPIBasedGoalRate'] === undefined) ? '' : v['KPIBasedGoalRate'] + '%' : 'N/A'}</td><td className="body_table_cell"><i style={{ color: v['KPIBasedGoalRateOrQuantityColor'], fontSize: '19px', display: (v['IsGoalSetup']) ? (v['KPIBasedGoalSetup']) ? 'table-cell' : 'none' : 'none' }} className="fas fa-trophy"></i></td><td className="body_table_cell"><button type="button" className="link pinunpin" onClick={() => onClick(v)} >{(!v['IsPinned']) ? ' Pin' : 'Remove Pin'}</button></td></tr>
            )}
        </>
    )
}
