import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";

export const UnsubscribeContent = ({ ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const [unsunscribeContentVal, setUnscribeContent] = useState([]);
    const { ChangeEvent, Value } = props;
    // Get All list from API Call
    useEffect(() => {
        (APITOKEN.token !== "") &&
            Client.get(`Unsubscribe/Consent/SMS`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    setUnscribeContent(res['data'][0].ConsentDetails);
                } catch (err) {
                    console.error(err);
                }
            });
    }, [APITOKEN])
    return (
        <div className="formmodel-bx">
            <label className="_l_able_cls">Content</label>
            <select className="inputformmodel" value={Value.unsubscribeContent} onChange={ChangeEvent}>
                <option value="">Select</option>
                {unsunscribeContentVal.map((v, i) => <option key={i} value={v.ConsentDetailsId} disabled={!v.HasRecords}>{v.Name}</option>)}
            </select>
        </div>
    )

}

