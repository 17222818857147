import { useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import Client from "../../GlobalAPICall";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { DownLoadData } from '../DownloadChartJSON';
import HC_exporting from 'highcharts/modules/exporting';
import { HightChartBestDay, HightChartColorProps, maxHits } from '../constant/HightChartBestProps';

HC_exporting(Highcharts)
const BestTime = () => {
    const { APITOKEN } = useSelector(state => state);
    const [bestDayData, setBestDay] = useState("");
    Highcharts.setOptions(HightChartColorProps);
    const chart = useRef();
    const [downloadData, setDownloadData] = useState({
        data: DownLoadData,
        show: false
    });
    const [optionData, setOptionValue] = useState(HightChartBestDay);
    useEffect(() => {
        (APITOKEN['token'] !== "") &&
            Client.get('Insights/distribution/SMS/BestTime/Connect')
                .then(({ data }) => {
                    dataFilter(data || []);
                }).catch(err => {
                    throw err;
                });

        function handleWindowClick() {
            setDownloadData(prv => ({
                ...prv,
                show: false
            }))
        }
        window.addEventListener('click', handleWindowClick, false);
        return (() => {
            window.removeEventListener('click', handleWindowClick, false);
        })
    }, [APITOKEN]);

    const dataFilter = (data) => {
        const bestTimeAvg = [...data.map(item => ({ name: item.HitsSlotname, y: item.Percentage }))];
        const { DisplaySlotNameText } = maxHits(data)[0];
        setBestDay(DisplaySlotNameText);
        setOptionValue({ series: [{ data: bestTimeAvg, name: "BestTime" }] });
    }
    const exportChart = (e) => {
        e.stopPropagation();
        setDownloadData(prv => ({
            ...prv,
            show: !prv.show
        }))
    }
    const downloadImg = ev => {
        chart.current.chart.exportChart({
            type: ev.value
        })
    }

    return (
        <>
            <button type="button" className="graphProperties"
                onClick={exportChart}
                data-id="dailyrutine">
                <i className="fal fa-ellipsis-h"></i></button>
            {downloadData.show ?
                <div className="graphproperties-bx">
                    {downloadData['data'].map((v, i) => <div key={i} className="link grpahbx" onClick={() => downloadImg(v)}>{v.name}</div>)}
                </div>
                : <></>}
            <h3 className="_chart_title padding-top20 padding-bottom20">Best Time to Send SMS</h3>
            <p>Based on opens of your total SMS sending with SMS content.</p>
            <h3 className="chart_t link  -bestti">{bestDayData}</h3>
            <HighchartsReact highcharts={Highcharts} options={optionData} ref={chart} />
        </>
    )
}
export default BestTime;