import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Client from "../../GlobalAPICall";

const Integration = () => {
    const {APITOKEN} = useSelector(state => state);
    const [integrationData, setIntegration] = useState([])
    const setLoaded = useCallback(() => {
        APITOKEN['token'] &&
            Client.get(`Lookup/GetConnectors`, {
                headers: {
                    'Authorization': `Bearer ${APITOKEN['token']}`
                }
            }).then(({data}) => {
                try {
                    setIntegration(data)
                } catch (err) {
                    console.log(err);
                }
            });

    }, [APITOKEN])


    useEffect(() => {
        setLoaded();
    }, [setLoaded])
    return (
        <>
            <div>
                <ul className=" width100 inline-block _create_tem_list_id">
                    {integrationData.map((option, i) => <li key={i} className="clickable"><div className="_temimg"><img alt="" src={option['ThumbNail']} /></div><div className="_tem_des"><h3>{option['Name']}</h3><p>{option['Description']}</p></div><Link to={`/createContent/${option.Id}`} className="_sel_tem" >Select</Link></li>
                    )}
                </ul>
            </div>
        </>
    )
}
export default Integration