import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../../../../GlobalAPICall";
import { useNavigate } from "react-router";
import getParameterByName from "../../../../ReuseTemplate/GetRoute";

const CreateSenderName = () => {
    const { APITOKEN } = useSelector(state => state);
    const navigate = useNavigate();
    const [checkIfTeleMarketer, setCheckIfTeleMarketer] = useState({
        flag: false,
        telemarketersId: '',
        telemarketersPeId: ''
    })
    const [inputFieldValue, setInputField] = useState({
        MessageType: '',
        SenderId: '',
        SenderName: '',
        MessageTypeData: [],
        APIMessage: ''
    })

    const checkIfTeleMarketerIdExistsAPICall = useCallback(async () => {

        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            await Client.get(`Trigger/Templates/CheckIfTeleMarketerIdExists`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.success) {
                            setCheckIfTeleMarketer(prv => ({ ...prv, flag: false }))
                        } else {
                            setCheckIfTeleMarketer(prv => ({ ...prv, flag: true }));
                            const id = getParameterByName('id', window.location.href);
                            window.history.pushState({}, "", `/create/template/createSenderName${id !== null?`?id=${id}`:''}`);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN]);

    const load = useCallback(async () => {

        const cancelToken = axios.CancelToken.source();
        APITOKEN.token &&
            await Client.get(`Trigger/Templates/SMSSenderNameMessageType`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        setInputField(prv => ({ ...prv, MessageTypeData: res.data }));
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    if (axios.isCancel(err)) {
                        console.error(err);
                    }
                })
        return () => {
            cancelToken.cancel();
        }
    }, [APITOKEN]);

    const saveHeader = () => {
        const obj = {
            "headerName": inputFieldValue.SenderName,
            "headerId": inputFieldValue.SenderId,
            "messageType": inputFieldValue.MessageTypeId
        }
        if (!checkIfTeleMarketer.flag) {
            obj['gatewayTelemarkterId'] = checkIfTeleMarketer.telemarketersId;
            obj['gatewayPeId'] = checkIfTeleMarketer.telemarketersPeId;
        } else {
            delete obj['gatewayTelemarkterId'];
            delete obj['gatewayPeId'];
        }
        const EditId = getParameterByName('id', window.location.href);
        if (EditId !== null) {
            obj['id'] = EditId;
            PutAPI(obj);
        } else {
            PostAPI(obj);
        }
    }
    const [response,setAPIReponse] = useState({});
    const PutAPI = (obj) => {
        Client.put(`Trigger/Templates/SMSHeaderValues`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                if (res.data.success) {
                    navigate('/create/templates/localSender?type=92');
                } else {
                    setInputField(prv => ({ ...prv, APIMessage: res.data.message }))
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.error(err);
        })
    }
    const PostAPI = (obj) => {
        Client.post(`Trigger/Templates/SMSHeaderValues`, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(res => {
            try {
                if (res.data.success) {
                    const d = getParameterByName('d', window.location.href);
                    if (d !== null) {
                        navigate('/create/templates/localSender?type=92');
                    } else {
                        navigate('/create/template/createTemplateSMS');
                    }
                } else {
                    setAPIReponse(prv => ({
                        ...prv,
                        error:res.data.message
                    }))
                }
            } catch (err) {
                console.error(err);
            }
        }).catch(err => {
            console.error(err);
        })
    }
    const filterId = (prv, response) => {
        const index = prv.MessageTypeData.findIndex(x => x.templateType === response.messageTypeText);
        return prv['MessageTypeData'][index].id;
    }
    const editSenderNameObject = useCallback(async () => {

        APITOKEN['token'] &&
            await Client.get(`Trigger/Templates/SMSHeaderValues?templateId=${getParameterByName('id')}`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
                .then(res => {
                    try {
                        if (res.data.length > 0) {
                            const response = res.data[0];
                            setInputField(prv => ({
                                ...prv,
                                MessageType: response.messageTypeText,
                                SenderId: response.templateName,
                                SenderName: response.headerName,
                                MessageTypeId: filterId(prv, response)
                            }))
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }).catch(err => {
                    console.error(err);
                })
    }, [APITOKEN])
    useEffect(() => {
        checkIfTeleMarketerIdExistsAPICall()
        load();
        if (getParameterByName('id', window.location.href) !== null) {
            editSenderNameObject();
        }
    }, [load, editSenderNameObject, checkIfTeleMarketerIdExistsAPICall])

    return (
        <div className="dashbd_sec">
            {checkIfTeleMarketer.flag ? <div className="sms_Sec_1 white bx-boder-common width100">
                <div className="_h_header">
                    <h3>Sender Name SMS India.</h3>
                </div><div className="padding20">
                    <p>To be able to send SMS to Indian operators you need to have an approved Telemarketers ID. If you have it you can enter it here.</p>
                    <br />
                    <div className="formmodel-bx width322">
                        <input className="inputformmodel width100" onChange={e => setCheckIfTeleMarketer(prv => ({ ...prv, telemarketersId: e.target.value }))} placeholder="Telemarketers ID" />
                    </div>
                    <div className="formmodel-bx width322">
                        <label className="_l_able_cls">Telemarketers Pe Id</label>
                        <input className="inputformmodel width100" placeholder="Telemarketers Pe ID" onChange={e => setCheckIfTeleMarketer(prv => ({ ...prv, telemarketersPeId: e.target.value }))} />
                    </div>
                    <div id="nodatamessage">
                        <br />
                        <p className="font-16"><strong>Option 1.</strong> We can assist you! Read our Article on <a href="https://www.jirafix.com/hc/en_IN/sms/dlt-registration/telemarketer-and-account-id-india/" target="_blank" rel="noreferrer" className="link inline-block font-16"> How to apply for Telemarketers ID</a> and follow the instruction.</p>
                        <br />
                        <p className="font-16 margin-bottom"><strong>Option 2.</strong>If you want to register yourself, you can access government portal <a href="https://www.ucc-mtnl.in/" className="link inline-block font-16" target="_blank" rel="noreferrer">here.</a> </p>
                    </div>
                    <div className="text-right margin-top50">
                        <button type="button" className="grid_btn_1 nobg noborder margin-right10" onClick={()=> navigate('/create/templates/localSender?type=92')}>Cancel</button>
                        <button type="button" disabled={
                            (checkIfTeleMarketer.telemarketersId !== "" && checkIfTeleMarketer.telemarketersPeId !== "") ? false : true
                        } className="grid_btn_1 grid_btn_active" onClick={() => setCheckIfTeleMarketer(prv => ({ ...prv, flag: false }))}>Continue</button>
                    </div>

                </div></div> :
                <div className="sms_Sec_1 white bx-boder-common width100">
                    <div className="_h_header">
                        <h3>Please fill in your approved SMS Header values.</h3>
                    </div>
                    <div className="padding20">
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">Message Type</label>
                            <select className="inputformmodel width100"
                                value={inputFieldValue.MessageType}
                                onChange={e => setInputField(prv => ({ ...prv, MessageTypeId: e.target.childNodes[e.target.selectedIndex].getAttribute('data-id'), MessageType: e.target.value }))}>
                                <option value="">Select</option>
                                {inputFieldValue.MessageTypeData.map((v, i) => <option key={i} value={v.messageTypeText} data-id={v.id}>{v.templateType}</option>)}
                            </select>
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">Sender Id</label>
                            <input type="text" className="inputformmodel width100" value={inputFieldValue.SenderId} placeholder="Sender Id" onChange={e => setInputField(prv => ({ ...prv, SenderId: e.target.value }))} />
                        </div>
                        <div className="formmodel-bx width322">
                            <label className="_l_able_cls">Sender Name</label>
                            <input type="text" className="inputformmodel width100" value={inputFieldValue.SenderName} maxLength="6" placeholder="Sender Name"
                                onChange={e => setInputField(prv => ({ ...prv, SenderName: e.target.value }))} />
                            <p style={{ textAlign: 'right', paddingTop: '5px', color: 'rgb(26 40 61 / 30%)' }}>
                                Minimum characters: 6
                            </p>
                            {response.error && <p className="validation">{response.error}</p>}
                        </div>
                        <div className="margin-top50  text-right">
                            {inputFieldValue.APIMessage !== "" && <p className="validation text-left">{inputFieldValue.APIMessage}</p>}
                            <button type="button" className="grid_btn_1 nobg noborder _nodelete" onClick={() => navigate('/create/templates/localSender?type=92')} >Cancel</button>
                            <button type="button" className="grid_btn_1 grid_btn_active" disabled={
                                (inputFieldValue.MessageType !== "" &&
                                    inputFieldValue.SenderId !== "" &&
                                    (inputFieldValue.SenderName !== "" && inputFieldValue.SenderName.length === 6)) ? false : true
                            } onClick={saveHeader}>{getParameterByName('id', window.location.href) !== null ? 'Update' : 'Continue'}</button>
                        </div>
                    </div>
                </div>

            }</div>
    )

}

export default CreateSenderName
