import axios from "axios";
import { get as _get } from "lodash";
import { store } from "../Redux/Store";

// Create a new axios client with default settings
const apiCall = (targetType, subdomain = "authProd", addBearer = "") => {

  const url = process.env;
  let apiURL = url.REACT_APP_CRM_NODE_API_URL;
  if (subdomain === "export") {
    apiURL = url.REACT_APP_API_URL_EXPORT;
  } else if (subdomain === "import") {
    apiURL = url.REACT_APP_API_URL_BUlK_IMPORT;
  } else if (subdomain === "main") {
    apiURL = url.REACT_APP_API_URL;
  }

  const storeData = store.getState();
  const token = _get(storeData, "APITOKEN.token", "");

  const apiCall = axios.create({
    baseURL: apiURL,
    headers: {
      is_calling_from_react_app: true,
      "target": targetType, //"APIV3", //"crm"
      "Authorization": `${addBearer}${token}`,
      "X-Requested-With": "XMLHttpRequest",
    },
  });

  // Add an interceptor to modify the request before it is sent
  apiCall.interceptors.response.use(
    (response) => { return response },
    (error) => {
      //const statusCode = _get(error, "response.status", "");

      //if (statusCode === 401) store.dispatch(deleteOauthToken());

      return Promise.reject(error);
    }
  );

  return apiCall;
}

export default apiCall;