import React, { useEffect } from "react";
import getParameterByName from "../ReuseTemplate/GetRoute";
import Cookies from "js-cookie";
import { useNavigate } from "react-router";

export default function Redirect() {
  const navigate = useNavigate();
  useEffect(() => {
    const client_secret = getParameterByName("sk", window.location.href);
    if (client_secret !== null) {
      Cookies.set("secretKey", client_secret, { path: "/" });
      window.location.replace("/onboarding");
    } else {
      window.history.pushState("/login", "", "/login");
      // window.location.replace('/login');
      navigate("/login");
    }
  }, [navigate]);
  return <div></div>;
}
