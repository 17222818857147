import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TemplateListing from "./TemplateListing";
import Client from "../../GlobalAPICall";

import "./index.css";

const Template = () => {
  const { APITOKEN } = useSelector((state) => state);
  const [templateData, setTemplates] = useState([]);
  useEffect(() => {
    APITOKEN["token"] !== "" &&
      Client.get(
        `Lookup/TriggerTemplateTypes?domainUrl=${APITOKEN?.config?.subscriptionInfo?.DomainUrl}`,
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then((res) => {
          try {
            setTemplates(res["data"]);
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => console.error(err));
    return () => {
      setTemplates([]);
    };
  }, [APITOKEN]);
  return (
    <>
      <div className="dashbd_sec">
        <div className="bx-boder-common white margin-top50 margin-bottom inline-block width100">
          <div className="_insight_header white">
            <h3>Create Template</h3>
            <p className="content">&nbsp;</p>
          </div>
          <ul className="_create_tem_list">
            {templateData &&
              templateData.map((item, index) => {
                return <TemplateListing item={item} key={index} />;
              })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Template;
