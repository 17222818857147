import styled from "styled-components";
import { Datepicker } from "@mobiscroll/react";
import { useEffect, useState } from "react";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import moment from "moment";
import ButtonLoader from "./ButtonLoader";
import BalanceCheckFn from "./BalanceCheckFn";

const WaterMarkContainer = styled.div`
    top: 0;
    text-align: left;
    border-left: 1px solid #c8ccd0;
    position: fixed;
    margin-top: 0px;
    right: 0;
    background: #fff;
    width: 605px;
    height: 100%;
    z-index: 11000;
    overflow:auto;
`

const WaterHeader = styled.div`
    
    font-size: 16px;
    line-height: 1.5;
    font-family: roboto;
    div{
        padding: 20px 20px 0 20px;
    }
    h3{
        font-weight: 700;
        font-size: 16px;
    }
    p{
        font-size: 16px;

    }
`
const Masking = styled.div`
    position: fixed;
    width: 100%;
    top: 0px;
    right: 0;
    height: 100%;
    background: rgb(230 229 229 / 30%);
    z-index: 10000;

`


const WaterMarkRemove = ({ children, ...props }) => {
    const { APITOKEN } = useSelector(state => state);
    const { contentId, setLowBalancePopup, lowBlance, StartDate, EndDate } = props;
    const min = APITOKEN.config.currentTime.split('.');
    const [startDate, setStartDateSelected] = useState(!!StartDate ? StartDate : min[0]);
    const [currentDate, setCurrentData] = useState(!!StartDate ? StartDate : min[0]);
    const [endDate, setEndDateSelected] = useState("");
    const [waterText, setWaterMarkText] = useState(props.CampaignData);
    const [neverEndVal, setNeverEnd] = useState(props.IsNeverEnd);
    const [IsLoader, setIsLoader] = useState(false);
    const CampaignName = contentId.toString() === '2' ? 'Landing Page' : contentId.toString() === '5' ? 'Email' : contentId.toString() === '4' ? 'Survey' : contentId.toString() === '97' ? 'Store' : 'Workflow';
    const removeWaterMark = () => {
        const obj = {
            "startDate": moment(startDate).format("YYYY.MM.DD HH:mm"),
            "endDate": neverEndVal ? moment(startDate).format("YYYY.MM.DD HH:mm") : moment(endDate).format("YYYY.MM.DD HH:mm"),
            "name": waterText,
            "isNeverEnd": neverEndVal
        };
        debugger
        setIsLoader(true);

        const url = (contentId.toString() === '97') ? `mobile/store/${props.Id}/RemoveWaterMark` : `Campaign/${props.Id}/RemoveWaterMark`;
        Client.patch(url, obj, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                try {
                    if (data.success) {
                        props.closeEvent(false);
                        props.Reload();
                        setLowBalancePopup(prv => ({
                            ...prv,
                            display: false,
                            ...data,
                        }));
                    } else {
                        setLowBalancePopup(prv => ({
                            ...prv,
                            display: true,
                            ...data,
                        }));
                    }
                    setIsLoader(false);
                } catch (err) {
                    console.log(err);
                }
            })
    }


    useEffect(() => {
        //setCurrentData(startDate);
        // console.log(startDate)
        let date = new Date(startDate);
        const increasrMonth = date.setMonth(date.getMonth() + 1);
        //   const dates = increasrMonth.toISOString();
        // const dates = date.setMonth(date.getMonth() + 1);
        setEndDateSelected(!!EndDate ? EndDate : moment(increasrMonth).format("YYYY.MM.DD HH:mm"));
    }, [StartDate, EndDate, setCurrentData, currentDate, startDate])
    return (
        <>
            <WaterMarkContainer>
                <div className="topn-header">
                    <span className="selected-title">Activate {CampaignName}</span>
                    <span style={{ padding: '20px', float: 'right' }}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer" onClick={() => props.closeEvent(false)}></i>
                    </span>
                </div>
                <WaterHeader>
                    {children}
                </WaterHeader >
                <div className="_h_header b_top" style={{ borderBottom: '1px solid rgba(26, 40, 61, 0.24)', paddingLeft: '20px' }}>
                    <h3>Set Activation Period</h3></div>
                <div className="padding20 noborder">
                    <div className="formmodel-bx inline-block" style={{ width: '34%' }}>
                        <label className="_l_able_cls">Activation Time</label>

                        <Datepicker className="inputformmodel"
                            value={startDate}
                            min={currentDate}
                            onChange={e => { setStartDateSelected(e.inst._valueText.replace(/\./g, '/')); }}
                            dataTimezone='utc'
                            dateFormat="YYYY.MM.DD"
                            theme="ios"
                            locale={APITOKEN.config.countryCode}
                            disabled={(props.IsNeverEnd || props.Data.status === 'Active') ? true : false}
                            controls={['calendar', 'time']} placeholder="Start date"
                        />

                    </div>
                    <div className="formmodel-bx inline-block" style={{ width: '34%', padding: '0 0 0 5px', boxSizing: 'border-box' }}>
                        <label className="_l_able_cls">Closing Time</label>

                        {neverEndVal ? <input className="inputformmodel"
                            placeholder="Forever Active"
                            value="Forever Active"
                            readOnly
                            style={{
                                background: 'rgb(238, 238, 238', marginTop: '-2px'
                            }} /> :
                            <Datepicker className="inputformmodel" placeholder="End date"
                                value={endDate}
                                min={moment(startDate)}
                                onChange={e => { setEndDateSelected(e.value) }}
                                dataTimezone='utc'

                                theme="ios"
                                dateFormat="YYYY.MM.DD"
                                locale={APITOKEN.config.countryCode}
                                controls={['calendar', 'time']}
                            />
                        }
                    </div>
                    <div className="formmodel-bx nomargin">
                        <label className="_l_able_cls">
                            If you don't want a Closing Time, select Forever Active
                        </label>
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">
                            <input type="checkbox"
                                className="-checkbox-input inline-block"
                                value={neverEndVal}
                                checked={neverEndVal}
                                onChange={e => setNeverEnd(e.target.checked)}
                                style={{ position: 'relative', verticalAlign: 'middle' }} />
                            &nbsp;Forever Active
                        </label>
                    </div>

                    <div className="formmodel-bx" style={{ width: '81%' }}>
                        <label className="_l_able_cls">When the {CampaignName} is closed we will display the following Watermark Text</label>
                        <input className="inputformmodel" value={waterText} onChange={e => setWaterMarkText(e.target.value)} placeholder="Watermark Text" />
                    </div>
                    <div className="formmodel-bx nomargin">
                        <button type="button" className="grid_btn_1 grid_btn_active" onClick={removeWaterMark}>
                            <ButtonLoader IsLoader={IsLoader}>
                                Remove Watermark
                            </ButtonLoader>

                        </button>
                    </div>
                </div>
            </WaterMarkContainer>
            <Masking />
            {lowBlance && lowBlance.display ? <BalanceCheckFn APITOKEN={APITOKEN} lowBlance={lowBlance} setLowBalancePopup={setLowBalancePopup} removeWaterMark={removeWaterMark} /> : <></>}
        </>
    )

}

export default WaterMarkRemove
