import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./Reducers";

const persistConfigData = {
  key: "promoti",
  storage,
  whitelist: ["APITOKEN"],
};

const pReducer = persistReducer(persistConfigData, reducer);

// creating the main/central store
export const store = createStore(pReducer);

export const persistor = persistStore(store);

// export const store = createStore(reducer,
//     {},
//     window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
