import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { get as _get, isEmpty as _isEmpty, isEqual as _isEqual } from "lodash";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import Select from 'react-select';

import { createCustomFields, updateCustomFields } from "../../../../services/crm";

const CreateEditCustomFields = ({
	isOpen, setIsOpen, refreshTableData, setRefreshTableData, customFieldTypes, isEdit, setIsEdit,
	selectedEditField, setSelectedEditField, editCustomFieldFormData, FieldName = ""/*, setCustomFieldFormData*/
}) => {
	const pathArray = window.location.pathname.split("/");
	const listID = _get(pathArray, "[4]", null); // get PKID from URL

	//const [isOpen, setIsOpen] = useState(false)
	const toggleDrawer = () => { setIsOpen(!isOpen) }
	const [newOptionValue, setNewOptionValue] = useState("");
	const [validationData, setValidationData] = useState({});
	const [saveBtnDisable, setSaveBtnDisable] = useState(true);
	const [submitOptionButtonText, setOptionSubmitButtonText] = useState("Add Option");
	const [editOptionValues, setEditOptionValues] = useState({});
	const [isSetFieldNameOnce, setIsSetFieldNameOnce] = useState(false);
	const [isIncludeAllListOnce, setIsIncludeAllListOnce] = useState(false);
	const [isErrorIncludeAllList, setIsErrorIncludeAllList] = useState(false);
	//
	const [customFieldFormData, setCustomFieldFormData] = useState(null);

	// If Add/Edit Custom Field Drawer close, then set isEdit flag false
	useEffect(() => {
		if ((isOpen || false) === false) {
			setIsEdit(false);
			setCustomFieldFormData(null);
			setNewOptionValue("");
			setSelectedEditField({});
			setEditOptionValues({});
			setIsSetFieldNameOnce(false);
			setIsIncludeAllListOnce(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	// If Add/Edit Custom Field Drawer close, then set isEdit flag false
	useEffect(() => {
		setCustomFieldFormData(editCustomFieldFormData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editCustomFieldFormData]);

	// If Add/Edit Custom Field Drawer close, then set isEdit flag false
	useEffect(() => {
		setCustomFieldFormData({ ...customFieldFormData, customFieldName: FieldName });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [FieldName]);

	const validationCheck = useCallback(() => {
		let saveBtnFlag = false;
		let customFieldNameValidationText = "";

		if (_isEmpty(_get(customFieldFormData, "customFieldName", ""))) {
			saveBtnFlag = true;
			customFieldNameValidationText = "This field cannot be left blank. Please enter a unique field name to proceed.";
		}

		if (_isEmpty(_get(customFieldFormData, "customFieldType", "")) && (_get(customFieldFormData, "customFieldName", "") !== "Gender")) {
			saveBtnFlag = true;
		}

		if (((isEdit || false) !== true) && ([3, 4, 10, 11].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && _isEmpty(_get(customFieldFormData, "customFieldOptions", ""))) {
			saveBtnFlag = true;
		}
		if (((isEdit || false) === true) && (([3, 4, 6, 10, 11].includes(_get(customFieldFormData, "customFieldType.PKID", null))) || (_get(customFieldFormData, "customFieldName", null) === "Gender")) && _isEmpty(_get(customFieldFormData, "customFieldOptions", ""))) {
			saveBtnFlag = true;
		}

		if ((isEdit || false) === true) {
			const existingIsIncludeAllList = (_get(selectedEditField, "IncludeInAllLists", 0) === 1) ? true : false;
			const existingRegularExpresssion = (_get(selectedEditField, "RegularExpresssion", "") === "" || _get(selectedEditField, "RegularExpresssion", "") === null) ? "" : _get(selectedEditField, "RegularExpresssion", "");

			if (
				(!_isEmpty(_get(customFieldFormData, "customFieldName", "")) && _isEqual(_get(customFieldFormData, "customFieldName", ""), _get(selectedEditField, "CustomFeildName", ""))) &&
				(_isEqual(_get(customFieldFormData, "customFieldOptions", ""), JSON.parse(_get(selectedEditField, "MetaData", "")))) &&
				(_isEqual(_get(customFieldFormData, "minMax", ""), _get(selectedEditField, "MaxLen", ""))) &&
				(_isEqual(_get(customFieldFormData, "regex", ""), existingRegularExpresssion)) &&
				(_isEqual(_get(customFieldFormData, "isIncludedInAllList", ""), existingIsIncludeAllList))
			) {
				saveBtnFlag = true;
			}

			if (_get(customFieldFormData, "isIncludedInAllList", false) === false) {
				setIsErrorIncludeAllList(true);
			} else {
				setIsErrorIncludeAllList(false);
			}
		}

		/*if (([2].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && (_get(customFieldFormData, "regex", "") === "")) {
			saveBtnFlag = true;
		}
 
		if (([1].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && (_get(customFieldFormData, "minMax", "") === "")) {
			saveBtnFlag = true;
		  
			customFieldNameValidationText = "This field cannot be left blank. Please enter a unique field name to proceed.";
		}*/

		setSaveBtnDisable(saveBtnFlag);
		setValidationData({ customFieldName: customFieldNameValidationText });
		return saveBtnFlag;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customFieldFormData, isEdit, selectedEditField]);

	useEffect(() => {
		validationCheck();
	}, [customFieldFormData, validationCheck]);

	const saveCustomField = async () => {

		setIsSetFieldNameOnce(true);

		// Call Preference change API
		let payload = {
			"subscriberListId": listID,
			"labelName": _get(customFieldFormData, "customFieldName", ""),
			"dataTypeId": _get(customFieldFormData, "customFieldType.PKID", null),
			"isVisible": 1,
			"isMandatory": 1,
			"personalizationTag": `[${_get(customFieldFormData, "customFieldName", "").toString().toLowerCase()}]`,
			"isUsedAsMergeKey": false,
			"metaData": "[]",
			"isIncludedInAllList": _get(customFieldFormData, "isIncludedInAllList", false),
			"minLength": (_get(customFieldFormData, "minMax", 50) !== "" && !_isEmpty(_get(customFieldFormData, "minMax", 50))) ? parseInt(_get(customFieldFormData, "minMax", 50)) : 0,
			"maxLength": (_get(customFieldFormData, "minMax", 50) !== "" && !_isEmpty(_get(customFieldFormData, "minMax", 50))) ? parseInt(_get(customFieldFormData, "minMax", 50)) : 0,
		}

		if (!_isEmpty(_get(customFieldFormData, "customFieldOptions", []))) {
			payload = { ...payload, metaData: JSON.stringify(_get(customFieldFormData, "customFieldOptions", "")) }
		}

		if (_get(customFieldFormData, "regex", "") !== "") {
			payload = { ...payload, regex: _get(customFieldFormData, "regex", "") }
		}

		/*if (_get(customFieldFormData, "minMax", "") !== "") {
			payload = { ...payload, minLength: _get(customFieldFormData, "minMax", ""), maxLength: _get(customFieldFormData, "minMax", "") }
		}*/

		// Call update custom field preference api
		const response = await createCustomFields(payload);

		if (_get(response, "data.statusCode", 500) === 200) {
			toggleDrawer();
			setRefreshTableData(!refreshTableData); // Refresh Table Data if response is a success
		}
	};

	const updateCustomField = async () => {
		// Call Preference change API
		let payload = {
			"customFieldId": _get(selectedEditField, "CustomFieldId", ""),
			"subscriberListId": (listID || ""),
			"labelName": _get(customFieldFormData, "customFieldName", ""),
			"dataTypeId": _get(selectedEditField, "TypeId", ""),
			"isVisible": (_get(selectedEditField, "Visible", "") === true) ? 1 : 0,
			"isMandatory": (_get(selectedEditField, "Mandatory", "") === true) ? 1 : 0,
			"personalizationTag": _get(selectedEditField, "Tags", ""),
			"metaData": "[]",
			"minLength": (_get(customFieldFormData, "minMax", 50) !== "" && !_isEmpty(_get(customFieldFormData, "minMax", 50))) ? parseInt(_get(customFieldFormData, "minMax", 50)) : 50,
			"maxLength": (_get(customFieldFormData, "minMax", 50) !== "" && !_isEmpty(_get(customFieldFormData, "minMax", 50))) ? parseInt(_get(customFieldFormData, "minMax", 50)) : 50,
			"isUsedAsMergeKey": _get(selectedEditField, "MergeKey", false),
			"isIncludedInAllList": (_get(customFieldFormData, "customFieldName", "") === "Gender") ? false : _get(customFieldFormData, "isIncludedInAllList", false),
		}

		if (!_isEmpty(_get(customFieldFormData, "customFieldOptions", []))) {
			payload = { ...payload, metaData: JSON.stringify(_get(customFieldFormData, "customFieldOptions", "")) }
		}

		if (_get(customFieldFormData, "regex", "") !== "") {
			payload = { ...payload, regex: _get(customFieldFormData, "regex", "") }
		}

		// if (_get(customFieldFormData, "minMax", "") !== "") {
		//   payload = { ...payload, minLength: _get(customFieldFormData, "minMax", ""), maxLength: _get(customFieldFormData, "minMax", "") }
		// }

		// Call update custom field preference api
		const response = await updateCustomFields(payload);

		if (_get(response, "data.statusCode", 500) === 200) {
			toggleDrawer();
			setRefreshTableData(!refreshTableData); // Refresh Table Data if response is a success
		}
	};

	const cancelDrawer = async () => {

		setCustomFieldFormData(null);
		setNewOptionValue("");
		setValidationData({});
		toggleDrawer();
	}

	return (
		<Drawer
			open={isOpen}
			onClose={() => toggleDrawer()}
			direction="right"
			size={850}
			zIndex="9999"
		>
			<div className="topn-header">
				<span className="selected-title">Custom Field</span>
				<span style={{ padding: '20px', float: 'right' }} onClick={() => toggleDrawer()}>
					<i className="fa fal fa-times clsbtnNPS fright pointer"></i>
				</span>
			</div>
			<div className="scrollbar">
				<div className="padding20 b_top white text-center _h_header justify-content-between">
					{((isEdit || false) !== true) && (
						<h3>Create Custom Field</h3>
					)}
					{((isEdit || false) === true) && (
						<h3>Edit Custom Field</h3>
					)}

					{((isEdit || false) !== true) && (
						<button
							className="btn btn-light"
							onClick={() => {
								setIsEdit(false);
								setCustomFieldFormData(null);
								setNewOptionValue("");
								setSelectedEditField({});
								setEditOptionValues({});
								setIsSetFieldNameOnce(false);
								setIsIncludeAllListOnce(false);
							}}
						>Clear</button>
					)}
				</div>
				<div className="b_top">

					<div className="padding20">
						<div className="formmodel-bx">
							<label className="_l_able_cls">Add a suitable name for the custom field</label>
							<input
								type="text"
								placeholder="Custom Field Name"
								className={`inputformmodel width100 ${(((isSetFieldNameOnce || false) === true) && (_get(validationData, "customFieldName", "")) !== "") ? "has-danger" : ""}`}
								value={_get(customFieldFormData, "customFieldName", "")}
								onChange={(e) => {
									setIsSetFieldNameOnce(true);
									setCustomFieldFormData({ ...customFieldFormData, customFieldName: _get(e, "target.value", "") })
								}}
							/>
							{((isSetFieldNameOnce || false) === true) && (<p className="error-message mt-3">{_get(validationData, "customFieldName", "")}</p>)}
						</div>
						<div className="formmodel-bx">
							<label className="_l_able_cls">What type of data would you like to store in this field?</label>
							{((isEdit || false) === true) && (
								<div className="inputformmodel inputformmodelSelect width100">
									{(_get(selectedEditField, "TypeId", 0) === 9) ? "Predefined Values (with an option to select one value from a drop-down menu)" : _get(customFieldFormData, "customFieldType.Name", "")}
								</div>
							)}

							{((isEdit || false) !== true) && (
								<Select
									value={_get(customFieldFormData, "customFieldType", null)}
									name="language"
									className="custom-multi-select custom-multi-select-noscroll"
									classNamePrefix="select"
									options={(customFieldTypes || [])}
									getOptionLabel={(option) => (_get(option, "Name", ""))}
									getOptionValue={(option) => (_get(option, "PKID", ""))}
									onChange={(selectedOption) => {
										setCustomFieldFormData({ ...customFieldFormData, customFieldType: selectedOption, customFieldOptions: [], minMax: "", regex: "" });
										setEditOptionValues({});
									}}
								/>
							)}
						</div>

						{([2].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && (
							<div className="formmodel-bx">
								<label className="_l_able_cls">char. length</label>
								<input
									type="number"
									className="inputformmodel width100"
									value={_get(customFieldFormData, "minMax", 50)}
									onChange={(e) => setCustomFieldFormData({ ...customFieldFormData, minMax: _get(e, "target.value", "") })}
								/>
							</div>
						)}

						{([1].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && (
							<div className="formmodel-bx">
								<label className="_l_able_cls">Regex</label>
								<input
									type="text"
									className="inputformmodel width100"
									value={_get(customFieldFormData, "regex", "")}
									onChange={(e) => setCustomFieldFormData({ ...customFieldFormData, regex: _get(e, "target.value", "") })}
								/>
							</div>
						)}

						{((isEdit || false) !== true) && ([3, 4, 10, 11].includes(_get(customFieldFormData, "customFieldType.PKID", null))) && (
							<div className="formmodel-bx formmodel-bx-option">
								<label className="_l_able_cls fw-medium mb-0">Options available</label>

								<Fragment>
									<div className="formmodel-bx-option-list">
										{(!_isEmpty(_get(customFieldFormData, "customFieldOptions", []))) && (_get(customFieldFormData, "customFieldOptions", [])).map((option, i) => {
											return (
												<p key={i}>
													<span>{_get(option, "value", "")}</span>

													<i
														className="fal fa-trash-alt cursor-pointer"
														aria-hidden="true"
														onClick={() => {
															const updatedItems = _get(customFieldFormData, "customFieldOptions", []); // Make a copy of the original array
															updatedItems.splice(i, 1); // Remove the item at the specified index
															setCustomFieldFormData({ ...customFieldFormData, customFieldOptions: updatedItems });
														}}></i>
													<i
														className="fal fa-pencil-alt cursor-pointer"
														aria-hidden="true"
														onClick={() => {
															setNewOptionValue(_get(option, "value", ""));
															setOptionSubmitButtonText("Update Option");
														}}></i>
												</p>
											)
										})}
									</div>

									<input
										type="text"
										className="inputformmodel"
										value={(newOptionValue || "")}
										onChange={(e) => setNewOptionValue(_get(e, "target.value", ""))}
									/>

									<button
										className={`btn btn-light ${((newOptionValue || "") === "") ? "btn-disabled" : ""}`}
										disabled={((newOptionValue || "") === "")}
										onClick={() => {
											let options = _get(customFieldFormData, "customFieldOptions", []);

											options.push({ value: (newOptionValue || ""), id: Math.floor(Math.random() * 100000).toString() });

											setCustomFieldFormData({ ...customFieldFormData, customFieldOptions: options });
											setNewOptionValue("");
											setOptionSubmitButtonText("Add option");
										}}
									>
										{(submitOptionButtonText || "Add option")}
									</button>
								</Fragment>
							</div>
						)}

						{((isEdit || false) === true) &&
							(([3, 4, 6, 10, 11].includes(_get(customFieldFormData, "customFieldType.PKID", null))) || (_get(customFieldFormData, "customFieldName", null) === "Gender")) &&
							(
								<div className="formmodel-bx formmodel-bx-option">
									<label className="_l_able_cls fw-medium mb-0">Options available</label>
									<Fragment>
										<div className="formmodel-bx-option-list">
											{(!_isEmpty(_get(customFieldFormData, "customFieldOptions", []))) && (_get(customFieldFormData, "customFieldOptions", [])).map((option, i) => {
												return (
													<p key={i}>
														<span>{_get(option, "value", "")}</span>

														<i
															className="fal fa-trash-alt cursor-pointer"
															aria-hidden="true"
															onClick={() => {
																const updatedItems = _get(customFieldFormData, "customFieldOptions", []); // Make a copy of the original array
																updatedItems.splice(i, 1); // Remove the item at the specified index
																setCustomFieldFormData({ ...customFieldFormData, customFieldOptions: updatedItems });
															}}></i>
														<i
															className="fal fa-pencil-alt cursor-pointer"
															aria-hidden="true"
															onClick={() => {
																setEditOptionValues({ ...option, key: i, value: _get(option, "value", "") });
															}}></i>
													</p>
												)
											})}
										</div>

										<input
											type="text"
											className="inputformmodel"
											value={(_isEmpty(editOptionValues)) ? (newOptionValue || "") : _get(editOptionValues, "value", "")}
											onChange={(e) => {
												if (_isEmpty(editOptionValues)) {
													setNewOptionValue(_get(e, "target.value", ""))
												} else {
													setEditOptionValues({ ...editOptionValues, value: _get(e, "target.value", "") })
												}
											}}
										/>
										{(!_isEmpty(editOptionValues)) && (
											<button
												className={`btn btn-light ${(_get(editOptionValues, "value", "") === "") ? "btn-disabled" : ""}`}
												disabled={(_get(editOptionValues, "value", "") === "")}
												onClick={() => {
													let options = _get(customFieldFormData, "customFieldOptions", []);
													options[_get(editOptionValues, "key", 0)] = { ...options[_get(editOptionValues, "key", 0)], value: _get(editOptionValues, "value", "") };
													setCustomFieldFormData({ ...customFieldFormData, customFieldOptions: options });
													setEditOptionValues({})
												}}
											>
												Update option
											</button>
										)}

										{(_isEmpty(editOptionValues)) && (
											<button
												className={`btn btn-light ${((newOptionValue || "") === "") ? "btn-disabled" : ""}`}
												disabled={((newOptionValue || "") === "")}
												onClick={() => {
													let options = _get(customFieldFormData, "customFieldOptions", []);

													options.push({ value: (newOptionValue || ""), id: Math.floor(Math.random() * 100000).toString() });

													setCustomFieldFormData({ ...customFieldFormData, customFieldOptions: options });
													setNewOptionValue("");
												}}
											>
												Add option
											</button>
										)}
									</Fragment>
								</div>
							)}

						{(_get(customFieldFormData, "customFieldName", null) !== "Gender") && (
							<label className="_l_able_cls w-auto inline-block mb-4">
								<input
									type="checkbox"
									className="inline-block"
									value={_get(customFieldFormData, "isIncludedInAllList", false)}
									checked={_get(customFieldFormData, "isIncludedInAllList", false)}
									onChange={event => { setIsIncludeAllListOnce(true); setCustomFieldFormData({ ...customFieldFormData, isIncludedInAllList: !_get(customFieldFormData, "isIncludedInAllList", false) }) }}
								/>
								&nbsp; Include it in all lists
							</label>
						)}
						{(((isEdit || false) === true) && (isErrorIncludeAllList || false) === true) && ((isIncludeAllListOnce || false) === true) && (
							<p>This action will delete the custom field and its associated data from all the other lists as well. Are you sure you want to proceed?</p>
						)}

						<div className="formmodel-bx text-right mt-12 mb-0">
							<button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => cancelDrawer()} >Cancel</button>

							{((isEdit || false) !== true) && (
								<button
									type="button"
									className={`btn btn-primary btn-gape-15  ${((saveBtnDisable || false) === false) ? "grid_btn_active" : ""}`}
									disabled={saveBtnDisable}
									onClick={() => saveCustomField()}>
									Save
								</button>
							)}

							{((isEdit || false) === true) && (
								<button
									type="button"
									className={`btn btn-primary btn-gape-15  ${((saveBtnDisable || false) === false) ? "grid_btn_active" : ""}`}
									disabled={saveBtnDisable}
									onClick={() => updateCustomField()}>
									Update Field
								</button>
							)}
						</div>
					</div>
				</div>
			</div>
		</Drawer >
	)
}

export default CreateEditCustomFields;