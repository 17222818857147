
import HighchartsReact from 'highcharts-react-official';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Highcharts from 'highcharts';
import HC_exporting from 'highcharts/modules/exporting';
// import { DownLoadData } from '../DownloadChartJSON';
HC_exporting(Highcharts)

export default function BestDayChart({ Data, Title }) {
    Highcharts.setOptions({ colors: ['#aff2bf', '#e91d61', '#673ab7', '#61c8f7', '#BC36FE', "#2c53f7", "#ffa701"], borderWidth: 0 });
    const chart = useRef();
    const [bestDayData, setBestDay] = useState("");

    // const [downloadData, setDownloadData] = useState({
    //     data: DownLoadData,
    //     show: false
    // });
    const [optionData, setOptionValue] = useState({
        chart: {
            type: 'column'
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        accessibility: {
            announceNewData: {
                enabled: true
            },
            enabled: false
        },
        xAxis: {
            type: 'category'
        },
        yAxis: {
            title: {
                text: ''
            }

        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    format: '{point.y:.1f}%'
                }
            }
        },
        tooltip: {
            headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
        },
        series: [
            {

                colorByPoint: true,
                name: "BestDay",
                pointWidth: 30,
            }
        ],

    });
    const dataFilter = useCallback(() => {
        const chartArray = [];
        Data.forEach(function (v) {
            chartArray.push({ name: v['Hitsday'], y: v['Percentage'] });
        });
        const maxXObjects = Data.filter(function (o) { return o.Hits === Math.max.apply(Math, Data.map(function (o) { return o.Hits; })) });
        setBestDay(maxXObjects.length > 0 ? maxXObjects[0].Hitsday : []);
        setOptionValue({ series: [{ data: chartArray }] });
    }, [Data]);
    useEffect(() => {
        dataFilter()
    }, [dataFilter])
    return (
        <>
            {/* <button type="button"
            className="graphProperties"
            style={{ "marginRight": "30px" }}
            onClick={exportChart}
            data-id="weeklychart"><i className="fal fa-ellipsis-h"></i>
        </button> */}
            {/* {downloadData.show ?
            <div className="graphproperties-bx">
                {downloadData['data'].map((v, i) => <div key={i} className="link grpahbx" onClick={() => downloadImg(v)}>{v.name}</div>)}
            </div>
            : <></>} */}
            <h3 className="_chart_title padding-top20 padding-bottom20">Best Day</h3>
            <p>Based on opens of your total {Title}.</p>
            <h2 className="chart_t link -bestdy">{bestDayData}</h2>
            <HighchartsReact highcharts={Highcharts} options={optionData} ref={chart} />
        </>
    )
}
