export const CheckIsUnicodeExists = (msg,uniqueURLTypeLength) => {
    if (msg == null) {
        //throw new ArgumentNullException();
    }

    const NORMAL7BITMSGLEN = 160, NORMAL7BITMSGPARTLEN = 153, UNICODEMSGLEN = 70, UNICODEMSGPARTLEN = 67;
    var isUnicode = false;
    var msgPartCount = 1;
    var msgPartLength = uniqueURLTypeLength;
    var charLength = 1;
    var total7BitLength = uniqueURLTypeLength;

    msg = msg.split('');
    //charLength  = msg.length;
    for (let c = 0; c < msg.length; c++) {

        const charC = msg[c].charCodeAt(0);

        /*if(charC==20 || charC==91 || charC==92 || charC==93||charC==94 ||charC==123||charC==124||charC==125||charC==126||charC==8364){    
            charLength = charLength + 2;    
        }
        else if(charC==10 || charC==13 || charC==27 || charC==161||charC==167 ||charC==191||charC==201||charC==209||charC==214||charC==216||charC==220||charC==223||charC== 224 || charC== 232 ||charC== 233|| charC== 236 ||charC== 241 ||charC== 242 ||charC== 246 ||charC== 248 ||charC== 249 ||charC== 252 ||charC== 915 ||charC== 916 ||charC== 920 ||charC== 923 ||charC== 926 ||charC== 928 || charC== 931||charC== 934|| charC== 936||charC== 937){
            charLength= charLength + 1;
        }else{
         charLength = charLength;
        }*/

        switch (charC) {
            case 12: // Page Break control (FF)
            case 91: // [
            case 92: // \
            case 93: // ]
            case 94: // ^
            case 123: // {
            case 124: // |
            case 125: //  }
            case 126: // ~
            case 8364: // €
                charLength = 2;
                ///isUnicode = true;
                break;
            case 10: // Carriage return (CR)
            case 13: // Line feed (LF)
            case 27: // GSM escape char (ESC)
            case 161: // ¡
            case 167: // §
            case 191: // ¿
            case 201: // É
            case 209: // Ñ
            case 214: // Ö
            case 216: // Ø
            case 220: // Ü
            case 223: // ß
            case 224: // à
            case 232: // è
            case 233: // é
            case 236: // ì
            case 241: // ñ
            case 242: // ò
            case 246: // ö
            case 248: // ø
            case 249: // ù
            case 252: // ü
            case 915: // Γ
            case 916: // Δ
            case 920: // Θ
            case 923: // Λ
            case 926: // Ξ
            case 928: // Π
            case 931: // Σ
            case 934: // Φ
            case 936: // Ψ
            case 937: // Ω
                charLength = 1;
                //isUnicode = true;
                break;
            default:
                if ((charC >= 32 && charC <= 95) ||
                    (charC >= 97 && charC <= 126) ||
                    (charC >= 163 && charC <= 165) ||
                    (charC >= 196 && charC <= 199) ||
                    (charC >= 228 && charC <= 230)) {
                    charLength = 1;
                }
                else // If not in the 7-bit regular or extended charset, it must be a Unicode character, so the entire message must be encoded as Unicode
                {
                    isUnicode = true;
                }
                break;
        }
        if (isUnicode)
            break;
        total7BitLength += charLength;

        // If the current char does not fit in the current message part, start a new one
        if (msgPartLength + charLength > NORMAL7BITMSGPARTLEN) {
            msgPartCount++;
            msgPartLength = charLength;
        }
        else {
            msgPartLength += charLength;
        }
    }

    if (isUnicode) {
        var totalMsgLength = parseInt(msg.length) + parseInt(uniqueURLTypeLength);
        msgPartCount = totalMsgLength <= UNICODEMSGLEN ? 1 : parseInt(Math.ceil(totalMsgLength / parseInt(UNICODEMSGPARTLEN)));
    }
    else if (total7BitLength <= NORMAL7BITMSGLEN) {
        msgPartCount = 1;
    }

    return msgPartCount;

}
