import React, { useEffect, useState, useCallback } from "react";
import {
  get as _get,
  isEmpty as _isEmpty,
  cloneDeep as _cloneDeep,
  isEmpty,
} from "lodash";
import { useSelector } from "react-redux";
//import DatePicker from "react-datepicker";
import Drawer from "react-modern-drawer";
import { PickerOverlay } from "filestack-react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Datepicker } from "@mobiscroll/react";
import { Link } from "react-router-dom";
import moment from "moment";

import "react-modern-drawer/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "@mobiscroll/react/dist/css/mobiscroll.react.min.css";

import {
  getProfileDetails,
  addFieldsAndValue,
  getAllAddresses,
  getCRMUplodedFiles,
  getUserSegments,
  addEditAddress,
  getAddressTypes,
  getAllCountries,
  saveActivityCRMLog,
} from "../../../../../services/crm";
import getLocaleDateString from "../../../../../TimeFormat";

const FILE_PICKER_API_KEYS = process.env.REACT_APP_FILE_PICKER_API_KEY;
const defaultConfig = {
  transition: Slide,
  position: "bottom-center",
  autoClose: 1000,
  hideProgressBar: false,
  closeButton: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: false,
};

const Profile = (props) => {
  const pathArray = window.location.pathname.split("/");
  const listID = _get(pathArray, "[4]", null); // get PKID from UR
  const creationSource = _get(pathArray, "[5]", null); // get PKID from UR
  const queryParams = new URLSearchParams(window.location.search);
  const peopleId = queryParams.get("id");
  const peopleSubscriberId = queryParams.get("pId");
  const previoudId = queryParams.get("previousId");
  const currentListName = _get(props, "currentListName", "");
  const { APITOKEN } = useSelector((state) => state);
  const f = getLocaleDateString(APITOKEN.config.cultureCode);

  const [profileDetails, setProfileDetails] = useState({});
  const [allAddresses, setAllAddresses] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [userSegments, setUserSegments] = useState({});
  const [isAddEditAddressOpen, setIsAddEditAddressOpen] = useState(false);
  const [allCountries, setAllCountries] = useState([]);
  const [addressTypes, setAddressTypes] = useState([]);
  const [showFileUploadSection, setShowFileUploadSection] = useState(false);
  const [selectedRowForFileUpload, setSelectedRowForFileUpload] =
    useState(false);
  const [customFieldCheckboxChanged, setCustomFieldCheckboxChanged] = useState({
    stateChanged: false,
    row: {},
    value: null,
  });

  let defaultAddressPayload = {
    City: "",
    Country: "",
    Municipal: "",
    State: "",
    Street: "",
    Zip: "",
    PeopleSubscriberId: peopleSubscriberId,
    subscriberListId: listID,
    AddressType: "",
  };
  const [addressPayload, setAddressPayload] = useState(defaultAddressPayload);

  const fetchProfileDetails = useCallback(() => {
    (async () => {
      try {
        let payload = {
          PeopleId: peopleId || 0,
          ListId: listID,
          PeopleSubscriberId: peopleSubscriberId || 0,
        };

        const response = await getProfileDetails(payload);
        if (_get(response, "data.statusCode", 500) === 200) {
          let profileDefaultData = _cloneDeep(
            _get(response, "data.data.DefaultData", [])
          );
          let profileCustomData = _cloneDeep(
            _get(response, "data.data.CustomData", [])
          );

          (profileDefaultData || []).forEach((element) => {
            element["JsonMetaData"] = !_isEmpty(element["JsonMetaData"])
              ? JSON.parse(element["JsonMetaData"])
              : [];
          });

          (profileCustomData || []).forEach((element, i) => {
            element["JsonMetaData"] = !_isEmpty(
              _get(element, "JsonMetaData", "")
            )
              ? JSON.parse(_get(element, "JsonMetaData", ""))
              : [];

            if (_get(element, "HTMLControlType", "") === "ListBox") {
              if (_get(element, "Value", null) !== null) {
                const allValues = _get(element, "Value", "").split(",");

                element["Value"] = [];
                (allValues || []).forEach((e) => {
                  element["Value"].push(e.toLowerCase());
                });
              }
            } else if (_get(element, "HTMLControlType", "") === "CheckBox") {
              _get(element, "JsonMetaData", []).forEach((metaData) => {
                if (_get(element, "Value", null) !== null) {
                  const allMetaValues = _get(element, "Value", "").split(",");
                  (allMetaValues || []).forEach((metaValue) => {
                    if (
                      _get(metaData, "value", null).toLowerCase() ===
                      (metaValue || "").toLowerCase()
                    ) {
                      metaData["checked"] = true;
                    }
                  });
                }
              });
            } else if (_get(element, "HTMLControlType", "") === "RadioButton") {
              element["Value"] =
                _get(element, "Value", null) !== null
                  ? _get(element, "Value", null)
                  : null;
            } else if (_get(element, "HTMLControlType", "") === "DropDown") {
              element["Value"] =
                _get(element, "Value", null) !== null
                  ? _get(element, "Value", "")
                  : null;
            }
          });

          setProfileDetails({
            CustomData: profileCustomData || [],
            CustomProfile: _get(response, "data.data.CustomProfile", []),
            DefaultData: profileDefaultData || [],
          });
        }
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  }, [listID, peopleId, peopleSubscriberId]);

  const fetchCRMUploadedFiles = useCallback(() => {
    (async () => {
      try {
        const respFiles = await getCRMUplodedFiles({
          PeopleSubscriberId: peopleSubscriberId,
        });
        if (
          _get(respFiles, "flag", false) === true &&
          _get(respFiles, "data[0].filePath", "") !== "" &&
          !isEmpty(_get(respFiles, "data[0].filePath", ""))
        ) {
          setUploadedFiles(JSON.parse(_get(respFiles, "data[0].filePath", "")));
        }
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  }, [peopleSubscriberId]);

  useEffect(() => {
    (async () => {
      try {
        fetchProfileDetails();
        fetchCRMUploadedFiles();

        // Get All Addresses
        const respAddresses = await getAllAddresses({
          PeopleSubscriberId: peopleSubscriberId,
        });
        setAllAddresses(_get(respAddresses, "data.data", []));

        //Get User Segments
        const respSegments = await getUserSegments({
          subscriberListId: previoudId,
          PeopleSubscriberId: peopleSubscriberId,
        });
        setUserSegments(_get(respSegments, "data", []));
      } catch (error) {}
    })();
  }, [
    fetchProfileDetails,
    fetchCRMUploadedFiles,
    peopleId,
    listID,
    peopleSubscriberId,
    previoudId,
  ]);

  const onChangeProfileFields = async (
    controlName,
    value,
    callUpdateFunction = false
  ) => {
    let profileFields = _cloneDeep(profileDetails);
    let changedRow = {};

    const profileDefaultData = _get(profileDetails, "DefaultData", []).map(
      (row, key) => {
        if (_get(row, "ControlName", "") === controlName) {
          row = { ...row, Value: value, isChanged: true };
          changedRow = row;
        }
        return row;
      }
    );

    setProfileDetails({ ...profileFields, DefaultData: profileDefaultData });

    if ((callUpdateFunction || false) === true) {
      setCustomFieldCheckboxChanged({
        stateChanged: true,
        row: changedRow,
        value: value,
      });
    }
  };

  const onChangeProfileCustomFields = async (
    controlName,
    value,
    callUpdateFunction = false
  ) => {
    let profileFields = _cloneDeep(profileDetails);
    let changedRow = {};

    const profileCustomData = _get(profileDetails, "CustomData", []).map(
      (row, key) => {
        if (_get(row, "ControlName", "") === controlName) {
          row = { ...row, Value: value, isChanged: true };
          changedRow = row;
        }
        return row;
      }
    );

    setProfileDetails({ ...profileFields, CustomData: profileCustomData });

    if ((callUpdateFunction || false) === true) {
      setCustomFieldCheckboxChanged({
        stateChanged: true,
        row: changedRow,
        value: value,
      });
    }
  };

  // For custom field checkbox and radio button value changed
  useEffect(() => {
    if (_get(customFieldCheckboxChanged, "stateChanged", false) === true) {
      updateProfileFields(
        _get(customFieldCheckboxChanged, "row", {}),
        _get(customFieldCheckboxChanged, "value", "")
      );

      setCustomFieldCheckboxChanged({
        stateChanged: false,
        row: {},
        value: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldCheckboxChanged]);

  const updateProfileFields = (row, value) => {
    (async () => {
      try {
        if (_get(row, "isChanged", false) === true) {
          const updateFieldPayload = {
            Value: value,
            ControlId: _get(row, "ControlId", ""),
            LabelName: _get(row, "ControlName", ""),
            subscriberListId: listID,
            PeopleSubscriberId: peopleSubscriberId,
            PeopleId: peopleId,
          };

          const respUpdateField = await addFieldsAndValue({
            CustomFieldData: [updateFieldPayload],
          });

          if (_get(respUpdateField, "data.data", []).length > 0) {
            if (
              _get(respUpdateField, "data.data[0][0].RecordType", "") ===
                "Updated" ||
              _get(respUpdateField, "data.data[0][0].RecordType", "") ===
                "Created"
            ) {
              toast("Changes saved", {
                type: "success",
                ...defaultConfig,
              });

              if (typeof props.refreshProfileData === "function") {
                props.refreshProfileData(true);
              }
            }

            const value =
              typeof _get(row, "Value", "") === "object"
                ? _get(row, "Value", "") instanceof Date
                  ? moment(_get(row, "Value", "")).format()
                  : _get(row, "Value", "").toString()
                : _get(row, "Value", "");
            const finalValue =
              _get(row, "HTMLControlType", "") === "File" &&
              !_isEmpty(_get(row, "Value[0]['originalFile'].type", ""))
                ? JSON.parse(_get(row, "Value[0]['originalFile'].type", ""))
                : value;
            const saveActivityPayload = {
              content: "CRM",
              event: _get(respUpdateField, "data.data[0][0].RecordType", ""),
              subEvent: "",
              name: "Profile",
              crmList: currentListName || "",
              eventId: listID || "",
              object: _get(row, "ControlName", ""),
              value: finalValue,
              details:
                _get(row, "HTMLControlType", "") === "File"
                  ? ""
                  : _get(respUpdateField, "data.data[0][0].Remarks", ""),
              responsible: _get(APITOKEN, "TokenObject.userName", ""),
              peopleSubscriberId: peopleSubscriberId,
            };
            await saveActivityCRMLog(saveActivityPayload);
          }
          fetchProfileDetails();
          if (_get(row, "HTMLControlType", "") === "File") {
            fetchCRMUploadedFiles();
          }
        }
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  };

  const toggleAddressDrawer = () => {
    setIsAddEditAddressOpen((prevState) => !isAddEditAddressOpen);
  };

  useEffect(() => {
    if ((isAddEditAddressOpen || false) === false) {
      setAddressPayload(defaultAddressPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddEditAddressOpen]);

  const handleAddressSubmit = () => {
    (async () => {
      try {
        let payload = { AddressData: [addressPayload] };
        const respAddAddress = await addEditAddress(payload);

        if (
          _get(respAddAddress, `data.data[0][0].RecordType`, "") ===
            "created" ||
          _get(respAddAddress, `data.data[0][0].RecordType`, "") === "updated"
        ) {
          setAddressPayload(_cloneDeep(defaultAddressPayload));

          // Get All Addresses
          const respAddresses = await getAllAddresses({
            PeopleSubscriberId: peopleSubscriberId,
          });
          setAllAddresses(_get(respAddresses, "data.data", []));

          setIsAddEditAddressOpen(false);
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    })();
  };

  const onAddEditAddress = (isEdit = 0, row = {}) => {
    (async () => {
      try {
        toggleAddressDrawer();

        const respAddressTypes = await getAddressTypes(peopleSubscriberId);
        setAddressTypes(_get(respAddressTypes, "data.data", []));

        const respCountries = await getAllCountries({
          PeopleSubscriberId: peopleSubscriberId,
        });
        setAllCountries(_get(respCountries, "data.data", []));

        if ((isEdit || 0) === 1) {
          const editPayload = {
            AddressId: parseInt(_get(row, "AddressId", "")),
            AddressType: _get(row, "AddressTypeId", ""),
            PeopleSubscriberId: _get(row, "PeopleSubscriberId", ""),
            Street: _get(row, "Street", ""),
            Zip: _get(row, "Zip", ""),
            City: _get(row, "City", ""),
            Country: _get(row, "Country", ""),
            State: _get(row, "State", ""),
            Municipal: _get(row, "Municipal", ""),
            subscriberListId: listID,
          };
          setAddressPayload(editPayload);
        }
      } catch (error) {
        console.log("Error uploading: ", error);
      }
    })();
  };

  const fileUploadSection = () => {
    return (
      <PickerOverlay
        apikey={FILE_PICKER_API_KEYS}
        pickerOptions={{
          fromSources: ["local_file_system"],
          maxFiles: 4,
          onClose: () => {
            setShowFileUploadSection(false);
          },
        }}
        onUploadDone={(res) => {
          //const imgURL = (!_isEmpty(_get(res, "filesUploaded", []))) ? _get(res, "filesUploaded[0].url", []) : "";
          onChangeProfileCustomFields(
            _get(selectedRowForFileUpload, "ControlName", ""),
            _get(res, "filesUploaded", [])
          );
          updateProfileFields(
            selectedRowForFileUpload,
            JSON.stringify(_get(res, "filesUploaded", []))
          );
          setShowFileUploadSection(false);
        }}
      />
    );
  };

  const renderDrawerAddress = () => {
    return (
      <Drawer
        open={isAddEditAddressOpen}
        onClose={() => toggleAddressDrawer()}
        direction="right"
        size={572}
        zIndex="9999"
      >
        <div className="topn-header">
          <span className="selected-title">Edit Address</span>
          <span
            style={{ padding: "20px", float: "right" }}
            onClick={() => toggleAddressDrawer()}
          >
            <i className="fa fal fa-times clsbtnNPS fright pointer"></i>
          </span>
        </div>
        <div className="scrollbar">
          <div className="padding20 b_top white text-center _h_header justify-content-between">
            <h3>Address</h3>
          </div>
          <div className="padding20">
            <div className="row">
              <div className="row">
                <label className="_l_able_cls">Purpose</label>
                <div className="col-12 formmodel-bx">
                  <select
                    className="inputformmodel width100"
                    value={_get(addressPayload, "AddressType", "")}
                    onChange={(e) => {
                      setAddressPayload({
                        ...addressPayload,
                        AddressType: parseInt(_get(e, "target.value", "")),
                      });
                    }}
                  >
                    <option value="">Select</option>
                    {!_isEmpty(addressTypes) &&
                      (addressTypes || []).map((address, key) => {
                        return (
                          <option key={key} value={_get(address, "PKID", "")}>
                            {_get(address, "Type", "")}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
              <div className="row">
                <label className="_l_able_cls">Street</label>
                <div className="col-12 formmodel-bx">
                  <input
                    type="text"
                    placeholder="Street"
                    className="inputformmodel width100"
                    value={_get(addressPayload, "Street", "")}
                    onChange={(e) =>
                      setAddressPayload({
                        ...addressPayload,
                        Street: _get(e, "target.value", ""),
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <label className="_l_able_cls">Zip</label>
                <div className="col-12 formmodel-bx">
                  <input
                    type="text"
                    placeholder="Zip"
                    className="inputformmodel width100"
                    value={_get(addressPayload, "Zip", "")}
                    onChange={(e) =>
                      setAddressPayload({
                        ...addressPayload,
                        Zip: _get(e, "target.value", ""),
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <label className="_l_able_cls">City</label>
                <div className="col-12 formmodel-bx">
                  <input
                    type="text"
                    placeholder="City"
                    className="inputformmodel width100"
                    value={_get(addressPayload, "City", "")}
                    onChange={(e) =>
                      setAddressPayload({
                        ...addressPayload,
                        City: _get(e, "target.value", ""),
                      })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <label className="_l_able_cls">State</label>
                <div className="col-12 formmodel-bx">
                  <input
                    type="text"
                    placeholder="State"
                    className="inputformmodel width100"
                    value={_get(addressPayload, "State", "")}
                    onChange={(e) =>
                      setAddressPayload({
                        ...addressPayload,
                        State: _get(e, "target.value", ""),
                      })
                    }
                  />
                </div>
              </div>
              <div className="col-5 formmodel-bx">
                <label className="_l_able_cls">Country</label>
                <select
                  className="inputformmodel width100"
                  value={_get(addressPayload, "Country", "")}
                  onChange={(e) =>
                    setAddressPayload({
                      ...addressPayload,
                      Country: _get(e, "target.value", ""),
                    })
                  }
                >
                  <option value="">Select</option>
                  {!_isEmpty(allCountries) &&
                    (allCountries || []).map((country, key) => {
                      return (
                        <option
                          key={key}
                          value={_get(country, "CountryName", "")}
                        >
                          {_get(country, "CountryName", "")}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="row">
                <label className="_l_able_cls">Municipal</label>
                <div className="col-12 formmodel-bx">
                  <input
                    type="text"
                    placeholder="Municipal"
                    className="inputformmodel width100"
                    value={_get(addressPayload, "Municipal", "")}
                    onChange={(e) =>
                      setAddressPayload({
                        ...addressPayload,
                        Municipal: _get(e, "target.value", ""),
                      })
                    }
                  />
                </div>
              </div>

              <div className="formmodel-bx text-right mt-12 mb-0">
                <button
                  type="button"
                  className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg"
                  onClick={() => toggleAddressDrawer()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-gape-15"
                  disabled={_get(addressPayload, "AddressType", "") === ""}
                  onClick={() => handleAddressSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    );
  };

  return (
    <div>
      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Profile</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            {_get(profileDetails, "DefaultData", []).length > 0 &&
              _get(profileDetails, "DefaultData", []).map((row, key) => {
                console.log("ROW DEFAULT DATA: ", row);
                return (
                  <div className="col-6" key={key}>
                    <div className="mb-6">
                      <label className="_l_able_cls">
                        {_get(row, "ControlName", "")}
                      </label>
                      {_get(row, "HTMLControlType", "") === "TextBox" && (
                        <input
                          type="text"
                          className="inputformmodel width100"
                          value={
                            !_isEmpty(_get(row, "Value", null)) &&
                            _get(row, "ControlName", "") === "Date Created"
                              ? moment(
                                  _get(row, "Value", ""),
                                  "YYYY-mm-dd HH:mm:ss"
                                ).format(f)
                              : !_isEmpty(_get(row, "Value", null)) &&
                                _get(row, "ControlName", "") !== "Date Created"
                              ? _get(row, "Value", "")
                              : ""
                          }
                          onChange={(e) =>
                            onChangeProfileFields(
                              _get(row, "ControlName", ""),
                              _get(e, "target.value", "")
                            )
                          }
                          onBlur={(e) =>
                            updateProfileFields(
                              row,
                              _get(e, "target.value", "")
                            )
                          }
                          readOnly={_get(row, "IsReadOnly", false)}
                        />
                      )}

                      {_get(row, "HTMLControlType", "") === "ListBox" && (
                        <select
                          className="inputformmodel width100"
                          value={_get(row, "Value", "")}
                          multiple
                          onBlur={(e) => {
                            const selected = [
                              ..._get(e, "target.selectedOptions", []),
                            ];
                            const values = (selected || []).map(
                              (option) => option.value
                            );
                            const finalValue = !_isEmpty(values)
                              ? values.join(",")
                              : "";

                            updateProfileFields(row, finalValue);
                          }}
                          onChange={(e) => {
                            const selected = [
                              ..._get(e, "target.selectedOptions", []),
                            ];
                            const values = (selected || []).map(
                              (option) => option.value
                            );

                            onChangeProfileFields(
                              _get(row, "ControlName", ""),
                              values
                            );
                          }}
                        >
                          <option value="">None</option>
                          {_get(row, "JsonMetaData", []).length > 0 &&
                            _get(row, "JsonMetaData", []).map((meta, k) => {
                              return (
                                <option
                                  key={k}
                                  value={_get(meta, "value", "").toLowerCase()}
                                >
                                  {_get(meta, "value", "")}
                                </option>
                              );
                            })}
                        </select>
                      )}

                      {_get(row, "HTMLControlType", "") === "DropDown" && (
                        <select
                          className="inputformmodel width100"
                          value={_get(row, "Value", "")}
                          onChange={(e) =>
                            onChangeProfileFields(
                              _get(row, "ControlName", ""),
                              _get(e, "target.value", "")
                            )
                          }
                          onBlur={(e) =>
                            updateProfileFields(
                              row,
                              _get(e, "target.value", "")
                            )
                          }
                        >
                          <option></option>
                          {_get(row, "JsonMetaData", []).length > 0 &&
                            _get(row, "JsonMetaData", []).map((meta, k) => {
                              return (
                                <option key={k} value={_get(meta, "value", "")}>
                                  {_get(meta, "value", "")}
                                </option>
                              );
                            })}
                        </select>
                      )}

                      {_get(row, "HTMLControlType", "") === "Calender" && (
                        <Datepicker
                          className="inputformmodel "
                          value={
                            !_isEmpty(_get(row, "Value", ""))
                              ? moment(_get(row, "Value", "")).format(f)
                              : ""
                          }
                          onChange={(e) =>
                            onChangeProfileFields(
                              _get(row, "ControlName", ""),
                              _get(e, "value", ""),
                              true
                            )
                          }
                          //onClose={e => updateProfileFields(row, _get(e, "value", ""))}
                          dataTimezone="utc"
                          dateFormat="DD.MM.YYYY"
                          locale={APITOKEN.config.countryCode}
                          controls={["calendar"]}
                          placeholder="Choose a date"
                          touchUi={true}
                          theme="ios"
                          themeVariant="dark"
                        />
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Loyalty Program</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            {(props.loyaltyScoreData || []).length > 0 &&
              (props.loyaltyScoreData || []).map((row, key) => {
                return (
                  <div key={key} className="col-6">
                    <div className="mb-6">
                      <label className="_l_able_cls">
                        {_get(row, "controlName", "")}
                      </label>
                      <input
                        type="text"
                        className="inputformmodel width100"
                        value={_get(row, "value", "")}
                        readOnly
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Address</h3>
          <span className="flex-right">
            <button
              className="btn btn-light"
              onClick={() => onAddEditAddress(0)}
            >
              Add Address
            </button>
          </span>
        </div>

        {(allAddresses || []).length < 1 && (
          <div className="p-5 white">
            <div className="row">
              <div className="col-12">
                <div className="alert alert-warning">
                  You have not created any Address yet.
                  <p className="mt-4">
                    Create address specific groups of contacts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {(allAddresses || []).length > 0 && (
          <div className="white">
            <div className="row">
              <div className="col-12">
                <div className="dataTable-primary">
                  <table
                    width="100%"
                    cellPadding="0"
                    className="smstable"
                    cellSpacing="0"
                  >
                    <thead>
                      <tr>
                        <th className="table_cell_header pt-3" width="60">
                          <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                            <input type="checkbox" className="inline-block" />
                          </label>
                        </th>
                        <th className="table_cell_header pt-3" width="60">
                          Action
                        </th>
                        <th className="table_cell_header pt-3" width="100">
                          Purpose
                        </th>
                        <th className="table_cell_header pt-3">Street</th>
                        <th className="table_cell_header pt-3">Zip</th>
                        <th className="table_cell_header pt-3">City</th>
                        <th className="table_cell_header pt-3">Country</th>
                        <th className="table_cell_header pt-3">State</th>
                        <th className="table_cell_header pt-3">Municipal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {(allAddresses || []).length > 0 &&
                        (allAddresses || []).map((row, key) => {
                          return (
                            <tr key={key}>
                              <td className="body_table_cell">
                                <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                                  <input
                                    type="checkbox"
                                    className="inline-block"
                                  />
                                </label>
                              </td>
                              <td className="body_table_cell">
                                <span
                                  className="text-07f cursor-pointer"
                                  onClick={() => onAddEditAddress(1, row)}
                                >
                                  Edit
                                </span>
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "AddressType", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "Street", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "Zip", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "City", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "Country", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "State", "")}{" "}
                              </td>
                              <td className="body_table_cell">
                                {" "}
                                {_get(row, "Municipal", "")}{" "}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Custom Fields</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            {_get(profileDetails, "CustomData", []).length > 0 &&
              _get(profileDetails, "CustomData", []).map((row, key) => {
                return (
                  <div className="col-6" key={key}>
                    <div className="mb-6">
                      <label className="_l_able_cls">
                        {_get(row, "ControlName", "")}
                      </label>
                      {_get(row, "HTMLControlType", "") === "TextBox" && (
                        <input
                          type="text"
                          className="inputformmodel width100"
                          value={
                            _get(row, "Value", null) !== null
                              ? _get(row, "Value", "")
                              : ""
                          }
                          onChange={(e) =>
                            onChangeProfileCustomFields(
                              _get(row, "ControlName", ""),
                              _get(e, "target.value", "")
                            )
                          }
                          onBlur={(e) =>
                            updateProfileFields(
                              row,
                              _get(e, "target.value", "")
                            )
                          }
                          readOnly={_get(row, "IsReadOnly", false)}
                        />
                      )}

                      {_get(row, "HTMLControlType", "") === "ListBox" && (
                        <select
                          className="inputformmodel inputformmodel-Multiselect width100"
                          value={_get(row, "Value", "")}
                          multiple
                          onBlur={(e) => {
                            const selected = [
                              ..._get(e, "target.selectedOptions", []),
                            ];
                            const values = (selected || []).map(
                              (option) => option.value
                            );
                            const finalValue = !_isEmpty(values)
                              ? values.join(",")
                              : "";

                            updateProfileFields(row, finalValue);
                          }}
                          onChange={(e) => {
                            const selected = [
                              ..._get(e, "target.selectedOptions", []),
                            ];
                            const values = (selected || []).map(
                              (option) => option.value
                            );

                            onChangeProfileCustomFields(
                              _get(row, "ControlName", ""),
                              values
                            );
                          }}
                        >
                          {_get(row, "JsonMetaData", []) !== null &&
                            _get(row, "JsonMetaData", []).length > 0 &&
                            _get(row, "JsonMetaData", []).map((meta, k) => {
                              return (
                                <option
                                  key={k}
                                  value={_get(meta, "value", "").toLowerCase()}
                                >
                                  {_get(meta, "value", "")}
                                </option>
                              );
                            })}
                        </select>
                      )}

                      {_get(row, "HTMLControlType", "") === "DropDown" && (
                        <select
                          className="inputformmodel width100"
                          value={_get(row, "Value", "")}
                          onChange={(e) =>
                            onChangeProfileCustomFields(
                              _get(row, "ControlName", ""),
                              _get(e, "target.value", "")
                            )
                          }
                          onBlur={(e) =>
                            updateProfileFields(
                              row,
                              _get(e, "target.value", "")
                            )
                          }
                        >
                          <option></option>
                          {_get(row, "JsonMetaData", []) !== null &&
                            _get(row, "JsonMetaData", []).length > 0 &&
                            _get(row, "JsonMetaData", []).map((meta, k) => {
                              return (
                                <option key={k} value={_get(meta, "value", "")}>
                                  {_get(meta, "value", "")}
                                </option>
                              );
                            })}
                        </select>
                      )}

                      {_get(row, "HTMLControlType", "") === "Calender" && (
                        <Datepicker
                          className="inputformmodel "
                          value={
                            !_isEmpty(_get(row, "Value", ""))
                              ? _get(row, "Value", "")
                              : ""
                          }
                          onChange={(e) =>
                            onChangeProfileCustomFields(
                              _get(row, "ControlName", ""),
                              _get(e, "value", ""),
                              true
                            )
                          }
                          //onClose={e => updateProfileFields(row, _get(e, "value", ""))}
                          dataTimezone="utc"
                          dateFormat="YYYY.MM.DD"
                          locale={APITOKEN.config.countryCode}
                          controls={["calendar"]}
                          touchUi={true}
                          theme="ios"
                          themeVariant="dark"
                        />
                      )}

                      {_get(row, "HTMLControlType", "") === "File" && (
                        <button
                          className="fileUpload"
                          type="button"
                          onClick={() => {
                            setShowFileUploadSection(true);
                            setSelectedRowForFileUpload(row);
                          }}
                        >
                          File Upload
                        </button>
                      )}

                      {_get(row, "HTMLControlType", "") === "CheckBox" &&
                        _get(row, "JsonMetaData", []) !== null &&
                        _get(row, "JsonMetaData", []).length > 0 &&
                        _get(row, "JsonMetaData", []).map((meta, k) => {
                          const selected =
                            _get(row, "Value", null) !== null
                              ? _get(row, "Value", "").split(",")
                              : [];

                          return (
                            <div key={k}>
                              <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                                <input
                                  type="checkbox"
                                  className="inline-block ms-1 mt-1 mb-2 me-2"
                                  checked={
                                    (selected || []).includes(
                                      _get(meta, "value", "")
                                    )
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    (async () => {
                                      //const selectedValue = (_get(e, "target.checked", false) === true) ? _get(meta, "value", "") : "";
                                      let tmpSelected = _cloneDeep(
                                        selected || []
                                      );
                                      if (
                                        _get(e, "target.checked", false) ===
                                          true &&
                                        !(tmpSelected || []).includes(
                                          _get(meta, "value", "")
                                        )
                                      ) {
                                        (tmpSelected || []).push(
                                          _get(meta, "value", "")
                                        );
                                      } else {
                                        const index = tmpSelected.indexOf(
                                          _get(meta, "value", "")
                                        );
                                        if (index !== -1) {
                                          tmpSelected.splice(index, 1);
                                        }
                                      }
                                      const finalValue = !_isEmpty(tmpSelected)
                                        ? tmpSelected.join(",")
                                        : "";

                                      onChangeProfileCustomFields(
                                        _get(row, "ControlName", ""),
                                        finalValue,
                                        true
                                      );
                                    })();
                                  }}
                                />{" "}
                                {_get(meta, "value", "")}
                              </label>
                            </div>
                          );
                        })}

                      {_get(row, "HTMLControlType", "") === "RadioButton" &&
                        _get(row, "JsonMetaData", []) !== null &&
                        _get(row, "JsonMetaData", []).length > 0 &&
                        _get(row, "JsonMetaData", []).map((meta, k) => {
                          return (
                            <div key={k}>
                              <label className="_l_able_cls margin-bottom0 w-auto inline-block">
                                <input
                                  type="radio"
                                  name="custom_radio"
                                  className="inline-block ms-1 mt-1 mb-2 me-2"
                                  value={_get(meta, "value", "").toLowerCase()}
                                  checked={
                                    _get(meta, "value", null) !== null &&
                                    _get(row, "Value", null) !== null &&
                                    _get(meta, "value", "").toLowerCase() ===
                                      _get(row, "Value", "").toLowerCase()
                                      ? "checked"
                                      : ""
                                  }
                                  onChange={(e) => {
                                    (async () => {
                                      //const selectedValue = (_get(e, "target.checked", false) === true) ? _get(meta, "value", "") : "";
                                      //const finalValue = (!_isEmpty(tmpSelected)) ? tmpSelected.join(",") : "";

                                      await onChangeProfileCustomFields(
                                        _get(row, "ControlName", ""),
                                        _get(
                                          e,
                                          "target.value",
                                          ""
                                        ).toLowerCase(),
                                        true
                                      );
                                    })();
                                  }}
                                />{" "}
                                {_get(meta, "value", "")}
                              </label>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      {(showFileUploadSection || false) === true && fileUploadSection()}

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Uploaded File</h3>
        </div>

        <div className="white">
          <div className="row">
            <div className="col-12">
              <div className="dataTable-primary">
                <table
                  width="100%"
                  cellPadding="0"
                  className="smstable"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th className="table_cell_header pt-3" width="80%">
                        Name{" "}
                      </th>
                      <th className="table_cell_header pt-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(uploadedFiles || []).length < 1 && (
                      <tr>
                        <td className="body_table_cell white p-5" colSpan="2">
                          <div className="alert alert-warning">
                            File not uploaded yet.
                          </div>
                        </td>
                      </tr>
                    )}
                    {(uploadedFiles || []).length > 0 &&
                      (uploadedFiles || []).map((row, key) => {
                        return (
                          <tr key={key}>
                            <td className="body_table_cell">
                              {" "}
                              {_get(row, "filename", "")}{" "}
                            </td>
                            <td className="body_table_cell">
                              <span
                                className="text-07f cursor-pointer"
                                onClick={() =>
                                  window.open(_get(row, "url", ""), "_blank")
                                }
                              >
                                <i className="fal fa-file-download fa-fw fa-xl"></i>
                              </span>
                              {/*<Link to={_get(row, "url", "")} className="text-07f" target="_blank">
															<i className="fal fa-file-download fa-fw fa-xl"></i>
											</Link>*/}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>Segments</h3>
        </div>

        <div className="white">
          <div className="row">
            <div className="col-12">
              <div className="dataTable-primary">
                <table
                  width="100%"
                  cellPadding="0"
                  className="smstable"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th className="table_cell_header pt-3" width="40%">
                        Name
                      </th>
                      <th className="table_cell_header pt-3" width="50%">
                        Description
                      </th>
                      <th className="table_cell_header pt-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(userSegments || []).length < 1 && (
                      <tr>
                        <td className="body_table_cell white p-5" colSpan="3">
                          <div className="alert alert-warning">
                            This contact is not connected to any segment.
                          </div>
                        </td>
                      </tr>
                    )}

                    {(userSegments || []).length > 0 &&
                      (userSegments || []).map((row, key) => {
                        return (
                          <tr key={key}>
                            <td className="body_table_cell">
                              {" "}
                              {_get(row, "name", "")}{" "}
                            </td>
                            <td className="body_table_cell">
                              {" "}
                              {_get(row, "description", "")}{" "}
                            </td>
                            <td className="body_table_cell">
                              <Link
                                className="text-07f"
                                to={`/crm/people/subscribers/${listID}/${creationSource}/segment/buildSegment/${_get(
                                  row,
                                  "segmentId",
                                  ""
                                )}`}
                              >
                                Edit
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bx-boder-common nobg my-13">
        <div className="_h_header white border-radius2">
          <h3>NPS</h3>
        </div>

        <div className="p-5 white">
          <div className="row">
            <div className="col-12">
              <div className="alert alert-warning">
                You have not created any Address yet.
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderDrawerAddress()}

      <ToastContainer className="ToastCustom" />
    </div>
  );
};

export default Profile;
