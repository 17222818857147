import React, { useCallback, useEffect, useState } from 'react'
import Client from '../../../GlobalAPICall';
import { useSelector } from 'react-redux';
import axios from 'axios';
import BlankData from '../../../BlankData';
import ButtonLoader from '../../../ReuseTemplate/ButtonLoader';

function Language() {
    const [selectedLang, setLang] = useState("");
    const [apiResponse, setApiResponse] = useState({})
    const [deleteId, setDeleteId] = useState("");
    const [langList, setLangList] = useState({
        data: [],
        gridData: []
    });
    const [isLoader, setIsloader] = useState(false);
    const { APITOKEN } = useSelector(state => state);
    // Language API Call on Load//
    const load = useCallback(ViewAll => {
        const cancelToken = axios.CancelToken.source();
        (APITOKEN['token'] !== "") &&
            Client.get(`Mobile/Languages?viewAll=${ViewAll}`, {
                cancelToken: cancelToken.token,
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then((res) => {
                try {
                    ViewAll ?
                        setLangList(prv => ({
                            ...prv,
                            data: res.data
                        })) : setLangList(prv => ({
                            ...prv,
                            gridData: res.data
                        }))
                } catch (err) {
                    console.error(err);
                }
            }).catch(err => {
                if (axios.isCancel(err)) {
                    cancelToken.cancel();
                }
            });
    }, [APITOKEN]);

    // Language Save API Call //
    function saveLang() {
        setIsloader(true);
        Client.post(`Mobile/Languages?languageCode=${selectedLang}`, {}, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(({ data }) => {
                setIsloader(false);
                if (data.Success) {
                    setLang("");
                    load(false);
                } else {
                    setApiResponse(data);
                }
            }).catch(err => setIsloader(false));
    }

    // Language Delete API Call //
    function confirmDelete() {
        Client.delete(`Mobile/Language?id=${deleteId}`, {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        })
            .then(res => {
                if (res.data.Success) {
                    setDeleteId("");
                    load(false);
                }
            }).catch(err => console.error(err));
    }
    function landDelete(ev) {
        setDeleteId(ev.LanguageId);
    }
    useEffect(() => {
        // For LangUage Dropdown //
        load(true);
        // For LangUage Grid //
        load(false);
    }, [load])

    return (
        <>
            {deleteId === "" &&
                <div className="bx-boder-common white width100 inline-block">
                    <div className="_h_header">
                        <h3>Language</h3>
                    </div>
                    <div className="padding20">
                        <div style={{ maxWidth: '492px' }}>
                            <div className="formmodel-bx">
                                <label className="_l_able_cls">Select language</label>
                                <select className="inputformmodel" value={selectedLang} onChange={e => { setLang(e.target.value); setApiResponse({}) }}>
                                    <option value="">Select</option>
                                    {langList['data'].map((item, index) => {
                                        return <option key={index} value={item.LanguageCode}>{item.Value}</option>
                                    })}
                                </select>
                                {!apiResponse?.Success && <p className="validation">{apiResponse.Message}</p>}
                            </div>

                        </div>
                        <div className="formmodel-bx nomargin text-right">
                            <button type="button" disabled={
                                selectedLang !== "" ? false : true
                            } className="grid_btn_1 grid_btn_active" onClick={saveLang}>
                                <ButtonLoader IsLoader={isLoader}>Save</ButtonLoader>
                            </button>
                        </div>
                    </div>

                    <GridLanguage GridData={langList.gridData} OnDelete={landDelete} />
                </div>

            }
            {deleteId !== "" ?
                <div className="b_top bx-boder-common white width100 inline-block"> <div className="_h_header">
                    <h3>Delete</h3>
                </div>
                    <table
                        cellPadding="0"
                        cellSpacing="0"
                        width="100%">
                        <tbody>
                            <BlankData title="Are you sure you want to delete this language?" content="" />
                        </tbody>
                    </table>
                    <div className="text-right padding20">
                        <button className="grid_btn_1 noborder nobg" onClick={() => setDeleteId("")} type="button">Cancel</button>
                        <button className="grid_btn_1 deleteBtn" onClick={confirmDelete} type="button">Confirm</button>
                    </div>
                </div> : <></>
            }
        </>
    )
}

export default Language


function GridLanguage({ GridData, OnDelete }) {
    return (
        <div className="b_top">
            <table cellPadding="0" cellSpacing="0" className="smstable ">
                <thead>
                    <tr>
                        {/* <th className="table_cell_header">
                            Id
                        </th> */}
                        <th className="table_cell_header">
                            Name
                        </th>
                        <th className="table_cell_header">
                            Code
                        </th>
                        <th className="table_cell_header">
                            Actions
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {GridData.map((item, index) => {
                        return <tr key={index}>
                            {/* <td className="body_table_cell">
                                {item.PKID}
                            </td> */}
                            <td className="body_table_cell">
                                {item.Value}
                            </td>
                            <td className="body_table_cell">
                                {item.LanguageCode}
                            </td>
                            <td className="body_table_cell">
                                <button type="button" className="link" onClick={() => OnDelete(item)}>Delete</button>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}
