import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Client from "../GlobalAPICall";
import Tabing from "./Tabing";
import OnboardingBody from "./OnboardingBody";
import "./index.css";
const Onboarding = () => {
  const { APITOKEN, OnboardingStep } = useSelector((state) => state);
  const [dataValue, setDataValue] = useState([]);
  const defaultId = 9;
  const [tabId, setTabid] = useState(defaultId);
  const [loader, setLoader] = useState(false);

  const clickmethod = (item) => {
    setTabid(item);
    TabLoad(item);
  };
  const TabLoad = useCallback(
    (item) => {
      setLoader(true);
      APITOKEN["token"] !== "" &&
        Client.get(
          `Lookup/OnBoardingMenuDetails/${APITOKEN?.config?.subscriptionInfo?.DomainUrl}/${item}`,
          {
            headers: {
              Authorization: `Bearer ` + APITOKEN["token"],
            },
          }
        )
          .then(({ data }) => {
            try {
              setLoader(false);
              setDataValue(data);
            } catch (err) {
              console.log(err);
              setLoader(false);
            }
          })
          .catch((err) => setLoader(false));
      return () => {
        setDataValue([]);
      };
    },
    [APITOKEN]
  );

  useEffect(() => {
    TabLoad(defaultId);
  }, [TabLoad]);

  return loader ? (
    <div className="loading_onborard">&nbsp;</div>
  ) : (
    <div className="dashbd_sec">
      <div className=" bx-boder-common margin-top50 width100 inline-block white">
        <div className="on__container">
          {OnboardingStep.Data && (
            <div className="on__left">
              <div className="padding20 on_l_p">
                <h2>Let's Get Started</h2>

                <p>
                  <span>
                    {OnboardingStep.count && OnboardingStep.count}/
                    {OnboardingStep.Data.length} Complete
                  </span>
                </p>
                <p className="__progress">
                  <span
                    className="progessBar"
                    style={{
                      width: `${
                        (100 / OnboardingStep.Data.length) *
                        OnboardingStep.count
                      }%`,
                      background: "#3bac69",
                      position: "absolute",
                      left: 0,
                      top: 0,
                      height: "10px",
                      borderRadius: "10px",
                    }}
                  ></span>
                </p>
              </div>
              <p className="b_top"></p>
              <Tabing
                data={OnboardingStep.Data}
                Click={clickmethod}
                Id={tabId}
              />
            </div>
          )}
          <div className="on__right">
            <div className="tab__onboard">
              {dataValue && (
                <OnboardingBody
                  Id={tabId}
                  data={dataValue}
                  tabData={OnboardingStep.Data}
                  Click={clickmethod}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
