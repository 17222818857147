import { useEffect } from "react";
import Client from "../../../GlobalAPICall";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import SortableOrder from "../SortableOrder";
import { ToastContainer, toast } from "react-toastify";

const VideoGrid = ({ ...props }) => {
  const {
    speakerId,
    languageCode,
    APITOKEN,
    setVideoObject,
    videoObject,
    onThumnailFn,
  } = props;
  const onSortEnd = async (oldIndex, newIndex) => {
    const reorder = arrayMoveImmutable(videoObject.data, oldIndex, newIndex);
    const postObject = reorder.map(({ videoId }, index) => ({
      id: videoId,
      order: index + 1,
    }));
    setVideoObject((prv) => ({
      ...prv,
      data: arrayMoveImmutable(prv.data, oldIndex, newIndex),
    }));
    const { data } = await SortableOrder(
      { Id: speakerId, Type: "Video", Code: languageCode },
      postObject
    );
    if (data.Success) {
      toast.success("Save successfully", {
        position: "bottom-center",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  useEffect(() => {
    APITOKEN["token"] !== "" &&
      Client.get(
        `Mobile/VideosBySpeakerId?languageCode=${languageCode}&speakerId=${speakerId}`,
        {
          headers: {
            Authorization: `Bearer ` + APITOKEN["token"],
          },
        }
      )
        .then(({ data }) => {
          try {
            setVideoObject((prv) => ({
              ...prv,
              data,
            }));
          } catch (err) {
            console.error(err);
          }
        })
        .catch((err) => console.error(err));
  }, [APITOKEN, setVideoObject, languageCode, speakerId]);

  return (
    <>
      {videoObject.data.length > 0 && (
        <SortableList onSortEnd={onSortEnd} draggedItemClassName="dragged">
          <table cellPadding="0" cellSpacing="0" className="smstable">
            <thead>
              <tr>
                <th className="table_cell_header" width="40px"></th>
                <th className="table_cell_header">Title</th>
                <th className="table_cell_header">Description</th>
                <th className="table_cell_header">Category</th>
                <th className="table_cell_header">Actions</th>
              </tr>
            </thead>
            <tbody>
              {videoObject.data.map((v, i) => (
                <tr key={i}>
                  <SortableItem>
                    <td className="body_table_cell">
                      <em className="sort-icon">
                        <i className="fal fa-ellipsis-v" aria-hidden="true"></i>
                        <i className="fal fa-ellipsis-v" aria-hidden="true"></i>
                      </em>
                    </td>
                  </SortableItem>
                  <td className="body_table_cell">{v.videoTitle}</td>
                  <td className="body_table_cell">{v.videoDescription}</td>
                  <td className="body_table_cell">{v.categoryName}</td>
                  <td className="body_table_cell">
                    <button
                      type="button"
                      title="Edit"
                      className="link"
                      onClick={() => {
                        onThumnailFn(v, 1);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Edit
                    </button>
                    <button
                      type="button"
                      title="Delete"
                      className="link"
                      onClick={() => onThumnailFn(v, 4)}
                    >
                      Delete
                    </button>
                    <button
                      type="button"
                      title="Thumnail"
                      className="link"
                      onClick={() => onThumnailFn(v, 3)}
                    >
                      Thumnail
                    </button>
                    <button
                      type="button"
                      title="Caption"
                      className="link"
                      onClick={() => onThumnailFn(v, 2)}
                    >
                      Caption
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </SortableList>
      )}{" "}
      <ToastContainer className="ToastCustom" />
    </>
  );
};

export default VideoGrid;
