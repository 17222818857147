
const ImgCampaign = ({ v, contentId ,previewCampaign }) => {
    return (
        <div className="c-img" style={{ overflow: 'hidden' }}>
            <div style={{ display: (v['hasPreview']) ? 'none' : 'inline-block' }} className="draft-tag">
                This is still a Draft.
                <br />
                Once completed, preview will be displayed.
            </div>
            <div className="preview-tag" onClick={()=>previewCampaign(v)} style={{ display: (v['hasPreview']) ? 'inline-block' : 'none' }}>
                <div className="preview_bg">
                    {(contentId === '2') ? 'Landing Page Preview' : (contentId === '74') ? 'Workflow Preview' : (contentId === '4') ? 'Survey Preview': (contentId === '97') ? 'Store Preview' : 'Email Preview'}
                </div>
            </div>
            <img width="270" alt="" src={(v['inProgress']) ? 'https://deoqpqrmgsvan.cloudfront.net/web/base/icons/pencil-ruler-regular.png' : v['previewImagePath']} />
        </div>
    )

}

export default ImgCampaign
