import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom'
import Client from '../../GlobalAPICall';
import getParameterByName from '../../ReuseTemplate/GetRoute';

const ShareFolder = () => {

    const { id } = useParams();
    const checkIsAuth = useSelector(state => state);
    const [error, setError] = useState(true);
    const text = atob(getParameterByName('n', window.location.href));
    const [shareObject, setShareObject] = useState({ email: "", folderName: text });
    const [validationMessage, SetValidationMessage] = useState({ flag: true, message: "" });


    const navigate = useNavigate();
    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }
    const checkAccount = (v) => {
        if (!isValidEmail(v)) {
            setError(true);
        } else {
            setError(false);
        }
    }
    const shareCampaignFN = () => {
        const data = {
            "message": shareObject.message,
            "ToUserEmail": shareObject.email,
            "ContentName": shareObject.folderName
        };
        Client.post(`Campaign/${id}/sharecontent`, data, {
            headers: {
                'Authorization': `Bearer ` + checkIsAuth['APITOKEN']['token']
            }
        })
            .then(res => {
                try {
                    if (res.data.success) {
                        SetValidationMessage({ flag: false, message: "" });
                        navigate('/create/folderDetails');
                    } else {
                        SetValidationMessage({ flag: true, message: res.data.message })
                    }
                } catch (err) {
                    console.log(err);
                }
            }).catch(err=>{
                console.error(err);
            })
    }
    return (
        <>
            <div className="smsHeader nomargin">
                <div className="dashbd_sec">
                    <div className="left_h_p">
                        <h1 className="smstitle _t_i">Share  Folder</h1>
                        <p className="sms_content">You can share your content with other users of the platform. Add the User Account, an optional message and click on Share.</p>
                    </div>
                </div>
            </div>
            <div className="wrSms margin-top50">
                <div className="bx-boder-common common_Sec_1">
                    <div className="_h_header">
                        <h3>Share</h3>
                    </div>
                    <div className="padding20">
                        <div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">User Account</label>
                            <input type="text" className="inputformmodel width100" onChange={e => { checkAccount(e.target.value); setShareObject({ ...shareObject, ...{ email: e.target.value } }) }} />
                            {validationMessage.flag ? <p className="validation">{validationMessage.message}</p> : <></>}
                        </div>
                        <div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">Shared Folder Name</label>
                            <input type="text" className="inputformmodel width100" value={shareObject.folderName} onChange={e => setShareObject({ ...shareObject, ...{ folderName: e.target.value } })} />
                        </div>
                        <div className="formmodel-bx drop-zone">
                            <label className="_l_able_cls">Message</label>
                            <textarea type="text" className="inputformmodel width100 textareaModel" onChange={e => setShareObject({ ...shareObject, ...{ message: e.target.value } })}></textarea>
                        </div>
                        <div className="formmodel-bx text-right nomargin">
                            <button type="button" className="_clsbtn grid_btn_1 noborder btn-gape-15 nobg" onClick={() => navigate('/create/folderDetails')}>Cancel</button>
                            <button type="button" className="grid_btn_1 grid_btn_active btn-gape-15 pointer" onClick={shareCampaignFN} disabled={error}>Share</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default ShareFolder;