import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FolderListing from './FolderListing';
import MyContent from './MyContent';
import { useSelector } from 'react-redux';

const Tabing = ({ data, Id, TabLoad }) => {
    const defaultId = 1;
    const { APITOKEN } = useSelector(state => state);
    const [tabId, setTabid] = useState({ id: defaultId, totalNumber: 0 });
    const [buttonShow, setButtonShow] = useState({ nextPage: false, previous: true });
    let [pageno, setDownLoadPagination] = useState(1);
    let [permantaley, setPermantaley] = useState(false);
    let [historyToggle, setHistoryToggle] = useState(false);
    let [folderId, setFolderId] = useState({ flag: false, id: '' });
    let [skipUse, setKkipUse] = useState(10);
    let [searchText, setSearchText] = useState("");
    const [isDisplay, setDisplay] = useState(true);
    const navigate = useNavigate();

    // Tab Click //
    const clickMethod = (item) => {
        setTabid({
            id: item.id,
            totalNumber: item.totalCount
        });
        setDownLoadPagination(1);
        setDisplay(true);
    }

    return (
        <>
            <div className="mycontent">
                <ul className="tabing">
                    {data && data.map((item, index) => {
                        return <li key={index} onClick={() => { clickMethod(item); setFolderId({ ...folderId, ...{ id: '', flag: false } }) }} >
                            <Link className={item.id === tabId.id ? 'active ' : ' '}>{item.name} ({item.totalCount})</Link>
                        </li>
                    })}
                </ul>
                <button onClick={() => navigate(`/createContent/${Id}`)} type="button" className="grid_btn_1 grid_btn_active fright" style={{ margin: "4px" }}>
                    Create {Id === '2' ? 'Landing Page' : Id === '5' ? 'Email' : Id === '4' ? 'Survey' : Id === '97' ? 'Store' : 'Workflow'}
                </button>
            </div>
            {
                <div className="tab_body">
                    {tabId.id === 6 ? <FolderListing
                        tabId={tabId.id}
                        contentId={Id}
                        setFolderId={setFolderId}
                        folderId={folderId}
                        totalCount={tabId}
                        count={pageno}
                        onCountChange={setDownLoadPagination}
                        buttonEvent={setButtonShow}
                        buttonProps={buttonShow}
                        setHistoryToggle={setHistoryToggle}
                        historyToggle={historyToggle}
                        setPermantaley={setPermantaley}
                        permantaley={permantaley}
                        skip={skipUse}
                        setKkipUse={setKkipUse}
                        setTabid={setTabid}
                        APITOKEN={APITOKEN}
                        setDisplay={setDisplay}
                        setSearchText={setSearchText}
                        searchText={searchText}

                    /> :
                        APITOKEN['token'] &&
                        <MyContent
                            tabId={tabId.id}
                            contentId={Id}
                            totalCount={tabId}
                            count={pageno}
                            onCountChange={setDownLoadPagination}
                            buttonEvent={setButtonShow}
                            buttonProps={buttonShow}
                            setHistoryToggle={setHistoryToggle}
                            historyToggle={historyToggle}
                            setPermantaley={setPermantaley}
                            permantaley={permantaley}
                            skip={skipUse}
                            setKkipUse={setKkipUse}
                            setFolderId={setFolderId}
                            folderId={folderId}
                            setDisplay={setDisplay}
                            isDisplay={isDisplay}
                            APITOKEN={APITOKEN}
                            TabLoad={TabLoad}
                            setTabid={setTabid}
                            setSearchText={setSearchText}
                            searchText={searchText}
                        />}
                </div>
            }
        </>
    )
}

export default Tabing;
