
import './index.css';
import getLocaleDateString from '../TimeFormat';
import moment from 'moment';
import { useNavigate } from 'react-router';
import { useState } from 'react';
import CampaignPreview from '../ReuseTemplate/CampaignPreview';

const LastSend = ({ ...props }) => {
    const { Data, APITOKEN } = props;
    const f = getLocaleDateString(APITOKEN.config.cultureCode);
    const navigate = useNavigate();
    const campaignInfo = JSON.parse(Data.KPI);
    const [previewDisplay, setPreviewDisplay] = useState(false);

    const previewCampaign = () => {
        setPreviewDisplay(!previewDisplay);
    }
    return (
        <>
            <div className="margin-top50 bx-boder-common white">
                <div className="_tab_body_list">
                    <div className="-l-body-l white border-radius2">
                        <div className="_insight_header white">
                            <h3>Last Sent
                                <span className="flex-right fright drop-zone">
                                    <button className="grid_btn_1 nobg vertical-middle" type="button" onClick={() => navigate(`${Data.DistributionType === 1 ? `/insights/smsDetails/${Data.QueueId}` : `/insights/emailDetails/${Data.QueueId}`}`)}>Insight</button>
                                </span>
                            </h3>
                        </div>
                        <div className="lastopbarListing">
                            <h3 className="link camname inline-block gotosending pointer" onClick={() => navigate(`${Data.DistributionType === 1 ? `/insights/smsDetails/${Data.QueueId}` : `/insights/emailDetails/${Data.QueueId}`}`)} style={{ "width": "305px", "fontSize": "16px", "fontWeight": "500" }} >{Data.campaignName}</h3>
                            <span className="flex-right drop-zone sehalf">
                                <span className="_s-21_s">
                                    <label className="d-toph1">&nbsp;</label>
                                    <label className="d-toph1">Sent: <span className="startdate">{moment(Data.SentDate).utc().format(f)}</span></label>
                                    <label className="d-toph1" style={{ "paddingLeft": "15px" }}>Ends: <span className="enddate">{Data.CampaignEndDate !== null ? moment(Data.CampaignEndDate).utc().format(f) : ''}</span></label>
                                </span>
                            </span>
                        </div>
                        <div className="_listing_bx_ _listing_bx_content padding20">
                            <div className="c-img" style={{ "overflow": "hidden" }}>
                                <img className="-c-img" width="270" alt="" src={Data.CampaignImageUrl} />
                                <div className="preview-tag prevlink" onClick={previewCampaign}>{Data.DistributionType === 1 ? 'Landing Page Preview' : 'Email Page Preview'}</div>
                            </div>
                            <div className="-cam-right">
                                <div className="-l-send">
                                    {campaignInfo.map((v, i) =>
                                        <div className="-cam-detail-wrap" key={i}>
                                            <table width="100%" cellPadding="0" style={{ "tableLayout": "fixed" }} cellSpacing="0">
                                                <thead>
                                                    <tr>
                                                        <th className="excludeClass">{v.Name}</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody><tr><td style={{ "visibility": v['CountLabel'] === undefined ? 'hidden' : 'visible' }}>{v.CountLabel}</td><td style={{ "color": v.CountColor, "visibility": v['CountLabel'] === undefined ? 'hidden' : 'visible' }}><strong>{(v.Count === undefined || v.Count === -1) ? '' : v.Count}</strong></td></tr><tr><td style={{ "visibility": v['RateLabel'] === undefined ? 'hidden' : 'visible' }}>{v.RateLabel}</td><td style={{ "color": v.RateColor, "visibility": v['RateLabel'] === undefined ? 'hidden' : 'visible' }}><strong>{v.FormattedRate}%</strong></td></tr></tbody></table></div>
                                    )}

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {previewDisplay ? <CampaignPreview  Close={setPreviewDisplay} Link={Data}   /> : <></>}
        </>
    )
}

export default LastSend;