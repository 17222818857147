import { useParams } from "react-router"
import Client from "../../GlobalAPICall"
import { useState } from "react";
import ButtonLoader from "../../ReuseTemplate/ButtonLoader";

const ColleagueSlider = ({ ...props }) => {
    const { APITOKEN, Close } = props;
    const { id } = useParams();
    const [formFieldVal, setFormFields] = useState({
        email: "",
        subject: "",
        message: "",
        inValid: true
    });
    const [IsLoader, setIsLoader] = useState(false);
    const sendCollFN = () => {
        setIsLoader(true);
        const { email, subject, message } = formFieldVal;
        const obj = {
            toEmails: [{ email }],
            subject,
            message
        };
        Client.put(`Domain/${id}/SendConfigurationToColleague`, obj, {
            headers: {
                'Authorization': `Bearer ${APITOKEN['token']}`
            }
        })
            .then(({data}) => {
                try {
                    setIsLoader(false);
                    if (data.Success) {
                        Close(false);
                    }
                } catch (err) {
                    console.log(err);
                }

            }).catch(err => {
                console.error(err);
                setIsLoader(false);
            })
    }
    const formField = e => {
        if (e.target.name === 'email') {
            let regEmail = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g;
            if (!regEmail.test(e.target.value)) {
                setFormFields(prv => ({
                    ...prv,
                    inValid: true
                }))
            } else {
                setFormFields(prv => ({
                    ...prv,
                    inValid: false,
                    [e.target.name]: e.target.value
                }))
            }
        } else {
            setFormFields(prv => ({
                ...prv,
                [e.target.name]: e.target.value
            }))
        }
    }
    return (
        <div className="send__colleaguemasking">
            <div className="send__colleague">
                <div className="topn-header">
                    <span>Send to Colleague</span>
                    <span style={{
                        padding: '20px', float: 'right'
                    }} onClick={() => Close(false)}>
                        <i className="fa fal fa-times clsbtnNoti fright pointer"></i>
                    </span>
                </div>
                <div className="_h_header b_top white">
                    <h3>Send to Colleague</h3>
                </div>
                <div className="padding20">
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Email Address</label>
                        <input type="text" name="email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+" title="Invalid email address" className="inputformmodel width100" onChange={formField} />
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Subject</label>
                        <input type="text" name="subject" className="inputformmodel width100" onChange={formField} />
                    </div>
                    <div className="formmodel-bx">
                        <label className="_l_able_cls">Message</label>
                        <textarea type="text" name="message" className="inputformmodel width100 textareaModel" onChange={formField}></textarea>
                    </div>
                    <div className="formmodel-bx text-right nomargin">
                        <button type="button" onClick={sendCollFN} disabled={
                            (formFieldVal.inValid || formFieldVal.subject === "" || formFieldVal.message === "") ? true : false
                        } className="grid_btn_1 grid_btn_active">
                            <ButtonLoader IsLoader={IsLoader}>Send</ButtonLoader></button>
                    </div>
                </div>

            </div>
        </div>
    )

}

export default ColleagueSlider
