import React, { useEffect, useState, Fragment } from 'react';
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { PickerOverlay } from 'filestack-react';
import addImage from './../../../../assets/img/icon-add-image.svg';
import sixDot from './../../../../assets/img/icon-six-dot.svg';
import ButtonLoader from '../../../../ReuseTemplate/ButtonLoader';

import iPhoneHeader from './../../../../assets/img/iphone-header.png';
import iPhoneFooter from './../../../../assets/img/iphone-footer.png';
import iconChevRight from './../../../../assets/img/icon-chev-right.svg';

import { fetchLandingPages, createStore } from "../../../../services/store";

const FILE_PICKER_API_KEY = process.env.REACT_APP_FILE_PICKER_API_KEY;

const StampCards = ({ storeDetails, fetchStoreData }) => {
	const [stampCards, setStampCards] = useState(_get(storeDetails, "stamps", []));
	const [showFileUploadSection, setShowFileUploadSection] = useState(false);
	const [activeAccordionId, setActiveAccordionId] = useState(null);
	const [formValid, setFormValid] = useState(false);
	const [landingPages, setLandingPages] = useState([]);
	const [isLoader, setIsLoader] = useState(false);

	// On Page Refresh > Fetch Landing Pages
	useEffect(() => {
		(async () => {
			try {

				const response = await fetchLandingPages();

				setLandingPages(_get(response, "data", []));
			} catch (err) {
				console.log('Error occured when fetching list', err);
			}
		})();
	}, []);

	// If Props changed from, re-populate values in fields
	useEffect(() => {

		setStampCards(_get(storeDetails, "stamps", []));
	}, [storeDetails]);

	const handleNameChange = (id, value) => {
		const updatedCards = stampCards.map(card =>
			card.id === id ? { ...card, cardName: value } : card
		);
		setStampCards(updatedCards);
	};

	const handleLandingPageChange = (id, value) => {
		const updatedCards = stampCards.map(card =>
			card.id === id ? { ...card, campaignUrl: value } : card
		);
		setStampCards(updatedCards);
	};

	const addStampCard = () => {
		const newCard = { id: stampCards.length + 1, cardName: '', icon: null, campaignUrl: '', isOpen: true };
		setStampCards([...stampCards.map(card => ({ ...card, isOpen: false })), newCard]);
	};

	const toggleAccordion = (id) => {
		const updatedCards = stampCards.map(card =>
			card.id === id ? { ...card, isOpen: !card.isOpen } : card
		);
		setStampCards(updatedCards);
	};

	const deleteAccordion = (id) => {
		const updatedCards = stampCards.filter(card => card.id !== id);
		setStampCards(updatedCards);
	};

	const validateForm = () => {
		const newErrors = (stampCards || []).map(card => ({
			id: card.id,
			cardName: !card.cardName ? 'Stamp Card name is required.' : '',
			icon: !card.icon ? 'Icon image is required.' : '',
			campaignUrl: !card.campaignUrl ? 'Landing Page selection is required.' : '',
		}));

		setFormValid(newErrors.every(error => !error.cardName && !error.icon && !error.campaignUrl));
	};

	useEffect(() => {
		validateForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [stampCards]);

	const handleSubmit = () => {
		(async () => {
			try {

				if (formValid) {
					setIsLoader(true);
					const mergedData = {
						"storeId": _get(storeDetails, "storeId", ""),
						"stamps": stampCards,
						"isCompleted": true
					};

					const response = await createStore(mergedData);
					setIsLoader(false);
					if (_get(response, "flag", false) === false) {

					} else {
						fetchStoreData(true);
					}
				}
			} catch (err) {
				console.log('Error occured when submitting data', err);
			}
		})();
	};

	const fileUploadSection = () => {
		return (
			<PickerOverlay
				apikey={FILE_PICKER_API_KEY}
				pickerOptions={{
					fromSources: ["local_file_system"],
					accept: ["image/*"],
					onClose: () => { setShowFileUploadSection(false); }
				}}
				onUploadDone={(res) => {
					const updatedCards = stampCards.map(card =>
						card.id === activeAccordionId ? {
							...card,
							icon: _get(res, "filesUploaded[0].url", ""),
							fileName: _get(res, "filesUploaded[0].filename", "")
						} : card,
					);
					setStampCards(updatedCards);
					setShowFileUploadSection(false);
				}}
			/>
		);
	}

	const renderAccordian = () => {
		return (
			<Fragment>
				{stampCards.map(card => {

					return (
						<div className="formmodel-bx" key={card.id}>
							<div className="accordion-store">
								<div className="accordion-header" onClick={() => toggleAccordion(card.id)} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }}>
									<h4 className="flex align-items-center gap-2"><img src={sixDot} alt="" />{`Stamp Card ${card.id}`}</h4>
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<span onClick={(e) => { e.stopPropagation(); deleteAccordion(card.id); }} style={{ cursor: 'pointer' }}>
											<i className="fas fa-trash"></i>
										</span>
										<span>
											<i className={`fas fa-chevron-${card.isOpen ? 'up' : 'down'}`}></i>
										</span>
									</div>
								</div>
								{card.isOpen && (
									<div className="accordion-content">
										<div className="row">
											<div className="col-9">
												<div className="formmodel-bx">
													<label className="_l_able_cls">Stamp Card Name <span className="error-message">*</span></label>
													<input
														type="text"
														className="inputformmodel width100"
														value={card.cardName}
														onChange={(e) => handleNameChange(card.id, e.target.value)}
													/>
												</div>
												<div className="formmodel-bx">
													<label className="_l_able_cls">Select Landing Page <span className="error-message">*</span></label>
													<select
														className="inputformmodel width100"
														value={card.campaignUrl}
														onChange={(e) => handleLandingPageChange(card.id, e.target.value)}
													>
														<option value="">Select Landing Page</option>
														{!_isEmpty(landingPages) && (landingPages || []).map((v, i) => (_get(v, "IsDraft", false) === false) && (
															<option key={i} value={_get(v, "LongUrl", "")}>{_get(v, "Name", "")}</option>
														))}
													</select>
												</div>
												<div className="formmodel-bx">
													<label className="_l_able_cls">Icon Image <span className="error-message">*</span></label>
													<div className="position-relative">
														<div className="store-image">
															<div
																className="step-upload-file"
																onClick={() => {
																	setShowFileUploadSection(true);
																	setActiveAccordionId(_get(card, "id", 0));
																}}
															>
																<i className="fal fa-cloud-upload" aria-hidden="true"></i>
															</div>
														</div>
														{card.icon && (
															<div className="uploaded-images">
																<img src={_get(card, "icon", "")} alt="" width={100} />
															</div>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					)
				})}
			</Fragment>
		);
	};

	return (
		<>
			<div className="bx-boder-common width100 nobg mt-8">
				<div className="_h_header white border-radius2">
					<h3>Stamp Cards</h3>
				</div>

				<div className="p-5 white right-appscreenshot-height">

					<div className="row">
						<div className="col-8">
							<label className="_l_able_cls">Add stamp cards</label>

							{renderAccordian()}

							<div className="store-add-image mt-5" onClick={addStampCard}>
								<img src={addImage} alt="" />
								<p>Add Stamp Card</p>
							</div>

							<div className="formmodel-bx text-right mt-12 mb-0">
								<button
									type="button"
									className={`btn btn-primary btn-gape-15 ${formValid ? '' : 'btn-disabled'}`}
									onClick={handleSubmit}
									disabled={!formValid}
								>
									<ButtonLoader IsLoader={isLoader}>
										Save
									</ButtonLoader>
								</button>
							</div>
						</div>

						<div className="col-4">
							<div className="right-appscreenshot">
								<div className="right-appscreenshot-banner">
									<div className="overflow-hidden">
										<img src={iPhoneHeader} className="right-appscreenshot-banner-header" alt="Header" />
										<img src={iPhoneFooter} className="right-appscreenshot-banner-footer" alt="Footer" />

										<p className="right-appscreenshot-banner-header-text">Shop4You</p>

										<div className="right-appscreenshot-banner-feature">
											{((stampCards || []).length > 0) && (stampCards || []).map(card => {
												return (
													<div className="right-appscreenshot-banner-feature-list">
														<div className="right-appscreenshot-banner-feature-icon">
															{!_isEmpty(_get(card, "icon", null)) && (
																<img src={_get(card, "icon", "")} alt="" />
															)}
														</div>

														<div className="right-appscreenshot-banner-feature-text">
															{_get(card, "cardName", "")}
														</div>

														<div className="right-appscreenshot-banner-feature-arrow">
															<img src={iconChevRight} alt="Arrow" />
														</div>
													</div>
												)
											})}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{showFileUploadSection && fileUploadSection()}
		</>
	);
};

export default StampCards;
