import Tabing from './Tabing';
const ShareCampaignDetails = () => {
    return (
       <> <div className="dashbd_sec margin-top50">&nbsp;</div>
        <div className="dashbd_sec margin-top50">
            <Tabing data={[{ id: 1, name: 'Shared With You' }, { id: 2, name: 'Shared Content' }]} />
        </div>
        </>
    )
}
export default ShareCampaignDetails;