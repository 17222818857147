import React, { useRef, useState } from 'react';
import '../ReuseTemplate/Chargify.css'
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Client from '../GlobalAPICall';
import { useCallback } from 'react';
import { AddFundRePayment } from './Payment';
import ButtonLoader from './ButtonLoader';
const ChargifyPayment = (props) => {
    const { APITOKEN } = useSelector(state => state);
    const chargifyForm = useRef();
    const { setChargifyDisplay ,reload ,setPreloadBalance } = props;
    const payment13 = useRef();
    const [field13Validation, setField13] = useState(false);
    const [amount, setMinimumObject] = useState({});

    const chargify = useRef(new window.Chargify());
    // const [loader, setLoader] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        // setLoader(true)
        setField13(payment13.current.value === "" ? true : false)
        chargify.current.token(
            chargifyForm.current,

            (token) => {
                console.log('{host} token SUCCESS - token: ', token);
                // setLoader(false)
                createPaymentProfile(token);
                
            },

            (error) => {
                console.log('{host} token ERROR - err: ', error);
            }
        );
    }


    // Add fund api Call //

    

    // Create Paymet API Call  //
    const createPaymentProfile = token => {
        try {
            Client.post(`User/CreatePaymentProfile?chargify_token=${token}`, {}, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            }).then(({ data }) => {
                try {
                    const obj = {
                        "amount": payment13.current.value,
                        "details": "Add Prepaid Fund",
                        "memo": "Add Prepaid Fund"
                    };
                    setChargifyDisplay(false);
                    if (data['content'].success || data['success']) {
                        AddFundRePayment({ obj, APITOKEN }, function (data) {
                            setPreloadBalance(prv => ({
                                ...prv,
                                isloader: false,
                                isloaderaddFund: false,
                                response: true,
                                ...data
                            }));
                            reload();
                        });
                        
                    }
                } catch (err) {
                    console.error(err);
                }

            })
                .catch(err => console.error(err))
        } catch (err) {
            console.error(err);
        }
    }

    // Load Chargify with Public Key  //
    const callChargify = useCallback((data) => {
        chargify.current.load({
            publicKey: data['publicKey'],
            type: 'card',
            securityToken: data['security_token'],
            serverHost: data['serverHost'],
            hideCardImage: false,
            requiredLabel: '*',
            addressDropdowns: true,
            style: {
                '#chargify-form': { border: '0px dashed #ffc0cb57' },
                field: {
                    backgroundColor: '#ffff',
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: '5px'
                },
                input: {
                    backgroundColor: '#fff',
                    paddingTop: '4px',
                    paddingBottom: '1px',
                    placeholder: { color: '#eee' },
                    height: '36px'
                },
                label: {
                    backgroundColor: '#fff',
                    paddingTop: '2px',
                    paddingBottom: '1px',
                    marginTop: '0'
                },
                message: {
                    backgroundColor: '#f1a3a3',
                    color: 'white',
                    paddingTop: '7px',
                    paddingBottom: '7px',
                    paddingLeft: '7px',
                    paddingRight: '7px',
                    marginTop: '0px'
                }
            },
            fields: {
                firstName: {
                    selector: '#chargify5',
                    label: 'First Name',
                    placeholder: 'First Name',
                    required: true,
                    message: 'First name is not valid. Please update it.',
                    maxlength: '30',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px',

                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                lastName: {
                    selector: '#chargify6',
                    label: 'Last Name',
                    placeholder: 'Last Name',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '30',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                number: {
                    selector: '#chargify1',
                    label: 'Card number',
                    placeholder: 'xxxx xxxx xxxx xxxx',
                    message: 'This field is not valid. Please update it.',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                month: {
                    selector: '#chargify2',
                    label: 'Month',
                    placeholder: 'Month',
                    message: 'This field is not valid. Please update it.',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                year: {
                    selector: '#chargify3',
                    label: 'Year',
                    placeholder: 'YYYY',
                    message: 'This field is not valid. Please update it.',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                cvv: {
                    selector: '#chargify4',
                    label: 'CVV',
                    placeholder: 'CVV',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                address: {
                    selector: '#chargify7',
                    label: 'Address',
                    placeholder: 'Address 1',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '70',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                address2: {
                    selector: '#chargify8',
                    label: 'Address 2',
                    placeholder: 'Address 2',
                    message: 'This field is not valid. Please update it.',
                    maxlength: '70',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                city: {
                    selector: '#chargify11',
                    label: 'City',
                    placeholder: 'City',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '30',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                country: {
                    selector: '#chargify9',
                    label: 'Country',
                    placeholder: 'Select...',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '2',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        select: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            height: '36px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                state: {
                    selector: '#chargify10',
                    label: 'State',
                    placeholder: 'Select...',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '2',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        select: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },
                zip: {
                    selector: '#chargify12',
                    label: 'Zip',
                    placeholder: 'Zip',
                    required: true,
                    message: 'This field is not valid. Please update it.',
                    maxlength: '5',
                    style: {
                        field: {
                            backgroundColor: '#fff',
                            padding: '3px',
                            borderRadius: '5px'
                        },
                        input: {
                            backgroundColor: '#fff',
                            paddingTop: '2px',
                            paddingBottom: '1px'
                        },
                        label: {
                            paddingTop: '2px',
                            paddingBottom: '1px',
                            fontSize: '13px',
                            fontWeight: '400'
                        },
                        message: { paddingTop: '2px', paddingBottom: '1px' }
                    }
                },

            }
        });
    }, [])

    // Get Amount for Minimum amount Fields//

    const minimumAmount = useCallback(() => {
        Client.get('User/SMS_MinimumAmount', {
            headers: {
                'Authorization': `Bearer ` + APITOKEN['token']
            }
        }).then(({ data }) => {
            try {
                payment13.current.value = data.MinAmountForPayment;
                setMinimumObject(data);
            } catch (err) {
                console.log(err);
            }
        }).catch(err => console.error(err));

    }, [APITOKEN]);


    // Get Security Token //
    const securityToken = useCallback(() => {
        APITOKEN.token &&
            Client.get(`User/GetChargifySecurityTokenForPayment`, {
                headers: {
                    'Authorization': `Bearer ` + APITOKEN['token']
                }
            })
            .then(({ data }) => {
                try {
                    callChargify(data);
                    minimumAmount();
                } catch (err) {
                    console.error(err);
                }
                }).catch(err => console.error(err));
    }, [APITOKEN, callChargify, minimumAmount]);




    useEffect(() => {
        securityToken();


    }, [securityToken])

    return (
        <div className="chargify-js-masking">
            <div className="chargify-js-wrapper">
                <div className="chargify-js-content">
                    <div className="topn-header">
                        <span className="selected-title">Add card</span>
                        <span style={{ padding: '20px', float: 'right' }} onClick={() => setChargifyDisplay(false)}><i className="fa fal fa-times clsbtnNoti fright pointer"></i></span>
                    </div>
                    <form className="host-form" id='chargify-form' onSubmit={handleSubmit} ref={chargifyForm}>
                        <div className="cardfront cardheight">
                            <div id="chargify1"></div>
                        </div>
                        <div className="cardback cardheight">
                            <div id="chargify2"></div>
                            <div id="chargify3"></div>
                            <div id="chargify4"></div>
                        </div>
                        <div className="name cardheight">
                            <div id="chargify5"></div>
                            <div id="chargify6"></div>
                        </div>
                        <div className="address1 cardheight">
                            <div id="chargify7"></div>
                        </div>
                        <div className="address2 cardheight">
                            <div id="chargify8"></div>
                        </div>
                        <div className="address3 cardheight">
                            <div id="chargify9"></div>
                            <div id="chargify10"></div>
                        </div>
                        <div className="address4 cardheight">
                            <div id="chargify11"></div>
                            <div id="chargify12"></div>
                        </div>
                        <div className="payment cardheight drop-zone">
                            <div id="chargify13"><label>
                                When you add a credit card we need to charge you a minimum amount for your card Authentication. The amount will be added to your account balance.</label><label>Minimum amount (optional higher amount)</label>
                                <div className="card__flex">
                                    <span className="currency_add_card" style={{ height: '36px', paddingLeft: '10px' }}>
                                        {amount && amount.Currency}
                                    </span>
                                    <input type="number" id="payment13" className={`${field13Validation ? 'cfy-field--invalid' : ''}`} ref={payment13} />
                                    {field13Validation && <div className="paymentmessage cfy-message">This field is not valid. Please update it.</div>}</div></div>
                        </div>
                        <div className="text-right">
                            <button type="submit" className="host-button grid_btn_1 grid_btn_darkBlue_common">
                                <ButtonLoader IsLoader={false}>Add card</ButtonLoader></button></div>
                    </form>
                </div>


            </div>
        </div>
    );

}

export default ChargifyPayment;
