import { useCallback, useEffect, useState } from "react";
import Client from "../../GlobalAPICall";
import { useSelector } from "react-redux";
import { Datepicker } from "@mobiscroll/react";
// import getLocaleDateString from "../../TimeFormat";

const NPSFilterContainer = ({ ...props }) => {
    const { SetPostObject ,SetNPSFilterDisplay } = props;
    const { APITOKEN } = useSelector(state => state);
    const [dateSpan, setDateSpan] = useState([]);
    // const [minDate, setMinDate] = useState('');
    // const [maxDate, setMaxDate] = useState('');
    const [setDateValue, setDate] = useState('');

    const load = useCallback(() => {
        try {
            APITOKEN['token'] &&
                Client.get(`Insights/GetInsightFilterDateSpan`, {
                    headers: {
                        'Authorization': `Bearer ` + APITOKEN['token']
                    }
                })
                    .then(res => {
                        try {
                            setDateSpan(res.data);
                        } catch (err) {
                            console.log(err);
                        }
                    }).catch(err => {
                        console.error(err);
                    })
        } catch (err) {
            console.error(err);
        }
    }, [APITOKEN]);
    const onSelectedChange = (ev) => {
        setDate(ev.value)
        SetPostObject(prv => ({ ...prv, fromDate: ev.value[0], toDate: ev.value[1] }));
    };
    const dateRangeSelected = (ev) => {
        setDate([new Date(ev.FromDate), new Date(ev.ToDate)]);
        SetPostObject(prv => ({ ...prv, fromDate: new Date(ev.FromDate), toDate: new Date(ev.ToDate) }));
    }
    const calenderTimeSet = useCallback(() => {
        let now = new Date(),
            max = new Date(now.getFullYear(), now.getMonth(), 0);
        let startDate = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        setDate([startDate, max]);
    }, [])
    useEffect(() => {
        load();
        calenderTimeSet();
    }, [load, calenderTimeSet])
    return (
        <div className="nps__masking">
            <div className="filter__container">
                <div className="topn-header">
                    <span className="selected-title">NPS Filters</span>
                    <span style={{ padding: '20px', float: 'right' }} onClick={()=>SetNPSFilterDisplay(false)}>
                        <i className="fa fal fa-times clsbtnNPS fright pointer"></i>
                    </span>
                </div>
                <div className="scrollbar">
                    <div className="padding20 b_top white text-center">
                        <p className="font-16">Select the NPS Filters you would like to use.</p>
                    </div>
                    <div className="padding20 b_top">
                        <label className="font-16">Date Range</label>
                    </div>
                    <div className="b_top">
                        <div className="padding20 date_rangebx">
                            {dateSpan.map((v, i) => <button key={i} onClick={() => dateRangeSelected(v)} className="grid_btn_1 btn-gape-15 font-weight400 link margin-right15 spanformat"
                                style={{ background: v['selected'] ? '#ddedff' : '#fff', color: v['selected'] ? '#0177ff' : '#0177ff' }}>{v['DateSpan']}</button>)}
                        </div>
                        <div className="padding20">
                            <div className="formmodel-bx nomargin mbsc-control-w mbsc-mobiscroll mbsc-ltr mbsc-input">
                                <label className="_l_able_cls">Custom Date Range</label>
                                {/* <input type="text" className="inputformmodel date__range mbsc-control" /> */}
                                <span className="date__range inline-block">
                                    <Datepicker className="inline-block border-radius4  inputformmodel"
                                        select="range"
                                        value={setDateValue}
                                        onChange={e => onSelectedChange(e)}
                                        dataTimezone='utc'
                                        dateFormat="YYYY.MM.DD"
                                        display="center"
                                        theme="ios"
                                        locale={APITOKEN.config.countryCode}
                                        controls={['calendar']} />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

}

export default NPSFilterContainer
