import React, { Component } from 'react'

export class InsightBlank extends Component {
    render() {
        return (
            <div className="-n-d-p" id="notdataPin"><div className="no-data-cls"><button type="button" className="link">PIN</button></div><div className="no-data-cls"><button type="button" className="link">PIN</button></div><div className="no-data-cls"><button type="button" className="link">PIN</button></div><div className="no-data-cls"><button type="button" className="link">PIN</button></div></div>
        )
    }
}

export default InsightBlank
